import { renderTableComponent } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LoanInterface } from '../../../../models/interfaces/loans/loan';
import { LoanV2Type } from '../types/loan-v2-type';

export const RepaymentWeight = {
  disbursement: 0,
  'standard-repayment': 1,
  'skipped-repayment': 2,
  'edited-repayment': 3,
  'adhoc-repayment': 4,
};

export function renderViewRepayments({
  loansService,
}: {
  loansService: LoanInterface;
}): ({ loan }: { loan: LoanV2Type }) => JSX.Element {
  const Table = renderTableComponent();
  return function ViewRepayments({ loan }: { loan: LoanV2Type }): JSX.Element {
    const [repayments, setRepayments] = useState<Array<any>>([]);
    useEffect(() => {
      loansService.getSchedule({ id: loan.id }).then((res) => {
        setRepayments(
          res.sort((a, b) => {
            const diff = moment(a.monthYear).diff(moment(b.monthYear));
            if (diff !== 0) {
              return diff;
            }

            if (RepaymentWeight[a.type] === RepaymentWeight[b.type]) {
              return moment(a.lastModifiedAt).diff(b.lastModifiedAt);
            }

            return RepaymentWeight[a.type] - RepaymentWeight[b.type];
          })
        );
      });
    }, [loan.id]);
    const repaymentContri = _.sumBy(
      repayments,
      'details.repaymentContriInterest'
    );

    const repaymentPrincipal = _.sumBy(
      repayments,
      'details.repaymentContriPrincipal'
    );

    return (
      <div>
        <div className='border border-gray-200 rounded-md p-2 my-1'>
          <div>
            <b>Employee:</b> {loan.employee.name}
          </div>
          <div>
            <b>Loan Amount:</b>{' '}
            {loan.details?.loanRequests?.map((x, key) => (
              <span
                className='border border-gray-100 px-2 rounded-md'
                key={key}
              >
                {x.amount} - {x.status} on {moment(x.date).format('MMM,YY')}
              </span>
            ))}
          </div>
          <div>
            <b>Monthly Deduction:</b>{' '}
            {loan.details?.monthlyDeductions?.map((x, key) => (
              <span
                className='border border-gray-100 px-2 rounded-md'
                key={key}
              >
                {x.amount} - {x.status} on {moment(x.date).format('MMM,YY')}
              </span>
            ))}
          </div>
          <div>
            <b>Interest Percent:</b>{' '}
            {loan.details?.interestRates?.map((x, key) => (
              <span
                className='border border-gray-100 px-2 rounded-md'
                key={key}
              >
                {x.rate}% - {x.status} on {moment(x.date).format('MMM,YY')}
              </span>
            ))}
          </div>
        </div>
        <div className='border border-gray-200 rounded-md p-2 my-1'>
          <Table
            header={[
              [
                { name: 'Date' },
                { name: 'Type' },
                { name: 'No.Of.Days' },
                { name: 'Disbursement' },
                { name: 'Opening Balance' },
                { name: 'Interest Carried Over' },
                { name: 'Repayment' },
                { name: 'Monthly Interest' },
                { name: 'Interest Rate' },
                {
                  name: 'Repayment Contribution towards Interest',
                },
                {
                  name: 'Repayment Contribution towards Principal',
                },
                { name: 'Closing Balance' },
                { name: 'Status' },
              ],
            ]}
            body={[
              ...repayments.map((x) => {
                return {
                  cells: [
                    { value: moment(x.monthYear).format('YYYY-MM-DD') },
                    { value: x.type },
                    { value: x.details.noOfDays, style: 'text-right' },
                    {
                      value: x.details.disbursementAmt || '-',
                      style: 'text-right',
                    },
                    { value: x.details.openingBal, style: 'text-right' },
                    {
                      value: x.details.interestCarriedOver,
                      style: 'text-right',
                    },
                    { value: x.details.repayment, style: 'text-right' },
                    { value: x.details.monthlyInterest, style: 'text-right' },
                    { value: x.details.interestRate, style: 'text-right' },
                    {
                      value: x.details.repaymentContriInterest,
                      style: 'text-right',
                    },
                    {
                      value: x.details.repaymentContriPrincipal,
                      style: 'text-right',
                    },
                    { value: x.details.closingBal, style: 'text-right' },
                    { value: x.status },
                  ],
                };
              }),
              {
                cells: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  {
                    value: repaymentContri
                      ? parseFloat(`${repaymentContri}`).toFixed(2)
                      : 0,
                    style: 'font-bold text-right',
                  },
                  {
                    value: repaymentPrincipal
                      ? parseFloat(`${repaymentPrincipal}`).toFixed(2)
                      : 0,
                    style: 'font-bold text-right',
                  },
                  { value: '' },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };
}
