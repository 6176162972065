/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import {
  Revision,
  RevisionFilterType,
  RevisionType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { RevisionInterface } from '../../../../../models/interfaces/inventory/revision';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpRevisionRepo
  extends GenericHttpWithLogsRepo<Revision, RevisionFilterType>
  implements RevisionInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/revision',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async approveRevision(data: {
    lastModifiedBy: { id: string; name: string };
    id: string;
    companyGroupId: string;
    summary: {
      computedAt: string;
      downstreamVersionIncrements: {
        stockgroup: number;
        item: number;
        grade: number;
      };
      downstreamVersionSkipped: {
        stockgroup: number;
        item: number;
        grade: number;
      };
      projectedVersion: string;
      details: {
        type: RevisionType;
        id: string;
        incremented: boolean;
        version: string;
        reason: string;
      }[];
    };
  }): Promise<{ success: boolean; message: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/approve-revision`,
      data
    );

    return res.data;
  }
}
