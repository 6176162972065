/* eslint-disable no-unused-vars */
import { useEffect } from 'react';

import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import {
  renderTableWithMapperComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedGodownTransfer } from '../../../../hooks/inventory/godown-transfer/use-godown-transfer';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { MRSType, UseMRS } from '../../../../hooks/inventory/mrs/use-mrs';
import { UseCombinedBom } from '../../../../hooks/order/work-order/bom/use-bom';
import { BomIcon, BomPriorityColor } from '../bom-register/utils/bomIcons';
import { BomDetailsCard } from '../components/bom-details';
import CollectForm from './forms/collectForm';

export function renderMRSRegister({
  useMRS,
  useCombinedBatchItemGodown,
  useCombinedGodownTransfer,
  useCombinedBom,
}: {
  useMRS: UseMRS;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useCombinedGodownTransfer: UseCombinedGodownTransfer;
  useCombinedBom: UseCombinedBom;
}): () => JSX.Element {
  return function MRSRegister(): JSX.Element {
    const { data: mrsData, getAll: getAllMrs } = useMRS();
    const { set: setBom } = useCombinedBom();

    useEffect(() => {
      getAllMrs();
      // eslint-disable-next-line
    }, []);

    const Table = renderTableWithMapperComponent<MRSType, any>();

    const tableHeader: TableHeader = [
      [
        { name: 'BOM #', rowSpan: 2 },
        { name: 'MRS #', rowSpan: 2 },
        { name: 'Target', rowSpan: 2 },

        { name: 'Item', rowSpan: 2 },

        { name: 'Quantity', colSpan: 5, style: 'text-center' },

        { name: 'Status', rowSpan: 2 },
        { name: 'Action', rowSpan: 2 },
      ],
      [
        // Quantity
        { name: 'Balanced' },
        { name: 'Physically Allocated', style: 'w-10' },
        { name: 'Virtually Allocated', style: 'w-10' },
        { name: 'Issued' },
        { name: 'Total' },
      ],
    ];

    const bodyMapper: (item: MRSType) => TableBodyRow = (item: MRSType) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <Popover className='relative'>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <>
                      {BomIcon[item.bom.bomType]} {item.bom.name}
                      <ExclamationTriangleIcon
                        className={`inline w-5 ${
                          BomPriorityColor[item.bom.priority]
                        }`}
                      />
                    </>
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <BomDetailsCard
                      name={item.bom.name}
                      createdAt={moment(item.bom.createdAt).format(
                        'MMM Do YYYY'
                      )}
                      finishedItem={item.bom.item?.name}
                      quantity={`${
                        item.bom.details.finishedItem?.quantity || 0
                      } ${item.bom.details.finishedItem?.uom || 'kg'}s`}
                      engineer={item.bom.engineer.name}
                      officer={item.bom.officer.name}
                      link={`https://localhost.com/profile/${item.bom.id}`}
                    />
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ),
        },
        {
          value: '220718-1',
        },
        { value: moment(item.bom.targetDate).format('MMM Do YYYY') },
        { value: item.bom.rawItem.name },
        // TODO: issued QTy pending to be subtracted.
        {
          value:
            item.bom.details.rawMaterial.quantity -
            item.physicalStock -
            item.virtualStock,
          style: 'text-center',
        },
        { value: item.physicalStock, style: 'text-center' }, // Physically Allocated
        { value: item.virtualStock, style: 'text-center' }, // Virtually Allocated
        { value: item.issuedStock, style: 'text-center' }, // TODO: Map to proper values "Issued Quantity"
        {
          value: `${item.bom.details.rawMaterial?.quantity} ${item.bom.details.rawMaterial?.uom}`,
        },

        { value: `${item.bom.details.status || ''}` },
      ];

      return {
        cells,
        rowData: {
          item,
        },
      };
    };

    // const filter: Filter<any> = {
    //   filterFields:[
    //   ],
    //   // eslint-disable-next-line
    //   filterMapper: (filterSelection: any) => {
    //     const filterData: any = { };
    //     return filterData;
    //   }
    // }

    return (
      <div>
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          type={{
            type: 'simple',
            useResources: useMRS,
          }}
          actions={[
            {
              name: 'Notify to collect',
              show: ({ item }) => {
                if (item.physicalStock > 0) {
                  return true;
                }

                return false;
              },
              behaviour: 'modal',
              modal: {
                size: 'large',
                title: 'Notify To Collect',
                content: ({ data: { item }, onClose }) => {
                  const activeMrs = mrsData?.find(
                    (m) => m.bom.id === item.bom.id
                  ) as MRSType;

                  const Form = renderFormV2({
                    formName: 'Send Notification for collecting items',
                    onSubmit: async (form) => {
                      if (form.email) {
                        const updatedData = ({
                          id: activeMrs.bom.id,
                          status: 'ready-to-collect',
                          details: { status: 'ready-to-collect' },
                          extra: { notifyToUserEmail: form.email },
                        } as unknown) as BOMRes;
                        setBom(updatedData);
                        onClose();
                      }
                    },
                    fieldsData: [
                      {
                        type: 'email',
                        property: 'email',
                        required: true,
                        label: 'Enter email',
                      },
                    ],
                    initialFormState: {
                      email: '',
                    },
                    mapTToU: (f) => f,
                  });

                  return <Form />;
                },
              },
            },
            {
              name: 'Collect',
              show: ({ item }) => {
                if (item.physicalStock > 0) {
                  return true;
                }

                return false;
              },
              behaviour: 'modal',
              modal: {
                size: 'large',
                title: 'Collect',
                content: ({ data: { item }, onClose }) => {
                  const activeMrs = mrsData?.find(
                    (m) => m.bom.id === item.bom.id
                  ) as MRSType;

                  return (
                    <CollectForm
                      formData={activeMrs}
                      onCancel={onClose}
                      onSave={onClose}
                      useCombinedBatchItemGodown={useCombinedBatchItemGodown}
                      useCombinedGodownTransfer={useCombinedGodownTransfer}
                    />
                  );
                },
              },
            },
          ]}
        />
      </div>
    );
  };
}
