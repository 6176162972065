import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedTemplate } from '../../../../../hooks/admin/communication-template/use-template';
import { UseCombinedUser } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedQuestionnaireQuestion } from '../../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedBatchItemGodown } from '../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedPurchaseOrderFulfilment } from '../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UseCombinedPurchaseOrder } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderPurchaseOrderProfile } from './po-flow';
import { renderPurchaseOrderFulfilmentDetails } from './purchase-fulfillment-details';

export type RenderPODetailsProp = {
  hooks: {
    useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
    useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
    useCombinedUser: UseCombinedUser;
    useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
    useFileTransfer: UseFileTransfer;
    useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
    useCombinedTemplate: UseCombinedTemplate;
  };
  services: {
    itemService: ItemInterface;
  };
};

export function renderPurchaseOrderDetails({
  hooks: {
    useCombinedPurchaseOrder,
    useCombinedUser,
    useCombinedPurchaseOrderFulfilment,
    useFileTransfer,
    useCombinedQuestionnaireQuestion,
    useCombinedTemplate,
  },
  services: { itemService },
}: RenderPODetailsProp) {
  return function PODetails(): JSX.Element {
    const { id } = useParams();
    const { resource: data, get: getOrder } = useCombinedPurchaseOrder();
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getOrder(id);
      }
      // getCgs();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data?.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            Status:{' '}
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {data?.status || 'OPEN'}
            </span>{' '}
            Item:{' '}
            <a
              className='font-bold'
              href={`/inventory/masters/items/${data?.purchaseRequisition?.itemDetails?.id}/properties`}
              target='_blank'
              rel='noreferrer'
            >
              {(data?.purchaseEnquiry?.purchaseRequisitions &&
                data?.purchaseEnquiry?.purchaseRequisitions[0]?.itemDetails
                  ?.name) ||
                data?.purchaseRequisition?.itemDetails?.name}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; Quantity:{' '}
            <b>
              {(data?.purchaseEnquiry?.purchaseRequisitions &&
                data?.purchaseEnquiry?.purchaseRequisitions[0]?.details
                  ?.quantity) ||
                data?.purchaseRequisition?.details?.quantity ||
                '??'}{' '}
              {data?.purchaseRequisition?.itemDetails?.uom}
            </b>
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${data?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {data?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(data?.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const FulFillment = renderPurchaseOrderFulfilmentDetails({
      useCombinedPurchaseOrderFulfilment,
      useCombinedPurchaseOrder,
      itemService,
      useFileTransfer,
      useCombinedQuestionnaireQuestion,
      useCombinedTemplate,
      useCombinedUser,
    });

    const newCategories = [
      {
        name: 'details',
        Fn: renderPurchaseOrderProfile({
          id,
          useCombinedPurchaseOrder,
        }),
      },
      {
        name: 'fulfilments',
        Fn: () => {
          // if (!cgs || !data) return <div />;
          return data ? <FulFillment purchaseOrder={data} /> : <></>;
        },
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
