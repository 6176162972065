import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useState } from 'react';

export type TagType = {
  id: number;
  name: string;
};

const tags = [
  { id: 1, name: 'tag-1' },
  { id: 2, name: 'tag-2' },
  { id: 3, name: 'tag-3' },
  { id: 4, name: 'tag-4' },
];

type MultiSelectProps = {
  // eslint-disable-next-line no-unused-vars
  updateTag: (tag: Array<TagType>) => void;
  incomingTags: Array<TagType>;
};

export function GodownMultiSelectBox({
  updateTag,
  incomingTags,
}: MultiSelectProps): JSX.Element {
  const [selectedTags, setSelectedTags] = useState<Array<TagType>>(
    incomingTags
  );
  const [query, setQuery] = useState('');
  const filteredTags =
    query === ''
      ? tags
      : tags.filter((tag) =>
          tag.name.toLowerCase().includes(query.toLowerCase())
        );

  const selectTag = (value) => {
    setSelectedTags(value);
  };

  return (
    <Combobox value={selectedTags} onChange={selectTag} multiple>
      <>
        {selectedTags.map((tag, idx) => (
          <span
            key={idx}
            className='border rounded-md border-zinc-400 bg-zinc-200 px-2 mx-1 shadow hover:bg-blue-200'
          >
            {tag.name}
          </span>
        ))}

        <CheckIcon
          className='h-6 w-6 inline-block text-green-300 cursor-pointer'
          onClick={() => updateTag(selectedTags)}
        />
        <XMarkIcon
          className='h-6 w-6 inline-block text-red-500 cursor-pointer'
          onClick={() => updateTag(incomingTags)}
        />

        <div className='w-full flex justify-between items-center m-1'>
          <Combobox.Input
            onChange={(e) => setQuery(e.target.value)}
            className='flex-1 p-2 box-border border border-zinc-200'
            displayValue={(tag: TagType) => tag.name}
          />
        </div>

        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options>
            {filteredTags.length === 0 && query !== '' ? (
              <div className='relative cursor-pointer select-none py-2 px-4 text-red-300 active: bg-blue-400'>
                <Combobox.Option value={{ id: null, name: query }}>
                  Not Found! Create new tag as {query}
                </Combobox.Option>
              </div>
            ) : (
              filteredTags.map((tag) => (
                <Combobox.Option
                  key={tag.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-blue-400 text-white' : 'text-gray-900'
                    }`
                  }
                  value={tag}
                  onSelect={selectTag}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {tag.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-blue-400'
                          }`}
                        >
                          <CheckIcon className='h-5 w-5' aria-hidden='true' />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </>
    </Combobox>
  );
}
