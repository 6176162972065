import {
  EmployeeProfileType,
  LoanType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLoan } from '../../../../hooks/loans/loan/use-loan';
import { createLoanReportsModal } from './create-loan-reports';

type ViewCurrMonthDisbursementsProps = {
  useCombinedLoan: UseCombinedLoan;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCurrentUserRoles: UseCurrentUserRoles;
};

export function ViewCurrMonthDisbursementsForm({
  useCombinedLoan,
  useCombinedEmployeeProfile,
  useCurrentUserRoles,
}: ViewCurrMonthDisbursementsProps): () => JSX.Element {
  return function ViewCurrMonthDisbursements(): JSX.Element {
    const {
      getAll: getAllEmployees,
      list: emps,
    } = useCombinedEmployeeProfile();
    const { list: loans, getAll: getAllLoans } = useCombinedLoan();

    useEffect(() => {}, [loans]);

    useEffect(() => {
      getAllEmployees();
      getAllLoans({
        status: 'notify-to-disburse',
        createdAt: moment().format('YYYY-MM'),
      });
      // eslint-disable-next-line
    }, []);

    if (!loans) return <></>;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: '',
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            modal: {
              title: 'Download',
              content: ({ onClose }) => {
                const Card = createLoanReportsModal();
                return (
                  <Card
                    data={
                      loans &&
                      (loans as LoanType[]).map((loan) => {
                        return {
                          employee: emps?.find(
                            (q) =>
                              q.id.toLowerCase() ===
                              loan?.employee?.id.toLowerCase()
                          ) as EmployeeProfileType,
                          resource: loan,
                        };
                      })
                    }
                  />
                );
              },
            },
            name: 'Download',
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee Name' },
        { name: 'Loan Id' },
        { name: 'Amount' },
        { name: 'Rate of Interest %' },
        { name: 'Tenure (in Months)' },
        { name: 'Status' },
      ],
    ];

    const tableData =
      loans?.map((loan, idx) => ({
        cells: [
          { value: loan.employee.name },
          { value: loan.name },
          { value: loan.amount },
          { value: loan.tenure },
          { value: loan.rateOfInterest },
          { value: loan.status },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
          <Card header={cardHeader} body={cardBody} />
        </div>
      </>
    );
  };
}
