/* eslint-disable class-methods-use-this */
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import { decodeToken, isExpired } from 'react-jwt';
import { ErpV2Api } from '../models/interfaces/adapters/erp-v2-api';
import {
  getRefreshToken,
  getUserToken,
  resetUserToken,
  setUserToken,
  UserToken,
} from '../utils/token-helper';

export class ErpV2ApiAdapter implements ErpV2Api {
  adminApiUrl: string;
  refreshToken: string;
  jwtToken: string;

  constructor({ adminApiUrl }: { adminApiUrl: string }) {
    this.adminApiUrl = adminApiUrl;
    this.refreshToken = getRefreshToken();
    this.jwtToken = getUserToken();

    setInterval(() => {
      if (this.refreshToken && this.jwtToken) {
        const currentTime = moment();
        const expiry = (decodeToken(this.jwtToken) as any).exp;
        if (moment(expiry * 1000).diff(currentTime, 'minute') < 10) {
          console.log(
            'token expiring in ',
            moment(expiry * 1000).diff(currentTime, 'minute')
          );
          console.log('refreshing token...');
          this.callRefreshToken({
            refreshToken: this.refreshToken,
            token: this.jwtToken,
          });
        }
      } else {
        this.refreshToken = getRefreshToken();
        this.jwtToken = getUserToken();
      }
    }, 60000);
  }

  async getPlainInstance(data: { apiUrl: string }): Promise<AxiosInstance> {
    return axios.create({
      baseURL: data.apiUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
  }

  async getAuthorizedInstance(data: {
    apiUrl: string;
  }): Promise<AxiosInstance> {
    const token = await this.getToken();
    const currCompanyId = this.getCurrentCompany();
    const currCompanyGroupId = this.getCurrentCompanyGroup();
    const currLocationId = this.getCurrentLocation();

    return axios.create({
      baseURL: data.apiUrl,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'company-group-id': currCompanyGroupId,
        'company-id': currCompanyId,
        'location-id': currLocationId,
        'x-access-token': token,
      },
    });
  }

  private getCurrentCompanyGroup(): string {
    const localData = localStorage.getItem('current-company-group');
    let currCompanyGroup: string = '';
    if (localData) {
      const company = JSON.parse(localData) as { id: string; name: string };
      currCompanyGroup = company.id;
    }

    return currCompanyGroup;
  }

  private getCurrentLocation(): string {
    const localData = localStorage.getItem('currentLocation');
    let currLocation: string = '';
    if (localData) {
      const location = JSON.parse(localData) as { id: string; name: string };
      currLocation = location.id;
    }

    return currLocation;
  }

  private getCurrentCompany(): string {
    const localData = localStorage.getItem('currentCompany');
    let currCompanyId: string = '';
    if (localData) {
      const company = JSON.parse(localData) as Company;
      currCompanyId = company.id;
    }

    return currCompanyId;
  }

  private async callRefreshToken({
    refreshToken,
    token,
  }: {
    refreshToken: string;
    token: string;
  }) {
    const httpInstance = await this.getPlainInstance({
      apiUrl: this.adminApiUrl,
    });

    const payload = {
      refreshToken,
      email: decodeToken<UserToken>(refreshToken)?.user?.email,
      companyGroupId: decodeToken<UserToken>(token)?.companyGroup.id,
    };
    const res = await httpInstance.post('/api/v1/login/refresh-token', payload);

    if (res.status === 200) {
      const { data } = res;
      setUserToken(data.token, data.refreshToken);
      this.jwtToken = data.token;
      this.refreshToken = data.refreshToken;
      return data.token;
    }

    throw new Error('refresh token is invalid or expired for the user');
  }

  private async getToken(): Promise<string> {
    const token = this.jwtToken; //getUserToken();

    if (!token) {
      // TODO: This should happen via hook (after react router 5 is added)
      // console.log('user not logged in, Redirect to login screen');
      const path = window.location.pathname.split('?')[0];
      if (
        ![
          '/login',
          '/set-password/',
          '/reset-password',
          '/icons',
          '/accordion',
        ].includes(path)
      ) {
        const redirectUrl = window.location.pathname;
        localStorage.setItem('redirect-url', redirectUrl);
        window.location.href = `${window.location.origin}/login`;
      } else {
        return '';
      }
      throw new Error('user not logged in, Redirect to login screen');
    }

    if (!isExpired(token)) {
      return token;
    }

    // If expired token then check if the refresh token is expired or not.
    const refreshToken = this.refreshToken; // getRefreshToken();

    if (!refreshToken) {
      resetUserToken();
      // TODO: This should happen via hook (after react router 5 is added)
      if (window.location.pathname !== '/login') {
        window.location.href = `${window.location.origin}/login`;
      }
      console.log(
        'user not logged in nor has refresh token, Redirect to login screen'
      );
      throw new Error(
        'user not logged in nor has refresh token, Redirect to login screen'
      );
    }

    if (isExpired(refreshToken)) {
      resetUserToken();
      // TODO: This should happen via hook (after react router 5 is added)
      console.log('refresh token expired, Redirect to login screen');

      if (window.location.pathname !== '/login') {
        window.location.href = `${window.location.origin}/login`;
      }
      throw new Error('refresh token expired, Redirect to login screen');
    }

    return this.callRefreshToken({ refreshToken, token });
  }
}
