import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';

// eslint-disable-next-line
export type BulkInsuranceFormType = {
  fromMonthYear: string;
  toMonthYear: string;
  amount: number;
  insuranceCompanyName: string;
  policyNo: string;
};

export function renderAddBulkInsuranceForm(): (data: any) => JSX.Element {
  return function AddBulkInsuranceForm({
    onSave,
  }: {
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<Partial<BulkInsuranceFormType>> = {
      fieldsData: [
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          property: 'fromMonthYear',
          type: 'input',
          label: 'From Month-Year (YYYY-MM)',
        },
        {
          property: 'toMonthYear',
          type: 'input',
          label: 'To Month-Year (YYYY-MM)',
        },
        {
          property: 'insuranceCompanyName',
          type: 'input',
          label: 'Insurance Company',
        },
        {
          property: 'policyNo',
          type: 'input',
          label: 'Policy Number',
        },
      ],
      initialFormState: {
        amount: 0,
        fromMonthYear: moment().format('YYYY-MM'),
        toMonthYear: moment().format('YYYY-MM'),
        insuranceCompanyName: '',
        policyNo: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<BulkInsuranceFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<BulkInsuranceFormType>>
    ): BulkInsuranceFormType {
      return (b as unknown) as BulkInsuranceFormType;
    }
  };
}
