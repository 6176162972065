import {
  BatchItemGodown,
  GradeType,
} from '@erp_core/erp-types/dist/modules/inventory';
import {
  renderTableComponent,
  TableBody,
  TableBodyRow,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { Batch } from '../../../../modules/common/fragments/batch';

export function renderVendorGodown({
  id,
  useCombinedBatchItemGodown,
}: {
  id: string;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
}) {
  const Table = renderTableComponent();
  return function VendorGodown(): JSX.Element {
    const { list: batches, getAll: getBatches } = useCombinedBatchItemGodown();
    const [info, setInfo] = useState<
      {
        item: GradeType;
        batches: { name: string; batches: BatchItemGodown[] }[];
      }[]
    >([]);

    const [tableBody, setTableBody] = useState<TableBody>([]);

    useEffect(() => {
      getBatches({
        getAll: true,
        // search?: string;
        // item_id?: string;
        godownId: id,
        // category?: string;
        // lastModifiedBy?: string;
        // reference_id?: string;
        // state?: string;
        // grade_id?: string;
        // active?: boolean;
        crossLocations: true,
        // expiry_by?: string;
        // action?: string;
        // reference_type?: string;
      });
      // eslint-disable-next-line
    }, [id]);

    useEffect(() => {
      const batchInfo: {
        item: GradeType;
        batches: {
          name: string;
          batches: BatchItemGodown[];
        }[];
      }[] = [];
      batches?.forEach((b) => {
        const bat = (b as unknown) as BatchItemGodown;
        const gradeBatch = batchInfo.find((x) => x.item.id === bat.grade.id);
        if (gradeBatch) {
          const nameBatch = gradeBatch.batches.find((x) => x.name === bat.name);
          if (nameBatch) {
            nameBatch?.batches.push(bat);
          } else {
            gradeBatch.batches.push({ name: bat.name, batches: [bat] });
          }
        } else {
          batchInfo.push({
            item: bat.grade,
            batches: [{ name: bat.name, batches: [bat] }],
          });
        }
      });

      batchInfo.forEach((b) => {
        b.batches = _.sortBy(b.batches, 'name');
      });

      setInfo(batchInfo);
    }, [batches]);

    useEffect(() => {
      const body: TableBody = [];
      info.forEach((i) => {
        let rowSpan = 1;
        if (i.batches.length > 1) {
          rowSpan = i.batches.length;
        }

        i.batches.forEach((b, idx) => {
          const row: TableBodyRow = { cells: [] };
          if (idx === 0) {
            row.cells.push({
              rowSpan: rowSpan,
              value: (
                <>
                  <div className='font-semibold'>{i.item?.name}</div>
                  <div>{i.item?.item?.name}</div>
                </>
              ),
            });
          }

          row.cells.push({
            value: b.name,
          });

          row.cells.push({
            value: (
              <div className='flex flex-wrap'>
                {b.batches?.map((x) => (
                  <div key={x.id}>
                    <Batch
                      id={x.id}
                      quantity={x.quantity}
                      name={x.name}
                      description={
                        <>
                          <div>
                            Item: {x?.grade?.name}
                            {x?.details?.bomName ? (
                              <div>{`Bom: ${(x as any).details.bomName}`}</div>
                            ) : null}
                            {x?.details?.expectedDeliveryDate ? (
                              <div className='text-xs text-gray-800'>{`Expected By: ${x?.details.expectedDeliveryDate}`}</div>
                            ) : null}
                          </div>
                        </>
                      }
                      state={(x as any).state}
                      allocated={(x as any).action === 'allocated'}
                    />
                  </div>
                ))}
              </div>
            ),
          });

          body.push(row);
        });
      });

      setTableBody(body);
    }, [info]);

    return (
      <>
        <Table
          header={[[{ name: 'Item' }, { name: 'Name' }, { name: 'Batches' }]]}
          body={tableBody}
        />
      </>
    );
  };
}
