import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CombinedAttDataType,
  LeaveAppType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedEmployeeProfile } from '../../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../../../hooks/hrd/leave/use-leave';
import { LeaveInterface } from '../../../../../../models/interfaces/hrd/leave';

type RenderLeaveProps = {
  data: any;
  onClose: () => void;
};

type RenderLeaveFormProps = {
  leaveService: LeaveInterface;
  currentUser: CurrentUserType;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeave: UseCombinedLeave;
};

export const leaveTypeList: string[] = [
  'casual-leave',
  'sick-leave',
  'privilege-leave',
  'compensatory-off',
  'unpaid-leave',
  'on-job-accident-leave',
  'layoff-leave',
];

export const OnlyFullDayDenominationLeaves: string[] = [
  'maternity-leave',
  'layoff-leave',
];

export const LeavesWithBalanceRequirement: string[] = [
  'casual-leave',
  'sick-leave',
  'privilege-leave',
  'compensatory-off',
];

export function renderRequestLeaveFormV2({
  leaveService,
  currentUser,
  useCombinedEmployeeProfile,
  useCombinedLeave,
}: RenderLeaveFormProps): (p: RenderLeaveProps) => JSX.Element {
  return function RequestLeaveV2({
    data: { attendance },
    onClose,
  }: RenderLeaveProps): JSX.Element {
    const incomingEmployee = attendance?.employee || undefined;
    const dateStart = attendance?.date || '';
    const [allowFullDay, setAllowFullDay] = useState<boolean>(true);

    const { getListSync: getLeaves } = useCombinedLeave();
    const [selectedEmployee, setSelectedEmployee] = useState<
      IdName | undefined
    >(incomingEmployee);
    const [allLeaves, setAllLeaves] = useState<LeaveAppType[]>([]);

    useEffect(() => {
      if (selectedEmployee?.id) {
        const hasPunches = (attendance as CombinedAttDataType)?.details?.punches
          ?.length;
        let onlyHalfDayPossible = false;
        if (hasPunches) {
          onlyHalfDayPossible = true;
        }
        if (
          hasPunches &&
          (attendance as CombinedAttDataType)?.details?.summary?.ignorePunches
        ) {
          onlyHalfDayPossible = false;
        }

        setAllowFullDay(!onlyHalfDayPossible);

        getLeaves({ employeeId: selectedEmployee.id, crossGroup: 'true' }).then(
          (leaves) => {
            setAllLeaves(leaves);
          }
        );
      }
      // eslint-disable-next-line
    }, [selectedEmployee]);

    const Form1 = renderFormV2({
      formName: 'Apply Leave',
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          label: 'Employee Name',
          required: true,
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect: () => {},
            filter: { crossGroup: 'true' },
          },
        },
      ],
      initialFormState: {
        employee: { id: '', name: '' },
      },
      mapTToU: (p: any) => p,
      onSubmit: async (formData) => {
        if (formData.employee.id) {
          setSelectedEmployee({
            id: formData.employee.id,
            name: formData.employee.name,
          });
        }
      },
    });

    async function validateForm(
      formData: any
    ): Promise<{ [k: string]: string }> {
      const result: { [k: string]: string } = {};
      if (
        formData.type &&
        LeavesWithBalanceRequirement.includes(formData.type) &&
        allLeaves.filter(
          (x) => x.type === formData.type && x.status === 'un-consumed'
        ).length === 0
      ) {
        result.type = `${formData.type} bank empty!`;
      }

      if (
        formData.dateStart &&
        formData.dateEnd &&
        moment(formData.dateStart).isAfter(moment(formData.dateEnd))
      ) {
        result.dateEnd = 'End Date cannot be before Start Date';
      }

      if (
        formData.duration === 'half-day' &&
        OnlyFullDayDenominationLeaves.includes(formData.type)
      ) {
        result.duration = 'Cannot select Half-day for this type';
      }

      if (
        formData.duration === 'half-day' &&
        formData.dateStart &&
        allLeaves.find(
          (x) =>
            [
              `${formData.dateStart}-first`,
              `${formData.dateStart}-second`,
            ].includes(x.name) &&
            ['redeemed', 'pending', 'approved'].includes(x.status)
        )
      ) {
        result.dateStart = 'Leave already exists on this date-range';
      }

      if (
        formData.duration === 'full-day' &&
        formData.dateStart &&
        formData.dateEnd &&
        moment(formData.dateStart).isSameOrBefore(moment(formData.dateEnd))
      ) {
        let date = moment(formData.dateStart);
        const dates: Array<string> = [];

        do {
          if (!dates.includes(date.format('YYYY-MM-DD'))) {
            dates.push(`${date.format('YYYY-MM-DD')}-first`);
            dates.push(`${date.format('YYYY-MM-DD')}-second`);
          }
          date = moment(date).add(1, 'day');
        } while (moment(date).isSameOrBefore(moment(formData.dateEnd)));

        if (
          allLeaves.find(
            (x) =>
              dates.includes(x.name) &&
              ['redeemed', 'pending', 'approved'].includes(x.status)
          )
        ) {
          result.dateStart = 'Leave already exists on this date-range';
        }

        if (
          formData.type &&
          LeavesWithBalanceRequirement.includes(formData.type) &&
          dates.length >
            allLeaves.filter(
              (x) => x.type === formData.type && x.status === 'un-consumed'
            ).length
        ) {
          result.type = `${formData.type} balance is insufficient for the date-range!`;
        }
      }

      return result;
    }

    const leaveDuration = [
      { text: 'Select', value: '' },
      { text: 'Half Day', value: 'half-day' },
    ];

    if (allowFullDay) {
      leaveDuration.push({ text: 'Full Day', value: 'full-day' });
    }

    const Form2 = renderFormV2({
      formName: `Apply Leave for ${selectedEmployee?.name}`,
      fieldsData: [
        {
          property: 'type',
          type: 'select',
          label: 'Type',
          required: true,
          options: [
            { text: 'Select', value: '' },
            ...leaveTypeList.map((lt) => {
              const requiresBalance = LeavesWithBalanceRequirement.includes(lt);
              return {
                value: lt,
                text: `${lt} - ${
                  requiresBalance
                    ? `(Balance ${
                        allLeaves.filter(
                          (x) => x.type === lt && x.status === 'un-consumed'
                        ).length / 2
                      } day)`
                    : 'No Balance required'
                }`,
              };
            }),
          ],
          validate: validateForm,
        },
        {
          property: 'duration',
          type: 'select',
          label: 'Duration',
          required: true,
          options: leaveDuration,
        },
        {
          property: 'dateStart',
          type: 'date',
          label: 'Date Start',
          required: true,
        },
        {
          property: 'dateEnd',
          type: 'date',
          label: 'End Date',
          dependentOn: (fd) => {
            if (fd.duration === 'full-day') {
              return true;
            }
            return false;
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        type: '',
        reason: '',
        duration: '',
        dateStart: dateStart,
        dateEnd: '',
      },
      mapTToU: (p: any) => p,
      onSubmit: async (formData) => {
        try {
          const sd = formData.dateStart || dateStart;
          const ed = formData.duration === 'full-day' ? formData.dateEnd : sd;

          const leaveData = {
            dateStart: sd,
            dateEnd: ed,
            duration: formData.duration,
            reason: formData.reason,
            type: formData.type,
            employee: {
              id: selectedEmployee?.id,
              name: selectedEmployee?.name,
            },
            details: {},
            appliedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
            approvedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
          } as LeaveAppType;

          const response = await leaveService.applyLeave(leaveData);
          if (response.success) {
            toast.success('Employee Leave requested successfully');
          } else {
            toast.error('Employee Leave request failed!');
          }
          onClose();
        } catch (error) {
          console.log(error);
          toast('Something went wrong');
        }
      },
      style: 'w-1/2',
    });

    if (!selectedEmployee) {
      return <Form1 />;
    }

    return (
      <div>
        <Form2 />
      </div>
    );
  };
}
