import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
import { UseCombinedLicenseContractDocType } from '../../../../hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';
import { UseCombinedLicenseContract } from '../../../../hooks/order/work-order/license-contract/use-license-contract';
import { renderLicenseContract } from './license-contract';
import { renderLicenseContractDocType } from './license-contract-doc-type';

type CreateLiscenseContractPage = {
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
  useCombinedLicenseContract: UseCombinedLicenseContract;
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
  useCombinedVendor: UseCombinedVendor;
};

export const createLiscenseContractPage = ({
  useCombinedLicenseContractDocType,
  useCombinedLicenseContract,
  useCombinedItem,
  useCombinedClient,
  useCombinedVendor,
}: CreateLiscenseContractPage) => {
  return function () {
    const LiscenseContractDocumentType = renderLicenseContractDocType({
      useCombinedLicenseContractDocType,
    });

    const LicenseContract = renderLicenseContract({
      useCombinedLicenseContract,
      useCombinedClient,
      useCombinedItem,
      useCombinedLicenseContractDocType,
      useCombinedVendor,
    });

    return (
      <div className='w-full'>
        <LiscenseContractDocumentType />
        <LicenseContract />
      </div>
    );
  };
};
