/* eslint-disable no-unused-vars */
import { CompanyGroup } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import { CompanyGroupAdminInterface } from '../../../models/interfaces/admin/company-group';

export type UseCurrentCompanyGroup = () => {
  currentCompanyGroup: CompanyGroup;
  setCompanyGroup: (s: CompanyGroup) => void;
};

export interface UseCurrentCompanyGroupOptions {
  useCompanyGroupService: () => CompanyGroupAdminInterface;
}

export function createUseCurrentCompanyGroup({
  useCompanyGroupService,
}: UseCurrentCompanyGroupOptions): UseCurrentCompanyGroup {
  return () => {
    const [currentCompanyGroup, setCurrentCompanyGroup] = useState<
      CompanyGroup
    >({} as CompanyGroup);

    const service = useCompanyGroupService();

    const apiCall = async () => {
      const com = await service.getCurrent();
      setCurrentCompanyGroup(com);
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, []);

    const setCompanyGroup = async (com: CompanyGroup) => {
      await service.setCurrent(com);
      setCurrentCompanyGroup(com);
    };

    return {
      currentCompanyGroup,
      setCompanyGroup(s) {
        setCompanyGroup(s);
      },
    };
  };
}
