import { SwitchShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  SwitchShiftSchduleInterface,
  SwitchShiftScheduleFilter,
} from '../../../../../models/interfaces/hrd/switch-shift-schedule';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class SwitchShiftScheduleHttpRepo
  extends GenericHttpWithLogsRepo<
    SwitchShiftScheduleType,
    SwitchShiftScheduleFilter
  >
  implements SwitchShiftSchduleInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/attendance/switch-shift-schedule',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async approveSwitchShift(id: string): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${id}/approve-shift-switch`,
      {}
    );

    if (res.status === 200) {
      toast.success('Switch Shift Approved Successfully');
      return;
    }
    toast.error('Switch Shift Failed');
  }
}
