import { EmailProps } from '@erp_core/erp-types/dist/modules/communication';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import axios from 'axios';
import { COMMUNICATION_SERVER_URI } from '../../../../config/server-uri';

export const sendSalarySlip = async ({
  companyGroupId,
  salarySlipData,
}: {
  companyGroupId: string;
  salarySlipData: any;
}) => {
  const { employee, currentMonth, employeeProfile } = salarySlipData;

  const instance = axios.create({
    baseURL: COMMUNICATION_SERVER_URI,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'company-group-id': companyGroupId,
      'company-id': employeeProfile.company.id,
    },
  });

  const employeeEmail =
    (employeeProfile as EmployeeProfileType).details?.contactDetails
      ?.emailAddress?.personalEmail || '';

  const emailBody: EmailProps = {
    from: 'noreply@recat.in',
    to: `${employeeEmail ? `${employeeEmail},` : ''}`,
    subject: `Salary Slip - ${currentMonth.monthName}-${currentMonth.year}`,
    text: `Salary Slip - ${currentMonth.monthName}-${currentMonth.year}`,
    html: `<div>
      <div>Hello ${employee.name},</div>
      <br />

      <div>Please find attached your salary slip for ${currentMonth.monthName} / ${currentMonth.year} </div>

      <br /><br />
      <div>Regards</div>
      <div>${employeeProfile.company.name}</div>
    </div>`,
    attachments: [
      {
        type: 'build',
        name: `${employee.name}-${currentMonth.monthName}-${currentMonth.year}.pdf`,
        template: 'F67B8697-C228-4C62-986C-743CFAA6080F',
        data: salarySlipData,
      },
    ],
  };
  const response = await instance.post('/mail', emailBody);

  return response.data?.response?.success;
};
