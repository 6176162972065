import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';

export function renderGodownTransfer(): () => JSX.Element {
  return function GodownTransfer(): JSX.Element {
    const Card = renderCardComponent();

    const header: CardHeader = {
      title: 'Godown-Transfers Daybook',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <div className='text-lg'>
          Date, Source Godown (company in case different), Destination Godown
          (depedendent on the current location), Qty, Reason, Item (Grade),
          Approver, Requested By, Receipt, Status
        </div>
      ),
    };

    return <Card header={header} body={body} />;
  };
}
