import { Node, renderTableComponent } from '@erp_core/erp-ui-components';

export type BomListProps = {
  node: Node;
};

function calculateNodeList(node: Node, res: Array<Node>) {
  res.push(node);
  node.children?.forEach((n) => {
    calculateNodeList(n, res);
  });
}

export function renderBomList(): (p: BomListProps) => JSX.Element {
  const Table = renderTableComponent();
  return function BomList({ node }: BomListProps): JSX.Element {
    const nodes: Array<Node> = [];
    calculateNodeList(node, nodes);
    return (
      <div className='py-8'>
        <Table
          header={[
            [
              { name: 'Item' },
              { name: 'Quantity' },
              { name: 'Stock' },
              { name: 'Cost Contribution %' },
              { name: 'Dependency' },
            ],
          ]}
          body={nodes.map((n) => {
            return {
              cells: [
                { value: n.title },
                { value: 'TODO' },
                { value: n.stock?.total.quantity || '0' },
                { value: 'TODO' },
                { value: 'TODO' },
              ],
            };
          })}
        />
      </div>
    );
  };
}
