import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  propType?: string;
  currentValue: string;
  validPattern: string;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

export type EditChangeReqFormType = {
  currentValue: string;
  newValue: string;
  reason: string;
};

const mapper = {
  number: 'number',
  date: 'date',
  input: 'input',
  formula: 'input',
};

export const renderEditChangeRequest = () => {
  return function EditChangeRequest({
    propType,
    currentValue,
    validPattern,
    onSave,
  }: EditChangeReqProps) {
    const formProps: FormProps<EditChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },

        {
          property: 'newValue',
          type: mapper[propType || 'input'] || 'input',
          required: true,
          label: 'New Value',
          validate: async (form) => {
            if (!validPattern) {
              return {} as any;
            }

            const pattern = new RegExp(validPattern);
            const test = pattern.test(form.newValue as string);

            if (test) {
              return {} as any;
            }

            return {
              newValue: `Pattern match invalid ${validPattern}`,
            };
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue || '',
        newValue: '',
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason,
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditChangeReqFormType>
    ): EditChangeReqFormType {
      return {
        currentValue: b.currentValue || currentValue,
        newValue: b.newValue,
        reason: b.reason,
      } as EditChangeReqFormType;
    }
  };
};
