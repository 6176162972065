/* eslint-disable react-hooks/exhaustive-deps */
import { CompanyGroupSettingDetails } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect } from 'react';
import { UseCombinedAttendanceDevice } from '../../../../../hooks/hrd/attendance-device/use-attendance-device';
import { renderAttendanceDeviceForm } from './forms';

export const renderAttendanceDevices = ({
  mode,
  useCombinedAttendanceDevice,
  refreshData,
}: {
  mode: 'view' | 'config';
  useCombinedAttendanceDevice: UseCombinedAttendanceDevice;
  refreshData: () => void;
}) => {
  return function ({ cgsDetails }: { cgsDetails: CompanyGroupSettingDetails }) {
    const { set: setDevice } = useCombinedAttendanceDevice();
    const {
      getAll: getAttendanceDevices,
      list: attendanceDevices,
    } = useCombinedAttendanceDevice();

    useEffect(() => {
      getAttendanceDevices();
    }, []);
    const handleAddAttendanceDevice = (res) => {
      setDevice({ ...res, ...{ deviceSchedule: {} } });
      refreshData();
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Status' },
        { name: 'Model' },
        { name: 'Type' },
        { name: 'Location' },
        { name: 'IP Address' },
        { name: 'Last Count' },
      ],
    ];

    const tableBody: TableBody = attendanceDevices
      ? attendanceDevices.map((d) => ({
          cells: [
            { value: d.name },
            { value: d.status },
            { value: d.model },
            { value: d.type },
            { value: d.physicalLocation },
            { value: d.ipAddress },
            { value: d.lastKnownPunchCount },
          ],
        }))
      : [];
    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: 'Attendance devices',
      actions: [
        {
          type: 'button',
          show: () => mode === 'config',
          button: {
            name: 'add',
            behaviour: 'modal',
            modal: {
              title: 'Add Attendance device',
              size: 'large',
              content: ({ onClose }) => {
                const keys = _.keys(
                  cgsDetails.companyGroup?.location?.sisterLocations || []
                );
                const formData = {
                  locations: keys.map((k) => ({ text: k, value: k })),
                };
                const Form = renderAttendanceDeviceForm();
                return (
                  <Form
                    data={formData}
                    onSave={async (form) => {
                      await handleAddAttendanceDevice(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
