import moment from 'moment';

export const calculateDateRange = (startDate, endDate) => {
  let date: any = [];
  while (moment(startDate) <= moment(endDate)) {
    date.push(startDate);
    startDate = moment(startDate).add(1, 'days').format('YYYY-MM-DD');
  }
  return date;
};
