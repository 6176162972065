import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';

export type ContactDetails = {
  mobile1: string;
  mobile2: string;
  personalEmail: string;
  officeEmail: string;
  departmentEmail: string;
  permanentAddressLine1: string;
  permanentAddressLine2: string;
  permanentAddressCity: string;
  permanentAddressPincode: string;
  permanentAddressDistrict: string;
  permanentAddressState: string;
  permanentAddressCountry: string;

  currentAddressLine1: string;
  currentAddressLine2: string;
  currentAddressCity: string;
  currentAddressPincode: string;
  currentAddressDistrict: string;
  currentAddressState: string;
  currentAddressCountry: string;
};

// eslint-disable-next-line
export function renderEditContactDetailsForm(): (data: any) => JSX.Element {
  return function EditContactDetailForm({
    data,
    onSave,
  }: {
    data: ContactDetails;
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<ContactDetails> = {
      fieldsData: [
        {
          property: 'mobile1',
          type: 'input',
          label: 'Mobile 1',
          groupName: 'Contact',
        },
        {
          property: 'mobile2',
          type: 'input',
          label: 'Mobile 2',
          groupName: 'Contact',
        },
        {
          property: 'personalEmail',
          type: 'input',
          label: 'Personal Email',
          groupName: 'Contact',
        },
        {
          property: 'officeEmail',
          type: 'input',
          label: 'Office Email',
          groupName: 'Contact',
        },
        {
          property: 'departmentEmail',
          type: 'input',
          label: 'Department Email',
          groupName: 'Contact',
        },

        {
          property: 'permanentAddressLine1',
          type: 'input',
          label: 'Address Line 1',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressLine2',
          type: 'input',
          label: 'Address Line 2',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressCity',
          type: 'input',
          label: 'Address City',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressPincode',
          type: 'input',
          label: 'Address Pincode',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressDistrict',
          type: 'input',
          label: 'Address District',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressState',
          type: 'input',
          label: 'Address State',
          groupName: 'Permanent Address',
        },
        {
          property: 'permanentAddressCountry',
          type: 'input',
          label: 'Address Country',
          groupName: 'Permanent Address',
        },

        {
          property: 'currentAddressLine1',
          type: 'input',
          label: 'Address Line 1',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressLine2',
          type: 'input',
          label: 'Address Line 2',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressCity',
          type: 'input',
          label: 'Address City',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressPincode',
          type: 'input',
          label: 'Address Pincode',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressDistrict',
          type: 'input',
          label: 'Address District',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressState',
          type: 'input',
          label: 'Address State',
          groupName: 'Current Address',
        },
        {
          property: 'currentAddressCountry',
          type: 'input',
          label: 'Address Country',
          groupName: 'Current Address',
        },
      ],
      initialFormState: data,
      onSubmit: onSave,
      mapTToU: (x) => {
        return x as any;
      },
      style: 'md:w-1/2',
      allowUnchanged: true,
    };

    const Form = renderFormV2<ContactDetails>(formProps);

    return <Form />;
  };
}
