/* eslint-disable no-unused-vars */
import { VendorInterface } from 'models/interfaces/order/purchase/vendors';

export type UseVendorItem = () => {
  deleteVendorItem(id: string): Promise<void>;
};

type CreateUseVendor = {
  vendorService: VendorInterface;
};

export function createUseVendorItem({
  vendorService,
}: CreateUseVendor): UseVendorItem {
  return () => {
    const deleteVendorItem = async (id: string) => {
      vendorService.deleteVendorItem(id);
    };
    return {
      deleteVendorItem,
    };
  };
}
