import axios from 'axios';
// import { getJwtToken } from '../../../../utils/cookie-utils';

// const token = getJwtToken();

const CustomAxios = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Authorization': `Bearer ${getJwtToken()}`
  },
});

export default CustomAxios;
