import { LoadingButton } from '@erp_core/erp-ui-components';
import { QuestionType } from 'models/types/questionnairetopic/question';
import { useState } from 'react';

export function DependentQuestion({
  id,
  question,
  currentQuestion,
  orderNo,
  questionList,
  dependentQuestionsAnswers,
  dependentQuestionId,
  saveQuestion,
}: {
  id: string;
  orderNo: number;
  question: QuestionType;
  currentQuestion: string;
  questionList: Array<QuestionType>;
  dependentQuestionId: string;
  dependentQuestionsAnswers: string;
  saveQuestion: (data: QuestionType) => Promise<void>;
}): JSX.Element {
  const [questionId, setQuestionId] = useState<string>(
    question.details.dependentQuestionId || ''
  );
  const [dependentQuestionsAnswer, setDependentQuestionsAnswer] = useState<{
    svalue: string;
  }>({ svalue: question.details.dependentQuestionsAnswer || '' });
  return (
    <div className='border-gray-800 p-4 mx-16 space-y-4'>
      <div>
        {' '}
        <label className='block text-gray-700 text-sm font-bold mb-8 text-center'>
          Dependent Question <br />
        </label>
        <label className='block text-gray-700 text-sm font-bold text-left'>
          Current Question <br />
        </label>
        <label className='block text-gray-700 text-sm text-left'>
          {currentQuestion}
        </label>
      </div>

      <div>
        <label className='block text-gray-700 text-sm font-bold text-left'>
          Show When <br />
        </label>
        {
          <select
            className='border p-2 w-2/4 border-l border-black text-center'
            value={questionId}
            onChange={(evt) => {
              setQuestionId(evt.target.value);
            }}
          >
            <option value=''>Select</option>
            {questionList.map((que, idx) => {
              return que.orderNo < orderNo ? (
                <option key={idx} value={que.id}>
                  {que.name}
                </option>
              ) : null;
            })}
          </select>
        }
        <div>
          {' '}
          <br />{' '}
          <label className='block text-gray-700 text-sm font-bold text-left'>
            Equals
            <br />
          </label>
          <input
            className='w-100 border border-gray-200'
            type='text'
            value={dependentQuestionsAnswer.svalue}
            onChange={(e) => {
              setDependentQuestionsAnswer({ svalue: e.target.value });
            }}
          />
        </div>
      </div>
      <div className='my-2 mx-auto'>
        <LoadingButton
          defaultStyle='bg-green-500 text-white p-2'
          behaviorFn={async () => {
            await saveQuestion({
              id,
              details: {
                dependentQuestionId: questionId,
                dependentQuestionsAnswer: dependentQuestionsAnswer.svalue,
              },
            } as QuestionType);
          }}
          text='Save'
        />
      </div>
    </div>
  );
}
