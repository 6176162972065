import { Chart, registerables } from 'chart.js';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { config } from './config/app-config';
import './index.css';

import { App } from './App';
import * as serviceWorker from './serviceWorker';

Chart.register(...registerables);

const CurrentContextProvider = config.providers.location;
const UserProvider = config.providers.user;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserProvider useCurrentUser={config.hooks.useCurrentUser}>
      <CurrentContextProvider
        useCurrentLocation={config.hooks.useCurrentLocation}
        useCurrentCompany={config.hooks.useCurrentCompany}
        useCurrentCompanyGroup={config.hooks.useCurrentCompanyGroup}
        useCombinedCompanyGroupSetting={
          config.hooks.useCombinedCompanyGroupSetting
        }
      >
        <BrowserRouter>
          <App config={config} />
        </BrowserRouter>
      </CurrentContextProvider>
    </UserProvider>
  </React.StrictMode>
);

serviceWorker.unregister();
