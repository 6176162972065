/* eslint-disable react-hooks/exhaustive-deps */

import {
  ConstantsFilterType,
  MetricsType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { renderAddMetricsForm } from './form/add-metrics-form';

export type RenderMetricsProps = {
  useCombinedMetric: UseCombinedMetric;
};

export function renderMetrics({
  useCombinedMetric,
}: RenderMetricsProps): () => JSX.Element {
  return function Metrics(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      MetricsType,
      ConstantsFilterType
    >();
    const { syncSet: setMetrics } = useCombinedMetric();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Metrics',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Metrics',
            behaviour: 'modal',
            modal: {
              title: 'Add Metrics',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      saveCurrency(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddMetricsForm();

    const saveCurrency = async (form: Partial<MetricsType>) => {
      try {
        const finalData = {
          ...form,
        } as MetricsType;
        await setMetrics(finalData as MetricsType);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Name' }, { name: 'Short-Form' }, { name: 'Icon' }],
    ];

    const bodyMapper = (r: MetricsType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.symbol || '' },
        { value: r.icon },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                type: 'paginated',
                usePaginatedResources: useCombinedMetric,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
