import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';

export const ccCategories: Array<IdName> = [
  { id: '1', name: 'SOP' },
  { id: '2', name: 'Format or Record' },
  {
    id: '3',
    name:
      'Specification of raw materials/packing materials,intermediates & finished goods',
  },
  { id: '4', name: 'Analytical Procedures / Observation sheets' },
  { id: '5', name: 'BPCR/BBR/BPR' },
  { id: '6', name: 'Labeling & packaging' },
  { id: '7', name: 'Equipment/ Instrument' },
  { id: '8', name: 'Facility (Building/Layout)' },
  { id: '9', name: 'Process' },
  { id: '10', name: 'Utilities' },
  { id: '11', name: 'Vendors' },
  { id: '12', name: 'EHS' },
  { id: '13', name: 'If other,specify ' },
];
