import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedUser } from '../../../../../../../hooks/admin/user-admin/use-users-admin';

type Props = {
  onSave: (data: { inspectors: IdName[] }) => Promise<void>;
  itemData?: ItemRes;
};

export const renderPoInspectionForm = ({
  useCombinedUser,
}: {
  useCombinedUser: UseCombinedUser;
}) => {
  return function ({ onSave, itemData }: Props) {
    const Form = renderFormV2<{ inspectors: IdName[] }>({
      fieldsData: [
        {
          type: 'searchable-multi-select',
          property: 'inspectors',
          label: 'Select Inspectors',
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: () => {},
          },
        },
      ],
      allowUnchanged: true,
      onSubmit: async (f) => {
        await onSave(f);
      },
      mapTToU: (t) => {
        return t as any;
      },
      initialFormState: {
        inspectors: [...(itemData?.details?.profile?.inspectors || [])],
      },
    });
    return <Form />;
  };
};
