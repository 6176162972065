import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { AttendanceType, Punch } from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';
import toast from 'react-hot-toast';
import { AttendanceFilter } from '../../../../models/interfaces/hrd/attendance';
import { addPunch } from './add-manual-punch';

export async function movePunch({
  d,
  p,
  direction,
  date,
  getAllAttendanceSync,
  currentUser,
  setAttendance,
  getData,
  onClose,
}: {
  d: AttendanceType;
  p: Punch;
  date: string;
  direction: 'prev' | 'next';
  currentUser: CurrentUserType;
  getAllAttendanceSync: (
    filter?: AttendanceFilter | undefined,
    o?: any
  ) => Promise<AttendanceType[]>;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  onClose: () => void;
}) {
  const targetDate =
    direction === 'next'
      ? moment(date).add('days', 1).format('YYYY-MM-DD')
      : moment(date).subtract('days', 1).format('YYYY-MM-DD');
  const atts = await getAllAttendanceSync({ date: targetDate }); // TODO add filter by employee id;
  const att = atts.find((f) => f.employee.id === d.employee.id);

  if (att?.finalized) {
    toast.error(
      'Move punch not possible. The target date is already finalized!'
    );
    return;
  }

  // add attendance punch in neighbored days
  if (!att) {
    // If attendance not fond we have to create one
    const attendance = ({
      name: targetDate,
      date: targetDate,
    } as unknown) as AttendanceType;

    const punch = {
      type: p.action as any,
      date: p.timeStamp,
      employee: d.employee,
      createdBy: p.createdBy || { id: currentUser.id, name: currentUser.name },
      location: p.location,
      reason: '',
    };

    await addPunch({
      attendance,
      mp: punch,
      type: p.type,
      currentUser,
      date,
      setAttendance,
      getData,
      onClose: () => {
        onClose();
      },
    });
  } else {
    att.details = {
      ...att.details,
      punches: [...(att.details?.punches || [])],
    };
    await addPunch({
      attendance: att,
      mp: {
        type: p.action as any,
        date: p.timeStamp,
        employee: att.employee,
        location: p.location,
        createdBy: p.createdBy || {
          id: currentUser.id,
          name: currentUser.name,
        },
        reason: '',
      },
      type: p.type,
      currentUser,
      date,
      setAttendance,
      getData,
      onClose: () => {
        onClose();
      },
    });
  }

  // remove attendance from current day
  const finalData: AttendanceType = {
    id: d.id,
    details: d.details,
  } as AttendanceType;

  const index = finalData.details?.punches.findIndex(
    (f) => f.timeStamp === p.timeStamp
  );

  if (index !== undefined) {
    finalData.details?.punches.splice(index, 1);
  }
  await setAttendance(finalData);
  getData();
}
