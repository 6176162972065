import { FileTransferInterface } from '../../models/interfaces/fileTransfer/fileTransfer';

export class FileTransferService implements FileTransferInterface {
  fileTransferRepo: FileTransferInterface;

  constructor(fileTransferRepo: FileTransferInterface) {
    this.fileTransferRepo = fileTransferRepo;
  }

  set(
    id: string,
    data: any,
    ext: string
  ): Promise<{ success: boolean; url: string }> {
    return this.fileTransferRepo.set(id, data, ext);
  }

  publicSet(id: string, data: any): Promise<{ success: boolean; url: string }> {
    return this.fileTransferRepo.publicSet(id, data);
  }

  get(id: string, returnBase64?: boolean): Promise<string> {
    return this.fileTransferRepo.get(id, returnBase64);
  }

  publicGet(id: string, returnBase64?: boolean): Promise<string> {
    return this.fileTransferRepo.publicGet(id, returnBase64);
  }

  list(id: string): Promise<any> {
    return this.fileTransferRepo.list(id);
  }
}
