/* eslint-disable no-unused-vars */
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import { CompanyAdminInterface } from '../../../models/interfaces/admin/company-admin';
import { CompanyGroupLocalStorage } from '../../../utils/token-helper';
import { UseCurrentCompanyGroup } from '../company-group-admin/use-current-company-group';
import { UseCurrentUser } from '../user-admin/use-current-user';

export type UseCurrentCompany = () => {
  data: Company;
  setCurrentCompany: (s: Company) => void;
};

export interface UseCurrentCompanyOptions {
  useCompanyAdminService: () => CompanyAdminInterface;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentUser: UseCurrentUser;
}

export function createUseCurrentCompany({
  useCompanyAdminService,
  useCurrentCompanyGroup,
  useCurrentUser,
}: UseCurrentCompanyOptions): UseCurrentCompany {
  return () => {
    const [currentCompany, setCurrentCompany] = useState<Company>(
      {} as Company
    );
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const { data: currentUser } = useCurrentUser();
    const companyService = useCompanyAdminService();
    const apiCall = async () => {
      const com = await companyService.getCurrent();
      const comLocal = new CompanyGroupLocalStorage();

      if (com?.id) {
        setCurrentCompany(com);
      } else {
        if (!currentCompany?.id || !currentCompanyGroup?.id || !currentUser?.id)
          return;
        const companies = (await companyService.getAll({
          getAll: true,
        })) as Company[];
        if (companies.length > 0) {
          const companyId =
            comLocal.get()?.[currentUser.id]?.[currentCompanyGroup.id]
              ?.currentCompany?.id || '';
          const company = companies.find((l) => l.id === companyId);
          setCurrentCompany(company || companies[0]); // Persisting in local storage
          companyService.setCurrent(company || companies[0]); // Setting state
        }
      }
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, []);

    const setCompany = async (com: Company) => {
      await companyService.setCurrent(com);
      setCurrentCompany(com);
    };

    return {
      data: currentCompany,
      setCurrentCompany: (s: Company) => {
        setCompany(s);
      },
    };
  };
}
