import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts/user';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

export type RegularizingChangeType = {
  temporaryChangeContinue: string;
  permanentCCFId: string;
};

export function renderRegularizingChangeForm() {
  return function AddRegularizingChangeForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const formProps: FormProps<RegularizingChangeType> = {
      fieldsData: [
        {
          type: 'select',
          label: 'Do you want to this change continue?',
          property: 'temporaryChangeContinue',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'yes' },
            { value: 'no', text: 'no' },
          ],
        },
        {
          type: 'input',
          label: 'link permanent CCF No:',
          property: 'permanentCCFId',
          dependentOn: (formData) => {
            return formData?.temporaryChangeContinue === 'yes';
          },
        },
      ],
      initialFormState: {
        temporaryChangeContinue: '',
        permanentCCFId: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        // const managementComiteeComments = data?.details.managementComiteeComments
        await onSave(({
          details: {
            ...data?.details,
            temporaryChangeContinue: {
              temporaryChangeContinue: f.temporaryChangeContinue,
              permanentCCFId: f.permanentCCFId,
              changeClosedOn: moment(),
              closedBy: {
                id: currentUser.id,
                name: currentUser.name,
              },
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<RegularizingChangeType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<RegularizingChangeType>
    ): RegularizingChangeType {
      return (b as unknown) as RegularizingChangeType;
    }
  };
}
