// import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
// import {
//   BankAccountDetail,
//   Company,
// } from '@erp_core/erp-types/dist/types/modules/admin/company';
// import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
// import _ from 'lodash';
// import { ExtSal } from '../utils/statutory-reports';

// export type SRType = {
//   data: Array<{
//     salary: ExtSal;
//     employee: EmployeeProfileType;
//     bank: BankAccountDetail;
//     company: Company;
//     salRevision: SalaryRevisionType;
//     category?: string;
//   }>;
//   month: string;
// };

import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  BankAccountDetail,
  Company,
} from '@erp_core/erp-types/dist/types/modules/admin/company';
import {
  EmployeeRegistrationFilter,
  EmployeeRegistrationType,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { downloadFile } from '@erp_core/erp-ui-components';
import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { createStatutoryReports, ExtSal } from '../utils/statutory-reports';

export type SRType = {
  data: Array<{
    salary: ExtSal;
    employee: EmployeeProfileType;
    bank: BankAccountDetail;
    company: Company;
    salRevision: SalaryRevisionType;
    category?: string;
  }>;
  month: string;
};

export function createStatutoryReportsModal(): (p: {
  data: SRType;
  getRegistrations: (
    filter?: EmployeeRegistrationFilter
  ) => Promise<EmployeeRegistrationType[]>;
}) => JSX.Element {
  return function StatutoryReports({
    data: { data, month },
    getRegistrations,
  }: {
    data: SRType;
    getRegistrations: (
      filter?: EmployeeRegistrationFilter
    ) => Promise<EmployeeRegistrationType[]>;
  }): JSX.Element {
    const [reports, setReports] = useState<
      {
        name: string;
        data: {
          name: string;
          data: string;
        }[];
      }[]
    >([]);

    useEffect(() => {
      new Promise((res) => res(true)).then(async () => {
        const reports: {
          name: string;
          data: {
            name: string;
            data: string;
          }[];
        }[] = [];
        const categories: Array<{ name: string; list: SRType }> = [];
        data.forEach((x) => {
          if (
            [
              'G03',
              'G04',
              'G05',
              'G06',
              'G07',
              'G08',
              'G09',
              'G9.1',
              'G9.2',
              'G9.3',
              'G10',
              'G11',
              'G12',
              'GZ1',
              'G13',
              'G14',
              'G15',
              'G16',
              'G17',
              'G18',
              'G19',
            ].includes(x.employee.details.grade?.id)
          ) {
            let StaffAndWorkers = categories.find(
              (y) =>
                y.name ===
                `Staff-and-Workers-${x.employee.details.workLocation}`
            );
            if (!StaffAndWorkers) {
              categories.push({
                name: `Staff-and-Workers-${x.employee.details.workLocation}`,
                list: { data: [], month },
              });
              StaffAndWorkers = categories.find(
                (y) =>
                  y.name ===
                  `Staff-and-Workers-${x.employee.details.workLocation}`
              );
            }
            if (StaffAndWorkers) {
              StaffAndWorkers.list.data.push(x);
            }
          }

          if (['G20', 'G21'].includes(x.employee.details.grade?.id)) {
            let Director = categories.find(
              (y) => y.name === `Director-${x.employee.details.workLocation}`
            );
            if (!Director) {
              categories.push({
                name: `Director-${x.employee.details.workLocation}`,
                list: { data: [], month },
              });
              Director = categories.find(
                (y) => y.name === `Director-${x.employee.details.workLocation}`
              );
            }
            if (Director) {
              Director.list.data.push(x);
            }
          }

          // if (
          //   [
          //     'G07',
          //     'G08',
          //     'G09',
          //     'G9.1',
          //     'G9.2',
          //     'G9.3',
          //     'G10',
          //     'G11',
          //     'G12',
          //   ].includes(x.employee.details.grade?.id)
          // ) {
          //   let Staff = categories.find(
          //     (y) => y.name === `Staff-${x.employee.details.workLocation}`
          //   );
          //   if (!Staff) {
          //     categories.push({
          //       name: `Staff-${x.employee.details.workLocation}`,
          //       list: { data: [], month },
          //     });
          //     Staff = categories.find(
          //       (y) => y.name === `Staff-${x.employee.details.workLocation}`
          //     );
          //   }
          //   if (Staff) {
          //     Staff.list.data.push(x);
          //   }
          // }

          // if ([].includes(x.employee.details.grade?.id)) {
          //   let Worker = categories.find(
          //     (y) => y.name === `Worker-${x.employee.details.workLocation}`
          //   );
          //   if (!Worker) {
          //     categories.push({
          //       name: `Worker-${x.employee.details.workLocation}`,
          //       list: { data: [], month },
          //     });
          //     Worker = categories.find(
          //       (y) => y.name === `Worker-${x.employee.details.workLocation}`
          //     );
          //   }
          //   if (Worker) {
          //     Worker.list.data.push(x);
          //   }
          // }

          if (['G01', 'G02'].includes(x.employee.details.grade?.id)) {
            let Apprentice = categories.find(
              (y) => y.name === `Apprentice-${x.employee.details.workLocation}`
            );
            if (!Apprentice) {
              categories.push({
                name: `Apprentice-${x.employee.details.workLocation}`,
                list: { data: [], month },
              });
              Apprentice = categories.find(
                (y) =>
                  y.name === `Apprentice-${x.employee.details.workLocation}`
              );
            }
            if (Apprentice) {
              Apprentice.list.data.push(x);
            }
          }
          // if (['G03'].includes(x.employee.details.grade?.id)) {
          //   let Helpers = categories.find(
          //     (y) => y.name === `Helpers-${x.employee.details.workLocation}`
          //   );
          //   if (!Helpers) {
          //     categories.push({
          //       name: `Helpers-${x.employee.details.workLocation}`,
          //       list: { data: [], month },
          //     });
          //     Helpers = categories.find(
          //       (y) => y.name === `Helpers-${x.employee.details.workLocation}`
          //     );
          //   }
          //   if (Helpers) {
          //     Helpers.list.data.push(x);
          //   }
          // }
        });

        for (const x of categories) {
          const rep = await createStatutoryReports(
            {
              data: x.list.data,
              month: month,
            },
            x.name,
            ['bank-report', 'esic', 'epf', 'pt', 'lic', 'mlwf', 'bank-loan'],
            getRegistrations
          );

          reports.push({
            name: x.name,
            data: rep,
          });
        }

        const summaryList: {
          salary: ExtSal;
          employee: EmployeeProfileType;
          bank: BankAccountDetail;
          company: Company;
          salRevision: SalaryRevisionType;
          category?: string | undefined;
        }[] = [];

        categories.forEach((x) => {
          const summary = JSON.parse(JSON.stringify(x.list.data[0]));
          summary.category = x.name;

          x.list.data.forEach((y, idx) => {
            if (idx !== 0) {
              if (
                summary.salary.details.salaryParams.earning &&
                summary.salary.details.salaryParams.deduction
              ) {
                summary.salary.details.salaryParams.earning.grossEarnings +=
                  y.salary.details?.salaryParams?.earning?.grossEarnings || 0;
                summary.salary.details.salaryParams.deduction.employeePf +=
                  y.salary.details?.salaryParams?.deduction?.employeePf || 0;
                summary.salary.details.salaryParams.deduction.employeeEsic +=
                  y.salary.details?.salaryParams?.deduction?.employeeEsic || 0;
                summary.salary.details.salaryParams.deduction.employeePt +=
                  y.salary.details?.salaryParams?.deduction?.employeePt || 0;
                summary.salary.details.salaryParams.deduction.employeeMlwf +=
                  y.salary.details?.salaryParams?.deduction?.employeeMlwf || 0;
                summary.salary.details.salaryParams.deduction.salaryAdvanceTaken +=
                  y.salary.details?.salaryParams?.deduction
                    ?.salaryAdvanceTaken || 0;
                summary.salary.details.salaryParams.deduction.adjustedAdvances +=
                  y.salary.details?.salaryParams?.deduction?.adjustedAdvances ||
                  0;
                summary.salary.details.salaryParams.deduction.insurancePremiumDeductions +=
                  y.salary.details?.salaryParams?.deduction
                    ?.insurancePremiumDeductions || 0;
                summary.salary.details.salaryParams.deduction.loanEmiDeductions +=
                  y.salary.details?.salaryParams?.deduction
                    ?.loanEmiDeductions || 0;
                summary.salary.details.salaryParams.deduction.loanInterestEmiDeductions +=
                  y.salary.details?.salaryParams?.deduction
                    ?.loanInterestEmiDeductions || 0;
                summary.salary.details.salaryParams.deduction.incomeTaxDeduction +=
                  y.salary.details?.salaryParams?.deduction
                    ?.incomeTaxDeduction || 0;
                summary.salary.details.salaryParams.deduction.adhocDeductionsVal +=
                  y.salary.details?.salaryParams?.deduction
                    ?.adhocDeductionsVal || 0;
                summary.salary.details.salaryParams.deduction.otherDeductions +=
                  y.salary.details?.salaryParams?.deduction?.otherDeductions ||
                  0;
                summary.salary.details.salaryParams.earning.employeeTakeHome +=
                  y.salary.details?.salaryParams?.earning?.employeeTakeHome ||
                  0;
              }
            }
          });
          summaryList.push(summary);
        });

        const salSumRep = await createStatutoryReports(
          {
            data: summaryList,
            month: month,
          },
          'salary-summary',
          ['salary-summary'],
          getRegistrations
        );

        reports.push({
          name: '',
          data: salSumRep,
        });

        let ptSummaryList: {
          salary: ExtSal;
          employee: EmployeeProfileType;
          bank: BankAccountDetail;
          company: Company;
          salRevision: SalaryRevisionType;
          category?: string | undefined;
        }[] = [];

        categories.forEach((x) => {
          const innerSummaryList: {
            salary: ExtSal;
            employee: EmployeeProfileType;
            bank: BankAccountDetail;
            company: Company;
            salRevision: SalaryRevisionType;
            category?: string | undefined;
          }[] = [];

          x.list.data.forEach((y, idx) => {
            if (y.salary?.details?.salaryParams?.deduction?.employeePt) {
              let inner = innerSummaryList.find(
                (z) =>
                  z.salary?.details?.salaryParams?.deduction?.employeePt ===
                  y.salary?.details?.salaryParams?.deduction?.employeePt
              );
              if (!inner) {
                inner = JSON.parse(JSON.stringify(y));
                if (inner) {
                  inner.category = x.name;
                  inner.salary.details.salaryParams.totalPt = 0;
                  inner.salary.details.salaryParams.numberOfEmployees = 0;
                  innerSummaryList.push(inner);
                }
              }
              if (inner) {
                inner.salary.details.salaryParams.numberOfEmployees += 1;
                inner.salary.details.salaryParams.totalPt +=
                  y.salary?.details?.salaryParams?.deduction?.employeePt;
              }
            }
          });
          ptSummaryList = [...ptSummaryList, ...innerSummaryList];
        });

        const ptRep = await createStatutoryReports(
          {
            data: ptSummaryList,
            month: month,
          },
          'pt-summary',
          ['pt-summary'],
          getRegistrations
        );

        reports.push({
          name: '',
          data: ptRep,
        });

        const combinedReports: {
          salary: ExtSal;
          employee: EmployeeProfileType;
          bank: BankAccountDetail;
          company: Company;
          salRevision: SalaryRevisionType;
          category?: string | undefined;
        }[] = [];

        categories.forEach((x) => {
          x.list.data.forEach((y, idx) => {
            y.category = x.name;
            combinedReports.push(y);
          });
        });

        const combRep = await createStatutoryReports(
          {
            data: combinedReports,
            month: month,
          },
          'combined-reports',
          [
            'loans-recovery',
            'advance-recovery',
            'tds-report',
            'bonus-retentions-report',
            'retention-deductions',
          ],
          getRegistrations
        );
        reports.push({
          name: '',
          data: combRep,
        });

        setReports(reports);
      });
      // eslint-disable-next-line
    }, [data, month]);

    const fileDate = moment(month).format('YYYY-MM');

    return (
      <div>
        <div className='text-center'>Statutory Reports</div>
        <div>
          {reports.map((y, rIdx) => (
            <div
              key={y.name + rIdx}
              className='border rounded border-gray-200 p-1 m-1'
            >
              <div className='text-center'>{y.name}</div>
              {y.data.map((x, idx) => (
                <div
                  key={x.name + idx}
                  className='border border-gray-200 p-2 m-0.5'
                >
                  <div className='flex'>
                    <div className='flex-none'>
                      <DocumentTextIcon className='w-5 inline mr-2' />
                      {x.name}
                    </div>
                    <div className='flex-auto '>
                      <ArrowDownTrayIcon
                        className='cursor-pointer inline w-4 float-right'
                        onClick={() => {
                          downloadFile({
                            fileType: 'application/csv',
                            fileName: _.kebabCase(
                              `${data[0].company.shortName}-${fileDate}-${x.name}`
                            ),
                            result: x.data,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };
}
