import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';

export function renderAddBomFlow(): ({
  save,
}: {
  save: (d: any) => Promise<void>;
}) => JSX.Element {
  return function AddBomFlow({
    save,
  }: {
    save: (d: any) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<{ name: string; description: string }> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input', required: true },
        {
          property: 'description',
          label: 'Description',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        description: '',
      },
      mapTToU: (r) => {
        return r as { name: string; description: string };
      },
      onSubmit: save,
    };

    const Form = renderFormV2<{ name: string; description: string }>(formProps);

    return <Form />;
  };
}
