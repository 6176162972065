import toast from 'react-hot-toast';
import { FileTransferInterface } from '../../models/interfaces/fileTransfer/fileTransfer';

export type UsePublicTransfer = () => {
  // eslint-disable-next-line
  set(path: string, data: any, ext: string): Promise<any>;
  // eslint-disable-next-line
  get(path: string, returnBase64?: boolean): Promise<string>;
  // eslint-disable-next-line
  list(path: string): Promise<any>;
};

export type UsePublicTransferProp = {
  useFileTransferService: () => FileTransferInterface;
};

export function createUsePublicFileTransfer({
  useFileTransferService,
}: UsePublicTransferProp): UsePublicTransfer {
  return () => {
    const fileTransferService = useFileTransferService();
    const set = async (path: string, data: any) => {
      // eslint-disable-next-line
      const res = await fileTransferService.publicSet(path, data);
      if (res.success) {
        toast('File uploaded successfully');
        return res;
      }
      toast('File failed to upload');
      return;
    };

    const get = async (path: string, returnBase64?: boolean) =>
      fileTransferService.publicGet(path, returnBase64);

    const list = async (path: string) => fileTransferService.list(path);

    return {
      set: (path, data) => set(path, data),
      get: (path, returnBase64) => get(path, returnBase64),
      list: (path) => list(path),
    };
  };
}
