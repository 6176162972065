import { EmployeeRegistrationType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddEmployeeRegistrationForm() {
  return function AddEmployeeRegistrationForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: (data: EmployeeRegistrationType) => Promise<void>;
    data?: EmployeeRegistrationType;
    isEdit?: boolean;
  }): JSX.Element {
    const formProps: FormProps<Partial<EmployeeRegistrationType>> = {
      fieldsData: [
        {
          property: 'regDocumentType',
          type: 'select',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'adhar-card', value: 'adhar-card' },
            { text: 'pan-card', value: 'pan-card' },
            { text: 'passport', value: 'passport' },
            { text: 'driving-licence', value: 'driving-licence' },
            { text: 'voter-id', value: 'voter-id' },
            {
              text: 'employee-provident-fund',
              value: 'employee-provident-fund',
            },
            { text: 'esic', value: 'esic' },
            { text: 'mlwf', value: 'mlwf' },
            { text: 'gratuity-form', value: 'gratuity-form' },
            {
              text: 'previous-employer-salary-slip',
              value: 'previous-employer-salary-slip',
            },
            { text: 'resume', value: 'resume' },
            { text: 'joining-form', value: 'joining-form' },
            { text: 'offer-letter', value: 'offer-letter' },
            {
              text: 'company-policy-declaration',
              value: 'company-policy-declaration',
            },
            { text: 'confirmation-letter', value: 'confirmation-letter' },
            { text: 'salary-revision-letter', value: 'salary-revision-letter' },
            { text: 'employment-bond', value: 'employment-bond' },
            {
              text: 'contract-for-apprenticeship',
              value: 'contract-for-apprenticeship',
            },
            { text: 'other', value: 'other' },
          ],
          label: 'Document Type',
        },
        {
          property: 'regDate',
          type: 'date',
          label: 'Date Of Registration',
        },
        {
          property: 'validUpto',
          type: 'date',
          label: 'Valid UpTo',
        },
        {
          property: 'accountNo',
          type: 'input',
          required: true,
          label: 'Account Number',
        },
      ],
      initialFormState: {
        regDocumentType: data?.regDocumentType || '',
        validUpto: data?.validUpto || '',
        accountNo: data?.accountNo || '',
        regDate: data?.regDate || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          id: data?.id,
          regDocumentType: l.regDocumentType || data?.regDocumentType,
          validUpto: l.validUpto || data?.validUpto,
          accountNo: l.accountNo || data?.accountNo,
          regDate: l.regDate || data?.regDate,
        } as EmployeeRegistrationType);
      },
    };

    const Form = renderFormV2<Partial<EmployeeRegistrationType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EmployeeRegistrationType>>
    ): EmployeeRegistrationType {
      return (b as unknown) as EmployeeRegistrationType;
    }
  };
}
