import {
  ItemCategoryReq,
  ItemCategoryReqEdit,
  ItemCategoryRes,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { ItemCategoryInterface } from '../../../../models/interfaces/inventory/item-category';

export class ItemCategoryHttpRepo implements ItemCategoryInterface {
  apiUrl: string;

  erpV2Api: ErpV2Api;

  constructor(apiUrl: string, erpV2Api: ErpV2Api) {
    this.apiUrl = apiUrl;
    this.erpV2Api = erpV2Api;
  }

  async getAll(): Promise<{
    success: boolean;
    data?: ItemCategoryRes[] | undefined;
  }> {
    try {
      const httpInstance = await this.erpV2Api.getAuthorizedInstance({
        apiUrl: this.apiUrl,
      });
      const res = await httpInstance.get(`${this.apiUrl}/categories`);
      if (res.data.error) return { success: false };
      return { success: true, data: res.data };
    } catch (error) {
      return { success: false };
    }
  }

  get(
    id: string
  ): Promise<{ success: boolean; data?: ItemCategoryRes | undefined }> {
    throw new Error('Method not implemented.');
  }

  create(
    data: ItemCategoryReq
  ): Promise<{ success: boolean; data?: ItemCategoryRes | undefined }> {
    throw new Error('Method not implemented.');
  }

  edit(
    data: ItemCategoryReqEdit
  ): Promise<{ success: boolean; data?: ItemCategoryRes | undefined }> {
    throw new Error('Method not implemented.');
  }

  delete(id: string): Promise<{ success: boolean }> {
    throw new Error('Method not implemented.');
  }
}
