/* eslint-disable no-unused-vars */
import { SetPswdRequest } from '@erp_core/erp-types/dist/modules/admin';
import { SetPasswordInterface } from '../../models/interfaces/set-password';

export type UseSetPassword = () => {
  setPassword: (a: SetPswdRequest) => void;
};

export interface UseSetPasswordOptions {
  useSetPasswordService: () => SetPasswordInterface;
}

export function createUseSetPassword({
  useSetPasswordService,
}: UseSetPasswordOptions): UseSetPassword {
  return () => {
    const setPasswordService = useSetPasswordService();

    const setPasswordApiCall = async (a: SetPswdRequest) => {
      try {
        await setPasswordService.setPassword(a);
      } catch (e) {
        console.log(e);
      }
    };

    return {
      setPassword: (a: SetPswdRequest) => {
        setPasswordApiCall(a);
      },
    };
  };
}
