import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedRole } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedDashboard } from '../../../../hooks/analytics/dashboard/use-dashboard';
import { renderAddEditReport } from '../forms/add-edit-report';

export function renderDashboardModule({
  useCombinedDashboard,
  useCombinedRole,
}: {
  useCombinedDashboard: UseCombinedDashboard;
  useCombinedRole: UseCombinedRole;
}) {
  return function DashboardModule({ module }: { module: string }) {
    const { list: reports, getAll: getReports } = useCombinedDashboard();

    const Card = renderCardComponent();
    const Table = renderTableComponent();

    useEffect(() => {
      getReports();
      // eslint-disable-next-line
    }, [module]);

    return (
      <Card
        header={{
          title: <div className='capitalize'>{module} Config</div>,
        }}
        body={{
          type: 'jsx-component',
          body: (
            <Table
              header={[
                [
                  { name: 'Report Name' },
                  { name: 'Sub-Module' },
                  { name: 'Type' },
                  { name: 'Display Information' },
                  { name: 'Roles' },
                  { name: 'Status' },
                ],
              ]}
              body={(reports || [])
                .sort(
                  (a, b) =>
                    (a.details?.displaySettings?.sequence || 0) -
                    (b.details?.displaySettings?.sequence || 0)
                )
                .map((x) => {
                  return {
                    cells: [
                      {
                        value: (
                          <div>
                            <p className='font-semibold'>{x.name}</p>
                            <p>{x.description}</p>
                          </div>
                        ),
                        style: 'capitalize',
                      },
                      { value: x.subModule },
                      { value: x.type },
                      {
                        value: (
                          <div>
                            <div>
                              Sequence: {x.details?.displaySettings?.sequence}
                            </div>
                            <div>
                              RowSpan: {x.details?.displaySettings?.rowSpan}
                            </div>
                            <div>
                              ColSpan: {x.details?.displaySettings?.colSpan}
                            </div>
                          </div>
                        ),
                      },
                      {
                        value: (
                          <div>
                            {(Array.isArray(x?.roles) ? x?.roles : [])?.map(
                              (y) => (
                                <span className='border border-gray-200 mx-1'>
                                  {y.name}
                                </span>
                              )
                            )}
                          </div>
                        ),
                      },
                      { value: x.status },
                    ],
                    rowData: {
                      report: x,
                    },
                  };
                })}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit Report',
                    content: ({ data: { report }, onClose }) => {
                      const Edit = renderAddEditReport({
                        useCombinedRole,
                        useCombinedDashboard,
                      });
                      return (
                        <Edit
                          module={report.module}
                          rep={report}
                          onClose={onClose}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          ),
        }}
      />
    );
  };
}
