import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { LoadingButton } from '@erp_core/erp-ui-components';

type BulkOffSiteActionFormProps = {
  list: Array<OffSiteWorkHoursType>;
  onClose: () => void;
  setOffSiteWh: (s: OffSiteWorkHoursType) => Promise<OffSiteWorkHoursType>;
  currentUser: CurrentUserType;
  getOffSiteWorkHr: (filter?: OffSiteWorkHoursFilterType) => void;
  date: string;
};

export function BulkOffSiteActionForm({
  list,
  onClose,
  setOffSiteWh,
  currentUser,
  getOffSiteWorkHr,
  date,
}: BulkOffSiteActionFormProps): JSX.Element {
  const pendingValidnCheck = list
    .map((li) => li.status === 'approved')
    .includes(true);

  return (
    <div className='m-2 border border-gray-200 rounded-lg'>
      {pendingValidnCheck ? (
        <div className='text-center text-red-600'>
          Seems like approved entries are selected. Kindly deselect them to
          perform this action!
        </div>
      ) : (
        <>
          <div className='text-center'>
            <span className='text-gray-600 italic'>Entries: </span>
            {list.map((l, index) => (
              <div key={l.id}>
                {l.employee.name}-{l.status}-{l.date}
              </div>
            ))}
          </div>
          <div className='align-center m-1'>
            <LoadingButton
              text='Save'
              loadingStyle='text-red-600'
              behaviourParams={{}}
              behaviorFn={async () => {
                await new Promise((resolve) => {
                  setTimeout(async () => {
                    for (let i = 0; i < list.length; i++) {
                      const finalData = {
                        id: list[i].id,
                        approvedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                        status: 'approved',
                      } as OffSiteWorkHoursType;
                      await setOffSiteWh(finalData);
                    }
                    resolve('');
                  }, 1000);
                });
                onClose();
                await getOffSiteWorkHr({ date });
              }}
            ></LoadingButton>
          </div>
        </>
      )}
    </div>
  );
}
