/* eslint-disable no-unused-vars */
import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { Input, Toggle } from '@erp_core/erp-ui-components';
import {
  ArrowTopRightOnSquareIcon,
  ArrowUpTrayIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { FileTransferInterface } from '../../../../models/interfaces/fileTransfer/fileTransfer';
import { GodownMultiSelectBox, TagType } from './godown-multi-select-box';

type GodComProps = {
  godownData: Godown;
  reload: any;
};

type CreateGodownSideView = {
  useCombinedGodown: UseCombinedGodown;
  fileTransferService: FileTransferInterface;
};

/* eslint-disable arrow-body-style */
export const createGodownSideView = ({
  useCombinedGodown,
  fileTransferService,
}: CreateGodownSideView): ((props: GodComProps) => JSX.Element) => {
  return function GodownView({ godownData, reload }: GodComProps): JSX.Element {
    const { syncSet: setGodown } = useCombinedGodown();
    const [selectedTags, setSelectedTags] = useState<Array<TagType>>([]);
    const [selectedFile, setSelectedFile] = useState<any>();
    const [toggle, setToggle] = useState(false);

    const editGodown = async (field: string, value: string | boolean) => {
      const data: any = {};
      data[field] = value;
      data.id = godownData.id;
      await setGodown(data);
      reload();
    };

    const uploadImage = async () => {
      const imagePath = `${godownData.company.id}/${godownData.location.id}/inventory/godown/${godownData.id}`;
      const service = await fileTransferService.publicSet(
        imagePath,
        selectedFile
      );
      const imgURL = service.url;
      editGodown('imageUrl', `${imgURL}`);
    };
    const fileHandler = (e) => {
      setSelectedFile(e.target.files[0]);
    };

    const updateTag = async (value: Array<TagType>) => {
      setToggle(false);
      setSelectedTags(value);
      const data: any = {
        id: godownData.id,
        tags: value.map((val) => {
          return val.name;
        }),
      };

      await setGodown(data);
    };

    if (!godownData) return <div />;

    return (
      <div className='w-2/5 h-full mt-4 overflow-y-auto p-3 pb-20 relative border-2 border-zinc-200 bg-zinc-50'>
        <div className='flex float-right'>
          <Toggle
            onChange={(bool) => {
              editGodown('isActive', bool);
            }}
            toggle={godownData.isActive}
          />
        </div>
        <div className='flex w-full flex-col md:flex-row'>
          <div className='mr-4'>
            <img
              className='w-28 h-28 border border-zinc-200 rounded-md'
              alt='godown'
              src={
                godownData.details.imageUrl ||
                'https://cdn-icons-png.flaticon.com/512/2897/2897818.png'
              }
            />
          </div>

          <div className='border border-zinc-200 p-2'>
            <Input
              className='text-2xl font-bold'
              type='text'
              field='name'
              initialValue={godownData.name}
              onOk={editGodown}
            />
            <ArrowTopRightOnSquareIcon
              className='inline w-5 text-blue-500 cursor-pointer'
              onClick={() => {
                window.open(
                  `/inventory/masters/godowns/${godownData.id}`,
                  '_blank'
                );
              }}
            />

            <Input
              type='text-area'
              field='description'
              initialValue={godownData.description}
              onOk={editGodown}
            />

            <div className='flex'>
              {/* {godownData.details.tags.map */}
              <span className='font-semibold'>Tags:</span>
              {!toggle && (
                <>
                  {selectedTags.map((tag, idx) => (
                    <span
                      key={idx}
                      className='border rounded-md border-zinc-400 bg-zinc-200 px-2 mx-1 shadow hover:bg-blue-200'
                    >
                      {tag.name}
                    </span>
                  ))}
                  <PencilSquareIcon
                    className='h-6 w-6 cursor-pointer'
                    onClick={() => setToggle(true)}
                  />
                </>
              )}
            </div>
            {toggle && (
              <GodownMultiSelectBox
                updateTag={updateTag}
                incomingTags={selectedTags}
              />
            )}
          </div>
        </div>

        <div>
          <label className='inline-block'>
            <span className='sr-only'>Choose godown photo</span>
            <input
              type='file'
              onChange={fileHandler}
              accept='.png, .jpg, .jpeg'
              className='block w-full text-sm text-slate-500
                file:mr-4 file:py-2 file:px-4
                file:rounded-full file:border-0
                file:text-sm file:font-semibold
                file:bg-blue-50 file:text-blue-600
                hover:file:bg-blue-100
              '
            />
          </label>
          {selectedFile ? (
            <ArrowUpTrayIcon
              onClick={uploadImage}
              className='w-6 h-6 focus:ring-gray-300 text-blue-400 cursor-pointer inline-block'
            />
          ) : (
            ''
          )}
        </div>

        <div className='border border-zinc-200 p-2'>
          <h3>Godown Specifications</h3>
          <div className='flex items-center mt-2'>
            <div className='mr-2 font-bold'>In-Charge: </div>
            <div>
              {godownData.details
                ? godownData.details.inCharge
                : 'Amitabh Bacchan'}
            </div>
          </div>

          <div className='flex items-center mt-2'>
            <div className='mr-2 font-bold'>Dimensions (LxWxH): </div>
            <div>
              {godownData.details
                ? godownData.details.dimensions
                : '30 x 40 x 30 '}
            </div>
          </div>

          <div className='flex items-center mt-2'>
            <div className='mr-2 font-bold'>Address : </div>
            <div>
              {godownData.details ? godownData.details.address : 'India'}
            </div>
          </div>

          <div className='mt-2'>
            <div className='font-bold'>Valid Items: </div>
            {/* godownData.details.validItems ||  */}
            <div className='flex flex-wrap'>
              {[{ name: 'abc' }, { name: 'xyz' }, { name: 'pqr' }].map((t) => (
                <div
                  key={t.name}
                  className='mr-2 p-1 cursor-pointer bg-blue-300 rounded font-bold'
                >
                  {t.name}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='absolute bottom-2 right-4 flex items-center text-gray-600 space-x-2'>
          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Last modified By</div>
              <div>
                {godownData.lastModifiedBy?.name || 'Shiva'},{' '}
                {godownData.lastModifiedAt
                  ? moment(godownData.lastModifiedAt).format('DD-MM-YYYY')
                  : '-'}
              </div>
            </div>
          </div>

          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Created By</div>
              <div>
                {godownData.createdBy?.name || 'Shiva'},{' '}
                {godownData.createdAt
                  ? moment(godownData.createdAt).format('DD-MM-YYYY')
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};
