import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  BankAccountDetail,
  Company,
} from '@erp_core/erp-types/dist/types/modules/admin/company';
import {
  EmployeeRegistrationFilter,
  EmployeeRegistrationType,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedPayrollReport } from '../../../../hooks/hrd/payroll-reports/use-payroll-report';
import { ExtSal } from '../utils/statutory-reports';
import {
  calculateBatchReport,
  createBatchReport,
} from './new-create-statutory-reports';

export function renderSendBatchForRelease({
  onClose,
  useFileTransfer,
  currentCompanyGroupId,
  currentCompanyId,
  useCombinedPayrollReport,
}: {
  onClose: () => void;
  useFileTransfer: UseFileTransfer;
  currentCompanyGroupId: string;
  currentCompanyId: string;
  useCombinedPayrollReport: UseCombinedPayrollReport;
}) {
  const BatchReport = createBatchReport();
  return function SendBatchForRelease({
    date,
    salaries,
    data,
    month,
    setSalary,
    getRegistrations,
  }: {
    date: string;
    salaries: SalaryType[];
    data: Array<{
      salary: ExtSal;
      employee: EmployeeProfileType;
      bank: BankAccountDetail;
      company: Company;
      salRevision: SalaryRevisionType;
      category?: string;
    }>;
    month: string;
    setSalary: (s: SalaryType) => Promise<SalaryType>;
    getRegistrations: (
      filter?: EmployeeRegistrationFilter
    ) => Promise<EmployeeRegistrationType[]>;
  }): JSX.Element {
    const { set: setReport } = useFileTransfer();
    const { syncSet: setPayrollReport } = useCombinedPayrollReport();
    const [reports, setReports] = useState<
      {
        name: string;
        data: {
          name: string;
          data: string;
          fileType: string;
          ext: string;
        }[];
      }[]
    >([]);

    useEffect(() => {
      calculateBatchReport({ data: { data, month }, getRegistrations }).then(
        (reps) => {
          setReports(reps);
        }
      );
      // eslint-disable-next-line
    }, [data, month]);

    async function onReportSave(
      data: any,
      name: string,
      type: string,
      ext: string,
      batch: string
    ) {
      // name includes extension
      // type is specific to document
      let finalName = `${moment().format('YYYY-MM-DD-HH:mm:ss')}-${name}`;
      finalName = finalName.replaceAll(`.${ext}`, '');

      console.log(finalName);

      const file = new File([data], `${finalName}.${ext}`, { type });

      await setReport(
        `${currentCompanyGroupId}/${currentCompanyId}/analytics/reports/payroll/${month}/${finalName}`,
        file,
        ext
      );

      await setPayrollReport({
        monthYear: month,
        name: finalName,
        type: 'bank-details',
        status: 'created',
        details: {
          batch: batch,
        },
        url: `${currentCompanyGroupId}/${currentCompanyId}/analytics/reports/payroll/${month}/${finalName}.${ext}`,
      } as any);
    }

    const batchNames = _.uniq(
      salaries
        .filter((x) => x.releaseDetails?.batchNumber)
        .map((x) => x.releaseDetails.batchNumber || '')
    );
    return (
      <div>
        <div className='text-center text-lg'>Send Batch For Release {date}</div>
        <div className='text-center'>
          Please note that sending batch for release will save these batch
          reports to Accounts module
        </div>
        <div className='p-1 border border-gray-200 rounded-md'>
          {batchNames.map((b, idx) => (
            <div className='m-1 border border-gray-100 flex' key={b}>
              <div className='flex-auto'>{b}</div>
              <div className='flex-none'>
                <LoadingButton
                  text='Send To Release'
                  behaviorFn={async ({ idx }) => {
                    try {
                      await onReportSave(
                        reports[idx].data[0].data,
                        reports[idx].data[0].name,
                        reports[idx].data[0].fileType,
                        reports[idx].data[0].ext,
                        b
                      );

                      // TODO: save the path to salary reports table

                      for (const sal of salaries) {
                        if (sal.releaseDetails.batchNumber === b) {
                          await setSalary({
                            id: sal.id,
                            status: 'sent-for-release',
                          } as any);
                        }
                      }

                      console.log('file saved');
                    } catch (e) {
                      console.log(e);
                    } finally {
                      onClose();
                    }
                  }}
                  behaviourParams={{ idx }}
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          <BatchReport reports={reports} />
        </div>
      </div>
    );
  };
}

export function renderSendRecalBatchForRelease({
  onClose,
  useFileTransfer,
  currentCompanyGroupId,
  currentCompanyId,
  useCombinedPayrollReport,
}: {
  onClose: () => void;
  useFileTransfer: UseFileTransfer;
  currentCompanyGroupId: string;
  currentCompanyId: string;
  useCombinedPayrollReport: UseCombinedPayrollReport;
}) {
  const BatchReport = createBatchReport();
  return function SendRecalBatchForRelease({
    date,
    salaries,
    data,
    month,
    setSalary,
    getRegistrations,
  }: {
    date: string;
    salaries: SalaryType[];
    data: Array<{
      salary: ExtSal;
      employee: EmployeeProfileType;
      bank: BankAccountDetail;
      company: Company;
      salRevision: SalaryRevisionType;
      category?: string;
    }>;
    month: string;
    setSalary: (s: SalaryType) => Promise<SalaryType>;
    getRegistrations: (
      filter?: EmployeeRegistrationFilter
    ) => Promise<EmployeeRegistrationType[]>;
  }): JSX.Element {
    const { set: setReport } = useFileTransfer();
    const { syncSet: setPayrollReport } = useCombinedPayrollReport();

    const [reports, setReports] = useState<
      {
        name: string;
        data: {
          name: string;
          data: string;
          fileType: string;
          ext: string;
        }[];
      }[]
    >([]);

    useEffect(() => {
      calculateBatchReport({ data: { data, month }, getRegistrations }).then(
        (reps) => {
          setReports(reps);
        }
      );
      // eslint-disable-next-line
    }, [data, month]);

    async function onReportSave(
      data: any,
      name: string,
      type: string,
      ext: string,
      batch: string
    ) {
      // name includes extension
      // type is specific to document
      let finalName = `${moment().format('YYYY-MM-DD-HH:mm:ss')}-recal-${name}`;
      finalName = finalName.replaceAll(`.${ext}`, '');

      const file = new File([data], `${finalName}.${ext}`, { type });

      await setReport(
        `${currentCompanyGroupId}/${currentCompanyId}/analytics/reports/payroll/${month}/${finalName}`,
        file,
        ext
      );

      await setPayrollReport({
        monthYear: month,
        name: finalName,
        type: 'bank-details',
        status: 'created',
        details: {
          batch: batch,
        },
        url: `${currentCompanyGroupId}/${currentCompanyId}/analytics/reports/payroll/${month}/${finalName}.${ext}`,
      } as any);
    }

    const batchNames = _.uniq(
      salaries
        .filter((x) => x.releaseDetails?.batchNumber)
        .map((x) => x.releaseDetails.batchNumber || '')
    );
    return (
      <div>
        <div className='text-center text-lg'>
          Send Recalculate Batch For Release {date}
        </div>
        <div className='text-center'>
          Please note that sending batch for release will save these batch
          reports to Accounts module
        </div>
        <div className='p-1 border border-gray-200 rounded-md'>
          {batchNames.map((b, idx) => (
            <div className='m-1 border border-gray-100 flex' key={b}>
              <div className='flex-auto'>{b}</div>
              <div className='flex-none'>
                <LoadingButton
                  text='Send To Release'
                  behaviorFn={async ({ idx }) => {
                    try {
                      await onReportSave(
                        reports[idx].data[0].data,
                        reports[idx].data[0].name,
                        reports[idx].data[0].fileType,
                        reports[idx].data[0].ext,
                        b
                      );

                      // TODO: save the path to salary reports table

                      for (const sal of salaries) {
                        if (sal.releaseDetails.batchNumber === b) {
                          await setSalary({
                            id: sal.id,
                            status: 'sent-for-release',
                          } as any);
                        }
                      }

                      console.log('file saved');
                    } catch (e) {
                      console.log(e);
                    } finally {
                      onClose();
                    }
                  }}
                  behaviourParams={{ idx }}
                />
              </div>
            </div>
          ))}
        </div>
        <div>
          <BatchReport reports={reports} />
        </div>
      </div>
    );
  };
}
