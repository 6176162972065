/* eslint-disable no-unused-vars */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type Fileds = {
  name: string;
  deliverBy: string;
  remark: string;
};

type Props = { onSave: any };
// eslint-disable-next-line
export function renderCreateOrderFrom(): (props: Props) => JSX.Element {
  return function CreateOrderForm({ onSave }: Props): JSX.Element {
    const formProps: FormProps<Fileds> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Order name',
          required: true,
        },
        {
          property: 'deliverBy',
          type: 'date',
          label: 'Deliver By',
          required: true,
        },
        {
          property: 'remark',
          type: 'input',
          label: 'Remarks',
        },
      ],
      initialFormState: {
        name: '',
        remark: '',
        deliverBy: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };
    const NewForm = renderFormV2<Fileds>(formProps);

    return <NewForm />;

    function mapPFDToP(b: FormDataType<Fileds>): Fileds {
      return {
        name: b.name as string,
        deliverBy: b.deliverBy as string,
        remark: b.remark as string,
      };
    }
  };
}
