import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { renderAdddExitDateAndReasonForm } from './exitdate-reason-addd-form';

export type renderExitDateAndReasonProps = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
};

export function renderExitDateAndReason({
  useCombinedEmployeeProfile,
}: renderExitDateAndReasonProps): () => JSX.Element {
  return function ExitDateAndReason(): JSX.Element {
    const {
      syncSet: setEmployee,
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();

    useEffect(() => {
      getAllEmployees();
      // eslint-disable-next-line
    }, []);

    const exitEmployeeRcds = employees?.filter(
      (x) =>
        x.details.workTimeLines?.find((y) => y.activity === 'resigned')?.date
    );

    const handleExitDateAndReasonAdd = async (
      form: Partial<EmployeeProfileType>
    ) => {
      try {
        const finalData = {
          ...form,
        } as EmployeeProfileType;
        await setEmployee(finalData);
        toast('Data added sucessfully');
        getAllEmployees();
      } catch (error) {
        toast('Something went wrong');
      }

      // const finalData = {
      //   id: employee.id,
      //   // details: {
      //   //   exitDateAndReason: [...employee.details.exitDateAndReason, data],
      //   // },
      // };
    };
    // const Form = renderAddExitDateAndReasonForm();
    const Form = renderAdddExitDateAndReasonForm({
      useCombinedEmployeeProfile,
    });

    const ExitDateAndReasonTable = renderTableComponent();
    const exitDateAndReasonTableHeader: TableHeader = [
      [{ name: 'Employee Name' }, { name: 'Reason' }, { name: 'Date' }],
    ];

    const tableData =
      exitEmployeeRcds?.map((r, idx) => ({
        cells: [
          { value: r.user.name },
          { value: '-' },
          {
            value:
              moment(
                r.details.workTimeLines?.find((x) => x.activity === 'resigned')
                  ?.date
              ).format('DD-MMM-YYYY') || '',
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;
    // const ExitDateAndReasonTableBody: TableBody = employee.details
    //   ?.exitDateAndReason
    //   ? employee.details.exitDateAndReason.map((f) => {
    //       const cells: Array<TableCell> = [];
    //       cells.push(
    //         { value: f.empName },
    //         { value: f.reason },
    //         { value: f.exitDate }
    //       );

    //       return { cells };
    //     })
    //   : [];

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Exit Employees',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Exit Date And Reason',
              size: 'large',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={(form) => {
                      handleExitDateAndReasonAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <ExitDateAndReasonTable
          header={exitDateAndReasonTableHeader}
          body={tableBody}
        />
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
