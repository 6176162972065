import { RuleRequestType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  MisconductRuleBookType,
  MisconductType,
} from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { MisconductRuleBookHttpRepo } from '../datalayer/repository/http/disciplinary/misconduct-rule-book';
import { MisconductHttpRepo } from '../datalayer/repository/http/disciplinary/miscounduct';
import { RuleRequestHttpRepo } from '../datalayer/repository/http/disciplinary/rule-request';
import {
  MisconductFilter,
  MisconductInterface,
} from '../models/interfaces/hrd/disciplinary/misconduct';
import {
  MisconductRuleBookFilter,
  MisconductRuleBookInterface,
} from '../models/interfaces/hrd/disciplinary/misconduct-rule-book';
import {
  RuleRequestFilter,
  RuleRequestInterface,
} from '../models/interfaces/hrd/disciplinary/rule-request';
import { erpV2Api } from './admin';
import { DISCIPLINARY_SERVER_URI } from './server-uri';

export const disciplinaryMisconductService = new MisconductHttpRepo(
  DISCIPLINARY_SERVER_URI,
  erpV2Api
);

export const useCombinedMisconduct = createUseCombinedResource<
  MisconductType,
  MisconductFilter,
  MisconductInterface
>({
  useService: () => disciplinaryMisconductService,
});

export const disciplinaryMisconductRuleBookService = new MisconductRuleBookHttpRepo(
  DISCIPLINARY_SERVER_URI,
  erpV2Api
);

export const useCombinedMisconductRuleBook = createUseCombinedResource<
  MisconductRuleBookType,
  MisconductRuleBookFilter,
  MisconductRuleBookInterface
>({
  useService: () => disciplinaryMisconductRuleBookService,
});

export const disciplinaryRuleRequestService = new RuleRequestHttpRepo(
  DISCIPLINARY_SERVER_URI,
  erpV2Api
);

export const useCombinedRuleRequest = createUseCombinedResource<
  RuleRequestType,
  RuleRequestFilter,
  RuleRequestInterface
>({
  useService: () => disciplinaryRuleRequestService,
});
