/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
export function BomDetailsCard({
  name,
  createdAt,
  finishedItem,
  quantity,
  engineer,
  officer,
  link,
}) {
  return (
    <div className=' text-left text-gray-700'>
      <div>
        <strong>BOM Name:</strong> {name}
      </div>
      <div>
        <strong>Created on:</strong> {createdAt}
      </div>
      <div>
        <strong>Finished Item / Equipment:</strong> {finishedItem}
      </div>

      <div>
        <strong>Quantity:</strong> {quantity}
      </div>
      <div>
        <strong>Design/Prod Engineer:</strong> {engineer}
      </div>
      <div>
        <strong>Site/Prod Officer:</strong> {officer}
      </div>
      <div>
        {/* eslint-disable-next-line */}
        <a target='_blank' href={link}>
          view more details
        </a>
      </div>
    </div>
  );
}
