import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type EditPropertyValProps = {
  propType?: string;
  currentValue: string;
  onSave: (data: Partial<EditPropertyValFormType>) => Promise<void>;
};

export type EditPropertyValFormType = {
  currentValue: string;
  newValue: string;
};

export const renderEditPropertyValue = () => {
  return function EditPropertyVal({
    propType,
    currentValue,
    onSave,
  }: EditPropertyValProps) {
    console.log('currentValue', propType);

    const formProps: FormProps<EditPropertyValFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },

        {
          property: 'newValue',
          type: `${propType === 'input'}` ? 'input' : 'number',
          required: true,
          label: 'New Value',
        },
      ],
      initialFormState: {
        currentValue: currentValue || '',
        newValue: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyValFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditPropertyValFormType>
    ): EditPropertyValFormType {
      return {
        currentValue: b.currentValue || currentValue,
        newValue: b.newValue,
      } as EditPropertyValFormType;
    }
  };
};
