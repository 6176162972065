import { FormField, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedItem } from '../../../../../../hooks/inventory/item/use-combined-item';

type EnduseFormType = {
  id: string;
  name: string;
  description: string;
  ratio: string;
  isSystemItem: string;
  item: {
    id: string;
    name: string;
  };
};

export function renderEditItemEndUse({
  useCombinedItem,
}: {
  useCombinedItem: UseCombinedItem;
}) {
  return function ({
    onSubmit,
    data,
  }: {
    data?: {
      id?: string;
      name: string;
      ratio?: string;
      description: string;
    };
    onSubmit: (f: EnduseFormType) => Promise<void>;
  }) {
    const fields: Array<FormField<EnduseFormType>> = [
      {
        property: 'isSystemItem',
        type: data ? 'input' : 'select',
        options: [
          {
            text: 'Yes',
            value: 'Yes',
          },
          {
            text: 'No',
            value: 'No',
          },
        ],
        label: 'Is System Item?',
        readonly: data ? true : false,
      },
      {
        property: 'item',
        type: data ? 'input' : 'searchable-select',
        label: 'Item',
        dependentOn(formData) {
          if (formData.isSystemItem === 'Yes') {
            return true;
          }
          return false;
        },
        readonly: data ? true : false,
        searchOptions: {
          useSearch: useCombinedItem,
          onSearchValueSelect(u) {},
        },
      },
      {
        property: 'name',
        type: 'input',
        label: 'Name',
        dependentOn(formData) {
          if (formData.isSystemItem === 'No') {
            return true;
          }
          return false;
        },
        readonly: data ? true : false,
      },
      {
        property: 'description',
        type: 'input',
        label: 'Description',
      },
      {
        property: 'ratio',
        label: 'Ratio',
        type: 'input',
      },
    ];
    const Form = renderFormV2<EnduseFormType>({
      style: 'md:w-1/2',
      fieldsData: fields,
      initialFormState: {
        id: data?.id || '',
        name: data?.name || '',
        description: data?.description || '',
        ratio: data?.ratio || '',
        isSystemItem: data?.id ? 'Yes' : 'No',
        item: data
          ? data.name
          : {
              id: '',
              name: '',
            },
      },
      onSubmit: async (data) => {
        await onSubmit(data);
      },
      allowUnchanged: true,
      mapTToU: (b) => b as EnduseFormType,
    });

    return (
      <div>
        <Form />
      </div>
    );
  };
}
