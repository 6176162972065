/* eslint-disable no-unused-vars */

import {
  Company,
  CompanyGroup,
  CompanyGroupSetting,
  LocationType,
} from '@erp_core/erp-types/dist/modules/admin';
import { createContext } from 'react';

export const CurrentContext = createContext<{
  company: Company;
  location: LocationType;
  companyGroup: CompanyGroup;
  cgSetting: CompanyGroupSetting;
  setLocation: (l: LocationType) => void;
  setCompany: (c: Company) => void;
  setCompanyGroup: (cg: CompanyGroup) => void;
}>({
  company: {} as Company,
  location: {} as LocationType,
  companyGroup: {} as CompanyGroup,
  cgSetting: {} as CompanyGroupSetting,
  setCompany: (c: Company) => {},
  setLocation: (l: LocationType) => {},
  setCompanyGroup: (cg: CompanyGroup) => {},
});
