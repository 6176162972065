import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseCombinedItem } from '../../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from '../../../../../hooks/order/sales/client/use-client';
import { UseCombinedLicenseContractDocType } from '../../../../../hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';
import { UseCombinedLicenseContract } from '../../../../../hooks/order/work-order/license-contract/use-license-contract';
import { renderLicenseContractorm } from './form/license-contract';

export type RenderLicenseContractProps = {
  useCombinedLicenseContract: UseCombinedLicenseContract;
  useCombinedItem: UseCombinedItem;
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
  useCombinedClient: UseCombinedClient;
  useCombinedVendor: UseCombinedVendor;
};

export function renderLicenseContract({
  useCombinedLicenseContract,
  useCombinedItem,
  useCombinedLicenseContractDocType,
  useCombinedClient,
  useCombinedVendor,
}: RenderLicenseContractProps): () => JSX.Element {
  return function LicenseContractList(): JSX.Element {
    const {
      list: licensceContracts,
      getAll: getLicenseContracts,
    } = useCombinedLicenseContract();

    const { companyGroup, company } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);

    useEffect(() => {
      // TODO: Add current location
      getLicenseContracts();
      // eslint-disable-next-line
    }, []);

    console.log('licensceContracts', licensceContracts);

    const { set: setLicenseContract } = useCombinedLicenseContract();

    const Table = renderTableComponent();

    const Card = renderCardComponent();

    const onSaveLicenseContract = async (form: any) => {
      const finalData = {
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };

      await setLicenseContract(finalData);
      toast('Data added sucessfully');
      getLicenseContracts();
    };

    const cardHeader: CardHeader = {
      title: 'License Contract',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add License Contract',
              content: ({ onClose }) => {
                const Form = renderLicenseContractorm({
                  useCombinedItem,
                  useCombinedLicenseContractDocType,
                  useCombinedClient,
                  useCombinedVendor,
                });
                const formData = {};
                return (
                  <Form
                    onSave={async (e) => {
                      onSaveLicenseContract(e);
                      onClose();
                    }}
                    data={formData as any}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        // { name: 'Document Type' },
        { name: 'Party Type' },
        // { name: 'Party Name' },
        { name: 'Valid From' },
        { name: 'Expires At' },
      ],
    ];
    const tableBody: TableBodyRow[] =
      licensceContracts?.map((e) => {
        const cells: Array<TableCell> = [
          { value: e?.name || '' },
          { value: e?.description },
          // { value: e?.docType.name },
          { value: e?.partyType },
          // { value: e?.partyName },
          { value: moment(e?.validFrom).format('DD MMM yyyy') },
          { value: moment(e?.expiresAt).format('DD MMM yyyy') },
        ];

        return {
          rowData: {
            licenseContract: e,
          },
          cells,
        };
      }) || [];
    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} actions={[]} />,
    };

    return (
      <div className='m-3'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
