/* eslint-disable no-unused-vars */
import { ItemBatchGodown } from '@erp_core/erp-types/dist/modules/inventory';
import { Input, LoadingButton } from '@erp_core/erp-ui-components';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { dateGetter } from '../../../../utils/common';

type ItemInput = {
  itemBatchData: ItemBatchGodown;
};

type Props = {
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  reload(): void;
};

/* eslint-disable arrow-body-style */
export const createItemBatchSideView = ({
  useCombinedBatchItemGodown,
  reload,
}: Props): ((props: ItemInput) => JSX.Element) => {
  return function ItemView({ itemBatchData }: ItemInput): JSX.Element {
    const { get: getItemBatchData, syncSet } = useCombinedBatchItemGodown();

    if (!itemBatchData) return <div />;

    const editItem = async (field: string, value: string | boolean) => {
      const data: any = {};
      data[field] = value;

      const res = await syncSet({
        id: itemBatchData.id,
        ...data,
      });
      if (res) getItemBatchData(itemBatchData.id);
      reload();
    };

    return (
      <div className='w-2/4 min-h-full mt-4 p-3 relative border-l-2 overflow-y-auto border-zinc-200 bg-zinc-50'>
        <div className='flex flex-col md:flex-row'>
          <div className='mr-4'>
            <img
              className='w-28 h-28 border border-zinc-200 rounded-md'
              alt='godown'
              src='https://cdn-icons-png.flaticon.com/512/1311/1311452.png'
            />
          </div>

          <div className='flex-1'>
            <div className='border border-zinc-200 rounded-md p-2'>
              <Input
                className='text-2xl font-bold'
                type='text'
                field='name'
                initialValue={itemBatchData.name}
                onOk={editItem}
              />
              <div className='my-2'>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Batch: </div>
                  <div className='text-gray-700'> {itemBatchData.name}</div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Quantity: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {itemBatchData.quantity}{' '}
                    {itemBatchData.item.details?.profile?.units || 'kg'}
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Rate: </div>
                  {/* <div className='text-gray-700'> {itemBatchData.rate ? `RS${itemBatchData.rate}/- per ${itemBatchData.unit || 'kg'}` : 'Not available'}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className='border relative border-zinc-200 my-2 rounded-md p-2'>
              <div className='text-lg font-bold'>Profile</div>
              <div className='my-2'>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Alias: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {itemBatchData.item.details?.alias || 'Not present'}
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Abbreviation: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {itemBatchData.item.details?.abbreviation || 'Not present'}
                  </div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Status: </div>
                  <div className='text-gray-700'>
                    {' '}
                    {itemBatchData.item.status || 'Not present'}
                  </div>
                </div>

                <div className='flex space-x-2 items-center'>
                  <div className='font-bold'>Inspectors: </div>
                  <div className='text-gray-700 flex space-x-2'>
                    {itemBatchData.item.details?.inspectors?.map((i) => (
                      <div
                        key={i.id}
                        className='p-0.5 font-semibold rounded border border-gray-300'
                      >
                        {i.name}
                      </div>
                    ))}
                  </div>
                </div>

                <div className='flex absolute right-2 bottom-2 space-x-2'>
                  <a
                    href={`/inventory/masters/items/${itemBatchData.item.id}/properties`}
                    target='_blank'
                    rel='noreferrer'
                    className='bg-transparent underline text-blue-600'
                  >
                    see more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className='border relative border-zinc-200 my-2 rounded-md p-2'>
              <div className='text-lg font-bold'>Vendors</div>
              <div className='my-2 flex space-x-2 items-center'>
                {([] as any[]).map((v) => {
                  return (
                    <img
                      key={v.id}
                      className='w-16 h-16'
                      src={v.imgUrl}
                      alt={v.name}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            <div className='border relative border-zinc-200 my-2 rounded-md p-2'>
              <div className='text-lg font-bold'>Specifications</div>
              <div className='my-2'>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Color: </div>
                  <div className='text-gray-700'>Light brown</div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Risk Level: </div>
                  <div className='text-gray-700'>High risk</div>
                </div>
                <div className='flex space-x-2'>
                  <div className='font-bold'>Packing type: </div>
                  <div className='text-gray-700'>Only plastic containers</div>
                </div>
                <div className='flex absolute right-2 bottom-2 space-x-2'>
                  <LoadingButton
                    defaultStyle='bg-transparent underline text-blue-600'
                    behaviorFn={async () =>
                      console.log('Redirect to Profile page')
                    }
                    text='see more'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='absolute mt-auto right-4 flex items-center text-gray-600 space-x-2'>
          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Last modified</div>
              <div>
                {itemBatchData.lastModifiedBy.name},{' '}
                {itemBatchData.lastModifiedAt
                  ? dateGetter(new Date(itemBatchData.lastModifiedAt))
                  : '-'}
              </div>
            </div>
          </div>

          <div className='mt-2'>
            <div className='flex items-center space-x-2'>
              <div className='font-bold'>Created</div>
              <div>
                {itemBatchData.createdBy.name},{' '}
                {itemBatchData.createdAt
                  ? dateGetter(new Date(itemBatchData.createdAt))
                  : '-'}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
};
