import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { downloadFile, LoadingButton } from '@erp_core/erp-ui-components';
import axios from 'axios';
import moment from 'moment';
import { FILE_MANAGER_SERVER_URI } from '../../../../config/server-uri';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';

async function downloadInvoicePdf({
  templateUrl,
  invoiceData,
  getFile,
}: {
  templateUrl: string;
  invoiceData: any;
  getFile: (path: string) => Promise<string>;
}) {
  let html = await getFile(templateUrl);
  const axiosInstance = axios.create({
    baseURL: FILE_MANAGER_SERVER_URI,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const response = await axiosInstance.post('/pdf', {
    template: `<div style="padding: 20px;">${html}</div>`,
    data: invoiceData,
  });
  const fType = 'application/pdf';
  const fName = 'sse-invoice.pdf';
  const res = response.data;
  console.log('res', res);

  downloadFile({ fileType: fType, fileName: fName, result: res });
}

export function DownloadSSEInvoice({
  useFileTransfer,
  salaries,
}: {
  useFileTransfer: UseFileTransfer;
  salaries: SalaryType[];
}): JSX.Element {
  const { get: getFile } = useFileTransfer();

  const filteredSalaries = salaries.filter(
    (x) =>
      ['approved', 'sent-for-release', 'released'].includes(x.status) &&
      x.company.name === 'Shree Sai Enterprises'
  );
  const labourCharges = filteredSalaries.reduce((prev, x) => {
    return (
      prev + parseInt(`${x.details?.salaryParams?.earning?.basicDa || '0'}`)
    );
  }, 0);
  const allAllowances = filteredSalaries.reduce((prev, x) => {
    // salaryParams.earning.totalAllowances + salaryParams.earning.exGratiaMonthly + salaryParams.earning.adhocEarningsVal
    return (
      prev +
      parseInt(`${x.details?.salaryParams?.earning?.totalAllowances || '0'}`) +
      parseInt(`${x.details?.salaryParams?.earning?.exGratiaMonthly || '0'}`) +
      parseInt(`${x.details?.salaryParams?.earning?.adhocEarningsVal || '0'}`)
    );
  }, 0);

  const grossWages = filteredSalaries.reduce((prev, x) => {
    return (
      prev +
      parseInt(`${x.details?.salaryParams?.earning?.grossEarnings || '0'}`)
    );
  }, 0);

  const pfContribution = filteredSalaries.reduce((prev, x) => {
    // PF: salaryParams.deduction.pf + salaryParams.deduction.pfa + salaryParams.deduction.edli
    return (
      prev +
      parseInt(`${x.details?.salaryParams?.deduction?.pf || '0'}`) +
      parseInt(`${x.details?.salaryParams?.deduction?.pfa || '0'}`) +
      parseInt(`${x.details?.salaryParams?.deduction?.edli || '0'}`)
    );
  }, 0);

  const esicContribution = filteredSalaries.reduce((prev, x) => {
    // ESIC: salaryParams.deduction.esic
    return (
      prev + parseInt(`${x.details?.salaryParams?.deduction?.esic || '0'}`)
    );
  }, 0);

  let serviceCharges = filteredSalaries.reduce((prev, x) => {
    // Service charges: salaryParams.earning.grossEarnings * 5%
    return (
      prev +
      parseInt(`${x.details?.salaryParams?.earning?.grossEarnings || '0'}`)
    );
  }, 0);
  serviceCharges = parseFloat(
    parseFloat(`${(serviceCharges * 5) / 100}`).toFixed(2)
  );

  const deductions = filteredSalaries.reduce((prev, x) => {
    // deductions: salaryParams.deduction.adhocDeductionsVal
    return (
      prev +
      parseInt(
        `${x.details?.salaryParams?.deduction?.adhocDeductionsVal || '0'}`
      )
    );
  }, 0);

  const totalTaxableAmount =
    labourCharges +
    allAllowances +
    pfContribution +
    esicContribution +
    serviceCharges -
    deductions;

  const sgstPercent = 9;
  const sgst = parseFloat(
    parseFloat(`${(totalTaxableAmount * sgstPercent) / 100}`).toFixed(2)
  );

  const cgstPercent = 9;
  const cgst = parseFloat(
    parseFloat(`${(totalTaxableAmount * cgstPercent) / 100}`).toFixed(2)
  );

  const igstPercent = 0;
  const igst = parseFloat(
    parseFloat(`${(totalTaxableAmount * igstPercent) / 100}`).toFixed(2)
  );

  const reverseChargeTax = 0;

  const totalTaxAmount = cgst + sgst + igst + reverseChargeTax;

  const totalInvoiceAmount = totalTaxableAmount + totalTaxAmount;

  const yearMonth = moment().subtract(1, 'month').format('YYYY/MM');
  return (
    <div>
      <LoadingButton
        text='Download'
        behaviorFn={async () => {
          await downloadInvoicePdf({
            templateUrl:
              '85025EE1-E570-43D1-A390-4A141E31BE7D/email-templates/mail/054043F6-077A-42E1-B2B5-638F3EF72155.html',
            getFile,
            invoiceData: {
              invoiceNumber: `SSE/${yearMonth}`,
              invoiceDate: moment().format('YYYY-MM-DD'),
              invoiceMonth: moment().subtract(1, 'month').format('MMM, YYYY'),
              labourCharges,
              allAllowances,
              grossWages,
              pfContribution,
              esicContribution,
              serviceCharges,
              deductions,
              totalTaxableAmount,
              sgst,
              sgstPercent,
              cgst,
              cgstPercent,
              igst,
              igstPercent,
              reverseChargeTax,
              totalTaxAmount,
              totalInvoiceAmount,
            },
          });
        }}
        behaviourParams={{}}
      />
    </div>
  );
}
