import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { RotationalShiftDayType } from '@erp_core/erp-types/dist/modules/planning';

export function calculateShiftDuration({
  employee,
  shiftDay,
}: {
  employee: EmployeeProfileType;
  shiftDay?: RotationalShiftDayType;
}): number {
  if (employee.details.jobProfile.shiftDuration) {
    return employee.details.jobProfile.shiftDuration;
  }

  // TODO: Check rotational shiftday for exception
  if (shiftDay) {
    // TODO: This needs to be verified for the actual hours in the shiftday timing.
    return 8;
  }

  const shiftHours =
    employee.details.jobProfile?.shiftType === 'Rotational' ? 8 : 9;

  return shiftHours;
}
