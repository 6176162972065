/* eslint-disable class-methods-use-this */
import { CompanyGroup } from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithCurrentRepo } from '../generic-resource-with-current';

export type CompanyGroupFilter = {
  search?: string;
};

export class HttpCompanyGroupRepo extends GenericHttpWithCurrentRepo<
  CompanyGroup,
  CompanyGroupFilter,
  CompanyGroup
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/company-groups',
      itemName: 'current-company-group',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
