import { FormField, renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedItem } from '../../../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedStockGroup } from '../../../../../../hooks/inventory/stockgroup/use-stockgroup';

export const renderItemApprovalForm = ({
  itemId,
  currentCompanyGroupId,
  currentCompanyId,
  vendorId,
  useFileTransfer,
  onSave,
  useCombinedItem,
  useCombinedStockGroup,
}: {
  itemId: string;
  currentCompanyGroupId: string;
  currentCompanyId: string;
  vendorId: string;
  useFileTransfer: UseFileTransfer;
  onSave: any;
  useCombinedItem: UseCombinedItem;
  useCombinedStockGroup: UseCombinedStockGroup;
}) => {
  return function ItemApprovalForm() {
    const {
      get: getStockGroup,
      resource: stockGroup,
    } = useCombinedStockGroup();
    const { get: getItem, resource: item } = useCombinedItem();

    useEffect(() => {
      getItem(itemId);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (item?.stockgroup?.id) {
        getStockGroup(item?.stockgroup?.id);
      }
      // eslint-disable-next-line
    }, [item]);

    const initialFormState =
      (stockGroup &&
        Object.fromEntries(
          (stockGroup?.details as any)?.specification?.fields?.map((f) => [
            f?.property,
            '',
          ]) || []
        )) ||
      {};

    const itemSpecPath = `${currentCompanyGroupId}/${currentCompanyId}/${vendorId}/${itemId}/item-spec-${moment().unix()}`;
    const siteDocsPath = `${currentCompanyGroupId}/${currentCompanyId}/${vendorId}/${itemId}/site-doc-${moment().unix()}`;
    const vqPath = `${currentCompanyGroupId}/${currentCompanyId}/${vendorId}/${itemId}/vq-${moment().unix()}`;
    const qcPath = `${currentCompanyGroupId}/${currentCompanyId}/${vendorId}/${itemId}/qc-${moment().unix()}`;
    const performanceReportPath = `${currentCompanyGroupId}/${currentCompanyId}/${vendorId}/${itemId}/performance-report-${moment().unix()}`;
    const fields: Array<FormField<{
      itemSpecs: string;
      siteDocs: string;
      remarks: string;
    }>> = [
      {
        property: 'itemSpecs',
        type: 'file',
        accept: 'application/pdf',
        required: true,
      },
      {
        property: 'siteDocs',
        type: 'file',
        accept: 'application/pdf',
        required: true,
      },
      {
        property: 'vq',
        type: 'file',
        accept: 'application/pdf',
        required: true,
      },
      {
        property: 'qc',
        type: 'file',
        accept: 'application/pdf',
        required: true,
      },
      {
        property: 'performanceReport',
        type: 'file',
        accept: 'application/pdf',
        required: true,
      },
      ...((stockGroup?.details as any)?.specification?.fields || []),
      {
        property: 'remarks',
        type: 'input',
        label: 'Remark',
        required: true,
      },
    ];
    const Form = renderFormV2<any>({
      style: 'md:w-1/2',
      fieldsData: fields,
      initialFormState: {
        itemSpecs: '',
        siteDocs: '',
        qc: '',
        vq: '',
        performanceReport: '',
        remarks: '',
        ...initialFormState,
      },
      onSubmit: (data) =>
        onSave(
          data,
          { itemSpecPath, siteDocsPath, vqPath, qcPath, performanceReportPath },
          itemId
        ),
      mapTToU: (b) => b,
      upload: {
        useFileTransfer,
        config: [
          {
            name: 'itemSpecs',
            path: itemSpecPath,
            type: 'private',
          },
          {
            name: 'siteDocs',
            path: siteDocsPath,
            type: 'private',
          },
          {
            name: 'vq',
            path: vqPath,
            type: 'private',
          },
          {
            name: 'qc',
            path: qcPath,
            type: 'private',
          },
          {
            name: 'performanceReport',
            path: performanceReportPath,
            type: 'private',
          },
        ],
      },
    });

    return <Form />;
  };
};
