import { useEffect } from 'react';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';

type Props = {
  id: string;
  uom: string;
  view: 'total' | 'combo-reserved' | 'combo-allocated';
};
export function renderVirtualStockMf({
  useVirtualStock,
}: {
  useVirtualStock: UseVirtualMFStock;
}): // eslint-disable-next-line
({ id, uom, view }: Props) => JSX.Element {
  return function VirtualStock({ id, uom, view }: Props): JSX.Element {
    const { data, get } = useVirtualStock();
    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, []);

    return <div className='text-center'>{renderStock()}</div>;
    function renderStock() {
      if (view === 'total')
        return (
          <span>
            {data.total || 0} {uom}
          </span>
        );
      if (view === 'combo-reserved')
        return (
          <span>
            {data.reserved || 0} {uom}/{data.unAllocated || 0} {uom}{' '}
          </span>
        );
      if (view === 'combo-allocated')
        return (
          <span>
            {data.allocated || 0} {uom}/{data.unAllocated || 0} {uom}{' '}
          </span>
        );
      return <span>No data</span>;
    }
  };
}
