/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { EmployeeRegistrationType } from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeRegistrationFilter } from '@erp_core/erp-types/dist/types/modules/hrd/employee-registration';
import { ErpV2Api } from 'models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class EmployeeRegistrationHttpRepo extends GenericHttpWithLogsRepo<
  EmployeeRegistrationType,
  EmployeeRegistrationFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/employee-registration-details',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
