import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedMisconduct } from '../../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
import { UserRendererInterface } from '../../../../common/fragments/user';

export function MisconductHistory({
  useCombinedMisconduct,
  employeeId,
  userRenderService,
}: {
  useCombinedMisconduct: UseCombinedMisconduct;
  employeeId: string;
  userRenderService: UserRendererInterface;
}): JSX.Element {
  const { list: misconducts, getAll: getMisconducts } = useCombinedMisconduct();

  useEffect(() => {}, [misconducts]);

  useEffect(() => {
    //getMisconducts({ employeeId: employeeId});
    getMisconducts({
      employeeId: employeeId,
    });
    // eslint-disable-next-line
  }, []);

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'History',
  };

  const Table = renderTableComponent();

  const tableHeader: TableHeader = [
    [
      { name: 'Employee' },
      { name: 'Date' },
      { name: 'Category' },
      { name: 'Reason' },
      { name: 'Amount' },
      { name: 'Status' },
    ],
  ];

  const tableData =
    misconducts?.map((r, idx) => ({
      cells: [
        {
          value: (
            <userRenderService.userCard
              link={true}
              size='small'
              id={r.employee?.id}
              name={r.employee.name}
            />
          ),
        },
        { value: r.date },
        { value: r.category },
        { value: r.reason },
        { value: r.amount },
        { value: r.status },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div>
        <div className='w-full'>
          <Table header={tableHeader} body={tableBody} />
        </div>
      </div>
    ),
  };

  return (
    <>
      <div className='p-16 border border-gray-200 rounded-lg h-18 border rounded-md cursor-pointer p-2'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}
