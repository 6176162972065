import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';

type AddDepedencyType = {
  name: string;
  processId: string;
  consumedGrade: { id: string; name: string };
  producedGrade: { id: string; name: string };
  consumptionRatio: string;
  costContribution: string;
};

export function renderAddBomDependency(): ({
  save,
}: {
  save: (d: any) => Promise<void>;
  useCombinedGrade: UseCombinedGrade;
}) => JSX.Element {
  return function AddBomDependency({
    save,
    useCombinedGrade,
  }: {
    save: (d: any) => Promise<void>;
    useCombinedGrade: UseCombinedGrade;
  }): JSX.Element {
    const formProps: FormProps<AddDepedencyType> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input', required: true },
        {
          property: 'processId',
          label: 'Process Id',
          type: 'input',
          required: true,
        },
        {
          property: 'consumedGrade',
          label: 'Consumed Grade',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedGrade,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'producedGrade',
          label: 'Produced Grade',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedGrade,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'consumptionRatio',
          label: 'Consumption Ratio',
          type: 'input',
          required: true,
        },
        {
          property: 'costContribution',
          label: 'Cost Contribution',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        processId: '',
        name: '',
        consumedGrade: { id: '', name: '' },
        producedGrade: { id: '', name: '' },
        consumptionRatio: '',
        costContribution: '',
      },
      mapTToU: (r) => {
        return r as AddDepedencyType;
      },
      onSubmit: save,
      style: 'w-1/2',
    };

    const Form = renderFormV2<AddDepedencyType>(formProps);

    return <Form />;
  };
}
