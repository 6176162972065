/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { TopicType } from '@erp_core/erp-types/dist/types/modules/questionnaire/topic';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { TopicFilter } from '../../../../../models/interfaces/questionnaire/topic';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class TopicHttpRepo extends GenericHttpWithLogsRepo<
  TopicType,
  TopicFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/topics',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
