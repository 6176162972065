import {
  CompanyGroupSetting,
  InventorySetting,
} from '@erp_core/erp-types/dist/types/modules/admin/companyGroupSetting';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { PlusIcon } from '@heroicons/react/24/outline';

export type OperationsProps = {
  config: InventorySetting;
  setCGS: (s: CompanyGroupSetting) => Promise<CompanyGroupSetting>;
};

export type Operation = {
  name: string;
  description: string;
  actions: Array<{
    name: string;
    description: string;
    type: 'input' | 'number' | 'select';
    options?: Array<string>;
    properties: Array<{
      name: string;
      options?: Array<string>;
    }>;
  }>;
};

export function renderOperations(): (props: OperationsProps) => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function Operations({ config, setCGS }: OperationsProps): JSX.Element {
    const operations: Array<Operation> = config.operations || [];
    const tableBody: TableBody = operations.map((x) => {
      return {
        cells: [{ value: x.name }, { value: x.description }],
      };
    });

    const header: CardHeader = {
      title: 'Operations',
      actions: [
        {
          type: 'button',
          button: {
            suffix: <PlusIcon className='w-4 inline' />,
            style: 'bg-green-600 p-1 text-white',
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Operation',
              content: ({ onClose }) => {
                const Form = renderFormV2<{
                  name: string;
                  description: string;
                }>({
                  fieldsData: [
                    {
                      label: 'Name',
                      property: 'name',
                      required: true,
                      type: 'input',
                    },
                    {
                      label: 'Description',
                      property: 'description',
                      required: true,
                      type: 'input',
                    },
                  ],
                  onSubmit: async (form) => {
                    const op = { ...form, actions: [] };
                    const inventorySetting = config;
                    inventorySetting.operations = [...operations, op];
                    await setCGS({
                      details: {
                        inventory: inventorySetting,
                      },
                    } as any);
                    onClose();
                    //save(form)
                  },
                  mapTToU: (p) => {
                    return p as any;
                  },
                  allowUnchanged: true,
                  initialFormState: { name: '', description: '' },
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={[[{ name: 'Name' }, { name: 'Description' }]]}
            body={tableBody}
          />
        </>
      ),
    };
    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
