import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  AttendanceType,
  CombinedAttDataType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  RotationalShiftDayType,
  ShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { Popover } from '@headlessui/react';
import moment from 'moment';
import { ShiftIcon } from '../../../../../utils/common';

export function ShiftInfo({
  d,
  date,
  employee,
  rotationalShiftDays,
  shiftSchedules,
  attendances,
}: {
  d: CombinedAttDataType;
  date: string;
  employee?: EmployeeProfileType;
  rotationalShiftDays: RotationalShiftDayType[];
  shiftSchedules: ShiftScheduleType[];
  attendances: AttendanceType[];
}): JSX.Element {
  const weekDay = moment(d.date, 'YYYY-MM-DD').format('dddd');
  const shiftDay =
    d?.shiftDay ||
    rotationalShiftDays?.find((x) => x.employee.id === employee?.id);
  let employeeRotationalShift: any =
    d?.shiftDay || shiftDay?.id || shiftDay?.details?.shiftId?.id
      ? shiftSchedules?.find(
          (x) =>
            x.id ===
            rotationalShiftDays?.find((x) => x.employee.id === employee?.id)
              ?.details?.shiftId?.id
        )
      : null;

  if (!employeeRotationalShift && d?.shiftSchedule) {
    employeeRotationalShift = d?.shiftSchedule;
  }

  if (employee?.details?.jobProfile?.shiftType === 'General') {
    return (
      <div
        title={`${
          employee?.details?.jobProfile?.shiftType || 'Unknown ShiftType'
        }`}
      >
        <div className='flex border border-gray-200'>
          <div className='my-auto text-md'>
            {ShiftIcon(employee?.details?.jobProfile?.shiftType)}
          </div>
          <div className='text-xs'>
            {(shiftDay && shiftDay.attendance === 'weekly-off') ||
            (!shiftDay &&
              weekDay === employee.details?.jobProfile?.weeklyOff) ? (
              '🏠 Weekly-Off'
            ) : (
              <>
                <div>{d.shiftInfo?.name || 'Unknown Shift'}</div>
                <span className='font-thin'>
                  {d?.shiftInfo?.start ||
                    employeeRotationalShift?.startTime ||
                    'xx:xx:xx'}{' '}
                  -{' '}
                  {d?.shiftInfo?.end ||
                    employeeRotationalShift?.endTime ||
                    'xx:xx:xx'}
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (employee?.details?.jobProfile?.shiftType === 'Fixed') {
    return (
      <div
        title={`${
          employee?.details?.jobProfile?.shiftType || 'Unknown ShiftType'
        }`}
      >
        {ShiftIcon(employee?.details?.jobProfile?.shiftType)}
        {weekDay === employee.details?.jobProfile?.weeklyOff ? (
          '🏠 Weekly-Off'
        ) : (
          <span>
            {employee?.details?.jobProfile?.startTime || 'not-set'} -{' '}
            {employee?.details?.jobProfile?.endTime || 'not-set'}
          </span>
        )}
      </div>
    );
  }

  if (employee?.details?.jobProfile?.shiftType === 'Flexible') {
    return (
      <div
        title={`${
          employee?.details?.jobProfile?.shiftType || 'Unknown ShiftType'
        }`}
      >
        {ShiftIcon(employee?.details?.jobProfile?.shiftType)}
        {weekDay === employee.details?.jobProfile?.weeklyOff ? (
          '🏠 Weekly-Off'
        ) : (
          <span>Not applicable</span>
        )}
      </div>
    );
  }

  if (employee?.details?.jobProfile?.shiftType === 'Rotational') {
    const employeesWorkingAtSameWorkArea = rotationalShiftDays
      .filter(
        (rs) =>
          rs.details.workArea?.id === shiftDay?.details.workArea?.id &&
          rs.employee.id !== employee.id
      )
      .map((e) => e.employee);

    return (
      <div>
        <div
          title={`${
            employee?.details?.jobProfile?.shiftType || 'Unknown ShiftType'
          }`}
        >
          {ShiftIcon(employee?.details?.jobProfile?.shiftType || '')}
          {shiftDay?.attendance === 'weekly-off' ? (
            '🏠 Weekly-Off'
          ) : (
            <span>
              {employeeRotationalShift?.startTime || '?'} -{' '}
              {employeeRotationalShift?.endTime || '?'}
            </span>
          )}
        </div>
        <Popover className='relative'>
          <Popover.Button>
            🏭 {shiftDay?.details.workArea?.name || ' --'}
          </Popover.Button>
          <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
            <div
              className='cursor-pointer hover:font-bold'
              title='Work area name'
            >
              {/* <a target='_blank' rel="noreferrer" href={`/inventory/godowns/${shiftDay?.details.workArea?.id || ''}`}>🏭 {shiftDay?.details.workArea?.name || ' --'}</a> */}
              <EmployeesInSameWorkAreaPopOver
                key={employee.id}
                employeesWorkingAtSameWorkArea={employeesWorkingAtSameWorkArea}
                date={date}
                attendances={attendances}
              />
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    );
  }

  return (
    <div
      title={`${
        employee?.details?.jobProfile?.shiftType || 'Unknown ShiftType'
      }`}
    >
      {ShiftIcon(employee?.details?.jobProfile?.shiftType || '')}
      not configured
    </div>
  );
}

const EmployeesInSameWorkAreaPopOver = ({
  employeesWorkingAtSameWorkArea,
  attendances,
  date,
}: {
  employeesWorkingAtSameWorkArea: UserIdName[];
  date: string;
  attendances: AttendanceType[];
}) => {
  return (
    <table>
      <thead>
        <tr>
          <th className='p-2'>Emp name</th>
          <th className='p-2'>First check in time</th>
          <th className='p-2'>Last check out time</th>
        </tr>
      </thead>
      <tbody>
        {employeesWorkingAtSameWorkArea.map((e, idx) => {
          const punches = attendances
            .find((a) => a.employee.id === e.id)
            ?.details?.punches?.sort((a, b) => {
              if (a?.timeStamp > b?.timeStamp) return 1;
              if (a?.timeStamp < b?.timeStamp) return -1;
              return 0;
            });

          const startPunch = punches?.[0]?.timeStamp || '';
          const startPunchDayGap = startPunch
            ? moment(startPunch).diff(moment(date), 'd')
            : 0;
          const endPunch = punches?.[punches.length - 1]?.timeStamp || '';
          const endPunchDayGap = startPunch
            ? moment(endPunch).diff(moment(date), 'd')
            : 0;

          return (
            <tr key={`${e.id}-${idx}`} className=''>
              <td className='p-2'>{e.name}</td>
              <td className='p-2'>
                {startPunch ? moment(startPunch).local().format('hh:mm a') : ''}{' '}
                {startPunchDayGap !== 0 ? `${startPunchDayGap} day` : ''}
              </td>
              <td className='p-2'>
                {endPunch ? moment(endPunch).local().format('hh:mm a') : ''}{' '}
                {endPunchDayGap !== 0 ? `${endPunchDayGap} day` : ''}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
