export const getVendorBusinessTerms = ({
  deliveryTerm,
  paymentTerm,
}: {
  deliveryTerm: string;
  paymentTerm: string;
}) => {
  return (
    <div className='mb-2'>
      <div>
        Delivery term: <span className='font-medium'> {deliveryTerm} </span>{' '}
      </div>
      <div>
        Payment term: <span className='font-medium'>{paymentTerm}</span>
      </div>
    </div>
  );
};
