import { PaymentSchedule } from '@erp_core/erp-types/dist/types/modules/common/schedules/payment';

export const getPaymentSchedule = (paymentSchedule: PaymentSchedule) => {
  return (
    <div className='border rounded-lg p-2 mt-2'>
      {/* <p className='font-medium mb-1'>Payment Schedule  {'(Payment in '}{paymentSchedule?.amountIn}{')'}</p> */}
      {paymentSchedule?.schedule?.map((ps, index) => {
        return (
          <div key={index}>
            Event: {ps.event}; Amount:{' '}
            <span className='font-medium'>
              {' '}
              {ps.amount}
              {paymentSchedule.amountIn === 'per' && '%'}{' '}
            </span>
          </div>
        );
      })}
    </div>
  );
};
