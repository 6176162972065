/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { ActionType } from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { ActionFilterType } from '../../../../models/interfaces/admin/action-admin';
import { GenericHttpWithLogsRepo } from '../generic-resource-with-logs';

export class HttpActionAdminRepo extends GenericHttpWithLogsRepo<
  ActionType,
  ActionFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/actions',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
