import { LogsType } from '@erp_core/erp-types/dist/modules/common';
import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  renderLogs,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { AttendanceInterface } from 'models/interfaces/hrd/attendance';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderAttendanceBody } from '../day-book/components/table/table-body';
import { Header } from '../day-book/components/table/table-header';

export function renderAttendanceDetails({
  isDaybook,
  attendanceService,
  userRendererService,
  useCombinedAttendance,
  useCurrentUserRoles,
  useUserAuthorization,
}: {
  isDaybook: boolean;
  attendanceService: AttendanceInterface;
  userRendererService: UserRendererInterface;
  useCombinedAttendance: UseCombinedAttendance;
  useCurrentUserRoles: UseCurrentUserRoles;
  useUserAuthorization: UseUserAuthorization;
}) {
  const Table = renderTableComponent();

  return function AttendanceDetails() {
    const [att, setAtt] = useState<CombinedAttDataType | null>(null);
    const [logs, setLogs] = useState<LogsType[]>([]);
    const { id, date } = useParams();
    const tableHeader = Header({ type: 'employee-book' });
    const { user: currentUser } = useContext(UserContext);
    const { syncSet: setAttendance } = useCombinedAttendance();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      if (id && date) {
        attendanceService.getSingleAttendance(id, date).then((res) => {
          if (res.attendance) {
            setAtt(res.attendance);
          }
          if (res.activity) {
            setLogs(
              res.activity.map((x) => {
                (x as any).changes?.forEach((y) => {
                  if (_.isObject(y.initialValue)) {
                    y.initialValue = JSON.stringify(y.initialValue);
                  }
                  if (_.isObject(y.finalValue)) {
                    y.finalValue = JSON.stringify(y.finalValue);
                  }
                  // console.log(y)
                  // _.each(_.keys(y.initialValue), z => {
                  //   if (Array.isArray(y.initialValue[z]) || _.isObject(y.initialValue[z])) {
                  //     y.initialValue[z] = JSON.stringify(y.initialValue[z]);
                  //   }
                  // })
                  // _.each(_.keys(y.finalValue), z => {
                  //   if (Array.isArray(y.finalValue[z]) || _.isObject(y.finalValue[z])) {
                  //     y.finalValue[z] = JSON.stringify(y.finalValue[z]);
                  //   }
                  // })
                  return y;
                });

                return x;
              })
            );
          }
        });
      }
    }, [date, id]);
    const AttBody = renderAttendanceBody({
      isDaybook,
      userRendererService,
      currentUser,
      getAllAttendanceSync: async () => {
        if (id && date) {
          const res = await attendanceService.getSingleAttendance(id, date);
          setAtt(res.attendance);

          return res.attendance;
        }

        return {} as any;
      },
      setAttendance,
      currentUserRoles: currentUserRoles || [],
      useUserAuthorization,
      fetchData: async () => {
        if (id && date) {
          const res = await attendanceService.getSingleAttendance(id, date);
          setAtt(res.attendance);
        }
      },
    });

    // const Actions = renderActions({
    //   isHr,
    //   setAttendance,
    //   leaveService,
    //   currentUser,
    //   useEmployees,
    //   useLeaves,
    //   getEmployeeSync,
    //   setGatePass,
    //   setOffsite,
    //   useOvertimes,
    //   saveNewOvertime,
    //   setOvertime,
    //   currentCompany,
    //   companyGroupSetting,
    //   fetchData: async () => {
    //     if (id && date) {
    //       const res = await attendanceService.getSingleAttendance(id, date)
    //       setAtt(res.attendance);
    //     }      },
    //   isSystemAdmin,
    //   attendanceService,
    //   logRecordService,
    //   getLeavesSync,
    //   setLeave,
    // });

    // const actions = Actions();

    const newtableBody: TableBody = att
      ? AttBody({
          type: 'employee-book',
          list: [{ attendance: att }],
        })
      : [];

    if (!att) {
      return <div>Loading...</div>;
    }

    const Logs = renderLogs();
    return (
      <>
        <Table
          header={tableHeader}
          body={newtableBody}
          actions={[]}
          auth={{ actions: getAllowedActions().actions }}
        />
        <Logs title='History' data={logs} />
      </>
    );
  };
}
