import { DashboardType } from '@erp_core/erp-types/dist/modules/analytics';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { HttpBomEstimationRepo } from '../datalayer/repository/http/analytics/bom/estimation';
import { HttpDashbaordRepo } from '../datalayer/repository/http/analytics/dashboard';
import { HttpHrdDashboardReportRepo } from '../datalayer/repository/http/analytics/hrd-dashboard/dashboard';
import { createUseBomEstimate } from '../hooks/analytics/bom/use-bom-estimate';
import { createUseHrdDashboardReport } from '../hooks/analytics/hrd-dashboard-report/use-hrd-dashboard-report';
import { DashboardInterface } from '../models/interfaces/analytics/dashboard';
import { DashboardFilter } from '../models/types/analytics/dashboard';
import { erpV2Api } from './admin';
import { ANALYTICS_SERVER_URI } from './server-uri';

const bomEstimateService = new HttpBomEstimationRepo(
  ANALYTICS_SERVER_URI,
  erpV2Api
);

export const hrdDashboardReportService = new HttpHrdDashboardReportRepo(
  ANALYTICS_SERVER_URI,
  erpV2Api
);

export const dashboardService = new HttpDashbaordRepo(
  ANALYTICS_SERVER_URI,
  erpV2Api
);

export const useBomEstimate = createUseBomEstimate({
  useBomEstimateService: () => bomEstimateService,
});

export const useHrdDashboardReport = createUseHrdDashboardReport({
  useHrdDashboardReportService: () => hrdDashboardReportService,
});

export const useCombinedDashboard = createUseCombinedResource<
  DashboardType,
  DashboardFilter,
  DashboardInterface
>({
  useService: () => dashboardService,
});
