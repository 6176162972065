/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { PurchaseEnquiry } from '@erp_core/erp-types/dist/modules/order';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  PurchaseEnquiryFilterType,
  PurchaseEnquiryInterface,
} from '../../../../../models/interfaces/order/purchase/purchase-enquiry';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpPurchaseEnquiryRepo
  extends GenericHttpWithLogsRepo<PurchaseEnquiry, PurchaseEnquiryFilterType>
  implements PurchaseEnquiryInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/purchase/purchase-enquiry',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async sendEnquiries(enquiries: Array<string>): Promise<void> {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });

    const reqBody = {
      enquiries,
    };
    const result = await http.post(
      '/api/v1/purchase/purchase-enquiry/send-enquiries',
      reqBody
    );

    if (result.status === 200) {
      toast.success('Enquiries Dispatched');
    }
    console.log(result);
  }
}
