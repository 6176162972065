import { CubeIcon } from '@heroicons/react/24/outline';

export function Batch({
  name,
  quantity,
  description,
  allocated,
}: {
  id: string;
  name: string;
  quantity: number;
  description?: string | JSX.Element;
  allocated?: boolean;
  state: 'real' | 'virtual';
}): JSX.Element {
  return (
    <div className='border p-1'>
      <div
        className={`p-1 mx-auto text-center m-1 ${
          allocated ? 'bg-green-100' : 'bg-gray-100'
        }`}
      >
        <CubeIcon className='inline-block w-8 h-8 text-blue-500' />
        {quantity}
      </div>

      <div className='mx-auto text-center'>
        <label className='block text-blue-600 text-md font-bold'>{name}</label>
        {description ? (
          <label className='block text-gray-800 text-md font-bold mb-2'>
            {description}
          </label>
        ) : null}
      </div>
    </div>
  );
}
