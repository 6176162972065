export function renderPEName(): // eslint-disable-next-line
({ id, name }: { id: string; name: string }) => JSX.Element {
  return function PE({ id, name }: { id: string; name: string }): JSX.Element {
    return (
      <a
        href={`/purchase/registers/enquiry/${id}/details`}
        target='_blank'
        className='border bg-pink-100 rounded text-pink-800 text-xs'
      >
        {name}
      </a>
    );
  };
}
