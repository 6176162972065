import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type AddAttendanceDevice = {
  name: string;
  model: string;
  type: string;
  physicalLocation: string;
  ipAddress: string;
};
export const renderAttendanceDeviceForm = () => {
  return function AttendanceDeviceForm({
    data,
    onSave,
  }: {
    data: {
      locations: { text: string; value: string }[];
    };
    onSave: any;
  }) {
    const formProps: FormProps<AddAttendanceDevice> = {
      fieldsData: [
        { property: 'name', type: 'input', required: true, label: 'Name' },
        { property: 'model', type: 'input', required: true, label: 'Model' },
        {
          property: 'type',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'fingerprint-scanner', value: 'fingerprint-scanner' },
          ],
          required: true,
          label: 'Type',
        },
        {
          property: 'physicalLocation',
          type: 'select',
          required: true,
          label: 'Location',
          options: [{ text: 'select', value: '' }, ...data.locations],
        },
        {
          property: 'ipAddress',
          type: 'input',
          required: true,
          label: 'IP Address',
        },
      ],
      initialFormState: {
        name: '',
        model: '',
        type: '',
        ipAddress: '',
        physicalLocation: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<AddAttendanceDevice>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<AddAttendanceDevice>
    ): AddAttendanceDevice {
      return b as AddAttendanceDevice;
    }
  };
};
