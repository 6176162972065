import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { LicenseContractDocType } from 'models/types/order/license-contract-doc-type';

export const renderLicenseContractDocForm = () => {
  return function LicenseContractDocForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: LicenseContractDocType;
    isEdit: boolean;
  }) {
    const formProps: FormProps<Partial<LicenseContractDocType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
        },
        {
          property: 'description',
          type: 'text-area',
          rows: 3,
          required: true,
          label: 'Description',
        },
        {
          property: 'dependsUpon',
          type: 'tags',
          label: 'Depends Upon',
        },
        {
          property: 'affectUpon',
          type: 'tags',
          label: 'Affects On',
        },
        {
          property: 'level',
          type: 'select',
          required: true,
          label: 'Level',
          options: [
            { text: 'Select', value: '' },
            { text: 'transaction', value: 'transaction' },
            { text: 'item', value: 'item' },
          ],
        },
      ],
      initialFormState: {
        name: data?.name || '',
        description: data?.description || '',
        dependsUpon: data?.dependsUpon || [],
        affectUpon: data?.affectUpon || [],
        level: data?.level || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
    };

    const Form = renderFormV2<Partial<LicenseContractDocType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<LicenseContractDocType>>
    ): LicenseContractDocType {
      return {
        name: b.name || data?.name,
        description: b.description || data?.description,
        dependsUpon: b.dependsUpon || data?.dependsUpon,
        affectUpon: b.affectUpon || data?.affectUpon,
        level: b.level || data?.level,
      } as any;
    }
  };
};
