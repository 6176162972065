/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { PfReturnsType } from '@erp_core/erp-types/dist/modules/hrd';
import { StatutoryReturnFilterType } from '@erp_core/erp-types/dist/types/modules/hrd/statutory-returns/statutory-returns';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpPFReturnRepo extends GenericHttpWithLogsRepo<
  PfReturnsType,
  StatutoryReturnFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/employees/pf-returns',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
