/* eslint-disable no-unused-vars */
export const removeOverlapped = (
  aggregated?: { id: string; plannedSchedule: any; proposedSchedule: any }[]
) => {
  if (!aggregated) return [];
  const sched = aggregated.map((a) => {
    const diff = a.proposedSchedule.map((prs) => {
      // will be removing overlapping enteries
      return prs;
    });

    return {
      id: a.id,
      schedule: [...diff, ...a.plannedSchedule],
    };
  });

  return sched;
};
