import { SRType } from '../components/create-statutory-reports';

export const categorySorter = [
  {
    grades: [
      'G03',
      'G04',
      'G05',
      'G06',
      'G07',
      'G08',
      'G09',
      'G9.1',
      'G9.2',
      'G9.3',
      'G10',
      'G11',
      'G12',
      'GZ1',
      'G13',
      'G14',
      'G15',
      'G16',
      'G17',
      'G18',
      'G19',
    ],
    name: 'Staff-and-Workers',
  },
  {
    grades: ['G20', 'G21'],
    name: 'Director',
  },
  {
    grades: ['G01', 'G02'],
    name: 'Apprentice',
  },
  {
    grades: ['K-01'],
    name: 'Helper',
  },
  {
    grades: ['K-02'],
    name: 'Staff',
  },
  {
    grades: ['K-03'],
    name: 'Owner',
  },
];

export function getCategories({
  srList,
}: {
  srList: SRType;
}): Array<{ name: string; list: SRType }> {
  const categories: Array<{ name: string; list: SRType }> = [];
  srList.data.forEach((x) => {
    for (const cat of categorySorter) {
      if (cat.grades.includes(x.employee.details.grade?.id)) {
        let Category = categories.find(
          (y) => y.name === `${cat.name}-${x.employee.details.workLocation}`
        );
        if (!Category) {
          categories.push({
            name: `${cat.name}-${x.employee.details.workLocation}`,
            list: { data: [], month: srList.month },
          });
          Category = categories.find(
            (y) => y.name === `${cat.name}-${x.employee.details.workLocation}`
          );
        }
        if (Category) {
          Category.list.data.push(x);
        }
      }
    }
  });

  return categories;
}
