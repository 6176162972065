/* eslint-disable react-hooks/exhaustive-deps */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  LeadCustomerEnquiryItems,
  LeadCustomerEnquiryType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import toast from 'react-hot-toast';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClientItem } from '../../../../hooks/order/sales/client-item/use-client-item';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { renderAddProductForm } from './form';

type RenderEnquiryProductsTable = {
  loading: boolean;
  leadCustomerEnquiry: LeadCustomerEnquiryType;
  setLeadCustomerEnquiry: (
    s: LeadCustomerEnquiryType
  ) => Promise<LeadCustomerEnquiryType>;
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
  useCombinedClientItem: UseCombinedClientItem;
  itemService: ItemInterface;
};

export const renderEnquiryProductsTable = ({
  loading,
  leadCustomerEnquiry,
  useCombinedItem,
  useCombinedClient,
  useCombinedClientItem,
  itemService,
  setLeadCustomerEnquiry,
}: RenderEnquiryProductsTable) => {
  return function EnquiryProductsTable() {
    // const { syncSet: setLeadCustomerEnquiryItems } = useLeadCustomerEnquiryItems();
    const AddProductForm = renderAddProductForm({
      leadCustomerEnquiry,
      useCombinedItem,
      useCombinedClient,
      itemService,
      useCombinedClientItem,
    });

    // TODO: Adding enquiry-items entry after enquiry is created
    // creating the enquiry it ws giving error for insert in enquiry table
    // To be handled in backend...
    const handleAddProduct = async (formData: any) => {
      try {
        const finalData: Partial<LeadCustomerEnquiryType> = {
          ...leadCustomerEnquiry,
          id: leadCustomerEnquiry?.id || '',
          enquiryItems: [
            // ...leadCustomerEnquiry?.enquiryItems,
            {
              item: {
                id: formData?.product?.id,
                name: formData?.product?.name,
              },
              details: {
                endUse: formData?.endUse,
                totalQuantity: formData?.totalQuantity,
                totalQuantityUom: formData?.totalUom?.toString(),
                info: _.values(formData?.info).map((pi) => ({
                  quantity: pi?.quantity,
                  uom: pi?.uom.toString(),
                  deliveryDate: pi?.deliveryDate,
                })),
              },
            },
          ] as LeadCustomerEnquiryItems[],
        } as LeadCustomerEnquiryType;
        await setLeadCustomerEnquiry(finalData as LeadCustomerEnquiryType);
        toast('Added product successfully');
      } catch (err) {
        // console.log('Error');
        toast('Something went wrong!');
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Products',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Product',
            behaviour: 'modal',
            modal: {
              title: 'Add Product',
              content: ({ onClose }) => {
                return (
                  <AddProductForm
                    onSave={async (formData) => {
                      await handleAddProduct(formData);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Product' },
        { name: 'End Use' },
        { name: 'Total Quantity' },
        { name: 'Total UOM' },
      ],
    ];

    const tableBody: TableBody =
      leadCustomerEnquiry?.enquiryItems?.map((e) => ({
        cells: [
          { value: e?.item?.name || '' },
          {
            value: (
              <>
                {e?.details?.endUse
                  ? e?.details?.endUse.map((o) => `${o.name},`)
                  : '--'}
              </>
            ),
          },
          { value: e?.details?.totalQuantity || '' },
          { value: e?.details?.totalQuantityUom || '' },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div className='w-full space-y-3'>
          {loading ? (
            <Recat className='h-5 inline animate-pulse mx-4' />
          ) : (
            <Card header={cardHeader} body={cardBody} />
          )}
        </div>
      </>
    );
  };
};
