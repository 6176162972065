import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import {
  AdjustmentsVerticalIcon,
  ChartBarSquareIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, Route, Routes, useLocation } from 'react-router-dom';
import { UserAuthorization } from '../../../models/interfaces/admin/user-admin';
import { ModuleSidebarLink, ModuleType } from '../modules';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

type Menu = {
  type?: 'path' | 'jsx';
  auth?: string;
  // component?: ({ config }: { config: any }) => JSX.Element; // TODO: Should be compulsory
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  path: string;
  name: string;
  hidden?: boolean;
};

export type SidePaths = {
  auth?: string;
  name: string;
  path?: string;
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element;
  iconStyle?: string;
  menu?: Menu[];
};

export type SideBarProps = {
  base: string;
  // Body: (c: { config: any; menu: SidePaths[] }) => JSX.Element;
  sidePaths: SidePaths[];
};

export const SideBar = ({
  // myRoutes,
  userAuth,
  modules,
}: {
  // myRoutes: SideBarProps[];
  userAuth: UserAuthorization;
  modules: ModuleType[];
}) => {
  const [expanded, setExpanded] = useState(
    JSON.parse(localStorage.getItem('expanded') || 'false')
  );
  const [allSubExpanded, setAllSubExpanded] = useState(
    JSON.parse(localStorage.getItem('allSubExpanded') || 'false')
  );

  let { pathname } = useLocation();
  const [currentModule, setCurrentModule] = useState<string>('');

  useEffect(() => {
    if (pathname) {
      const val = pathname.split('/')[1];
      if (val !== currentModule) {
        setCurrentModule(val);
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  let query = useQuery();

  useEffect(() => {
    localStorage.setItem('expanded', JSON.stringify(expanded));
    localStorage.setItem('allSubExpanded', JSON.stringify(allSubExpanded));
  }, [expanded, allSubExpanded]);

  return (
    <>
      {!expanded ? (
        <span className='bg-gray-200 p-1 rounded-full fixed top-18 left-2 md:invisible z-50'>
          <ChevronDoubleRightIcon
            className='w-5 h-5 animate-pulse text-indigo-900'
            onClick={() => setExpanded(!expanded)}
          />
        </span>
      ) : null}
      <div
        className={`${
          expanded ? 'w-64' : 'w-0 lg:w-12'
        } transition-all container scrollbar-thin scrollbar-track-indigo-800 scrollbar-thumb-indigo-900 overflow-y-auto bg-indigo-900 text-white h-full`}
      >
        <div className='sticky top-0 w-full p-4'>
          <div className='pt-12 px-2 w-full'>
            <div
              className='absolute left-4 top-2 cursor-pointer'
              onClick={() => setAllSubExpanded(!allSubExpanded)}
            >
              {expanded ? (
                allSubExpanded ? (
                  <ChevronUpIcon className='w-5 h-5' />
                ) : (
                  <ChevronDownIcon className='w-5 h-5' />
                )
              ) : null}
            </div>
            {expanded ? (
              <Link
                className='absolute cursor-pointer top-2 w-44 text-center bg-indigo-800 left-10 capitalize text-white'
                to={`/${currentModule}`}
              >
                <ChartBarSquareIcon className='w-4 h-4 text-white inline' />{' '}
                {currentModule}
              </Link>
            ) : null}
            <div
              className='absolute right-2 top-2 cursor-pointer'
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? (
                <ChevronDoubleLeftIcon className='w-5 h-5' />
              ) : (
                <ChevronDoubleRightIcon className='w-5 h-5' />
              )}
            </div>
          </div>
          <Routes>
            {modules.map((module, index) => (
              <Route
                key={index}
                path={`${module.path}/*`}
                element={
                  <div className='items-start text-base'>
                    {module.sidebarLinks.map((sp, i) => {
                      return (
                        <div key={`${index}-${i}`} className='pb-1'>
                          {sp.menu ? (
                            <>
                              {!sp.auth ||
                              (sp.auth &&
                                userAuth.actions &&
                                userAuth.actions[sp.auth] &&
                                userAuth.actions[sp.auth].action) ? (
                                <NewSubMenu
                                  userAuth={userAuth}
                                  allSubExpanded={allSubExpanded}
                                  expanded={expanded}
                                  sp={sp}
                                  base={`${module.path}/*`}
                                  currentPath={pathname}
                                  query={query.get('tab')}
                                />
                              ) : null}
                            </>
                          ) : (
                            <NewSimpleMenu sp={sp} expanded={expanded} />
                          )}
                        </div>
                      );
                    })}
                  </div>
                }
              ></Route>
            ))}
          </Routes>
        </div>
        <Recat className='h-3 z-50 absolute left-1 bottom-2' />
      </div>
    </>
  );
};

const NewSimpleMenu = ({
  sp,
  expanded,
}: {
  sp: ModuleSidebarLink;
  expanded: boolean;
}) => {
  return (
    <NavLink to={sp.path}>
      <div className='font-bold text-left text-blue-500'>
        {sp.icon ? (
          <span className='w-5 h-5'>
            <sp.icon className='w-5 h-5 inline' />
          </span>
        ) : (
          <AdjustmentsVerticalIcon className='w-5 mr-3 h-5' />
        )}
        {expanded ? sp.name : ''}
      </div>
    </NavLink>
  );
};

function renderNewCurrentStyling(
  currentPath: string,
  targetPath: string,
  query
) {
  let comparePath = `${targetPath}`;
  if (query) {
    currentPath += `?tab=${query}`;
  }

  return currentPath.includes(comparePath)
    ? 'text-indigo-900 font-semibold bg-slate-300 rounded-md'
    : 'text-zinc-200';
}

const NewSubMenu = ({
  sp,
  base,
  allSubExpanded,
  expanded,
  userAuth,
  currentPath,
  query,
}: {
  sp: ModuleSidebarLink;
  allSubExpanded: boolean;
  base: string;
  expanded: boolean;
  userAuth: UserAuthorization;
  currentPath: string;
  query: string | null;
}) => {
  const [subMenuExpanded, setSubMenuExpanded] = useState(true);
  const renderLink = (t: Menu, idx: number) => {
    const renderMenu = () => (
      <div key={idx} className='w-full'>
        <NavLink key={t.path} to={`${t.path}`}>
          <div
            className={`hover:font-bold hover:bg-slate-400 hover:text-indigo-800 rounded-md pl-3 ${renderNewCurrentStyling(
              currentPath,
              t.path,
              query
            )}`}
          >
            {t.Icon ? <t.Icon className='w-4 h-4 inline' /> : null} {t.name}
          </div>
        </NavLink>
      </div>
    );
    if (!t.auth) return renderMenu();
    if (t.auth && userAuth.actions[t.auth] && userAuth.actions[t.auth].action)
      return renderMenu();

    return null;
  };

  useEffect(() => {
    setSubMenuExpanded(allSubExpanded);
  }, [allSubExpanded]);

  return (
    <div className='flex flex-col items-start'>
      {sp.menu?.length &&
      sp.menu.find(
        (t) =>
          !t.auth ||
          (t.auth &&
            userAuth.actions[t.auth] &&
            userAuth.actions[t.auth].action)
      ) ? (
        <>
          <div
            className='group/item font-bold flex text-left text-white cursor-pointer flex w-full h-8'
            onClick={() => setSubMenuExpanded(!subMenuExpanded)}
          >
            <div className='flex-none mr-2'>
              {sp.icon ? (
                <span className='w-5 mr-3 h-5'>
                  <sp.icon className='w-5 h-5 group-hover/item:w-6 group-hover/item:h-6' />
                </span>
              ) : (
                <AdjustmentsVerticalIcon className='w-5 mr-3 h-5' />
              )}
            </div>
            <div className='flex-auto'>{expanded ? sp.name : ''}</div>
            <div className='flex-none'>
              {expanded ? (
                <>
                  {subMenuExpanded ? (
                    <ChevronUpIcon className='w-5 h-5' />
                  ) : (
                    <ChevronDownIcon className='w-5 h-5' />
                  )}
                </>
              ) : null}
            </div>
          </div>
          {sp.menu ? (
            <div
              className={`${
                !expanded || !subMenuExpanded ? 'hidden' : ''
              } transition-all text-left w-full`}
            >
              {sp.menu.map((t, idx) => {
                if (!t.hidden) {
                  return renderLink(t, idx);
                }
                return null;
              })}
            </div>
          ) : (
            <div />
          )}
        </>
      ) : null}
    </div>
  );
};
