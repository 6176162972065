import {
  BomDependency,
  BomDependencyFilter,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpBomDependencyRepo extends GenericHttpWithLogsRepo<
  BomDependency,
  BomDependencyFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/work-order/bom-dependency',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
