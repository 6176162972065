import {
  RotationalShiftScheduleFilter,
  RotationalShiftScheduleType,
} from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-schedule';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class RotationalShiftScheduleHttpRepo extends GenericHttpWithLogsRepo<
  RotationalShiftScheduleType,
  RotationalShiftScheduleFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/attendance/rotational-shift-schedule',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
