import { Account } from '@erp_core/erp-icons/icons/web/account';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { manufacturingVoucherService } from '../../config/order';
import { UseCombinedMetric } from '../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedUser } from '../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedEmployeeProfile } from '../../hooks/hrd/employee/profile/use-employee-profile';
import { UseBatchItemAllocate } from '../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseCombinedBatchItemGodown } from '../../hooks/inventory/item/batch/use-batch-item-godown';
import { UsePhysicalStock } from '../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseCombinedItem } from '../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../hooks/order/sales/client/use-client';
import { UseCombinedSalesEnquiry } from '../../hooks/order/sales/enquiry/use-sales-enquiry';
import { UseCombinedLeadCustomerEnquiry } from '../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiry';
import { UseCombinedLeadCustomer } from '../../hooks/order/sales/lead-customer/use-lead-customer';
import { UseCombinedLeadEnquiry } from '../../hooks/order/sales/lead-enquiry/use-lead-enquiry';
import { UseCombinedSalesOrder } from '../../hooks/order/sales/order/use-sales-order';
import { UseCombinedManufacturingVoucher } from '../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';
import { UseVirtuallyReserveMfVoucher } from '../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { UserRendererInterface } from '../common/fragments/user';
import renderSalesItemEnquiryDetails from './item-enquiry';
import renderSalesLeadEnquiryDetails from './lead-enquiry';
import { renderSalesEnquiryDetails } from './sales-enquiry';
import { renderSalesEnquiryLeadDetailsV2 } from './sales-enquiry/sales-enquiry-lead-details-v2';

type RenderSalesMastersProps = {
  usePhysicalStock: UsePhysicalStock;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useCombinedManufacturingVoucher: UseCombinedManufacturingVoucher;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
  useCombinedSalesOrder: UseCombinedSalesOrder;
  useCombinedSalesEnquiry: UseCombinedSalesEnquiry;
  useCombinedUser: UseCombinedUser;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedMetric: UseCombinedMetric;
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedLeadCustomerEnquiry: UseCombinedLeadCustomerEnquiry;
  userRendererService: UserRendererInterface;
  useCombinedLeadEnquiry: UseCombinedLeadEnquiry;
};

export function renderSalesMasters({
  usePhysicalStock,
  useCombinedBatchItemGodown,
  useBatchGodownItemAllocate,
  useVirtualMfStock,
  useCombinedManufacturingVoucher,
  useVirtuallyReserveMfVoucher,
  useCombinedItem,
  useCombinedClient,
  useCombinedSalesOrder,
  useCombinedSalesEnquiry,
  useCombinedUser,
  useCombinedEmployeeProfile,
  useCombinedMetric,
  useCombinedLeadCustomer,
  useCombinedLeadCustomerEnquiry,
  userRendererService,
  useCombinedLeadEnquiry,
}: RenderSalesMastersProps): () => JSX.Element {
  return function SalesMasters(): JSX.Element {
    const NewTabs = renderTabsV2Component();

    const SalesEnquiryDetailsV2 = renderSalesEnquiryDetails({
      hooks: {
        useCombinedItem,
        useCombinedClient,
        useCombinedSalesOrder,
        useCombinedSalesEnquiry,
        useCombinedUser,
      },
      services: {
        manufacturingVoucherService,
        userRendererService,
      },
    });

    const SalesEnquiryDetailsNewV2 = renderSalesEnquiryLeadDetailsV2({
      hooks: {
        useCombinedItem,
        useCombinedUser,
        useCombinedEmployeeProfile,
        useCombinedMetric,
        useCombinedLeadCustomer,
        useCombinedLeadCustomerEnquiry,
        useCombinedClient,
      },
      services: {
        manufacturingVoucherService,
        userRendererService,
      },
    });

    const LeadEnquiryDetails = renderSalesLeadEnquiryDetails({
      useCombinedLeadEnquiry,
      useCombinedClient,
      useCombinedItem,
    });

    const SalesEnquiryItemsDetailsV2 = renderSalesItemEnquiryDetails({
      useCombinedSalesEnquiry,
      usePhysicalStock,
      useCombinedBatchItemGodown,
      useBatchGodownItemAllocate,
      useVirtualMfStock,
      useCombinedManufacturingVoucher,
      useVirtuallyReserveMfVoucher,
    });

    const newCategories: DataArray = [
      { name: 'sales-enquiries', Fn: SalesEnquiryDetailsV2 },
      { name: 'sales-enquiries-v2', Fn: SalesEnquiryDetailsNewV2 },
      { name: 'lead-enquiries', Fn: LeadEnquiryDetails },
      { name: 'item-enquiries', Fn: SalesEnquiryItemsDetailsV2 },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      icon: (
        <Account className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      title: 'Sales Enquiries',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
