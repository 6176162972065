import {
  GetSalaryAdvanceAmountReq,
  GetSalaryAdvanceAmountResp,
  SalaryAdvanceInterface,
} from 'models/interfaces/hrd/salary-advance';
import { useState } from 'react';

export type UseSalaryAdvanceEligibleAmt = () => {
  getSalaryAdvanceEligibleAmt: (p: GetSalaryAdvanceAmountReq) => void;
  data: GetSalaryAdvanceAmountResp;
};

export interface UseSalaryAdvanceEligibleAmtOptions {
  useSalaryAdvanceEligibleAmtService: () => SalaryAdvanceInterface;
}

export function createUseSalaryAdvanceEligibleAmt({
  useSalaryAdvanceEligibleAmtService,
}: UseSalaryAdvanceEligibleAmtOptions): UseSalaryAdvanceEligibleAmt {
  return () => {
    const salaryAdvService = useSalaryAdvanceEligibleAmtService();
    const [data, setData] = useState<any>();

    const eligibleAdvAmtApiCall = async (p: GetSalaryAdvanceAmountReq) => {
      try {
        const resp = await salaryAdvService.getEligibleAdvanceAmt(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      getSalaryAdvanceEligibleAmt: (p: GetSalaryAdvanceAmountReq) => {
        eligibleAdvAmtApiCall(p);
      },
      data,
    };
  };
}
