import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseCombinedClosureDatePeriod } from '../../../../hooks/quality-assurance/closure-date-period/use-closure-date-period';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

// eslint-disable-next-line

export type ClassificationOfchangeType = {
  classificationMajorMinor: string;
  qaReviewComments: string;
  // tentativeDateClosure: string;
};

type RenderAddClassificationOfchange = {
  useCombinedClosureDatePeriod: UseCombinedClosureDatePeriod;
};

export function renderClassificationOfchangeForm({
  useCombinedClosureDatePeriod,
}: RenderAddClassificationOfchange) {
  return function AddClassificationOfchangeForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const {
      list: closureDatePeriods,
      getAll: getClosureDatePeriods,
    } = useCombinedClosureDatePeriod();

    useEffect(() => {
      getClosureDatePeriods();
      // eslint-disable-next-line
    }, []);

    const formProps: FormProps<ClassificationOfchangeType> = {
      fieldsData: [
        {
          type: 'select',
          label: 'Classification of Change',
          property: 'classificationMajorMinor',
          options: [
            { value: 'select', text: 'select' },
            { value: 'Major', text: 'Major' },
            { value: 'Minor', text: 'Minor' },
          ],
        },
        {
          type: 'text-area',
          label: 'QA Comments',
          property: 'qaReviewComments',
          rows: 3,
        },
        //   {
        //     type: 'date',
        //     label: 'Tentative date of closure',
        //     property: 'tentativeDateClosure'
        //   }
      ],
      initialFormState: {
        classificationMajorMinor: '',
        qaReviewComments: '',
        // tentativeDateClosure: ''
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        const days = closureDatePeriods?.find(
          (x) => x.type === f.classificationMajorMinor
        )?.days;
        const newdt = moment().add(days, 'days');

        await onSave(({
          details: {
            ...data?.details,
            classificationMajorMinor:
              f.classificationMajorMinor ||
              data?.details?.classificationMajorMinor,
            qaReviewComments:
              f.qaReviewComments || data?.details?.qaReviewComments,
            tentativeDateClosure: newdt || data?.details?.tentativeDateClosure,
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ClassificationOfchangeType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<ClassificationOfchangeType>
    ): ClassificationOfchangeType {
      return (b as unknown) as ClassificationOfchangeType;
    }
  };
}
