import {
  LicenseContract,
  LicenseContractDocType,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { LicenseContractType } from '@erp_core/erp-types/dist/types/modules/order/work-order/license-contract';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedItem } from 'hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from 'hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from 'hooks/order/sales/client/use-client';
import { UseCombinedLicenseContractDocType } from 'hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';

export type LicenseContractFormType = {
  name: string;
  shortName: string;
  description: string;
  refNo: string;
  docType: LicenseContractDocType;
  addOnType: LicenseContractType;
  validFrom: string;
  expiresAt: string;
  partyName: string;
  partyType: string;
  item: IdName;
  client: IdName;
  vendor: IdName;
};

export const renderLicenseContractorm = ({
  useCombinedItem,
  useCombinedLicenseContractDocType,
  useCombinedClient,
  useCombinedVendor,
}: {
  useCombinedItem: UseCombinedItem;
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
  useCombinedClient: UseCombinedClient;
  useCombinedVendor: UseCombinedVendor;
}) => {
  return function LicenseContractForm({
    onSave,
    data,
  }: {
    onSave: any;
    data?: LicenseContract;
  }) {
    const formProps: FormProps<LicenseContractFormType> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
        },
        {
          property: 'shortName',
          type: 'input',
          required: true,
          label: 'Short Name',
        },
        {
          property: 'description',
          type: 'text-area',
          rows: 3,
          required: true,
          label: 'Description',
        },
        {
          property: 'refNo',
          type: 'input',
          label: 'Refference No',
        },
        {
          property: 'docType',
          type: 'select',
          label: 'License Contract Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'lic', value: 'lic' },
            { text: 'contract', value: 'contract' },
          ],
        },
        {
          property: 'addOnType',
          label: 'Document Type',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedLicenseContractDocType,
            onSearchValueSelect: (u: any) => {},
          },
        },
        {
          property: 'partyType',
          type: 'select',
          required: true,
          label: 'Party Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'client', value: 'client' },
            { text: 'vendor', value: 'vendor' },
            { text: 'regulatory body', value: 'regulatorybody' },
          ],
        },
        {
          property: 'client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect: (u: any) => {},
          },
          dependentOn: (formData) => {
            return formData.partyType === 'client';
          },
        },
        {
          property: 'vendor',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedVendor,
            onSearchValueSelect: (u: any) => {},
          },
          dependentOn: (formData) => {
            return formData.partyType === 'vendor';
          },
        },
        {
          property: 'partyName',
          type: 'input',
          label: 'Party Name',
          dependentOn: (formData) => {
            return formData.partyType === 'regulatorybody';
          },
        },
        {
          property: 'validFrom',
          type: 'date',
          required: true,
          label: 'Valid From',
        },
        {
          property: 'expiresAt',
          type: 'date',
          required: true,
          label: 'Expires At',
        },

        {
          property: 'item',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect: (u: any) => {},
          },
        },
      ],
      initialFormState: {
        name: data?.name || '',
        description: data?.description || '',
        addOnType: '',
        item: '',
        shortName: '',
        refNo: '',
        docType: '',
        validFrom: '',
        expiresAt: '',
        partyName: '',
        partyType: '',
        client: '',
        vendor: '',
      },
      onSubmit: async (f) => {
        console.log('formdata', f);
        await onSave(({
          name: f.name || '',
          description: f.description || '',
          addOnType: f.addOnType,
          item: {
            id: f.item.id,
            name: f.item.name,
          },
          shortName: f.shortName,
          refNo: f.refNo,
          docType: {
            id: f.docType.id,
            name: f.docType.name,
          },
          validFrom: f.validFrom,
          expiresAt: f.expiresAt,
          document: '',
          partyName: f.partyName,
          partyType: f.partyType,
          details: {
            client: f.client || { id: '', name: '' },
            vendor: f.vendor || { id: '', name: '' },
          },
        } as unknown) as LicenseContract);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
    };

    const Form = renderFormV2<LicenseContractFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      p: FormDataType<LicenseContractFormType>
    ): LicenseContractFormType {
      return (p as unknown) as LicenseContractFormType;
    }
  };
};
