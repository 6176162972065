import {
  JobsType,
  TaskFilter,
  TaskType,
} from '@erp_core/erp-types/dist/modules/admin';
import { JobsFilter } from '@erp_core/erp-types/dist/types/modules/admin/scheduler/job';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { SchedulerJobHttpRepo } from '../datalayer/repository/http/scheduler/jobs';
import { SchedulerTaskHttpRepo } from '../datalayer/repository/http/scheduler/task';
import { JobInterface } from '../models/interfaces/admin/scheduler/job';
import { TaskInterface } from '../models/interfaces/admin/scheduler/task';
import { erpV2Api } from './admin';
import { SCHEDULER_SERVER_URI } from './server-uri';

export const schedulertaskService = new SchedulerTaskHttpRepo(
  SCHEDULER_SERVER_URI,
  erpV2Api
);

export const useCombinedTask = createUseCombinedResource<
  TaskType,
  TaskFilter,
  TaskInterface
>({
  useService: () => schedulertaskService,
});

export const schedulerjobService = new SchedulerJobHttpRepo(
  SCHEDULER_SERVER_URI,
  erpV2Api
);

export const useCombinedJob = createUseCombinedResource<
  JobsType,
  JobsFilter,
  JobInterface
>({
  useService: () => schedulerjobService,
});
