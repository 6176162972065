import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';

export function renderSalParamCode(): ({
  incomingData,
  onClose,
  saveParams,
}: {
  incomingData: any;
  onClose: () => void;
  saveParams: React.Dispatch<React.SetStateAction<SalaryParamGroup>>;
}) => JSX.Element {
  return function Code({
    incomingData,
    onClose,
    saveParams,
  }: {
    incomingData: any;
    onClose: () => void;
    saveParams: React.Dispatch<React.SetStateAction<SalaryParamGroup>>;
  }): JSX.Element {
    const [data, setData] = useState<string>(JSON.stringify(incomingData));

    function onCodeSave() {
      // const file = new File([data], `${id.toLowerCase()}.html`, { type: 'text/plain'});
      // setTemplate(`${currentCompanyGroup.id}/salary-slip-templates/${id.toLowerCase()}`, file, 'html');
      saveParams(JSON.parse(data));
      onClose();
    }

    return (
      <div className='h-[calc(100vh_-_15rem)]'>
        <textarea
          rows={15}
          className='w-full'
          value={data}
          onChange={(e) => setData(e.target.value)}
        ></textarea>
        <LoadingButton
          defaultStyle='bg-green-600 text-white p-2 border rounded-lg my-2'
          behaviorFn={async () => onCodeSave()}
          text='Save'
        />
      </div>
    );
  };
}
