/* eslint-disable class-methods-use-this */
/* eslint-disable no-await-in-loop */
import {
  UpdateUserRole,
  UserRolesType,
} from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { UserRolesAdminInterface } from '../../../../models/interfaces/admin/user-roles-admin';

export class HttpUserRolesRepo implements UserRolesAdminInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getUserRoles(id: string): Promise<UserRolesType> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const res = await httpInstance.get(`/api/v1/users/user-role/${id}`);
    if (res.status !== 200) {
      throw new Error(`User Role with ${id} does not exist`);
    }

    const { data } = res;

    return data as UserRolesType;
  }

  async setUserRoles(s: Array<UpdateUserRole>): Promise<UserRolesType> {
    for (let i = 0; i < s.length; i++) {
      const httpInstance = await this.erpv2Api.getAuthorizedInstance({
        apiUrl: this.apiUrl,
      });
      const res = await httpInstance.patch(
        `/api/v1/users/user-role/${s[i].userId}`,
        {
          ...s[i],
        }
      );
      if (res.status !== 200) {
        throw new Error(`User Role with ${s[i].userId} updation failed`);
      }
    }

    return this.getUserRoles(s[0].userId);
  }
}
