import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

const header: TableHeader = [
  [
    { name: 'Packing type' },
    { name: 'Filling factor' },
    { name: 'Filled volume' },
    { name: 'Net Weight' },
    { name: 'Commercial' },
    { name: 'Is standard' },
    { name: 'Actions' },
  ],
];

type RenderItemBomTab = {
  itemData: ItemRes;
};

export function renderItemPacking({ itemData }: RenderItemBomTab) {
  return function () {
    const Card = renderCardComponent();
    const Table = renderTableComponent();

    const tableBody: TableBody =
      itemData?.details?.packaging?.map((p) => {
        return {
          cells: [
            { value: p.packagingType || '' },
            { value: p.fillingFactor || '' },
            { value: p.filledVolume || '' },
            { value: p.netWeight || '' },
            { value: p.commercial || '' },
            { value: p.isStandard?.toString() || '' },
            {
              value: (
                <PencilSquareIcon
                  className='w-5 h-5 hover:cursor-pointer hover:text-blue-500'
                  onClick={() => {
                    console.log('Edit');
                  }}
                />
              ),
            },
          ],
        };
      }) || [];
    return (
      <div className='space-y-3'>
        <Card
          header={{
            title: 'Packaging',
            actions: [
              {
                type: 'button',
                button: {
                  behaviour: 'click',
                  onClick: async () => {
                    console.log('');
                  },
                  name: 'Add Packing',
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={header} body={tableBody} />,
          }}
        />
      </div>
    );
  };
}
