import { FinishedGood } from '@erp_core/erp-icons/icons/web/finished-good';
import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import {
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import {
  CalendarDaysIcon,
  HomeIcon,
  UserIcon,
} from '@heroicons/react/24/outline';

export function renderStep3({
  onClose,
}: {
  onClose: () => void;
}): (p: {
  form1: any;
  form2: any;
  setBomSync: (s: BOMRes) => Promise<BOMRes>;
}) => JSX.Element {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  const tableHeader = [
    [{ name: 'Name' }, { name: 'Quantity' }, { name: 'UOM' }],
  ];
  return function Step3({
    form1,
    form2,
    setBomSync,
  }: {
    form1: any;
    form2: any;
    setBomSync: (s: BOMRes) => Promise<BOMRes>;
  }): JSX.Element {
    return (
      <div>
        <Card
          header={{
            title: `Bom For ${form1.grade.name}`,
            subheading: 'Select item grades are',
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Header form={form1} />
                <div className='border border-gray-200 p-1 rounded-md my-2'>
                  <Table
                    header={tableHeader}
                    body={form2.selectedItems.map((x) => {
                      return {
                        cells: [
                          { value: x.name },
                          {
                            value: `${
                              (x.ratio || 1) * parseInt(form1.quantity)
                            }`,
                          },
                          {
                            value: x.uom,
                          },
                        ],
                      };
                    })}
                  />
                  <div className='flex flex-row-reverse'>
                    <LoadingButton
                      behaviorFn={async () => {
                        await setBomSync({
                          status: 'draft',
                          priority: form1.priority,
                          bomType: form1.bomType,
                          item: form1.grade,
                          targetDate: form1.targetDate,
                          engineer: form1.engineer,
                          bomProject: form1.bomProject,
                          // officer: undefined, // TODO
                          rawItems: form2.selectedItems.map((x) => {
                            return {
                              quantity:
                                (x.ratio || 1) * parseInt(form1.quantity),
                              ratio: x.ratio || 1,
                              id: x.id,
                              name: x.name,
                              uom: x.uom,
                            };
                          }),
                          details: {
                            status: 'draft',
                            finishedItem: {
                              quantity: form1.quantity,
                              uom: form1.uom, // TODO
                            },
                            activity: [],
                            dispatchTo: form1.dispatchTo,
                          } as any,
                        } as any);
                        onClose();
                      }}
                      defaultStyle='bg-green-600 text-white p-1 border border-white rounded-md'
                      text='Save'
                    />
                  </div>
                </div>
              </div>
            ),
          }}
        />
      </div>
    );
  };
}

const priority = {
  low: <div className='ml-2 inline font-bold text-green-500'>LOW</div>,
  medium: <div className='ml-2 inline font-bold text-orange-500'>MEDIUM</div>,
  high: <div className='ml-2 inline font-bold text-red-500'>HIGH</div>,
};

function Header({ form }: { form: any }): JSX.Element {
  return (
    <div className='p-2 my-2 border-2 border-gray-200 p-1 rounded-md'>
      <div className='flex items-center font-bold mb-3'>
        <div className='flex flex-auto'>
          <FinishedGood className='w-7 h-7' />{' '}
          <span className='font-semibold'>{form.grade.name}</span>{' '}
          <span className='ml-5'>Quantity {form.quantity}Kg</span>
        </div>
        <div className='flex flex-auto'>
          <span className='font-bold'>
            <CalendarDaysIcon className='w-5 h-5 text-red-500 inline' /> Target
            date: {form.targetDate}{' '}
          </span>
        </div>
      </div>

      <div className='flex'>
        <div className='flex-auto'>
          <span className='font-bold'>
            <UserIcon className='w-5 h-5 text-blue-500 inline' /> Engineer:{' '}
            {form.engineer.name}{' '}
          </span>
        </div>
        <div className='flex-auto'>
          <span className='font-bold'>
            <HomeIcon className='w-5 h-5 text-orange-500 inline' /> Dispatch to:{' '}
            {form.dispatchTo.name}{' '}
          </span>
        </div>
        <div className='flex-auto'>
          <span className='font-bold'>
            Priority: {priority[form.priority || 'low']}{' '}
          </span>
        </div>
      </div>
    </div>
  );
}
