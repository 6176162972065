/* eslint-disable react-hooks/exhaustive-deps */
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseUserAuthorization } from '../../hooks/admin/user-authorization/use-user-authorization';

type Props = {
  useUserAuthorization: UseUserAuthorization;
};

export const renderAuthWrapper = ({ useUserAuthorization }: Props) => {
  return function AuthWrapper({
    children,
    action,
    showUnAuthorizedWarning,
    customAuthorization,
  }: {
    children: JSX.Element;
    action: string;
    showUnAuthorizedWarning?: boolean;
    customAuthorization?: JSX.Element;
  }): JSX.Element {
    const { get: getAllowedActions } = useUserAuthorization();
    const [allowedActions, setAllowedActions] = useState<any>();

    const isActionValid = () => {
      if (!allowedActions) return false;
      if (!allowedActions.hasOwnProperty(action)) return false;
      if (!allowedActions[action].action) return false;
      return true;
    };

    useEffect(() => {
      const ac = getAllowedActions();
      setAllowedActions(ac.actions);
    }, []);
    if (!isActionValid() && showUnAuthorizedWarning) {
      return (
        <div>
          <LockClosedIcon className='inline text-red-700 w-4' /> You are not
          authorized!
        </div>
      );
    }

    if (!isActionValid() && customAuthorization) {
      return customAuthorization;
    }

    if (!isActionValid()) return <></>;
    return children;
  };
};
