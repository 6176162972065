import { UserLoginRequest } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseLogin } from '../../../hooks/login/use-login';
import { Landing } from '../components/landing';
import { Logo } from '../components/logo';

export type RenderLoginProps = {
  useLogin: UseLogin;
  useUserAuthorization: UseUserAuthorization;
};

// eslint-disable-next-line no-unused-vars
export function renderLogin({ useLogin }: RenderLoginProps): () => JSX.Element {
  return function Login(): JSX.Element {
    const navigate = useNavigate();
    const { loginStatus, login, refreshToken } = useLogin();
    const onSave = async (form) => {
      await login(form);
    };

    const handleResetPswdPath = () => {
      navigate('/reset-password');
    };

    useEffect(() => {
      if (loginStatus === 'login-success') {
        const redirectUrl = localStorage.getItem('redirect-url');
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate('/');
        }
        setTimeout(() => {
          document.location.reload();
        }, 200);
      }

      if (loginStatus === 'login-expired') {
        refreshToken();
      }
    }, [loginStatus, refreshToken, navigate]);

    const formProps: FormProps<UserLoginRequest> = {
      fieldsData: [
        { property: 'domain', label: 'Domain', type: 'input', required: true },
        { property: 'email', label: 'Email', type: 'email', required: true },
        {
          property: 'password',
          label: 'Password',
          type: 'password',
          required: true,
        },
      ],
      initialFormState: {
        domain: '',
        email: '',
        password: '',
      },
      mapTToU: mapPFDToP,
      style: '',
      button: {
        style: 'justify-center text-medium',
        text: 'Sign In',
      },
      onSubmit: onSave,
    };

    const Form = renderFormV2<UserLoginRequest>(formProps);

    return (
      // <div className='flex'>
      <div className='h-screen flex flex-row flex-wrap-wrap justify-center border-2'>
        <div className='flex flex-col justify-center w-full lg:w-1/2 p-5 lg:px-32'>
          <Logo />
          <div className='font-medium text-xl text-center'>Welcome Back!</div>
          <Form />
          {loginStatus === 'before-login' ? (
            ''
          ) : (
            <div className='font-bold my-2 text-red-500'>{loginStatus}</div>
          )}
          <div
            className='font-md text-center cursor-pointer underline text-black-600'
            onClick={handleResetPswdPath}
          >
            Forgot Password
          </div>
          <div></div>
        </div>
        <Landing />
      </div>
      // </div>
    );

    function mapPFDToP(
      b: FormDataType<UserLoginRequest> & { [k: string]: any }
    ): UserLoginRequest {
      return {
        domain: b.domain as string,
        email: b.email as string,
        password: b.password as string,
      };
    }
  };
}
