import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { renderTableWithAddEdit } from '@erp_core/erp-ui-components';
import { UseCombinedGodown } from '../../../../../../hooks/inventory/godown-inventory/use-godown';

type Props = {
  godown: Godown;
  setGodown: (data: Godown, options?: any) => Promise<Godown>;
};

export const renderWorkAreaBlock = ({
  useCombinedGodown,
}: {
  useCombinedGodown: UseCombinedGodown;
}) => {
  return function WorkAreaBlock({ godown, setGodown }: Props): JSX.Element {
    const SiblingWorkAreaComponent = renderTableWithAddEdit<Godown>({
      description:
        'Sibling WorkArea is used for assigning a common Reliever during shift allocation',
      resource: 'siblingWorkAreas',
      properties: [
        {
          name: 'name',
          type: 'searchable-select',
          data: {
            useSearch: useCombinedGodown,
            filter: {
              type: 'work-area',
            },
          },
          required: true,
          formToObjectMapper: (f, o) => {
            o.id = f.name.id;
            o.name = f.name.name;
          },
          objectToFormMapper: (o, f) => {
            f.name = { id: o.id, name: o.name };
          },
        },
      ],
      actions: [{ name: 'edit' }, { name: 'delete' }],
      resourceData: godown,
      setResource: setGodown,
    });

    return (
      <>
        <div className='my-3'>
          <SiblingWorkAreaComponent />
        </div>
      </>
    );
  };
};
