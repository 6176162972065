import { InsurancePayment } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';

import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';

// eslint-disable-next-line
export function renderAddInsuranceForm(): (data: any) => JSX.Element {
  return function AddInsuranceForm({ onSave }: { onSave: any }): JSX.Element {
    const formProps: FormProps<Partial<InsurancePayment>> = {
      fieldsData: [
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          property: 'monthYear',
          type: 'input',
          label: 'Month',
        },
        {
          property: 'insuranceCompanyName',
          type: 'input',
          label: 'Insurance Company',
        },
        {
          property: 'policyNo',
          type: 'input',
          label: 'Policy Number',
        },
      ],
      initialFormState: {
        amount: 0,
        monthYear: moment().format('YYYY-MM'),
        insuranceCompanyName: '',
        policyNo: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<InsurancePayment>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<InsurancePayment>>
    ): InsurancePayment {
      return {
        monthYear: (b.monthYear as string) || moment().format('YYYY-MM'),
        amount: b.amount as number,
        insuranceCompanyName: b.insuranceCompanyName as string,
        policyNo: b.policyNo as string,
        status: 'pending',
        paidBy: {} as any,
      };
    }
  };
}
