import { TableHeader } from '@erp_core/erp-ui-components';

export function Header({
  type,
}: {
  type: 'daybook' | 'pending-action' | 'employee-book';
}): TableHeader {
  if (type === 'daybook') {
    return [
      [
        { name: 'Emp name', style: 'w-4/5 md:w-2/12' },
        { name: 'In Entry', style: 'hidden md:table-cell md:w-2/12' },
        { name: 'Out Entry', style: 'hidden md:table-cell md:w-2/12' },
        { name: 'Summary', style: 'hidden md:table-cell md:w-5/12' },
        // { name: 'Status', style: 'hidden md:table-cell md:w-2/12' },
      ],
    ];
  }

  if (type === 'pending-action') {
    return [
      [
        {
          name: 'Date',
          style: 'hidden md:table-cell md:w-1/12',
        },
        { name: 'Emp name', style: 'md:w-2/12' },
        {
          name: 'In Entry',
          style: 'hidden md:table-cell md:w-1/12',
        },
        {
          name: 'Out Entry',
          style: 'hidden md:table-cell md:w-1/12',
        },
        {
          name: 'Summary',
          style: 'hidden md:table-cell md:w-5/12',
        },
        // {
        //   name: 'Status',

        //   style: 'hidden md:table-cell md:w-2/12',
        // },
        {
          name: 'Reason',
          style: 'hidden md:table-cell md:w-2/12',
        },
      ],
    ];
  }

  // Employee Book
  return [
    [
      {
        name: 'Date',
        style: 'hidden md:table-cell md:w-1/12',
      },
      { name: 'Emp name', style: 'md:w-2/12' },
      {
        name: 'In Entry',
        style: 'hidden md:table-cell md:w-1/12',
      },
      {
        name: 'Out Entry',
        style: 'hidden md:table-cell md:w-1/12',
      },
      {
        name: 'Summary',
        style: 'hidden md:table-cell md:w-5/12',
      },
      {
        name: 'Finalization',
        style: 'hidden md:table-cell md:w-2/12',
      },
    ],
  ];
}
