import { CurrencyType } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type CurrencyFormType = {
  name: string;
  country: string;
  symbol: string;
};

export function renderAddCurrencyForm() {
  return function AddCurrencyForm({
    onSave,
  }: {
    onSave: (data: CurrencyType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<CurrencyType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Currency Name',
          required: true,
        },
        {
          property: 'symbol',
          type: 'input',
          label: 'Symbol',
          required: true,
        },
        {
          property: 'country',
          type: 'input',
          label: 'Country',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        symbol: '',
        country: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          symbol: l.symbol,
          country: l.country,
        } as CurrencyType);
      },
    };

    const Form = renderFormV2<Partial<CurrencyType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<CurrencyType>>): CurrencyType {
      return (b as unknown) as CurrencyType;
    }
  };
}
