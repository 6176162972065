import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  propOptions?: string | Array<string>;
  currentValue: string;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

type EditPropertyChangeReqFormType = {
  currentValue: string;
  newValue: string;
  reason: string;
};

export const renderEditSelectPropertyChangeReq = () => {
  return function EditSelectPropertyChangeReq({
    propOptions,
    currentValue,
    // type,
    onSave,
  }: EditChangeReqProps) {
    const selectOptions =
      propOptions && typeof propOptions === 'string'
        ? propOptions?.split(',')
        : propOptions || [];
    let options: { text: string; value: string }[] = [
      { text: 'Select', value: '' },
    ];

    _.forEach(selectOptions, function (key, val) {
      options.push({
        text: key,
        value: key,
      });
    });

    const formProps: FormProps<EditPropertyChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'select',
          required: true,
          label: 'New Value',
          options: options,
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue || '',
        newValue: '',
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue || '',
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditPropertyChangeReqFormType>
    ): EditPropertyChangeReqFormType {
      return {
        currentValue: b.currentValue || currentValue,
        newValue: b.newValue,
      } as EditPropertyChangeReqFormType;
    }
  };
};
