/* eslint-disable react-hooks/exhaustive-deps */
// import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
// import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import { LeadCustomerEnquiryType } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderInlineInput,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { UseCombinedLeadCustomerEnquiry } from '../../../hooks/order/sales/lead-customer-enquiry/use-lead-customer-enquiry';
import { UseCombinedLeadCustomer } from '../../../hooks/order/sales/lead-customer/use-lead-customer';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderLeadCustomerEnquiryDetailsProfile } from '../lead-customer-enquiry-details-profile';

type RenderLeadCustomerEnquiryDetails = {
  useCombinedItem: UseCombinedItem;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedLeadCustomerEnquiry: UseCombinedLeadCustomerEnquiry;
  useCombinedMetric: UseCombinedMetric;
  useCombinedClient: UseCombinedClient;
  useCombinedClientItem: UseCombinedClientItem;
  itemService: ItemInterface;
};

export function renderLeadCustomerEnquiryDetails({
  useCombinedItem,
  useCombinedEmployeeProfile,
  useCombinedLeadCustomer,
  useCombinedLeadCustomerEnquiry,
  useCombinedMetric,
  useCombinedClient,
  useCombinedClientItem,
  itemService,
}: RenderLeadCustomerEnquiryDetails): () => JSX.Element {
  const Card = renderCardComponent();

  return function LeadCustomerEnquiryProfile(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();

    const {
      resource: leadCustomerEnquiry,
      get: getLeadCustomerEnquiry,
      loading,
      syncSet: setLeadCustomerEnquiry,
    } = useCombinedLeadCustomerEnquiry();

    useEffect(() => {
      if (id) getLeadCustomerEnquiry(id);
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useCombinedLeadCustomerEnquiry,
      id,
    });

    const header: CardHeader = {
      title: (
        <div>
          <></>
          <InlineInput name={'name'} value={leadCustomerEnquiry?.name || id} />
        </div>
      ) as any,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomerEnquiry?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomerEnquiry?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomerEnquiry?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomerEnquiry?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(leadCustomerEnquiry?.lastModifiedAt).fromNow()}
          </div>
          {/* <div className='flex space-x-2 my-2'>
            {leadCustomerEnquiry.disabled === false ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
          </div> */}
        </>
      ),
    };

    const Profile = renderLeadCustomerEnquiryDetailsProfile({
      leadCustomerEnquiry:
        leadCustomerEnquiry || ({} as LeadCustomerEnquiryType),
      setLeadCustomerEnquiry,
      loading,
      useCombinedItem,
      useCombinedEmployeeProfile,
      useCombinedLeadCustomer,
      useCombinedMetric,
      useCombinedClient,
      useCombinedClientItem,
      itemService,
    });

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([['Profile', () => (leadCustomerEnquiry ? <Profile /> : <></>)]]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
