import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderHistory,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedSalesEnquiry } from '../../../../hooks/order/sales/enquiry/use-sales-enquiry';

export type RenderSalesEnquiryItemsDetailsV2Prop = {
  hooks: {
    useCombinedSalesEnquiry: UseCombinedSalesEnquiry;
  };
};

export function renderSalesEnquiryItemsDetailsV2({
  hooks: { useCombinedSalesEnquiry },
}: RenderSalesEnquiryItemsDetailsV2Prop): () => JSX.Element {
  const History = renderHistory();

  return function SalesEnquiryItemsDetailsV2(): JSX.Element {
    const { id } = useParams();
    const { resource: item, get: getSalesEnquiry } = useCombinedSalesEnquiry();
    const Card = renderCardComponent();
    const Tabs = renderTabsComponent();

    useEffect(() => {
      if (id) {
        getSalesEnquiry(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${item?.name || id}`,
      subheading: (
        <>
          <div>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${item?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {item?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${item?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {item?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(item?.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      [
        'History',
        () => <History useResource={useCombinedSalesEnquiry} id={id} />,
      ],
    ]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
