/* eslint-disable no-unused-vars */
import { UserLoginRequest } from '@erp_core/erp-types/dist/modules/admin';
import { useState } from 'react';
import { LoginInterface, LoginStatus } from '../../models/interfaces/login';

export type UseLogin = () => {
  loginStatus: LoginStatus;
  login: (l: UserLoginRequest) => Promise<void>;
  refreshToken: () => void;
};

export interface UseLoginOptions {
  useLoginService: () => LoginInterface;
}

export function createUseLogin({ useLoginService }: UseLoginOptions): UseLogin {
  return () => {
    const loginService = useLoginService();

    const [loginStatus, setLoginStatus] = useState<LoginStatus>(
      loginService.checkLoginStatus()
    );

    const loginApiCall = async (l: UserLoginRequest) => {
      try {
        await loginService.login(l);
        setLoginStatus('login-success');
      } catch (e) {
        console.log(e);
        setLoginStatus('login-failed');
        setTimeout(() => {
          setLoginStatus('before-login');
        }, 2000);
      }
    };

    const refreshApiCall = async () => {
      try {
        await loginService.refreshToken();
        setLoginStatus('login-success');
      } catch (e) {
        console.log(e);
        setLoginStatus('login-failed');
        setTimeout(() => {
          setLoginStatus('before-login');
        }, 2000);
      }
    };

    return {
      loginStatus,
      login: async (l: UserLoginRequest) => {
        return loginApiCall(l);
      },
      refreshToken: () => {
        refreshApiCall();
      },
    };
  };
}
