/* eslint-disable class-methods-use-this */

import { ModuleType } from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { ModuleFilterType } from '../../../../models/interfaces/admin/module-admin';
import { GenericHttpWithLogsRepo } from '../generic-resource-with-logs';

export class HttpModuleAdminRepo extends GenericHttpWithLogsRepo<
  ModuleType,
  ModuleFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/modules',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
