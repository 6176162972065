/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  CurrentUserType,
  User,
  UsersFilterType,
} from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { UserAuthorization } from '../../../../models/interfaces/admin/user-admin';
import { GenericHttpWithCurrentRepo } from '../generic-resource-with-current';

export class HttpUsersRepo extends GenericHttpWithCurrentRepo<
  User,
  UsersFilterType,
  CurrentUserType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/users',
      itemName: 'current-user',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getAuthorization(id: string): Promise<UserAuthorization> {
    const api = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: `${this.apiUrl}`,
    });
    const data = await api.get(`/api/v1/users/${id}/authorization`);
    return data.data as UserAuthorization;
  }
}
