import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import { useState } from 'react';
import { GodownTransferInterface } from '../../../models/interfaces/inventory/godown-transfer';
import { BatchItemGodownInterface } from '../../../models/interfaces/inventory/item/batch/batch-item-godown';
import { PurchaseOrderInterface } from '../../../models/interfaces/order/purchase/purchase-order';
import { BOMInterface } from '../../../models/interfaces/order/work-order/bom';

export type UseMRS = () => {
  data?: Array<MRSType>;
  // eslint-disable-next-line no-unused-vars
  getAll: () => void;
  loading: boolean;
};

export interface UseMRSOptions {
  useBomService: () => BOMInterface;
  useBatchItemGodownService: () => BatchItemGodownInterface;
  usePurchaseOrderService: () => PurchaseOrderInterface;
  useGodownTransferService: () => GodownTransferInterface;
}

export function createUseMRS({
  useBomService,
  useBatchItemGodownService,
  usePurchaseOrderService,
  useGodownTransferService,
}: UseMRSOptions): UseMRS {
  return () => {
    const bomService = useBomService();
    const batchItemGodownService = useBatchItemGodownService();
    const purchaseOrderService = usePurchaseOrderService();
    const godownTransferService = useGodownTransferService();

    const [mrsList, setMRSList] = useState<Array<MRSType>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const apiCall = async () => {
      try {
        setLoading(true);
        const bomList = (await bomService.getAll({
          approved: true,
        })) as BOMRes[];

        const physicalStockPromises: Array<Promise<
          number
        >> = bomList.map((bom) =>
          batchItemGodownService
            .getAll({ reference_id: bom.id })
            .then(
              (batchItemGodowns: any) =>
                batchItemGodowns?.reduce(
                  (prev, current) => prev + current.quantity,
                  0
                ) || 0
            )
        );
        const virtualStockPromises: Array<Promise<number>> = bomList.map(
          (bom) =>
            purchaseOrderService
              .getAll({ referenceId: bom.id })
              .then((purchaseOrders: any) => {
                let quantity = 0;
                purchaseOrders?.forEach((a) => {
                  quantity += Number(
                    a.purchaseOrderItems.reduce((p, c) => p + c.quantity, 0)
                  );
                });

                return quantity;
              })
        );

        const godownTransferPromises: Array<Promise<number>> = bomList.map(
          (bom) =>
            godownTransferService
              .getAll({ referenceId: bom.id } as any)
              .then((godownTransfer: any) => {
                let quantity = 0;
                godownTransfer?.forEach((a) => {
                  quantity += Number(a.quantity);
                });

                return quantity;
              })
        );

        const physicalStocks = await Promise.all(physicalStockPromises);
        const virtualStock = await Promise.all(virtualStockPromises);
        const issuedStock = await Promise.all(godownTransferPromises);

        setMRSList(
          bomList.map((bom, idx) => ({
            bom,
            physicalStock: physicalStocks[idx],
            virtualStock: virtualStock[idx],
            issuedStock: issuedStock[idx],
          }))
        );

        setLoading(false);
      } catch (error) {
        console.log('error', error);
        setLoading(false);
      }
    };

    return {
      data: mrsList,
      getAll: () => {
        apiCall();
      },
      loading,
    };
  };
}

// TODO: Its a temporary type
export type MRSType = {
  bom: BOMRes;
  physicalStock: number;
  virtualStock: number;
  issuedStock: number;
};
