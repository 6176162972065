import { BomProjectType } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseCombinedUser } from '../../../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedBomProject } from '../../../../../hooks/order/work-order/bom-project/use-bom-project';

export type BomProjectFormType = {
  name: string;
  description: string;
  type: string;
  parent: IdName;
  targateDate: string;
  budget: string;
  agency: string;
  designEng: IdName;
  siteEng: IdName;
  status: string;
  completion: string;
};

export function renderAddEditBomProject({
  useCombinedUser,
  useCombinedBomProject,
}: {
  useCombinedUser: UseCombinedUser;
  useCombinedBomProject: UseCombinedBomProject;
}) {
  return function AddEditBomProject({
    bomProject,
    setBomProject,
    onClose,
  }: {
    bomProject?: BomProjectType;
    onClose: () => void;
    setBomProject: (s: BomProjectType) => Promise<BomProjectType>;
  }): JSX.Element {
    console.log(bomProject);
    const { user: currentUser } = useContext(UserContext);
    const { location: currentLocation, company, companyGroup } = useContext(
      CurrentContext
    );

    const initialData = {
      id: bomProject?.id || '',
      name: bomProject?.name || '',
      description: bomProject?.description || '',
      designEng: bomProject?.details?.designEng?.id
        ? bomProject?.details?.designEng
        : { id: '', name: '' },
      siteEng: bomProject?.details?.siteEng?.id
        ? bomProject?.details?.siteEng
        : { id: '', name: '' },
      type: bomProject?.type || '',
      status: bomProject?.status || '',
      parent: bomProject?.parentBomProject?.id
        ? bomProject?.parentBomProject
        : { id: '', name: '' },
      targateDate: bomProject?.details?.targateDate || '',
      budget: bomProject?.details?.budget || '',
      agency: bomProject?.details?.agency || '',
      completion: bomProject?.details?.completion || '',
    };
    const formProps: FormProps<Partial<BomProjectFormType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'type',
          type: 'select',
          label: 'Type',
          options: [
            { value: '', text: 'Select' },
            { value: 'E-BOM', text: 'Electrical' },
            { value: 'C-BOM', text: 'Civil' },
            { value: 'F-BOM', text: 'Fabrication' },
            { value: 'S-BOM', text: 'Structure' },
            { value: 'M-BOM', text: 'Manufacturing' },
            { value: 'FU-BOM', text: 'Furniture' },
            { value: 'A-BOM', text: 'Automation' },
            { value: 'OA-BOM', text: 'Office Automation' },
          ],
          required: true,
        },
        {
          property: 'parent',
          type: 'searchable-select',
          label: 'Parent Project',
          searchOptions: {
            useSearch: useCombinedBomProject,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'status',
          type: 'select',
          label: 'Status',
          options: [
            { text: 'Opened', value: 'Opened' },
            { text: 'Closed', value: 'Closed' },
          ],
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        {
          property: 'targateDate',
          type: 'date',
          label: 'Target Date',
          required: true,
        },
        {
          property: 'budget',
          type: 'input',
          label: 'Budget(%)',
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'designEng',
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect(u) {},
          },
          label: 'Design Engineer',
          style: '',
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'siteEng',
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect(u) {},
          },
          label: 'Site Engineer',
          style: '',
          required: true,
        },
        {
          property: 'agency',
          type: 'input',
          label: 'Agency',
          required: true,
        },
        {
          property: 'completion',
          type: 'input',
          label: 'Completion(%)',
          required: true,
        },
      ],
      initialFormState: initialData,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        const form = {
          id: bomProject?.id || undefined,
          name: l.name || initialData.name,
          description: l.description || initialData.description,
          status: l.status || initialData.status,
          parentBomProject:
            l.parent ||
            (initialData.parent?.id ? initialData.parent : undefined),
          type: l.type || initialData.type,
          details: {
            targateDate: l.targateDate || initialData.targateDate,
            budget: l.budget || initialData.budget,
            agency: l.agency || initialData.agency,
            designEng: l.designEng || initialData.designEng,
            siteEng: l.siteEng || initialData.siteEng,
            completion: l.completion || initialData.completion,
          },
        } as any;

        const finalData = {
          ...form,
          companyGroup: {
            id: companyGroup.id,
            name: companyGroup.name,
          },
          company: {
            id: company.id,
            name: company.name,
          },
          location: {
            id: currentLocation.id,
            name: currentLocation.name,
          },
          createdBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          lastModifiedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        } as BomProjectType;
        await setBomProject(finalData as BomProjectType);
        toast('Data added sucessfully');
        onClose();
      },
    };

    const Form = renderFormV2<Partial<BomProjectFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<BomProjectFormType>>
    ): BomProjectFormType {
      return (b as unknown) as BomProjectFormType;
    }
  };
}
