import {
  MultiRangeSlider,
  SingleRangeSlider,
} from '@erp_core/erp-ui-components';
import { useCallback, useMemo, useState } from 'react';

export function renderRangeSlidersUsePage(): () => JSX.Element {
  return function RangeSlidersUsePage() {
    const [parentVal, setParentVal] = useState(10);

    const sliderValueChanged = useCallback((val) => {
      setParentVal(val);
    }, []);

    const sliderProps = useMemo(
      () => ({
        min: 100,
        max: 500,
        value: parentVal,
        step: 100,
        label: 'This is a reusable slider',
        onChange: (e) => sliderValueChanged(e),
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [parentVal]
    );

    return (
      <div>
        {/* <h1>PARENT VALUE: {parentVal}</h1> */}
        <SingleRangeSlider {...sliderProps} />
        <div className='py-10'>
          <MultiRangeSlider
            initialMaxValue={300}
            mode='multi'
            min={0}
            max={1000}
            onChange={({ min, max }) =>
              console.log(`min = ${min}, max = ${max}`)
            }
          />
        </div>
      </div>
    );
  };
}
