import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseCombinedHazardGhsClass } from '../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedPrecautionaryStatement } from '../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-precautionary-statement';
import { UseCombinedHazardStatement } from '../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { renderHazardGhsClass } from './hazard-class-category-list';
import { renderGhsPrecautionaryStatement } from './hazard-precautions';
import { renderHazardStatements } from './hazard-statements';

export type RenderHazardRegulationsProps = {
  useCombinedPrecautionaryStatement: UseCombinedPrecautionaryStatement;
  usePublicTransfer: UsePublicTransfer;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
  useCombinedHazardStatement: UseCombinedHazardStatement;
};

// eslint-disable-next-line
export function renderHazardRegulations({
  useCombinedPrecautionaryStatement,
  usePublicTransfer,
  useCombinedHazardGhsClass,
  useCombinedHazardStatement,
}: RenderHazardRegulationsProps): () => JSX.Element {
  return function HazardRegulations(): JSX.Element {
    const Card = renderCardComponent();
    const TabsV2 = renderTabsV2Component();
    const header: CardHeader = {
      title: 'Hazard Regulations',
    };

    const GHSClassCategory = renderHazardGhsClass({
      usePublicTransfer,
      useCombinedHazardGhsClass,
    });

    const GhsStatement = renderHazardStatements({
      useCombinedHazardStatement,
    });

    const GhsPrecautions = renderGhsPrecautionaryStatement({
      useCombinedPrecautionaryStatement,
    });

    const newCategories: DataArray = [
      { name: 'ghs-class-category', Fn: GHSClassCategory },
      { name: 'h-statements', Fn: GhsStatement },
      { name: 'p-statements', Fn: GhsPrecautions },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <TabsV2 data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
