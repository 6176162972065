import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { renderFormV2 } from '@erp_core/erp-ui-components';

type RoleType = {
  name: string;
  min: number;
  max: number;
  level: 'beginner' | 'intermediate' | 'professional';
};

export const AddItemShiftRolesForm = ({
  item,
  save,
  data,
}: {
  item: ItemRes;
  save: (form: any) => Promise<void>;
  data?: RoleType;
}) => {
  const itemDetails: any = item.details;
  const Form = renderFormV2<any>({
    fieldsData: [
      {
        property: 'name',
        label: 'Name',
        type: 'input',
        validate: async (form) => {
          if (
            itemDetails?.shiftConfig?.roles?.find(
              (s) =>
                s.name.toLowerCase() === (form.name as string).toLowerCase()
            ) &&
            data?.name.toLowerCase() !== (form.name as string).toLowerCase()
          ) {
            return await {
              name: 'This name already exist',
            };
          }
          return (await {}) as any;
        },
        required: true,
      },
      {
        property: 'level',
        label: 'Level',
        type: 'select',
        options: [
          { value: '', text: 'Select' },
          { value: 'beginner', text: 'Beginner' },
          { value: 'intermediate', text: 'Intermediate' },
          { value: 'professional', text: 'Professional' },
        ],
        required: true,
      },

      {
        property: 'min',
        label: 'Minimum Employee',
        type: 'number',
        required: true,
        validate: (form) => {
          if (Number(form.min) > Number(form.max)) {
            return {
              min: 'Min should less than max',
              max: 'Max should greater than min',
            };
          }
          return {} as any;
        },
      },
      {
        property: 'max',
        label: 'Maximum Employee',
        type: 'number',
        required: true,
      },
    ],
    onSubmit: async (form) => {
      await save(form);
    },
    mapTToU: (p) => {
      return {
        name: p.name,
        level: p.level,
        min: Number(p.min),
        max: Number(p.max),
      };
    },
    allowUnchanged: true,
    initialFormState: {
      name: data?.name || '',
      level: data?.level || '',
      min: Number(data?.min || 0),
      max: Number(data?.max || 0),
    },
  });

  return <Form />;
};
