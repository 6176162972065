import initials from 'initials';

// eslint-disable-next-line
export function renderVendor(): ({
  id,
  name,
}: {
  id: string;
  name: string;
}) => JSX.Element {
  return function Vendor({
    id,
    name,
  }: {
    id: string;
    name: string;
  }): JSX.Element {
    return (
      <span>
        <span className='rounded-full w-8 h-8 text-xs border p-0.5 bg-purple-800 text-white'>
          {initials(name).substring(0, 2)}
        </span>
        &nbsp;{' '}
        <a
          href={`/purchase/masters/vendors/${id}/profile`}
          target='_blank'
          rel='noreferrer'
        >
          {name}
        </a>
      </span>
    );
  };
}
