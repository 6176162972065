export const colors = [
  {
    bgLight: '#2a9df4',
    bgDark: '#1e2f97',
    textLight: '#2a9df4',
    textDark: '#1e2f97',
  },
  {
    bgLight: '#ff8164',
    bgDark: '#c61a09',
    textLight: '#ff8164',
    textDark: '#c61a09',
  },
  {
    bgLight: '#f1ee8e',
    bgDark: '#e47200',
    textLight: '#f1ee8e',
    textDark: '#e47200',
  },
  {
    bgLight: '#b3cf99',
    bgDark: '#4b6043',
    textLight: '#b3cf99',
    textDark: '#4b6043',
  },
];
