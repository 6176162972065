import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedDepartment } from '../../../../hooks/hrd/department/use-department';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

export type QaApprovalType = {
  qulaityMonitor: string;
  dept: IdName;
  status: string;
};

type RenderAddDeptCommentsForm = {
  useCombinedDepartment: UseCombinedDepartment;
};

export function renderQaApprovalForm({
  useCombinedDepartment,
}: RenderAddDeptCommentsForm) {
  return function AddQaApprovalForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<QaApprovalType> = {
      fieldsData: [
        {
          type: 'select',
          label: 'Need to monitor impact on quality?',
          property: 'qulaityMonitor',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'yes' },
            { value: 'no', text: 'no' },
          ],
        },
        {
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedDepartment,
            onSearchValueSelect: (e) => {},
          },
          label: 'Responsible Department',
          property: 'dept',
        },
        {
          type: 'select',
          label: 'status',
          property: 'status',
          options: [
            { value: 'select', text: 'select' },
            { value: 'accepted', text: 'accepted' },
            { value: 'rejected', text: 'rejected' },
          ],
        },
      ],
      initialFormState: {
        qulaityMonitor: '',
        dept: '',
        status: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        // const managementComiteeComments = data?.details.managementComiteeComments
        await onSave(({
          details: {
            ...data?.details,
            qaFinalApproval: {
              qulaityMonitor: f.qulaityMonitor,
              dept: {
                id: f.dept.id,
                name: f.dept.name,
              },
              status: f.status,
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<QaApprovalType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<QaApprovalType>): QaApprovalType {
      return (b as unknown) as QaApprovalType;
    }
  };
}
