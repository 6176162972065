import {
  LicenseContractDocType,
  LicenseContractDocTypeFilter,
} from 'models/types/order/license-contract-doc-type';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';

import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class LicenseContractDocTypeHttpRepo extends GenericHttpWithLogsRepo<
  LicenseContractDocType,
  LicenseContractDocTypeFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/work-order/license-contract-doc-type',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
