import {
  SalaryBonus,
  SalaryBonusFilter,
} from '@erp_core/erp-types/dist/types/modules/hrd/salary-bonus';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { SalaryBonusHttpRepo } from '../datalayer/repository/http/salary-bonus';
import { SalaryBonusInterface } from '../models/interfaces/hrd/salary-bonus';
import { erpV2Api } from './admin';
import { SALARY_ADVANCE_SERVER_URI } from './server-uri';

export const salaryBonusService = new SalaryBonusHttpRepo(
  SALARY_ADVANCE_SERVER_URI,
  erpV2Api
);

export const useCombinedSalaryBonus = createUseCombinedResource<
  SalaryBonus,
  SalaryBonusFilter,
  SalaryBonusInterface
>({
  useService: () => salaryBonusService,
});
