import { renderToast } from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../models/interfaces/adapters/erp-v2-api';
import {
  BaseResourceType,
  FilterType,
  ResourceWithLogsInterface,
} from '../../../models/interfaces/generic-resource';
import { GenericHttpRepo, NotificationConfig } from './generic-resource';

export class GenericHttpWithLogsRepo<
  T extends BaseResourceType,
  U extends FilterType
> extends GenericHttpRepo<T, U> implements ResourceWithLogsInterface<T, U> {
  baseURI: string;

  resourceEndpoint: string;

  erpv2Api: ErpV2Api;

  notificationConfig?: NotificationConfig<T>;

  constructor({
    baseURI,
    erpv2Api,
    resourceEndpoint,
    notificationConfig,
  }: {
    erpv2Api: ErpV2Api;
    baseURI: string;
    resourceEndpoint: string;
    notificationConfig?: NotificationConfig<T>;
  }) {
    super({ baseURI, erpv2Api, resourceEndpoint });

    this.baseURI = baseURI;
    this.erpv2Api = erpv2Api;
    this.resourceEndpoint = resourceEndpoint;
    this.notificationConfig = notificationConfig;
  }

  async getLogs(
    id: string
  ): Promise<
    Array<{
      event: string;
      time: string;
      action: string;
      details: string;
    }>
  > {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(`${this.resourceEndpoint}/${id}/logs`);
    if (res.status === 200) {
      const { data } = res;

      return data.map(
        (c) =>
          c as {
            event: string;
            time: string;
            action: string;
            details: string;
          }
      );
    }

    return [];
  }

  async set(s: T): Promise<T> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    if (!s.id) {
      const res = await httpInstance.post(this.resourceEndpoint, s);
      if (res.status !== 200) {
        throw new Error('Resource creation failed');
      }
      this.notificationConfig?.set?.create?.enable &&
        renderToast({
          title:
            this.notificationConfig?.set?.create?.defaultMessage.title || '',
          description: this.notificationConfig?.set?.create?.defaultMessage
            .description,
          link: this.notificationConfig?.set?.create?.defaultMessage.link,
          componentToast: toast as any,
        });
      return res.data as T;
    }

    const res = await httpInstance.patch(`${this.resourceEndpoint}/${s.id}`, s);

    if (res.status !== 200) {
      throw new Error('Resource saving failed');
    }

    if (this.notificationConfig?.set?.update?.enable) {
      if (!this.notificationConfig?.set?.update?.dynamicMessage) {
        renderToast({
          title:
            this.notificationConfig?.set?.update?.defaultMessage.title || '',
          description: this.notificationConfig.set.update.defaultMessage
            .description,
          link: this.notificationConfig.set.update.defaultMessage.link,
          componentToast: toast as any,
        });
      } else {
        const msg = this.notificationConfig?.set?.update?.dynamicMessage?.(s);
        renderToast({
          title: msg.title || '',
          description: msg.description,
          link: msg.link,
          componentToast: toast as any,
        });
      }
    }
    return this.get(s.id);
  }
}
