/* eslint-disable no-unused-vars */
import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import { LocationInterface } from '../../../models/interfaces/admin/location-admin';
import { CompanyGroupLocalStorage } from '../../../utils/token-helper';
import { UseCurrentCompany } from '../company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../company-group-admin/use-current-company-group';
import { UseCurrentUser } from '../user-admin/use-current-user';

export type UseCurrentLocation = () => {
  data: LocationType;
  setCurrentLocation: (s: LocationType) => void;
};

export interface UseCurrentLocationOptions {
  useLocationsService: () => LocationInterface;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCurrentCompany: UseCurrentCompany;
  useCurrentUser: UseCurrentUser;
}

export function createUseCurrentLocation({
  useLocationsService,
  useCurrentCompanyGroup,
  useCurrentCompany,
  useCurrentUser,
}: UseCurrentLocationOptions): UseCurrentLocation {
  return () => {
    const [location, setLocation] = useState<LocationType>({} as LocationType);
    const locationService = useLocationsService();
    const comLocal = new CompanyGroupLocalStorage();
    const { data: currentCompany } = useCurrentCompany();
    const { currentCompanyGroup } = useCurrentCompanyGroup();
    const { data: currentUser } = useCurrentUser();
    const apiCall = async () => {
      const loc = await locationService.getCurrent();
      if (loc?.id) {
        setLocation(loc);
      } else {
        if (!currentCompany?.id || !currentCompanyGroup?.id || !currentUser?.id)
          return;
        const locations = (await locationService.getAll({
          getAll: true,
        })) as LocationType[];
        if (locations.length > 0) {
          const locationId =
            comLocal.get()?.[currentUser.id]?.[currentCompanyGroup.id]
              ?.currentLocation?.id || '';
          //  [currentCompanyGroup.id]?.[currentCompany.id]; // Extracting company last location
          const location = locations.find((l) => l.id === locationId);
          setLocation(location || locations[0]); // Persisting in local storage
          locationService.setCurrent(location || locations[0]); // Setting state
        }
      }
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, [currentCompany.id, currentCompanyGroup.id]);

    const set = async (loc: LocationType) => {
      await locationService.setCurrent(loc);
      setLocation(loc);
      comLocal.set(currentUser, currentCompanyGroup, currentCompany, loc);
    };

    return {
      data: location,
      setCurrentLocation: (s: LocationType) => {
        set(s);
      },
    };
  };
}
