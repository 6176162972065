/* eslint-disable no-unused-vars */
import { NotificationAction } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedNotificationAction } from '../../../hooks/admin/notification-admin/use-authorizations';
import { UseCombinedRole } from '../../../hooks/admin/role-admin/use-roles-admin';

export type RenderEditNotificationFormProps = {
  useCombinedNotificationAction: UseCombinedNotificationAction;
  useCombinedRole: UseCombinedRole;
};

type EditNotificationActionForm = {
  notificationAction: NotificationAction;
  onClose: () => void;
};

export function renderEditNotificationActionForm({
  useCombinedNotificationAction,
  useCombinedRole,
}: RenderEditNotificationFormProps): (
  props: EditNotificationActionForm
) => JSX.Element {
  return function EditNotificationActionForm({
    notificationAction,
    onClose,
  }: EditNotificationActionForm): JSX.Element {
    const { syncSet: setNotificationAction } = useCombinedNotificationAction();

    const saveAuthorization = async (form) => {
      if (notificationAction.id) form.id = notificationAction.id;
      await setNotificationAction(form);
      onClose();
    };

    const formProps: FormProps<Partial<NotificationAction>> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input', required: true },
        {
          type: 'searchable-multi-select',
          label: 'Roles',
          property: 'roles',
          searchOptions: {
            useSearch: useCombinedRole,
            onSearchValueSelect: () => {},
          },
        },
      ],
      initialFormState: {
        name: notificationAction.name,
        roles: notificationAction.roles
          ? notificationAction.roles.map((r) => ({ id: r.id, name: r.name }))
          : [],
      },
      mapTToU: mapPFDToP,
      onSubmit: saveAuthorization,
    };

    const Form = renderFormV2<Partial<NotificationAction>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<NotificationAction>>
    ): Partial<NotificationAction> {
      return (b as unknown) as NotificationAction;
    }
  };
}
