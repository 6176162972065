import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { WrenchIcon } from '@heroicons/react/24/outline';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { ValueRenderer } from '../value-renderer/value-renderer';

export function RHSRenderer({
  x,
  useFileTransfer,
  changeRequested,
  parentValues,
}: {
  changeRequested: boolean;
  x: ItemPropertyValue;
  useFileTransfer: UseFileTransfer;
  parentValues: ItemPropertyValue[];
}): JSX.Element {
  return (
    <div>
      <div className='text-md font-medium text-gray-900'>
        <div className='flex'>
          <div className='flex-auto'>
            <div className='flex'>
              <div className='flex-auto'>
                {!x.value ? (
                  <div className='text-md font-medium text-gray-900'>
                    Value not set...
                  </div>
                ) : (
                  <ValueRenderer
                    key={x.name}
                    x={x}
                    useFileTransfer={useFileTransfer}
                    parentValues={parentValues}
                  />
                )}
              </div>
              {changeRequested ? (
                <WrenchIcon className='inline w-5 text-green-500 animate-pulse' />
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {x.value?.owner ? (
        <div className='text-gray-700 text-right italic text-sm'>
          set at {x.value?.owner?.name}
        </div>
      ) : null}
    </div>
  );
}
