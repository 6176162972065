import { EnquiryQuotes } from '@erp_core/erp-types/dist/modules/order';

export const getColumnHeadings = (enquiryQuotesData?: EnquiryQuotes[]) => {
  const vendors =
    enquiryQuotesData?.map((q) => ({ name: q.vendor.name, id: q.vendor.id })) ||
    [];
  return [
    {
      id: 'index',
      name: 'Fields',
    },
    {
      id: 'null',
      name: 'Requested',
    },
    ...vendors,
  ];
};
