import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  CLRepaymentSchedule,
  NewCompanyLoan,
} from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { DateSelector, LoadingButton } from '@erp_core/erp-ui-components';
import { PencilSquareIcon, PlusIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';

export function createAddRepayments(): ({
  companyLoan,
}: {
  companyLoan: NewCompanyLoan;
  currentUser: UserIdName;
  onSave: (c: NewCompanyLoan) => void;
}) => JSX.Element {
  return function AddRepayments({
    companyLoan,
    onSave,
    currentUser,
  }: {
    companyLoan: NewCompanyLoan;
    currentUser: UserIdName;
    onSave: (c: NewCompanyLoan) => void;
  }): JSX.Element {
    const [status, setStatus] = useState<
      'pending' | 'approved' | 'rejected' | 'closed'
    >(companyLoan.status);
    const [dateApproved] = useState<string>(
      companyLoan.approval?.dateApproved || moment().format('YYYY-MM-DD')
    );
    const [disbursementDetails] = useState<any>(
      companyLoan.approval.disbursementDetails
    );
    const [approvedBy] = useState<UserIdName>(
      companyLoan.approval?.approvedBy || currentUser
    );
    const [interestRate, setInterestRate] = useState<number>(
      companyLoan.approval?.interestRate || 0
    );
    const [totalPrinciple, setTotalPrinciple] = useState<number>(
      companyLoan.approval?.amount?.principal || 0
    );
    const [totalInterest, setTotalInterest] = useState<number>(
      companyLoan.approval?.amount?.interest || 0
    );

    const [index, setIndex] = useState<number>(0);
    const [repayments, setRepayments] = useState<Array<CLRepaymentSchedule>>(
      companyLoan.repaymentSchedule || []
    );
    const [monthYear, setMonthYear] = useState<string>(
      moment().format('YYYY-MM')
    );
    const [principle, setPrinciple] = useState<number>(0);
    const [interest, setInterest] = useState<number>(0);
    const [repaymentStatus, setRepaymentStatus] = useState<string>('');
    const [paymentDate, setPaymentDate] = useState<string>('');

    return (
      <div className='p-2 border border-gray-200 rounded-lg'>
        <div className='text-lg font-semibold text-center'>
          Loan Request Amount: {companyLoan.amount}
        </div>
        <div className='text-sm font-thin italic text-center'>
          {' '}
          {companyLoan.reason}{' '}
        </div>
        <div className='mx-auto w-48'>
          Status
          <select
            value={status}
            className='bg-gray-100 w-36 text-center'
            onChange={(evt) => {
              setStatus(evt.target.value as any);
            }}
          >
            <option value={'pending'}>Pending</option>
            <option value={'approved'}>Approved</option>
            <option value={'rejected'}>Rejected</option>
            <option value={'closed'}>Closed</option>
          </select>
        </div>
        <div className='border border-gray-200 p-1 text-center flex'>
          <div>
            Interest Rate:
            <input
              className='w-10 border border-gray-200 text-right'
              value={interestRate}
              onChange={(evt) => setInterestRate(parseInt(evt.target.value))}
            />
            {'%'}
          </div>
          <div>
            Total Principle:
            <input
              className='w-10 border border-gray-200 text-right'
              value={totalPrinciple}
              onChange={(evt) => setTotalPrinciple(parseInt(evt.target.value))}
            />
          </div>
          <div>
            Total Interest:
            <input
              className='w-10 border border-gray-200 text-right'
              value={totalInterest}
              onChange={(evt) => setTotalInterest(parseInt(evt.target.value))}
            />
          </div>
        </div>
        <div className='border border-gray-200 p-1'>
          <div className='text-right'>
            <PlusIcon
              className='w-6 text-green-600 ml-auto'
              onClick={() => {
                setRepayments([
                  ...repayments,
                  {
                    monthYear: '',
                    interest: 0,
                    principal: 0,
                    status: 'pending',
                    paymentDate: '',
                  },
                ]);
              }}
            />
          </div>
          {repayments.map((x, idx) => (
            <div key={idx}>
              <div
                className={`${
                  idx === index ? 'bg-blue-300 border border-blue-700' : ''
                } p-2 flex`}
              >
                <div className='basis-1/6 p-1'>Month: {x.monthYear}</div>
                <div className='basis-1/6 p-1'>Principle: {x.principal}</div>
                <div className='basis-1/6 p-1'>Interest: {x.interest}</div>
                <div className='basis-1/6 p-1'>Status:{x.status}</div>
                <div className='basis-1/6 p-1'>PaymentDate:{x.paymentDate}</div>
                <div className='basis-1/6 p-1'>
                  {x.status !== 'paid' ? (
                    <PencilSquareIcon
                      className='w-4 inline'
                      onClick={() => {
                        setIndex(idx);
                        setPrinciple(x.principal);
                        setMonthYear(x.monthYear);
                        setInterest(x.interest);
                        setRepaymentStatus(x.status);
                        setPaymentDate(x.paymentDate);
                      }}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className='border border-gray-200 p-1 mb-2'>
          <div className='flex'>
            <div>
              Month:{' '}
              <input
                type={'month'}
                value={monthYear}
                onChange={(evt) => setMonthYear(evt.target.value)}
              />
            </div>
            <div>
              Principle:{' '}
              <input
                type={'number'}
                value={principle}
                onChange={(evt) => setPrinciple(parseInt(evt.target.value))}
              />
            </div>
            <div>
              Interest:{' '}
              <input
                type={'number'}
                value={interest}
                onChange={(evt) => setInterest(parseInt(evt.target.value))}
              />
            </div>
          </div>
          <div className='flex'>
            <div>
              Status:{' '}
              <select
                value={repaymentStatus}
                onChange={(evt) => setRepaymentStatus(evt.target.value)}
              >
                <option value=''>Select</option>
                <option value='pending'>Pending</option>
                <option value='paid'>Paid</option>
              </select>
            </div>
            <div>
              Payment Date:{' '}
              <DateSelector
                format='YYYY-MM-DD'
                initialState={paymentDate}
                onChange={(date) => setPaymentDate(date)}
              />
            </div>
            <div>
              <LoadingButton
                defaultStyle='bg-blue-700 p-1 border rounded text-white'
                behaviorFn={async () => {
                  if (index > 0 || repayments.length > 0) {
                    repayments[index] = {
                      monthYear: monthYear,
                      interest: interest,
                      principal: principle,
                      status: repaymentStatus as any,
                      paymentDate: paymentDate,
                    };
                  } else {
                    repayments.push({
                      monthYear: monthYear,
                      interest: interest,
                      principal: principle,
                      status: 'pending',
                      paymentDate: '',
                    });
                  }
                  setRepayments([...repayments]);
                }}
                text='Save'
              />
            </div>
          </div>
        </div>

        <div className='text-center'>
          <LoadingButton
            defaultStyle='mx-auto w-32 bg-blue-600 text-white border rounded-lg'
            behaviorFn={async () => {
              onSave({
                status: status,
                amount: companyLoan.amount,
                tenureInMonths: companyLoan.tenureInMonths,
                reason: companyLoan.reason,
                dateApplied: companyLoan.dateApplied,
                approval:
                  status === 'approved'
                    ? {
                        dateApproved: dateApproved,
                        approvedBy: approvedBy,
                        interestRate: interestRate,
                        amount: {
                          principal: totalPrinciple,
                          interest: totalInterest,
                        },
                        disbursementDetails: disbursementDetails,
                      }
                    : companyLoan.approval,
                repaymentSchedule: repayments,
              });
            }}
            text='Save Repayments'
          />
        </div>
      </div>
    );
  };
}
