import { MetricsType } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddMetricsForm() {
  return function AddMetricsForm({
    onSave,
  }: {
    onSave: (data: MetricsType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<MetricsType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'symbol',
          type: 'input',
          label: 'Short-Form',
          required: true,
        },
        {
          property: 'icon',
          type: 'input',
          label: 'Icon',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        symbol: '',
        icon: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          symbol: l.symbol,
          icon: l.icon,
        } as MetricsType);
      },
    };

    const Form = renderFormV2<Partial<MetricsType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<MetricsType>>): MetricsType {
      return (b as unknown) as MetricsType;
    }
  };
}
