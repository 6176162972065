import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type LateMarkType = {
  status: 'approved' | 'rejected';
  reason: string;
};

export const renderLateMarkForm = () => {
  return function LateMarkForm({ onSave }: { onSave: any }): JSX.Element {
    const formProps: FormProps<LateMarkType> = {
      dynamicContent: {
        top: async () => {
          return (
            <div>
              <div className='font-bold text-red-500 animate-pulse'>
                Please Consider Shift Switch if the employee is attending a
                different shift!{' '}
              </div>
            </div>
          );
        },
      },
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          required: true,
          label: 'Status',
          options: [
            { text: 'Select', value: '' },
            { text: 'Approve', value: 'approved' },
            { text: 'Reject', value: 'rejected' },
          ],
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
      ],
      initialFormState: {
        status: '',
        reason: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };
    const Form = renderFormV2<LateMarkType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<LateMarkType>): LateMarkType {
      return {
        status: b.status,
        reason: b.reason,
      } as any;
    }
  };
};
