import { FileTransferRepo } from '../datalayer/repository/http/fileTransfer';
import { FileTransferService } from '../datalayer/services/fileTransfer';
import { createUseFileTransfer } from '../hooks/file-transfer/use-file-transfer';
import { createUsePublicFileTransfer } from '../hooks/file-transfer/use-public-transfer';
import { FILE_MANAGER_SERVER_URI } from './server-uri';

export const fileTransferService = new FileTransferService(
  new FileTransferRepo(FILE_MANAGER_SERVER_URI)
);
export const usePublicTransfer = createUsePublicFileTransfer({
  useFileTransferService: () => fileTransferService,
});

export const useFileTransfer = createUseFileTransfer({
  useFileTransferService: () => fileTransferService,
});
