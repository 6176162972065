import { HazardClass } from '@erp_core/erp-types/dist/modules/inventory';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedHazardStatement } from '../../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';

type Props = {
  onSave: (form: any) => Promise<void>;
  useCombinedHazardStatement: UseCombinedHazardStatement;
};

export const renderEditGhsClassStatements = ({
  onSave,
  useCombinedHazardStatement,
}: Props) => {
  return function ({ hazardClassData }: { hazardClassData: HazardClass }) {
    type FormType = { statements: { id: string; name: string }[] };
    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          property: 'statements',
          type: 'searchable-multi-select',
          label: 'Hazard Statements',
          searchOptions: {
            useSearch: useCombinedHazardStatement,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        statements: hazardClassData.statements
          ? hazardClassData.statements.map((u) => ({ id: u.id, name: u.name }))
          : [],
      },
      onSubmit: onSave,
      mapTToU(p) {
        return (p as unknown) as FormType;
      },
    });

    return <Form />;
  };
};
