import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeAssesmentType } from 'models/types/quality-assurance/change-assesment';

// eslint-disable-next-line
export function renderSendToQA() {
  return function SendToQAForm({
    onSave,
    onClose,
  }: {
    onSave: any;
    onClose: () => any;
  }): JSX.Element {
    const formProps: FormProps<Partial<ChangeAssesmentType>> = {
      fieldsData: [
        {
          property: 'description',
          type: 'input',
          required: true,
          label: 'Description',
        },
      ],
      initialFormState: {
        name: '',
        description: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (data) => {
        onSave(data);
        onClose();
      },
    };

    const Form = renderFormV2<Partial<ChangeAssesmentType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ChangeAssesmentType>>
    ): ChangeAssesmentType {
      return {
        description: b.description as string,
      } as ChangeAssesmentType;
    }
  };
}
