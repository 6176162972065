/* eslint-disable prefer-template */

import { HazardClass } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';

type Props = {
  onSave: (data: any) => void;
  usePublicTransfer: UsePublicTransfer;
};

type AddHazardGhsClassFormProps = { defaultData?: HazardClass };

// eslint-disable-next-line
export function renderAddHazardGhsClassForm({
  onSave,
  usePublicTransfer,
}: Props): (d: AddHazardGhsClassFormProps) => JSX.Element {
  return function ({ defaultData }: AddHazardGhsClassFormProps): JSX.Element {
    const { companyGroup } = useContext(CurrentContext);

    type FormType = {
      type: string;
      chapterNo: string;
      name: string;
      categoryName: string;
      symbol: string;
      signalWord: string;
      description: string;
      criteria: string;
    };

    const formProps: FormProps<Partial<FormType>> = {
      fieldsData: [
        {
          property: 'type',
          type: 'select',
          options: [
            { text: 'Select Hazard Type', value: '' },
            { text: 'physical', value: 'physical' },
            { text: 'health', value: 'health' },
            { text: 'environment', value: 'environment' },
          ],
          required: true,
          label: 'Hazard Type',
        },
        {
          property: 'chapterNo',
          type: 'input',
          required: true,
          label: 'GHS Chapter No.',
        },
        {
          property: 'name',
          type: 'input',
          label: 'GHS Chapter Name',
        },
        {
          property: 'categoryName',
          type: 'input',
          required: true,
          label: 'Category Name',
        },
        {
          property: 'symbol',
          type: defaultData ? 'file' : 'input',
          readonly: true,
          accept: '.jpg, .png',
          label: 'Symbol',
        },
        {
          property: 'signalWord',
          type: 'select',
          options: [
            { text: 'No Signal Word', value: 'No Signal Word' },
            { text: 'Danger', value: 'Danger' },
            { text: 'Warning', value: 'Warning' },
          ],
          label: 'Signal Word',
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
        },
        {
          property: 'criteria',
          type: 'text-area',
          label: 'Criteria',
          rows: 3,
        },
      ],
      initialFormState: {
        type: defaultData?.type || '',
        name: defaultData?.name || '',
        chapterNo: defaultData?.chapterNo || '',
        categoryName: defaultData?.categoryName || '',
        signalWord: defaultData?.details.signalWord || 'No Signal Word',
        description: defaultData?.details.description || '',
        criteria: defaultData?.details?.criteria || '',
      },
      upload: {
        useFileTransfer: usePublicTransfer,
        config: [
          {
            name: 'symbol',
            path: `${companyGroup.id}/${defaultData?.id || 'default'}`,
            type: 'public',
          },
        ],
      },
      allowUnchanged: !defaultData,
      onSubmit: async (f) => {
        if (defaultData) {
          await onSave({ ...f, id: defaultData.id });
        } else {
          await onSave(f);
        }
      },
      mapTToU: mapFFDToF,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<FormType>>(formProps);

    return <Form />;

    function mapFFDToF(b: FormDataType<Partial<FormType>>): FormType {
      const hazardClass: HazardClass = ({ ...b } as unknown) as HazardClass;
      hazardClass['details'] = {};
      if (b.symbol) {
        hazardClass.details = {
          ...hazardClass.details,
          symbol: b.symbol as string,
        };
      }
      if (b.signalWord) {
        hazardClass.details = {
          ...hazardClass.details,
          signalWord: b.signalWord as string,
        };
      }
      if (b.description) {
        hazardClass.details = {
          ...hazardClass.details,
          description: b.description as string,
        };
      }

      if (b.criteria) {
        hazardClass.details = {
          ...hazardClass.details,
          criteria: b.criteria as string,
        };
      }
      return (hazardClass as unknown) as FormType;
    }
  };
}
