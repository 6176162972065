import {
  ActionType,
  Authorization,
  Company,
  CompanyFilterType,
  CompanyGroup,
  CompanyGroupSetting,
  LocationFilterType,
  LocationType,
  ModuleType,
  NotificationAction,
  OnboardEmployeeFilterType,
  OnboardEmployeeType,
  ResourceType,
  RoleType,
  User,
  UsersFilterType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AppearanceFilterType,
  AppearanceType,
  ColorFilterType,
  ColorType,
  ConstantsFilterType,
  CurrencyType,
  DeliveryTerm,
  EmployeeCategory,
  EmployeeDesignation,
  GSTType,
  HsnFilterType,
  HsnType,
  MetricsType,
  OdourFilterType,
  OdourType,
  PaymentTerm,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  DepartmentAppType,
  DepartmentFilterType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { ErpV2ApiAdapter } from '../adapter/erp-v2-api';
import { HttpActionAdminRepo } from '../datalayer/repository/http/admin/action';
import { HttpAuthorizationRepo } from '../datalayer/repository/http/admin/authorization';
import { HttpCompanyRepo } from '../datalayer/repository/http/admin/company';
import {
  CompanyGroupFilter,
  HttpCompanyGroupRepo,
} from '../datalayer/repository/http/admin/company-group';
import { HttpCompanyGroupSettingRepo } from '../datalayer/repository/http/admin/company-group-settings';
import { HttpAppearanceRepo } from '../datalayer/repository/http/admin/constants/appearance';
import { HttpColorRepo } from '../datalayer/repository/http/admin/constants/color';
import { HttpCurrencyRepo } from '../datalayer/repository/http/admin/constants/currency';
import { HttpDeliveryTermRepo } from '../datalayer/repository/http/admin/constants/delivery-term';
import { HttpEmployeeCategoryRepo } from '../datalayer/repository/http/admin/constants/employee-category';
import { HttpEmployeeDesignationRepo } from '../datalayer/repository/http/admin/constants/employee-designation';
import { HttpGstRepo } from '../datalayer/repository/http/admin/constants/gst';
import { HttpHsnRepo } from '../datalayer/repository/http/admin/constants/hsn';
import { HttpMetricsRepo } from '../datalayer/repository/http/admin/constants/metrics';
import { HttpOdourRepo } from '../datalayer/repository/http/admin/constants/odour';
import { HttpPaymentTermRepo } from '../datalayer/repository/http/admin/constants/payment-term';
import { HttpLocationsRepo } from '../datalayer/repository/http/admin/location';
import { HttpModuleAdminRepo } from '../datalayer/repository/http/admin/module';
import { HttpNotificationActionRepo } from '../datalayer/repository/http/admin/notification-action';
import { HttpOnboardEmployeeRepo } from '../datalayer/repository/http/admin/onborad-employee';
import { HttpResourceAdminRepo } from '../datalayer/repository/http/admin/resource';
import { HttpRoleAdminRepo } from '../datalayer/repository/http/admin/role';
import { HttpUsersRepo } from '../datalayer/repository/http/admin/user';
import { HttpUserRolesRepo } from '../datalayer/repository/http/admin/user-roles';
import { HttpDepartmentRepo } from '../datalayer/repository/http/hrd/department';
import { HttpLoginRepo } from '../datalayer/repository/http/login';
import { HttpResetPasswordRepo } from '../datalayer/repository/http/reset-password';
import { HttpSetPasswordRepo } from '../datalayer/repository/http/set-password';
import { createUseCurrentCompany } from '../hooks/admin/company-admin/use-current-company';
import { createUseCurrentCompanyGroup } from '../hooks/admin/company-group-admin/use-current-company-group';
import { createUseCurrentLocation } from '../hooks/admin/location-admin/use-current-location';
import { createUseCurrentUserRoles } from '../hooks/admin/role-admin/use-current-user-roles';
import { createUseSisterLocation } from '../hooks/admin/sister-location/use-sister-location';
import { createUseCurrentUser } from '../hooks/admin/user-admin/use-current-user';
import { createUseUserRolesAdmin } from '../hooks/admin/user-admin/use-user-roles-admin';
import { createUseUserAuthorization } from '../hooks/admin/user-authorization/use-user-authorization';
import { DeliveryTermFilter } from '../hooks/constants/delivery-term/use-delivery-term';
import { createUseLogin } from '../hooks/login/use-login';
import { createUseLogout } from '../hooks/logout/use-log-out';
import { createUseResetPassword } from '../hooks/reset-password/use-reset-password';
import { createUseSetPassword } from '../hooks/set-password/use-set-password';
import {
  ActionAdminInterface,
  ActionFilterType,
} from '../models/interfaces/admin/action-admin';
import {
  AuthorizationFilter,
  AuthorizationInterface,
} from '../models/interfaces/admin/authorization';
import { CompanyAdminInterface } from '../models/interfaces/admin/company-admin';
import { CompanyGroupAdminInterface } from '../models/interfaces/admin/company-group';
import {
  CompanyGroupSettingFilterType,
  CompanyGroupSettingInterface,
} from '../models/interfaces/admin/company-group-settings';
import { AppearanceInterface } from '../models/interfaces/admin/constants/appearance';
import { ColorInterface } from '../models/interfaces/admin/constants/color';
import { CurrencyInterface } from '../models/interfaces/admin/constants/currency';
import { DeliveryTermsInterface } from '../models/interfaces/admin/constants/delivery-term';
import { EmployeeCategoryInterface } from '../models/interfaces/admin/constants/employee-category';
import { EmployeeDesignationInterface } from '../models/interfaces/admin/constants/employee-designation';
import { GstInterface } from '../models/interfaces/admin/constants/gst';
import { HsnInterface } from '../models/interfaces/admin/constants/hsn';
import { MetricsInterface } from '../models/interfaces/admin/constants/metrics';
import { OdourInterface } from '../models/interfaces/admin/constants/odour';
import { PaymentTermsInterface } from '../models/interfaces/admin/constants/payment-term';
import { DepartmentInterface } from '../models/interfaces/admin/department';
import { LocationInterface } from '../models/interfaces/admin/location-admin';
import {
  ModuleAdminInterface,
  ModuleFilterType,
} from '../models/interfaces/admin/module-admin';
import {
  NotificationActionFilter,
  NotificationActionInterface,
} from '../models/interfaces/admin/notification-action';
import { OnboardEmployeeInterface } from '../models/interfaces/admin/onboard-employee';
import {
  ResourceAdminFilterType,
  ResourceAdminInterface,
} from '../models/interfaces/admin/resource-admin';
import {
  RoleAdminInterface,
  RoleFilterType,
} from '../models/interfaces/admin/role-admin';
import { UserAdminInterface } from '../models/interfaces/admin/user-admin';
import { ADMIN_SERVER_URI } from './server-uri';

export const erpV2Api = new ErpV2ApiAdapter({ adminApiUrl: ADMIN_SERVER_URI });

// ADMIN DOMAIN
export const loginService = new HttpLoginRepo(ADMIN_SERVER_URI, erpV2Api);
export const useLogin = createUseLogin({
  useLoginService: () => loginService,
});
export const useLogout = createUseLogout({});

export const resetPasswordService = new HttpResetPasswordRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useResetPassword = createUseResetPassword({
  useResetPasswordService: () => resetPasswordService,
});

export const setPasswordService = new HttpSetPasswordRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useSetPassword = createUseSetPassword({
  useSetPasswordService: () => setPasswordService,
});

export const locationsService = new HttpLocationsRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedLocationAdmin = createUseCombinedResource<
  LocationType,
  LocationFilterType,
  LocationInterface
>({
  useService: () => locationsService,
});

export const roleAdminService = new HttpRoleAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
// export const useRoleAdmin = createUseResource<
//   RoleType,
//   RoleFilterType,
//   RoleAdminInterface
// >({
//   useService: () => roleAdminService,
// });

export const useCombinedRole = createUseCombinedResource<
  RoleType,
  RoleFilterType,
  RoleAdminInterface
>({
  useService: () => roleAdminService,
});

export const moduleAdminService = new HttpModuleAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useCombinedModuleAdmin = createUseCombinedResource<
  ModuleType,
  ModuleFilterType,
  ModuleAdminInterface
>({
  useService: () => moduleAdminService,
});

export const resourceAdminService = new HttpResourceAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedResourcesAdmin = createUseCombinedResource<
  ResourceType,
  ResourceAdminFilterType,
  ResourceAdminInterface
>({
  useService: () => resourceAdminService,
});

export const actionAdminService = new HttpActionAdminRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedActionAdmin = createUseCombinedResource<
  ActionType,
  ActionFilterType,
  ActionAdminInterface
>({
  useService: () => actionAdminService,
});

export const companyAdminService = new HttpCompanyRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedCompanyAdmin = createUseCombinedResource<
  Company,
  CompanyFilterType,
  CompanyAdminInterface
>({
  useService: () => companyAdminService,
});

export const companyGroupAdminService = new HttpCompanyGroupRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedCompanyGroupAdmin = createUseCombinedResource<
  CompanyGroup,
  CompanyGroupFilter,
  CompanyGroupAdminInterface
>({
  useService: () => companyGroupAdminService,
});

export const userAdminService = new HttpUsersRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedUser = createUseCombinedResource<
  User,
  UsersFilterType,
  UserAdminInterface
>({
  useService: () => userAdminService,
});

export const userRolesAdminService = new HttpUserRolesRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useUserRoles = createUseUserRolesAdmin({
  useUserRolesAdminService: () => userRolesAdminService,
});

export const companyGroupSettingService = new HttpCompanyGroupSettingRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedCompanyGroupSetting = createUseCombinedResource<
  CompanyGroupSetting,
  CompanyGroupSettingFilterType,
  CompanyGroupSettingInterface
>({
  useService: () => companyGroupSettingService,
});

export const authorizationService = new HttpAuthorizationRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedAuthorization = createUseCombinedResource<
  Authorization,
  AuthorizationFilter,
  AuthorizationInterface
>({ useService: () => authorizationService });

export const notificationActionService = new HttpNotificationActionRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);
export const useCombinedNotificationAction = createUseCombinedResource<
  NotificationAction,
  NotificationActionFilter,
  NotificationActionInterface
>({ useService: () => notificationActionService });

export const employeeCategoryService = new HttpEmployeeCategoryRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedEmployeeCategory = createUseCombinedResource<
  EmployeeCategory,
  { search?: string },
  EmployeeCategoryInterface
>({
  useService: () => employeeCategoryService,
});

export const employeeDesignationService = new HttpEmployeeDesignationRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedEmployeeDesignation = createUseCombinedResource<
  EmployeeDesignation,
  { search?: string },
  EmployeeDesignationInterface
>({
  useService: () => employeeDesignationService,
});

export const useCurrentUser = createUseCurrentUser({
  useUserAdminService: () => userAdminService,
});
export const useCurrentCompanyGroup = createUseCurrentCompanyGroup({
  useCompanyGroupService: () => companyGroupAdminService,
});

export const useCurrentCompany = createUseCurrentCompany({
  useCompanyAdminService: () => companyAdminService,
  useCurrentCompanyGroup,
  useCurrentUser,
});
export const useCurrentLocation = createUseCurrentLocation({
  useLocationsService: () => locationsService,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useCurrentUser,
});
export const useCurrentUserRoles = createUseCurrentUserRoles({
  useCombinedUser: useCombinedUser,
});
export const useUserAuthorization = createUseUserAuthorization({
  userService: userAdminService,
});
export const useSisterLocation = createUseSisterLocation({
  useCombinedCompanyGroupSetting,
});

// CONSTANTS DOMAIN
export const currencyService = new HttpCurrencyRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedCurrency = createUseCombinedResource<
  CurrencyType,
  ConstantsFilterType,
  CurrencyInterface
>({
  useService: () => currencyService,
});

export const deliveryTermsService = new HttpDeliveryTermRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedDeliveryTerm = createUseCombinedResource<
  DeliveryTerm,
  DeliveryTermFilter,
  DeliveryTermsInterface
>({ useService: () => deliveryTermsService });

export const paymentTermsService = new HttpPaymentTermRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedPaymentTerm = createUseCombinedResource<
  PaymentTerm,
  DeliveryTermFilter,
  PaymentTermsInterface
>({ useService: () => paymentTermsService });

export const gstService = new HttpGstRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedGST = createUseCombinedResource<
  GSTType,
  ConstantsFilterType,
  GstInterface
>({
  useService: () => gstService,
});

export const hsnService = new HttpHsnRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedHsn = createUseCombinedResource<
  HsnType,
  HsnFilterType,
  HsnInterface
>({
  useService: () => hsnService,
});

export const metricsService = new HttpMetricsRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedMetric = createUseCombinedResource<
  MetricsType,
  ConstantsFilterType,
  MetricsInterface
>({
  useService: () => metricsService,
});
export const departmentService = new HttpDepartmentRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedDepartment = createUseCombinedResource<
  DepartmentAppType,
  DepartmentFilterType,
  DepartmentInterface
>({ useService: () => departmentService });

export const onboardEmployeeService = new HttpOnboardEmployeeRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedOnboardEmployee = createUseCombinedResource<
  OnboardEmployeeType,
  OnboardEmployeeFilterType,
  OnboardEmployeeInterface
>({ useService: () => onboardEmployeeService });

export const appearanceService = new HttpAppearanceRepo(
  ADMIN_SERVER_URI,
  erpV2Api
);

export const useCombinedAppearance = createUseCombinedResource<
  AppearanceType,
  AppearanceFilterType,
  AppearanceInterface
>({
  useService: () => appearanceService,
});

export const odourService = new HttpOdourRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedOdour = createUseCombinedResource<
  OdourType,
  OdourFilterType,
  OdourInterface
>({
  useService: () => odourService,
});

export const colorService = new HttpColorRepo(ADMIN_SERVER_URI, erpV2Api);

export const useCombinedColor = createUseCombinedResource<
  ColorType,
  ColorFilterType,
  ColorInterface
>({
  useService: () => colorService,
});
