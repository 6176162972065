/* eslint-disable no-unused-vars */
import { ResetOtpRequest } from '@erp_core/erp-types/dist/modules/admin';
import { ResetPasswordInterface } from '../../models/interfaces/reset-password';

export type UseResetPassword = () => {
  reset: (a: ResetOtpRequest) => void;
};

export interface UseResetPasswordOptions {
  useResetPasswordService: () => ResetPasswordInterface;
}

export function createUseResetPassword({
  useResetPasswordService,
}: UseResetPasswordOptions): UseResetPassword {
  return () => {
    const resetPasswordService = useResetPasswordService();

    const resetPasswordApiCall = async (a: ResetOtpRequest) => {
      try {
        await resetPasswordService.resetPassword(a);
      } catch (e) {
        console.log(e);
      }
    };

    return {
      reset: (a: ResetOtpRequest) => {
        resetPasswordApiCall(a);
      },
    };
  };
}
