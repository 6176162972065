import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { RotationalShiftDayType } from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-day';
import { OpenConfirm } from '@erp_core/erp-ui-components';
import { AdjustEmployeeOvertimProps } from '../../../shifts/employee-shifts/forms/select-switch-adjust-employee-form';

type Props2 = {
  openConfirm: OpenConfirm;
  getEmployeeSync: (id: string) => Promise<EmployeeProfileType>;
  currentUser: IdName;
  setOvertime: (data: OvertimeType) => Promise<OvertimeType>;
  setRotationalShiftDay: (data: RotationalShiftDayType) => void;
};

type CallbackType = {
  emp1: {
    shift: RotationalShiftDayType;
    overTime: OvertimeType;
  };
  emp2?: {
    shift: RotationalShiftDayType;
    overTime: OvertimeType;
  };
};

export const adjustEmployeeOvertime = async (
  {
    re1,
    re2,
    empShift,
    reason,
    markCurrentEmployeeOnLeave,
  }: AdjustEmployeeOvertimProps,
  {
    openConfirm,
    setOvertime,
    setRotationalShiftDay,
    getEmployeeSync,
    currentUser,
  }: Props2,
  callBack?: (data: CallbackType) => void
) => {
  const data: Partial<CallbackType> = {};

  openConfirm({
    title: 'Create Overtime',
    message: (
      <div>
        <p>
          {re2?.id ? '4' : '8'} hours of overtime will be created for{' '}
          {re1.employee.name} on date {re1.date}
        </p>
        <p>
          {re2?.id
            ? `4 hours overtime will be created for ${re2.employee.name} on date ${re2.date}`
            : ''}
        </p>
        <p>
          {markCurrentEmployeeOnLeave ? (
            <>
              {empShift.employee.name} will be marked on leave for date{' '}
              {empShift.date}
            </>
          ) : (
            <>
              {empShift.employee.name} will still work for date {empShift.date}{' '}
              but not at his designated workarea.
            </>
          )}
        </p>
      </div>
    ) as any,
    onConfirm: async () => {
      // create overtime for re1 8hours if rm2 is undefined and 4hours if rm2 is defined
      const re1Details = await getEmployeeSync(re1.employee.id);
      const emp1Overtime: Partial<OvertimeType> = {
        employee: re1.employee,
        company: re1Details.company,
        date: re1.date,
        hours: re2?.id ? '4' : '8',
        reason: reason || '',
        status: 'issued',
        issuedBy: { id: currentUser.id, name: currentUser.name },
      };
      const o1 = await setOvertime(emp1Overtime as OvertimeType);

      data['emp1'] = {
        shift: re1,
        overTime: o1,
      };

      // create overtime for re2 if re2 is defined
      if (re2 && re2.id) {
        const re2Details = await getEmployeeSync(re2.employee.id);
        const emp2Overtime: Partial<OvertimeType> = {
          employee: re2.employee,
          company: re2Details.company,
          date: re2.date,
          hours: '4',
          reason: reason || '',
          status: 'issued',
          issuedBy: { id: currentUser.id, name: currentUser.name },
        };
        const o2 = await await setOvertime(emp2Overtime as OvertimeType);
        data['emp2'] = {
          shift: re2,
          overTime: o2,
        };
      }

      // update empShift attendance = 'leave'
      if (markCurrentEmployeeOnLeave) {
        await setRotationalShiftDay({
          id: empShift.id,
          attendance: 'leave',
        } as RotationalShiftDayType);
      }

      if (callBack) callBack(data as CallbackType);
    },
  });
};
