import { HrdDashboardReportInterface } from '../../../models/interfaces/analytics/hrd-dashboard-report/report';
import {
  HrdDashboardReportQuery,
  HrdDashboardReportType,
} from '../../../models/types/analytics/hrd-dashboard';

export type PayrollReport =
  | 'comparison-report'
  | 'summary-report'
  | 'advance-recovery-report'
  | 'loan-recovery-report'
  | 'bonus-retention-report'
  | 'salary-sheet-details'
  | 'salary-sheet-details-one-line'
  | 'salary-sheet-regulatory'
  | 'tds-report'
  | 'esic-report'
  | 'pt-report'
  | 'epf-report';

export type UseHrdDashboardReport = () => {
  // eslint-disable-next-line no-unused-vars
  getReport(data: HrdDashboardReportQuery): Promise<HrdDashboardReportType>;
  getPayrollReport(month: string, reportType: PayrollReport): Promise<any>;
};

type CreateHrdReport = {
  useHrdDashboardReportService: () => HrdDashboardReportInterface;
};

export function createUseHrdDashboardReport({
  useHrdDashboardReportService,
}: CreateHrdReport): UseHrdDashboardReport {
  return () => {
    const hrdDashboardReportService = useHrdDashboardReportService();
    const getReport = async (data: HrdDashboardReportQuery) => {
      const resp = await hrdDashboardReportService.getReport(data);
      return resp;
    };

    const getPayrollReport = async (month: string, report: PayrollReport) => {
      const resp = await hrdDashboardReportService.getPayrollReport(
        month,
        report
      );
      return resp;
    };

    return {
      getReport,
      getPayrollReport,
    };
  };
}
