import { ItemHazard } from '@erp_core/erp-types/dist/types/modules/inventory/item';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedHazardGhsClass } from '../../../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedPrecautionaryStatement } from '../../../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-precautionary-statement';

type Props = {
  onSave: (form: any) => Promise<void>;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
  useCombinedPrecautionaryStatement: UseCombinedPrecautionaryStatement;
};
type T = { id: string; name: string };

export const renderAddHazardPrecautionInItem = ({
  onSave,
  useCombinedHazardGhsClass,
  useCombinedPrecautionaryStatement,
}: Props) => {
  return function ({ defaultHazard }: { defaultHazard: ItemHazard }) {
    type FormType = { class: T; statements: T[] };

    // const [currentState, setCurrentState] = useState<FormType>();

    const initialFormState: FormType = {
      class: { id: '', name: '' },
      // statements: currentState?.class.id
      //   ? defaultHazard.precautions
      //       ?.filter((ds) => (ds.ghsClassId = currentState.class.id))
      //       .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
      //   : [],
      statements: [],
    };

    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          property: 'class',
          type: 'searchable-select',
          label: 'Hazard Class',
          searchOptions: {
            useSearch: useCombinedHazardGhsClass,
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              customBody: (p) => (
                <div>
                  <div className='bold'>{p.name}</div>
                  <div className='text-sm text-gray-600'>{p.categoryName}</div>
                </div>
              ),
            },
          },
        },

        {
          property: 'statements',
          type: 'searchable-multi-select', // currentState?.class.id ? 'searchable-multi-select' : 'input',
          readonly: true,
          label: 'Hazard Precaution Statements',
          searchOptions: {
            useSearch: useCombinedPrecautionaryStatement,
            onSearchValueSelect(u) {},
            filter: {
              // hazardClassId: currentState?.class.id TODO this has to be set after making fields as state varibale
            },
          },
        },
      ],
      initialFormState,
      onFormDataChange: ({ formData, updateFormData, fields, setFields }) => {
        let classProp: any = {};
        updateFormData((ufd) => {
          classProp = ufd.class as { id: string; name: string };

          if (classProp.id) {
            console.log('naya value set kiya', defaultHazard.precautions, {
              statements: classProp.id
                ? defaultHazard.precautions
                    ?.filter((ds) => ds.ghsClassId === classProp.id)
                    .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
                : [],
              class: ufd.class,
            });
            return {
              statements: classProp.id
                ? defaultHazard.precautions
                    ?.filter((ds) => ds.ghsClassId === classProp.id)
                    .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
                : [],
              class: ufd.class,
            };
          }

          // Return the same object.
          return ufd;
        });

        if (classProp) {
          const stmts = fields.find((x) => x.property === 'statements');
          if (stmts && (stmts as any).searchOptions?.filter) {
            (stmts as any).searchOptions.filter.hazardClassId = classProp.id;
          }
          setFields([...fields]);
        }

        // const classProp = formData.class as { id: string; name: string; };

        // console.log(formData)
        // if (classProp.id // && (!currentState || classProp.id !== currentState.class.id)
        // ) {

        // console.log(classProp)
        //   updateFormData((latestVal) => {
        //     console.log(latestVal, formData);
        //     return {
        //       statements: classProp.id
        //         ? defaultHazard.precautions
        //             ?.filter((ds) => ds.ghsClassId === classProp.id)
        //             .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
        //         : [],
        //       class: formData.class,
        //     }
        //   });

        //   const stmts = fields.find(x => x.property === 'statements');
        //   if (stmts && stmts.searchOptions?.filter) {
        //     stmts.searchOptions.filter.hazardClassId = classProp.id;
        //   }
        //   setFields([ ...fields ]);
        // }
      },
      onSubmit: onSave,
      mapTToU(p) {
        return (p as unknown) as FormType;
      },
    });

    return (
      <Form
      // currentState={currentState}
      />
    );
  };
};
