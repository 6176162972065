/* eslint-disable react-hooks/exhaustive-deps */

import { ItemBatchGodown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGodown } from '../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { BatchItemGodownFilterType } from '../../../models/interfaces/inventory/item/batch/batch-item-godown';
import { UserRendererInterface } from '../../common/fragments/user';

export type RenderItemBatchesProps = {
  useCombinedUser: UseCombinedUser;
  useCombinedItem: UseCombinedItem;
  useCombinedGodown: UseCombinedGodown;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  userRendererService: UserRendererInterface;
};

export function renderItemBatches({
  useCombinedUser,
  useCombinedItem,
  useCombinedGodown,
  useCombinedBatchItemGodown,
  userRendererService,
}: RenderItemBatchesProps): () => JSX.Element {
  return function ItemBatches(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      ItemBatchGodown,
      BatchItemGodownFilterType
    >();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Batch-Item Register',
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Batch Name' },
        {
          name: (
            <>
              <CubeIcon className='inline w-6 h-6' />
              Product Name
            </>
          ),
        },
        { name: 'Date' },
        { name: 'Expiry Date' },
        { name: 'Rate' },
        { name: 'Quantity' },
        {
          name: (
            <>
              <CubeIcon className='inline w-6 h-6' />
              Godown Name
            </>
          ),
        },
        {
          name: 'Status',
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (r) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        {
          value: (
            <div>
              <div className='font-semibold'>{r.grade?.item?.name || ''}</div>
              <div className='font-light'>{r.grade.name}</div>
            </div>
          ),
          link: `/inventory/masters/items/${r.grade?.item?.id}/properties`,
        },
        { value: moment(r.createdAt).format('YYYY-MM-DD') },
        { value: r.expiryBy },
        { value: r.quantity },
        { value: r.rate || '' },
        {
          value: <>{r.godown?.name}</>,
          link: `/inventory/masters/godowns/${r.godown?.id}`,
        },
        {
          value: r.action,
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
            />
          ),
        },
      ];
      return {
        cells,
      };
    };

    const filter: Filter<BatchItemGodownFilterType> = {
      version: 'v2',
      sortFields: [
        // {
        //   key: 'ItemName',
        //   value: 'item', // TODO Sort filter not working
        //   defaultOrder: 'asc',
        // },
        // {
        //   key: 'GodownName',
        //   value: 'godown', // TODO Sort filter not working
        //   defaultOrder: 'asc',
        // },
        {
          key: 'BatchName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'Quantity',
          value: 'quantity',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          type: 'text',
          value: '',
        },
        {
          key: 'item_id',
          type: 'search-select',
          value: 'item_id',
        },
        {
          key: 'godownId',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: BatchItemGodownFilterType) => {
        const filterData: BatchItemGodownFilterType = {};
        filterData.state = 'real';
        filterData.active = true;

        if (filterSelection.search) {
          filterData.search = filterSelection.search;
        }
        if (filterSelection.item_id) {
          filterData.item_id = filterSelection.item_id;
        }
        if (filterSelection.godownId) {
          filterData.godownId = filterSelection.godownId;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as BatchItemGodownFilterType;
      },
      filterResources: {
        item_id: {
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect: (u) => {},
          },
        },
        godownId: {
          searchOptions: {
            useSearch: useCombinedGodown,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              filter={filter}
              type={{
                type: 'paginated',
                usePaginatedResources: useCombinedBatchItemGodown,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
