import { LoadingButton } from '@erp_core/erp-ui-components';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef } from 'react';

export type TextEditorProps = {
  initialHtml: string;
  onSave: (data: string) => void;
};

// key: vifpvbtfu4zv90fzqvaxyvm8ocdw1qxacjfjw2zi0t7z8kpz

// TODO: Make it self sufficient component to fetch and save data
export function renderTextEditor(): (props: TextEditorProps) => JSX.Element {
  return function TextEditor({
    initialHtml,
    onSave,
  }: TextEditorProps): JSX.Element {
    const editorRef = useRef(null);

    const log = () => {
      if (editorRef.current) {
        onSave((editorRef.current as any).getContent());
      }
    };

    return (
      <>
        <div className='flex flex-row-reverse'>
          <LoadingButton
            text='Save'
            behaviourParams={{}}
            behaviorFn={async () => log()}
            defaultStyle='bg-green-500 p-2 border rounded float-right'
            loadingStyle='bg-black-500 p-2 border rounded float-right'
          />
        </div>
        <Editor
          apiKey='vifpvbtfu4zv90fzqvaxyvm8ocdw1qxacjfjw2zi0t7z8kpz'
          onInit={(evt, editor) => ((editorRef as any).current = editor)}
          initialValue={initialHtml}
          init={{
            // height: 500,
            menubar: false,
            plugins: [
              // 'a11ychecker',
              'advlist',
              // 'advcode',
              // 'advtable',
              'autolink',
              // 'checklist',
              // 'export',
              'lists',
              'link',
              'image',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              // 'powerpaste',
              'fullscreen',
              // 'formatpainter',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
              'textcolor',
            ],
            toolbar:
              'undo redo | casechange blocks | image | bold italic backcolor forecolor | ' +
              'alignleft aligncenter alignright alignjustify | ' +
              'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            textcolor_cols: '5',
            link_default_target: '_blank',
            link_assume_external_targets: true,
          }}
        />
      </>
    );
  };
}
