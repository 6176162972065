import { VouchersType } from '@erp_core/erp-types/dist/modules/accounts';
import { VouchersInterface } from '../../../../../models/interfaces/accounts/vouchers/vouchers';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { VouchersFilterType } from '../../../../../models/types/accounts/vouchers';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpVouchersRepo
  extends GenericHttpWithLogsRepo<VouchersType, VouchersFilterType>
  implements VouchersInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/vouchers',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getDaybook({
    to,
    from,
  }: {
    to: string;
    from: string;
  }): Promise<Array<VouchersType>> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(`${this.resourceEndpoint}/daybook`, {
      params: {
        to,
        from,
      },
    });

    if (res.status === 200) {
      const { data } = res;
      return data as Array<VouchersType>;
    }

    return [] as Array<VouchersType>;
  }
}
