import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import _ from 'lodash';
import moment from 'moment';

export type CalESIC = {
  salRoute: string;
  value: string;
  reason: string;
  data: {
    value: 'Yes' | 'No' | 'Unknown';
    until?: string;
  };
};

export function findExtractableProperties(
  subgroup: SalaryParamGroup,
  propertiesToSave: { property: string; group: string }[]
) {
  if (subgroup.parameters) {
    subgroup.parameters.forEach((p) => {
      if (p.exportToSalaryRevision) {
        propertiesToSave.push({
          property: _.camelCase(p.name),
          group: p.group,
        });
      }
    });
  }

  if (subgroup.subgroups) {
    subgroup.subgroups.forEach((sg) => {
      findExtractableProperties(sg, propertiesToSave);
    });
  }
}

export function calculateESICDates(
  cgSetting: CompanyGroupSetting | null | undefined
): Array<string> {
  let dates = cgSetting?.details?.hrd?.salaryRules?.cutOffESICDates
    ? cgSetting?.details?.hrd?.salaryRules?.cutOffESICDates
        .split(',')
        .map((x) => {
          return moment(x.trim(), 'MM-DD').format('YYYY-MM-DD');
        })
    : [];
  if (dates.length) {
    const nextYearDates = dates.map((x) =>
      moment(x, 'YYYY-MM-DD').add('1', 'year').format('YYYY-MM-DD')
    );
    dates = [...dates, ...nextYearDates];
  }

  return dates;
}
