import {
  DateSelector,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LoanInterface } from '../../../../models/interfaces/loans/loan';
import { LoanV2Type } from '../types/loan-v2-type';
import { RepaymentWeight } from './view-repayments';

export function renderSimulateRepayments({
  loansService,
}: {
  loansService: LoanInterface;
}): ({ loan }: { loan: LoanV2Type }) => JSX.Element {
  const Table = renderTableComponent();
  return function SimulateRepayments({
    loan,
  }: {
    loan: LoanV2Type;
  }): JSX.Element {
    const [fromMonth, setFromMonth] = useState<string>(
      moment().format('YYYY-MM-DD')
    );
    const [monthlyRepayment, setMonthlyRepayment] = useState<number>(
      _.last(loan.details?.monthlyDeductions)?.amount || 1000
    );
    const [interestRate, setInterestRate] = useState<number>(
      _.last(loan?.details?.interestRates)?.rate || 12
    );
    const [disbursement, setDisbursement] = useState<number>(0);

    const [repayments, setRepayments] = useState<Array<any>>([]);
    useEffect(() => {
      const request: any = {
        id: loan.id,
      };

      if (monthlyRepayment) {
        request.monthlyDeduction = monthlyRepayment;
      }

      if (disbursement) {
        request.disbursement = monthlyRepayment;
      }

      if (interestRate) {
        request.interestRate = interestRate;
      }
      loansService.simulateSchedule(request).then((res) => {
        setRepayments(
          res.sort((a, b) => {
            const diff = moment(a.monthYear).diff(moment(b.monthYear));
            if (diff !== 0) {
              return diff;
            }

            if (RepaymentWeight[a.type] === RepaymentWeight[b.type]) {
              return moment(a.lastModifiedAt).diff(b.lastModifiedAt);
            }

            return RepaymentWeight[a.type] - RepaymentWeight[b.type];
          })
        );
      });
    }, [loan.id, fromMonth, monthlyRepayment, interestRate, disbursement]);
    return (
      <div>
        <div className='border border-gray-200 rounded-md p-2 my-1'>
          <div className='flex'>
            <div className='w-1/2'>
              <div>
                <b>Employee:</b> {loan.employee.name}
              </div>
              <div>
                <b>Loan Amount:</b>{' '}
                {loan.details?.loanRequests?.map((x, key) => (
                  <span
                    className='border border-gray-100 px-2 rounded-md'
                    key={key}
                  >
                    {x.amount} - {x.status} on {moment(x.date).format('MMM,YY')}
                  </span>
                ))}
              </div>
              <div>
                <b>Monthly Deduction:</b>{' '}
                {loan.details?.monthlyDeductions?.map((x, key) => (
                  <span
                    className='border border-gray-100 px-2 rounded-md'
                    key={key}
                  >
                    {x.amount} - {x.status} on {moment(x.date).format('MMM,YY')}
                  </span>
                ))}
              </div>
              <div>
                <b>Interest Percent:</b>{' '}
                {loan.details?.interestRates?.map((x, key) => (
                  <span
                    className='border border-gray-100 px-2 rounded-md'
                    key={key}
                  >
                    {x.rate}% - {x.status} on {moment(x.date).format('MMM,YY')}
                  </span>
                ))}
              </div>
            </div>
            <div className='w-1/2'>
              <div>
                <b>Monthly Deduction:</b>
                <input
                  type='number'
                  className='inline border border-gray-100 w-64'
                  defaultValue={monthlyRepayment}
                  onBlur={(evt) =>
                    setMonthlyRepayment(parseInt(evt.target.value))
                  }
                />
              </div>
              <div>
                <b>Interest Rate %:</b>
                <input
                  type='number'
                  className='inline border border-gray-100 w-64'
                  defaultValue={interestRate}
                  onBlur={(evt) => setInterestRate(parseInt(evt.target.value))}
                />
              </div>
              <div>
                <b>Disbursement:</b>
                <input
                  type='number'
                  className='inline border border-gray-100 w-64'
                  defaultValue={disbursement}
                  onBlur={(evt) => setDisbursement(parseInt(evt.target.value))}
                />
              </div>
              <div>
                <b>Date:</b>
                <DateSelector
                  format='YYYY-MM-DD'
                  initialState={fromMonth}
                  onChange={(date) => setFromMonth(date)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='border border-gray-200 rounded-md p-2 my-1'>
          <Table
            header={[
              [
                { name: 'Date' },
                { name: 'Type' },
                { name: 'No.Of.Days' },
                { name: 'Disbursement' },
                { name: 'Opening Balance' },
                { name: 'Interest Carried Over' },
                { name: 'Repayment' },
                { name: 'Monthly Interest' },
                { name: 'Interest Rate' },
                {
                  name: 'Repayment Contribution towards Interest',
                },
                {
                  name: 'Repayment Contribution towards Principal',
                },
                { name: 'Closing Balance' },
                { name: 'Status' },
              ],
            ]}
            body={[
              ...repayments.map((x) => {
                return {
                  cells: [
                    { value: moment(x.monthYear).format('YYYY-MM-DD') },
                    { value: x.type },
                    { value: x.details.noOfDays, style: 'text-right' },
                    {
                      value: x.details.disbursementAmt || '-',
                      style: 'text-right',
                    },
                    { value: x.details.openingBal, style: 'text-right' },
                    {
                      value: x.details.interestCarriedOver,
                      style: 'text-right',
                    },
                    { value: x.details.repayment, style: 'text-right' },
                    { value: x.details.monthlyInterest, style: 'text-right' },
                    { value: x.details.interestRate, style: 'text-right' },
                    {
                      value: x.details.repaymentContriInterest,
                      style: 'text-right',
                    },
                    {
                      value: x.details.repaymentContriPrincipal,
                      style: 'text-right',
                    },
                    { value: x.details.closingBal, style: 'text-right' },
                    { value: x.status },
                  ],
                };
              }),
              {
                cells: [
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  { value: '' },
                  {
                    value: _.sumBy(
                      repayments,
                      'details.repaymentContriInterest'
                    ).toFixed(2),
                    style: 'font-bold text-right',
                  },
                  {
                    value: _.sumBy(
                      repayments,
                      'details.repaymentContriPrincipal'
                    ).toFixed(2),
                    style: 'font-bold text-right',
                  },
                  { value: '' },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  };
}
