/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { SalesQcOrder } from '../../../models/types/sales/qc-order';
import { SalesQcOrderDummyData } from './dummyData';
import { renderDispatchRequestForm } from './forms/dispatch-request';

function renderSalesQcOrderDetails() {
  return function SalesQcOrderDetails() {
    const [data, setData] = useState<SalesQcOrder[]>();

    const getData = async () => {
      try {
        const resp = SalesQcOrderDummyData;
        if (resp) {
          setData(resp);
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getData();
    }, []);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name', rowSpan: 2 },
        { name: 'Respond By', rowSpan: 2 },
        { name: 'Client', rowSpan: 2 },
        { name: 'Items', rowSpan: 2 },
        { name: 'Remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        { name: 'Created By', rowSpan: 2 },
        { name: 'Status', rowSpan: 2 },
      ],
    ];

    const tableData =
      data?.map((item) => ({
        cells: [
          { value: item.name, link: '/' },
          { value: item.respondBy },
          { value: item.client },
          {
            value: (
              <div>
                {item.items.map((a, i) => (
                  <a key={i}>{a.name}, </a>
                ))}{' '}
              </div>
            ),
          },
          { value: item.remarks },
          {
            value: (
              <div>
                {item.attachments.map((a, i) => (
                  <a key={i}>{a}, </a>
                ))}{' '}
              </div>
            ),
          },
          { value: item.createdBy },
          { value: 'status' },
        ],
        rowData: {
          salesQcOrder: item,
        },
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Sales QC Orders',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Dispatch Request',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Dispatch Request',
                content: ({ data, onClose }) => {
                  const Form = renderDispatchRequestForm();
                  return (
                    <Form
                      onSave={() => {
                        toast('Dispatch request sent');
                        onClose();
                      }}
                    />
                  );
                },
              },
            },
            {
              name: 'Close Order',
              show: () => true,
              behaviour: 'click',
              onClick: async () => {
                toast('Order Closed.');
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderSalesQcOrderDetails;
