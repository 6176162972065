import {
  ConfiguratorFilterType,
  ConfiguratorType,
} from '@erp_core/erp-types/dist/modules/notification';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { NotificationRepo } from '../datalayer/repository/http/notification';
import { NotificationConfiguratorHttpRepo } from '../datalayer/repository/http/notification/configurator';
import { NotificationConfiguratorInterface } from '../models/interfaces/notification/configurator';
import { erpV2Api } from './admin';
import { NOTIFICATON_SERVER_URI } from './server-uri';

export const notificationService = new NotificationRepo(NOTIFICATON_SERVER_URI);

// Notification Configurator Service
export const notificationConfiguratorService = new NotificationConfiguratorHttpRepo(
  NOTIFICATON_SERVER_URI,
  erpV2Api
);
export const useCombinedNotificationConfigurator = createUseCombinedResource<
  ConfiguratorType,
  ConfiguratorFilterType,
  NotificationConfiguratorInterface
>({ useService: () => notificationConfiguratorService });
