import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedMisconduct } from '../../../../../hooks/hrd/disciplinary/misconduct/use-misconduct';
// import { UseCurrentUser } from '../../../../../hooks/admin/user-admin/use-current-user';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UserRendererInterface } from '../../../../common/fragments/user';
import { renderEmployeeMisconductForm } from './misconducts';
// import { createPenaltiesCard } from './penalties';

type CreateEmployeeDisciplinaryCardType = {
  // id: string;
  // useCurrentUser: UseCurrentUser;
  useFileTransfer: UseFileTransfer;
  useCombinedMisconduct: UseCombinedMisconduct;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  employee: EmployeeProfileType;
  userRendererService: UserRendererInterface;
  // setEmployee: (s: EmployeeProfileType) => void;
};

export function renderEmployeeDisciplinary({
  employee,
  // setEmployee,
  // useCurrentUser,
  // id,
  useFileTransfer,
  useCombinedMisconduct,
  useCombinedEmployeeProfile,
  userRendererService,
}: CreateEmployeeDisciplinaryCardType): () => JSX.Element {
  const Misconduct = renderEmployeeMisconductForm();

  return function EmployeeDisciplinary(): JSX.Element {
    return (
      <>
        {/* <div>{data.id ? <Memo id={data.id} /> : null}</div> */}
        <br />
        {/* <div>{data.id ? <Counselling id={data.id} /> : null}</div> */}
        {/* <PenaltiesCard
          useCurrentUser={useCurrentUser}
          employee={employee}
          setEmployee={setEmployee}
        /> */}
        <br />
        <Misconduct
          userRendererService={userRendererService}
          useFileTransfer={useFileTransfer}
          useCombinedMisconduct={useCombinedMisconduct}
          useCombinedEmployeeProfile={useCombinedEmployeeProfile}
          employee={employee}
        />
      </>
    );
  };
}
