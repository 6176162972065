/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderHistory,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedBom } from '../../../../../hooks/order/work-order/bom/use-bom';

export function renderBomHistory({
  id,
  useCombinedBom,
}: {
  id: string;
  useCombinedBom: UseCombinedBom;
}) {
  return function BomHistory() {
    const { resource: data, get } = useCombinedBom();

    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const History = renderHistory();

    const cardHeader: CardHeader = {
      title: `${data?.name}`,
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <History useResource={useCombinedBom} id={id} />,
    };

    return <Card header={cardHeader} body={cardBody} />;
  };
}
