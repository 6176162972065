/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { ItemBatchGodown } from '@erp_core/erp-types/dist/modules/inventory';
import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import moment from 'moment';
import { AllocateData } from '../../../../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import { BatchItemGodownFilterType } from '../../../../../../models/interfaces/inventory/item/batch/batch-item-godown';
import { BOMInterface } from '../../../../../../models/interfaces/order/work-order/bom';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class HttpBatchItemGodownRepo extends GenericHttpWithLogsRepo<
  ItemBatchGodown,
  BatchItemGodownFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  bomService: BOMInterface;

  constructor(apiUrl: string, erpv2Api: ErpV2Api, bomService: BOMInterface) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/items/batches/item-batch-godowns',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
    this.bomService = bomService;
  }

  async allocateItem(data: AllocateData) {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const allocateData = {
      id: data.id,
      referenceId: data.referenceId,
      quantity: data.quantity,
    };
    const result = await http.post('/items/batches/allocate', allocateData);
    if (result.status === 200) {
      if (data.referenceType === 'bom') {
        const bomData = await this.bomService.get(data.referenceId);

        const bomDataRequest: BOMRes = {
          id: data.referenceId,
          details: {
            status: data.allocationComplete ? 'allocated' : 'closed',
            activity: [
              {
                action: 'allocation',
                details: '',
                user: data.currentUser,
                event: `${data.currentUser.name} allocated ${data.quantity} ${bomData.rawItem.name} the BOM ${bomData.name}`,
                time: moment.utc().format(),
              },
              ...(bomData.details.activity || []),
            ] as any,
          },
        } as BOMRes;

        await this.bomService.set(bomDataRequest);
      }
    }
    return result.data as boolean;
  }
}
