import {
  SalaryAdvanceFilter,
  SalaryAdvanceType,
} from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { SalaryAdvanceHttpRepo } from '../datalayer/repository/http/salary-advance';
import { createUseSalaryAdvanceEligibleAmt } from '../hooks/hrd/salary-advance/use-salary-advance-eligible-amount';
import { SalaryAdvanceInterface } from '../models/interfaces/hrd/salary-advance';
import { erpV2Api } from './admin';
import { SALARY_ADVANCE_SERVER_URI } from './server-uri';

export const salaryAdvanceService = new SalaryAdvanceHttpRepo(
  SALARY_ADVANCE_SERVER_URI,
  erpV2Api
);

export const useCombinedSalaryAdvance = createUseCombinedResource<
  SalaryAdvanceType,
  SalaryAdvanceFilter,
  SalaryAdvanceInterface
>({
  useService: () => salaryAdvanceService,
});

export const useSalaryAdvanceEligibleAmt = createUseSalaryAdvanceEligibleAmt({
  useSalaryAdvanceEligibleAmtService: () => salaryAdvanceService,
});
