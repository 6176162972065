import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { decodeToken } from 'react-jwt';

export type UserToken = {
  user: CurrentUserType;
  companyGroup: {
    id: string;
    name: string;
  };
};

type CompanyGroupLocalStorageType = {
  [key: string]: {
    [key: string]: {
      currentCompanyGroup?: IdName;
      currentCompany?: IdName;
      currentLocation?: IdName;
    };
  };
};
export class CompanyGroupLocalStorage {
  ItemName = 'company-group-settings';
  get() {
    try {
      const data: CompanyGroupLocalStorageType = JSON.parse(
        localStorage.getItem(this.ItemName) || '{}'
      );
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  }

  set(user: IdName, companyGroup: IdName, company: IdName, location: IdName) {
    if (!companyGroup?.id || !company?.id || !location?.id) return {};
    try {
      let data = this.get();
      data[user.id] = {
        ...data[user.id],
        [companyGroup.id]: {
          currentCompany: company,
          currentCompanyGroup: companyGroup,
          currentLocation: location,
        },
      };
      localStorage.setItem(this.ItemName, JSON.stringify(data));
      return data;
    } catch (e) {
      // console.log(e);
      return {};
    }
  }

  clear() {
    localStorage.removeItem(this.ItemName);
  }
}

export function setUserToken(userToken: string, refreshToken?: string): void {
  localStorage.setItem('erp-token', userToken);
  if (refreshToken) {
    localStorage.setItem('erp-refresh-token', refreshToken);
  }

  const userTokenDetails = decodeToken<UserToken>(userToken);

  if (userTokenDetails) {
    localStorage.setItem(
      'current-company-group',
      JSON.stringify(userTokenDetails.companyGroup)
    );
    localStorage.setItem('current-user', JSON.stringify(userTokenDetails.user));
  }
}

export function resetUserToken(): void {
  localStorage.removeItem('current-user');
  localStorage.removeItem('current-company-group');
  localStorage.removeItem('currentLocation');
  localStorage.removeItem('currentCompany');
  localStorage.removeItem('erp-token');
  localStorage.removeItem('erp-refresh-token');
}

export function getUserToken(): string {
  return localStorage.getItem('erp-token') || '';
}

export function getRefreshToken(): string {
  return localStorage.getItem('erp-refresh-token') || '';
}
