import { renderPureHistory } from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { OverviewFlow } from '../../../../../../components/flow-chart';
import { UseCombinedPurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { getPurchaseOrderFlowGraph } from './utils/get-po-flow-graph';
// import { getPrFlowGraph } from './utils/get-pr-flow-graph';

export function renderPurchaseOrderProfile({
  id,
  useCombinedPurchaseOrder,
}: {
  id: string;
  useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
}): () => JSX.Element {
  return function PRProfile() {
    const { get: getOrder, resource: data } = useCombinedPurchaseOrder();
    useEffect(() => {
      getOrder(id);
      // eslint-disable-next-line
    }, []);

    const History = renderPureHistory();

    return (
      <div>
        <div className='w-full h-48 border border-gray-200 mx-auto'>
          <OverviewFlow data={data} getFlowGraph={getPurchaseOrderFlowGraph} />
        </div>
        <div className='w-full p-4'>
          {data?.details?.activity && data?.details?.activity.length > 0 ? (
            <History logs={data.details.activity} />
          ) : null}
        </div>
      </div>
    );
  };
}
