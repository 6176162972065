import {
  GetDisbursementRequest,
  GetDisbursementResponse,
  LoanInterface,
} from 'models/interfaces/loans/loan';
import { useState } from 'react';

export type UseDisbursementData = () => {
  getDisbursementValue: (p: GetDisbursementRequest) => void;
  data: GetDisbursementResponse[];
};

export interface UseDisbursmentOptions {
  useDisbursementValueService: () => LoanInterface;
}

export function createUseDisbursementValue({
  useDisbursementValueService,
}: UseDisbursmentOptions): UseDisbursementData {
  return () => {
    const loanService = useDisbursementValueService();
    const [data, setData] = useState<any>();

    const disbursementValApiCall = async (p: GetDisbursementRequest) => {
      try {
        const resp = await loanService.getDisbursement(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      getDisbursementValue: (p: GetDisbursementRequest) => {
        disbursementValApiCall(p);
      },
      data,
    };
  };
}
