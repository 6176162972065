/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import { SalaryFilterType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import { ErpV2Api } from 'models/interfaces/adapters/erp-v2-api';
import {
  GetSalaryAdvanceAmountReq,
  GetSalaryAdvanceAmountResp,
} from 'models/interfaces/hrd/salary-advance';
import { GenericHttpWithLogsRepo } from '../generic-resource-with-logs';

export class SalaryAdvanceHttpRepo extends GenericHttpWithLogsRepo<
  SalaryAdvanceType,
  SalaryFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/salary-advances',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getEligibleAdvanceAmt(
    p: GetSalaryAdvanceAmountReq
  ): Promise<GetSalaryAdvanceAmountResp> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });
    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${p}/eligible-value`
    );

    if (res.status === 200) {
      const { data } = res;
      return data as GetSalaryAdvanceAmountResp;
    }

    return {} as GetSalaryAdvanceAmountResp;
  }
}
