import { VendorLocation } from '@erp_core/erp-types/dist/modules/order';
import { VouchersType } from '@erp_core/erp-types/dist/types/modules/accounts/vouchers';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { GeneralAddressType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  KeyValue,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { paymentTerms } from '@erp_core/erp-utils';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../../contexts/current';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';
import { UseCombinedItemCategory } from '../../../../../hooks/inventory/item/item-category/use-item-category';
import { UseCombinedVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { EditDeliverySchedule } from './delivery-schedule';
import { EditGRNDetails, EditGRNItems, EditGRNTransport } from './grn-details';
import { EditShipToDetails } from './ship-to-details';

type ItemType = {
  grade: Grade;
  quantity: number;
  rate: number;
  uom: string;
  discountPercent: number;
  deliverySchedule: Array<{
    batchNumber: number;
    date: string;
    quantity: number;
  }>;
  amount: number;
  gst: number;
};

export type GRNVoucher = {
  details: {
    name: string;
    date: string;
    invoiceNumber: string;
    totalAmountPayable: number;
    dispatchThrough: string;
  };
  items: Array<{
    grade: IdName;
    batchNumber: number;
    quantity: number;
    rate: number;
    amount: number;
    reason: string;
  }>;
  transport: Array<{
    name: string;
    type: 'BL' | 'E-WAY-Bill' | 'AWB' | 'LR' | 'Courier Docket' | 'GIRIR';
    documentNumber: string;
    vehicleNumber: string;
    amount: number;
    date: string;
    remarks: string;
    attachment: string;
  }>;
};

export type POVoucher = {
  name: string;
  voucherNumber: string;
  itemCategory: IdName;
  date: string;
  referenceNumber: string;
  vendor: IdName;
  vendorAddress: VendorLocation;
  vendorGST: string;
  vendorPan: string;
  paymentTerms: Array<{
    percent: number;
    paymentType: string;
    noOfDays: number;
  }>;
  buyer: {
    delivery: {
      type: 'self' | 'job-work';
      name: string;
      address: GeneralAddressType;
      contact: {
        mobile: string;
        email: string;
      };
      gst: string;
    };
    billing: {
      name: string;
      address: GeneralAddressType;
      contact: {
        mobile: string;
        email: string;
      };
      gst: string;
    };
  };
  grns: Array<GRNVoucher>;
  purchaseLedger: IdName;
  items: Array<ItemType>;
  transportationPaidBy: 'seller' | 'buyer' | 'unknown';
  transportationCost: '' | 'part-of-invoice' | 'separate-invoice';
  total: number;
  remarks: string;
};

export function renderPurchaseOrder({
  useCombinedVendor,
  useCombinedItemCategory,
  useCombinedGrade,
  itemService,
}: {
  useCombinedVendor: UseCombinedVendor;
  useCombinedGrade: UseCombinedGrade;
  useCombinedItemCategory: UseCombinedItemCategory;
  itemService: ItemInterface;
}) {
  return function PurchaseOrderVoucher({
    po,
    onSave,
  }: {
    po?: VouchersType;
    onSave(s: VouchersType): Promise<void>;
  }) {
    const [voucher, setVoucher] = useState<POVoucher>(
      po ? (po.details as POVoucher) : ({} as POVoucher)
    );
    const [editBasicDetails, setEditBasicDetails] = useState<boolean>(false);
    const {
      get: getVendor,
      resource: selectedVendor,
      getSync: getVendorSync,
    } = useCombinedVendor();
    const { location, company } = useContext(CurrentContext);
    const { getAll: getVendors, list: jobWorkVendors } = useCombinedVendor();

    useEffect(() => {
      if (voucher?.vendor?.id) {
        getVendor(voucher.vendor.id);
      }
      // eslint-disable-next-line
    }, [voucher?.vendor?.id]);

    useEffect(() => {
      getVendors({
        type: 'job-work',
      });
      // eslint-disable-next-line
    }, []);

    const Form1 = renderFormV2({
      formName: 'PO Details',
      initialFormState: {
        name: voucher.name || '',
        voucherNumber: voucher.voucherNumber || '',
        itemCategory: voucher.itemCategory || { id: '', name: '' },
        date: voucher.date || '',
        referenceNumber: voucher.referenceNumber || '',
        vendor: voucher.vendor || { id: '', name: '' },
        remarks: voucher.remarks || '',
        transportationPaidBy: voucher.transportationPaidBy || '',
        transportationCost: voucher.transportationCost || '',
        // purchaseLedger: voucher.purchaseLedger || { id: '', name: '' },
      },
      style: 'w-1/6',
      fieldsData: [
        {
          property: 'name',
          label: 'PO Number',
          type: 'input',
          required: true,
        },
        {
          property: 'referenceNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'date',
          type: 'date',
          required: true,
        },
        {
          property: 'voucherNumber',
          type: 'input',
          required: true,
        },
        {
          property: 'itemCategory',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedItemCategory,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'vendor',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedVendor,
            onSearchValueSelect(u) {},
          },
          required: true,
        },
        {
          property: 'transportationPaidBy',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Seller', value: 'seller' },
            { text: 'Buyer', value: 'buyer' },
            { text: 'Unknown', value: 'unknown' },
          ],
          required: true,
        },
        {
          property: 'transportationCost',
          type: 'select',
          dependentOn: (formData) => {
            return formData.transportationPaidBy === 'buyer';
          },
          options: [
            { text: 'Select', value: '' },
            { text: 'Part of Invoice', value: 'part-of-invoice' },
            { text: 'Separate Invoice', value: 'separate-invoice' },
          ],
        },
        {
          property: 'remarks',
          type: 'input',
        },
      ],
      mapTToU: (x) => x,
      onSubmit: async (form) => {
        console.log(form);
        let vendorAddress: any = voucher.vendorAddress || undefined;
        let vendorPaymentTerms: any = voucher.paymentTerms;
        if (form.vendor) {
          vendorAddress = undefined;
          const ven = await getVendorSync((form.vendor as any)?.id);
          vendorPaymentTerms = ven?.details?.termsOfBusiness?.paymentTerms;
        }
        setVoucher((x) => ({
          ...x,
          ...{ vendorAddress, paymentTerms: vendorPaymentTerms },
          ...(form as any),
        }));
        setEditBasicDetails(false);
      },
    });
    const Table = renderTableComponent();
    const Card = renderCardComponent();
    return (
      <div>
        {editBasicDetails ? (
          <Form1 />
        ) : (
          <Card
            header={{
              title: 'Purchase Order Details',
              actions: [
                {
                  type: 'button',
                  button: {
                    name: 'Edit',
                    behaviour: 'click',
                    onClick: async () => {
                      setEditBasicDetails(true);
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'columns',
              body: [
                [
                  { key: 'PO Number', value: voucher.name },
                  { key: 'Reference #', value: voucher.referenceNumber },
                ],
                [
                  { key: 'Date', value: voucher.date },
                  { key: 'Voucher #', value: voucher.voucherNumber },
                ],
                [
                  { key: 'Item Category', value: voucher.itemCategory?.name },
                  { key: 'Vendor', value: voucher.vendor?.name },
                ],
                [
                  {
                    key: 'Transportation Paid By',
                    value:
                      voucher.transportationPaidBy === 'buyer'
                        ? `${voucher.transportationPaidBy} (${voucher.transportationCost})`
                        : voucher.transportationPaidBy,
                  },
                  { key: 'Remarks', value: voucher.remarks },
                ],
              ],
            }}
          />
        )}

        <div className='flex flex-wrap'>
          <div className='w-1/4'>
            <Card
              header={{ title: 'Bill To' }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <div className='font-bold'>{location.company?.name}</div>
                    <KeyValue
                      lhs='Address'
                      rhs={[
                        `${location.details?.invoiceTo?.address?.addressLine1}, ${location.details?.invoiceTo?.address?.addressLine2}`,
                        `${location.details?.invoiceTo?.address.city} - ${location.details?.invoiceTo?.address.pincode}, ${location.details?.invoiceTo?.address.district}`,
                        `${location.details?.invoiceTo?.address.state}, ${location.details?.invoiceTo?.address.country}`,
                      ]}
                    />
                    <div className='flex justify-between'>
                      <KeyValue
                        lhs='GST'
                        rhs={
                          company.details?.documents?.find(
                            (x) => x.type === 'gst'
                          )?.number || '-'
                        }
                      />
                      <KeyValue
                        lhs='PAN'
                        rhs={
                          company.details?.documents?.find(
                            (x) => x.type === 'pan-card'
                          )?.number || '-'
                        }
                      />
                    </div>
                  </div>
                ),
              }}
            />
          </div>
          <div className='w-1/4'>
            <Card
              header={{
                title: 'Ship To',
                actions: [
                  {
                    type: 'button',
                    button: {
                      name: 'Edit',
                      behaviour: 'modal',
                      modal: {
                        title: 'Edit Ship To',
                        content: () => {
                          return (
                            <EditShipToDetails
                              jobWorkVendors={jobWorkVendors || []}
                              po={voucher}
                              currentLocation={{
                                name: location.company.name,
                                address: location.details?.address,
                                gst:
                                  company.details?.documents?.find(
                                    (x) => x.type === 'gst'
                                  )?.number || '-',
                                contact: company.details.contact,
                              }}
                              savePo={async (po) => setVoucher(po)}
                            />
                          );
                        },
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <div className='font-bold'>
                      {voucher?.buyer?.delivery?.name}
                    </div>
                    <KeyValue
                      lhs='Address'
                      rhs={[
                        `${
                          voucher?.buyer?.delivery?.address?.addressLine1 || ''
                        }, ${
                          voucher?.buyer?.delivery?.address?.addressLine2 || ''
                        }`,
                        `${voucher?.buyer?.delivery?.address.city || ''} - ${
                          voucher?.buyer?.delivery?.address.pincode || ''
                        }, ${voucher?.buyer?.delivery?.address.district || ''}`,
                        `${voucher?.buyer?.delivery?.address.state || ''}, ${
                          voucher?.buyer?.delivery?.address.country || ''
                        }`,
                      ]}
                    />
                    <KeyValue lhs='GST' rhs={voucher?.buyer?.delivery?.gst} />
                  </div>
                ),
              }}
            />
          </div>
          <div className='w-1/4'>
            {voucher.vendor?.id ? (
              <Card
                header={{
                  title: `
                ${voucher.vendor.name} Details
                  ${
                    voucher.vendorAddress?.name
                      ? `${
                          location.details?.invoiceTo?.address?.country ===
                          voucher.vendorAddress?.address?.country
                            ? `(PO - Domestic ${
                                location.details?.invoiceTo?.address?.state ===
                                voucher.vendorAddress?.address?.state
                                  ? 'Same State'
                                  : 'Other State'
                              })`
                            : '(PO - International)'
                        }`
                      : ''
                  }
              `,
                }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <div className=''>
                      <div className=''>
                        <select
                          value={voucher.vendorAddress?.name || ''}
                          onChange={(evt) => {
                            const sel = selectedVendor?.details?.locations?.find(
                              (x) => x.name === evt.target.value
                            );
                            if (sel) {
                              setVoucher((v) => ({
                                ...v,
                                ...{ vendorAddress: sel },
                              }));
                            }
                          }}
                        >
                          <option value=''>Select Address</option>
                          {selectedVendor?.details?.locations?.map((x) => (
                            <option key={x.name} value={x.name}>
                              {x.name}
                            </option>
                          ))}
                        </select>
                        {voucher.vendorAddress?.name ? (
                          <>
                            <KeyValue
                              lhs='Address'
                              rhs={[
                                `${voucher.vendorAddress?.address.addressLine1}, ${voucher.vendorAddress?.address?.addressLine2}`,
                                `${voucher.vendorAddress?.address.city} - ${voucher.vendorAddress?.address.pincode}, ${voucher.vendorAddress?.address.district}`,
                                `${voucher.vendorAddress?.address.state}, ${voucher.vendorAddress?.address.country}`,
                              ]}
                            />
                            <div className='flex justify-between'>
                              <KeyValue lhs='GST' rhs={voucher.vendorGST} />
                              <KeyValue lhs='PAN' rhs={voucher.vendorPan} />
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className='block'>
                        {selectedVendor?.id ? (
                          <>
                            <KeyValue
                              lhs='Payment Terms'
                              rhs={paymentTerms(
                                selectedVendor?.details?.termsOfBusiness
                                  ?.paymentTerms
                              )}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  ),
                }}
              />
            ) : null}
          </div>
          <div className='w-1/4'>
            {selectedVendor?.id && voucher?.vendorAddress?.id ? (
              <Card
                header={{ title: 'Account Ledgers' }}
                body={{
                  type: 'jsx-component',
                  body: (
                    <div className='flex'>
                      {calculateLedgers({
                        itemCategory: voucher.itemCategory.name,
                        gsts: _.uniq(voucher.items?.map((x) => x.gst)),
                        currentAddress: {
                          state: location.details?.invoiceTo?.address.state,
                          country: location.details?.invoiceTo?.address.country,
                        },
                        vendorAddress: {
                          state: voucher.vendorAddress?.address.state,
                          country: voucher.vendorAddress?.address.country,
                        },
                        transportationCost: voucher.transportationCost,
                        transportationPaidBy: voucher.transportationPaidBy,
                      }).map((x, idx) => (
                        <div
                          className='border border-gray-200 mx-1 rounded-md'
                          key={idx}
                        >
                          {x}
                        </div>
                      ))}
                    </div>
                  ),
                }}
              />
            ) : null}
          </div>
        </div>
        {selectedVendor?.id ? (
          <Card
            header={{
              title: 'Items',
              actions: [
                {
                  type: 'button',
                  button: {
                    name: 'Add',
                    behaviour: 'click',
                    onClick: async () => {
                      const items = voucher.items || [];
                      items.push({} as any);
                      setVoucher({ ...voucher, ...{ items } });
                    },
                  },
                },
              ],
            }}
            body={{
              type: 'jsx-component',
              body: (
                <Table
                  header={[
                    [
                      { name: 'Item (Grade)' },
                      { name: 'Delivery Schedule' },
                      { name: 'Quantity' },
                      { name: 'Rate' },
                      { name: 'Discount %' },
                      { name: 'Net Amount' },
                      { name: 'GST%' },
                      { name: 'GST-Amount' },
                      { name: 'Amount' },
                    ],
                  ]}
                  body={(voucher.items || [])?.map((x, idx) => {
                    const taxes = getTax(
                      x?.gst || 0,
                      {
                        state: location.details?.invoiceTo?.address.state,
                        country: location.details?.invoiceTo?.address.country,
                      },
                      {
                        state: voucher.vendorAddress?.address.state,
                        country: voucher.vendorAddress?.address.country,
                      }
                    );
                    return {
                      cells: [
                        {
                          value: (
                            <>
                              <div>{x?.grade?.name || 'Not Set'}</div>
                              <div>{x?.grade?.item?.name}</div>
                            </>
                          ),
                        },
                        {
                          value: (
                            <div>
                              {x.deliverySchedule?.map((y) => (
                                <div key={y.batchNumber}>
                                  <KeyValue
                                    lhs={`Batch ${y.batchNumber}`}
                                    rhs={`Quantity ${y.quantity}${x?.uom} on ${y.date}`}
                                  />
                                </div>
                              ))}
                            </div>
                          ),
                        },
                        { value: `${x?.quantity}${x?.uom}` || 'Not Set' },
                        { value: x?.rate || 'Not Set' },
                        { value: x?.discountPercent || 'Not Set' },
                        {
                          value: (
                            <>
                              {getNetAmount({
                                quantity: x?.quantity || 0,
                                rate: x?.rate || 0,
                                discount: x?.discountPercent || 0,
                              })}
                            </>
                          ),
                        },
                        {
                          value: (
                            <>
                              {taxes.map((y, idx) => (
                                <div key={idx}>
                                  <KeyValue lhs={y.key} rhs={`${y.value}%`} />
                                </div>
                              ))}
                            </>
                          ),
                        },
                        {
                          value: (
                            <>
                              {getGSTAmount({
                                quantity: x?.quantity || 0,
                                rate: x?.rate || 0,
                                discount: x?.discountPercent || 0,
                                gst: x?.gst || 0,
                                currentAddress: {
                                  state:
                                    location.details?.invoiceTo?.address.state,
                                  country:
                                    location.details?.invoiceTo?.address
                                      .country,
                                },
                                vendorAddress: {
                                  state: voucher.vendorAddress?.address.state,
                                  country:
                                    voucher.vendorAddress?.address.country,
                                },
                              })}
                            </>
                          ),
                        },
                        {
                          value:
                            (
                              <>
                                {getAmount({
                                  quantity: x?.quantity || 0,
                                  rate: x?.rate || 0,
                                  discount: x?.discountPercent || 0,
                                  gst: x?.gst || 0,
                                  currentAddress: {
                                    state:
                                      location.details?.invoiceTo?.address
                                        .state,
                                    country:
                                      location.details?.invoiceTo?.address
                                        .country,
                                  },
                                  vendorAddress: {
                                    state: voucher.vendorAddress?.address.state,
                                    country:
                                      voucher.vendorAddress?.address.country,
                                  },
                                })}
                              </>
                            ) || 'Not Set',
                        },
                      ],
                      rowData: {
                        item: x,
                        idx,
                      },
                    };
                  })}
                  actions={[
                    {
                      name: 'Edit',
                      behaviour: 'modal',
                      show: () => true,
                      modal: {
                        title: 'Edit Item',
                        content: ({
                          data: { item, idx },
                          onClose,
                        }: {
                          onClose: () => void;
                          data: {
                            idx: number;
                            item: ItemType;
                          };
                        }) => {
                          const ItemForm = renderFormV2({
                            initialFormState: {
                              grade: item.grade || { id: '', name: '' },
                              quantity: item.quantity || 0,
                              rate: item.rate || 0,
                              uom: item.uom || '',
                              discountPercent: item.discountPercent || 0,
                              gst: item.gst || 0,
                              amount: item.amount || 0,
                            },
                            style: 'w-1/3',
                            fieldsData: [
                              {
                                property: 'grade',
                                type: 'searchable-select',
                                required: true,
                                searchOptions: {
                                  useSearch: useCombinedGrade,
                                  selectTarget: (form) => {
                                    return {
                                      id: form.id,
                                      name: form.name,
                                      item: {
                                        id: form.item.id,
                                        name: form.item.name,
                                      },
                                    };
                                  },
                                  onSearchValueSelect: (u: any) => {},
                                  // filter?: any;
                                  searchOptionsBody: {
                                    customBody: (data: Grade) => {
                                      return (
                                        <div>
                                          <div className='font-bold'>
                                            {data.name}
                                          </div>
                                          <div>{data.item?.name}</div>
                                        </div>
                                      );
                                    },
                                  },
                                },
                                validate: async (formData) => {
                                  if (
                                    (formData?.grade as any)?.item?.id &&
                                    !selectedVendor?.items?.find(
                                      (x) =>
                                        x.item.id ===
                                        (formData?.grade as any)?.item?.id
                                    )
                                  ) {
                                    return {
                                      grade:
                                        'Vendor does not support this item',
                                    };
                                  }

                                  return {
                                    grade: '',
                                  } as any;
                                },
                              },
                              {
                                property: 'quantity',
                                required: true,
                                type: 'number',
                              },
                              {
                                property: 'rate',
                                required: true,
                                type: 'number',
                              },
                              {
                                property: 'discountPercent',
                                type: 'number',
                              },
                            ],
                            mapTToU: (m) => m,
                            onSubmit: async (form) => {
                              const items = voucher.items;
                              if ((form.grade as any)?.item.id) {
                                const item = await itemService.getProperties(
                                  (form.grade as any)?.item.id
                                );
                                const gst = item?.find((x) => x.name === 'gst');
                                const uom = item?.find((x) => x.name === 'uom');
                                if (
                                  gst &&
                                  gst.value?.data &&
                                  gst.value?.data?.value
                                ) {
                                  form.gst = parseFloat(gst.value?.data?.value);
                                }
                                if (
                                  uom &&
                                  uom.value?.data &&
                                  uom.value?.data?.['uom-user']?.name
                                ) {
                                  form.uom =
                                    uom?.value?.data?.['uom-user']?.name;
                                }
                              }

                              const updatedItem = {
                                ...items[idx],
                                ...(form as any),
                              };
                              items[idx] = updatedItem;
                              setVoucher((v) => ({ ...v, ...{ items } }));
                              onClose();
                            },
                          });
                          return (
                            <div>
                              <ItemForm />
                            </div>
                          );
                        },
                      },
                    },
                    {
                      name: 'Edit Delivery Schedule',
                      show: () => true,
                      behaviour: 'modal',
                      modal: {
                        title: 'Edit Delivery Schedule',
                        content: ({
                          data: { item, idx },
                          onClose,
                        }: {
                          onClose: () => void;
                          data: {
                            idx: number;
                            item: ItemType;
                          };
                        }) => {
                          return (
                            <div>
                              <EditDeliverySchedule
                                item={item.grade?.name}
                                quantity={item.quantity}
                                deliverySchedule={item.deliverySchedule || []}
                                saveDeliverySchedule={async (sch) => {
                                  const newVoucher = { ...voucher };
                                  newVoucher.items[idx].deliverySchedule = sch;
                                  setVoucher(newVoucher);
                                }}
                              />
                            </div>
                          );
                        },
                      },
                    },
                  ]}
                />
              ),
            }}
          />
        ) : null}
        {po?.id ? (
          <div>
            <Card
              header={{
                title: 'GRN Vouchers',
                actions: [
                  {
                    type: 'button',
                    button: {
                      name: 'Add',
                      behaviour: 'click',
                      onClick: async () => {
                        const newVoucher = { ...voucher };
                        if (!newVoucher.grns) {
                          newVoucher.grns = [];
                        }
                        newVoucher.grns.push({} as GRNVoucher);
                        setVoucher(newVoucher);
                      },
                    },
                  },
                ],
              }}
              body={{
                type: 'jsx-component',
                body: (
                  <div>
                    <Table
                      header={[
                        [
                          { name: 'GRN Details' },
                          { name: 'Item Details' },
                          { name: 'Transport Details' },
                        ],
                      ]}
                      body={(voucher.grns || []).map((x, idx) => {
                        return {
                          rowData: {
                            grn: x,
                            idx: idx,
                          },
                          cells: [
                            {
                              value: (
                                <div>
                                  <div className='flex justify-between'>
                                    <KeyValue
                                      lhs='GRN #'
                                      rhs={x.details?.name}
                                    />
                                    <KeyValue
                                      lhs='Date'
                                      rhs={x.details?.date}
                                    />
                                    <KeyValue
                                      lhs='Invoice #'
                                      rhs={x.details?.invoiceNumber}
                                    />
                                  </div>
                                  <div className='flex justify-between'>
                                    <KeyValue
                                      lhs='Total Amount Payable'
                                      rhs={x.details?.totalAmountPayable}
                                    />
                                    <KeyValue
                                      lhs='Dispatch Through'
                                      rhs={x.details?.dispatchThrough}
                                    />
                                  </div>
                                </div>
                              ),
                            },
                            {
                              value: (
                                <div>
                                  {x.items?.map((y, yIdx) => (
                                    <div
                                      key={yIdx}
                                      className='my-0.5 border border-gray-100'
                                    >
                                      <div className='flex justify-between'>
                                        <KeyValue
                                          lhs='Item (Grade)'
                                          rhs={y.grade?.name}
                                        />
                                        <KeyValue
                                          lhs='Batch #'
                                          rhs={y.batchNumber}
                                        />
                                        <KeyValue
                                          lhs='Quantity'
                                          rhs={y.quantity}
                                        />
                                        <KeyValue lhs='Rate' rhs={y.rate} />
                                        <KeyValue lhs='Amount' rhs={y.amount} />
                                      </div>
                                      <div>
                                        <KeyValue lhs='Reason' rhs={y.reason} />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ),
                            },
                            {
                              value: (
                                <div>
                                  {x.transport?.map((y, yIdx) => (
                                    <div
                                      key={yIdx}
                                      className='my-0.5 border border-gray-100'
                                    >
                                      <div className='flex justify-between'>
                                        <KeyValue lhs='Date' rhs={y.date} />
                                        <KeyValue lhs='Name' rhs={y.name} />
                                        <KeyValue
                                          lhs='Document #'
                                          rhs={y.documentNumber}
                                        />
                                        <KeyValue lhs='Type' rhs={y.type} />
                                        <KeyValue lhs='Amount' rhs={y.amount} />
                                        <KeyValue
                                          lhs='Attachment'
                                          rhs={y.attachment}
                                        />
                                      </div>
                                      <div>
                                        <KeyValue
                                          lhs='Remarks'
                                          rhs={y.remarks}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ),
                            },
                          ],
                        };
                      })}
                      actions={[
                        {
                          name: 'Edit GRN Details',
                          behaviour: 'modal',
                          show: () => true,
                          modal: {
                            title: 'Edit GRN Details',
                            content: ({
                              data: { grn, idx },
                              onClose,
                            }: {
                              data: { grn: GRNVoucher; idx: number };
                              onClose: () => void;
                            }) => {
                              return (
                                <EditGRNDetails
                                  grn={grn}
                                  saveGRN={async (det) => {
                                    const newVoucher = { ...voucher };
                                    newVoucher.grns[idx].details = det;
                                    setVoucher(newVoucher);
                                    onClose();
                                  }}
                                />
                              );
                            },
                          },
                        },
                        {
                          name: 'Edit GRN Items',
                          behaviour: 'modal',
                          show: () => true,
                          modal: {
                            title: 'Edit GRN Items',
                            content: ({
                              data: { grn, idx },
                              onClose,
                            }: {
                              data: { grn: GRNVoucher; idx: number };
                              onClose: () => void;
                            }) => {
                              return (
                                <EditGRNItems
                                  poItems={voucher.items?.map((x) => {
                                    return {
                                      id: x.grade.id,
                                      name: x.grade.name,
                                    };
                                  })}
                                  grn={grn}
                                  saveGrnItems={async (det) => {
                                    const newVoucher = { ...voucher };
                                    newVoucher.grns[idx].items = det;
                                    setVoucher(newVoucher);
                                    onClose();
                                  }}
                                />
                              );
                            },
                          },
                        },
                        {
                          name: 'Edit GRN Transport',
                          behaviour: 'modal',
                          show: () => true,
                          modal: {
                            title: 'Edit GRN Transport',
                            content: ({
                              data: { grn, idx },
                              onClose,
                            }: {
                              data: { grn: GRNVoucher; idx: number };
                              onClose: () => void;
                            }) => {
                              return (
                                <EditGRNTransport
                                  grn={grn}
                                  saveGrnTransport={async (det) => {
                                    const newVoucher = { ...voucher };
                                    newVoucher.grns[idx].transport = det;
                                    setVoucher(newVoucher);
                                    onClose();
                                  }}
                                />
                              );
                            },
                          },
                        },
                      ]}
                    />
                  </div>
                ),
              }}
            />
          </div>
        ) : null}
        <LoadingButton
          text={'Save'}
          behaviorFn={async () => {
            await onSave({
              id: po?.id || '',
              dateTime: voucher.date,
              name: voucher.name,
              description: '',
              details: voucher,
              voucherType: 'purchase-order',
            } as VouchersType);
          }}
        />
      </div>
    );
  };
}

function getTax(
  gst: number,
  currentAddress: {
    state: string;
    country: string;
  },
  vendorAddress: {
    state: string;
    country: string;
  }
): Array<{ key: string; value: number }> {
  if (currentAddress.country !== vendorAddress.country) {
    return [{ key: 'N/A', value: 0 }];
  }

  if (currentAddress.state === vendorAddress.state) {
    return [
      { key: 'CGST', value: gst / 2 },
      { key: 'SGST', value: gst / 2 },
    ];
  }

  return [{ key: 'IGST', value: gst }];
}

function getAmount({
  quantity,
  rate,
  discount,
  gst,
  currentAddress,
  vendorAddress,
}: {
  quantity: number;
  rate: number;
  discount: number;
  gst: number;
  currentAddress: {
    state: string;
    country: string;
  };
  vendorAddress: {
    state: string;
    country: string;
  };
}) {
  const netAmount = getNetAmount({ quantity, rate, discount });
  const gstAmount = getGSTAmount({
    quantity,
    rate,
    discount,
    gst,
    currentAddress,
    vendorAddress,
  });

  return netAmount + gstAmount;
}

function getNetAmount({
  quantity,
  rate,
  discount,
}: {
  quantity: number;
  rate: number;
  discount: number;
}) {
  let amount = quantity * rate;
  if (amount) {
    amount -= (amount * discount) / 100;
  }

  return parseFloat(amount.toFixed(2));
}

function getGSTAmount({
  quantity,
  rate,
  discount,
  gst,
  currentAddress,
  vendorAddress,
}: {
  quantity: number;
  rate: number;
  discount: number;
  gst: number;
  currentAddress: {
    state: string;
    country: string;
  };
  vendorAddress: {
    state: string;
    country: string;
  };
}) {
  const totalTax = getTax(gst, currentAddress, vendorAddress).reduce(
    (prv, curr) => {
      if (curr.value) {
        return prv + curr.value;
      }
      return prv;
    },
    0
  );

  const netAmount = getNetAmount({ quantity, rate, discount });
  const amount = (netAmount * totalTax) / 100;

  return parseFloat(amount.toFixed(2));
}

function calculateLedgers({
  itemCategory,
  gsts,
  currentAddress,
  vendorAddress,
  transportationPaidBy,
  transportationCost,
}: {
  itemCategory: string;
  gsts: number[];
  currentAddress: {
    state: string;
    country: string;
  };
  vendorAddress: {
    state: string;
    country: string;
  };
  transportationPaidBy: 'seller' | 'buyer' | 'unknown';
  transportationCost: '' | 'part-of-invoice' | 'separate-invoice';
}) {
  const result: Array<string> = [];
  if (currentAddress.country !== vendorAddress.country) {
    if (itemCategory === 'Consumable Goods') {
      result.push('Purchase CG-Import');
      result.push('771-Pur-IGST-Import under Bonded Warehouse');
    }

    if (itemCategory === 'Finished Goods') {
    }

    if (itemCategory === 'RND Item') {
      result.push('RND Import A/C');
    }
    if (itemCategory === 'Unknown Item Type') {
    }
    if (itemCategory === 'Work in Progess') {
      result.push('Purchase CG-Import');
    }
    if (itemCategory === 'Intermediary goods') {
      result.push('Purchase CG-Import');
    }
    if (itemCategory === 'Raw Material') {
      result.push('Purchase RM Import A/C');
      result.push('771-Pur-IGST-Import under Bonded Warehouse');
    }
    if (itemCategory === 'Fixed Asset') {
      result.push('Purchase CG-Import');
    }
    if (itemCategory === 'Trading') {
      result.push('Sales International Trading');
    }
    if (itemCategory === 'Machine parts') {
      result.push('Purchase CG-Import');
      result.push('771-Pur-IGST-Import under Bonded Warehouse');
    }
    if (itemCategory === 'Fabrication') {
      result.push('Purchase CG-Import');
    }
  }

  if (currentAddress.country === vendorAddress.country) {
    if (itemCategory === 'Consumable Goods') {
      result.push('Purchase engg Consumables A/C');
    }
    if (itemCategory === 'Finished Goods') {
    }
    if (itemCategory === 'RND Item') {
      result.push('Purchase RND A/C');
    }
    if (itemCategory === 'Unknown Item Type') {
    }
    if (itemCategory === 'Work in Progess') {
      result.push('Purchase engg Consumables A/C');
    }
    if (itemCategory === 'Intermediary goods') {
      result.push('');
    }
    if (itemCategory === 'Raw Material') {
      result.push('Purchase RM Domestic A/C');
    }
    if (itemCategory === 'Fixed Asset') {
      result.push('Purchase engg Consumables A/C');
    }
    if (itemCategory === 'Trading') {
      result.push('Sales Trading A/C');
    }
    if (itemCategory === 'Machine parts') {
      result.push('Purchase engg Consumables A/C');
    }
    if (itemCategory === 'Fabrication') {
      result.push('Purchase engg Consumables A/C');
    }

    gsts.forEach((x) => {
      if (currentAddress.state !== vendorAddress.state) {
        result.push(`771 PUR-CGST-${x / 2}%`);
        result.push(`771 PUR-SGST-${x / 2}%`);
      } else {
        result.push(`771 PUR-IGST-${x}%`);
      }
    });
    result.push('P720 Round OFF');
  }

  if (transportationPaidBy === 'buyer') {
    if (transportationCost === 'part-of-invoice') {
      result.push('P105,Packing & Forwarding charged by supplier(GST)A/C');
    } else {
      result.push('Transportation Ledger');
    }
  }

  return result;
}
