import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';

type AddBomProps = { onSave: (data) => Promise<void> };

export function renderAddGrade(): (props: AddBomProps) => JSX.Element {
  return function AddGrade({ onSave }: AddBomProps): JSX.Element {
    const formProps: FormProps<{ name: string; gradeId: string }> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Grade Name',
        },
        {
          property: 'gradeId',
          type: 'input',
          required: true,
          label: 'Grade Id',
        },
      ],
      initialFormState: {
        name: '',
        gradeId: '',
      },
      onSubmit: onSave,
      mapTToU: (x) => {
        return x as any;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<{ name: string; gradeId: string }>(formProps);

    return <Form />;
  };
}
