import { TaskType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type TaskFormType = {
  name: string;
  cronExpression: string;
  status: 'live' | 'draft' | 'stopped';
  resource: string;
  url: string;
  forecastRange: number;
};

export function renderAddEditTaskForm() {
  return function AddEditTaskForm({
    task,
    onSave,
  }: {
    task?: TaskType;
    onSave: (data: TaskType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<TaskFormType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'resource',
          type: 'input',
          label: 'Resource',
          required: true,
        },
        {
          property: 'url',
          type: 'input',
          label: 'Url',
          required: true,
        },
        {
          property: 'cronExpression',
          type: 'input',
          label: 'Cron Expression',
          required: true,
        },
        {
          property: 'forecastRange',
          type: 'number',
          label: 'Forecast Range',
        },
        {
          property: 'status',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'live', value: 'live' },
            { text: 'stopped', value: 'stopped' },
            { text: 'draft', value: 'draft' },
          ],
        },
      ],
      initialFormState: {
        name: task?.name || '',
        resource: task?.jobDetails?.resource || '',
        url: task?.jobDetails?.url || '',
        cronExpression: task?.cronExpression || '',
        forecastRange: task?.forecastRange || 0,
        status: task?.status || 'draft',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          id: task?.id || '',
          name: l.name || task?.name,
          cronExpression: l.cronExpression || task?.cronExpression,
          forecastRange: parseInt(
            `${l.forecastRange || task?.forecastRange || '0'}`
          ),
          jobDetails: {
            resource: l.resource || task?.jobDetails?.resource,
            url: l.url || task?.jobDetails?.url || '',
          },
          status: l.status || task?.status || 'draft',
        } as TaskType);
      },
    };

    const Form = renderFormV2<Partial<TaskFormType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<TaskFormType>>): TaskFormType {
      return (b as unknown) as TaskFormType;
    }
  };
}
