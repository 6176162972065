import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedPurchaseEnquiry } from '../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiriy';
import { UseVirtualItemAllocate } from '../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UseCombinedPurchaseOrder } from '../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UseCombinedPurchaseRequisition } from '../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { UseCombinedVendor } from '../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedBom } from '../../../hooks/order/work-order/bom/use-bom';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { PurchaseEnquiryInterface } from '../../../models/interfaces/order/purchase/purchase-enquiry';
import { PurchaseOrderInterface } from '../../../models/interfaces/order/purchase/purchase-order';
import { VendorInterface } from '../../../models/interfaces/order/purchase/vendors';
import { BOMInterface } from '../../../models/interfaces/order/work-order/bom';
import { UserRendererInterface } from '../../common/fragments/user';
import { renderPurchaseOrderEnquiry } from './purchase-enquiry';
import { renderPurchaseOrder } from './purchase-order';
import { renderPurchaseOrderRequisition } from './purchase-requisition';

export type RenderPurchaseRequistionsProps = {
  useCombinedUser: UseCombinedUser;
  useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  useCombinedVendor: UseCombinedVendor;
  useCombinedBom: UseCombinedBom;
  useCombinedGrade: UseCombinedGrade;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useVirtualItemAllocate: UseVirtualItemAllocate;
  bomService: BOMInterface;
  useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
  useCombinedPurchaseEnquiry: UseCombinedPurchaseEnquiry;
  vendorService: VendorInterface;
  useUserAuthorization: UseUserAuthorization;
  useFileTransfer: UseFileTransfer;
  itemService: ItemInterface;
  useCombinedItem: UseCombinedItem;
  userRendererService: UserRendererInterface;
  purchaseEnquiryService: PurchaseEnquiryInterface;
  purchaseOrderService: PurchaseOrderInterface;
};

export function renderPurchaseRequisitions({
  useCombinedUser,
  useCombinedPurchaseRequisition,
  useCombinedVendor,
  useCombinedBom,
  useVirtualItemAllocate,
  bomService,
  useCombinedDeliveryTerm,
  useCombinedPurchaseOrder,
  useCombinedPurchaseEnquiry,
  vendorService,
  useUserAuthorization,
  useCombinedPaymentTerm,
  useFileTransfer,
  itemService,
  useCombinedItem,
  useCombinedGrade,
  userRendererService,
  purchaseEnquiryService,
  purchaseOrderService,
}: RenderPurchaseRequistionsProps): () => JSX.Element {
  return function PurchaseRequisitions(): JSX.Element {
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Purchase Requisitions',
    };

    const PRRegister = renderPurchaseOrderRequisition({
      useCombinedUser,
      useCombinedDeliveryTerm,
      useCombinedPurchaseRequisition,
      useCombinedVendor,
      useCombinedPurchaseEnquiry,
      useCombinedPaymentTerm,
      useCombinedPurchaseOrder,
      useCombinedBom,
      useUserAuthorization,
      vendorService,
      useFileTransfer,
      itemService,
      userRenderService: userRendererService,
    });

    const PERegister = renderPurchaseOrderEnquiry({
      purchaseOrderService,
      purchaseEnquiryService,
      useCombinedVendor,
      useCombinedBom,
      useCombinedPurchaseEnquiry,
      useCombinedUser,
      useFileTransfer,
      useCombinedItem,
      userRenderService: userRendererService,
    });

    const PORegister = renderPurchaseOrder({
      useCombinedGrade,
      useCombinedPurchaseOrder,
      useCombinedVendor,
      useCombinedUser,
      useCombinedItem,
      useFileTransfer,
      userRendererService,
    });

    // const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   ['Purchase Requisition - Register', PRRegister],
    //   ['Purchase Enquiry - Register', PERegister],
    //   ['Purchase Order - Register', PORegister],
    // ]);

    const newCategories: DataArray = [
      { name: 'purchase-requisition-register', Fn: PRRegister },
      { name: 'purchase-enquiry-register', Fn: PERegister },
      { name: 'purchase-order-register', Fn: PORegister },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
