import { LoadingButton } from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import {
  ArrowLeftIcon,
  ArrowRightOnRectangleIcon,
  ArrowUpIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useState } from 'react';
import Confetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { Bid, Player, Tournament } from './data';
import { PlayerCard } from './player-card';

export function renderPerformAuction() {
  return function PerformAunction({
    player,
    tournament,
    onClose,
    onSold,
    onUnsold,
  }: {
    player: Player;
    tournament: Tournament;
    onClose: () => void;
    onSold: ({ player, bid }: { player: Player; bid: Bid }) => void;
    onUnsold: ({ player }: { player: Player }) => void;
  }) {
    const { width, height } = useWindowSize();
    const [confetti, setConfetti] = useState<boolean>(false);

    const [bid, setBid] = useState<Bid>({
      value: 0,
      nextBid: player.basePrice || 0,
      topBidder: '',
      secondBidder: '',
      activities: [],
    });

    return (
      <>
        <div>
          <div className='flex justify-center bg-gray-800'>
            <PlayerCard
              size='large'
              teams={tournament.teams}
              player={player}
              selectPlayer={async ({ player }) => {
                console.log(player);
              }}
            />
          </div>
          <div className='bg-black text-white'>
            {bid ? (
              <div className='flex'>
                <div className='flex-auto'>
                  <div>
                    <div className='text-6xl text-center animate-pulse text-yellow-500'>
                      Current Bid: {bid.value}
                    </div>
                    <div className='flex justify-center items-center'>
                      {/* 
                      https://cruip.com/animated-gradient-borders-with-tailwind-css/
                      */}
                      <div className='bg-gray-700 [background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.yellow.500)_86%,_theme(colors.yellow.300)_90%,_theme(colors.yellow.500)_94%,_theme(colors.slate.600/.48))_border-box] rounded-2xl border border-transparent animate-border rounded-lg'>
                        {bid.topBidder ? (
                          <>
                            <img
                              alt=''
                              src={
                                tournament.teams.find(
                                  (y) => y.name === bid.topBidder
                                )?.logo || '/unknown-team.png'
                              }
                              className='h-48 mx-auto animate-hflip'
                            />
                            <div className='text-xl text-center'>
                              {bid.topBidder}
                            </div>
                          </>
                        ) : (
                          <ShieldExclamationIcon className='h-48' />
                        )}
                      </div>
                      <div className='text-4xl mx-32'>V/S</div>
                      <div className=''>
                        {bid.secondBidder ? (
                          <div className='flex'>
                            <div className='bg-gray-700 border border-gray-500 rounded-lg'>
                              <img
                                alt={
                                  tournament.teams.find(
                                    (y) => y.name === bid.secondBidder
                                  )?.name
                                }
                                src={
                                  tournament.teams.find(
                                    (y) => y.name === bid.secondBidder
                                  )?.logo || '/unknown-team.png'
                                }
                                className='h-48 mx-auto'
                              />
                              <div className='text-xl text-center'>
                                {bid.secondBidder}
                              </div>
                            </div>
                            <div className='flex flex-col justify-center w-64 ml-5'>
                              <div>
                                <div>Next Bid: {bid.nextBid}</div>
                                <LoadingButton
                                  defaultStyle='bg-gray-800 border border-yellow-500 p-1 my-1 rounded-md text-yellow-500'
                                  text={
                                    <>
                                      <ArrowUpIcon className='inline h-12 mr-2 animate-bounce text-yellow-500' />
                                      Raise
                                    </>
                                  }
                                  behaviorFn={async () => {
                                    setBid({
                                      value: bid.nextBid,
                                      nextBid: calculateNextBid(
                                        tournament,
                                        bid.nextBid,
                                        'normal'
                                      ),
                                      topBidder: bid.secondBidder,
                                      secondBidder: bid.topBidder,
                                      activities: [
                                        {
                                          by: bid.secondBidder,
                                          value: bid.nextBid,
                                          action: 'raise',
                                          time: moment.utc().toString(),
                                        },
                                        ...bid.activities,
                                      ],
                                    });
                                  }}
                                />
                              </div>
                              <div>
                                <LoadingButton
                                  defaultStyle='bg-gray-800 border border-red-500 p-1 my-1 rounded-md text-red-500'
                                  text={
                                    <>
                                      <ArrowRightOnRectangleIcon className='inline h-12 mr-2 text-red-500' />
                                      Exit
                                    </>
                                  }
                                  behaviorFn={async () => {
                                    setBid({
                                      value: bid.value,
                                      nextBid: bid.nextBid,
                                      topBidder: bid.topBidder,
                                      secondBidder: '',
                                      activities: [
                                        {
                                          by: bid.secondBidder,
                                          value: bid.value,
                                          action: 'exit',
                                          time: moment.utc().toString(),
                                        },
                                        ...bid.activities,
                                      ],
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <ShieldExclamationIcon className='h-48' />
                        )}
                      </div>
                    </div>
                    <div className='flex flex-wrap my-5'>
                      {tournament.teams.map((x, idx) => {
                        return (
                          <div
                            key={idx}
                            className='p-1 cursor-pointer hover:bg-gray-800'
                            onClick={() => {
                              if (
                                bid &&
                                // !bid.secondBidder &&
                                x.purse.remaining >= bid.nextBid &&
                                bid.topBidder !== x.name
                              ) {
                                setBid({
                                  value: bid.nextBid,
                                  nextBid: calculateNextBid(
                                    tournament,
                                    bid.nextBid,
                                    'normal'
                                  ),
                                  topBidder: x.name,
                                  secondBidder: bid.topBidder,
                                  activities: [
                                    {
                                      by: x.name,
                                      value: bid.nextBid,
                                      action: 'raise',
                                      time: moment.utc().toString(),
                                    },
                                    ...bid.activities,
                                  ],
                                });
                              }
                            }}
                          >
                            <img
                              alt=''
                              src={x.logo}
                              className='h-12 mx-auto hover:scale-125'
                            />
                            <div className='text-xl text-center text-yellow-500'>
                              {x.name}
                            </div>
                            <div className='text-xl text-center'>
                              Funds Available: {x.purse.remaining}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className='mt-8 mb-2'>
                      <div className='flex justify-center'>
                        {bid && bid.activities?.length ? (
                          <LoadingButton
                            defaultStyle='bg-gray-200 text-red-500 border rounded-md'
                            text={
                              <>
                                <ArrowLeftIcon className='w-8 inline mr-2 text-red-500' />
                                Revert
                              </>
                            }
                            behaviorFn={async () => {
                              if (bid.activities[0].action === 'raise') {
                                if (bid.activities.length > 1) {
                                  bid.topBidder = bid.activities[1].by;
                                  bid.secondBidder = '';
                                  bid.value = calculatePreviousBid(
                                    tournament,
                                    bid.value,
                                    'normal'
                                  );
                                  bid.activities.splice(0, 1);
                                } else {
                                  bid.topBidder = '';
                                  bid.secondBidder = '';
                                  bid.value = calculatePreviousBid(
                                    tournament,
                                    bid.value,
                                    'normal'
                                  );
                                  bid.activities = [];
                                }
                                setBid({ ...bid });
                              } else if (
                                bid.activities[0].action === 'bonanza-raise'
                              ) {
                                if (bid.activities.length > 1) {
                                  bid.topBidder = bid.activities[1].by;
                                  bid.secondBidder = '';
                                  bid.value = calculatePreviousBid(
                                    tournament,
                                    bid.value,
                                    'bonanza'
                                  );
                                  bid.activities.splice(0, 1);
                                } else {
                                  bid.topBidder = '';
                                  bid.secondBidder = '';
                                  bid.value = calculatePreviousBid(
                                    tournament,
                                    bid.value,
                                    'bonanza'
                                  );
                                  bid.activities = [];
                                }
                                setBid({ ...bid });
                              } else {
                                bid.activities?.splice(0, 1);
                                setBid({ ...bid });
                              }
                            }}
                          />
                        ) : null}
                        {bid && bid.topBidder && !bid.secondBidder ? (
                          <LoadingButton
                            defaultStyle='bg-green-500 text-white border rounded-md px-4'
                            text={
                              <>
                                <ShieldCheckIcon className='h-8 inline mr-2' />
                                Sold
                              </>
                            }
                            behaviorFn={async () => {
                              setConfetti(true);
                              await sleep(10000);
                              onSold({ player: player, bid: bid });
                              onClose();
                            }}
                          />
                        ) : null}
                        {bid && !bid.topBidder && !bid.secondBidder ? (
                          <LoadingButton
                            defaultStyle='bg-gray-200 text-red-500 border rounded-md'
                            text={
                              <>
                                <XCircleIcon className='w-8 inline mr-2 text-red-500' />
                                Unsold
                              </>
                            }
                            behaviorFn={async () => {
                              onUnsold({ player: player });
                              onClose();
                            }}
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className='flex-none h-96 overflow-y-auto'>
                    {/* {
                      bid.activities.map((x, bIdx) => <div className='my-1 p-1 border border-gray-700' key={bIdx}>
                        {
                          x.action === 'raise' ?
                            `${x.by} raises bid to ${x.value}` :
                            `${x.by} exits bidding`
                        }
                      </div>)
                    } */}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {confetti ? <Confetti width={width} height={height} /> : null}
      </>
    );
  };
}

function calculateNextBid(
  tournament: Tournament,
  currentBid: number,
  bidType: 'normal' | 'bonanza'
) {
  const range = tournament.auction?.range?.find(
    (x) => x.from <= currentBid && x.to > currentBid
  );
  if (range) {
    if (bidType === 'normal') {
      return currentBid + range.increment;
    }

    return currentBid + range.bonanza;
  }

  return currentBid;
}

function calculatePreviousBid(
  tournament: Tournament,
  currentBid: number,
  bidType: 'normal' | 'bonanza'
) {
  const range = tournament.auction?.range?.find(
    (x) => x.from <= currentBid && x.to > currentBid
  );
  if (range) {
    if (bidType === 'normal') {
      return currentBid - range.increment;
    }

    return currentBid - range.bonanza;
  }

  return currentBid;
}
