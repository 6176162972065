import { downloadFile, downloadPdfFile } from '@erp_core/erp-ui-components';
import axios from 'axios';
import { FILE_MANAGER_SERVER_URI } from '../../../../config/server-uri';

export const downloadSalarySlip = async ({
  companyGroupId,
  salaryParamId,
  salarySlipData,
  getSpecTemplate,
  companyId,
  monthYear,
  salaryId,
}: {
  companyGroupId: string;
  salaryParamId: string;
  salarySlipData: any;
  companyId: string;
  monthYear: string;
  getSpecTemplate: Function;
  salaryId: string;
}) => {
  const salaryUrl =
    salaryId && companyId
      ? `${companyGroupId.toUpperCase()}/${companyId.toUpperCase()}/employees/salaries/${salarySlipData.employeeProfile.id.toUpperCase()}/${monthYear}/${salaryId.toUpperCase()}`
      : '';
  // try downloading from s3 bucket
  if (salaryId && salaryUrl) {
    const pdf = await getSpecTemplate(salaryUrl, true);
    if (pdf) {
      downloadPdfFile({
        result: pdf,
        fileName: `${salarySlipData.employeeProfile.name}-${monthYear}.pdf`,
      });
      return;
    }
  }

  const html = await getSpecTemplate(
    `${companyGroupId}/salary-slip-templates/${salaryParamId.toLowerCase()}.html`
  );
  // const html = convertToHTML(JSON.parse(data));

  const axiosInstance = axios.create({
    baseURL: FILE_MANAGER_SERVER_URI,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const response = await axiosInstance.post('/pdf', {
    template: `<div style="padding: 20px;">${html}</div>`,
    data: salarySlipData,
    upload: `${salaryUrl}.pdf`,
  });
  const fType = 'application/pdf';
  const fName = 'abc.pdf';
  const res = response.data;
  console.log('res', res);

  downloadFile({ fileType: fType, fileName: fName, result: res });
};
