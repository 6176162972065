import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  LoadingButton,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LogRecordInterface } from '../../../../../models/interfaces/planning/log-record';
import { addPunch } from '../../util/add-manual-punch';

export function renderSearchPunches({
  logRecordService,
  setAttendance,
  getData,
  currentUser,
}: {
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  logRecordService: LogRecordInterface;
  currentUser: CurrentUserType;
}): (p: {
  data: { attendance: CombinedAttDataType };
  onClose: () => void;
}) => JSX.Element {
  const Table = renderTableComponent();
  return function SearchPunches({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);
    const [punches, setPunches] = useState<Array<any>>([]);
    const [existingPunches, setExistingPunches] = useState<Array<string>>([]);

    console.log(attendance);

    useEffect(() => {
      setLoading(true);
      logRecordService
        .searchPunches({
          employeeId: attendance.employeeProfile?.details?.employeeId,
          date: attendance.date,
        })
        .then((res) => {
          console.log(res);
          setPunches(res.finalPunches);
          setLoading(false);
        });

      const existingPunches: Array<string> = [];
      if (attendance.punchResult?.checkInList?.length) {
        attendance.punchResult?.checkInList?.forEach((c) => {
          c.punches?.forEach((p) => {
            existingPunches.push(p.timeStamp);
          });
        });
      }
      if (attendance.punchResult?.checkOutList?.length) {
        attendance.punchResult?.checkOutList?.forEach((c) => {
          c.punches?.forEach((p) => {
            existingPunches.push(p.timeStamp);
          });
        });
      }
      setExistingPunches(existingPunches);

      // eslint-disable-next-line
    }, []);
    return (
      <div>
        <div>Search Punches {loading ? 'loading...' : ''}</div>
        <Table
          header={[
            [
              { name: 'Incoming Date Time' },
              { name: 'Already Present Date Time' },
              { name: 'Import' },
            ],
          ]}
          body={punches.map((p) => {
            let existingPunch = '';
            if (existingPunches.includes(p.utcDate)) {
              existingPunch = moment
                .utc(p.utcDate)
                .tz('Asia/Kolkata')
                .format('YYYY-MM-DD HH:mm:ss');
            }
            return {
              cells: [
                {
                  value: moment
                    .utc(p.utcDate)
                    .tz('Asia/Kolkata')
                    .format('YYYY-MM-DD HH:mm:ss'),
                },
                { value: existingPunch },
                {
                  value: (
                    <>
                      {' '}
                      {moment.utc(p.utcDate).format('YYYY-MM-DD') ===
                        attendance.date &&
                      !existingPunches.includes(p.utcDate) ? (
                        <LoadingButton
                          text={'Import'}
                          behaviorFn={async () => {
                            await addPunch({
                              attendance: attendance,
                              type: 'auto',
                              currentUser: currentUser,
                              setAttendance,
                              date: attendance.date,
                              getData,
                              onClose,
                              mp: {
                                date: p.utcDate,
                                type: 'not-determined',
                                createdBy: {
                                  id: currentUser.id,
                                  name: currentUser.name,
                                },
                                employee: attendance.employee,
                                reason: 'Imported from ui',
                                location: {
                                  id: '',
                                  name: '',
                                },
                                device: {
                                  id: '',
                                  name: '',
                                  deviceId: punches.find(
                                    (x) => x.utcDate === p.utcDate
                                  )?.ip,
                                },
                              } as any,
                            });
                            onClose();
                          }}
                          behaviourParams={{}}
                        />
                      ) : null}
                    </>
                  ),
                },
              ],
            };
          })}
        />

        <div></div>
      </div>
    );
  };
}
