import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type MisconductIssueCriticalChargeSheetFormType = {
  date: string;
  details: string;
};

export const issueCriticalChargeSheetFormComponent = () => {
  return function issueCriticalChargeSheetForm({
    data,
    onSave,
  }: {
    data?: MisconductType;
    onSave: (data: MisconductType) => Promise<void>;
  }) {
    const formProps: FormProps<MisconductIssueCriticalChargeSheetFormType> = {
      fieldsData: [
        { property: 'date', type: 'date', label: 'Date', required: true },
        {
          property: 'details',
          type: 'input',
          label: 'Details',
          required: true,
        },
      ],
      initialFormState: {
        date: data?.details?.issueCriticalChargeSheet?.date || '',
        details: data?.details?.issueCriticalChargeSheet?.details || '',
      },
      onSubmit: async (f) => {
        await onSave(({
          id: data?.id,
          details: {
            issueCriticalShowCause: {
              date: f.date || data?.details?.issueCriticalChargeSheet?.date,
              details:
                f.details || data?.details?.issueCriticalChargeSheet?.details,
            },
          },
        } as unknown) as MisconductType);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<MisconductIssueCriticalChargeSheetFormType>(
      formProps
    );

    return <Form />;

    function mapPFDToP(
      b: FormDataType<MisconductIssueCriticalChargeSheetFormType>
    ): MisconductIssueCriticalChargeSheetFormType {
      return (b as unknown) as MisconductIssueCriticalChargeSheetFormType;
    }
  };
};
