import { Client } from '@erp_core/erp-types/dist/modules/order';
import { GeneralAddressType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  KeyValue,
  LoadingButton,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { SOVoucher } from './sales-order';

export function EditSOShipToDetails({
  so,
  currentLocation,
  jobWorkClients,
  savePo,
}: {
  so: SOVoucher;
  currentLocation: {
    name: string;
    address: GeneralAddressType;
    contact: string;
  };
  jobWorkClients: Array<Client>;
  savePo: (po: SOVoucher) => Promise<void>;
}) {
  const [salesOrder, setPurchaseOrder] = useState<SOVoucher>(so);
  const Card = renderCardComponent();
  console.log('currentLocation', currentLocation);
  return (
    <>
      <select
        value={so.buyer?.delivery.type}
        onChange={(evt) => {
          if (evt.target.value === 'self') {
            const newSo: SOVoucher = { ...salesOrder };
            if (!newSo.buyer) {
              newSo.buyer = {
                delivery: {
                  type: 'self',
                  name: currentLocation.name,
                  address: currentLocation.address,
                  contact: currentLocation.contact,
                  gst: '',
                },
                billing: {} as any,
              };
            } else {
              newSo.buyer.delivery = {
                type: 'self',
                name: currentLocation.name,
                address: currentLocation.address,
                contact: currentLocation.contact,
                gst: '',
              };
            }

            setPurchaseOrder(newSo);
          }
        }}
      >
        <option value={''}>Select</option>
        <option value='self'>Self</option>
      </select>

      <Card
        header={{ title: 'Ship To' }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {salesOrder.buyer?.delivery?.name ? (
                <div>
                  <div className='font-bold'>
                    {salesOrder.buyer?.delivery?.name}
                  </div>
                  <KeyValue
                    lhs='Address'
                    rhs={[
                      `${
                        salesOrder.buyer?.delivery?.address?.addressLine1 || ''
                      }, ${
                        salesOrder.buyer?.delivery?.address?.addressLine2 || ''
                      }`,
                      `${salesOrder.buyer?.delivery?.address?.city || ''} - ${
                        salesOrder.buyer?.delivery?.address?.pincode || ''
                      }, ${
                        salesOrder.buyer?.delivery?.address?.district || ''
                      }`,
                      `${salesOrder.buyer?.delivery?.address?.state || ''}, ${
                        salesOrder.buyer?.delivery?.address?.country || ''
                      }`,
                    ]}
                  />
                  <KeyValue
                    lhs='Contact'
                    rhs={salesOrder.buyer?.delivery?.contact || ''}
                  />
                  <KeyValue
                    lhs='GST'
                    rhs={salesOrder.buyer?.delivery?.gst || '-'}
                  />
                </div>
              ) : null}
            </>
          ),
        }}
      />

      <LoadingButton
        text='Save'
        behaviorFn={async () => {
          savePo(salesOrder);
        }}
      />
    </>
  );
}
