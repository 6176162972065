import {
  OdourFilterType,
  OdourType,
} from '@erp_core/erp-types/dist/modules/constants';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpOdourRepo extends GenericHttpWithLogsRepo<
  OdourType,
  OdourFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/constants/odour',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
