import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { PurchaseOrderInterface } from '../../../../models/interfaces/order/purchase/purchase-order';

export type VirtuallyAllocateData = {
  id: string;
  referenceId: string;
  quantity: number;
  referenceType: 'bom' | 'sales-enquiry' | 'sales-order';
  currentUser: CurrentUserType;
  allocationComplete: boolean; // Send this true when no further allocation should be made for the targeted reference type.
};

export type UseVirtualItemAllocate = () => {
  // eslint-disable-next-line no-unused-vars
  set(data: VirtuallyAllocateData): Promise<boolean>;
};

type CreateUseVirtualItemAllocate = {
  purchaseOrderService: PurchaseOrderInterface;
};

export function createUseVirtualItemAllocate({
  purchaseOrderService,
}: CreateUseVirtualItemAllocate): UseVirtualItemAllocate {
  return () => {
    const set = async (data: VirtuallyAllocateData) => {
      return purchaseOrderService.allocateItem(data);
    };

    return {
      set,
    };
  };
}
