import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  Godown,
  GodownDetails,
} from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  RotationalShiftDayFilter,
  RotationalShiftDayType,
} from '@erp_core/erp-types/dist/types/modules/planning/attendance/rotational-shift-day';
import { OpenConfirm } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { SaveWorkPlaceSchedule } from './save-workplace-schedule';
import { ShiftBuilderV2, WASch } from './shift-generator-v2';

type Props = {
  godown: Godown;
  setGodown: (data: Godown, options?: any) => Promise<Godown>;
  openConfirm: OpenConfirm;
  getShifts: (
    filter?: RotationalShiftDayFilter | undefined
  ) => Promise<RotationalShiftDayType[]>;
  getEmployee: (id: string, o?: any) => Promise<EmployeeProfileType>;
  currentLocation: LocationType;
  setRotationalShiftDay: (
    s: RotationalShiftDayType,
    o?: any
  ) => Promise<RotationalShiftDayType>;
};

export const renderShiftBuilderTable = () => {
  return function ShiftBuilderTable({
    openConfirm,
    godown,
    getShifts,
    getEmployee,
    currentLocation,
    setGodown,
    setRotationalShiftDay,
  }: Props): JSX.Element {
    const [scheduleData, setScheduleData] = useState<{
      employees: { roleName: string; count: number }[];
      relievers: { roleName: string; count: number }[];
      schedule: WASch;
    }>({
      employees: godown.details.scheduleConfig?.employees || [],
      relievers: godown.details.scheduleConfig?.relievers || [],
      schedule: (godown.details.scheduleConfig as any)?.schedule || [],
    });

    const godownDetails: GodownDetails = godown.details;

    return (
      <ShiftBuilderV2
        name={godown.name}
        godown={godown}
        setGodown={setGodown}
        getShifts={getShifts}
        getEmployee={getEmployee}
        setRotationalShiftDay={setRotationalShiftDay}
        currentLocation={currentLocation}
        employees={[
          ...((godown.type === 'supervisor-work-area' &&
            (godownDetails.supervisors?.map((s) => {
              return {
                name: s.name,
                roleName: 'Supervisor',
                id: s.id,
              };
            }) as any)) ||
            []),

          ..._.map(scheduleData.employees, (e) =>
            _.map(_.times(e.count), (x) => {
              return {
                name: `E${x}-${e.roleName}`,
                roleName: e.roleName,
                id: '',
              };
            })
          ).flat(),

          ..._.map(scheduleData.relievers, (e) =>
            _.map(_.times(e.count), (x) => {
              return {
                name: `R${x}-${e.roleName}`,
                roleName: e.roleName,
                id: '',
              };
            })
          ).flat(),
        ]}
        schedule={scheduleData.schedule}
        OnSave={({ data, onClose }) => {
          return <SaveWorkPlaceSchedule data={data} onClose={onClose} />;
        }}
        onSaveGrid={(result) => {
          openConfirm({
            title: 'Are you sure you want to save schedule?',
            onConfirm: async () => {
              const dataToSave = JSON.stringify({
                employees: scheduleData.employees || [],
                relievers: scheduleData.relievers || [],
                schedule: result,
              });
              setScheduleData(JSON.parse(dataToSave));

              const schConfig = godownDetails.scheduleConfig || {
                type: 'system',
              };
              (schConfig as any).schedule = result;

              const finalData: Partial<Godown> = {
                id: godown.id,
                details: {
                  scheduleConfig: schConfig,
                } as GodownDetails,
              };
              await setGodown((finalData as unknown) as Godown);
            },
          });
        }}
        shifts={godownDetails.shifts || []}
      />
    );
  };
};
