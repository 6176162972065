import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import {
  AnswerType,
  QuestionType,
} from 'models/types/questionnairetopic/question';
import { customQuestionRenderer } from '../../store';
import { DependentQuestion } from '../component/dependent-question';
import { MultichoiceAnswers } from '../component/multichoice-ans';

type AddQuestionFormProps = {
  defaultData?: QuestionType;
  questionList?: Array<QuestionType>;
  onClose: () => void;
};
type QuestionsForm = {
  id?: string;
  orderNo: number;
  question: string;
  answerType: AnswerType;
  isDependentOn: string;
  name: string;
  hasIdealAnswer: string;
  isDynamic: string;
  idealAnswer: string;
  hasSubQuestions: string;
  customQuestionRenderer: string;
  mapperProperty: string;
};
// eslint-disable-next-line
export function renderAddQuestionsForm({
  companyGroupId,
  onSave,
}: {
  companyGroupId: string;
  onSave: (q: QuestionType) => Promise<void>;
}): (d: AddQuestionFormProps) => JSX.Element {
  return function ({
    defaultData,
    questionList,
    onClose,
  }: AddQuestionFormProps): JSX.Element {
    const validateForm = async (form) => {
      if (
        ['binary', 'binaryWithText'].includes(form.answerType) &&
        form.hasIdealAnswer === 'yes'
      ) {
        if (form.idealAnswer === 'yes' || form.idealAnswer === 'no') {
          return {};
        } else {
          return { idealAnswer: 'Ideal answer should be Yes or No' } as any;
        }
      }
      return {};
    };

    const formProps: FormProps<Partial<QuestionsForm>> = {
      dynamicContent: {
        bottom: async (formData) => {
          return (
            <>
              {formData.id &&
              ['multichoice', 'multiselect'].includes(
                (formData?.answerType || '') as any
              ) ? (
                <MultichoiceAnswers
                  id={(formData.id || '') as any}
                  answers={defaultData?.details.answers || []}
                  saveQuestion={onSave}
                  hasIdealAnswer={defaultData?.details.hasIdealAnswer || ''}
                  idealAnswer={defaultData?.details.idealAnswer || ''}
                />
              ) : null}
              {formData.id &&
              defaultData &&
              (formData.isDependentOn === 'yes' ||
                defaultData?.details.isdependentOn === 'yes') ? (
                <DependentQuestion
                  id={(formData?.id || '') as any}
                  question={defaultData}
                  currentQuestion={defaultData.name}
                  orderNo={defaultData?.orderNo}
                  questionList={questionList || []}
                  dependentQuestionsAnswers={
                    defaultData?.details.dependentQuestionsAnswer || ''
                  }
                  dependentQuestionId={
                    defaultData?.details.dependentQuestionId || ''
                  }
                  saveQuestion={onSave}
                />
              ) : null}
            </>
          );
        },
      },
      fieldsData: [
        {
          property: 'orderNo',
          type: 'number',
          label: 'Order No',
        },
        {
          property: 'name',
          type: 'input',
          label: 'Question',
        },
        {
          property: 'answerType',
          type: 'select',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'text', value: 'text' },
            { text: 'binary', value: 'binary' },
            { text: 'multichoice', value: 'multichoice' },
            { text: 'multiselect', value: 'multiselect' },
            { text: 'binaryWithText', value: 'binaryWithText' },
          ],
          label: 'Answer Type',
          // readonly: defaultData?.id ? true : false,
        },
        {
          property: 'isDependentOn',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          label: 'Is Dependent on',
        },
        {
          property: 'hasIdealAnswer',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          label: 'Has ideal answer?',
        },
        {
          property: 'idealAnswer',
          type: 'input',
          label: 'Ideal Answer',
          dependentOn: (formData) => {
            return formData?.hasIdealAnswer === 'yes';
          },
          validate: validateForm,
        },
        {
          property: 'isDynamic',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          label: 'Is Dynamic?',
        },
        {
          property: 'hasSubQuestions',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
          label: 'Has SubQuestions?',
        },
        {
          property: 'customQuestionRenderer',
          type: 'select',
          label: 'Custom Question Renderer',
          options: [
            { text: 'Select', value: '' },
            ...(customQuestionRenderer[companyGroupId] || []).map((x) => ({
              text: x.functionName,
              value: x.functionName,
            })),
          ],
        },
        {
          property: 'mapperProperty',
          type: 'input',
          label: 'Mapper Property',
        },
      ],
      initialFormState: {
        id: defaultData?.id || '',
        name: defaultData?.name || '',
        orderNo: defaultData?.orderNo || '',
        answerType: defaultData?.answerType || '',
        isDependentOn: defaultData?.details.isdependentOn || '',
        hasIdealAnswer: defaultData?.details.hasIdealAnswer || '',
        idealAnswer: defaultData?.details.idealAnswer,
        isDynamic: defaultData?.details.isDynamic || 'no',
        hasSubQuestions: defaultData?.details?.hasSubQuestions || 'no',
        customQuestionRenderer:
          defaultData?.details?.customQuestionRenderer || '',
        mapperProperty: defaultData?.details?.mapperProperty || '',
      },
      // allowUnchanged: !defaultData,
      onSubmit: async (f) => {
        await onSave({
          id: defaultData?.id,
          details: {
            isdependentOn: (f.isDependentOn ||
              defaultData?.details?.isdependentOn) as any,
            hasIdealAnswer: (f.hasIdealAnswer ||
              defaultData?.details?.hasIdealAnswer) as any,
            idealAnswer: (f.idealAnswer ||
              defaultData?.details?.idealAnswer) as any,
            isDynamic: (f.isDynamic || defaultData?.details?.isDynamic) as any,
            hasSubQuestions:
              f.hasSubQuestions || defaultData?.details?.hasSubQuestions,
            customQuestionRenderer:
              f.customQuestionRenderer ||
              defaultData?.details?.customQuestionRenderer,
            mapperProperty:
              f.mapperProperty || defaultData?.details?.mapperProperty,
          },
          name: f.name || defaultData?.name,
          orderNo: f.orderNo || defaultData?.orderNo,
          answerType: f.answerType || defaultData?.answerType,
        } as QuestionType);
        onClose();
      },

      mapTToU: mapFFDToF,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<QuestionsForm>>(formProps);

    return <Form />;

    function mapFFDToF(b: FormDataType<Partial<QuestionsForm>>): QuestionsForm {
      return (b as unknown) as QuestionsForm;
    }
  };
}
