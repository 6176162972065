import { RoleType } from '@erp_core/erp-types/dist/modules/admin';

export function getNavigation(
  currentUserRoles: RoleType[]
): Array<{ name: string; path: string; access: boolean }> {
  const isSystemAdmin = currentUserRoles?.find((r) => r.name === 'System-Admin')
    ? true
    : false;
  const navigation = [
    {
      name: 'System',
      path: '/system',
      access: isSystemAdmin,
    },
    {
      name: 'Inventory',
      path: '/inventory',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Inventory')
        )
          ? true
          : false,
    },
    {
      name: 'Purchase',
      path: '/purchase',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Purchase')
        )
          ? true
          : false,
    },
    {
      name: 'Sales',
      path: '/sales',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Sales')
        )
          ? true
          : false,
    },
    {
      name: 'QA',
      path: '/quality-assurance',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.shortName === 'QA')
        )
          ? true
          : false,
    },
    {
      name: 'Production',
      path: '/production',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Production')
        )
          ? true
          : false,
    },
    {
      name: 'HRD',
      path: '/hrd',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'HRD')
        )
          ? true
          : false,
    },
    {
      name: 'Accounts',
      path: '/accounts',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Accounts')
        )
          ? true
          : false,
    },
    {
      name: 'Miscellaneous',
      path: '/miscellaneous',
      access:
        isSystemAdmin ||
        currentUserRoles?.find((r) =>
          r.authorizations.find((x) => x.module.name === 'Miscellaneous')
        )
          ? true
          : false,
    },
  ];

  return navigation;
}
