import { useNavigate, useParams } from 'react-router-dom';
import { UseCombinedQuestionnaireQuestion } from '../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedQuestionnaireSurveyAnswer } from '../../../../hooks/hrd/questionnaire/questionnaire-survey-ans/use-questionnaire-survey-answer';
import { SurveyAnswersType } from '../../../../models/types/questionnairetopic/survey-answers';
import { renderSurveyAnswerForm, SurveyAnswerType } from './form';

export function renderSurveyAnswersPage({
  useCombinedQuestionnaireQuestion,
  useCombinedQuestionnaireSurveyAnswer,
}: {
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedQuestionnaireSurveyAnswer: UseCombinedQuestionnaireSurveyAnswer;
}): () => JSX.Element {
  const SurveyAnswersForm = renderSurveyAnswerForm({
    useCombinedQuestionnaireQuestion,
  });
  return function SurveyAnswersPage(): JSX.Element {
    const { id, date } = useParams();
    const navigate = useNavigate();
    const {
      syncSet: saveSurveyAnswer,
    } = useCombinedQuestionnaireSurveyAnswer();

    async function onSave(req: {
      topic: { id: string };
      details: { surveyAns: SurveyAnswerType };
    }) {
      const data = req;
      if (date) {
        (data.details as any).date = date;
      }
      await saveSurveyAnswer(data as SurveyAnswersType);
      navigate('/miscellaneous/questionnaire/survey-successful');
    }

    if (id) {
      return <SurveyAnswersForm id={id} onSave={onSave} date={date} />;
    }

    return <div></div>;
  };
}
