/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../../contexts/user';
import { UseCombinedUser } from '../user-admin/use-users-admin';

export type UseCurrentUserRoles = () => {
  data: RoleType[] | undefined;
};

export interface UseCurrentUserRolesProps {
  useCombinedUser: UseCombinedUser;
}

export function createUseCurrentUserRoles({
  useCombinedUser,
}: UseCurrentUserRolesProps): UseCurrentUserRoles {
  return () => {
    const [currentUserRoles, setCurrentUserRoles] = useState<RoleType[]>();

    const { user: currentUser } = useContext(UserContext);

    const { getSync: getUserDetails } = useCombinedUser();

    const getUserRoles = async () => {
      if (currentUser.id) {
        const userDetails = await getUserDetails(currentUser.id);
        setCurrentUserRoles(userDetails.roles);
      }
    };

    useEffect(() => {
      if (!currentUser) return;
      getUserRoles();
    }, [currentUser]);

    return {
      data: currentUserRoles,
    };
  };
}
