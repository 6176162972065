import { SalaryBonus } from '@erp_core/erp-types/dist/types/modules/hrd/salary-bonus';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type SalaryBonusActionFormType = {
  reason: string;
  bonusType: string;
  amount: number;
  status: string;
};

export const SalaryBonusApprovalFormComponent = () => {
  return function SalaryBonusApprovalForm({
    data,
    title,
    onSave,
  }: {
    data?: SalaryBonus;
    title: string;
    onSave: (data: SalaryBonus) => Promise<void>;
  }) {
    console.log('title', title);
    const formProps: FormProps<SalaryBonusActionFormType> = {
      fieldsData: [
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
        {
          property: 'bonusType',
          type: 'select',
          options: [
            {
              text: data ? data?.bonusType : 'Select',
              value: data ? data?.bonusType : '',
            },
            { text: 'perentage-base', value: 'perentage-base' },
            { text: 'fixed', value: 'fixed' },
          ],
          required: true,
        },
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
          required: true,
        },
        {
          property: 'status',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'approved', value: 'approved' },
            { text: 'rejected', value: 'rejected' },
          ],
          dependentOn: (formData) => {
            return title === 'approval';
          },
        },
      ],
      initialFormState: {
        reason: data?.reason || '',
        amount: data?.amount || '',
        bonusType: data?.bonusType || '',
        status: data?.status || '',
      },
      onSubmit: async (f) => {
        const negotiation = data?.details?.negotiation || [];
        if (data) {
          negotiation.push({
            bonusType: data?.bonusType,
            amount: data?.amount,
            reason: data?.reason,
            proposedBy: {
              id: data?.requstedBy.id || '',
              name: data?.requstedBy.name || '',
            },
            dateTime: data?.createdAt || '',
          });
        }

        await onSave(({
          id: data?.id,
          amount: f.amount,
          bonusType: f.bonusType,
          status: f.status || data?.status,
          reason: f.reason,
          details: {
            negotiation: negotiation,
          },
        } as unknown) as SalaryBonus);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      button: {
        style: 'float-left',
      },
    };

    const Form = renderFormV2<SalaryBonusActionFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<SalaryBonusActionFormType>
    ): SalaryBonusActionFormType {
      return (b as unknown) as SalaryBonusActionFormType;
    }
  };
};
