import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  DateSelector,
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';
import { AttendanceFilter } from '../../../../models/interfaces/hrd/attendance';
import { calculateShiftDuration } from '../../attendance/util/calculate-shift-duration';

type Report = {
  name: string;
  employeeId: string;
  days: string;
  totalFinalizedDays: string;
  presentDays: string;
  absentDays: string;
  payableDaysRatio: string;
  totalPaidLeaves: string;
  totalUnpaidLeaves: string;
  lateMarkPenaltyRatio: string;
  absentDaysPenalty: string;
  scheduledWeekOffs: string;
  lateMarkPenaltyDays: string;
  actualCheckDays: string;
  checkDays: string;
  eligibleWeekOffs: string;
  totalPayableDays: string;
  otSalary: string;
  otCompoff: string;
  remark: string;
};

export function EstimatePayableDays({
  filteredEmployees,
  date,
  getAttendanceSync,
  currentCompany,
}: {
  currentCompany: Company;
  filteredEmployees: EmployeeProfileType[];
  date;
  getAttendanceSync: (
    filter?: AttendanceFilter,
    o?: any
  ) => Promise<AttendanceType[]>;
}): JSX.Element {
  const [until, setUntil] = useState<string>(moment().format('YYYY-MM-DD'));
  const report: XlsxDownloadMapper = [
    { columnName: 'name', dataBinding: { property: 'name' } },
    {
      columnName: 'employeeId',
      dataBinding: { property: 'employeeId' },
    },
    {
      columnName: 'days',
      dataBinding: { property: 'days' },
    },
    {
      columnName: 'totalFinalizedDays',
      dataBinding: { property: 'totalFinalizedDays' },
    },
    {
      columnName: 'presentDays',
      dataBinding: { property: 'presentDays' },
    },
    {
      columnName: 'absentDays',
      dataBinding: { property: 'absentDays' },
    },
    {
      columnName: 'payableDaysRatio',
      dataBinding: { property: 'payableDaysRatio' },
    },
    {
      columnName: 'totalPaidLeaves',
      dataBinding: { property: 'totalPaidLeaves' },
    },
    {
      columnName: 'totalUnpaidLeaves',
      dataBinding: { property: 'totalUnpaidLeaves' },
    },
    {
      columnName: 'scheduledWeekOffs',
      dataBinding: { property: 'scheduledWeekOffs' },
    },
    {
      columnName: 'eligibleWeekOffs',
      dataBinding: { property: 'eligibleWeekOffs' },
    },
    {
      columnName: 'absentDaysPenalty',
      dataBinding: { property: 'absentDaysPenalty' },
    },
    {
      columnName: 'lateMarkPenaltyDays',
      dataBinding: { property: 'lateMarkPenaltyDays' },
    },
    {
      columnName: 'lateMarkPenaltyRatio',
      dataBinding: { property: 'lateMarkPenaltyRatio' },
    },
    {
      columnName: 'otSalary',
      dataBinding: { property: 'otSalary' },
    },
    {
      columnName: 'otCompoff',
      dataBinding: { property: 'otCompoff' },
    },
    {
      columnName: 'totalPayableDays',
      dataBinding: { property: 'totalPayableDays' },
    },
    {
      columnName: 'actualCheckDays',
      dataBinding: { property: 'actualCheckDays' },
    },
    {
      columnName: 'checkDays',
      dataBinding: { property: 'checkDays' },
    },
    {
      columnName: 'remark',
      dataBinding: { property: 'remark' },
    },
  ];

  async function downloadReport() {
    const resultEmployees: Report[] = [];

    let actualDays = 0;

    const monthDetails = {
      month: parseInt(moment(date, 'YYYY-MM-DD').format('MM')),
      year: moment(date, 'YYYY-MM-DD').year(),
      monthName: moment(date, 'YYYY-MM-DD').format('MMM'),
      totalDays: moment(date, 'YYYY-MM-DD').daysInMonth(),
      totalOfficialDays: moment(date, 'YYYY-MM-DD').daysInMonth(),
    };

    actualDays = monthDetails.totalOfficialDays;

    if (
      moment().format('MM-YYYY') ===
      moment(date, 'YYYY-MM-DD').format('MM-YYYY')
    ) {
      monthDetails.totalDays = parseInt(moment(until).format('DD'));
      monthDetails.totalOfficialDays = parseInt(moment(until).format('DD'));
    }

    actualDays = monthDetails.totalOfficialDays;

    const month =
      monthDetails.month > 9
        ? monthDetails.month.toString()
        : `0${monthDetails.month}`;
    const year = monthDetails.year.toString();

    for (const employee of filteredEmployees) {
      try {
        const empMonthDetails = { ...monthDetails };
        const joiningDate = employee.details?.workTimeLines?.find(
          (x) => x.activity === 'joined'
        )?.date;
        const joiningIsSameAsCurrentMonth =
          moment(joiningDate).format('YYYY-MM') ===
          moment(date).format('YYYY-MM');
        const resignedDate = employee.details?.workTimeLines?.find(
          (x) => x.activity === 'resigned'
        )?.date;
        const resignedIsSameAsCurrentMonth =
          moment(resignedDate).format('YYYY-MM') ===
          moment(date).format('YYYY-MM');

        if (joiningIsSameAsCurrentMonth) {
          empMonthDetails.totalOfficialDays =
            empMonthDetails.totalOfficialDays -
            parseInt(moment(joiningDate).format('DD')) +
            1;

          actualDays = empMonthDetails.totalOfficialDays;
        }

        if (resignedIsSameAsCurrentMonth) {
          empMonthDetails.totalOfficialDays =
            empMonthDetails.totalOfficialDays -
            (moment(date, 'YYYY-MM-DD').daysInMonth() -
              parseInt(moment(resignedDate).format('DD')));

          actualDays = empMonthDetails.totalOfficialDays;
        }

        let attendanceData = await getAttendanceSync({
          employeeId: `equal::${employee.id}`,
          from: `more-than::${year}-${month}-00`,
          to: `less-than::${year}-${month}-32`,
        });

        attendanceData = attendanceData.filter((x) =>
          moment(x.date).isSameOrBefore(moment(until))
        );

        const payableDaysRatio = parseFloat(
          attendanceData
            .reduce((x, curr) => {
              const shiftHours =
                curr.details?.summary &&
                curr.details.summary.hasOwnProperty('shiftDuration')
                  ? curr.details?.summary?.shiftDuration
                  : calculateShiftDuration({ employee: employee });

              const payableDayRatio = parseFloat(
                (
                  (curr.details?.summary?.payableWorkHours || 0) / shiftHours
                ).toFixed(2)
              );

              if (payableDayRatio) {
                return x + payableDayRatio;
              }

              return x;
            }, 0)
            .toFixed(2)
        );

        const totalPaidLeaves = attendanceData.reduce((x, curr) => {
          let count = 0;

          if (curr.finalized && curr.details?.summary?.leaves) {
            curr.details?.summary.leaves.forEach((lv, idx) => {
              if (idx < 2) {
                const types = [
                  'casual-leave',
                  'compensatory-off',
                  'maternity-leave',
                  'on-job-accident-leave',
                  'privilege-leave',
                  'sick-leave',
                  'layoff-leave',
                ];
                if (types.includes(lv.type)) {
                  count += 0.5;
                }
              }
            });
          }

          return x + count;
        }, 0);

        const totalUnpaidLeaves = attendanceData.reduce((x, curr) => {
          let count = 0;

          if (curr.finalized && curr.details?.summary?.leaves) {
            curr.details?.summary.leaves.forEach((lv, idx) => {
              if (idx < 2) {
                if (lv.type === 'unpaid-leave') {
                  count += 0.5;
                }
              }
            });
          }

          return x + count;
        }, 0);

        const compOffs = attendanceData.reduce((x, curr) => {
          let count = 0;

          if (curr.finalized && curr.details?.summary?.leaves) {
            curr.details?.summary.leaves.forEach((lv, idx) => {
              if (idx < 2) {
                if (lv.type === 'compensatory-off') {
                  count += 0.5;
                }
              }
            });
          }

          return x + count;
        }, 0);

        const onJobAccidentLeaves = attendanceData.reduce((x, curr) => {
          let count = 0;

          if (curr.finalized && curr.details?.summary?.leaves) {
            curr.details?.summary.leaves.forEach((lv, idx) => {
              if (idx < 2) {
                if (lv.type === 'on-job-accident-leave') {
                  count += 0.5;
                }
              }
            });
          }

          return x + count;
        }, 0);

        const scheduledWeeklyOffCount = attendanceData.filter(
          (x) =>
            x.finalized &&
            ['week-off', 'worked-on-week-off', 'week-off-leave'].includes(
              x.status
            )
        ).length;

        const otSalary = attendanceData.reduce((prev, curr) => {
          if (
            curr.finalized &&
            curr.details?.summary?.addSalaryForOvertime?.overtimeHours
          ) {
            const salHours = parseFloat(
              `${curr.details?.summary?.addSalaryForOvertime?.overtimeHours}`
            );
            return prev + salHours;
          }
          return prev;
        }, 0);

        const otCompoff = attendanceData.reduce((prev, curr) => {
          if (
            curr.finalized &&
            curr.details?.summary?.overtime?.reward === 'comp-off'
          ) {
            const ots = parseInt(
              `${curr.details?.summary?.overtime?.totalCompOffEarned}`
            );
            return prev + ots;
          }
          return prev;
        }, 0);

        let elgibleWeekOffCalculation = Math.round(
          scheduledWeeklyOffCount -
            (empMonthDetails.totalOfficialDays -
              ((payableDaysRatio -
                totalPaidLeaves +
                compOffs +
                onJobAccidentLeaves) *
                7) /
                6) /
              7
        );
        if (elgibleWeekOffCalculation < 0) {
          elgibleWeekOffCalculation = 0;
        }
        elgibleWeekOffCalculation =
          elgibleWeekOffCalculation > scheduledWeeklyOffCount
            ? scheduledWeeklyOffCount
            : elgibleWeekOffCalculation;
        const absentDays = attendanceData.filter(
          (x) => x.finalized && x.status === 'absent'
        ).length;
        const absentDaysPenalty =
          attendanceData.filter(
            (x) =>
              x.finalized &&
              x.status === 'absent' &&
              x.details?.summary?.payableDayPenalty
          ).length / 2;
        const totalPayableDays =
          payableDaysRatio + elgibleWeekOffCalculation - absentDaysPenalty;

        let lateMarkPenaltyRatio = 0;

        attendanceData
          .filter(
            (x) =>
              x.finalized &&
              x.details?.lateMark?.status === 'approved' &&
              x.details?.summary?.payableDayPenalty
          )
          .forEach((x) => {
            if (x.details?.summary?.lateMarkMins) {
              const lateHourRatio =
                x.details?.summary?.lateMarkMins /
                (employee.details.jobProfile.shiftType === 'Rotational'
                  ? 8
                  : 9);

              lateMarkPenaltyRatio += lateHourRatio;
            }
          });

        const totalFinalizedDays = attendanceData.filter((x) => x.finalized)
          .length;
        const checkDays =
          absentDays +
          payableDaysRatio +
          totalUnpaidLeaves +
          (scheduledWeeklyOffCount - elgibleWeekOffCalculation);
        const remark = totalFinalizedDays === checkDays;

        resultEmployees.push({
          name: employee.name,
          employeeId: employee.details?.employeeId,
          days: `${empMonthDetails.totalDays}`,
          totalFinalizedDays: `${totalFinalizedDays}`,
          presentDays: `${
            attendanceData.filter(
              (x) => x.finalized && x.details?.summary?.payableDay
            ).length
          }`,
          absentDays: `${absentDays}`,
          payableDaysRatio: `${payableDaysRatio}`,
          totalPaidLeaves: `${totalPaidLeaves}`,
          totalUnpaidLeaves: `${totalUnpaidLeaves}`,
          eligibleWeekOffs: `${elgibleWeekOffCalculation}`,
          absentDaysPenalty: `${absentDaysPenalty}`,
          lateMarkPenaltyDays: `${
            attendanceData.filter(
              (x) =>
                x.finalized &&
                x.details?.lateMark?.status === 'approved' &&
                x.details?.summary?.payableDayPenalty
            ).length
          }`,
          lateMarkPenaltyRatio: `${lateMarkPenaltyRatio}`,
          scheduledWeekOffs: `${scheduledWeeklyOffCount}`,
          actualCheckDays: `${actualDays}`,
          checkDays: `${checkDays}`,
          otSalary: `${otSalary}`,
          otCompoff: `${otCompoff}`,
          totalPayableDays: `${
            empMonthDetails.totalDays < totalPayableDays
              ? empMonthDetails.totalDays
              : totalPayableDays
          }`,
          remark: remark ? 'Ok' : 'Mismatch',
        });
      } catch (err) {
        console.log(err);
      }
    }
    return [
      {
        data: resultEmployees,
        mapper: report,
        fileName: `${currentCompany.shortName}-${date}-payable-days-estimation`,
      },
    ];
  }

  const Report = renderBulkDownload({
    mapperFun: downloadReport,
    downloadIcon: ExcelDownload,
    name: 'Download Report',
  });

  return (
    <div className='w-full justify-center'>
      <div className='text-center'>
        Download Report of {filteredEmployees.length} employees{' '}
        {moment().format('MM-YYYY') ===
        moment(date, 'YYYY-MM-DD').format('MM-YYYY') ? (
          <span>
            until{' '}
            <DateSelector
              initialState={until}
              format='YYYY-MM-DD'
              onChange={(date) => setUntil(date)}
            />
          </span>
        ) : null}
      </div>
      <div className='border border-green-500 rounded p-1 w-40 mx-auto'>
        <Report />
      </div>
    </div>
  );
}
