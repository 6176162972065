/* eslint-disable no-unused-vars */
import { renderGenericStockTab, Transaction } from '@erp_core/erp-reports';
import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item/index';

type RenderStockTab = {
  services: {
    itemService: ItemInterface;
  };
  itemId: string;
};

type ViewType =
  | 'godown'
  | 'transactions'
  | 'quaterly-transactions'
  | 'monthly-transactions';

export function renderStockTab({
  services: { itemService },
  itemId,
}: RenderStockTab) {
  return function () {
    const [viewType, setViewType] = useState<ViewType>('godown');
    const viewTypes: ViewType[] = [
      'godown',
      'transactions',
      'quaterly-transactions',
      'monthly-transactions',
    ];
    const [tr, setTr] = useState<Transaction[]>([]);

    const fetchTransactions = async () => {
      const tran = await itemService.getTransactions(itemId);
      const mapped = tran.map((t) => ({
        id: t.id,
        name: t.name,
        createdAt: t.createdAt,
        to: {
          id: t.toGodown?.id || '',
          name: t.toGodown?.name || '',
        },
        from: {
          id: t.fromGodown?.id || '',
          name: t.fromGodown?.name || '',
        },
        quantity: t.quantity,
      }));
      setTr(mapped);
      // setTr(tran)
      return tran;
    };

    useEffect(() => {
      fetchTransactions();
    }, []);

    const getGodownList = (
      data: Transaction[],
      range: { from: string; to: string }
    ) => {
      const godownList: { id: string; name: string }[] = [];
      _.forEach(data, (n) => {
        if (n.from && !godownList.find((i) => i.id === n.from?.id))
          godownList.push({ id: n.from.id, name: n.from.name });
        if (n.to && !godownList.find((i) => i.id === n.to?.id))
          godownList.push({ id: n.to.id, name: n.to.name });
      });
      return godownList;
    };

    const calculateGodownLevelInwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter((i) => i.to && i.to.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const calculateGodownLevelOutwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter((i) => i.from && i.from.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };

    const GodownLevelStock = renderGenericStockTab({
      // title: 'Godown Level',
      primaryColumnTitle: 'Godown',
      transactions: tr,
      components: {
        LineChart: Line,
      },
      getList: getGodownList,
      calculateInwardQnt: calculateGodownLevelInwardQnt,
      calculateOutwardQnt: calculateGodownLevelOutwardQnt,
    });

    // transaction
    const getDatesList = (
      data: Transaction[],
      range: { from: string; to: string }
    ) => {
      const itemList = data.map((n) => {
        if (
          moment(n.createdAt).isBetween(moment(range.from), moment(range.to)) &&
          (!n.to?.id || !n.from?.id)
        )
          return {
            id: n.id,
            name: moment(n.createdAt).format('DD-MM-YYYY'),
          };
        return null;
      });
      const finalList = itemList.filter(Boolean) as any;
      return finalList;
    };

    const calculateTransactionLevelInwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter((i) => !i.from?.id && i.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const calculateTransactionLevelOutwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter((i) => !i.to?.id && i.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };

    const TLStock = renderGenericStockTab({
      // title: 'TLS',
      primaryColumnTitle: 'Date',
      transactions: tr,
      components: {
        LineChart: Line,
      },
      showLineGraph: true,
      getList: getDatesList,
      calculateInwardQnt: calculateTransactionLevelInwardQnt,
      calculateOutwardQnt: calculateTransactionLevelOutwardQnt,
    });

    const getMonthsList = (
      data: Transaction[],
      range: { from: string; to: string }
    ) => {
      var dateStart = moment(range.from);
      var dateEnd = moment(range.to);
      const monthsList: { id: string; name: string }[] = [];

      while (
        dateEnd > dateStart ||
        dateStart.format('M') === dateEnd.format('M')
      ) {
        const month = dateStart.format('MMMM-YYYY');
        monthsList.push({
          id: month,
          name: month,
        });
        dateStart.add(1, 'month');
      }
      return monthsList;
    };

    const calculateMonthLevelInwardQnt = (data: Transaction[], id?: string) => {
      const qty = data
        .filter(
          (i) => !i.from?.id && moment(i.createdAt).format('MMMM-YYYY') === id
        )
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const calculateMonthLevelOutwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter(
          (i) => !i.to?.id && moment(i.createdAt).format('MMMM-YYYY') === id
        )
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };

    const TLMStock = renderGenericStockTab({
      // title: 'TLMS',
      primaryColumnTitle: 'Month',
      transactions: tr,
      components: {
        LineChart: Line,
      },
      showLineGraph: true,
      getList: getMonthsList,
      calculateInwardQnt: calculateMonthLevelInwardQnt,
      calculateOutwardQnt: calculateMonthLevelOutwardQnt,
    });

    const getQuaterList = (
      data: Transaction[],
      range: { from: string; to: string }
    ) => {
      var dateStart = moment(range.from);
      var dateEnd = moment(range.to);
      const quaterList: { id: string; name: string }[] = [];

      while (dateEnd > dateStart || dateStart.quarter() === dateEnd.quarter()) {
        const quater = `Q${dateStart.quarter()}-${dateStart.format('YYYY')}`;
        quaterList.push({
          id: quater,
          name: quater,
        });
        dateStart.add(3, 'month');
      }
      return quaterList;
    };

    const calculateQuaterLevelInwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter(
          (i) =>
            !i.from?.id &&
            `Q${moment(i.createdAt).quarter()}-${moment(i.createdAt).format(
              'YYYY'
            )}` === id
        )
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const calculateQuaterLevelOutwardQnt = (
      data: Transaction[],
      id?: string
    ) => {
      const qty = data
        .filter(
          (i) =>
            !i.to?.id &&
            `Q${moment(i.createdAt).quarter()}-${moment(i.createdAt).format(
              'YYYY'
            )}` === id
        )
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };

    const TLQStock = renderGenericStockTab({
      // title: 'TLMS',
      primaryColumnTitle: 'Quarter',
      transactions: tr,
      components: {
        LineChart: Line,
      },
      showLineGraph: true,
      getList: getQuaterList,
      calculateInwardQnt: calculateQuaterLevelInwardQnt,
      calculateOutwardQnt: calculateQuaterLevelOutwardQnt,
    });

    return (
      <div>
        {viewTypes.map((vt, i) => {
          return (
            <LoadingButton
              key={i}
              defaultStyle={`py-2 px-4 mr-3 mb-3 border-2 rounded-xl shadow ${
                viewType === vt ? 'bg-blue-300' : ''
              }`}
              // type='button'
              behaviorFn={async () => await setViewType(vt)}
              text={vt}
            />
          );
        })}

        {viewType === 'godown' && <GodownLevelStock />}
        {viewType === 'transactions' && <TLStock />}
        {viewType === 'quaterly-transactions' && <TLQStock />}
        {viewType === 'monthly-transactions' && <TLMStock />}
      </div>
    );
  };
}
