/* eslint-disable no-unused-vars */

import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import Calendar from '@toast-ui/react-calendar';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UseCombinedManufacturingVoucher } from '../../../../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';
import { UseCombinedMachineSchedule } from '../../../../../hooks/planning/production/machine-schedule';
import { UseCombinedMachine } from '../../../../../hooks/planning/production/machine/use-machine';
import { renderCreateMv } from './create-mv';
import { colors } from './utils/colors';
import { getPlanned } from './utils/get-planned';
import { getProposed } from './utils/get-proposed';
import { removeOverlapped } from './utils/remove-overlapped';

type RenderPlanProps = {
  hooks: {
    useCombinedMachine: UseCombinedMachine;
    useCombinedMachineSchedule: UseCombinedMachineSchedule;
    useCombinedManufacturingVoucher: UseCombinedManufacturingVoucher;
  };
};

export const renderPlan = ({
  hooks: {
    useCombinedMachine,
    useCombinedMachineSchedule,
    useCombinedManufacturingVoucher,
  },
}: RenderPlanProps) =>
  function Plan() {
    const { getAll, list: machines } = useCombinedMachine();
    const {
      getAll: getAllManufacturingVouchers,
      list: manufacturingVouchers,
    } = useCombinedManufacturingVoucher();
    const {
      getAll: getAllMachineSchedules,
      list: machineSchedules,
    } = useCombinedMachineSchedule();

    const groupedMvs = _.groupBy(manufacturingVouchers, 'machineId');

    const [selectedMachines, setSelectedMachines] = useState<string[]>([]);
    const handleMachineSelect = (id: string) => {
      setSelectedMachines((sm) => {
        if (sm.find((i) => i === id)) {
          return sm.filter((i) => i !== id);
        }
        return [...sm, id];
      });
    };

    const aggregatedSchedule = machines?.map((m, index) => {
      const color = colors[index];
      return {
        id: m.id,
        plannedSchedule: getPlanned(groupedMvs[m.id], color),
        proposedSchedule: getProposed(
          machineSchedules?.find((s) => s.machine.id === m.id),
          color
        ),
      };
    });

    const getFinalSchedule = () => {
      const filtered = aggregatedSchedule?.filter((a) =>
        selectedMachines.includes(a.id)
      );
      const sched = removeOverlapped(filtered);
      const final = sched?.map((f) => f.schedule);
      return _.flatten(final);
    };
    const finalSchedule = getFinalSchedule();

    const getDaySummary = (date: any) => {
      // const requiredDate = moment()
      const filtered = aggregatedSchedule?.filter((a) =>
        selectedMachines.includes(a.id)
      );
      const summary = filtered?.map((as) => {
        const machineName =
          machines?.find((m) => m.id === as.id)?.name || as.id;
        let plannedHours = 0;
        let proposedHours = 0;
        as.plannedSchedule.map((ps) => {
          if (
            date.format('YYYY-MM-DD') !== moment(ps.start).format('YYYY-MM-DD')
          )
            return;
          const diff = moment(ps.end).diff(moment(ps.start), 'hours');
          plannedHours += diff;
        });

        as.proposedSchedule?.map((pr: any) => {
          if (
            date.format('YYYY-MM-DD') !== moment(pr.start).format('YYYY-MM-DD')
          )
            return;
          const diff = moment(pr.end).diff(moment(pr.start), 'hours');
          proposedHours += diff;
        });

        const freeHours = proposedHours - plannedHours;
        if (freeHours > 0) {
          return `System proposed that on ${moment(date).format(
            'dddd'
          )} ${machineName} could run for ${proposedHours} hours but only ${plannedHours} hours are planned so far. ${freeHours} hours are still free.`;
        }
        if (freeHours === 0) {
          if (proposedHours === 0) {
            return '';
          }
          return ` ${machineName} planned to run on it's full capacity. System proposed ${proposedHours} hours and all ${proposedHours} hours planned.`;
        }
        if (freeHours < 0) {
          return `System proposed that on ${moment(date).format(
            'dddd'
          )} ${machineName} could run for ${proposedHours} hours. but ${plannedHours} hours are planned. ${machineName} planned to run ${-freeHours} hours over it's full capacity.`;
        }
        return '';
      });

      return summary;
    };
    const getData = () => {
      getAll();
      getAllManufacturingVouchers();
      getAllMachineSchedules();
    };
    useEffect(() => {
      getData();
      // eslint-disable-next-line
    }, []);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Date', rowSpan: 2 },
        { name: 'Summary', rowSpan: 2 },
      ],
    ];

    const tableData: any = [];

    for (let i = 0; i < 15; i++) {
      const requiredDate = moment().add(i, 'days');
      const row = {
        cells: [
          { value: requiredDate.format('DD-MM-YY') },
          {
            value: (
              <div>
                {getDaySummary(requiredDate)?.map((s) => (
                  <div key={String(Math.random())} className='mb-1'>
                    {s}
                  </div>
                ))}
              </div>
            ),
          },
        ],
      };

      tableData.push(row);
    }
    // data?.details.schedule && Object.entries(data?.details.schedule)?.map(([key, value]) => ({
    //   cells: [
    //     { value: key },
    //     { value: value.length === 0 ? 'closed' : ` runs on every ${key}` },

    //   ]
    // })) || []

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Production plan summary (2 weeks)',
      actions: [
        {
          type: 'button',
          show: () => Boolean(aggregatedSchedule && machines),
          button: {
            name: 'Save',
            behaviour: 'modal',
            modal: {
              title: 'Create Manufacturing vouchers',
              content: ({ onClose }) => {
                let filtered =
                  aggregatedSchedule?.filter((a) =>
                    selectedMachines.includes(a.id)
                  ) || [];
                filtered = filtered.map((i) => ({
                  ...i,
                  proposedSchedule: i.proposedSchedule.filter(
                    (a) =>
                      !i.plannedSchedule.find(
                        (t) => t.start === a.start && t.end === a.end
                      )
                  ),
                }));

                const MvCreate = renderCreateMv({
                  useCombinedManufacturingVoucher,
                });

                return (
                  <MvCreate
                    close={() => {
                      getData();
                      onClose();
                    }}
                    filtered={filtered}
                    machines={machines || []}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} />,
    };

    return (
      <div>
        <div className='mb-2 text-2xl font-bold tracking-tight text-gray-900'>
          {' '}
          Production Plan
        </div>
        <div className='flex p-4'>
          <div className='p-4 mr-4 w-64'>
            <div className='mb-2 text-xl font-bold tracking-tight text-gray-900'>
              Machines
            </div>

            {machines?.map((m, index) => (
              <div key={m.id} className='flex items-center mb-4'>
                <input
                  style={{ color: colors[index % 4].textDark }}
                  id='default-checkbox'
                  type='checkbox'
                  checked={!!selectedMachines.find((id) => m.id === id)}
                  onChange={() => handleMachineSelect(m.id)}
                  className='w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500'
                />
                <label
                  style={{ color: colors[index % 4].textDark }}
                  className='ml-2 text-lg font-medium text-gray-900'
                >
                  {m.name}
                </label>
              </div>
            ))}
          </div>

          <div className='grow'>
            <Calendar
              usageStatistics={false}
              height='900px'
              isReadOnly={false}
              useDetailPopup
              useFormPopup
              view='month'
              month={{
                visibleWeeksCount: 3,
              }}
              week={{
                taskView: false,
                eventView: ['time'],
                startDayOfWeek: 1,
              }}
              // calendars={calendars}
              events={finalSchedule as any}
              //  template={template}
            />

            <Card header={cardHeader} body={cardBody} />
          </div>
        </div>
      </div>
    );
  };
