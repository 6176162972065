/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircle } from '@erp_core/erp-icons/icons/web/check-circle';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Client, ClientItem } from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedGrade } from '../../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClientItem } from '../../../hooks/order/sales/client-item/use-client-item';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { renderAddClientItemForm } from './form';

type RenderClientItemsProps = {
  useCombinedItem: UseCombinedItem;
  useCombinedGrade: UseCombinedGrade;
  useCombinedClientItem: UseCombinedClientItem;
  useUserAuthorization: UseUserAuthorization;
  client: Client;
  loading: boolean;
  setClient: (s: Client) => Promise<Client>;
  itemService: ItemInterface;
};
export const renderClientItemList = ({
  client,
  loading,
  setClient,
  useCombinedItem,
  useCombinedClientItem,
  useUserAuthorization,
  itemService,
  useCombinedGrade,
}: RenderClientItemsProps) => {
  return function ClientItemList() {
    const { syncSet: setClientItem } = useCombinedClientItem();
    const { get: getAllowedActions } = useUserAuthorization();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Items',
      actions: [
        {
          auth: 'UI:BTN-ADD-VENDOR-ITEM:VIEW',
          type: 'button',
          button: {
            name: 'Add Client Item',
            behaviour: 'modal',
            modal: {
              title: 'Add Client Item',
              content: ({ onClose }) => {
                const ClientItemForm = renderAddClientItemForm({
                  client,
                  setClientItem,
                  useCombinedItem,
                  itemService,
                });
                return (
                  <>
                    <ClientItemForm onClose={onClose} />
                  </>
                );
              },
            },
          },
          show: () => {
            if (client.id) {
              return true;
            }
            return false;
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        // { name: 'Name',  },
        // { name: 'Status',  },
        { name: 'Item Name' },
        // { name: 'Approval status' },
        { name: 'End Use' },
        { name: 'Client Location' },
        { name: 'Contact Person' },
        { name: 'Specifications' },
        // { name: 'Approval Documents',  },
      ],
    ];

    const tableBody: TableBody =
      client?.items?.map((l, idx) => ({
        rowData: { item: l, idx },
        cells: [
          {
            value: (
              <div>
                <div className='font-semibold'>
                  {l.name}
                  {l.status === 'approved' ? (
                    <CheckCircle className='inline w-3 text-green-600' />
                  ) : null}
                </div>
                <div>
                  {l.details?.grades?.map((g) => (
                    <span
                      className='border border-gray-200 px-1 rounded-md mx-1'
                      key={g.id}
                    >
                      {g.name}
                    </span>
                  ))}
                </div>
              </div>
            ),
          },
          // { value: l.status },
          {
            value: (
              <>
                {l.details?.endUse
                  ? l.details?.endUse.map((o) => `${o.name},`)
                  : '--'}
              </>
            ),
          },
          {
            value: `${l.details?.clientLocation?.name || '--'}`,
          },
          {
            value: `${l.details?.contactPerson?.name || '--'}`,
          },
          { value: '-' },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit Grades',
                  behaviour: 'modal',
                  show: () => true,
                  modal: {
                    title: 'Edit Grades',
                    content: ({
                      data: { item, idx },
                      onClose,
                    }: {
                      data: { item: ClientItem; idx: number };
                      onClose: () => void;
                    }) => {
                      console.log(item.item);
                      const Form = renderFormV2({
                        initialFormState: {
                          grades: item.details.grades || [],
                        },
                        fieldsData: [
                          {
                            property: 'grades',
                            label: 'Grades',
                            type: 'searchable-multi-select',
                            searchOptions: {
                              filter: {
                                item: item.item?.id,
                              },
                              searchOptionsBody: {
                                customBody: (p) => (
                                  <div>
                                    <div className='bold'>{p.name}</div>
                                    <div className='text-sm text-gray-600'>
                                      {p.item.name}
                                    </div>
                                  </div>
                                ),
                              },
                              useSearch: useCombinedGrade,
                              onSearchValueSelect: () => {},
                            },
                          },
                        ],
                        onSubmit: async (form) => {
                          console.log(form);
                          setClientItem({
                            id: item.id,
                            details: {
                              grades: form.grades as Array<IdName>,
                            },
                          } as any);
                        },
                        mapTToU: (x) => x,
                      });
                      return <Form />;
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div className='w-full space-y-3'>
          {loading ? (
            <Recat className='h-5 inline animate-pulse mx-4' />
          ) : (
            <Card
              header={cardHeader}
              body={cardBody}
              auth={{ actions: getAllowedActions().actions }}
            />
          )}
        </div>
      </>
    );
  };
};
