/* eslint-disable no-unused-vars */
import { User } from '@erp_core/erp-types/dist/modules/admin';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedRole } from '../../../hooks/admin/role-admin/use-roles-admin';

export type RenderEditRoleFormProps = {
  useCombinedRole: UseCombinedRole;
  saveRole: (user: User) => Promise<void>;
};

export type EditRoleFormProps = {
  roles: Array<{ id: string; name: string }>;
};

export function renderUserEditForm({
  useCombinedRole,
  saveRole,
}: RenderEditRoleFormProps): (r: {
  user: User;
  onClose: () => void;
}) => JSX.Element {
  return function ({
    user,
    onClose,
  }: {
    user: User;
    onClose: () => void;
  }): JSX.Element {
    const save = async (form) => {
      await saveRole({ id: user.id, roles: form.roles } as User);
      onClose();
    };

    const formProps: FormProps<Partial<EditRoleFormProps>> = {
      fieldsData: [
        {
          property: 'roles',
          label: 'Select Role',
          type: 'searchable-multi-select',
          searchOptions: {
            useSearch: useCombinedRole,
            onSearchValueSelect: () => {},
          },
        },
      ],
      initialFormState: {
        roles: user.roles
          ? ((user.roles.map((a) => ({
              id: a.id,
              name: a.name,
            })) as unknown) as any)
          : [],
      },
      mapTToU: (b) => {
        return {
          roles: b.roles as any,
        };
      },
      onSubmit: save,
    };
    const Form = renderFormV2<Partial<EditRoleFormProps>>(formProps);
    return <Form />;
  };
}
