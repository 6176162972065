/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { PremiumsFilter } from '@erp_core/erp-types/dist/modules/hrd';
import { PremiumsType } from '@erp_core/erp-types/dist/modules/insurance';
import { ErpV2Api } from 'models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class PremiumsHttpRepo extends GenericHttpWithLogsRepo<
  PremiumsType,
  PremiumsFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/premiums',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
