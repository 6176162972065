import { DeliveryModesShortForm } from '@erp_core/erp-types/dist/modules/constants';

type InsuranceType = 'to-your-account ' | 'to-our-account';
type Freight = 'paid-basis' | 'to-pay-basis';

export type DeliveryMode = {
  id: DeliveryModesShortForm;
  name: string;
};

export const deliveryModesData: DeliveryMode[] = [
  {
    id: 'A',
    name: 'By Air way',
  },
  {
    id: 'R',
    name: 'By Road way',
  },
  {
    id: 'S',
    name: 'By Sea Way',
  },
  {
    id: 'AR',
    name: 'By Air and Road ways',
  },
  {
    id: 'AS',
    name: 'By Air and Sea ways',
  },
  {
    id: 'RS',
    name: 'By Road and Sea ways',
  },
  {
    id: 'ARS',
    name: 'By Air, Road and Sea ways',
  },
  {
    id: 'C',
    name: 'By Courier',
  },
];

export class DeliveryModeRepo {
  getAll(filter: {
    name?: string;
    insurance?: InsuranceType;
    freight: Freight;
  }) {
    const result = deliveryModesData.filter((a) => {
      return !filter.name || a.name.includes(filter.name);
    });
    return result;
  }

  get(id: string) {
    return deliveryModesData.find((d) => d.id === id) || null;
  }
}
