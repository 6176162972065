import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UserContext } from '../../../../contexts/user';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

// eslint-disable-next-line

export type CustomerCommentsType = {
  comments: string;
  document: string;
  applicable: string;
};

type Props = {
  useFileTransfer: UseFileTransfer;
};

export function renderCustomerCommentsForm({ useFileTransfer }: Props) {
  return function AddCustomerCommentsForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const { company, companyGroup } = useContext(CurrentContext);
    const formProps: FormProps<CustomerCommentsType> = {
      fieldsData: [
        {
          type: 'select',
          label: 'Applicable',
          options: [
            { value: 'select', text: 'select' },
            { value: 'yes', text: 'yes' },
            { value: 'no', text: 'no' },
          ],
          property: 'applicable',
        },
        {
          type: 'text-area',
          label: 'Comments',
          property: 'comments',
          rows: 3,
          dependentOn: (formData) => {
            return formData.applicable === 'yes';
          },
        },
        {
          property: 'document',
          type: 'file',
          readonly: true,
          accept: '.jpg, .png, .pdf',
          label: 'document',
          dependentOn: (formData) => {
            return formData.applicable === 'yes';
          },
        },
      ],
      initialFormState: {
        comments: '',
        document: '',
        applicable: '',
      },
      upload: {
        useFileTransfer: useFileTransfer,
        config: [
          {
            name: 'document',
            path: `${companyGroup.id}/${company.id}/requisition/customer-comments/${data?.id}`,
            type: 'public',
          },
        ],
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        // const customerComments = data?.details.customerComments
        await onSave(({
          details: {
            ...data?.details,
            customerComments: {
              applicable: f.applicable,
              document: f.document,
              comments: f.comments,
              commentedBy: {
                id: currentUser.id,
                name: currentUser.name,
              },
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<CustomerCommentsType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<CustomerCommentsType>
    ): CustomerCommentsType {
      return (b as unknown) as CustomerCommentsType;
    }
  };
}
