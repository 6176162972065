import { PaymentTerm } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddPaymentTermsForm() {
  return function AddPaymentTerms({
    onSave,
  }: {
    onSave: (data: PaymentTerm) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<PaymentTerm>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        description: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          description: l.description,
        } as PaymentTerm);
      },
    };

    const Form = renderFormV2<Partial<PaymentTerm>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<PaymentTerm>>): PaymentTerm {
      return (b as unknown) as PaymentTerm;
    }
  };
}
