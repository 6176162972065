import { EmployeeDesignation } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedEmployeeCategory } from '../../../../hooks/admin/constants/employee-category/use-employee-category';

export type EmployeeDesignationFormType = {
  name: string;
  id: string;
  grade: string;
};

export function renderAddEmployeeDesignationForm() {
  return function AddEmployeeDesignationForm({
    onSave,
    useCombinedEmployeeCategory,
  }: {
    onSave: (data: EmployeeDesignation) => Promise<void>;
    useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  }): JSX.Element {
    const formProps: FormProps<Partial<EmployeeDesignationFormType>> = {
      fieldsData: [
        {
          property: 'id',
          type: 'input',
          label: 'id',
          required: true,
        },
        {
          property: 'name',
          type: 'input',
          label: 'name',
          required: true,
        },
        {
          property: 'grade',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedEmployeeCategory,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        id: '',
        name: '',
        grade: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave(({
          id: l.id,
          name: l.name,
          category: l.grade,
        } as unknown) as EmployeeDesignation);
      },
    };

    const Form = renderFormV2<Partial<EmployeeDesignationFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EmployeeDesignationFormType>>
    ): EmployeeDesignationFormType {
      return (b as unknown) as EmployeeDesignationFormType;
    }
  };
}
