import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { useConfirm } from '@erp_core/erp-ui-components';
import { EmployeeRoles } from './emp-roles';
// import { EmployeeSkills } from './emp-skills';
import { EmployeeWorkLogs } from './emp-work-logs';

type Props = {
  data: EmployeeProfileType;
  setEmployee: (
    data: EmployeeProfileType,
    options?: any
  ) => Promise<EmployeeProfileType>;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr: boolean;
};

export function renderEmployeeJobProfile() {
  return function EmployeeJobProfile({
    data,
    setEmployee,
    actions,
    isHr,
  }: Props): JSX.Element {
    const { Confirm, openConfirm } = useConfirm();
    return (
      <div>
        <Confirm />
        <br />
        <div className='flex space-x-2'>
          <div className='w-1/2'>
            <EmployeeRoles
              openConfirm={openConfirm}
              employeeProfile={data}
              setEmployee={setEmployee}
              actions={actions}
              isHr={isHr}
            />
          </div>
          <div className='w-1/2'>
            {/* <EmployeeSkills
              openConfirm={openConfirm}
              employeeProfile={data}
              setEmployee={setEmployee}
              actions={actions}
              isHr={isHr}
            /> */}
          </div>
        </div>
        <div>
          <EmployeeWorkLogs
            openConfirm={openConfirm}
            employeeProfile={data}
            setEmployee={setEmployee}
            actions={actions}
            isHr={isHr}
          />
        </div>
      </div>
    );
  };
}
