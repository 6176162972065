import { Transaction } from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  StockGroup,
  StockGroupTempFilterType,
} from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import { downloadExcelFile } from '@erp_core/erp-ui-components';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { StockGroupInterface } from '../../../../../models/interfaces/inventory/stock-group';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpStockGroupRepo
  extends GenericHttpWithLogsRepo<StockGroup, StockGroupTempFilterType>
  implements StockGroupInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/stockgroups',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getTransactions(id: string): Promise<Transaction[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/transactions`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c as Transaction);
    }

    return [];
  }

  async getProperties(id: string): Promise<ItemPropertyValue[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/properties`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c as ItemPropertyValue);
    }

    return [];
  }

  async setPropertyValues(id: string, data: any): Promise<StockGroup> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${id}/property-values`,
      data
    );
    if (res.status === 200) {
      const { data } = res;

      return data as StockGroup;
    }

    return {} as StockGroup;
  }

  async getSpecificationsTemplate(id: string): Promise<{ template: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/specifications-template`
    );
    if (res.status === 200) {
      const { data } = res;

      return data as Promise<{ template: string }>;
    }

    return { template: '' };
  }

  async getSheetItemStockgroup(id: string): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/item-stockgroup-sheet`
    );
    if (res.status === 200) {
      const { data } = res;

      downloadExcelFile({
        fileName: 'stockgroup',
        base64Data: data,
      });
    }
  }

  async uploadSheetItemStockgroup(id: string, data: string): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${id}/item-stockgroup-sheet`,
      {
        data,
      }
    );
    if (res.status === 200) {
      const { data } = res;

      return data;
    }
  }
}
