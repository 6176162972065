import { ManufacturingVoucherInterface } from '../../../../models/interfaces/order/work-order/manufacturing-voucher.ts/index.js';

export type ReserveData = {
  manufacturingVoucherItemId: string;
  referenceId: string;
  referenceType: string;
  quantity: number;
  expireBy?: string;
};

export type UseVirtuallyReserveMfVoucher = () => {
  // eslint-disable-next-line no-unused-vars
  set(data: ReserveData): void;
};

type CreateUseVirtuallyReserveMfVoucher = {
  manufacturingVoucherService: ManufacturingVoucherInterface;
};

export function createUseVirtuallyReserveMfVoucher({
  manufacturingVoucherService,
}: CreateUseVirtuallyReserveMfVoucher): UseVirtuallyReserveMfVoucher {
  return () => {
    const set = (data: ReserveData) => {
      manufacturingVoucherService.allocateItem(data);
    };
    return {
      set,
    };
  };
}
