import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedItem } from '../../../../../../../hooks/inventory/item/use-combined-item';
import { CategoryIcons } from '../../../../item-categories';

type AddNewRawItemFormProps = {
  useCombinedItem: UseCombinedItem;
  onSave: (props: any) => Promise<void>;
};

type AddNewRawItemType = {
  itemId: string;
  ratio: number;
  costContributionPerc: number;
  reason: string;
};

export function renderAddNewRawItemForm({
  useCombinedItem,
  onSave,
}: AddNewRawItemFormProps): () => JSX.Element {
  return function AddNewRawItemForm(): JSX.Element {
    const onSubmit = async (form) => {
      await onSave(form);
    };

    const formProps: FormProps<AddNewRawItemType> = {
      fieldsData: [
        {
          property: 'itemId',
          label: 'Select item to be added',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              customBody: (data: ItemRes) => {
                return (
                  <div>
                    <div className='font-bold'>{data.name}</div>
                    <div>
                      {data.categories?.map((x) => (
                        <CategoryIcons category={x.category} key={x.id} />
                      ))}
                      {data.category.name}
                    </div>
                  </div>
                );
              },
            },
          },
        },
        {
          property: 'reason',
          label: 'How it will be used?',
          type: 'input',
          required: true,
        },
        {
          property: 'ratio',
          label: 'Quantity in % (0 - 100)',
          type: 'number',
          required: true,
        },
        {
          property: 'costContributionPerc',
          label: 'Cost Contribution in %',
          type: 'number',
          required: true,
        },
      ],
      initialFormState: {
        itemId: '',
        reason: '',
        ratio: '',
        costContributionPerc: '',
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: onSubmit,
    };

    const Form = renderFormV2<AddNewRawItemType>(formProps);

    return <Form />;
    function mapPFDToP(
      b: FormDataType<AddNewRawItemType> & { [k: string]: any }
    ): AddNewRawItemType {
      return {
        itemId: (b.itemId as { id: string }).id as string,
        reason: b.reason as string,
        ratio: b.ratio as number,
        costContributionPerc: b.costContributionPerc as number,
      };
    }
  };
}
