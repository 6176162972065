import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { Card, ColumnizedContent } from '@erp_core/erp-ui-components';
import { createCompanyCard } from '../cards/companyprofilecards';

export function renderCompanyProfile({ loading }: { loading: boolean }) {
  return function ({ company }: { company: Company }) {
    // eslint-disable-next-line

    const itemProfileInitialData: Card = {
      header: {
        title: 'Company Profile',
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initialItemData = {
      profileData: itemProfileInitialData,
    };
    const ProfileCard = createCompanyCard({
      initialData: initialItemData.profileData,
      mapperFunction: mapItemProfileBody,
    });

    return (
      <div className='w-full space-y-3'>
        <ProfileCard companyData={company} loadingItem={loading} />
      </div>
    );
  };
}

const mapItemProfileBody = (data: Company): ColumnizedContent => [
  [
    { key: 'Name', value: data.name || '--' },
    { key: 'Description', value: data.description || '--' },
    { key: 'Short Name', value: data.shortName || '--' },
    {
      key: 'Address',
      value:
        (data.details?.address?.addressLine1 || '') +
          ',' +
          (data.details?.address?.city || '') +
          ',' +
          (data.details?.address?.district || '') +
          ',' +
          (data.details?.address?.state || '') || '-',
    },
    { key: 'Contact No', value: data.details?.contact?.mobile || '--' },
    { key: 'Email', value: data.details?.contact?.email || '--' },
  ],
  [
    { key: 'Date of incorporation', value: data.dateOfIncorporation || '--' },
    {
      key: 'TIN No',
      value:
        data.details?.documents?.find((x) => x.type === 'tin')?.number || '--',
    },
    {
      key: 'CIN No',
      value:
        data.details?.documents?.find((x) => x.type === 'cin')?.number || '--',
    },
    {
      key: 'GST No',
      value:
        data.details?.documents?.find((x) => x.type === 'gst')?.number || '--',
    },
    {
      key: 'IE code ',
      value:
        data.details?.documents?.find((x) => x.type === 'ie-code')?.number ||
        '--',
    },
  ],
  [
    {
      key: 'company-logo',
      value: data.logo || '',
      type: 'image',
      style: 'h-8 mx-auto',
    },
  ],
];
