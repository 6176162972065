import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { Card, ColumnizedContent } from '@erp_core/erp-ui-components';
import { createLocationCard } from '../cards/location-profile-cards';

export function renderLocationProfile({ loading }: { loading: boolean }) {
  return function ({ location }: { location: LocationType }) {
    // eslint-disable-next-line

    const locationProfileInitialData: Card = {
      header: {
        title: 'Location Profile',
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initiallocationData = {
      profileData: locationProfileInitialData,
    };
    const ProfileCard = createLocationCard({
      initialData: initiallocationData.profileData,
      mapperFunction: maplocationProfileBody,
    });

    return (
      <div className='w-full space-y-3'>
        <ProfileCard locationData={location} loadingItem={loading} />
      </div>
    );
  };
}

const maplocationProfileBody = (data: LocationType): ColumnizedContent => [
  [
    { key: 'Name', value: data.name || '--' },
    { key: 'Description', value: data.description || '--' },
    { key: 'Short Name', value: data.shortName || '--' },
    {
      key: 'Address',
      value:
        (data.details?.address?.addressLine1 || '') +
          ',' +
          (data.details?.address?.city || '') +
          ',' +
          (data.details?.address?.district || '') +
          ',' +
          (data.details?.address?.state || '') || '-',
    },
    { key: 'Contact No', value: data.details?.contact?.mobile || '--' },
    { key: 'Email', value: data.details?.contact?.email || '--' },
  ],
];
