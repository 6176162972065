import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  RotationalShiftDayType,
  ShiftScheduleType,
} from '@erp_core/erp-types/dist/modules/planning';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function renderBulkShiftChange() {
  return function BulkShiftChange({
    entries,
    combinedData,
    shiftSchedules,
    onClose,
    workArea,
    setRotationalShiftDay,
  }: {
    combinedData: CombinedAttDataType[];
    entries: Array<number>;
    shiftSchedules: ShiftScheduleType[];
    onClose: () => void;
    workArea: IdName;
    setRotationalShiftDay: (
      s: RotationalShiftDayType
    ) => Promise<RotationalShiftDayType>;
  }): JSX.Element {
    const [selectedShift, setSelectedShift] = useState<string>('');
    const [reason, setReason] = useState<string>('');

    const data = combinedData.filter(
      (x, idx) => entries.includes(idx) && !x.finalized
    );

    const rotationalShifts = data.filter((x) => x.shiftDay);
    const noRotationalShifts = data.filter((x) => !x.shiftDay);
    return (
      <div>
        <div className='border bordergray-200 p-2 rounded-md my-1'>
          <div className='font-semibold'>
            Employees with No Rotational Shift
          </div>
          <div className='italic text-gray-800'>
            A new shift will be created for them
          </div>
          <div className='flex flex-wrap'>
            {noRotationalShifts.map((x) => (
              <span
                className='px-2 py-1 my-1 border border-gray-200 rounded-full'
                key={x.employee?.id}
              >
                {x.employee?.name || x.employee?.id || 'unknown'}
              </span>
            ))}
          </div>
          <div className='text-center font-bold'>
            Total: {noRotationalShifts.length}
          </div>
        </div>
        <div className='border bordergray-200 p-2 rounded-md my-1'>
          <div className='font-semibold'>Employees with Rotational Shift</div>
          <div className='italic text-gray-800'>
            Their existing shifts will be Skipped as they already have a
            rotational shift
          </div>
          <div className='flex flex-wrap'>
            {rotationalShifts.map((x) => (
              <span
                className='px-2 py-1 my-1 border border-gray-200 rounded-full'
                key={x.employee?.id}
              >
                {x.employee?.name || x.employee?.id || 'unknown'}
              </span>
            ))}
          </div>
          <div className='text-center font-bold'>
            Total: {rotationalShifts.length}
          </div>
        </div>

        {noRotationalShifts.length ? (
          <div className='border bordergray-200 p-2 rounded-md my-1'>
            <div className='font-semibold'>
              Select New Shift
              <select
                value={selectedShift}
                onChange={(evt) => {
                  setSelectedShift(evt.target.value);
                }}
              >
                <option value={''}>Select</option>
                {shiftSchedules.map((x) => (
                  <option value={x.id} key={x.id}>
                    {x.name}
                  </option>
                ))}
              </select>
            </div>
            <div className='font-semibold'>
              Work Area <span className='font-normal'>{workArea.name}</span>
            </div>
            <div className='font-semibold'>
              Reason
              <input
                defaultValue={reason}
                onBlur={(evt) => {
                  console.log(evt);
                  setReason(evt.target.value);
                }}
                className='border border-gray-200 p-1 w-64'
              />
            </div>
            <div className='flex justify-center'>
              {selectedShift && reason ? (
                <LoadingButton
                  text='Change Shifts'
                  behaviourParams={{}}
                  behaviorFn={async () => {
                    const shift = shiftSchedules.find(
                      (x) => x.id === selectedShift
                    );
                    for (const att of noRotationalShifts) {
                      if (
                        att.employeeProfile.details.authorizations
                          ?.attendanceSupervisor &&
                        shift
                      ) {
                        const final: Partial<RotationalShiftDayType> = {
                          employee: {
                            id: att.employee.id,
                            name: att.employee.name,
                          },
                          date: att.date,
                          supervisor:
                            att.employeeProfile.details.authorizations
                              ?.attendanceSupervisor,
                          attendance: 'working',
                          details: {
                            shiftId: { id: shift.id, name: shift.name },
                            scheduleId: '',
                            role: 'employee',
                            workArea: workArea,
                          },
                        };
                        console.log(final);
                        await setRotationalShiftDay(
                          (final as unknown) as RotationalShiftDayType
                        );
                      } else {
                        console.log('No Supervisor for', att.employee.name);
                      }
                    }

                    toast.success('Bulk Shifts created successfully');
                    onClose();
                  }}
                />
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  };
}
