import {
  BomEstimate,
  BomEstimateQuery,
} from '@erp_core/erp-types/dist/modules/analytics';
import { useState } from 'react';
import { BomEstimateInterface } from '../../../models/interfaces/analytics/bom/estimate';

// export type UseBomEstimate = UseResource<BomEstimate>;

export type UseBomEstimate = () => {
  // eslint-disable-next-line no-unused-vars
  getEstimate(data: BomEstimateQuery): void;
  data: BomEstimate;
};

type CreateBomEstimate = {
  useBomEstimateService: () => BomEstimateInterface;
};

export function createUseBomEstimate({
  useBomEstimateService,
}: CreateBomEstimate): UseBomEstimate {
  return () => {
    const bomEstimateService = useBomEstimateService();
    const [data, setData] = useState<any>();
    const getEstimate = async (data: BomEstimateQuery) => {
      const resp = await bomEstimateService.getEstimate(data);
      setData(resp);
    };
    return {
      getEstimate,
      data,
    };
  };
}
