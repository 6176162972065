/* eslint-disable no-unused-vars */
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type RenderAddSalaryParamFormProps = {
  onSave: (data: Partial<SalaryParamType>) => Promise<void>;
};

export function renderAddSalaryParam({
  onSave,
}: RenderAddSalaryParamFormProps): ({
  revisionNum,
}: {
  revisionNum: number;
}) => JSX.Element {
  return function AddSalaryParam({
    revisionNum,
  }: {
    revisionNum: number;
  }): JSX.Element {
    const formProps: FormProps<Partial<SalaryParamType>> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input', required: true },
        {
          property: 'description',
          label: 'Description',
          type: 'input',
          required: true,
        },
        {
          property: 'revisionNum',
          label: 'Revision',
          type: 'input',
          readonly: true,
        },
        { property: 'date', label: 'Date', type: 'date', required: true },
      ],
      initialFormState: {
        name: '',
        date: '',
        details: '',
        revisionNum,
      },
      mapTToU: mapPFDToP,
      onSubmit: onSave,
    };

    const Form = renderFormV2<Partial<SalaryParamType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<SalaryParamType>>
    ): Partial<SalaryParamType> {
      const res = {
        ...b,
        ...{ status: 'draft', revisionNum: revisionNum, details: {} },
      };
      return (res as unknown) as SalaryParamType;
    }
  };
}
