import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { paymentTerms } from '@erp_core/erp-utils';
import { useState } from 'react';
import { deliveryModesData } from '../../../../../datalayer/repository/in-memory/admin/delivery-mode';
import { UseCombinedDeliveryTerm } from '../../../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../../../hooks/constants/payment-term/use-payment-term';
// import { paymentTerms } from '../../../../../utils/purchase/vendor-payment-terms';

type CreateItemCardType = {
  vendor: Vendor;
  loading: boolean;
  setVendor: (s: Vendor) => Promise<Vendor>;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
};

export function renderVendorTerms({
  vendor,
  loading,
  setVendor,
  useCombinedDeliveryTerm,
  useCombinedPaymentTerm,
}: CreateItemCardType) {
  return function VendorTerms(): JSX.Element {
    const onEditVendorTerms = async (f: any) => {
      f.modeOfTransport = deliveryModesData.find(
        (i) => i.id === f.modeOfTransport
      );

      let termsOfBusiness = vendor.details?.termsOfBusiness;
      const saveData = {
        id: vendor.id,
        details: {
          termsOfBusiness: { ...termsOfBusiness, ...f },
        },
      };
      console.log('ye update hoga', saveData);
      await setVendor(saveData as Vendor);
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `Terms of Business: ${vendor.name}`,
      actions: [
        {
          type: 'button',
          button: {
            name: 'Edit Payment Terms',
            behaviour: 'modal',
            modal: {
              title: 'Edit Payment Terms',
              content: ({ onClose }) => {
                return (
                  <EditPaymentTerms
                    paymentTerms={
                      Array.isArray(
                        vendor.details?.termsOfBusiness?.paymentTerms
                      )
                        ? vendor.details?.termsOfBusiness?.paymentTerms || []
                        : []
                    }
                    onSave={async (newPt) => {
                      let termsOfBusiness = vendor.details?.termsOfBusiness;
                      const saveData = {
                        id: vendor.id,
                        details: {
                          termsOfBusiness: {
                            ...termsOfBusiness,
                            ...{ paymentTerms: newPt },
                          },
                        },
                      };
                      await setVendor(saveData as Vendor);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          type: 'button',
          show: () => !loading,
          button: {
            name: 'Edit Delivery Terms',
            behaviour: 'modal',
            modal: {
              title: 'Edit Vendor Terms',
              content: ({ onClose }) => {
                const Form = renderFormV2({
                  fieldsData: [
                    // {
                    //   property: 'paymentTerms',
                    //   label: 'Payment Term',
                    //   searchOptions: {
                    //     useSearch: usePaymentTerms,
                    //     onSearchValueSelect(u) {},
                    //   },
                    //   type: 'searchable-select',
                    //   required: true,
                    // },
                    // {
                    //   property: 'creditPeriod',
                    //   label: 'Credit Period',
                    //   type: 'number',
                    //   required: true,
                    // },
                    {
                      property: 'deliveryTerms',
                      label: 'Delivery Term',
                      type: 'searchable-select',
                      searchOptions: {
                        useSearch: useCombinedDeliveryTerm,
                        onSearchValueSelect(u) {},
                      },
                      required: true,
                    },
                    {
                      property: 'modeOfTransport',
                      label: 'Mode Of Transport',
                      type: 'select',
                      options: deliveryModesData.map((d) => ({
                        text: d.name,
                        value: d.id,
                      })),
                      required: true,
                    },
                    // {
                    //   property: 'preCarraigeBy',
                    //   label: 'Pre Carraige By',
                    //   type: 'input',
                    //   required: true,
                    // },
                    // {
                    //   property: 'placeOfReceiptByCarrier',
                    //   label: 'Place Of Receipt By Carrier',
                    //   type: 'input',
                    //   required: true,
                    // },
                  ],
                  initialFormState: {
                    // paymentTerms: (vendor?.details?.termsOfBusiness
                    //   ?.paymentTerms as string) || { id: '', name: '' },
                    // creditPeriod:
                    //   vendor?.details?.termsOfBusiness?.creditPeriod || '',
                    deliveryTerms:
                      (vendor?.details?.termsOfBusiness
                        ?.deliveryTerms as string) || '',
                    modeOfTransport:
                      vendor?.details?.termsOfBusiness?.modeOfTransport?.id ||
                      '',
                    // preCarraigeBy:
                    //   (vendor?.details?.termsOfBusiness
                    //     ?.preCarraigeBy as string) || '',
                    // placeOfReceiptByCarrier:
                    //   vendor?.details?.termsOfBusiness?.placeOfReceiptByCarrier ||
                    //   '',
                  },
                  style: 'md:w-1/2',
                  button: {
                    style: 'w-fit',
                  },
                  onSubmit: async (f) => {
                    await onEditVendorTerms(f);
                    onClose();
                  },
                  allowUnchanged: true,
                  mapTToU(p) {
                    return (p as unknown) as any;
                  },
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const body: ColumnizedContent = [
      [
        {
          key: 'Payment Terms:',
          value: paymentTerms(vendor.details?.termsOfBusiness?.paymentTerms),
          type: 'text',
        },
        // {
        //   key: 'Credit Period, Days:',
        //   value:
        //     vendor?.details?.termsOfBusiness?.creditPeriod?.toString() || '0',
        //   type: 'text',
        // },
      ],
      [
        {
          key: 'Delivery Terms:',
          value: vendor?.details?.termsOfBusiness?.deliveryTerms?.name || '',
          type: 'text',
        },
        {
          key: 'Mode of Transport:',
          value: vendor?.details?.termsOfBusiness?.modeOfTransport?.name || '',
          type: 'text',
        },
      ],
      // [
      //   {
      //     key: 'Pre-Carraige By:',
      //     value:
      //       (vendor?.details?.termsOfBusiness?.preCarraigeBy as string) || '',
      //     type: 'text',
      //   },
      //   {
      //     key: 'Place of Receipt by Carrier:',
      //     value:
      //       vendor?.details?.termsOfBusiness?.placeOfReceiptByCarrier || '',
      //     type: 'text',
      //   },
      // ],
    ];

    const cardBody: CardBody = {
      type: 'columns',
      body,
    };
    if (loading)
      return (
        <div className='w-full h-36 flex justify-center items-center'>
          <Recat className='h-5 inline animate-pulse mx-4' />
        </div>
      );
    return (
      <div className='w-full space-y-3'>
        <Card body={cardBody} header={cardHeader} />
      </div>
    );
  };
}

function EditPaymentTerms({
  paymentTerms,
  onSave,
}: {
  paymentTerms: Array<{
    percent: number;
    noOfDays: number;
    paymentType: string;
  }>;
  onSave: (
    paymentTerms: Array<{
      percent: number;
      noOfDays: number;
      paymentType: string;
    }>
  ) => Promise<void>;
}) {
  const [pt, setPT] = useState<
    Array<{ percent: number; noOfDays: number; paymentType: string }>
  >(paymentTerms);
  return (
    <div>
      {pt.map((x, idx) => (
        <div key={idx}>
          <span className='font-semibold'>Percent</span>{' '}
          <input
            type='number'
            className='w-20 border border-gray-100'
            defaultValue={x.percent}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].percent = parseFloat(evt.target.value);
              setPT(newPt);
            }}
          />
          % &nbsp;&nbsp;&nbsp;
          <span className='font-semibold'>PaymentType:</span>{' '}
          <input
            className='border border-gray-100'
            defaultValue={x.paymentType}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].paymentType = evt.target.value;
              setPT(newPt);
            }}
          />{' '}
          &nbsp;&nbsp;&nbsp;
          <span className='font-semibold'>No of Days:</span>
          <input
            type='number'
            className='w-20 border border-gray-100'
            defaultValue={x.noOfDays}
            onBlur={(evt) => {
              const newPt = [...pt];
              newPt[idx].noOfDays = parseFloat(evt.target.value);
              setPT(newPt);
            }}
          />{' '}
        </div>
      ))}
      <LoadingButton
        text={'Add'}
        behaviourParams={{}}
        behaviorFn={async () => {
          setPT((pt) => [...pt, { percent: 0, noOfDays: 0, paymentType: '' }]);
        }}
      />

      <div className='float-right'>
        <LoadingButton
          text={'Save'}
          behaviourParams={{}}
          behaviorFn={async () => {
            await onSave(pt);
          }}
        />
      </div>
    </div>
  );
}
