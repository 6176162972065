/* eslint-disable no-unused-vars */
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
import { renderAddMultiItemsForm } from './add-multi-items';

type Props = {
  data: {};
  onSave: any;
};

export type itemDetails = {
  itemName: IdName;
  quantity: number;
  rate: number;
  packaging: string;
  state: string;
  attachments: string;
  remark: string;
  targetDate: string;
};

// eslint-disable-next-line
export function renderAddSalesMultiItemsEnquiryForm({
  useCombinedClient,
  useCombinedItem,
}: {
  useCombinedClient: UseCombinedClient;
  useCombinedItem: UseCombinedItem;
}): (props: Props) => JSX.Element {
  return function AddAddSalesMoultiItemsEnquiryForm({
    data,
    onSave,
  }: Props): JSX.Element {
    const [clientDetails, setClientDetails] = useState<any>({});

    const [items, setItems] = useState<itemDetails[]>();

    const handleAddMultiItems = (formData: any) => {
      const newItems = [...(items || []), formData];

      setItems(newItems);
    };

    // const [items, setItems] = useState<Items>(
    //   {} as Items
    // );

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item Name' },
        { name: 'Quantity' },
        { name: 'Rate' },
        { name: 'Packaging' },
        { name: 'State' },
        { name: 'Attachments' },
        { name: 'Remark' },
        { name: 'Target Date' },
      ],
    ];

    const tableBody: TableBody =
      items?.map((item, idx) => ({
        rowData: { item: item, idx: idx },
        cells: [
          { value: item.itemName.name },
          { value: item.quantity },
          { value: item.rate },
          { value: item.packaging },
          { value: item.state },
          { value: item.attachments },
          { value: item.remark },
          { value: item.targetDate },
        ],
      })) || [];

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `Item Details ${clientDetails?.client?.name}`,
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'add items ',
            modal: {
              title: 'Add  Items',
              content: ({ onClose }) => {
                const Form = renderAddMultiItemsForm({
                  useCombinedItem,
                });
                return (
                  <Form
                    onSave={async (form) => {
                      await handleAddMultiItems(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Edit',
              show: (p) => true,
              behaviour: 'modal',
              modal: {
                title: 'Edit',
                content: ({ data, onClose }) => {
                  const item = data.item;
                  const idx = data.idx;
                  const Form2 = renderFormV2<itemDetails>({
                    formName: 'Edit',
                    fieldsData: [
                      {
                        property: 'itemName',
                        label: 'Item Name',
                        type: 'searchable-select',
                        searchOptions: {
                          useSearch: useCombinedItem,
                          onSearchValueSelect: (v) => {
                            // console.log(v);
                          },
                        },
                        required: true,
                      },
                      {
                        property: 'quantity',
                        label: 'Quantity',
                        type: 'number',
                        required: true,
                      },
                      {
                        property: 'rate',
                        label: 'Rate',
                        type: 'input',
                        required: true,
                      },
                      {
                        property: 'targetDate',
                        label: 'Target Date',
                        type: 'date',
                        required: true,
                      },
                      {
                        property: 'packaging',
                        label: 'Packaging',
                        type: 'input',
                        required: true,
                      },
                      {
                        property: 'attachments',
                        label: 'Attachments',
                        type: 'input',
                        required: true,
                      },
                      {
                        property: 'remark',
                        label: 'Remark',
                        type: 'input',
                        required: true,
                      },
                    ],
                    style: 'w-1/2',
                    initialFormState: { ...item } as any,
                    onSubmit: async (res) => {
                      const editItems = [...(items || [])];
                      try {
                        editItems[idx].itemName = {
                          id: res.itemName?.id || editItems[idx].itemName.id,
                          name:
                            res.itemName?.name || editItems[idx].itemName.name,
                        };
                        editItems[idx].quantity =
                          res.quantity || editItems[idx].quantity;
                        editItems[idx].rate = res.rate || editItems[idx].rate;
                        editItems[idx].packaging =
                          res.packaging || editItems[idx].packaging;
                        editItems[idx].attachments =
                          res.attachments || editItems[idx].attachments;
                        editItems[idx].targetDate =
                          res.targetDate || editItems[idx].targetDate;

                        setItems(editItems);
                      } catch {}

                      onClose();
                    },
                    mapTToU: (s) => {
                      return s as any;
                    },
                  });

                  return <Form2 />;
                },
              },
            },
          ]}
        />
      ),
    };

    const Form = renderFormV2({
      onSubmit: async (form) => {
        setClientDetails({
          name: form.name,
          respondBy: form.respondBy,
          expireBy: form.expireBy,
          client: form.client,
          remark: form.remark,
          status: '',
        });
        //await setClientdetails(form);

        setTimeout(() => {
          console.log('clientDetails', clientDetails);
        }, 1000);
      },
      style: 'w-1/3',
      button: {
        text: 'Next',
      },
      mapTToU: (f) => f,
      initialFormState: {
        name: '',
        respondBy: '',
        expireBy: '',
        client: '',
        remark: '',
        status: '',
      },
      fieldsData: [
        {
          property: 'client',
          label: 'Client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect: (v) => {
              // console.log(v);
            },
          },
          required: true,
        },
        {
          property: 'respondBy',
          label: 'Respond By',
          type: 'date',
          required: true,
        },
        {
          property: 'expireBy',
          label: 'Expired By',
          type: 'date',
          required: true,
        },
        {
          property: 'remark',
          label: 'Remark',
          type: 'input',
          required: true,
        },
      ],
    });
    if (!clientDetails.client || clientDetails?.client?.id === '') {
      return <Form />;
    }
    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
