import { SalesManufacturingOrder } from '../../../../models/types/sales/manufacturing-order';

export const SalesManufacturingOrderDummyData: SalesManufacturingOrder[] = [
  {
    id: 'i1',
    manufactureBy: '22-02-2022',
    itemDetails: {
      name: 'steel',
      quantity: '1000',
      uom: 'kg',
      specs: 'must be strainless',
    },
    remarks: 'none',
    attachments: ['email copy'],
    status: 'null',
  },
  {
    id: 'i2',
    manufactureBy: '22-02-2022',
    itemDetails: {
      name: 'iron',
      quantity: '5000',
      uom: 'kg',
      specs: 'purity above 92%',
    },
    remarks: 'must match specs',
    attachments: ['email copy'],
    status: 'null',
  },
];
