import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedGodown } from '../../../../../hooks/inventory/godown-inventory/use-godown';

export type GodownProfileTabProps = {
  godown: Godown;
  setGodown: (s: Godown, o?: any) => Promise<Godown>;
};
export function renderGodownProfileTab({
  useCombinedGodown,
}: {
  useCombinedGodown: UseCombinedGodown;
}): (props: GodownProfileTabProps) => JSX.Element {
  const Card = renderCardComponent();

  const Table = renderTableComponent();

  return function GodownProfileTab({
    godown,
    setGodown,
  }: GodownProfileTabProps): JSX.Element {
    const {
      getAll: getAllGodowns,
      list: childrenGodowns,
    } = useCombinedGodown();

    useEffect(() => {
      getAllGodowns({ parentGodownId: godown.id });
      // eslint-disable-next-line
    }, []);

    const cardHeader: CardHeader = {
      title: godown.name,
      actions: [
        {
          type: 'button',
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              title: 'Edit Godown',
              content: ({ onClose }) => {
                const Form = renderFormV2<any>({
                  formName: `Edit ${godown.name}`,
                  fieldsData: [
                    {
                      type: 'input',
                      property: 'name',
                      label: 'Name',
                      required: true,
                    },
                    {
                      type: 'input',
                      property: 'legacyGodownId',
                      label: 'Block-Id',
                    },
                    {
                      type: 'input',
                      property: 'assetBlockComponents',
                      label: 'Details',
                    },
                    {
                      type: 'select',
                      property: 'category',
                      label: 'Category',
                      required: true,
                      options: [
                        { text: 'Select', value: '' },
                        {
                          text: 'effluent-treatment',
                          value: 'effluent-treatment',
                        },
                        { text: 'production', value: 'production' },
                        { text: 'utility', value: 'utility' },
                        { text: 'staff', value: 'staff' },
                        { text: 'storage', value: 'storage' },
                        { text: 'open-space', value: 'open-space' },
                        { text: 'electrical', value: 'electrical' },
                        {
                          text: 'plant-n-machinery',
                          value: 'plant-n-machinery',
                        },
                        { text: 'process-headers', value: 'process-headers' },
                      ],
                    },
                    {
                      type: 'select',
                      property: 'stateOfGodown',
                      label: 'Godown State',
                      required: true,
                      options: [
                        { text: 'Select', value: '' },
                        { text: 'operational', value: 'operational' },
                        { text: 'work-in-progress', value: 'work-in-progress' },
                      ],
                    },
                  ],
                  initialFormState: {
                    name: godown.name,
                    legacyGodownId: godown.legacyGodownId,
                    assetBlockComponents:
                      godown.details?.assetBlockComponents || '',
                    category: godown.details.category,
                    stateOfGodown: godown.details.stateOfGodown,
                  },
                  mapTToU: (p: any) => {
                    const s: any = { details: {} };
                    if (p.name) {
                      s.name = p.name;
                    }
                    if (p.legacyGodownId) {
                      s.legacyGodownId = p.legacyGodownId;
                    }
                    if (p.assetBlockComponents) {
                      s.details.assetBlockComponents = p.assetBlockComponents;
                    }
                    if (p.category) {
                      s.details.category = p.category;
                    }
                    if (p.stateOfGodown) {
                      s.details.stateOfGodown = p.stateOfGodown;
                    }

                    return s;
                  },
                  onSubmit: async (s) => {
                    await setGodown({ id: godown.id, ...s });
                    onClose();
                  },
                });
                return <Form />;
              },
            },
          },
        },
      ],
    };

    const list: Array<{ name: string; value: string | number }> = [
      { name: 'Block-Id', value: godown.legacyGodownId },
      { name: 'Name', value: godown.name },
      { name: 'Owner', value: godown.details?.ownership },
      { name: 'Supervisor', value: godown.details?.inCharge },
      { name: 'Details', value: godown.details?.assetBlockComponents || '' },
      { name: 'Category', value: godown.details?.category },
      { name: 'Address', value: godown.details?.address },
      { name: 'Godown State', value: godown.details?.stateOfGodown },
    ];

    const tableHeader: TableHeader = [
      [
        { name: 'Id' },
        { name: 'Name' },
        { name: 'Asset Block Components' },
        { name: 'Owner' },
        { name: 'Supervisor' },
      ],
    ];

    const tableBody: TableBody = childrenGodowns
      ? childrenGodowns.map((r) => ({
          cells: [
            {
              value: r.legacyGodownId,
              link: `/inventory/masters/godowns/${r.id}`,
            },
            { value: r.name },
            { value: r.details?.assetBlockComponents || '' },
            { value: r.details?.ownership },
            { value: r.details?.inCharge },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div className='grid grid-cols-3 gap-4'>
          {list.map((x, idx) => (
            <div key={idx}>
              <span className='mx-1 font-semibold'>{x.name}:</span> {x.value}
            </div>
          ))}
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
        {childrenGodowns?.length ? (
          <div className='my-2'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        ) : null}
      </div>
    );
  };
}
