/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  BomEstimate,
  BomEstimateQuery,
} from '@erp_core/erp-types/dist/modules/analytics';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { BomEstimateFilterType } from '../../../../../models/interfaces/analytics/bom/estimate';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpBomEstimationRepo extends GenericHttpWithLogsRepo<
  BomEstimate,
  BomEstimateFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/purchase/boms/estimate',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getEstimate(data: BomEstimateQuery) {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const result = await http.post(this.resourceEndpoint, data);
    return result.data;
  }
}
