/* eslint-disable react-hooks/exhaustive-deps */
import { Client, ClientDetails } from '@erp_core/erp-types/dist/modules/order';
import {
  Card,
  ColumnizedContent,
  FormDataType,
  FormField,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../contexts/current';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { createClientCard } from './card/profile-card';

type CreateClientCardType = {
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  setClient: (s: Client) => Promise<Client>;
  loading: boolean;
  client: Client;
};

export function renderClientProfile({
  client,
  useFileTransfer,
  usePublicTransfer,
  setClient,
  loading,
}: CreateClientCardType): () => JSX.Element {
  return function ClientProfile(): JSX.Element {
    const { companyGroup } = useContext(CurrentContext);

    const itemProfileInitialData: Card = {
      header: {
        title: 'Client Profile',
        actions: [
          {
            type: 'button',
            button: {
              name: 'Edit',
              behaviour: 'modal',
              modal: {
                title: 'Profile',
                content: ({ onClose }) => {
                  const Form = renderFormV2<any>({
                    style: 'md:w-1/2',
                    fieldsData: getFields(),
                    initialFormState: {
                      logo: client.details?.partyProfile?.logo || '',
                      status: client.status || '',
                      clientType:
                        client.details?.partyProfile?.clientType || '',
                      currency: client.details?.partyProfile?.currency || '',
                      email: client?.details?.email || '',
                      website: client.details?.website || '',
                    },
                    onSubmit: async (data) => {
                      await setClient({
                        id: client.id,
                        leadCustomerId: client.leadCustomerId,
                        status: data.status,
                        details: {
                          email: data?.email || client?.details?.email,
                          website: data?.website || client?.details?.website,
                          partyProfile: {
                            logo:
                              data.logo || client.details.partyProfile?.logo,
                            currency:
                              data.currency ||
                              client.details.partyProfile?.currency,
                            clientType:
                              data.clientType ||
                              client.details.partyProfile?.clientType,
                          },
                        } as Partial<ClientDetails>,
                      } as Client);
                      onClose();
                    },
                    mapTToU: (b: FormDataType<any>) => b,
                    upload: {
                      useFileTransfer: usePublicTransfer,
                      config: [
                        {
                          name: 'logo',
                          path: `${companyGroup.id}/${client.id}`,
                          type: 'public',
                        },
                      ],
                    },
                  });

                  return <Form />;
                },
              },
            },
          },
        ],
      },
      body: {
        type: 'columns',
        body: [],
      },
    };
    const initialItemData = {
      profileData: itemProfileInitialData,
    };

    const ProfileCard = createClientCard({
      initialData: initialItemData.profileData,
      mapperFunction: mapClientProfileBody,
      client,
      loading,
    });
    return (
      <div className='w-full space-y-3'>
        <ProfileCard id={client.id} />
      </div>
    );
  };
}

const mapClientProfileBody = (data: Client): ColumnizedContent => [
  [
    {
      key: 'Name:',
      value: data?.name || '-',
      type: 'text',
    },
    {
      key: 'Status:',
      value: data?.status || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Client-Type:',
      value: data?.details?.partyProfile?.clientType || '-',
      type: 'text',
    },
    {
      key: 'Currency:',
      value: data?.details?.partyProfile?.currency || '-',
      type: 'text',
    },
  ],
  [
    {
      key: 'Email:',
      value: data?.details?.email || '-',
      type: 'text',
    },
    {
      key: 'Website:',
      value: data?.details?.website || '-',
      type: 'text',
    },
  ],
];

function getFields(): FormField<any>[] {
  return [
    { property: 'logo', type: 'file' },
    {
      property: 'status',
      type: 'select',
      options: [
        { text: 'Select', value: '' },
        { text: 'approved', value: 'approved' },
        { text: 'pending', value: 'pending' },
      ],
    },
    {
      property: 'currency',
      type: 'select',
      options: [
        { text: 'Select', value: '' },
        { text: 'INR', value: 'INR' },
        { text: 'USD', value: 'USD' },
      ],
    },
    {
      property: 'clientType',
      type: 'select',
      options: [
        { text: 'Select', value: '' },
        { text: 'manufacturer', value: 'manufacturer' },
        { text: 'trader', value: 'trader' },
      ],
    },
    {
      property: 'email',
      type: 'input',
    },
    {
      property: 'website',
      type: 'input',
    },
  ];
}
