/* eslint-disable no-unused-vars */

import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { createContext } from 'react';

export const UserContext = createContext<{
  user: CurrentUserType;
  setUser: (l: CurrentUserType) => void;
}>({
  user: {} as CurrentUserType,
  setUser: (l: CurrentUserType) => {},
});
