import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  OpenConfirm,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { TrashIcon } from '@heroicons/react/24/outline';
import cronstrue from 'cronstrue';

export const EmployeeWorkLogs = ({
  employeeProfile,
  setEmployee,
  openConfirm,
  actions,
  isHr,
}: {
  employeeProfile: EmployeeProfileType;
  openConfirm: OpenConfirm;
  setEmployee: (
    data: EmployeeProfileType,
    options?: any
  ) => Promise<EmployeeProfileType>;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr: boolean;
}) => {
  const Card = renderCardComponent();

  const Table = renderTableComponent();

  const deleteWorkLog = (index: number) => {
    openConfirm({
      onConfirm: async () => {
        const workLogs: { name: string; level: string }[] =
          (employeeProfile.details.workLogs as any)?.workLogs || [];
        workLogs.splice(index, 1);
        await setEmployee({
          id: employeeProfile.id,
          details: {
            workLogs: {
              ...employeeProfile.details.workLogs,
              workLogs,
            },
          } as any,
        } as EmployeeProfileType);
      },
    });
  };

  const tableBody: TableBody =
    employeeProfile?.details?.workLogs?.workLogs?.map((rl, index) => {
      return {
        cells: [
          { value: rl.name },
          { value: rl.linkId },
          {
            value: (
              <>
                <div className='font-semibold'>{rl.frequency}</div>
                <div>
                  {cronstrue.toString(rl.frequency, {
                    verbose: true,
                    tzOffset: 5.5,
                  })}
                </div>
              </>
            ),
          },
          { value: rl.status },
          {
            value: (
              <div>
                {isHr ? (
                  <TrashIcon
                    className='text-red-500 w-5 h-5'
                    onClick={() => {
                      deleteWorkLog(index);
                    }}
                  />
                ) : null}
              </div>
            ),
          },
        ],
      };
    }) || [];

  const addWorkLogSave = async (form) => {
    const final = {
      id: employeeProfile.id,
      details: {
        workLogs: {
          workLogs: [
            ...(employeeProfile?.details?.workLogs?.workLogs || []),
            form,
          ],
        },
      },
    };
    await setEmployee((final as unknown) as EmployeeProfileType);
  };

  return (
    <div>
      <Card
        auth={{ actions }}
        header={{
          title: 'Employee Log Book',
          actions: [
            {
              auth: 'HRD:EMP-PROFILE:EDIT',
              type: 'button',
              button: {
                name: 'Add Work Log',
                behaviour: 'modal',
                modal: {
                  title: 'Add Work Log',
                  content: ({ onClose }) => {
                    return (
                      <AddWorkLogForEmployeeForm
                        employeeProfile={employeeProfile}
                        save={async (form) => {
                          await addWorkLogSave(form);
                          onClose();
                        }}
                      />
                    );
                  },
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Name' },
                    { name: 'Link Id' },
                    { name: 'Frequency' },
                    { name: 'Status' },
                    { name: 'Action' },
                  ],
                ]}
                body={tableBody}
              />
            </div>
          ),
        }}
      />
    </div>
  );
};

type WorkLogType = {
  name: string;
  linkId: string;
  frequency: string;
  status: 'on' | 'off';
};

const AddWorkLogForEmployeeForm = ({
  employeeProfile,
  save,
  data,
}: {
  employeeProfile: EmployeeProfileType;
  save: (form: any) => Promise<void>;
  data?: WorkLogType;
}) => {
  const Form = renderFormV2<any>({
    fieldsData: [
      {
        property: 'name',
        label: 'Employee Work-book',
        type: 'input',
        required: true,
      },
      {
        property: 'linkId',
        label: 'Link Id',
        type: 'input',
        required: true,
      },
      {
        property: 'frequency',
        label: 'Frequency',
        type: 'input',
        required: true,
      },
      {
        property: 'status',
        label: 'Status',
        type: 'select',
        options: [
          { value: '', text: 'Select' },
          { value: 'on', text: 'On' },
          { value: 'off', text: 'Off' },
        ],
        required: true,
      },
    ],
    onSubmit: async (form) => {
      await save(form);
    },
    mapTToU: (p) => {
      return {
        name: p.name,
        linkId: p.linkId,
        frequency: p.frequency,
        status: p.status,
      };
    },
    allowUnchanged: true,
    initialFormState: {
      name: data?.name || '',
      linkId: data?.linkId || '',
      frequency: data?.frequency || '',
      status: data?.status || '',
    },
  });

  return <Form />;
};
