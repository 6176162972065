import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import { MarkerType, Position } from 'reactflow';
import {
  Edges,
  ReactFlowNodeType,
} from '../../../../../../../components/flow-chart';
import { getActionDate } from '../../../../../../../components/flow-chart/utils/get-action-date';
import { getNodeStyle } from '../../../../../../../components/flow-chart/utils/get-node-style';

export function getPurchaseOrderFlowGraph({
  data,
}: {
  data: PurchaseOrder;
}): { nodes: Array<ReactFlowNodeType>; edges: Array<Edges> } {
  if (!data.details) return { nodes: [], edges: [] };
  return {
    nodes: [
      {
        id: '1',
        data: {
          label: (
            <div>
              <strong>PO Opened</strong>
              <p>{getActionDate(data, 'raised-po')}</p>
            </div>
          ),
        },
        position: { x: 0, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('OPEN', data.status),
      },
      {
        id: '2',
        data: {
          label: (
            <div>
              <strong>PO Closed</strong>
              <p>{getActionDate(data, 'closed')}</p>
            </div>
          ),
        },
        position: { x: 200, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('CLOSED', data.status),
      },
    ],
    edges: [
      {
        id: 'e1-2',
        source: '1',
        target: '2',
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
    ],
  };
}
