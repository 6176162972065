import { HazardClass } from '@erp_core/erp-types/dist/modules/inventory';
import {
  PageNotFound,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedHazardGhsClass } from '../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedPrecautionaryStatement } from '../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-precautionary-statement';
import { UseCombinedHazardStatement } from '../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';
import { renderEditGhsClassPrecautions } from './forms/edit-precautions';
import { renderEditGhsClassStatements } from './forms/edit-statements';

type RenderHazardClassPageProps = {
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
  useCombinedHazardStatement: UseCombinedHazardStatement;
  useCombinedPrecautionaryStatement: UseCombinedPrecautionaryStatement;
};

export const renderHazardClassPage = ({
  useCombinedHazardGhsClass,
  useCombinedPrecautionaryStatement,
  useCombinedHazardStatement,
}: RenderHazardClassPageProps) => {
  return () => {
    const {
      resource: hazardClassData,
      syncSet: set,
      get: getHazardClassData,
    } = useCombinedHazardGhsClass();

    const { id } = useParams();

    useEffect(() => {
      if (id) {
        getHazardClassData(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!hazardClassData) {
      return (
        <div className='w-full h-full flex justify-center items-center'>
          <PageNotFound />
        </div>
      );
    }

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [{ name: 'Statement Code' }, { name: 'Statement' }],
    ];

    const statementsTableBody: TableBody =
      (hazardClassData as any).statements?.map((h) => {
        return {
          cells: [{ value: h.name }, { value: h.statement }],
        };
      }) || [];

    const precautionsTableBody: TableBody =
      (hazardClassData as any).precautions?.map((h) => {
        return {
          cells: [{ value: h.name }, { value: h.statement }],
        };
      }) || [];

    const Card = renderCardComponent();

    return (
      <div>
        <div className='mb-2 text-sm flex space-x-4 p-3 border w-full'>
          <div className='font-bold'>
            GHS Class Name:{' '}
            <span className='font-semibold'> {hazardClassData.name}</span>
          </div>
          <div>|</div>
          <div className='font-bold'>
            GHS Chapter No:{' '}
            <span className='font-semibold'> {hazardClassData.chapterNo}</span>
          </div>
          <div>|</div>
          <div className='font-bold'>
            Category:{' '}
            <span className='font-semibold'>
              {' '}
              {hazardClassData.categoryName}
            </span>
          </div>
          <div>|</div>
          <div className='font-bold'>
            Type :{' '}
            <span className='font-semibold'> {hazardClassData.type}</span>
          </div>
        </div>
        <div className='mb-2 text-sm flex space-x-4 p-3 border w-full'>
          <div className='font-bold'>
            Criteria:{' '}
            <span className='font-semibold'>
              {' '}
              {hazardClassData.details?.criteria}
            </span>
          </div>
        </div>

        <Card
          header={{
            title: 'Hazard statements',
            subheading: (
              <span className='font-bold'>
                For {hazardClassData.chapterNo || '""'}-
                {hazardClassData.categoryName || '""'}
              </span>
            ),
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Edit Statements',
                  behaviour: 'modal',
                  modal: {
                    title: `Edit Hazard Statements for ${hazardClassData.name}`,
                    content: ({ onClose }) => {
                      const onSave = async (d: Partial<HazardClass>) => {
                        d['id'] = hazardClassData.id;
                        await set(d as HazardClass);
                        onClose();
                      };
                      const Form = renderEditGhsClassStatements({
                        onSave,
                        useCombinedHazardStatement,
                      });
                      return <Form hazardClassData={hazardClassData} />;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={tableHeader} body={statementsTableBody} />,
          }}
        />

        <Card
          header={{
            title: 'Hazard Precautions',
            subheading: (
              <span className='font-bold'>
                For {hazardClassData.chapterNo || '""'}-
                {hazardClassData.categoryName || '""'}
              </span>
            ),
            actions: [
              {
                type: 'button',
                button: {
                  name: 'Edit Precautions',
                  behaviour: 'modal',
                  modal: {
                    title: `Edit Hazard Precautions for ${hazardClassData.name}`,
                    content: ({ onClose }) => {
                      const onSave = async (d: Partial<HazardClass>) => {
                        d['id'] = hazardClassData.id;
                        await set(d as HazardClass);
                        onClose(); // Closing Modal
                      };

                      const Form = renderEditGhsClassPrecautions({
                        onSave,
                        useCombinedPrecautionaryStatement,
                      });

                      return <Form hazardClassData={hazardClassData} />;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: <Table header={tableHeader} body={precautionsTableBody} />,
          }}
        />
      </div>
    );
  };
};
