import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { nanoid } from 'nanoid';
import { UseGetMachineSchedule } from '../../../../../hooks/planning/production/machine-schedule';
import { createMachineProfile } from './machine-profile';
import { renderMachineSchedule } from './machine-schedule';

type RenderMachineProps = {
  hooks: {
    useGetMachineSchedule: UseGetMachineSchedule;
  };
};

export function renderMachine({
  hooks: { useGetMachineSchedule },
}: RenderMachineProps): () => JSX.Element {
  return function MachineProfile(): JSX.Element {
    const MachineSchedule = renderMachineSchedule({ useGetMachineSchedule });

    const MachineProfile = createMachineProfile({
      hooks: { useGetMachineSchedule },
    });

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      ['Machine Schedule', MachineSchedule],
      ['Machine Profile', MachineProfile],
    ]);

    const Tabs = renderTabsComponent();

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Machine Page',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
