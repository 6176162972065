/* eslint-disable no-unused-vars */
import { ModuleType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedModuleAdmin } from '../../../hooks/admin/module-admin/use-modules-admin';
import { shortForm } from '../../../utils/strings';

export type RenderEditModuleFormProps = {
  useCombinedModuleAdmin: UseCombinedModuleAdmin;
};

export function renderEditModuleForm({
  useCombinedModuleAdmin,
}: RenderEditModuleFormProps): (ModuleType) => JSX.Element {
  return function EditModuleForm({
    r,
    onClose,
  }: {
    r: ModuleType;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setModuleAdmin } = useCombinedModuleAdmin();

    const saveModule = async (form) => {
      if (r.id) form['id'] = r.id;
      if (form.shortName) form['shortName'] = shortForm(form.shortName);
      await setModuleAdmin(form);
      onClose();
    };

    const formProps: FormProps<Partial<ModuleType>> = {
      fieldsData: [
        { property: 'name', label: 'Module Name', type: 'input' },
        { property: 'shortName', label: 'Module Short Name', type: 'input' },
        { property: 'description', label: 'Module Description', type: 'input' },
      ],
      initialFormState: {
        name: r.name,
        shortName: r.shortName,
        description: r.description,
      },
      mapTToU: mapPFDToP,
      onSubmit: saveModule,
    };

    const Form = renderFormV2<Partial<ModuleType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ModuleType>>
    ): Partial<ModuleType> {
      return {
        name: b.name as string,
        shortName: b.shortName as string,
        description: b.description as string,
      };
    }
  };
}
