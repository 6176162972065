import { ReactNode, useMemo } from 'react';
import { UserContext } from '../../contexts/user';
import { UseCurrentUser } from '../../hooks/admin/user-admin/use-current-user';

export type UserProviderProps = {
  children: ReactNode;
  useCurrentUser: UseCurrentUser;
};

export function UserProvider({
  children,
  useCurrentUser,
}: UserProviderProps): JSX.Element {
  const { data: currentUser, setCurrentUser } = useCurrentUser();

  const value = useMemo(
    () => ({
      user: currentUser,
      setUser: setCurrentUser,
    }),
    // eslint-disable-next-line
    [currentUser]
  );
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
