import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { CurrentContext } from '../../contexts/current';
import { UseCurrentCompany } from '../../hooks/admin/company-admin/use-current-company';
import { UseCurrentCompanyGroup } from '../../hooks/admin/company-group-admin/use-current-company-group';
import { UseCombinedCompanyGroupSetting } from '../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCurrentLocation } from '../../hooks/admin/location-admin/use-current-location';

export type CurrentContextProviderProps = {
  children: ReactNode;
  useCurrentLocation: UseCurrentLocation;
  useCurrentCompany: UseCurrentCompany;
  useCurrentCompanyGroup: UseCurrentCompanyGroup;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

export function CurrentContextProvider({
  children,
  useCurrentLocation,
  useCurrentCompany,
  useCurrentCompanyGroup,
  useCombinedCompanyGroupSetting,
}: CurrentContextProviderProps): JSX.Element {
  const { data: currentLocation, setCurrentLocation } = useCurrentLocation();
  const { data: currentCompany, setCurrentCompany } = useCurrentCompany();
  const {
    getListSync: getCompanyGroupSettings,
  } = useCombinedCompanyGroupSetting();
  const [currentCGS, setCurrentCGS] = useState<CompanyGroupSetting>(
    {} as CompanyGroupSetting
  );
  const {
    currentCompanyGroup,
    setCompanyGroup: setCurrentCompanyGroup,
  } = useCurrentCompanyGroup();

  const value = useMemo(
    () => ({
      company: currentCompany,
      location: currentLocation,
      companyGroup: currentCompanyGroup,
      setCompany: setCurrentCompany,
      setLocation: setCurrentLocation,
      setCompanyGroup: setCurrentCompanyGroup,
      cgSetting: currentCGS,
    }),
    // eslint-disable-next-line
    [currentLocation, currentCompanyGroup, currentCompany, currentCGS]
  );

  useEffect(() => {
    if (currentCompanyGroup?.id) {
      // const cgs = await
      getCompanyGroupSettings().then((cgs) => {
        if (
          cgs.length &&
          cgs.find((c) => c.companyGroup.id === currentCompanyGroup.id)
        ) {
          const cg = cgs.find(
            (c) => c.companyGroup.id === currentCompanyGroup.id
          );
          if (cg) {
            setCurrentCGS(cg);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [currentCompanyGroup?.id]);

  return (
    <CurrentContext.Provider value={value}>{children}</CurrentContext.Provider>
  );
}
