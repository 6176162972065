/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GodownFilterType } from '../../../../../models/types/inventory/godown';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpGodownRepo extends GenericHttpWithLogsRepo<
  Godown,
  GodownFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/godowns',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
