import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useNavigate } from 'react-router';
import { UseSetPassword } from '../../../hooks/set-password/use-set-password';
import { Landing } from '../components/landing';
import { Logo } from '../components/logo';

export type SetPswd = {
  email: string;
  otp: string;
  newPassword: string;
  password: string;
  companyGroupId: string;
};

export type RenderSetPasswordProps = {
  useSetPassword: UseSetPassword;
};

// eslint-disable-next-line no-unused-vars
export function renderSetPassword({
  useSetPassword,
}: RenderSetPasswordProps): () => JSX.Element {
  return function SetPassword(): JSX.Element {
    const { setPassword } = useSetPassword();
    const navigate = useNavigate();

    const onSave = async (form) => {
      setPassword(form);
    };

    const handleLoginPswdPath = () => {
      if (!history) return;
      navigate('/login');
    };

    let url = window.location.search;
    url = url.replace('?', '');

    let searchParams = new URLSearchParams(url);
    let email = searchParams.get('email');
    let otp = searchParams.get('otp');
    let companyGroupId = searchParams.get('companyGroupId');

    const formProps: FormProps<Partial<SetPswd>> = {
      fieldsData: [
        {
          property: 'newPassword',
          label: 'New Password',
          type: 'password',
          required: true,
        },
        {
          property: 'password',
          label: 'Verify Password',
          type: 'password',
          required: true,
        },
      ],
      initialFormState: {
        newPassword: '',
        password: '',
      },
      mapTToU: mapPFDToP,
      style: '',
      button: {
        style: 'justify-center',
      },
      onSubmit: onSave,
    };

    const Form = renderFormV2<Partial<SetPswd>>(formProps);

    return (
      <div className='h-screen flex flex-row flex-wrap-wrap justify-center border-2'>
        <Logo />
        <div className='flex flex-col justify-center w-full lg:w-1/2 p-5 lg:px-32'>
          <div className='font-medium text-xl text-center'>Set Password</div>
          <Form />
          <div
            className='font-md text-center cursor-pointer underline text-black-600'
            onClick={handleLoginPswdPath}
          >
            Login
          </div>
        </div>
        <Landing />
      </div>
    );

    function mapPFDToP(b: FormDataType<Partial<SetPswd>>): SetPswd {
      return {
        password: b.password as string,
        email: email as string,
        otp: otp as string,
        companyGroupId: companyGroupId as string,
      } as SetPswd;
    }
  };
}
