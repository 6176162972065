import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { BankAccountDetail } from '@erp_core/erp-types/dist/types/modules/admin/company';
import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  CardBody,
  CardHeader,
  downloadFile,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { sleep } from '@erp_core/erp-utils';
import { BanknotesIcon, UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../contexts/user';
import { UseCombinedCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UseUserAuthorization } from '../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedSalaryAdvance } from '../../../hooks/hrd/salary-advance/use-salary-advance';
import { UserRendererInterface } from '../../common/fragments/user';
import { findParamType, formatParam } from '../salary/utils/generate-salary';
import { ExtSal, generateReport } from '../salary/utils/statutory-reports';
import { SalaryAdvanceApprovalFormComponent } from './component/approval-salary-advance';

export type RenderSalaryAdvanceProps = {
  useCombinedSalaryAdvance: UseCombinedSalaryAdvance;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
};

export function renderSalaryAdvances({
  useCombinedSalaryAdvance,
  useCombinedEmployeeProfile,
  useUserAuthorization,
  userRendererService,
  useCombinedCompanyAdmin,
}: RenderSalaryAdvanceProps): () => JSX.Element {
  return function SalaryAdvances(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const { get: getAllowedActions } = useUserAuthorization();
    const {
      getAll: getEmployees,
      list: employees,
    } = useCombinedEmployeeProfile();
    const { getAll: getCompanies, list: companies } = useCombinedCompanyAdmin();
    const { syncSet: setSalaryAdvance } = useCombinedSalaryAdvance();

    const {
      list: salaryAdvances,
      getAll: getSalaryAdvances,
    } = useCombinedSalaryAdvance();

    useEffect(() => {
      getEmployees({ crossGroup: 'true' });
      getCompanies();
      // eslint-disable-next-line
    }, []);

    const [date, setDate] = useState(
      localStorage.getItem('advance-month') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      getSalaryAdvances({ date });
      localStorage.setItem('advance-month', date);
      // eslint-disable-next-line
    }, [date]);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Salary Advances',
      icon: (
        <BanknotesIcon className='w-8 h-8 inline stroke-indigo-900 self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
      ],
      menu: {
        actions: [
          {
            show: () => true,
            behaviour: 'click',
            name: 'Download Salary Advance Report',
            onClick: async () => {
              const filtered =
                salaryAdvances?.filter((x) => x.status === 'approved') || [];
              const sortByCompany = _.groupBy(filtered, 'company.id');
              console.log(sortByCompany);

              for (const key in sortByCompany) {
                console.log(key);
                await sleep(1000);
                const comp = companies?.find((x) => x.id === key);
                if (comp) {
                  const repData: Array<{
                    salary: ExtSal;
                    employee: EmployeeProfileType;
                    bank: BankAccountDetail;
                    company: Company;
                    category?: string;
                  }> = [];
                  for (const entry of sortByCompany[key]) {
                    const emp = employees?.find(
                      (x) => x.id === entry.employee.id
                    );
                    if (emp) {
                      repData.push({
                        salary: {
                          status: 'approved',
                          bankAccountName: 'DBS INR',
                          bankAccountNumber:
                            comp.details.bankAccounts.find((y) => y.account)
                              ?.account || '',
                          details: {
                            reason: entry.reason,
                            salaryParams: {
                              earning: {
                                employeeTakeHome: entry.details.relasedAmount,
                              },
                            },
                          },
                          selectedSalaryParam: {
                            details: {
                              inner: {
                                employeeTakeHome: 'number',
                              },
                            },
                          } as any,
                        } as any,
                        employee: emp,
                        company: comp,
                        bank:
                          comp.details.bankAccounts.find((y) => y.account) ||
                          ({} as any),
                      });
                    }
                  }
                  let momentUnix = moment().unix();
                  const report = await generateReport(
                    {
                      type: 'bank-report',
                      owner: 'DBS INR',
                      fileType: 'text/plain',
                      ext: 'txt',
                      lineBreak: '\n',
                      isApplicable: (data) => {
                        if (
                          data.find(
                            (x) =>
                              [
                                'approved',
                                'sent-for-release',
                                'released',
                              ].includes(x.salary?.status) &&
                              x.salary.bankAccountName === 'DBS INR'
                          )
                        ) {
                          return true;
                        }

                        return false;
                      },
                      fileName: () =>
                        `${comp.shortName}-Advance-Salary-${date}-DBS-report.txt`,
                      details: {
                        type: 'detailed',
                        separator: 'comma',
                        rows: [
                          {
                            type: 'header',
                            values: [
                              { value: async () => 'HEADER' },
                              {
                                value: async () => {
                                  return moment().format('DDMMYYYY');
                                },
                              },
                              {
                                value: async (data) => {
                                  return data[0].bank?.accountId || '';
                                },
                              },
                              {
                                value: async (data) => {
                                  const emp = data[0]
                                    .employee as EmployeeProfileType;
                                  return emp.company.name;
                                },
                              },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                            ],
                          },
                          {
                            type: 'employee-details',
                            values: [
                              {
                                value: async (data) => {
                                  return 'PAYMENT';
                                },
                              },
                              {
                                value: async (data) => {
                                  return 'SAL';
                                },
                              }, // Maybe different for a different module
                              {
                                value: async (data) => {
                                  return data.salary?.bankAccountNumber || '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return 'INR';
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return 'INR';
                                },
                              },
                              {
                                value: async (data) => {
                                  return `${momentUnix.toString().slice(-5)}`;
                                },
                              },
                              {
                                value: async (data) => {
                                  return moment().format('DDMMYYYY');
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  const emp = data.employee as EmployeeProfileType;
                                  return emp.name;
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  return '';
                                },
                              },
                              {
                                value: async (data) => {
                                  const accountNo =
                                    (data.employee?.details?.bankDetails &&
                                      _.find(
                                        data.employee?.details?.bankDetails,
                                        (b) => b.active === 'yes'
                                      )?.accountNo) ||
                                    '';

                                  return accountNo;
                                },
                              },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              {
                                value: async (data) => {
                                  const ifsc =
                                    (data.employee?.details?.bankDetails &&
                                      _.find(
                                        data.employee?.details?.bankDetails,
                                        (b) => b.active === 'yes'
                                      )?.ifsc) ||
                                    '';
                                  return ifsc;
                                },
                              },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              {
                                value: async (data) => {
                                  // In case oldSalaryId is present then it is recaluclated salary
                                  const value =
                                    data.salary?.details?.oldSalaryId &&
                                    data.salary?.details?.amount &&
                                    data.salary?.details?.action === 'disburse'
                                      ? data.salary.details.amount
                                      : data.salary?.details?.salaryParams
                                          ?.earning?.employeeTakeHome;

                                  const earning = formatParam({
                                    value: value,
                                    metric: 'number',
                                    scale: findParamType(
                                      'employeeTakeHome',
                                      data.salary.selectedSalaryParam.details
                                        .inner,
                                      'scale'
                                    ),
                                    plain: true,
                                  });

                                  return earning;
                                },
                              },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '22' }, // ALways this value (maybe different for other module)
                              { value: async () => '' },
                              { value: async () => '' },
                              {
                                value: async (data) =>
                                  data?.salary?.details?.reason ||
                                  'Salary Advance',
                              }, // TODO include month year
                            ],
                          },
                          {
                            type: 'header',
                            values: [
                              { value: async () => 'TRAILER' },
                              {
                                value: async (list) => {
                                  const count = list.filter(
                                    (x) =>
                                      [
                                        'approved',
                                        'sent-for-release',
                                        'released',
                                      ].includes(x.salary?.status) &&
                                      x.salary.bankAccountName === 'DBS INR'
                                  );
                                  return `${count.length}`;
                                },
                              },
                              {
                                value: async (list) => {
                                  const count = list.filter(
                                    (x) =>
                                      [
                                        'approved',
                                        'sent-for-release',
                                        'released',
                                      ].includes(x.salary?.status) &&
                                      x.salary.bankAccountName === 'DBS INR'
                                  );
                                  let value = 0;
                                  count.forEach((data) => {
                                    // In case oldSalaryId is present then it is recaluclated salary
                                    const earningValue =
                                      data.salary?.details?.oldSalaryId &&
                                      data.salary?.details?.amount &&
                                      data.salary?.details?.action ===
                                        'disburse'
                                        ? data.salary.details.amount
                                        : data.salary?.details?.salaryParams
                                            ?.earning?.employeeTakeHome;
                                    const earning = parseFloat(
                                      formatParam({
                                        value: earningValue,
                                        metric: 'number',
                                        scale: findParamType(
                                          'employeeTakeHome',
                                          data.salary.selectedSalaryParam
                                            .details.inner,
                                          'scale'
                                        ),
                                        plain: true,
                                      })
                                    );

                                    value += earning;
                                  });
                                  return `${value}`;
                                },
                              },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                              { value: async () => '' },
                            ],
                          },
                        ],
                        recordEligibility: (data) => {
                          return [
                            'approved',
                            'sent-for-release',
                            'released',
                          ].includes(data.salary?.status);
                        },
                      },
                    },
                    repData
                  );

                  console.log(report);
                  downloadFile({
                    fileType: report.fileType,
                    fileName: `${report.name}`,
                    result: report.data,
                  });
                }
              }
            },
          },
        ],
      },
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Requested Date' },
        { name: 'Reason' },
        { name: 'Eligible Amount' },
        { name: 'Requested Amount' },
        { name: 'Released Date' },
        { name: 'Released Amount' },
        { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const ApprovalForm = SalaryAdvanceApprovalFormComponent();

    const approveRequest = async (data: SalaryAdvanceType) => {
      try {
        const finalData = {
          ...data,
          authorizer: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setSalaryAdvance(finalData);
        toast('Data updated sucessfully');
        getSalaryAdvances({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableData: TableBody =
      salaryAdvances?.map((r, idx) => ({
        rowData: {
          salaryAdvance: r,
        },
        cells: [
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.employee?.id}
                name={r.employee.name}
              />
            ),
          },
          { value: r.date },
          { value: r.reason },
          { value: r.feasibleAmount },
          { value: r.amount },
          { value: r.details?.releasedDate || '-' },
          { value: r.details?.relasedAmount || '-' },
          { value: r.status },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              auth={{ actions: getAllowedActions().actions }}
              actions={[
                {
                  name: 'Approval',
                  auth: 'UI:APR-SAL-ADV:VIEW',
                  behaviour: 'modal',
                  show: ({ salaryAdvance }) =>
                    salaryAdvance.status === 'pending',
                  modal: {
                    title: 'Approval',
                    content: ({ data: { salaryAdvance }, onClose }) => {
                      return (
                        <ApprovalForm
                          data={salaryAdvance}
                          onSave={async (form) => {
                            await approveRequest(form);
                            onClose();
                          }}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
