import { ResetOtpRequest } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { UseResetPassword } from '../../../hooks/reset-password/use-reset-password';
import { Landing } from '../components/landing';
import { Logo } from '../components/logo';

export type RenderResetPasswordProps = {
  useResetPassword: UseResetPassword;
};

// eslint-disable-next-line no-unused-vars
export function renderResetPassword({
  useResetPassword,
}: RenderResetPasswordProps): () => JSX.Element {
  return function ResetPassword(): JSX.Element {
    const { reset } = useResetPassword();
    const navigate = useNavigate();
    const onSave = async (form) => {
      reset(form);
    };

    const formProps: FormProps<ResetOtpRequest> = {
      fieldsData: [
        { property: 'domain', label: 'Domain', type: 'input', required: true },
        { property: 'email', label: 'Email', type: 'email', required: true },
      ],
      initialFormState: {
        email: '',
        domain: '',
      },
      mapTToU: mapPFDToP,
      style: '',
      button: {
        style: 'justify-center',
        text: 'Reset Password',
      },
      onSubmit: onSave,
    };

    const Form = renderFormV2<ResetOtpRequest>(formProps);

    return (
      <div className='h-screen flex flex-row flex-wrap-wrap justify-center border-2'>
        <Logo />
        <div className='flex flex-col justify-center w-full lg:w-1/2 p-5 lg:px-32'>
          <div className='flex flex-row font-medium text-sm text-left'>
            <ArrowLeftIcon
              className='cursor-pointer text-black-500 w-4'
              onClick={() => {
                navigate('/login');
              }}
            />
            <label className='p-2'>Back</label>
          </div>
          <div className='font-medium text-xl text-center'>Reset Password</div>
          <div>
            <p className='font-medium text-sm text-center text-gray-500'>
              Enter the email associated with account and we’ll send an email
              with instructions to reset password.
            </p>
          </div>
          <br />
          <Form />
          <div></div>
        </div>
        <Landing />
      </div>
    );

    function mapPFDToP(b: FormDataType<ResetOtpRequest>): ResetOtpRequest {
      return {
        domain: b.domain as string,
        email: b.email as string,
      };
    }
  };
}
