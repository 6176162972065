import moment from 'moment';

export function getTemplate() {
  return {
    time(event) {
      return `<span style="color:#000;">
        ${moment(event.start.d.d).format(
          'HH:mm'
        )}</span><span style="color:#fff; background-color: ${
        event.backgroundColor
      };">${event.title}</span>`;
    },
    milestone(event) {
      return `<span style="color:#000;">
        ${moment(event.start.d.d).format(
          'HH:mm'
        )}</span><span style="color:#fff; background-color: ${
        event.backgroundColor
      };">${event.title}</span>`;
    },
    milestoneTitle() {
      return 'Milestone';
    },
    allday(event) {
      return `${event.title}<i class="fa fa-refresh"></i>`;
    },
    alldayTitle() {
      return 'All Day';
    },
  };
}

// export const calendars = [
//   {
//     id: 'default',
//     name: 'Default Shift',
//     backgroundColor: '#651b1c',
//     borderColor: '#ff00ff',
//   },
//   {
//     id: 'DA8C779A-258F-4082-B70F-332B81005A48',
//     name: 'First Shift',
//     backgroundColor: '#991b1b',
//     borderColor: '#ff0000',
//   },
//   {
//     id: 'CA8C779A-258F-4082-B70F-332B81005A48',
//     name: 'First Shift',
//     backgroundColor: '#991b1b',
//     borderColor: '#ff0000',
//   },
//   {
//     id: 'EA8C779A-258F-4082-B70F-332B81005A48',
//     name: 'Second Shift',
//     backgroundColor: '#166534',
//     borderColor: '#00ff00',
//   },
//   {
//     id: '8D2B695B-ADFD-4ECF-A86D-411E9F0D5385',
//     name: 'Second Shift',
//     backgroundColor: '#166534',
//     borderColor: '#00ff00',
//   },
//   {
//     id: 'FA8C779A-258F-4082-B70F-332B81005A48',
//     name: 'Third Shift',
//     backgroundColor: '#1e40af',
//     borderColor: '#0000ff',
//   },
//   {
//     id: '5BAF7538-0A50-40A3-82EC-8CECF743254D',
//     name: 'Third Shift',
//     backgroundColor: '#1e40af',
//     borderColor: '#0000ff',
//   },
//   {
//     id: 'FB8C779A-258F-4082-B70F-332B81005A48',
//     name: 'General Shift',
//     backgroundColor: '#ff00ff',
//     borderColor: '#ff00ff',
//   },
// ];

export function shiftColorDecider(name: string) {
  if (name.toLowerCase().includes('general')) {
    return {
      backgroundColor: '#ff00ff',
      borderColor: '#ff00ff',
      start: 'T09:00:00+05:30',
      end: 'T18:00:00+05:30',
    };
  }

  if (name.toLowerCase().includes('first')) {
    return {
      backgroundColor: '#991b1b',
      borderColor: '#ff0000',
      start: 'T07:00:00+05:30',
      end: 'T15:00:00+05:30',
    };
  }

  if (name.toLowerCase().includes('second')) {
    return {
      backgroundColor: '#166534',
      borderColor: '#00ff00',
      start: 'T15:00:00+05:30',
      end: 'T23:00:00+05:30',
    };
  }

  if (name.toLowerCase().includes('third')) {
    return {
      backgroundColor: '#1e40af',
      borderColor: '#0000ff',
      start: 'T23:00:00+05:30',
      end: 'T23:59:59+05:30',
    };
  }

  return {
    backgroundColor: '#651b1c',
    borderColor: '#ff00ff',
    start: 'T09:00:00+05:30',
    end: 'T18:00:00+05:30',
  };
}
