import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Client } from '@erp_core/erp-types/dist/modules/order';
import {
  Card,
  ColumnizedContent,
  renderCardComponent,
} from '@erp_core/erp-ui-components';

type CreateClientCardType = {
  initialData: Card;
  client: Client;
  mapperFunction: (data: any) => ColumnizedContent;
  loading: boolean;
};
export function createClientCard({
  initialData,
  client,
  mapperFunction,
  loading,
}: CreateClientCardType) {
  return function ({ id }: { id: string }) {
    const CardComponent = renderCardComponent();

    if (loading)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: (
              <div className=''>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ),
          }}
          header={initialData.header}
        />
      );

    if (!client)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: (
              <div className=''>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ),
          }}
          header={initialData.header}
        />
      );

    if (!client)
      return (
        <CardComponent
          body={{
            type: 'jsx-component',
            body: <div className=''>No data present</div>,
          }}
          header={initialData.header}
        />
      );

    return (
      <CardComponent
        body={{ type: 'columns', body: mapperFunction(client) }}
        header={initialData.header}
      />
    );
  };
}
