import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  ChangeAssesmentFilterType,
  ChangeAssesmentType,
} from '../../../../../models/types/quality-assurance/change-assesment';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class ChangeAssesmentHttpRepo extends GenericHttpWithLogsRepo<
  ChangeAssesmentType,
  ChangeAssesmentFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/change-assesment',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
