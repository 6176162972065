import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

// eslint-disable-next-line

export type ChangeReqFormType = {
  status: string;
  commentsOfQA: string;
};

export function renderEvaluationQAApprovalForm() {
  return function EvaluationQAApprovalForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    isEdit?: boolean;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<ChangeReqFormType> = {
      fieldsData: [
        {
          groupName: 'QA-Approval',
          property: 'commentsOfQA',
          type: 'text-area',
          label: 'Comments',
          dependentOn: (formData) => {
            return data?.status === 'evaluation';
          },
          rows: 3,
        },
        {
          groupName: 'QA-Approval',
          property: 'status',
          type: 'select',
          label: 'Status',
          options: [
            { value: 'select', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'rejected', text: 'rejected' },
          ],
          dependentOn: (formData) => {
            return data?.status === 'evaluation';
          },
        },
      ],
      initialFormState: {
        commentsOfQA: data?.details.commentsOfQAEvaluation || '',
        status: data?.status || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          details: {
            ...data?.details,
          },
          status:
            f.status === 'approved'
              ? 'evaluation-accepted'
              : 'evaluation-rejected',
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<ChangeReqFormType>): ChangeReqFormType {
      return (b as unknown) as ChangeReqFormType;
    }
  };
}
