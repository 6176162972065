import {
  GetDisbursementRequest,
  LoanInterface,
  PostDisbursementResp,
} from 'models/interfaces/loans/loan';
import { useState } from 'react';

export type UseDisbursementPostData = () => {
  setDisbursementData: (p: GetDisbursementRequest) => void;
  res: PostDisbursementResp;
};

export interface UseDisbursementPostOptions {
  useDisbursementValuePostService: () => LoanInterface;
}

export function createUseDisbursementPostValue({
  useDisbursementValuePostService,
}: UseDisbursementPostOptions): UseDisbursementPostData {
  return () => {
    const loanService = useDisbursementValuePostService();
    const [res, setData] = useState<any>();

    const disbursementValPostApiCall = async (p: GetDisbursementRequest) => {
      try {
        const resp = await loanService.postDisbursement(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      setDisbursementData: (p: GetDisbursementRequest) => {
        disbursementValPostApiCall(p);
      },
      res,
    };
  };
}
