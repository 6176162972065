import {
  Company,
  CompanyGroupSetting,
  CurrentUserType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
  Punch,
} from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';
import {
  ManualPunchesEmployeeType,
  renderManualPunchesEmployeeForm,
} from '../../../forms/manual-punches-for-employee';
// import { addPunch } from '../../../util/add-manual-punch';
import { UseCombinedEmployeeProfile } from '../../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { AttendanceInterface } from '../../../../../../models/interfaces/hrd/attendance';

export function renderAddManualPunchForm({
  useCombinedEmployeeProfile,
  currentCompany,
  companyGroupSetting,
  currentUser,
  setAttendance,
  getData,
  attendanceService,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  currentCompany: Company;
  companyGroupSetting?: CompanyGroupSetting;
  currentUser: CurrentUserType;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  attendanceService: AttendanceInterface;
}): (p: { data: any; onClose: () => void }) => JSX.Element {
  return function AddManualPunchButton({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const Form = renderManualPunchesEmployeeForm({
      useCombinedEmployeeProfile,
      defaultData: {
        employee: attendance.employeeProfile,
        date: attendance.date,
      },
    });
    return (
      <Form
        currentCompany={currentCompany}
        companyGroupSetting={companyGroupSetting}
        onSave={async (d) => {
          const mp = d as ManualPunchesEmployeeType;
          const timeStamp = moment(mp.date).utc().format();
          const punch: Punch = {
            timeStamp,
            location: mp.location,
            type: 'manual',
            createdBy: mp.createdBy
              ? mp.createdBy
              : { id: currentUser.id, name: currentUser.name },
            action: mp.type,
            remark: mp.reason,
          };
          await attendanceService.addManualPunch({
            punch,
            employee: attendance.employee,
            date: attendance.date,
            employeeCompany: attendance.employeeProfile?.company?.id,
          });
          onClose();
          getData();
          // addPunch({
          //   attendance,
          //   mp: d as ManualPunchesEmployeeType,
          //   currentUser,
          //   setAttendance,
          //   getData,
          //   date: attendance.date,
          //   onClose: onClose,
          // })
        }}
      />
    );
  };
}
