/* eslint-disable no-unused-vars */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import React from 'react';

type Fileds = {};

// eslint-disable-next-line
export function renderEnquiryRequestForm(): (props: any) => JSX.Element {
  return function EnquiryRequestForm({
    data,
    onSave,
  }: {
    data: {};
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<Fileds> = {
      fieldsData: [],
      initialFormState: {},
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };
    const NewForm = renderFormV2<Fileds>(formProps);

    return <NewForm />;

    function mapPFDToP(b: FormDataType<Fileds>): Fileds {
      return {};
    }
  };
}
