import React, { useCallback, useState } from 'react';
import { renderVersionUpdater } from './version-updater';

/// Example to follow for implementing memoization
const Updater = React.memo(renderVersionUpdater());
export function renderVersion(): () => JSX.Element {
  return function Version(): JSX.Element {
    const [version, setVersion] = useState<string>('1');
    const [anotherVersion, setAnotherVersion] = useState<string>('1');
    console.log('rendering version');

    const updateVersion = useCallback((value: string) => {
      if (parseInt(value) % 5 === 0) {
        setAnotherVersion(value);
      } else {
        setVersion(value);
      }
    }, []);

    return (
      <div>
        Version : {version}
        <div>Another Version {anotherVersion}</div>
        <div>
          <Updater version={version} updateVersion={updateVersion} />
        </div>
      </div>
    );
  };
}
