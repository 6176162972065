/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { UseCombinedLeadEnquiry } from '../../../hooks/order/sales/lead-enquiry/use-lead-enquiry';
import { renderAddLeadEnquiryForm } from './forms/lead-enquiry';

function renderSalesLeadEnquiryDetails({
  useCombinedLeadEnquiry,
  useCombinedItem,
  useCombinedClient,
}: {
  useCombinedLeadEnquiry: UseCombinedLeadEnquiry;
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
}) {
  return function SalesLeadEnquiryDetails() {
    const { getAll, list: leadEnquiriesData } = useCombinedLeadEnquiry();

    useEffect(() => {
      // getData()
      getAll();
      // eslint-disable-next-line
    }, []);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Enquiry No' },
        { name: 'Mode of Enquiry' },
        { name: 'Product' },
        { name: 'Total Quantity' },
        { name: 'Type of Enquiry' },
        { name: 'remarks' },
      ],
    ];

    const tableData =
      leadEnquiriesData?.map((item) => ({
        rowData: { item },
        cells: [
          {
            value: item.name,
          },
          { value: item.enquiryNo },
          { value: item.modeOfEnquiry },
          { value: item.product },
          { value: item.totQty },
          { value: item.enqDt },
          { value: item.typeOfEnq },
          { value: item.remark },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Lead Enquiries',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Lead Enquiry',
            behaviour: 'modal',
            modal: {
              title: 'Add Lead Enquiry',
              content: ({ onClose }) => {
                const LeadEnquiryForm = renderAddLeadEnquiryForm({
                  useCombinedClient,
                  useCombinedItem,
                });
                return (
                  <>
                    <LeadEnquiryForm onClose={onClose} onSave={undefined} />
                  </>
                );
              },
            },
          },
          show: () => true,
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} actions={[]} />,
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderSalesLeadEnquiryDetails;
