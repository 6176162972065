import { BankDetailsType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
// import { UseCurrentCompany } from '../../../../../../hooks/admin/company-admin/use-current-company';
// import { UseCurrentCompanyGroup } from '../../../../../../hooks/admin/company-group-admin/use-current-company-group';
// import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';

type EditBankFormProps = {
  data: BankDetailsType;
  onSave: (data: Partial<BankDetailsType>) => Promise<void>;
};

type RenderEditBankForm = {
  // employeeId: string;
  // useFileTransfer: UseFileTransfer;
  // useCurrentCompanyGroup: UseCurrentCompanyGroup;
  // useCurrentCompany: UseCurrentCompany;
};

// eslint-disable-next-line
export function renderAddBankForm({}: // useFileTransfer,
// useCurrentCompanyGroup,
// useCurrentCompany,
// employeeId,
RenderEditBankForm): (data: EditBankFormProps) => JSX.Element {
  return function AddBankForm({
    onSave,
    data,
  }: EditBankFormProps): JSX.Element {
    // const { data: company } = useCurrentCompany();
    // const { currentCompanyGroup: companyGroup } = useCurrentCompanyGroup();

    const formProps: FormProps<Partial<BankDetailsType>> = {
      fieldsData: [
        {
          property: 'bank',
          type: 'input',
          required: true,
          label: 'Bank Name',
        },
        {
          property: 'branch',
          type: 'input',
          required: true,
          label: 'Bank Branch',
        },
        {
          property: 'branchCode',
          type: 'input',
          required: true,
          label: 'Branch Code',
        },
        {
          property: 'accountNo',
          type: 'input',
          required: true,
          label: 'Account Number',
        },
        {
          property: 'ifsc',
          type: 'input',
          required: true,
          label: 'IFSC Code',
        },
        {
          property: 'micrCode',
          type: 'input',
          required: true,
          label: 'Micr Code',
        },
        {
          property: 'swiftCode',
          type: 'input',
          label: 'Swift Code',
        },
        {
          property: 'accountStatus',
          type: 'select',

          options: [
            { text: 'Select', value: '' },
            { text: 'Pending', value: 'pending' },
            { text: 'Accepted', value: 'accepted' },
            { text: 'Rejected', value: 'rejected' },
          ],
        },
        {
          property: 'active',
          type: 'select',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
        // {
        //   property: 'uploadDocument',
        //   type: 'file',
        //   accept: 'pdf',
        //   label: 'Documents'
        // }
      ],
      initialFormState: {
        bank: '',
        branch: '',
        branchCode: '',
        accountNo: '',
        ifsc: '',
        // uploadDocument: '',
        micrCode: '',
        active: '',
        swiftCode: '',
        accountStatus: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      // upload: {
      //   useFileTransfer: useFileTransfer,
      //   config: [
      //     {
      //       name: 'uploadDocument',
      //       path: `${companyGroup.id}/${company.id}/employees/bank-details/${employeeId}/${data.bank}-${data.accountNo}`,
      //       type: 'private'
      //     }
      //   ]
      // }
    };

    const Form = renderFormV2<Partial<BankDetailsType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<BankDetailsType>>
    ): BankDetailsType {
      return {
        bank: b.bank as string,
        branch: b.branch as string,
        branchCode: b.branchCode as string,
        accountNo: b.accountNo as string,
        ifsc: b.ifsc as string,
        // uploadDocument: b.uploadDocument as string,
        micrCode: b.micrCode as string,
        active: b.active as any,
        swiftCode: b.swiftCode as string,
        accountStatus: b.accountStatus as any,
      } as any;
    }
  };
}
