import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseCombinedVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';

export type RenderVendorPurchaseOrderListProps = {
  useCombinedVendor: UseCombinedVendor;
  id: string;
};

// eslint-disable-next-line
export function renderVendorPurchaseOrderList({
  useCombinedVendor,
  id,
}: RenderVendorPurchaseOrderListProps): () => JSX.Element {
  return function VendorPurchaseOrderList(): JSX.Element {
    const { resource: vendor, get: getVendor } = useCombinedVendor();

    useEffect(() => {
      getVendor(id);
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Vendor',
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Purchase Order' },
        { name: 'Date' },
        { name: 'Items' },
        { name: 'Status' },
      ],
    ];

    const tableBody: TableBody = vendor?.purchaseOrders?.length
      ? vendor.purchaseOrders.map((r: PurchaseOrder) => ({
          cells: [
            {
              value: r.name,
              link: `/purchase/masters/vendors/${r.id}/profile`,
            },
            { value: moment(r.createdAt).format('YYYY-MM-DD') },
            {
              value: (
                <>
                  <span key={r.grade?.id}>{r.grade?.name}</span>
                </>
              ),
            },
            { value: r.status },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
