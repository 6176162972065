import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  // LoadingButton,
  OpenConfirm,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { TrashIcon } from '@heroicons/react/24/outline';

export const EmployeeRoles = ({
  employeeProfile,
  setEmployee,
  openConfirm,
  actions,
  isHr,
}: {
  employeeProfile: EmployeeProfileType;
  openConfirm: OpenConfirm;
  setEmployee: (
    data: EmployeeProfileType,
    options?: any
  ) => Promise<EmployeeProfileType>;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr: boolean;
}) => {
  const Card = renderCardComponent();

  const Table = renderTableComponent();

  const deleteRole = (index: number) => {
    openConfirm({
      onConfirm: async () => {
        const roles: { name: string; level: string }[] =
          employeeProfile.details.jobProfile?.roles || [];
        roles.splice(index, 1);
        await setEmployee({
          id: employeeProfile.id,
          details: {
            jobProfile: {
              ...employeeProfile.details.jobProfile,
              roles,
            },
          } as any,
        } as EmployeeProfileType);
      },
    });
  };

  const tableBody: TableBody =
    employeeProfile?.details?.jobProfile?.roles?.map((rl, index) => {
      return {
        cells: [
          { value: rl.name },
          { value: rl.level },
          {
            value: (
              <div>
                {isHr ? (
                  <TrashIcon
                    className='text-red-500 w-5 h-5'
                    onClick={() => {
                      deleteRole(index);
                    }}
                  />
                ) : null}
              </div>
            ),
          },
        ],
      };
    }) || [];

  const addRoleSave = async (form) => {
    const final = {
      id: employeeProfile.id,
      details: {
        jobProfile: {
          ...employeeProfile?.details?.jobProfile,
          roles: [...(employeeProfile?.details?.jobProfile?.roles || []), form],
        },
      },
    };
    await setEmployee((final as unknown) as EmployeeProfileType);
  };

  return (
    <div>
      <Card
        auth={{ actions }}
        header={{
          title: 'Employee Roles',
          actions: [
            {
              auth: 'HRD:EMP-PROFILE:EDIT',
              type: 'button',
              button: {
                behaviour: 'modal',
                name: 'Add Role',
                modal: {
                  title: 'Add Role',
                  content: ({ onClose }) => {
                    return (
                      <AddRoleForEmployeeForm
                        employeeProfile={employeeProfile}
                        save={async (form) => {
                          await addRoleSave(form);
                          onClose();
                        }}
                      />
                    );
                  },
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Role name' },
                    { name: 'Level' },
                    { name: 'Actions' },
                  ],
                ]}
                body={tableBody}
              />
            </div>
          ),
        }}
      />
    </div>
  );
};

type RoleType = {
  name: string;
  level: 'beginner' | 'intermediate' | 'professional';
};

const AddRoleForEmployeeForm = ({
  employeeProfile,
  save,
  data,
}: {
  employeeProfile: EmployeeProfileType;
  save: (form: any) => Promise<void>;
  data?: RoleType;
}) => {
  const employeeDetails: any = employeeProfile.details;
  const Form = renderFormV2<any>({
    fieldsData: [
      {
        property: 'name',
        label: 'Role',
        type: 'input',
        validate: async (form) => {
          if (
            employeeDetails?.jobProfile?.roles?.find(
              (s) =>
                s.name.toLowerCase() === (form.name as string).toLowerCase()
            ) &&
            data?.name.toLowerCase() !== (form.name as string).toLowerCase()
          ) {
            return await {
              name: 'This role is already added',
            };
          }
          return (await {}) as any;
        },
        required: true,
      },
      {
        property: 'level',
        label: 'Level',
        type: 'select',
        options: [
          { value: '', text: 'Select' },
          { value: 'beginner', text: 'Beginner' },
          { value: 'intermediate', text: 'Intermediate' },
          { value: 'professional', text: 'Professional' },
        ],
        required: true,
      },
    ],
    onSubmit: async (form) => {
      await save(form);
    },
    mapTToU: (p) => {
      return {
        name: p.name,
        level: p.level,
      };
    },
    allowUnchanged: true,
    initialFormState: {
      name: data?.name || '',
      level: data?.level || '',
    },
  });

  return <Form />;
};
