import { PremiumsType } from '@erp_core/erp-types/dist/modules/insurance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';

export type PremiumsFormType = {
  fromMonthYear: string;
  toMonthYear: string;
  amount: number;
};

// eslint-disable-next-line
export function renderAddPremiumForm() {
  return function AddPremiumForm({
    onSave,
    isEdit,
  }: {
    onSave: any;
    isEdit?: boolean;
  }): JSX.Element {
    const validateForm = async (form) => {
      if (isEdit) return '';
      if (!form.fromMonthYear) return '';

      if (
        moment(form.toMonthYear).format('YYYY-MM') <
        moment(form.fromMonthYear).format('YYYY-MM')
      ) {
        return {
          toMonthYear: 'to month year should be greater than from month year',
        } as any;
      } else if (
        moment(form.toMonthYear).format('YYYY') > moment().format('YYYY')
      ) {
        return {
          toMonthYear:
            'to month year should be less than from current month year',
        } as any;
      }
      return;
    };

    const formProps: FormProps<Partial<PremiumsFormType>> = {
      fieldsData: [
        {
          property: 'fromMonthYear',
          type: 'input',
          required: true,
          label: 'From Month-Year (YYYY-MM)',
        },
        {
          property: 'toMonthYear',
          type: 'input',
          required: true,
          label: 'To Month-Year (YYYY-MM)',
          validate: validateForm,
        },
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
          required: true,
        },
      ],
      initialFormState: {
        fromMonthYear: '',
        toMonthYear: '',
        amount: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: onSave,
    };

    const Form = renderFormV2<Partial<PremiumsType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<PremiumsFormType>>
    ): PremiumsFormType {
      return (b as unknown) as PremiumsFormType;
    }
  };
}
