/* eslint-disable no-unused-vars */
import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedLocationAdmin } from '../../../hooks/admin/location-admin/use-location';
import { shortForm } from '../../../utils/strings';

export type RenderEditLocationFormProps = {
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  companyId: string;
};

export function renderEditLocationForm({
  useCombinedLocationAdmin,
  companyId,
}: RenderEditLocationFormProps): (LocationType) => JSX.Element {
  return function EditLocationForm({
    r,
    onClose,
  }: {
    r: LocationType;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setLocationAdmin } = useCombinedLocationAdmin();

    const saveLocation = async (form) => {
      if (r.id) {
        form['id'] = r.id;
      }
      if (form.shortName) {
        form['shortName'] = shortForm(form.shortName);
      }

      const res = {
        id: form.id,
        name: form.name,
        shortName: form.shortName || r.shortName,
        details: {
          address: {
            addressLine1: form.addressLine1 || r.details?.address?.addressLine1,
            addressLine2: form.addressLine2 || r.details?.address?.addressLine2,
            city: form.city || r.details.address?.city,
            district: form.district || r.details?.address?.district,
            pincode: form.pincode || r.details?.address?.pincode,
            state: form.state || r.details?.address?.state,
            country: form.country || r.details?.address?.country,
          },
          contact: {
            mobile: form.mobile || r.details?.contact?.mobile,
            email: form.email || r.details?.contact?.email || '',
          },

          invoiceTo: {
            address: {
              addressLine1:
                form.invoiceToAddressLine1 ||
                r.details?.invoiceTo?.address?.addressLine1,
              addressLine2:
                form.invoiceToAddressLine2 ||
                r.details?.invoiceTo?.address?.addressLine2,
              city: form.invoiceToCity || r.details.invoiceTo?.address?.city,
              district:
                form.invoiceToDistrict ||
                r.details?.invoiceTo?.address?.district,
              pincode:
                form.invoiceToPincode || r.details?.invoiceTo?.address?.pincode,
              state:
                form.invoiceToState || r.details?.invoiceTo?.address?.state,
              country:
                form.invoiceToCountry || r.details?.invoiceTo?.address?.country,
            },
            contact: {
              mobile:
                form.invoiceToMobile || r.details?.invoiceTo?.contact?.mobile,
              email:
                form.invoiceToEmail ||
                r.details?.invoiceTo?.contact?.email ||
                '',
            },
          },
        },
      } as LocationType;

      await setLocationAdmin(res);
      onClose();
    };

    type FormType = {
      name: string;
      shortName: string;

      addressLine1: string;
      addressLine2: string;
      city: string;
      district: string;
      state: string;
      pincode: string;
      country: string;
      mobile: string;
      email: string;

      invoiceToAddressLine1: string;
      invoiceToAddressLine2: string;
      invoiceToCity: string;
      invoiceToDistrict: string;
      invoiceToState: string;
      invoiceToPincode: string;
      invoiceToCountry: string;
      invoiceToMobile: string;
      invoiceToEmail: string;
    };

    const formProps: FormProps<FormType> = {
      style: 'w-1/3',
      fieldsData: [
        {
          groupName: '1. General',
          property: 'name',
          label: 'Location name',
          type: 'input',

          required: true,
        },
        {
          groupName: '1. General',
          property: 'shortName',
          label: 'Location Short Name',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'addressLine1',
          label: 'Address Line 1',
          type: 'input',
        },
        {
          groupName: '2. Address',
          property: 'addressLine2',
          label: 'Address Line 2',
          type: 'input',
        },
        {
          groupName: '2. Address',
          property: 'city',
          label: 'City',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'pincode',
          label: 'Pincode',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'district',
          label: 'District',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'state',
          label: 'State',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'country',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'mobile',
          label: 'Mobile',
          type: 'input',
          required: true,
        },
        {
          groupName: '2. Address',
          property: 'email',
          label: 'Email',
          type: 'email',
        },

        {
          groupName: '3. Invoice To',
          property: 'invoiceToAddressLine1',
          label: 'Address Line 1',
          type: 'input',
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToAddressLine2',
          label: 'Address Line 2',
          type: 'input',
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToCity',
          label: 'City',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToPincode',
          label: 'Pincode',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToDistrict',
          label: 'District',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToState',
          label: 'State',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToCountry',
          label: 'Country',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToMobile',
          label: 'Mobile',
          type: 'input',
          required: true,
        },
        {
          groupName: '3. Invoice To',
          property: 'invoiceToEmail',
          label: 'Email',
          type: 'email',
        },
      ],
      allowUnchanged: true,
      initialFormState: {
        name: r.name,
        shortName: r.shortName,

        addressLine1: r.details?.address?.addressLine1 || '',
        addressLine2: r.details?.address?.addressLine2 || '',
        city: r.details?.address?.city || '',
        district: r.details?.address?.district || '',
        state: r.details?.address?.state || '',
        country: r.details?.address?.country || '',
        email: r.details?.contact?.email || '',
        pincode: r.details?.address?.pincode || '',
        mobile: r.details?.contact?.mobile || '',

        invoiceToAddressLine1:
          r.details?.invoiceTo?.address?.addressLine1 || '',
        invoiceToAddressLine2:
          r.details?.invoiceTo?.address?.addressLine2 || '',
        invoiceToCity: r.details?.invoiceTo?.address?.city || '',
        invoiceToDistrict: r.details?.invoiceTo?.address?.district || '',
        invoiceToState: r.details?.invoiceTo?.address?.state || '',
        invoiceToCountry: r.details?.invoiceTo?.address?.country || '',
        invoiceToPincode: r.details?.invoiceTo?.address?.pincode || '',
        invoiceToEmail: r.details?.invoiceTo?.contact?.email || '',
        invoiceToMobile: r.details?.invoiceTo?.contact?.mobile || '',
      },
      mapTToU: mapPFDToP,
      onSubmit: saveLocation,
    };

    const Form = renderFormV2<FormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<FormType>): FormType {
      return b as FormType;
    }
  };
}
