import { UseCombinedAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { UseCombinedCurrency } from '../../../hooks/admin/constants/currency/use-currency';
import { UseCombinedEmployeeCategory } from '../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedEmployeeDesignation } from '../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { UseCombinedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedHsn } from '../../../hooks/admin/constants/hsn/use-hsn';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedDeliveryTerm } from '../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../hooks/constants/payment-term/use-payment-term';
import { UseCombinedItemCategory } from '../../../hooks/inventory/item/item-category/use-item-category';
import { renderAppearance } from './appearance';
import { renderColor } from './color';
import { renderCurrency } from './currency';
import { renderDeliveryModes } from './delivery-modes';
import { renderDeliveryTerms } from './delivery-terms';
import { renderEmployeeCategory } from './employee-category';
import { renderEmployeeDesignation } from './employee-designation';
import { renderGst } from './gst';
import { renderHsn } from './hsn';
import { renderItemCategoryV2 } from './item-category';
import { renderMetrics } from './metrics';
import { renderOdour } from './odour';
import { renderPaymentTerms } from './payment-terms';

type CreateConstantsCardType = {
  useCombinedCurrency: UseCombinedCurrency;
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  useCombinedMetric: UseCombinedMetric;
  useCombinedItemCategory: UseCombinedItemCategory;
  useCombinedGST: UseCombinedGST;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedEmployeeDesignation: UseCombinedEmployeeDesignation;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedHsn: UseCombinedHsn;
};

export function renderConstants({
  useCombinedCurrency,
  useCombinedEmployeeCategory,
  useCombinedDeliveryTerm,
  useCombinedMetric,
  useCombinedGST,
  useCombinedItemCategory,
  useCombinedPaymentTerm,
  useCombinedEmployeeDesignation,
  useCombinedAppearance,
  useCombinedOdour,
  useCombinedColor,
  useCombinedHsn,
}: CreateConstantsCardType): () => JSX.Element {
  const Currency = renderCurrency({
    useCombinedCurrency,
  });
  const EmployeeCategory = renderEmployeeCategory({
    useCombinedEmployeeCategory,
  });
  const EmployeeDesignation = renderEmployeeDesignation({
    useCombinedEmployeeDesignation,
    useCombinedEmployeeCategory,
  });
  const Metrics = renderMetrics({ useCombinedMetric });
  const ItemCategory = renderItemCategoryV2({
    useCombinedItemCategory,
  });
  const Gst = renderGst({ useCombinedGST });
  const DeliveryTerms = renderDeliveryTerms({
    useCombinedDeliveryTerm,
  });
  const DeliveryModes = renderDeliveryModes();
  const PaymentTerms = renderPaymentTerms({
    useCombinedPaymentTerm,
  });
  const Appearance = renderAppearance({
    useCombinedAppearance,
  });

  const Odour = renderOdour({
    useCombinedOdour,
  });

  const Color = renderColor({
    useCombinedColor,
  });

  const Hsn = renderHsn({
    useCombinedHsn,
  });

  const sections: Array<{ Section: () => JSX.Element; colSpan: string }> = [
    { Section: Currency, colSpan: 'col-span-1' },
    { Section: ItemCategory, colSpan: 'col-span-2' },
    { Section: Metrics, colSpan: 'col-span-1' },
    { Section: Gst, colSpan: 'col-span-1' },
    { Section: DeliveryTerms, colSpan: 'col-span-1' },
    { Section: DeliveryModes, colSpan: 'col-span-1' },
    { Section: PaymentTerms, colSpan: 'col-span-1' },
    { Section: EmployeeCategory, colSpan: 'col-span-1' },
    { Section: EmployeeDesignation, colSpan: 'col-span-1' },
    { Section: Appearance, colSpan: 'col-span-1' },
    { Section: Odour, colSpan: 'col-span-1' },
    { Section: Color, colSpan: 'col-span-1' },
    { Section: Hsn, colSpan: 'col-span-2' },
  ];

  return function Constants(): JSX.Element {
    return (
      <div className='space-y-2 grid grid-cols-3 gap-4'>
        {sections.map((s, idx) => {
          return (
            <div key={idx} className={`h-full w-full space-x-2 ${s.colSpan}`}>
              <s.Section />
            </div>
          );
        })}
      </div>
    );
  };
}
