import {
  GroupLeavesType,
  LeaveAppType,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { LeaveInterface } from '../../../../../models/interfaces/hrd/leave';

export function EditLeave({
  leaves,
  leave,
  leaveService,
  onClose,
}: {
  leaves: LeaveAppType[];
  leave: GroupLeavesType;
  leaveService: LeaveInterface;
  onClose: () => void;
}) {
  const [sortedLeaves, setSortedLeaves] = useState<
    {
      date: string;
      first: LeaveAppType | null;
      second: LeaveAppType | null;
    }[]
  >([]);

  const [summary, setSummary] = useState<{
    cancelled: Array<{ id: string; name: string; date: string }>;
    dateStart: Array<{
      id: string;
      name: string;
      dateStart: string;
      dateEnd: string;
    }>;
    dateEnd: Array<{
      id: string;
      name: string;
      dateStart: string;
      dateEnd: string;
    }>;
  }>({ cancelled: [], dateEnd: [], dateStart: [] });

  const [errors, setErrors] = useState<string>('');

  useEffect(() => {
    const filtered = _.sortBy(
      leaves.filter(
        (x) =>
          x.employee.id === leave.leave.employee?.id &&
          x.dateStart === leave.leave.dateStart &&
          x.dateEnd === leave.leave.dateEnd
      ),
      'name'
    );

    const sLeaves: {
      date: string;
      first: LeaveAppType | null;
      second: LeaveAppType | null;
    }[] = [];

    let summary = '';

    filtered.forEach((lv) => {
      const x = { ...lv };
      const date = x.name.replace('-first', '').replace('-second', '');
      const isFirst = x.name.includes('-first');
      const isSecond = x.name.includes('-second');
      const sLeave = sLeaves.find((y) => y.date === date);
      if (sLeave) {
        if (isFirst) {
          if (sLeave.first === null) {
            sLeave.first = x;
          } else {
            summary += `${date} first leave already exists. `;
          }
        }
        if (isSecond) {
          if (sLeave.second === null) {
            sLeave.second = x;
          } else {
            summary += `${date} second leave already exists. `;
          }
        }
      } else {
        sLeaves.push({
          date: date,
          first: isFirst ? x : null,
          second: isSecond ? x : null,
        });
      }
    });

    setSortedLeaves(sLeaves);
    setErrors(summary);
  }, [leaves, leave]);

  useEffect(() => {
    const sum: {
      cancelled: Array<{ id: string; name: string; date: string }>;
      dateStart: Array<{
        id: string;
        name: string;
        dateStart: string;
        dateEnd: string;
      }>;
      dateEnd: Array<{
        id: string;
        name: string;
        dateStart: string;
        dateEnd: string;
      }>;
    } = {
      cancelled: [],
      dateEnd: [],
      dateStart: [],
    };
    sortedLeaves.forEach((s) => {
      if (s.first) {
        const lv = leaves.find((x) => x.id === s.first?.id);
        if (lv) {
          if (s.first.status === 'cancelled' && s.first.status !== lv.status) {
            sum.cancelled.push({
              id: s.first.id,
              name: s.first.name,
              date: s.date,
            });
          }
          if (s.first.dateStart !== lv.dateStart) {
            sum.dateStart.push({
              id: s.first.id,
              name: s.first.name,
              dateStart: s.first.dateStart,
              dateEnd: s.first.dateEnd,
            });
          }
          if (s.first.dateEnd !== lv.dateEnd) {
            sum.dateEnd.push({
              id: s.first.id,
              name: s.first.name,
              dateStart: s.first.dateStart,
              dateEnd: s.first.dateEnd,
            });
          }
        }
      }

      if (s.second) {
        const lv = leaves.find((x) => x.id === s.second?.id);
        if (lv) {
          if (
            s.second.status === 'cancelled' &&
            s.second.status !== lv.status
          ) {
            sum.cancelled.push({
              id: s.second.id,
              name: s.second.name,
              date: s.date,
            });
          }
          if (s.second.dateStart !== lv.dateStart) {
            sum.dateStart.push({
              id: s.second.id,
              name: s.second.name,
              dateStart: s.second.dateStart,
              dateEnd: s.second.dateEnd,
            });
          }
          if (s.second.dateEnd !== lv.dateEnd) {
            sum.dateEnd.push({
              id: s.second.id,
              name: s.second.name,
              dateStart: s.second.dateStart,
              dateEnd: s.second.dateEnd,
            });
          }
        }
      }
    });

    setSummary(sum);
  }, [sortedLeaves, leaves]);

  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return (
    <div>
      <Card
        header={{
          title: `Edit Leave for ${sortedLeaves[0]?.first?.employee.name}`,
        }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {errors ? <div className='text-red-500'>{errors}</div> : null}
              <Table
                header={[
                  [
                    { name: 'Date' },
                    { name: 'Start' },
                    { name: 'End' },
                    { name: 'Status' },
                  ],
                ]}
                body={sortedLeaves.map((x, idx) => {
                  return {
                    rowData: {
                      sortedLeave: x,
                      index: idx,
                    },
                    cells: [
                      { value: x.date },
                      { value: x.first?.dateStart || '' },
                      { value: x.first?.dateEnd || '' },
                      { value: x.first?.status || '' },
                    ],
                  };
                })}
                actions={[
                  {
                    name: 'Cancel',
                    show: ({
                      sortedLeave,
                    }: {
                      sortedLeave: {
                        date: string;
                        first: LeaveAppType | null;
                        second: LeaveAppType | null;
                      };
                    }) =>
                      ['pending', 'approved'].includes(
                        sortedLeave.first?.status || ''
                      ),
                    behaviour: 'click',
                    onClick: async ({
                      index,
                      sortedLeave,
                    }: {
                      index: number;
                      sortedLeave: {
                        date: string;
                        first: LeaveAppType | null;
                        second: LeaveAppType | null;
                      };
                    }) => {
                      const newSortedLeaves: Array<{
                        date: string;
                        first: LeaveAppType | null;
                        second: LeaveAppType | null;
                      }> = [...sortedLeaves];

                      // Update the status of current entry
                      if (
                        newSortedLeaves[index].first &&
                        newSortedLeaves[index].second
                      ) {
                        (newSortedLeaves[index].first as any).status =
                          'cancelled';
                        (newSortedLeaves[index].second as any).status =
                          'cancelled';
                      }

                      // Update End Date of previous entries
                      for (let i = index - 1; i >= 0; i--) {
                        if (
                          newSortedLeaves[i].first &&
                          newSortedLeaves[i].second
                        ) {
                          if (
                            newSortedLeaves[i].first?.status === 'cancelled'
                          ) {
                            break;
                          }

                          if (newSortedLeaves[index - 1].first) {
                            (newSortedLeaves[i].first as any).dateEnd =
                              newSortedLeaves[index - 1].date || '';
                            (newSortedLeaves[i].second as any).dateEnd =
                              newSortedLeaves[index - 1].date || '';
                          }
                        }
                      }

                      // Update Start Date of subsequent entries
                      for (let i = index + 1; i < newSortedLeaves.length; i++) {
                        if (
                          newSortedLeaves[i].first &&
                          newSortedLeaves[i].second
                        ) {
                          if (
                            newSortedLeaves[i].first?.status === 'cancelled'
                          ) {
                            break;
                          }

                          if (newSortedLeaves[index + 1].first) {
                            (newSortedLeaves[i].first as any).dateStart =
                              newSortedLeaves[index + 1].date || '';
                            (newSortedLeaves[i].second as any).dateStart =
                              newSortedLeaves[index + 1].date || '';
                          }
                        }
                      }

                      setSortedLeaves(newSortedLeaves);
                    },
                  },
                ]}
              />

              {summary.cancelled.length ? (
                <div>
                  <div className='text-center'>Summary</div>
                  <Table
                    header={[[{ name: 'Action' }, { name: 'Name' }]]}
                    body={[
                      {
                        cells: [
                          { value: 'Cancelled Leaves' },
                          {
                            value: (
                              <div>
                                {summary.cancelled.map((s, idx) => (
                                  <span
                                    key={idx}
                                    className='bg-gray-100 px-1 mx-2 border border-gray-200'
                                  >
                                    {s.name}
                                  </span>
                                ))}
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        cells: [
                          { value: 'Date Start Updated' },
                          {
                            value: (
                              <div>
                                {' '}
                                {summary.dateStart.map((s, idx) => (
                                  <span
                                    key={idx}
                                    className='bg-gray-100 px-1 mx-1'
                                  >
                                    {s.name}
                                  </span>
                                ))}
                              </div>
                            ),
                          },
                        ],
                      },
                      {
                        cells: [
                          { value: 'Date End Updated' },
                          {
                            value: (
                              <div>
                                {summary.dateEnd.map((s, idx) => (
                                  <span
                                    key={idx}
                                    className='bg-gray-100 px-1 mx-1'
                                  >
                                    {s.dateEnd} =&gt; {s.name}
                                  </span>
                                ))}
                              </div>
                            ),
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              ) : null}
              <div className='flex'>
                <div className='align-center'>
                  <LoadingButton
                    text='Save'
                    behaviorFn={async () => {
                      await leaveService.bulkWithdraw({
                        type: sortedLeaves[0].first?.type || 'unpaid-leave',
                        cancelLeaveIds: summary.cancelled.map((x) => x.id),
                        dateEditLeaves: [
                          ...summary.dateStart.map((x) => ({
                            id: x.id,
                            dateStart: x.dateStart,
                            dateEnd: x.dateEnd,
                          })),
                          ...summary.dateEnd.map((x) => ({
                            id: x.id,
                            dateStart: x.dateStart,
                            dateEnd: x.dateEnd,
                          })),
                        ],
                      });
                      onClose();
                    }}
                  />
                </div>
              </div>
              {/* {sortedLeaves.map((x, idx) => {
                return (
                  <div key={idx}>
                    {x.date} - First- {x.first?.status} - Second{' '}
                    {x.second?.status} -{x.first?.dateStart} -{' '}
                    {x.first?.dateEnd}
                  </div>
                );
              })} */}
            </>
          ),
        }}
      />
    </div>
  );
}
