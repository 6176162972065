import { RuleRequestType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type RuleRequestFormType = {
  description: string;
  date: string;
  status: string;
  type: string;
};
// eslint-disable-next-line
export const renderAddRuleRequestForm = () => {
  return function AddRuleRequestForm({
    data,
    onSave,
  }: {
    data?: RuleRequestType;
    onSave: (data: RuleRequestType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<RuleRequestFormType>> = {
      fieldsData: [
        {
          property: 'description',
          type: 'input',
          required: true,
          label: 'Description',
        },
        { property: 'date', type: 'date', label: 'Date', required: true },
        {
          property: 'status',
          type: 'select',
          options: [
            { value: '', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'cancelled', text: 'cancelled' },
          ],
          dependentOn: () => {
            return data?.status === 'pending';
          },
        },
        {
          property: 'type',
          type: 'select',
          options: [
            { value: 'select', text: 'select' },
            { value: 'minor', text: 'minor' },
            { value: 'major', text: 'major' },
            { value: 'critical', text: 'critical' },
          ],
          dependentOn: (formData) => {
            return formData?.status === 'approved';
          },
        },
      ],
      initialFormState: {
        date: data?.date || '',
        description: data?.description || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      allowUnchanged: !data,
      onSubmit: async (f) => {
        await onSave({
          id: data?.id,
          date: f.date || data?.date,
          description: f.description || data?.description,
          status: f.status || data?.status,
          details: {
            type: f.type || data?.details?.type,
            misconductId:
              data?.details?.misconductId || data?.details?.misconductId,
          },
        } as RuleRequestType);
      },
    };

    const Form = renderFormV2<Partial<RuleRequestFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<RuleRequestFormType>>
    ): RuleRequestFormType {
      return (b as unknown) as RuleRequestFormType;
    }
  };
};
