import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DependencySummary } from '..';

export function renderParamDependencySummary(
  dependencySummary: DependencySummary
): JSX.Element {
  const res: Array<string> = dependencySummary.outerParams;
  const innerParams: Array<string> = dependencySummary.innerParams;
  const errors: Array<string> = dependencySummary.errors;
  return (
    <div className='p-1'>
      {res.length ? (
        <div>
          <div className='text-gray-800 italic my-1'>
            The below calculations require following outer dependencies to be
            provided:
          </div>
          <div className='flex flex-wrap'>
            {res.map((r) => (
              <span
                className='text-sm bg-gray-50 px-1 mx-0.5 my-0.5 border border-gray-700 rounded-full'
                key={r}
              >
                {r}
              </span>
            ))}
          </div>
        </div>
      ) : null}
      {innerParams.length ? (
        <div>
          <div className='text-gray-800 italic my-1'>
            The below calculations makes use of following inner dependencies:
          </div>
          <div className='flex flex-wrap'>
            {innerParams.map((r) => (
              <span
                className='px-2 py-0.5 mx-0.5 border border-gray-700 rounded-full'
                key={r}
              >
                {r}
              </span>
            ))}
          </div>
        </div>
      ) : null}
      {errors.length ? (
        <div>
          {errors.map((r) => (
            <div className='italic text-red-600' key={r}>
              <ExclamationTriangleIcon className='w-5 inline mr-2' />
              {r}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
