/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { PhysicalStockType } from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import { PhysicalStockFilterType } from '../../../../../../models/interfaces/inventory/item/stock/physical-stock';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class HttpPhysicalStockRepo extends GenericHttpWithLogsRepo<
  PhysicalStockType,
  PhysicalStockFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/items/batches/physical-stock',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
