import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import { GeneralAddressType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  KeyValue,
  LoadingButton,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { POVoucher } from './purchase-order';

export function EditShipToDetails({
  po,
  currentLocation,
  jobWorkVendors,
  savePo,
}: {
  po: POVoucher;
  currentLocation: {
    name: string;
    address: GeneralAddressType;
    contact: { mobile: string; email: string };
    gst: string;
  };
  jobWorkVendors: Array<Vendor>;
  savePo: (po: POVoucher) => Promise<void>;
}) {
  const [purchaseOrder, setPurchaseOrder] = useState<POVoucher>(po);
  const Card = renderCardComponent();
  return (
    <>
      <select
        value={po.buyer?.delivery.type}
        onChange={(evt) => {
          if (evt.target.value === 'self') {
            const newPo: POVoucher = { ...purchaseOrder };
            if (!newPo.buyer) {
              newPo.buyer = {
                delivery: {
                  type: 'self',
                  name: currentLocation.name,
                  address: currentLocation.address,
                  contact: currentLocation.contact,
                  gst: currentLocation.gst,
                },
                billing: {} as any,
              };
            } else {
              newPo.buyer.delivery = {
                type: 'self',
                name: currentLocation.name,
                address: currentLocation.address,
                contact: currentLocation.contact,
                gst: currentLocation.gst,
              };
            }

            setPurchaseOrder(newPo);
          }

          if (evt.target.value === 'job-work') {
            console.log('aya');
            const newPo: POVoucher = { ...purchaseOrder };

            if (!newPo.buyer) {
              newPo.buyer = {} as any;
            }

            newPo.buyer.delivery = {
              type: 'job-work',
            } as any;

            setPurchaseOrder(newPo);
          }
        }}
      >
        <option value={''}>Select</option>
        <option value='self'>Self</option>
        <option value='job-work'>Job Work</option>
      </select>

      {purchaseOrder?.buyer?.delivery?.type === 'job-work' ? (
        <select
          onChange={(evt) => {
            if (evt.target.value) {
              const ven = jobWorkVendors.find(
                (x) => x.name === evt.target.value
              );
              if (ven) {
                const newPo: POVoucher = { ...purchaseOrder };
                newPo.buyer.delivery.name = ven.name;
                newPo.buyer.delivery.contact = {
                  mobile: ven.details?.contact?.[0]?.phones?.[0] || '',
                  email: ven.details?.contact?.[0]?.emails?.[0] || '',
                };
                newPo.buyer.delivery.address =
                  ven.details?.locations?.[0]?.address || ({} as any);
                newPo.buyer.delivery.gst =
                  ven.details?.vendorRegistration?.find(
                    (v) => v.regDocumentType === 'gst'
                  )?.accountNo || '';
                setPurchaseOrder(newPo);
              }
            }
          }}
          value={purchaseOrder?.buyer?.delivery?.name}
        >
          <option value=''>Select</option>
          {jobWorkVendors?.map((x) => (
            <option key={x.id} value={x.name}>
              {x.name}
            </option>
          ))}
        </select>
      ) : null}

      <Card
        header={{ title: 'Ship To' }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {purchaseOrder.buyer?.delivery?.name ? (
                <div>
                  <div className='font-bold'>
                    {purchaseOrder.buyer?.delivery?.name}
                  </div>
                  <KeyValue
                    lhs='Address'
                    rhs={[
                      `${
                        purchaseOrder.buyer?.delivery?.address?.addressLine1 ||
                        ''
                      }, ${
                        purchaseOrder.buyer?.delivery?.address?.addressLine2 ||
                        ''
                      }`,
                      `${
                        purchaseOrder.buyer?.delivery?.address?.city || ''
                      } - ${
                        purchaseOrder.buyer?.delivery?.address?.pincode || ''
                      }, ${
                        purchaseOrder.buyer?.delivery?.address?.district || ''
                      }`,
                      `${
                        purchaseOrder.buyer?.delivery?.address?.state || ''
                      }, ${
                        purchaseOrder.buyer?.delivery?.address?.country || ''
                      }`,
                    ]}
                  />
                  <KeyValue
                    lhs='GST'
                    rhs={purchaseOrder.buyer?.delivery?.gst || ''}
                  />
                </div>
              ) : null}
            </>
          ),
        }}
      />

      <LoadingButton
        text='Save'
        behaviorFn={async () => {
          savePo(purchaseOrder);
        }}
      />
    </>
  );
}
