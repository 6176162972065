import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { AttendanceType, Punch } from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';
import { ManualPunchesEmployeeType } from '../forms/manual-punches-for-employee';

export async function addPunch({
  attendance,
  mp,
  type,
  date,
  currentUser,
  setAttendance,
  getData,
  onClose,
}: {
  attendance: AttendanceType;
  mp: ManualPunchesEmployeeType;
  type?: 'manual' | 'auto';
  currentUser: CurrentUserType;
  date: string;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  onClose: () => void;
}) {
  const finalData: AttendanceType = {
    details: {},
  } as AttendanceType;

  const punches = attendance.details?.punches || [];

  (finalData.details as any).punches = punches;

  if (attendance.id) {
    finalData.id = attendance.id;
  }

  if (!finalData.id) {
    finalData.name = attendance.name || date;
    finalData.date = attendance.date || date;
    finalData.employee = mp.employee;
    finalData.finalized = false;
    finalData.finalizedBy = { id: currentUser.id, name: currentUser.name };
    finalData.status = 'not-determined';
  }

  const timeStamp = moment(mp.date).utc().format();
  const punch: Punch = {
    timeStamp,
    location: mp.location,
    type: type || 'manual',
    createdBy: mp.createdBy
      ? mp.createdBy
      : { id: currentUser.id, name: currentUser.name },
    action: mp.type,
    remark: mp.reason,
  };
  if ((mp as any).device) {
    punch.device = (mp as any).device;
  }

  if (finalData.details) {
    // If
    if (finalData.details.punches.length === 0) {
      finalData.details.punches.push(punch);
    } else {
      for (let i = 0; i < finalData.details.punches.length; i++) {
        const currentPunch = finalData.details.punches[i];
        const diff = moment
          .utc(currentPunch.timeStamp)
          .diff(moment.utc(timeStamp), 'minutes');
        if (i === finalData.details.punches.length - 1 && diff < 0) {
          finalData.details.punches.push(punch);
          break;
        }
        if (diff > 0) {
          finalData.details.punches.splice(i, 0, punch);
          break;
        }
      }
    }
  }
  await setAttendance(finalData);
  getData();
  onClose();
}
