/* eslint-disable react-hooks/exhaustive-deps */
import { AdvancedLoadingButton } from '@erp_core/erp-ui-components';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { renderEditChangeControlPatternForm } from './forms/edit-change-control';

type CreateQualityAssuranceConfigPage = {
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

export const createQualityAssuranceConfigPage = ({
  useCombinedCompanyGroupSetting,
}: CreateQualityAssuranceConfigPage) => {
  return function () {
    const { cgSetting } = useContext(CurrentContext);

    return (
      <div>
        <div>
          <div className='my-2'>
            <span className='font-bold text-md mr-3'>
              Change Control name pattern:{' '}
            </span>
            {cgSetting?.details?.qualityAssurance?.changeControl
              ?.changeControlPattern || ''}
            <AdvancedLoadingButton
              defaultStyle='ml-4'
              behaviour='modal'
              modal={{
                size: 'normal',
                title: 'Edit Change Control name pattern',
                content: ({ onClose }: { onClose: () => void }) => {
                  const EditChangeControlPatternForm = renderEditChangeControlPatternForm(
                    {
                      closeModal: onClose,
                      useCombinedCompanyGroupSetting,
                    }
                  );
                  return <EditChangeControlPatternForm data={cgSetting} />;
                },
              }}
              text={<PencilSquareIcon className='h-4 w-4' />}
            />
          </div>
        </div>
      </div>
    );
  };
};
