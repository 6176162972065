import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { UsePhysicalStock } from '../../../../../hooks/inventory/item/stock/use-physical-stock';

type StockType = 'total' | 'combo';

export function renderPhysicalStock({
  usePhysicalStock,
}: {
  usePhysicalStock: UsePhysicalStock;
}): // eslint-disable-next-line
({
  id,
  uom,
  className,
  view,
}: {
  id: string;
  uom: string;
  className?: string;
  view?: StockType;
}) => JSX.Element {
  // eslint-disable-next-line react/require-default-props
  return function PhysicalStock({
    id,
    uom,
    className,
    view = 'total',
  }: {
    id: string;
    uom: string;
    className?: string;
    view?: StockType;
  }): JSX.Element {
    const { data, get } = usePhysicalStock();
    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, []);

    return <div className={className || 'text-center'}>{renderStock()}</div>;
    function renderStock() {
      if (view === 'total') {
        if (data.hasOwnProperty('physicalQuantity')) {
          return (
            <span>
              {data.physicalQuantity} {uom}
            </span>
          );
        }

        return (
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' />
        );
      }

      if (
        data.hasOwnProperty('allocated') &&
        data.hasOwnProperty('unAllocated')
      ) {
        return (
          <span>
            {data.allocated} {uom} / {data.unAllocated} {uom}{' '}
          </span>
        );
      }

      return (
        <>
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' /> /{' '}
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' />
        </>
      );
    }
  };
}
