import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';

type EditPropertyValueProps = {
  propOptions?: string | Array<string>;
  currentValue: string;
  onSave: (data: Partial<EditPropertyValFormType>) => Promise<void>;
};

type EditPropertyValFormType = {
  currentValue: string;
  newValue: string;
};

export const renderEditSelectPropertyValue = () => {
  return function EditSelectPropertyValue({
    propOptions,
    currentValue,
    onSave,
  }: EditPropertyValueProps) {
    let options: { text: string; value: string }[] = [
      { text: 'Select', value: '' },
    ];

    if (Array.isArray(propOptions)) {
      _.forEach(propOptions, function (key) {
        options.push({
          text: key,
          value: key,
        });
      });
    } else {
      _.forEach(propOptions?.split(','), function (key, val) {
        options.push({
          text: key,
          value: key,
        });
      });
    }

    const formProps: FormProps<EditPropertyValFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'select',
          required: true,
          label: 'New Value',
          options: options,
        },
      ],
      initialFormState: {
        currentValue: currentValue || '',
        newValue: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyValFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditPropertyValFormType>
    ): EditPropertyValFormType {
      return {
        currentValue: b.currentValue || currentValue,
        newValue: b.newValue,
      } as EditPropertyValFormType;
    }
  };
};
