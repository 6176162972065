import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';

export type ChangeSupervisorProps = {
  form: {
    name: string;
    date: string;
    supervisor: { id: string; name: string };
  };
  saveShift: (form: any) => Promise<void>;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
};

export function renderChangeSupervisor(): (
  p: ChangeSupervisorProps
) => JSX.Element {
  return function ChangeSupervisor({
    form,
    saveShift,
    useCombinedEmployeeProfile,
  }: ChangeSupervisorProps): JSX.Element {
    const Form = renderFormV2({
      fieldsData: [
        {
          property: 'name',
          label: 'Employee Name',
          type: 'input',
          readonly: true,
        },
        {
          property: 'date',
          label: 'Date',
          type: 'input',
          required: true,
          readonly: true,
        },
        {
          property: 'supervisor',
          label: 'Supervisor',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect(u) {},
            filter: {
              crossGroup: 'true',
            },
          },
        },
      ],
      initialFormState: {
        name: form.name || '',
        date: form.date || '',
        supervisor: form.supervisor,
      },
      mapTToU: (s) => {
        return s;
      },
      style: '',
      onSubmit: saveShift,
    });
    return (
      <div>
        <Form />
      </div>
    );
  };
}
