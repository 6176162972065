import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { renderLateMarkForm } from '../../../forms/late-mark';

export function renderLateMarkForms({
  setAttendance,
  currentUser,
  refreshData,
}: {
  refreshData: () => void;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  currentUser: CurrentUserType;
}): ({ data, onClose }: { data: any; onClose: () => void }) => JSX.Element {
  return function LateMarkButton({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const saveLateMark = (form) => {
      let lateMark: any = {
        status: form.status,
        reason: form.reason,
      };
      if (form.status === 'approved') {
        lateMark.approvedBy = currentUser;
      } else {
        lateMark.rejectedBy = currentUser;
      }
      setAttendance({
        id: attendance.id,
        details: {
          lateMark,
        },
      } as any);
      refreshData();
      onClose();
    };

    const LateMarkForm = renderLateMarkForm();

    return <LateMarkForm onSave={saveLateMark} />;
  };
}
