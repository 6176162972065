/* eslint-disable class-methods-use-this */
import {
  Company,
  CompanyFilterType,
} from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithCurrentRepo } from '../generic-resource-with-current';

export class HttpCompanyRepo extends GenericHttpWithCurrentRepo<
  Company,
  CompanyFilterType,
  Company
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/companies',
      itemName: 'currentCompany',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getCurrent(): Promise<Company> {
    const currentLocation = localStorage.getItem(this.itemName);
    if (currentLocation) {
      return JSON.parse(currentLocation) as Company;
    }
    return {} as Company;
  }
}
