import { ItemHazard } from '@erp_core/erp-types/dist/types/modules/inventory/item';
// import { useState } from 'react';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedHazardGhsClass } from '../../../../../../hooks/admin/hazard-regulations/ghs-class/use-hazard-ghs-class';
import { UseCombinedHazardStatement } from '../../../../../../hooks/admin/hazard-regulations/hazard-statements/use-hazard-paginated';

type Props = {
  onSave: (form: any) => Promise<void>;
  useCombinedHazardGhsClass: UseCombinedHazardGhsClass;
  useCombinedHazardStatement: UseCombinedHazardStatement;
};
type T = { id: string; name: string };

export const renderAddHazardStatementInItem = ({
  onSave,
  useCombinedHazardStatement,
  useCombinedHazardGhsClass,
}: Props) => {
  return function ({ defaultStatements }: { defaultStatements: ItemHazard }) {
    console.log(defaultStatements);
    type FormType = { class: T; statements: T[] };

    // const [currentState, setCurrentState] = useState<FormType>();

    const initialFormState: FormType = {
      class: { id: '', name: '' },
      // statements: currentState?.class.id
      //   ? defaultStatements.statements
      //       ?.filter((ds) => ds.ghsClassId === currentState.class.id)
      //       .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
      //   : [],
      statements: [],
    };

    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          property: 'class',
          type: 'searchable-select',
          label: 'Hazard Class',
          searchOptions: {
            useSearch: useCombinedHazardGhsClass,
            onSearchValueSelect(u) {},
            searchOptionsBody: {
              // displayProp: 'categoryName',
              customBody: (p) => (
                <div>
                  <div className='bold'>{p.name}</div>
                  <div className='text-sm text-gray-600'>{p.categoryName}</div>
                </div>
              ),
            },
          },
        },

        {
          property: 'statements',
          type: 'searchable-multi-select', //currentState?.class.id ? 'searchable-multi-select' : 'input',
          readonly: true,
          label: 'Hazard Statements',
          searchOptions: {
            useSearch: useCombinedHazardStatement,
            onSearchValueSelect(u) {},
            filter: {
              // hazardClassId: currentState?.class.id
            },
          },
        },
      ],
      initialFormState,
      onFormDataChange: ({ formData, updateFormData, fields, setFields }) => {
        let classProp = {} as { id: string; name: string };

        // updateFormData((ufd) => {
        //   classProp = ufd.class  as { id: string; name: string };
        //   return {
        //     statements: classProp.id
        //       ? (ufd.statements as Array<{ id: string; name: string; }>)?.filter((ds) => ds.ghsClassId === classProp.id).map((t) => ({ id: t.id || '', name: t.name || '' })) || []
        //       : [],
        //     class: classProp,
        //   };
        // })

        if (
          classProp.id
          // form.class.id &&
          // (!currentState || form.class.id !== currentState.class.id)
        ) {
          // setCurrentState({
          //   statements: form.class.id
          //     ? defaultStatements.statements
          //         ?.filter((ds) => ds.ghsClassId === form.class.id)
          //         .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
          //     : [],
          //   class: form.class,
          // });
          updateFormData({
            statements: classProp.id
              ? defaultStatements.statements
                  ?.filter((ds) => ds.ghsClassId === classProp.id)
                  .map((t) => ({ id: t.id || '', name: t.name || '' })) || []
              : [],
            class: classProp,
          });

          const field = fields.find((x) => x.property === 'statements');
          if (field && (field as any).searchOptions?.filter) {
            (field as any).searchOptions.filter.hazardClassId = classProp.id;

            setFields([...fields]);
          }
        }
      },
      onSubmit: onSave,
      mapTToU(p) {
        return (p as unknown) as FormType;
      },
    });

    return (
      <Form
      // currentState={currentState}
      />
    );
  };
};
