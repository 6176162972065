import {
  ClassifierDragItem,
  Revision,
  RevisionType,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  renderTableComponent,
  TableBody,
  TableBodyRow,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { PencilIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { eval as expEval, parse } from 'expression-eval';
import _ from 'lodash';
import { useContext, useEffect } from 'react';
import { UseCombinedGST } from '../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../hooks/admin/constants/metrics/use-metric';
import { UseCurrentUserRoles } from '../../../hooks/admin/role-admin/use-current-user-roles';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
// import { createCurrentSelection } from './current-selection';
import { CurrentContext } from '../../../contexts/current';
import { UserContext } from '../../../contexts/user';
import { UseCombinedAppearance } from '../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../hooks/admin/constants/color/use-color';
import { UseCombinedOdour } from '../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedRevision } from '../../../hooks/inventory/revision/use-revision';
import { LHSRenderer } from './property-renderer/lhs-renderer';
import { RHSRenderer } from './property-renderer/rhs-renderer';
import { renderPropertyValueDetails } from './property-value-details';

type InvPropertiesProps = {
  parentValues: ItemPropertyValue[];
  EditProperty?: (p: { idx: number; onClose: () => void }) => JSX.Element;
  SoftEditProperty?: ({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }) => JSX.Element;
  AuthEditProperty?: ({
    idx,
    onClose,
  }: {
    idx: number;
    onClose: () => void;
  }) => JSX.Element;
  RuleUpdateProperty?: (p: { idx: number; onClose: () => void }) => JSX.Element;
  entityId: string;
  renderEditProp: boolean;
  renderEditValue: boolean;
  renderAuth: boolean;
  classifier?: string;
  type: RevisionType;
  properties: Array<ItemPropertyValue>;
  setRevision: (data: Revision) => Promise<void>;
  classifierItems: ClassifierDragItem[];
};

function sortProperties(
  filteredProperties: ItemPropertyValue[],
  classifierItems: ClassifierDragItem[],
  classifier: string
): ItemPropertyValue[] {
  // console.log(filteredProperties);
  const target = classifierItems?.find((x) => x.name === classifier);
  if (!target) {
    return filteredProperties;
  }

  const sorted = filteredProperties || [];
  for (let i = 0; i < target.items.length; i++) {
    const item = target.items[i];

    const idx = sorted.findIndex((x) => x.name === item.id);
    if (idx !== -1) {
      const prop = sorted.splice(idx, 1);
      sorted.splice(i, 0, prop[0]);
    }
  }

  return sorted;
}
export function renderInvProperties({
  useCombinedMetric,
  useCombinedGST,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
  useCombinedRevision,
  useFileTransfer,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedGST: UseCombinedGST;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCurrentUserRoles: UseCurrentUserRoles;
  useCombinedRevision: UseCombinedRevision;
  useFileTransfer: UseFileTransfer;
}) {
  const Table = renderTableComponent();
  return function InvProperties({
    parentValues,
    EditProperty,
    SoftEditProperty,
    AuthEditProperty,
    RuleUpdateProperty,
    entityId,
    renderEditProp,
    renderAuth,
    classifier,
    renderEditValue,
    properties,
    type,
    setRevision,
    classifierItems,
  }: InvPropertiesProps): JSX.Element {
    // const { data: currentUserRoles } = useCurrentUserRoles();
    const { user: currentUser } = useContext(UserContext);
    const { companyGroup: currentCompanyGroup } = useContext(CurrentContext);

    const { list: revisions, getAll: getRevisions } = useCombinedRevision();
    useEffect(() => {
      getRevisions({
        status: 'pending',
      });
      // eslint-disable-next-line
    }, []);

    const header: TableHeader = [
      _.compact([
        { name: 'Name', style: 'w-1/3' },
        { name: 'Value', style: 'w-2/3' },
      ]),
    ];

    // console.log(properties);

    const filteredProperties = properties.filter((x) => {
      if (!classifier) {
        return true;
      }

      if (x.rules?.length) {
        const hidePropertyRule = x.rules.find(
          (y) => y.type === 'hide-property'
        );
        if (hidePropertyRule && x.owner?.id !== entityId) {
          const availableValues: any = {};
          let condition = hidePropertyRule.condition;
          properties.forEach((z) => {
            if (z.value?.data) {
              const camelCased = _.camelCase(z.name);
              if (condition.includes(z.name)) {
                condition = condition.replaceAll(z.name, camelCased);
              }
              availableValues[camelCased] = z.value.data;
            }
          });
          try {
            const expression = parse(condition);

            const evaluated = expEval(expression, availableValues);
            if (evaluated) {
              return false;
            }
          } catch (e) {
            console.log('failed to evaluate', e);
          }
        }
      }

      if (classifier !== 'Draft') {
        return x.classifiers?.find((y) => y.name === classifier);
      }

      return (
        !x.classifiers ||
        x.classifiers.length === 0 ||
        x.classifiers.filter((x) => x.name).length === 0
      );
    });
    const finalSortedProperties = sortProperties(
      filteredProperties,
      classifierItems,
      classifier || ''
    );

    const body: TableBody = finalSortedProperties.map((x, seq) => {
      const revisionArrayObjChval =
        revisions?.find(
          (y) =>
            y.details.property === x.name &&
            y.name === x.name &&
            entityId === y.resource &&
            type === y.type
        )?.changeRequest.newValue || '';

      const revisionX: ItemPropertyValue = JSON.parse(JSON.stringify(x));
      revisionX.value = (revisionArrayObjChval as any).value;

      const previousValue: ItemPropertyValue = JSON.parse(JSON.stringify(x));
      previousValue.value = ['checklist', 'searchable-multiselect'].includes(
        x.type
      )
        ? { data: [] }
        : { data: 'TODO' };

      const auth: Array<{
        type: 'role' | 'user';
        actions: Array<'view' | 'edit'>;
        id: string;
        name: string;
      }> = [];

      x.auth?.visibleToRole?.forEach((r) => {
        const existing = auth.find((a) => a.id === r.id);
        if (!existing) {
          auth.push({
            type: 'role',
            actions: ['view'],
            id: r.id,
            name: r.name,
          });
        } else {
          existing.actions.push('view');
        }
      });

      x.auth?.editableByRole?.forEach((r) => {
        const existing = auth.find((a) => a.id === r.id);
        if (!existing) {
          auth.push({
            type: 'role',
            actions: ['edit'],
            id: r.id,
            name: r.name,
          });
        } else {
          existing.actions.push('edit');
        }
      });

      x.auth?.visibleToUser?.forEach((r) => {
        const existing = auth.find((a) => a.id === r.id);
        if (!existing) {
          auth.push({
            type: 'user',
            actions: ['view'],
            id: r.id,
            name: r.name,
          });
        } else {
          existing.actions.push('view');
        }
      });

      x.auth?.editableByUser?.forEach((r) => {
        const existing = auth.find((a) => a.id === r.id);
        if (!existing) {
          auth.push({
            type: 'user',
            actions: ['view'],
            id: r.id,
            name: r.name,
          });
        } else {
          existing.actions.push('edit');
        }
      });

      const ItemPropertyValueRender = renderPropertyValueDetails({
        useFileTransfer,
        useCombinedAppearance,
        useCombinedColor,
        useCombinedGST,
        useCombinedMetric,
        useCombinedOdour,
        useCombinedEmployeeProfile,
      });

      const directlyEdit = false;
      const row: TableBodyRow = {
        cells: [
          {
            value: (
              <LHSRenderer
                seq={seq}
                x={x}
                entityId={entityId}
                renderEditProp={renderEditProp}
                EditProperty={EditProperty}
                SoftEditProperty={SoftEditProperty}
                RuleUpdateProperty={RuleUpdateProperty}
                properties={properties}
                AuthEditProperty={AuthEditProperty}
                renderAuth={renderAuth}
                classifier={classifier}
                auth={auth}
              />
            ),
          },
          {
            button: {
              show: () => renderEditValue,
              icon: directlyEdit ? PencilIcon : PencilSquareIcon,
              style: 'inline ml-2 w-5 h-5 text-gray-400 cursor-pointer',
              behaviour: 'modal',
              modal: {
                size: 'large',
                title: directlyEdit
                  ? 'Direct Edit'
                  : `Property Value Details of ${x.displayName}`,
                content: ({ onClose }) => {
                  if (directlyEdit) {
                    // return <CurrentSelection
                    //   itemProperty={x} property={x.name} value={x.value} propType={(x?.type as string) || ''}
                    //   searchSelectOptions={(x?.searchSelectOptions as string) || ''}
                    //   propOptions= {x?.selectOptions || ''}
                    //   currentCompanyGroup={currentCompanyGroup}
                    //   currentIdNameValue={((x.value?.data as unknown) as IdName) || {}}
                    //   setValue={setValue} onClose={onClose}
                    //   owner={{ id: x.owner?.id || '', name: x.owner?.name || '' }}
                    // />;
                  }

                  return (
                    <ItemPropertyValueRender
                      properties={properties}
                      parentValues={parentValues}
                      currentCompanyGroup={currentCompanyGroup}
                      currentValue={x}
                      previousValue={previousValue}
                      changeRequestValue={revisionX}
                      currentUser={currentUser}
                      setRevision={setRevision}
                      onClose={onClose}
                      type={type}
                      entityId={entityId}
                    />
                  );
                },
                onClose: () => {
                  console.log('Closed modal');
                },
              },
            },
            value: (
              <RHSRenderer
                x={x}
                useFileTransfer={useFileTransfer}
                changeRequested={revisionX.value ? true : false}
                parentValues={parentValues}
              />
            ),
          },
        ],
      };
      return row;
    });
    return (
      <div>
        <Table header={header} body={body} />
      </div>
    );
  };
}
