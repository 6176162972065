import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';

export function ReportEditValueForm({
  formType,
  initialValue,
  propName,
  onClose,
  options,
  showTextArea,
}: {
  formType: 'text' | 'select';
  initialValue: string | Array<{ id: string; name: string }>;
  options?: Array<string>;
  propName: string;
  onClose: (finalVal: string | Array<{ id: string; name: string }>) => void;
  showTextArea?: boolean;
}): JSX.Element {
  const [editValue, setEditValue] = useState<
    string | Array<{ id: string; name: string }>
  >(initialValue);

  return (
    <div>
      <div className='text-center text-lg'>
        <span className='capitalize font-bold'>{propName}</span>:
        {Array.isArray(editValue) ? (
          <div>
            {editValue.map((e, idx) => (
              <div key={idx} className='inline p-0.5 border rounded'>
                {e.name}
              </div>
            ))}
          </div>
        ) : (
          <span className='mx-3 italic'>{editValue}</span>
        )}
      </div>
      <div className='text-center'>
        {formType === 'text' ? (
          <>
            {showTextArea ? (
              <textarea
                rows={4}
                className='mx-auto w-3/4 text-xl border border-gray-200 rounded text-black focus-visible:outline-none'
                value={(editValue as string) || ''}
                onChange={(evt) => {
                  setEditValue(evt.target.value);
                }}
              ></textarea>
            ) : (
              <input
                type='text'
                className='mx-auto text-xl border border-gray-200 rounded text-black focus-visible:outline-none'
                value={(editValue as string) || ''}
                onChange={(evt) => {
                  setEditValue(evt.target.value);
                }}
              />
            )}
          </>
        ) : null}
        {formType === 'select' ? (
          <select
            onChange={(e) => {
              setEditValue(e.target.value);
            }}
          >
            {options?.map((o) => (
              <option selected={editValue === o} key={o} value={o}>
                {o}
              </option>
            ))}
          </select>
        ) : null}
      </div>
      <div className='text-center my-3'>
        <LoadingButton
          defaultStyle='bg-green-500 inline mx-2 text-white p-2 border rounded-lg'
          behaviorFn={async () => {
            onClose(editValue);
          }}
          text='Save'
        />
      </div>
    </div>
  );
}
