/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseCombinedCompanyGroupSetting } from '../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCombinedEmployeeCategory } from '../../../hooks/admin/constants/employee-category/use-employee-category';
import { UseCombinedEmployeeDesignation } from '../../../hooks/admin/constants/employee-designation/use-employee-designation';
import { UseCombinedLocationAdmin } from '../../../hooks/admin/location-admin/use-location';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedAttendanceDevice } from '../../../hooks/hrd/attendance-device/use-attendance-device';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from '../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { UseCombinedLicenseContractDocType } from '../../../hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';
import { UseCombinedLicenseContract } from '../../../hooks/order/work-order/license-contract/use-license-contract';
import { UseCombinedLocationHoliday } from '../../../hooks/planning/location-holiday/use-location-holiday';
import { UseCombinedShiftSchedule } from '../../../hooks/planning/shift-schedule/use-shift-schedule';
import { CompanyGroupSettingInterface } from '../../../models/interfaces/admin/company-group-settings';
import { createCompanyGroupSettingPage } from './company-group';
import { createHrdConfig } from './hrd';
import { createInventoryConfigPage } from './inventory-config';
import { createInventoryTemplatesPage } from './inventory-templates';
import { createLiscenseContractPage } from './license-contract';
import { createCompanyGroupSettingLogs } from './logs';
import { createPurchaseConfigPage } from './purchase-config';
import { createQualityAssuranceConfigPage } from './quality-assurance-config';
import { createSalesConfigPage } from './sales-config';
import { createWorkOrderConfigPage } from './work-order-config';

type Props = {
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
  companyGroupSettingService: CompanyGroupSettingInterface;
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedAttendanceDevice: UseCombinedAttendanceDevice;
  useFileTransfer: UseFileTransfer;
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
  useCombinedEmployeeDesignation: UseCombinedEmployeeDesignation;
  useCombinedShiftSchedule: UseCombinedShiftSchedule;
  useCombinedLocationHoliday: UseCombinedLocationHoliday;
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
  useCombinedLicenseContract: UseCombinedLicenseContract;
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
  useCombinedVendor: UseCombinedVendor;
};

export const renderCompanyGroupConfig = ({
  useCombinedCompanyGroupSetting,
  companyGroupSettingService,
  useFileTransfer,
  useCombinedLocationAdmin,
  useCombinedAttendanceDevice,
  useCombinedEmployeeCategory,
  useCombinedEmployeeDesignation,
  useCombinedShiftSchedule,
  useCombinedLocationHoliday,
  useCombinedLicenseContractDocType,
  useCombinedLicenseContract,
  useCombinedClient,
  useCombinedItem,
  useCombinedVendor,
}: Props) => {
  return function () {
    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Config',
    };

    const InventoryTemplates = createInventoryTemplatesPage({
      useFileTransfer,
    });

    const InventoryConfig = createInventoryConfigPage({
      useCombinedCompanyGroupSetting,
    });
    const WorkOrderConfig = createWorkOrderConfigPage({
      useCombinedCompanyGroupSetting,
    });
    const QualityAssuranceConfig = createQualityAssuranceConfigPage({
      useCombinedCompanyGroupSetting,
    });
    const CompanyGroupSettingLogs = createCompanyGroupSettingLogs({
      companyGroupSettingService,
    });
    const PurchaseConfig = createPurchaseConfigPage({
      useCombinedCompanyGroupSetting,
    });
    const SalesConfig = createSalesConfigPage({
      useCombinedCompanyGroupSetting,
    });
    const CompanyGroupConfig = createCompanyGroupSettingPage({
      useCombinedCompanyGroupSetting,
      useCombinedLocationAdmin,
      useCombinedShiftSchedule,
      useCombinedLocationHoliday,
    });

    const HrdConfig = createHrdConfig({
      useCombinedAttendanceDevice,
      useCombinedEmployeeCategory,
      useCombinedCompanyGroupSetting,
      useCombinedEmployeeDesignation,
    });

    const LiscenseContractPage = createLiscenseContractPage({
      useCombinedLicenseContractDocType,
      useCombinedLicenseContract,
      useCombinedClient,
      useCombinedItem,
      useCombinedVendor,
    });

    const newCategories: DataArray = [
      { name: 'inventory', Fn: InventoryConfig },
      { name: 'inventory-templates', Fn: InventoryTemplates },
      { name: 'work-order', Fn: WorkOrderConfig },
      { name: 'quality-assurance', Fn: QualityAssuranceConfig },
      { name: 'purchase', Fn: PurchaseConfig },
      { name: 'sales', Fn: SalesConfig },
      { name: 'license-contract', Fn: LiscenseContractPage },
      { name: 'company-group', Fn: CompanyGroupConfig },
      { name: 'hrd', Fn: HrdConfig },
      { name: 'history', Fn: CompanyGroupSettingLogs },
    ];

    const NewTabs = renderTabsV2Component();

    return (
      <div>
        <Card
          header={cardHeader}
          body={{
            type: 'jsx-component',
            body: <NewTabs data={newCategories} manipulateLocation={true} />,
          }}
        />
      </div>
    );
  };
};
