import {
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';

export function EditDeliverySchedule({
  item,
  quantity,
  deliverySchedule,
  saveDeliverySchedule,
}: {
  item: string;
  quantity: number;
  deliverySchedule: Array<{
    batchNumber: number;
    date: string;
    quantity: number;
  }>;
  saveDeliverySchedule: (
    x: Array<{
      batchNumber: number;
      date: string;
      quantity: number;
    }>
  ) => Promise<void>;
}) {
  const [schedule, setSchedule] = useState<
    Array<{
      batchNumber: number;
      date: string;
      quantity: number;
    }>
  >(deliverySchedule);
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return (
    <div>
      <Card
        header={{
          title: `${item} Delivery Schedule`,
          subheading: <div>Quantity: {quantity}</div>,
          actions: [
            {
              type: 'button',
              button: {
                name: 'Add',
                behaviour: 'click',
                onClick: async () => {
                  const newSch = [...schedule];
                  newSch.push({
                    batchNumber: newSch.length + 1,
                    quantity: 0,
                    date: moment().format('YYYY-MM-DD'),
                  });
                  setSchedule(newSch);
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [{ name: 'Batch #' }, { name: 'Quantity' }, { name: 'Date' }],
                ]}
                body={schedule.map((x, idx) => {
                  return {
                    cells: [
                      { value: x.batchNumber },
                      {
                        value: (
                          <input
                            defaultValue={x.quantity}
                            type='number'
                            onBlur={(evt) => {
                              const newSch = [...schedule];
                              newSch[idx].quantity = parseFloat(
                                evt.target.value
                              );
                              setSchedule(newSch);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.date}
                            type='date'
                            onChange={(evt) => {
                              const newSch = [...schedule];
                              console.log(evt.target.value);
                              newSch[idx].date = evt.target.value;
                              setSchedule(newSch);
                            }}
                          />
                        ),
                      },
                    ],
                  };
                })}
              />
              <LoadingButton
                text={'Save'}
                behaviorFn={async () => {
                  await saveDeliverySchedule(schedule);
                }}
              />
            </div>
          ),
        }}
      />
    </div>
  );
}
