/* eslint-disable no-unused-vars */
import {
  UpdateUserRole,
  UserRolesType,
} from '@erp_core/erp-types/dist/modules/admin';
import { useState } from 'react';
import { UserRolesAdminInterface } from '../../../models/interfaces/admin/user-roles-admin';

export type UseUserRolesAdmin = () => {
  data: UserRolesType;
  setUserRoles: (userRoles: Array<UpdateUserRole>) => void;
  getUserRoles: (id: string) => void;
};

export interface UseUserRolesAdminOptions {
  useUserRolesAdminService: () => UserRolesAdminInterface;
}

export function createUseUserRolesAdmin({
  useUserRolesAdminService,
}: UseUserRolesAdminOptions): UseUserRolesAdmin {
  return () => {
    const [userRolesAdmin, setUserRolesAdmin] = useState<UserRolesType>(
      {} as UserRolesType
    );

    const userRolesAdminService = useUserRolesAdminService();

    const apiCall = async (id: string) => {
      const ra = await userRolesAdminService.getUserRoles(id);
      setUserRolesAdmin(ra);
    };

    const setCall = async (s: Array<UpdateUserRole>) => {
      const da = await userRolesAdminService.setUserRoles(s);
      setUserRolesAdmin(da);
    };

    return {
      data: userRolesAdmin,
      getUserRoles: (id: string) => {
        apiCall(id);
      },
      setUserRoles: (s: Array<UpdateUserRole>) => {
        setCall(s);
      },
    };
  };
}
