import { ShiftScheduleType } from '@erp_core/erp-types/dist/modules/planning';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export const renderShiftScheduleForm = () => {
  return function GateShiftScheduleForm({
    onSave,
    locations,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: ShiftScheduleType;
    isEdit?: boolean;
    locations: { text: string; value: string }[];
  }) {
    const formProps: FormProps<Partial<ShiftScheduleType>> = {
      fieldsData: [
        {
          groupName: '1. Basic Information',
          property: 'location',
          type: 'select',
          required: true,
          label: 'Location',
          options: [{ text: 'select', value: '' }, ...(locations || [])],
          readonly: isEdit,
        },
        {
          groupName: '1. Basic Information',
          property: 'name',
          type: 'input',
          required: true,
          label: 'name',
        },
        {
          groupName: '1. Basic Information',
          property: 'type',
          type: 'select',
          required: true,
          label: 'type',
          options: [
            { text: 'Select', value: '' },
            { text: 'Fixed', value: 'Fixed' },
            { text: 'Rotational', value: 'Rotational' },
          ],
        },
        {
          groupName: '1. Basic Information',
          property: 'frequency',
          type: 'input',
          required: true,
          label: 'frequency',
        },
        {
          groupName: '1. Basic Information',
          property: 'startTime',
          type: 'input',
          required: true,
          label: 'Start time (hh:mm:ss)',
        },
        {
          groupName: '1. Basic Information',
          property: 'endTime',
          type: 'input',
          required: true,
          label: 'End time (hh:mm:ss)',
        },
        {
          groupName: '1. Basic Information',
          property: 'shiftDuration',
          type: 'number',
          label: 'Shift Duration',
        },
        {
          groupName: '2. Break Information',
          property: 'breakName',
          type: 'input',
          label: 'Break Name',
        },
        {
          groupName: '2. Break Information',
          property: 'breakDuration',
          type: 'number',
          label: 'Break Duration',
        },
        {
          groupName: '2. Break Information',
          property: 'breakStart',
          type: 'input',
          label: 'Break Window Start (hh:mm:ss)',
        },
        {
          groupName: '2. Break Information',
          property: 'breakEnd',
          type: 'input',
          label: 'Break Window End (hh:mm:ss)',
        },
      ],
      initialFormState: {
        name: data?.name || '',
        location: data?.location || '',
        type: data?.type || '',
        startTime: data?.startTime || '',
        endTime: data?.endTime || '',
        frequency: data?.frequency || '',
        breakName: data?.breakName || '',
        breakEnd: data?.breakEnd || '',
        breakStart: data?.breakStart || '',
        shiftDuration: parseFloat(`${data?.shiftDuration || '0'}`),
        breakDuration: parseFloat(`${data?.breakDuration || '0'}`),
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
    };

    const Form = renderFormV2<Partial<ShiftScheduleType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ShiftScheduleType>>
    ): ShiftScheduleType {
      return {
        name: b.name || data?.name,
        location: b.location || data?.location,
        startTime: b.startTime || data?.startTime,
        endTime: b.endTime || data?.endTime,
        frequency: b.frequency || data?.frequency,
        breakStart: b.breakStart || data?.breakStart,
        breakEnd: b.breakEnd || data?.breakEnd,
        breakName: b.breakName || data?.breakName,
        breakDuration: parseFloat(
          `${b?.breakDuration || data?.breakDuration || '0'}`
        ),
        shiftDuration: parseFloat(
          `${b?.shiftDuration || data?.shiftDuration || '0'}`
        ),
        type: b.type || data?.type,
      } as any;
    }
  };
};
