/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { Document } from '@erp_core/erp-icons/icons/web/document';
import {
  PurchaseOrder,
  PurchaseOrderFulfilment,
} from '@erp_core/erp-types/dist/modules/order';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedTemplate } from '../../../hooks/admin/communication-template/use-template';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedQuestionnaireQuestion } from '../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedPurchaseOrderFulfilment } from '../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { ItemInterface } from '../../../models/interfaces/inventory/item';
import { downloadTemplatePdf } from '../../hrd/salary/utils/download-template';
import { renderInspectionForm } from '../inspection/inspection-form';
import { renderCreateEditPurchaseOrderFulfilmentForm } from '../requistions/purchase-order/purchase-order-details/purchase-fulfillment-details/forms/add-fulfilment';
import { renderPoInspectionForm } from '../requistions/purchase-order/purchase-order-details/purchase-fulfillment-details/forms/po-inspection';

export function renderGoodsReceived({
  useCombinedPurchaseOrderFulfilment,
  itemService,
  useCombinedQuestionnaireQuestion,
  useCombinedTemplate,
  useFileTransfer,
  useCombinedUser,
}: {
  useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
  itemService: ItemInterface;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedTemplate: UseCombinedTemplate;
  useFileTransfer: UseFileTransfer;
  useCombinedUser: UseCombinedUser;
}): ({ selectedPO }: { selectedPO?: PurchaseOrder }) => JSX.Element {
  return function GoodsReceived({
    selectedPO,
  }: {
    selectedPO?: PurchaseOrder;
  }) {
    const {
      list: fulfilments,
      set: setFulfilments,
      syncSet: setPurchaseFulfillment,
    } = useCombinedPurchaseOrderFulfilment();
    const {
      list: data,
      getAll: getFulfillments,
    } = useCombinedPurchaseOrderFulfilment();
    const { getSync: getTemplate } = useCombinedTemplate();
    const { get: getFile } = useFileTransfer();
    const { getListSync: getQuestions } = useCombinedQuestionnaireQuestion();

    const getData = async () => {
      try {
        if (selectedPO?.id) {
          getFulfillments({
            purchaseOrderId: selectedPO.id,
          });
        } else {
          getFulfillments();
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getData();
      // getCgs();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (fulfilments?.length) {
        getData();
        toast('Approved Sucessfully');
      }
      // eslint-disable-next-line
    }, [fulfilments]);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Purchase Order' },
        { name: 'GRN No' },
        { name: 'Invoice No' },
        { name: 'Arrived at' },
        // { name: 'Item' },
        { name: 'Vendor' },
        { name: 'Quantity/Invoice Quantity' },
        { name: 'remarks' },
        { name: 'Attachments' },
        { name: 'Status' },
      ],
    ];

    const tableBody: TableBody =
      data?.map((item: PurchaseOrderFulfilment) => ({
        rowData: {
          fulfillment: item,
        },
        cells: [
          {
            value: (
              <>
                <div>{item.purchaseOrder.grade?.name || '---'}</div>
                <div>{item.purchaseOrder.name}</div>
              </>
            ),
          },
          { value: item.details.grnNumber || '' },
          { value: item.details.invoiceNo || '' },
          { value: item.details.arrivedAt || '' },
          // { value: item?.purchaseOrder?.details?.itemDetails?.name || '' },
          { value: item?.purchaseOrder?.vendor?.name },
          {
            value: `${item.details.quantity || '--'}/${
              item.details.invoiceQuantity || '--'
            } ${item.details.uom}`,
          },
          { value: item.details.remarks || '' },
          {
            value: (
              <div>
                {item.details?.attachments?.map((a, i) => (
                  <div key={i}>{a}</div>
                ))}
                {(item.details as any)?.inspectionAnswers ? (
                  <div
                    onClick={async () => {
                      const properties = await itemService.getProperties(
                        item.purchaseOrder?.details?.itemDetails?.grade?.item
                          ?.id
                      );

                      const templateId = (properties?.find(
                        (x) => x.name === 'coa-template'
                      ) as any)?.value?.data;
                      if (templateId) {
                        const template = await getTemplate(templateId);
                        const url = template.url;
                        const spec: any = {};
                        if (properties) {
                          properties.forEach((p) => {
                            spec[p.name] = (p as any)?.value?.data;
                          });
                        }
                        const questions = await getQuestions({
                          topicId: (item.details as any).inspectionAnswers.topic
                            .id,
                          order: 'asc',
                          sortBy: 'orderNo',
                        });
                        console.log('que', questions);
                        const inspectionAnswers = (item.details as any)
                          .inspectionAnswers;
                        console.log('inpectionAns', inspectionAnswers);
                        const coaAnswer: any = {};

                        questions.forEach((q) => {
                          // We only pick questions that have mapper prop
                          if (q.details.mapperProperty) {
                            const ans = inspectionAnswers?.details?.surveyAns.find(
                              (x) => x.questionId === q.id
                            );
                            if (ans) {
                              coaAnswer[q.details.mapperProperty] =
                                ans.answerValue;
                            }
                          }
                        });

                        const chemComp = spec['chemical-composition'];
                        const renderChemicalComposition = `<table border="1" style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0);">
                       <thead>
                        <tr>
                          <th style="border-color: rgb(0, 0, 0); width: 25%">Components</th>
                          <th style="border-color: rgb(0, 0, 0); width: 15%">Method</th>
                          <th colspan="2" style="border-color: rgb(0, 0, 0); width: 20%; text-align: center;">As per in-house Specifications</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">Test Value</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%;text-align: center;">Test Result</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer Spec</th>
                          <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer COA</th>
                        </tr> 
                       </thead>
                       <tbody>
                        ${coaAnswer.chemicalComposition
                          ?.map((x) => {
                            const comp = chemComp.find(
                              (y) => y.component === x.name
                            );
                            return `
                            <tr>
                              <td style="border-color: rgb(0, 0, 0);">${x.name}</td>
                              <td style="border-color: rgb(0, 0, 0);">${comp?.moa}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.expected}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${comp?.uom}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.actual}</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                              <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                            </tr>`;
                          })
                          .join('')}
                       </tbody>
                      </table>`;

                        const impurities = spec['assay-of-other-impurity'];

                        const renderImpurities = `<table border="1" style="border-collapse: collapse; width: 100%; border: 1px solid rgb(0, 0, 0);">
                      <thead>
                       <tr>
                         <th style="border-color: rgb(0, 0, 0); width: 25%">Components</th>
                         <th style="border-color: rgb(0, 0, 0); width: 15%">Method</th>
                         <th colspan="2" style="border-color: rgb(0, 0, 0); width: 20%; text-align: center;">As per in-house Specifications</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">Test Value</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%;text-align: center;">Test Result</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer Spec</th>
                         <th style="border-color: rgb(0, 0, 0); width: 10%; text-align: center;">As per Manufacturer COA</th>
                       </tr> 
                      </thead>
                      <tbody>
                       ${coaAnswer.impurities
                         ?.map((x) => {
                           const imp = impurities.find(
                             (y) => y.impurity === x.name
                           );
                           if (imp && imp['test-done'] === 'yes') {
                             return `
                             <tr>
                               <td style="border-color: rgb(0, 0, 0);">${x.name}</td>
                               <td style="border-color: rgb(0, 0, 0);">${imp?.MoA}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.expected}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${imp['unit-of-measurement']}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">${x.actual}</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                               <td style="border-color: rgb(0, 0, 0); text-align: center;">todo</td>
                             </tr>`;
                           }
                           return '';
                         })
                         .join('')}
                      </tbody>
                     </table>`;

                        // console.log({
                        //   grn: item,
                        //   purchaseOrder: item.purchaseOrder,
                        //   item: item.purchaseOrder.details?.itemDetails,
                        //   specification: spec,
                        //   coaAnswer,
                        // });
                        if (url) {
                          await downloadTemplatePdf({
                            templateUrl: url,
                            dynamicRenderer: {
                              renderChemicalComposition: renderChemicalComposition,
                              renderImpurities: renderImpurities,
                            },
                            coaData: {
                              grn: item,
                              purchaseOrder: item.purchaseOrder,
                              item:
                                item.purchaseOrder.details?.itemDetails?.grade
                                  ?.item,
                              specification: spec,
                              coaAnswer,
                            },
                            getFile,
                          });
                        }
                      }
                    }}
                    className='inline mx-1 p-1 border border-red-500 rounded'
                  >
                    <Document className='text-red-500 inline w-5' /> COA
                  </div>
                ) : null}
              </div>
            ),
          },
          {
            value: item ? item.status : '--',
          },
        ],
      })) || [];

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Goods Received',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Edit',
              show: ({ fulfillment }) => {
                return ['scheduled-for-delivery'].includes(fulfillment.status);
              },
              behaviour: 'modal',
              modal: {
                title: 'Edit PO fulfilment (GRN)',
                content: ({
                  data: { fulfillment },
                  onClose,
                }: {
                  data: { fulfillment: PurchaseOrderFulfilment };
                  onClose: () => void;
                }) => {
                  const Form = renderCreateEditPurchaseOrderFulfilmentForm({
                    useCombinedPurchaseOrderFulfilment,
                  });
                  return (
                    <Form
                      fulfillment={fulfillment}
                      purchaseOrder={fulfillment.purchaseOrder}
                      onClose={onClose}
                    />
                  );
                },
              },
            },
            {
              name: 'Mark Delivered',
              show: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) =>
                Boolean(
                  fulfillment.status === 'scheduled-for-delivery' &&
                    fulfillment.details?.invoiceNo &&
                    fulfillment.details?.eWayBillNumber &&
                    fulfillment.details?.invoiceQuantity &&
                    fulfillment.details?.uom &&
                    fulfillment.details?.AWBorBlNumber
                ),
              behaviour: 'confirm',
              onConfirm: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) => {
                return {
                  title: 'Mark as delivered',
                  onConfirm: async () => {
                    setFulfilments({
                      id: fulfillment.id,
                      status: 'delivered',
                      details: {
                        arrivedAt: moment().format('YYYY-MM-DDTHH:MM'),
                      },
                    } as PurchaseOrderFulfilment);
                  },
                };
              },
            },
            {
              name: 'Send For Inspection',
              show: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) => {
                if (fulfillment.status === 'delivered') {
                  return true;
                }
                return false;
              },
              behaviour: 'modal',
              modal: {
                title: 'Send for Inspection',
                content: ({ data: { fulfillment }, onClose }) => {
                  const cont = async ({
                    inspectors,
                  }: {
                    inspectors: IdName[];
                  }) => {
                    const finalData = {
                      id: fulfillment.id,
                      status: 'under-inspection',
                      underInspection: true,
                      details: {
                        inspectors: inspectors.map((i) => i.id),
                        // TODO: Currently taking inspectors from UI should also include default inspectors from item master
                      },
                    };
                    await setFulfilments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                    onClose();
                    toast(
                      <div
                        className='cursor-pointer'
                        onClick={() =>
                          (window.location.href =
                            '/purchase/deliveries/purchase-item-inspection')
                        }
                      >
                        <div>Item sent for inspection</div>
                        <div>Click to view</div>
                      </div>
                    );
                  };

                  const Form = renderPoInspectionForm({ useCombinedUser });

                  return <Form onSave={cont} />;
                },
              },
            },
            {
              name: 'Inspect',
              behaviour: 'modal',
              modal: {
                title: 'Inspect',
                size: 'large',
                content: ({ data: { fulfillment }, onClose }) => {
                  const InspectionForm = renderInspectionForm({
                    itemService,
                    useCombinedQuestionnaireQuestion,
                  });
                  return (
                    <InspectionForm
                      item={fulfillment}
                      onClose={onClose}
                      setPurchaseFulfillment={setPurchaseFulfillment}
                    />
                  );
                },
              },
              show: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) => {
                if (fulfillment.status === 'under-inspection') {
                  if ((fulfillment.details as any)?.inspectionAnswers) {
                    return false;
                  }

                  return true;
                }

                return false;
              },
            },
            {
              name: 'Approve',
              show: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) => {
                return fulfillment.status === 'under-inspection';
              },
              behaviour: 'confirm',
              onConfirm: ({ fulfillment }) => {
                const finalData = {
                  id: fulfillment.id,
                  underInspection: false,
                  status: 'approved',
                  isApproved: true,
                };
                return {
                  title: `GRN No. ${fulfillment?.details.grnNumber || ''}`,
                  message: `Are you sure? Approving ${
                    fulfillment?.details.quantity || ''
                  } ${fulfillment?.details.uom || ''} ${
                    fulfillment?.purchaseOrder?.details?.itemDetails?.name
                  }`,
                  onConfirm: async () => {
                    setFulfilments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                  },
                };
              },
            },
            {
              name: 'Reject',
              show: ({
                fulfillment,
              }: {
                fulfillment: PurchaseOrderFulfilment;
              }) => {
                return fulfillment.status === 'under-inspection';
              },
              behaviour: 'confirm',
              onConfirm: ({ fulfillment }) => {
                const finalData = {
                  id: fulfillment.id,
                  underInspection: false,
                  isApproved: false,
                  status: 'rejected',
                };
                return {
                  title: `GRN No. ${fulfillment?.details.grnNumber || ''}`,
                  message: `Are you sure? Rejecting ${
                    fulfillment?.details.quantity || ''
                  } ${fulfillment?.details.uom || ''} ${
                    fulfillment?.purchaseOrder?.details?.itemDetails?.name
                  }`,
                  onConfirm: async () => {
                    setFulfilments(
                      (finalData as unknown) as PurchaseOrderFulfilment
                    );
                  },
                };
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
