import { Labels } from '@erp_core/erp-icons/icons/web/labels';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
} from '@erp_core/erp-ui-components';

import { TableBody, TableHeader } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedGatePass } from '../../../../hooks/hrd/employee/profile/gate-pass/use-gate-pass';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderGatePassForm } from '../forms/gate-pass';
import { BulkGatepassActionForm } from './form/gatepass-approval-form';

export const renderMonthGatePasses = ({
  mode,
  queryEmpId,
  useCombinedGatePass,
  useCombinedEmployeeProfile,
  useUserAuthorization,
  useCurrentUserRoles,
  userRendererService,
}: {
  mode: 'month-book' | 'employee';
  queryEmpId?: string;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedGatePass: UseCombinedGatePass;
  useUserAuthorization: UseUserAuthorization;
  useCurrentUserRoles: UseCurrentUserRoles;
  userRendererService: UserRendererInterface;
}) => {
  return function GatePasses() {
    const { getSync: getEmployeeSync } = useCombinedEmployeeProfile();
    const {
      list: gatePasses,
      getAll: getGatePasses,
      loading,
    } = useCombinedGatePass();
    const { syncSet: setGatePass } = useCombinedGatePass();
    const { user: currentUser } = useContext(UserContext);
    const { getAll: getAllUsers, list: users } = useCombinedEmployeeProfile();
    const { get: getAllowedActions } = useUserAuthorization();
    const { data: currentUserRoles } = useCurrentUserRoles();

    const isSystemAdmin = currentUserRoles?.find(
      (r) => r.name === 'System-Admin'
    )
      ? true
      : false;

    useEffect(() => {
      getAllUsers({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const [date, setDate] = useState(
      localStorage.getItem('gatepass-month') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      getGatePasses(
        mode === 'month-book'
          ? { date, crossGroup: 'true' }
          : { date, crossGroup: 'true', employeeId: queryEmpId }
      );
      localStorage.setItem('gatepass-month', date);
      // eslint-disable-next-line
    }, [date, queryEmpId]);

    const GatePassForm = renderGatePassForm({ useCombinedEmployeeProfile });

    // const GatePassForm1 = renderGatePassForm({ useEmployees, useCombinedLeave });

    const editGatePass = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setGatePass(finalData);
      toast('Gate pass edited sucessfully');
      getGatePasses({ date, crossGroup: 'true' });
    };

    const approveGatePass = async (id: string) => {
      const finalData = {
        id,
        status: 'approved',
      };
      await setGatePass(finalData as any);
      toast('Approved sucessfully');
      getGatePasses({ date, crossGroup: 'true' });
    };

    const issuedApproveGatePass = async (id: string) => {
      const finalData = {
        id,
        details: {
          issuedApprovedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          issuedApprovedAt: moment().utc().format(),
        },
      };
      await setGatePass(finalData as any);
      toast('Issued/Approved sucessfully');
      getGatePasses({ date, crossGroup: 'true' });
    };

    const allowedCheckoutGatePass = async (id: string) => {
      const finalData = {
        id,
        details: {
          allowedCheckOutBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          allowedCheckOutAt: moment().utc().format(),
        },
      };
      await setGatePass(finalData as any);
      toast('Checkout gatepass sucessfully');
      getGatePasses({ date, crossGroup: 'true' });
    };

    const allowedCheckinGatePass = async (id: string) => {
      const finalData = {
        id,
        details: {
          allowedCheckInBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          allowedCheckInAt: moment().utc().format(),
        },
      };
      await setGatePass(finalData as any);
      toast('Checkin gatepass sucessfully');
      getGatePasses({ date, crossGroup: 'true' });
    };

    const cancelGatePass = async (id: string) => {
      const finalData = {
        id,
        status: 'cancelled',
      };
      await setGatePass(finalData as any);
      toast('Cancelled sucessfully');
      getGatePasses({ date });
    };

    const saveGatePass = async (form: any) => {
      const empDetails = await getEmployeeSync(form.employee.id);
      try {
        const finalData = {
          ...form,
          company: empDetails.company,
          issuedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setGatePass(finalData);

        toast('Gate pass created sucessfully');
        getGatePasses({ date, crossGroup: 'true' });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Card = renderCardComponent();
    const CardHeader: CardHeader = {
      title: 'Gate Passes',
      icon: (
        <Labels className='w-8 h-8 stroke-none fill-indigo-900 inline self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
        {
          type: 'button',
          show: () => {
            if (mode === 'month-book') {
              return true;
            }

            if (currentUser.id === queryEmpId) {
              return true;
            }

            return false;
          },
          button: {
            name: 'Add Gate Pass',
            behaviour: 'modal',
            modal: {
              title: 'Add Gatepass',
              content: ({ onClose }) => {
                return (
                  <GatePassForm
                    data={{
                      name: '',
                      type: '',
                      exitTime: '',
                      entryTime: '',
                      employee:
                        mode === 'employee' && currentUser?.id === queryEmpId
                          ? { id: currentUser.id, name: currentUser.name }
                          : ({ id: '', name: '' } as any),
                      date: date,
                      reason: '',
                    }}
                    currentUser={
                      mode === 'employee' && currentUser?.id === queryEmpId
                    }
                    onSave={(form) => {
                      saveGatePass(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const TableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Name' },
        { name: 'Type' },
        { name: 'Employee' },
        { name: 'Exit Time' },
        { name: 'Entry Time' },
        { name: 'Reason' },
        { name: 'Status' },
        { name: 'Issued / Approved By' },
      ],
    ];

    function addActions(): TableActionsType[] {
      if (mode === 'employee') {
        return [];
      }
      return [
        {
          name: 'Edit',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (['issued'].includes(gatepass.status)) {
              return true;
            }

            // if (isSystemAdmin) {
            //   if (['approved'].includes(gatepass.status)) {
            //     return true;
            //   }
            // }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { gatepass },
              onClose,
            }: {
              data: {
                gatepass: GatePassType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <GatePassForm
                    onSave={async (e) => {
                      await editGatePass(e, gatepass.id);
                      onClose();
                    }}
                    data={gatepass as any}
                    isEdit={true}
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (
              ['issued'].includes(gatepass.status) &&
              users?.find((u) => u.id === gatepass.employee.id)?.details
                ?.authorizations?.attendanceAuthorizer?.id === currentUser.id
            ) {
              return true;
            }
            if (isSystemAdmin) {
              return true;
            }

            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ gatepass }) => {
            return {
              title: 'Are you sure you want to approve Gatepass?',
              message: `Approve Gatepass for ${gatepass.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await approveGatePass(gatepass.id);
              },
            };
          },
        },
        {
          name: 'Issued/Approve',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (
              ['issued'].includes(gatepass.status) &&
              gatepass.details?.issuedApprovedAt === undefined &&
              gatepass.details?.allowedCheckOutAt === undefined &&
              gatepass.details?.allowedCheckInAt === undefined &&
              users?.find((u) => u.id === gatepass.employee.id)?.details
                ?.authorizations?.attendanceAuthorizer?.id === currentUser.id
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ gatepass }) => {
            return {
              title: 'Are you sure you want to Issued-Approve Gatepass?',
              message: `Issued-Approve Gatepass for ${gatepass.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await issuedApproveGatePass(gatepass.id);
              },
            };
          },
        },
        {
          name: 'Allowed Checkout',
          auth: 'UI:BTN-GTP-CHECKOUT:VIEW',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (
              ['issued'].includes(gatepass.status) &&
              gatepass.details?.issuedApprovedAt !== undefined &&
              gatepass.details?.allowedCheckOutAt === undefined &&
              gatepass.details?.allowedCheckInAt === undefined
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ gatepass }) => {
            return {
              title: 'Are you sure you want to Allow Checkout Gatepass?',
              message: `Allow Checkout Gatepass for ${gatepass.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await allowedCheckoutGatePass(gatepass.id);
              },
            };
          },
        },
        {
          name: 'Allowed Checkin',
          auth: 'UI:BTN-GTP-CHECKIN:VIEW',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            if (
              ['issued'].includes(gatepass.status) &&
              gatepass.details?.issuedApprovedAt !== undefined &&
              gatepass.details?.allowedCheckOutAt !== undefined &&
              gatepass.details?.allowedCheckInAt === undefined &&
              users?.find((u) => u.id === gatepass.employee.id)?.details
                ?.authorizations?.attendanceAuthorizer?.id === currentUser.id
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ gatepass }) => {
            return {
              title: 'Are you sure you want to Allow CheckIn Gatepass?',
              message: `Allow CheckIn Gatepass for ${gatepass.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await allowedCheckinGatePass(gatepass.id);
              },
            };
          },
        },
        {
          name: 'Cancel',
          show: ({ gatepass }: { gatepass: GatePassType }) => {
            // we want to give ability to cancel only when the gatepass
            // is in pending state.
            if (!['cancelled'].includes(gatepass.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ gatepass }) => {
            return {
              title: 'Are you sure you want to Cancel Gatepass?',
              message: `Cancel Gatepass for ${gatepass.employee.name}?`,
              type: 'warning',
              onConfirm: async () => {
                await cancelGatePass(gatepass.id);
              },
            };
          },
        },
      ];
    }

    function renderTableBodyMapper(gatePasses?: GatePassType[]) {
      return (
        gatePasses?.map((p) => ({
          rowData: {
            gatepass: p,
          },
          cells: [
            {
              value: p.date,
            },
            {
              value: p.name,
            },
            {
              value: p.type,
            },
            {
              value: (
                <userRendererService.userCard
                  size='small'
                  id={p.employee.id}
                  name={p.employee.name}
                  link={true}
                />
              ),
            },
            {
              value: (
                <>
                  <span>{p.exitTime}</span>
                  <br />{' '}
                  {p.details?.allowedCheckOutAt ? (
                    <span
                      title={`Recorded by ${p.details?.allowedCheckOutBy?.name}`}
                    >
                      {moment(p.details?.allowedCheckOutAt).format('HH:mm')}
                      <span>{'  (CO)'}</span>
                    </span>
                  ) : (
                    <span className='font-bold'></span>
                  )}
                </>
              ),
            },
            {
              value: (
                <>
                  <span>{p.entryTime}</span>
                  <br />{' '}
                  {p.details?.allowedCheckInAt ? (
                    <span
                      title={`Recorded by ${p.details?.allowedCheckInBy?.name}`}
                    >
                      {moment(p.details?.allowedCheckInAt).format('HH:mm')}
                      <span>{'  (CI)'}</span>
                    </span>
                  ) : (
                    <span className='font-bold'></span>
                  )}
                </>
              ),
            },
            {
              value: p.reason,
            },
            {
              value: p.status,
            },
            {
              value: (
                <div>
                  <div>
                    Issued By{' '}
                    <userRendererService.userCard
                      size='small'
                      id={p.issuedBy.id}
                      name={p.issuedBy.name}
                      link={true}
                    />
                  </div>
                  {p.status === 'approved' ? (
                    <div>
                      Approved By{' '}
                      <userRendererService.userCard
                        size='small'
                        id={p.lastModifiedBy.id}
                        name={p.lastModifiedBy.name}
                        link={true}
                      />
                    </div>
                  ) : null}
                </div>
              ),
            },
          ],
        })) || []
      );
    }

    const TableBody: TableBody = renderTableBodyMapper(gatePasses);

    const filteredGatepasses = _.sortBy(
      gatePasses?.filter((x) => {
        const emp = users?.find((e) => e.id === x.employee.id);
        if (
          emp &&
          (emp.details?.authorizations?.attendanceAuthorizer?.id ||
            emp.details?.authorizations?.attendanceSupervisor?.id) ===
            currentUser.id
        ) {
          return true;
        }
        return false;
      }),
      (ot) => {
        switch (ot.status) {
          case 'issued':
            return 0;
          case 'approved':
            return 1;
          case 'redeemed':
            return 2;
          default:
            return 3;
        }
      }
    );

    const FilteredTableBody: TableBody = renderTableBodyMapper(
      filteredGatepasses
    );

    const MultiSelect: MultiSelect = {
      actions:
        mode === 'employee'
          ? []
          : [
              {
                name: 'Approve',
                show: () => true,
                behaviour: 'modal',
                modal: {
                  title: 'Bulk Approve Gatepass',
                  content: ({ entries, onClose }) => {
                    const list = filteredGatepasses.filter((x, idx) =>
                      entries.includes(idx)
                    );
                    return (
                      <BulkGatepassActionForm
                        list={list}
                        onClose={onClose}
                        setGatePass={setGatePass}
                        currentUser={currentUser}
                        getGatePasses={getGatePasses}
                        date={date}
                      />
                    );
                  },
                },
              },
            ],
    };

    const CardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                {mode === 'month-book' ? (
                  <div className='my-5'>
                    <Card
                      header={{ title: 'Requests for you' }}
                      body={{
                        type: 'jsx-component',
                        body: (
                          <Table
                            header={TableHeader}
                            body={FilteredTableBody}
                            multiSelect={MultiSelect}
                            actions={addActions()}
                            auth={{ actions: getAllowedActions().actions }}
                          />
                        ),
                      }}
                    />
                  </div>
                ) : null}

                <div className='my-5'>
                  <Card
                    header={{ title: 'All Requests' }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={TableBody}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };

    return (
      <div className='pb-52'>
        <Card header={CardHeader} body={CardBody} />
      </div>
    );
  };
};
