/* eslint-disable react-hooks/exhaustive-deps */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { LeadCustomerEnquiryType } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  FormField,
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
import { UseCombinedLeadCustomer } from '../../../../hooks/order/sales/lead-customer/use-lead-customer';
import {
  renderAddSalesEnquiryV2Form,
  SalesEnquiryFields,
} from '../../sales-enquiry/forms/add-sales-enquiry-v2';

type RenderEnquiryFollowupProps = {
  leadCustomerEnquiry: LeadCustomerEnquiryType;
  loading: boolean;
  setLeadCustomerEnquiry: (
    s: LeadCustomerEnquiryType
  ) => Promise<LeadCustomerEnquiryType>;
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedItem: UseCombinedItem;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedMetric: UseCombinedMetric;
  useCombinedClient: UseCombinedClient;
};

export const renderEnquiryFollowupList = ({
  leadCustomerEnquiry,
  loading,
  setLeadCustomerEnquiry,
  useCombinedLeadCustomer,
  useCombinedItem,
  useCombinedEmployeeProfile,
  useCombinedMetric,
  useCombinedClient,
}: RenderEnquiryFollowupProps) => {
  return function EnquiryFollowupList() {
    const {
      get: getLeadCustomer,
      resource: leadCustomerData,
    } = useCombinedLeadCustomer();

    useEffect(() => {
      if (leadCustomerEnquiry?.leadCustomer?.id) {
        getLeadCustomer(leadCustomerEnquiry?.leadCustomer?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    type AddFollowupForm = {
      date: string;
      type: string;
      contact: string;
      description: string;
      followedUpDate: string;
      stage: string;
    };

    const handleCreateSalesEnquiry = async (formData: SalesEnquiryFields) => {
      try {
        // console.log(formData);
        const finalData: Partial<LeadCustomerEnquiryType> = {
          ...leadCustomerEnquiry,
          id: leadCustomerEnquiry?.id,
          details: {
            ...leadCustomerEnquiry?.details,
            stage: formData?.stage || '',
            enquiryMode: formData?.enquiryMode || '',
            enquiryReferenceNum: formData?.enquiryRefNo || '',
            deliveryLocation: formData?.deliveryLocation || '',
          },
        };
        await setLeadCustomerEnquiry(finalData as LeadCustomerEnquiryType);
        toast('Created Enquiry for this lead successfully');
      } catch (err) {
        // console.log('Error');
        toast((err as any).message);
      }
    };

    const latestFollowedUpDateEntry = _.maxBy(
      leadCustomerEnquiry?.details?.followUps,
      'followedUpDate'
    );

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Follow-ups',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title:
                leadCustomerEnquiry?.details?.stage === 'Enquiry'
                  ? 'Add Enquiry Follow-up'
                  : 'Add Lead Follow-up',
              content: ({ onClose }) => {
                if (leadCustomerEnquiry.id) {
                  if (leadCustomerEnquiry?.details?.stage === 'Enquiry') {
                    const initialOptions = [{ value: '', text: 'Select' }];

                    const fields: Array<FormField<AddFollowupForm>> = [
                      {
                        property: 'date',
                        type: 'date',
                        required: true,
                        label: 'Date',
                      },
                      {
                        property: 'type',
                        type: 'select',
                        required: true,
                        label: 'Follow-up Type',
                        options: [
                          { text: 'Select', value: '' },
                          { text: 'Verbal', value: 'verbal' },
                          { text: 'Email', value: 'email' },
                          { text: 'Phone Call', value: 'phone-call' },
                          { text: 'Message', value: 'Message' },
                          { text: 'Whatsapp', value: 'Whatsapp' },
                          { text: 'Notes', value: 'notes' },
                        ],
                      },
                      {
                        property: 'stage',
                        type: 'select',
                        label: 'Stage',
                        required: true,
                        readonly: true,
                        options: [
                          { text: 'Select', value: '' },
                          // { text: 'Lead Generated', value: 'Lead Generated' },
                          // { text: 'Contacted', value: 'Contacted' },
                          // { text: 'Meeting Scheduled', value: 'Meeting Scheduled' },
                          // { text: 'Sample Sent', value: 'Sample Sent' },
                          // { text: 'Qualified to Buy', value: 'Qualified to Buy' },
                          {
                            text: 'Enquiry',
                            value: 'Enquiry',
                          },
                          // { text: 'Offer', value: 'Offer' },
                          // { text: 'Disqualified', value: 'Disqualified' },
                          // { text: 'Closed Lost', value: 'Closed Lost' },
                        ],
                      },
                      {
                        property: 'contact',
                        type: 'select',
                        label: 'Contact',
                        required: true,
                        options: [
                          ...initialOptions,
                          ...(leadCustomerData?.details?.contact?.map((m) => ({
                            value: m.name,
                            text: m.name,
                          })) || []),
                        ],
                      },
                      {
                        property: 'description',
                        type: 'input',
                        label: 'Description',
                        required: true,
                      },
                      {
                        property: 'followedUpDate',
                        type: 'date',
                        required: true,
                        label: 'Follow up-date',
                      },
                    ];

                    const Form = renderFormV2<AddFollowupForm>({
                      style: 'md:w-1/2',
                      fieldsData: fields,
                      initialFormState: {
                        date: moment().format('YYYY-MM-DD') || '',
                        type: '',
                        contact: '',
                        description: '',
                        followedUpDate: '',
                        stage: leadCustomerEnquiry?.details?.stage || '',
                      },
                      onSubmit: async (formData) => {
                        const finalData = {
                          ...leadCustomerEnquiry,
                          id: leadCustomerEnquiry?.id,
                          details: {
                            ...leadCustomerEnquiry.details,
                            // stage: formData?.stage,
                            followUps: [
                              ...(leadCustomerEnquiry?.details?.followUps ||
                                []),
                              {
                                date: formData?.date || '',
                                type: formData?.type || '',
                                followupStage: formData?.stage || '',
                                contact: formData?.contact || '',
                                description: formData?.description || '',
                                followedUpDate: formData?.followedUpDate || '',
                              },
                            ],
                          },
                        } as LeadCustomerEnquiryType;
                        await setLeadCustomerEnquiry(
                          finalData as LeadCustomerEnquiryType
                        );
                        onClose();
                      },
                      allowUnchanged: true,
                      mapTToU: (b) => b as AddFollowupForm,
                    });

                    return <Form />;
                  }

                  const initialOptions = [{ value: '', text: 'Select' }];

                  const fields: Array<FormField<AddFollowupForm>> = [
                    {
                      property: 'date',
                      type: 'date',
                      required: true,
                      label: 'Date',
                    },
                    {
                      property: 'type',
                      type: 'select',
                      required: true,
                      label: 'Follow-up Type',
                      options: [
                        { text: 'Select', value: '' },
                        { text: 'Verbal', value: 'verbal' },
                        { text: 'Email', value: 'email' },
                        { text: 'Phone Call', value: 'phone-call' },
                        { text: 'Message', value: 'Message' },
                        { text: 'Whatsapp', value: 'Whatsapp' },
                        { text: 'Notes', value: 'notes' },
                      ],
                    },
                    {
                      property: 'stage',
                      type: 'select',
                      label: 'Stage',
                      required: true,
                      // readonly: true,
                      options: [
                        { text: 'Select', value: '' },
                        // { text: 'Lead Generated', value: 'Lead Generated' },
                        { text: 'Contacted', value: 'Contacted' },
                        {
                          text: 'Meeting Scheduled',
                          value: 'Meeting Scheduled',
                        },
                        { text: 'Sample Sent', value: 'Sample Sent' },
                        { text: 'Qualified to Buy', value: 'Qualified to Buy' },
                        {
                          text: 'Won-Converted to Enquiry',
                          value: 'Won -Converted to Enquiry',
                        },
                        { text: 'Disqualified', value: 'Disqualified' },
                        { text: 'Closed Lost', value: 'Closed Lost' },
                      ],
                    },
                    {
                      property: 'contact',
                      type: 'select',
                      label: 'Contact',
                      required: true,
                      options: [
                        ...initialOptions,
                        ...(leadCustomerData?.details?.contact?.map((m) => ({
                          value: m.name,
                          text: m.name,
                        })) || []),
                      ],
                    },
                    {
                      property: 'description',
                      type: 'input',
                      label: 'Description',
                      required: true,
                    },
                    {
                      property: 'followedUpDate',
                      type: 'date',
                      required: true,
                      label: 'Follow up-date',
                    },
                  ];

                  const Form = renderFormV2<AddFollowupForm>({
                    style: 'md:w-1/2',
                    fieldsData: fields,
                    initialFormState: {
                      date: moment().format('YYYY-MM-DD') || '',
                      type: '',
                      contact: '',
                      description: '',
                      followedUpDate: '',
                      stage: leadCustomerEnquiry?.details?.stage || '',
                    },
                    onSubmit: async (formData) => {
                      const finalData = {
                        ...leadCustomerEnquiry,
                        id: leadCustomerEnquiry?.id,
                        details: {
                          ...leadCustomerEnquiry.details,
                          // stage: formData?.stage,
                          followUps: [
                            ...(leadCustomerEnquiry?.details?.followUps || []),
                            {
                              date: formData?.date || '',
                              type: formData?.type || '',
                              contact: formData?.contact || '',
                              followupStage: formData?.stage || '',
                              description: formData?.description || '',
                              followedUpDate: formData?.followedUpDate || '',
                            },
                          ],
                        },
                      } as LeadCustomerEnquiryType;
                      await setLeadCustomerEnquiry(
                        finalData as LeadCustomerEnquiryType
                      );
                      onClose();
                    },
                    allowUnchanged: true,
                    mapTToU: (b) => b as AddFollowupForm,
                  });

                  return <Form />;
                }

                return <></>;
              },
            },
          },
        },
        {
          type: 'button',
          button: {
            name: 'Add Enquiry',
            behaviour: 'modal',
            modal: {
              title: 'Add Enquiry',
              content: ({ onClose }) => {
                if (
                  latestFollowedUpDateEntry?.followupStage ===
                  'Qualified to Buy'
                ) {
                  const Form = renderAddSalesEnquiryV2Form({
                    useCombinedItem,
                    useCombinedEmployeeProfile,
                    useCombinedLeadCustomer,
                    useCombinedMetric,
                    useCombinedClient,
                  });

                  return (
                    <Form
                      onSave={(formData) => {
                        handleCreateSalesEnquiry(formData);
                        onClose();
                      }}
                      enqdata={leadCustomerEnquiry}
                    />
                  );
                }

                return <></>;
              },
            },
          },
        },
        {
          type: 'jsx',
          jsx:
            latestFollowedUpDateEntry?.followupStage === 'Enquiry' ? (
              <LoadingButton
                defaultStyle='p-2 border rounded-lg bg-green-500'
                behaviorFn={async () => {
                  console.log('add offer');
                }}
                text='Add Offer'
              />
            ) : (
              <></>
            ),
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Type of Communication' },
        { name: 'Followed-up Stage' },
        { name: 'Customer Contact' },
        { name: 'Description' },
        { name: 'Attachments' },
        { name: 'Follow up-date' },
      ],
    ];

    const tableBody: TableBody =
      leadCustomerEnquiry?.details?.followUps?.map((l, idx) => ({
        cells: [
          { value: l?.date || '-' },
          { value: l?.type || '-' },
          { value: l?.followupStage || '' },
          {
            value: `${l.contact || '--'}`,
          },
          {
            value: `${l.description || '--'}`,
          },
          { value: '--' },
          { value: `${l?.followedUpDate || '--'}` },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <>
        <div className='w-full space-y-3'>
          {loading ? (
            <Recat className='h-5 inline animate-pulse mx-4' />
          ) : (
            <Card header={cardHeader} body={cardBody} />
          )}
        </div>
      </>
    );
  };
};
