import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';

export type MisconductFormType = {
  employee: IdName;
  date: string;
  reason: string;
};
type RenderAddMisconductForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
};

// eslint-disable-next-line
export function renderAddMisconductForm({
  useCombinedEmployeeProfile,
}: RenderAddMisconductForm) {
  return function AddMisconductForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: MisconductType) => Promise<void>;
    isEdit?: boolean;
    data?: MisconductType;
  }): JSX.Element {
    const validateForm = async (form) => {
      if (isEdit) return '';
      if (!form.date) return '';
      if (moment(form.date).isAfter(moment())) {
        return { date: 'Date cannot be in future' } as any;
      }
    };
    const formProps: FormProps<MisconductFormType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
          required: true,
          label: 'Employee Name',
        },
        {
          property: 'date',
          type: 'date',
          label: 'Date',
          required: true,
          readonly: isEdit,
          validate: validateForm,
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
      ],
      initialFormState: {
        employee: '',
        reason: '',
        date: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (f) => {
        await onSave({
          employee: f.employee || data?.employee,
          date: f.date || data?.date,
          reason: f.reason || data?.reason,
        } as MisconductType);
      },
    };

    const Form = renderFormV2<MisconductFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<MisconductFormType>
    ): MisconductFormType {
      return (b as unknown) as MisconductFormType;
    }
  };
}
