import {
  CombinedAttDataType,
  EmployeeProfileType,
} from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';
import { calculateShiftDuration } from './calculate-shift-duration';

export function computePayableDayRatios(
  attendances: Array<CombinedAttDataType>
): { total: number; adjusted: number } {
  let total = 0;
  let adjusted = 0;

  attendances.forEach((x) => {
    const cpr = computePayableDayRatio(x);
    total += cpr.total;
    adjusted += cpr.adjusted;
  });

  return {
    total: parseFloat(total.toFixed(2)),
    adjusted: parseFloat(adjusted.toFixed(2)),
  };
}

export function computePayableDayRatio(
  x: CombinedAttDataType
): { total: number; adjusted: number } {
  if (!x.finalized) {
    return {
      total: 0,
      adjusted: 0,
    };
  }

  if (x.details?.summary?.payableDay) {
    const shiftHours =
      x.details?.summary && x.details.summary.hasOwnProperty('shiftDuration')
        ? x.details?.summary?.shiftDuration
        : calculateShiftDuration({
            employee: x?.employeeProfile,
          });

    const payableDayRatio = parseFloat(
      ((x.details?.summary?.payableWorkHours || 0) / shiftHours).toFixed(2)
    );
    const total = payableDayRatio;
    let adjusted = 0;
    if (x.details?.summary?.layoffRule) {
      if (x.details?.summary?.layoffRule?.value === 'full-day-pay') {
        adjusted += payableDayRatio;
      } else if (x.details?.summary?.layoffRule?.value === '75%-pay') {
        adjusted += payableDayRatio * 0.75;
      } else if (x.details?.summary?.layoffRule?.value === '50%-pay') {
        adjusted += payableDayRatio * 0.5;
      } else {
        adjusted += 0;
      }
    } else {
      adjusted += payableDayRatio;
    }

    return {
      total,
      adjusted,
    };
  }

  return {
    total: 0,
    adjusted: 0,
  };
}

export function calculateWeekOffs(
  month: string,
  attendances: Array<CombinedAttDataType>,
  employee: EmployeeProfileType
) {
  const scheduledWeeklyOffCount = attendances.filter(
    (x) =>
      x.finalized &&
      ['week-off', 'worked-on-week-off', 'week-off-leave'].includes(x.status)
  ).length;

  const totalOfficialDays = moment(`${month}-01`).daysInMonth();
  const payableDaysRatio = parseFloat(
    attendances
      .reduce((x, curr) => {
        const shiftHours =
          curr.details?.summary &&
          curr.details.summary.hasOwnProperty('shiftDuration')
            ? curr.details?.summary?.shiftDuration
            : calculateShiftDuration({ employee: employee });

        const payableDayRatio = parseFloat(
          ((curr.details?.summary?.payableWorkHours || 0) / shiftHours).toFixed(
            2
          )
        );

        if (payableDayRatio) {
          return x + payableDayRatio;
        }

        return x;
      }, 0)
      .toFixed(2)
  );

  const totalPaidLeaves = attendances.reduce((x, curr) => {
    let count = 0;

    if (curr.finalized && curr.details?.summary?.leaves) {
      curr.details?.summary.leaves.forEach((lv, idx) => {
        if (idx < 2) {
          const types = [
            'casual-leave',
            'compensatory-off',
            'maternity-leave',
            'on-job-accident-leave',
            'privilege-leave',
            'sick-leave',
            'layoff-leave',
          ];
          if (types.includes(lv.type)) {
            count += 0.5;
          }
        }
      });
    }

    return x + count;
  }, 0);

  const compOffs = attendances.reduce((x, curr) => {
    let count = 0;

    if (curr.finalized && curr.details?.summary?.leaves) {
      curr.details?.summary.leaves.forEach((lv, idx) => {
        if (idx < 2) {
          if (lv.type === 'compensatory-off') {
            count += 0.5;
          }
        }
      });
    }

    return x + count;
  }, 0);

  const onJobAccidentLeaves = attendances.reduce((x, curr) => {
    let count = 0;

    if (curr.finalized && curr.details?.summary?.leaves) {
      curr.details?.summary.leaves.forEach((lv, idx) => {
        if (idx < 2) {
          if (lv.type === 'on-job-accident-leave') {
            count += 0.5;
          }
        }
      });
    }

    return x + count;
  }, 0);

  let elgibleWeekOffCalculation = Math.round(
    scheduledWeeklyOffCount -
      (totalOfficialDays -
        ((payableDaysRatio - totalPaidLeaves + compOffs + onJobAccidentLeaves) *
          7) /
          6) /
        7
  );

  elgibleWeekOffCalculation =
    elgibleWeekOffCalculation > scheduledWeeklyOffCount
      ? scheduledWeeklyOffCount
      : elgibleWeekOffCalculation;

  return {
    scheduledWeekOff: scheduledWeeklyOffCount,
    eligibleWeekOff: elgibleWeekOffCalculation,
  };
}
