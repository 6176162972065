import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderHistory,
  renderInlineInput,
  useConfirm,
} from '@erp_core/erp-ui-components';
// import { renderTabsComponent } from '@erp_core/erp-ui-components';
import { DataArray, renderTabsV2Component } from '@erp_core/erp-ui-components';
import { Switch } from '@headlessui/react';
import {
  CubeIcon,
  HomeModernIcon,
  InboxStackIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
// import { UseEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
// import { UseBatchItemGodowns } from '../../../../hooks/inventory/item/batch/use-batch-item-godowns';
// import { UseShiftSchedules } from '../../../../hooks/planning/shift-schedule/use-shift-schedules';
import { GodownInterface } from '../../../../models/interfaces/inventory/generic/godown';
import { renderGodownAuthorisers } from './authoriser-tab';
import { renderGodownProfileTab } from './profile';
// import { renderGodownShiftConfig } from './shift-config';

type RenderGodownProfile = {
  hooks: {
    useCombinedGodown: UseCombinedGodown;
    // useBatchItemGodowns: UseBatchItemGodowns;
    // useShiftSchedules: UseShiftSchedules;
    useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
    useFileTransfer: UseFileTransfer;
    // useEmployeeProfile: UseEmployeeProfile;
    useUserAuthorization: UseUserAuthorization;
  };
  services: {
    godownService: GodownInterface;
  };
};

export function renderGodownProfile({
  hooks: {
    useCombinedGodown,
    // useBatchItemGodowns,
    // useShiftSchedules,
    useCombinedEmployeeProfile,
    // useEmployeeProfile,
    useUserAuthorization,
  },
  services: { godownService },
}: RenderGodownProfile): () => JSX.Element {
  const Card = renderCardComponent();
  const History = renderHistory();
  // const ShiftConfig = renderGodownShiftConfig({
  //   useBatchItemGodowns,
  //   useGodowns,
  //   useShiftSchedules,
  //   useEmployees,
  // });
  const GodownProfile = renderGodownProfileTab({ useCombinedGodown });
  const GodownAuthorisers = renderGodownAuthorisers({
    useCombinedEmployeeProfile,
  });

  return function ItemProfile(): JSX.Element {
    const { id } = useParams();

    // const Tabs = renderTabsComponent();
    const NewTabs = renderTabsV2Component();
    const { get: getAllowedActions } = useUserAuthorization();
    const {
      resource: godown,
      get,
      syncSet: setGodown,
      loading,
    } = useCombinedGodown();
    const { Confirm, openConfirm } = useConfirm();
    useEffect(() => {
      if (id) {
        get(id);
      }
      // eslint-disable-next-line
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useCombinedGodown,
      id,
    });
    const updateWorkAreaStatus = async (currentStatus: boolean) => {
      openConfirm({
        message: `Are your sure to ${
          currentStatus ? 'remove' : 'mark'
        } this godown as work area.`,
        onConfirm: async () => {
          await setGodown({
            id: godown?.id,
            type: !currentStatus ? 'work-area' : 'physical',
          } as Godown);
          get(id);
        },
      });
    };

    const updateSupervisorWorkAreaStatus = async (currentStatus: boolean) => {
      openConfirm({
        message: `Are your sure to ${
          currentStatus ? 'remove' : 'mark'
        } this godown as supervisor work area.`,
        onConfirm: async () => {
          await setGodown({
            id: godown?.id,
            type: !currentStatus ? 'supervisor-work-area' : 'physical',
          } as Godown);
          get(id);
        },
      });
    };

    const header: CardHeader = {
      title: `${godown?.name || id} [${godown?.legacyGodownId}]`,
      icon: godown?.details?.imageUrl ? (
        <img
          alt={godown.name}
          src={godown.details?.imageUrl}
          className='w-24 h-24 self-center'
        />
      ) : (
        <CubeIcon className='text-gray-800 w-24 h-full' />
      ),
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            <InlineInput
              name='description'
              value={
                godown?.description || 'Add a description for the godown...'
              }
            />
          </div>
          <div className='text-gray-700'>
            <a
              className='font-bold'
              href={`/inventory/godowns/${godown?.parentGodownId}`}
              target='_blank'
              rel='noreferrer'
            >
              <InboxStackIcon className='w-6 inline pr-1' />
              {godown?.parentGodownId === 'root'
                ? 'Root Godown'
                : godown?.parentGodownId}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${godown?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {godown?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${godown?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {godown?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(godown?.lastModifiedAt).fromNow()}
          </div>
          {godown && godown.id ? (
            <div className='flex space-x-2 w-fit justify-center items-center'>
              <span className='font-semibold'>
                <HomeModernIcon className='inline-block w-5 h-5 text-amber-600' />{' '}
                Work Area
              </span>
              <Switch
                checked={godown.type === 'work-area'}
                onChange={() =>
                  updateWorkAreaStatus(godown.type === 'work-area')
                }
                className={`${
                  godown.type === 'work-area' ? 'bg-blue-600' : 'bg-gray-200'
                } relative inline-flex h-4 w-8 items-center rounded-full`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    godown.type === 'work-area'
                      ? 'translate-x-4'
                      : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          ) : null}
          {godown && godown.id ? (
            <div className='flex space-x-2 w-fit justify-center items-center'>
              <span className='font-semibold'>🕵️‍♂️ Supervisor Work Area</span>
              <Switch
                checked={godown.type === 'supervisor-work-area'}
                onChange={() =>
                  updateSupervisorWorkAreaStatus(
                    godown.type === 'supervisor-work-area'
                  )
                }
                className={`${
                  godown.type === 'supervisor-work-area'
                    ? 'bg-blue-600'
                    : 'bg-gray-200'
                } relative inline-flex h-4 w-8 items-center rounded-full`}
              >
                <span className='sr-only'>Enable notifications</span>
                <span
                  className={`${
                    godown.type === 'supervisor-work-area'
                      ? 'translate-x-4'
                      : 'translate-x-1'
                  } inline-block h-3 w-3 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
          ) : null}
        </>
      ),
    };

    // const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   [
    //     'Profile',
    //     () => <GodownProfile godown={godown} setGodown={setGodown} />,
    //   ],
    // ]);

    const allowedActions = getAllowedActions();

    const newCategories: DataArray = [
      {
        name: 'profile',
        Fn: () =>
          godown ? (
            <GodownProfile godown={godown} setGodown={setGodown} />
          ) : (
            <></>
          ),
      },
      {
        name: 'authorisers',
        Fn: allowedActions.actions['UI:GOD-AUTHZ:VIEW']?.action
          ? () =>
              godown ? (
                <GodownAuthorisers
                  id={id}
                  loading={loading}
                  setGodown={setGodown}
                  godown={godown}
                />
              ) : (
                <></>
              )
          : () => <></>,
      },
      // {
      //   name: 'shift-config',
      //   Fn:
      //     godown?.details?.workArea || godown?.details?.supervisorWorkArea
      //       ? () => (
      //           <ShiftConfig
      //             godown={godown}
      //             setGodown={setGodown}
      //             openConfirm={openConfirm}
      //           />
      //         )
      //       : () => <></>,
      // },
      {
        name: 'history',
        Fn: () => <History useResource={useCombinedGodown} id={id} />,
      },
    ];

    // if (allowedActions.actions['UI:GOD-AUTHZ:VIEW']?.action) {
    //   categories.set('Authorisers', () => (
    //     <GodownAuthorisers
    //       id={id}
    //       loading={loading}
    //       setGodown={setGodown}
    //       godown={godown}
    //     />
    //   ));
    // }

    // Enabled for time-being since needed to work by Cata team
    // if (godown?.details?.workArea || godown?.details?.supervisorWorkArea) {
    //   categories.set('Shift Config', () => (
    //     <ShiftConfig
    //       godown={godown}
    //       setGodown={setGodown}
    //       openConfirm={openConfirm}
    //     />
    //   ));
    // }

    // categories.set(
    //   'History',
    //   renderHistoryTab({ id, godownService, useGodown })
    // );

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          {/* <Tabs
            data={categories}
            keyTab={nanoid()}
            defaultTabIndex={0}
            components={{ Tab }} 
          /> */}
          <NewTabs data={newCategories} manipulateLocation={true} />
        </>
      ),
    };

    return (
      <div>
        <Confirm />
        <Card header={header} body={body} />
      </div>
    );
  };
}
