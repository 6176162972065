/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
// import { useEffect } from 'react';
// import { useParams } from 'react-router-dom';
import { UseCombinedTemplate } from '../../../../../../hooks/admin/communication-template/use-template';
import { UseCombinedUser } from '../../../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedQuestionnaireQuestion } from '../../../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedPurchaseOrderFulfilment } from '../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { UseCombinedPurchaseOrder } from '../../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { ItemInterface } from '../../../../../../models/interfaces/inventory/item';
import { renderGoodsReceived } from '../../../../../../modules/purchase/goods-received';
import { renderCreateEditPurchaseOrderFulfilmentForm } from './forms/add-fulfilment';

export const renderPurchaseOrderFulfilmentDetails = ({
  useCombinedPurchaseOrder,
  useCombinedPurchaseOrderFulfilment,
  itemService,
  useFileTransfer,
  useCombinedQuestionnaireQuestion,
  useCombinedTemplate,
  useCombinedUser,
}: {
  useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
  useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
  itemService: ItemInterface;
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedTemplate: UseCombinedTemplate;
  useFileTransfer: UseFileTransfer;
  useCombinedUser: UseCombinedUser;
}) => {
  return function PurchaseOrderFulfilmentDetails({
    purchaseOrder,
  }: // cgs,
  {
    purchaseOrder: PurchaseOrder;
    // cgs: CompanyGroupSetting;
  }) {
    // const { id } = useParams();
    // const {
    //   data: fulfillments,
    //   getAll: getFulfillments,
    // } = usePurchaseOrderFulfilments();

    // useEffect(() => {
    //   getFulfillments({ purchaseOrderId: id });
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Purchase Orders Fulfilments (GRN)',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: `Add PO fulfilment (GRN) for ${purchaseOrder.grade?.name}`,
              content: ({ onClose }) => {
                const Form = renderCreateEditPurchaseOrderFulfilmentForm({
                  useCombinedPurchaseOrderFulfilment,
                });
                return <Form purchaseOrder={purchaseOrder} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const GoodsReceived = renderGoodsReceived({
      useCombinedPurchaseOrderFulfilment,
      itemService,
      useCombinedQuestionnaireQuestion,
      useCombinedTemplate,
      useFileTransfer,
      useCombinedUser,
    });

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <GoodsReceived selectedPO={purchaseOrder} />
        </>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
};
