import { DeliverySchedule } from '@erp_core/erp-types/dist/types/modules/common/schedules/delivery';

export const getDeliverySchedule = (
  deliverySchedule: DeliverySchedule,
  uom: string
) => {
  return (
    <div className='border rounded-lg p-2 mt-2'>
      {/* <p className='font-medium mb-1'>Delivery Schedule</p> */}
      {deliverySchedule?.map((ds, index) => {
        return (
          <div key={index}>
            Day: {ds.daysAfterOrder}; Quantity:{' '}
            <span className='font-medium'>
              {ds.quantity} {uom}s
            </span>
          </div>
        );
      })}
    </div>
  );
};
