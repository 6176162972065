import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedRevision } from '../../../../hooks/inventory/revision/use-revision';

type RenderRevisionProfileProps = {
  useCombinedRevision: UseCombinedRevision;
};

export function renderRevisionProfile({
  useCombinedRevision,
}: RenderRevisionProfileProps): () => JSX.Element {
  const Card = renderCardComponent();

  return function ItemProfile(): JSX.Element {
    const { id } = useParams();
    const {
      list: revisions,
      getAll: getRevisions,
      // eslint-disable-next-line
    } = useCombinedRevision();

    // eslint-disable-next-line
    useEffect(() => {
      if (id) {
        getRevisions();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const revision = revisions?.find((x) => x.id === id);
    const header: CardHeader = {
      title: `${revision?.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,

      subheading: (
        <>
          <div className='text-gray-700 italic'>
            <b> reason : </b>
            {revision?.reason}
            <br />
            <b>created date : </b>{' '}
            {moment(revision?.createdAt).format('DD-MMM-YYYY')}
            <br />
            <b>type : </b> {revision?.type}
          </div>

          <div className='text-gray-700'>
            requested by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${revision?.requestedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {revision?.requestedBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; approved by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${revision?.approvedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {revision?.approvedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(revision?.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: <></>,
    };

    return (
      <>
        {' '}
        <Card header={header} body={body} />
      </>
    );
  };
}
