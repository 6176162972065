import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { useEffect, useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';

export function renderOffBoardingActions({
  useCombinedEmployeeProfile,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}) {
  return function OffBoardingActions(): JSX.Element {
    const { getListSync: getEmployees } = useCombinedEmployeeProfile();
    const [employees, setEmployees] = useState<EmployeeProfileType[]>([]);
    const [resigned, setResigned] = useState<EmployeeProfileType[]>([]);
    const [selected, setSelected] = useState<EmployeeProfileType | null>(null);
    const [authorizations, setAuthorization] = useState<EmployeeProfileType[]>(
      []
    );

    useEffect(() => {
      getEmployees({ crossGroup: 'true' }).then((res) => {
        setEmployees(res);
        setResigned(res.filter((x) => x.details.resigned === 'yes'));
      });
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (selected) {
        setAuthorization(
          employees.filter((x) => {
            return [
              x.details.authorizations?.attendanceAuthorizer?.id,
              x.details.authorizations?.attendanceSupervisor?.id,
              x.details.authorizations?.attendanceSupervisorMonitor?.id,
              x.details.authorizations?.disciplinaryActionsAuthorizer?.id,
              x.details.authorizations?.insuranceAuthorizer?.id,
              x.details.authorizations?.loanAuthorizer?.id,
              x.details.authorizations?.salaryAdvanceAuthorizer?.id,
              x.details.authorizations?.salaryBankAccountAuthorizer?.id,
              x.details.authorizations?.salaryRevisionAuthorizer?.id,
            ].includes(selected.id);
          })
        );
      }
      // eslint-disable-next-line
    }, [selected]);

    return (
      <div>
        Select Resigned Employee{' '}
        <select
          onChange={(x) => {
            const sel = resigned.find((y) => y.id === x.target.value);
            if (sel) {
              setSelected(sel);
            }
          }}
          value={selected?.id}
        >
          <option value=''>Select</option>
          {resigned.map((x) => (
            <option key={x.id} value={x.id}>
              {x.name}
            </option>
          ))}
        </select>
        {selected ? (
          <div>
            Authorizations for {selected.name}
            {authorizations.map((x) => (
              <div className='flex' key={x.id}>
                <div className='w-1/3'>{x.name}</div>
                <div className='w-1/3'>
                  {x.details?.authorizations?.attendanceAuthorizer?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Attendance Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.attendanceSupervisor?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Attendance Supervisor
                    </span>
                  ) : null}
                  {x.details?.authorizations?.attendanceSupervisorMonitor
                    ?.id === selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Attendance Supervisor Monitor
                    </span>
                  ) : null}
                  {x.details?.authorizations?.disciplinaryActionsAuthorizer
                    ?.id === selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Disciplinary Actions Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.insuranceAuthorizer?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Insurance Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.loanAuthorizer?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Loan Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.salaryAdvanceAuthorizer?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Salary Advance Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.salaryBankAccountAuthorizer
                    ?.id === selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Salary bank Account Authorizer
                    </span>
                  ) : null}
                  {x.details?.authorizations?.salaryRevisionAuthorizer?.id ===
                  selected.id ? (
                    <span className='py-0.5 px-1 border rounded-md'>
                      Salary Revision Authorizer
                    </span>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  };
}
