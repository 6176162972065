import { DeliveryTerm } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type DeliveryTermFormType = {
  name: string;
  insurance: string;
  freight: string;
};

export function renderAddDeliveryTermForm() {
  return function AddDeliveryTermForm({
    onSave,
  }: {
    onSave: (data: DeliveryTerm) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<DeliveryTermFormType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'insurance',
          type: 'input',
          label: 'Insurance',
          required: true,
        },
        {
          property: 'freight',
          type: 'input',
          label: 'Freight',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        insurance: '',
        freight: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          details: {
            insurance: l.insurance,
            CarriageToPortOfImport: l.freight,
          },
        } as DeliveryTerm);
      },
    };

    const Form = renderFormV2<Partial<DeliveryTermFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<DeliveryTermFormType>>
    ): DeliveryTermFormType {
      return (b as unknown) as DeliveryTermFormType;
    }
  };
}
