import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeAssesmentType } from 'models/types/quality-assurance/change-assesment';

export type ClosureControlFormType = {
  closedOn: string;
  commentsOfHod: string;
  commentsOfQAManager: string;
  reviewPeriodEffectiveness: string;
};

// eslint-disable-next-line
export function renderClosureControlForm() {
  return function ClosureControlForm({
    onSave,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<ClosureControlFormType> = {
      fieldsData: [
        {
          property: 'closedOn',
          type: 'date',
          label: 'Closed On',
        },
        {
          property: 'commentsOfHod',
          type: 'text-area',
          label: 'Comments Of HOD',
          rows: 3,
        },
        {
          property: 'commentsOfQAManager',
          type: 'text-area',
          label: 'Comments Of QA Manager',
          rows: 3,
        },
        {
          property: 'reviewPeriodEffectiveness',
          type: 'input',
          label: 'Review period of effectiveness',
        },
      ],
      initialFormState: {
        closedOn: data?.details?.closureChangeControl?.closedOn || '',
        commentsOfHod: data?.details?.closureChangeControl?.commentsOfHod || '',
        commentsOfQAManager:
          data?.details?.closureChangeControl?.commentsOfQAManager || '',
        reviewPeriodEffectiveness:
          data?.details?.closureChangeControl?.reviewPeriodEffectiveness || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          details: {
            ...data?.details,
            closureChangeControl: {
              closedOn: f.closedOn,
              commentsOfHod: f.commentsOfHod,
              commentsOfQAManager: f.commentsOfQAManager,
              reviewPeriodEffectiveness: f.reviewPeriodEffectiveness,
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<ClosureControlFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<ClosureControlFormType>
    ): ClosureControlFormType {
      return (b as unknown) as ClosureControlFormType;
    }
  };
}
