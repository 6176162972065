/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseGetMachineSchedule } from '../../../../../../hooks/planning/production/machine-schedule';

type Props = {
  hooks: { useGetMachineSchedule: UseGetMachineSchedule };
};

export const createMachineProfile = ({
  hooks: { useGetMachineSchedule },
}: Props): (() => JSX.Element) =>
  function () {
    const { id } = useParams();

    const Card = renderCardComponent();

    const { getByMachineId, data } = useGetMachineSchedule();
    useEffect(() => {
      if (id) {
        getByMachineId(id);
      }
      // eslint-disable-next-line
    }, [id]);
    if (!data) return <Recat className='h-5 inline animate-pulse mx-4' />;
    return (
      <div>
        <Card
          header={{
            title: 'Machine Profile',
          }}
          body={{
            type: 'columns',
            body: [
              [
                { key: 'Name', value: data.name, type: 'text', style: '' },
                {
                  key: 'Machine',
                  value: data.machine.name,
                  type: 'text',
                  style: '',
                },
                {
                  key: 'Created by',
                  value: data.createdBy.name,
                  type: 'text',
                  style: '',
                },
              ],
            ],
          }}
        />
      </div>
    );
  };
