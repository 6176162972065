import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { GetSalaryAdvanceAmountResp } from 'models/interfaces/hrd/salary-advance';
import moment from 'moment';

type SalaryAdvanceFormType = {
  date: string;
  transactionId: string;
  reason: string;
  amount: number;
  eligibleAmount: number;
};

export function renderAddSalaryAdvanceForm({
  eligibleAmount,
}: {
  eligibleAmount: GetSalaryAdvanceAmountResp;
}) {
  return function AddSalaryAdvanceForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: SalaryAdvanceType) => Promise<void>;
    isEdit?: boolean;
    data?: SalaryAdvanceType;
  }): JSX.Element {
    const validateForm = async (form) => {
      if (isEdit) return '';
      if (!form.date) return '';

      if (moment(form.date).isAfter(moment())) {
        return { date: 'Date cannot be in future' } as any;
      }

      return {} as any;
    };
    const validateAmount = async (form) => {
      if (form.amount > form.eligibleAmount)
        return {
          amount: `Your eligible adavnce amount is ${eligibleAmount.amount}`,
        } as any;
    };
    const formProps: FormProps<any> = {
      fieldsData: [
        {
          property: 'date',
          type: 'date',
          label: 'Requested Date',
          required: true,
          readonly: isEdit,
          validate: validateForm,
        },
        {
          property: 'amount',
          type: 'input',
          required: true,
          label: 'Requested Amount',
          validate: validateAmount,
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
      ],
      initialFormState: {
        employee: '',
        date: '',
        transactionId: '',
        reason: '',
        amount: '',
        eligibleAmount: eligibleAmount?.amount,
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (f) => {
        await onSave({
          date: f.date || data?.date,
          transactionId: f.transactionId || f.transactionId,
          reason: f.reason || data?.reason,
          amount: f.amount || data?.amount,
          feasibleAmount: eligibleAmount?.amount || data?.feasibleAmount,
        } as SalaryAdvanceType);
      },
    };

    const Form = renderFormV2<SalaryAdvanceFormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<any>): SalaryAdvanceFormType {
      return (b as unknown) as SalaryAdvanceFormType;
    }
  };
}
