import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  CardBody,
  CardHeader,
  MonthSelector,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseCombinedAttendance } from '../../../../hooks/hrd/attendance/use-attendance';
import { UseAttendanceMonthlySummary } from '../../../../hooks/hrd/attendance/use-monthly-summary';

type renderEmployeeAttendanceProps = {
  useAttendanceMonthlySummary: UseAttendanceMonthlySummary;
  useCombinedAttendance: UseCombinedAttendance;
};

export function renderEmployeeAttendance({
  useAttendanceMonthlySummary,
  useCombinedAttendance,
}: renderEmployeeAttendanceProps): () => JSX.Element {
  return function EmployeeLeave(): JSX.Element {
    const [date, setDate] = useState(
      localStorage.getItem('emp-attendance') || moment.utc().format('YYYY-MM')
    );
    const { getAll: getAttendances, list: att } = useCombinedAttendance();
    const {
      getAttdMonthlySummary: getMonthlySummary,
      // data: monthly,
    } = useAttendanceMonthlySummary();
    const { id } = useParams();

    useEffect(() => {
      if (id) {
        getMonthlySummary({ id: id, query: { date: date } });
        localStorage.setItem('emp-attendance', date);

        getAttendances({
          employeeId: id,
          from: `more-than::${date}-00`,
          to: `less-than::${date}-32`,
        });
      }
      // eslint-disable-next-line
    }, [date, id]);

    const Card = renderCardComponent();
    const Table = renderTableComponent();

    const TableHeader: TableHeader = [
      [
        { name: 'Date' },
        { name: 'Status' },
        { name: 'Check-In' },
        { name: 'Check-Out' },
        { name: 'Total Work hours' },
        { name: 'Finalized' },
      ],
    ];

    // _.sortBy(monthly?.workDayHours || [], 'date').
    const TableBody: TableBody =
      att
        ?.sort((a, b) => moment(a.date).diff(b.date))
        .map((e) => {
          console.log(
            e?.details?.summary?.fakePunches?.checkout ||
              e?.details?.summary?.originalPunch.checkout
          );
          const checkInTime =
            e?.finalized &&
            (e?.details?.summary?.fakePunches?.checkIn ||
              e?.details?.summary?.originalPunch.checkIn);

          const checkIn =
            checkInTime && checkInTime !== '-'
              ? moment(checkInTime).format('DD MMM, HH:mm')
              : '-';

          const checkOutTime =
            e?.finalized &&
            (e?.details?.summary?.fakePunches?.checkout ||
              e?.details?.summary?.originalPunch.checkout);

          const checkOut =
            checkOutTime && checkOutTime !== '-'
              ? moment(checkOutTime).format('DD MMM, HH:mm')
              : '-';

          return {
            cells: [
              {
                value: e?.date,
              },
              {
                value: `${
                  e.status === 'on-leave'
                    ? e.details?.summary?.leaves?.[0].type
                    : e.status
                }`,
              },
              {
                value: checkIn,
              },
              {
                value: checkOut,
              },
              {
                value:
                  e?.finalized && e?.details?.summary?.payableWorkHours
                    ? `${e?.details?.summary?.payableWorkHours} hrs`
                    : '-',
              },
              {
                value: e?.finalized === true ? '✅' : '🕒',
              },
            ],
          };
        }) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          {TableBody.length ? (
            <Table header={TableHeader} body={TableBody} />
          ) : (
            <div className='w-full text-center'>
              <img
                className='mx-auto w-36'
                src='/no-results-found.gif'
                alt=''
              />
              <div className='text-blue-800 text-lg'>No Data Found!</div>
            </div>
          )}
        </div>
      ),
    };

    const cardHeader: CardHeader = {
      title: 'Attendance',
    };

    return (
      <>
        <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
          <span className='font-bold truncate'>Select Date</span>
          <MonthSelector
            format='YYYY-MM'
            initialState={date}
            onChange={(m) => setDate(m)}
          />
        </div>
        <Card header={cardHeader} body={cardBody} />
        {/* TODO: Achieve in Calendar view */}
        {/* <Calendar
          usageStatistics={false}
          height="400px"
          isReadOnly={true}
          view="month"
          month={{
            visibleWeeksCount: 3,
          }}
          week={{
            taskView: false,
            eventView: ['time'],
            startDayOfWeek: 1
          }}
          events={}
        /> */}
      </>
    );
  };
}
