import { HazardClass } from '@erp_core/erp-types/dist/modules/inventory';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedPrecautionaryStatement } from '../../../../../hooks/admin/hazard-regulations/ghs-precautionary-statements/use-precautionary-statement';

type Props = {
  onSave: (form: any) => Promise<void>;
  useCombinedPrecautionaryStatement: UseCombinedPrecautionaryStatement;
};

export const renderEditGhsClassPrecautions = ({
  onSave,
  useCombinedPrecautionaryStatement,
}: Props) => {
  return function ({ hazardClassData }: { hazardClassData: HazardClass }) {
    type FormType = { precautions: { id: string; name: string }[] };
    const Form = renderFormV2<FormType>({
      fieldsData: [
        {
          property: 'precautions',
          type: 'searchable-multi-select',
          label: 'Hazard Statements',
          searchOptions: {
            useSearch: useCombinedPrecautionaryStatement,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        precautions: hazardClassData.precautions
          ? hazardClassData.precautions.map((u) => ({ id: u.id, name: u.name }))
          : [],
      },
      onSubmit: onSave,
      mapTToU(p) {
        return (p as unknown) as FormType;
      },
    });

    return <Form />;
  };
};
