import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
// import { useEffect } from 'react';
import { UseCombinedEmployeeRegistration } from '../../../../../hooks/hrd/employee-registration-dtls/use-registration';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';

const employeeDetailsMapper: XlsxDownloadMapper = [
  {
    columnName: 'Employee Id',
    dataBinding: {
      property: 'employeeId',
    },
  },
  {
    columnName: 'Employee Name',
    dataBinding: { property: 'name' },
  },
  {
    columnName: 'Company',
    dataBinding: { property: 'company' },
  },
  {
    columnName: 'Resigned',
    dataBinding: { property: 'resigned' },
  },
  {
    columnName: 'Blood-Group',
    dataBinding: { property: 'bloodGroup' },
  },
  {
    columnName: 'Date of Joining',
    dataBinding: { property: 'dateOfJoining' },
  },
  {
    columnName: 'Department',
    dataBinding: { property: 'department' },
  },
  {
    columnName: 'Designation',
    dataBinding: { property: 'designation' },
  },
  {
    columnName: 'Job Profile',
    dataBinding: { property: 'jobProfile' },
  },
  {
    columnName: 'DOB',
    dataBinding: { property: 'dateOfBirth' },
  },
  {
    columnName: 'Current Address',
    dataBinding: { property: 'currentAddress' },
  },
  {
    columnName: 'Permanent Address',
    dataBinding: { property: 'permanentAddress' },
  },
  {
    columnName: 'Mobile',
    dataBinding: { property: 'mobile' },
  },
  {
    columnName: 'Mail ID',
    dataBinding: { property: 'email' },
  },
  {
    columnName: 'PAN No',
    dataBinding: { property: 'pan' },
  },
  {
    columnName: 'Aadhar Card No',
    dataBinding: { property: 'aadhar' },
  },
  {
    columnName: 'PF Number',
    dataBinding: { property: 'pf' },
  },
  {
    columnName: 'ESIC Number',
    dataBinding: { property: 'esic' },
  },
];

export function EmployeeDetailsReport({
  useCombinedEmployeeProfile,
  useCombinedEmployeeRegistration,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedEmployeeRegistration: UseCombinedEmployeeRegistration;
}): JSX.Element {
  const { getListSync: getEmployees } = useCombinedEmployeeProfile();
  const { getListSync: getRegistrations } = useCombinedEmployeeRegistration();
  // useEffect(() => {
  //   getEmployees({ crossGroup: 'true' })
  //     .then(emps => {
  //       console.log(emps.filter(x => {

  //         if (!x.details.onboardToPayroll && x.details.resigned !== 'yes') {
  //           return true;
  //         }

  //         return false;
  //       }))

  //     });
  // }, []);

  function addressMapper(currentAddress) {
    let address = '';
    if (currentAddress) {
      // `${currentAddress?.addressLine1}, ${currentAddress?.addressLine2}, ${currentAddress?.city}, ${currentAddress?.pincode}, ${currentAddress?.district}, ${currentAddress?.state}`
      if (currentAddress.addressLine1) {
        address += currentAddress.addressLine1;
      }
      if (currentAddress.addressLine2) {
        address += ',' + currentAddress.addressLine2;
      }
      if (currentAddress.city) {
        address += ',' + currentAddress.city;
      }
      if (currentAddress.pincode) {
        address += ',' + currentAddress.pincode;
      }
      if (currentAddress.district) {
        address += ',' + currentAddress.district;
      }
      if (currentAddress.state) {
        address += ',' + currentAddress.state;
      }
    }

    return address;
  }

  const DownloadReport = renderBulkDownload({
    mapperFun: async () => {
      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      const employees = await getEmployees({ crossGroup: 'true' });
      // const educationDetails: Array<EmployeeEducationType> = [];

      // employees.forEach(em => {
      //   if (em.details?.educationDetails?.length) {
      //     em.details?.educationDetails?.forEach(ed => {
      //       educationDetails.push({
      //         ...ed,
      //         id: '',
      //         name: '',
      //         employee: {
      //           id: em.id,
      //           name: em.name,
      //         },
      //         lastModifiedAt: '',
      //         lastModifiedBy: { id: '', name: '' },
      //         details: { marks: `${ed.marks || ''}` },
      //         companyGroup: { id: em.companyGroup?.id, name: em.companyGroup.name },
      //         disabled: false,
      //         createdAt: '',
      //         createdBy: { id: '', name: '' }
      //       });
      //     })
      //   }
      // })

      // console.log(educationDetails);
      const registrations = await getRegistrations();

      const dynamicMapper: XlsxDownloadMapper = [...employeeDetailsMapper];

      const fileName = 'employee-details-report'; // File name based in stock group id

      result.push({
        mapper: dynamicMapper,
        data: employees
          .sort((a, b) => {
            return (
              parseInt(a.details?.employeeId || '0') -
              parseInt(b.details?.employeeId || '0')
            );
          })
          .map((x) => {
            const currentAddress = addressMapper(
              x.details?.contactDetails?.currentAddress
            );
            const permanentAddress = addressMapper(
              x.details?.contactDetails?.permanentAddress
            );
            // let address = '';
            // if (currentAddress) {
            //   // `${currentAddress?.addressLine1}, ${currentAddress?.addressLine2}, ${currentAddress?.city}, ${currentAddress?.pincode}, ${currentAddress?.district}, ${currentAddress?.state}`
            //   if (currentAddress.addressLine1) {
            //     address += currentAddress.addressLine1;
            //   }
            //   if (currentAddress.addressLine2) {
            //     address += ',' + currentAddress.addressLine2;
            //   }
            //   if (currentAddress.city) {
            //     address += ',' + currentAddress.city;
            //   }
            //   if (currentAddress.pincode) {
            //     address += ',' + currentAddress.pincode;
            //   }
            //   if (currentAddress.district) {
            //     address += ',' + currentAddress.district;
            //   }
            //   if (currentAddress.state) {
            //     address += ',' + currentAddress.state;
            //   }
            // }
            // const currentAddress = x.details?.contactDetails?.currentAddress;
            // let address = '';
            // if (currentAddress) {
            //   // `${currentAddress?.addressLine1}, ${currentAddress?.addressLine2}, ${currentAddress?.city}, ${currentAddress?.pincode}, ${currentAddress?.district}, ${currentAddress?.state}`
            //   if (currentAddress.addressLine1) {
            //     address += currentAddress.addressLine1;
            //   }
            //   if (currentAddress.addressLine2) {
            //     address += ',' + currentAddress.addressLine2;
            //   }
            //   if (currentAddress.city) {
            //     address += ',' + currentAddress.city;
            //   }
            //   if (currentAddress.pincode) {
            //     address += ',' + currentAddress.pincode;
            //   }
            //   if (currentAddress.district) {
            //     address += ',' + currentAddress.district;
            //   }
            //   if (currentAddress.state) {
            //     address += ',' + currentAddress.state;
            //   }
            // }

            return {
              employeeId: x.details?.employeeId,
              name: x.name,
              company: x.company.name,
              resigned: x.details?.resigned || 'no',
              bloodGroup: x.details?.bloodGroup,
              dateOfJoining:
                x.details.workTimeLines?.find((y) => y.activity === 'joined')
                  ?.date || '',
              department: x.department?.name || x.details?.department || '',
              designation: x.details?.designation || '',
              jobProfile: '',
              dateOfBirth: x.details.birthDate,
              currentAddress: currentAddress,
              permanentAddress: permanentAddress,
              mobile: x.details?.contactDetails?.mobileNumber?.mobile1 || '',
              email:
                x.details?.contactDetails?.emailAddress?.personalEmail || '',
              pan:
                registrations.find(
                  (y) =>
                    y.employee.id === x.id && y.regDocumentType === 'pan-card'
                )?.accountNo || '',
              aadhar:
                registrations.find(
                  (y) =>
                    y.employee.id === x.id && y.regDocumentType === 'adhar-card'
                )?.accountNo || '',
              pf:
                registrations.find(
                  (y) =>
                    y.employee.id === x.id &&
                    y.regDocumentType === 'employee-provident-fund'
                )?.accountNo || '',
              esic:
                registrations.find(
                  (y) => y.employee.id === x.id && y.regDocumentType === 'esic'
                )?.accountNo || '',
            };
          }),
        fileName,
      });

      return result;
    },
    downloadIcon: ExcelDownload,
  });

  return (
    <div className='mx-auto'>
      <div className='flex justify-center'>
        <span className='flex border border-green-500 rounded p-1'>
          <DownloadReport /> Download Employee Details Report
        </span>
      </div>
    </div>
  );
}
