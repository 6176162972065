import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  Filter,
  renderFormV2,
  renderTableWithMapperComponent,
  TableActionsType,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { ClockIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useCallback, useContext, useMemo } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../../../contexts/user';
import { UseCombinedUser } from '../../../../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGodown } from '../../../../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedGrade } from '../../../../../../hooks/inventory/grade/use-grade';
import { UseCombinedBatchItemGodown } from '../../../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedPurchaseRequisition } from '../../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { UseCombinedBomProject } from '../../../../../../hooks/order/work-order/bom-project/use-bom-project';
import { UseCombinedBom } from '../../../../../../hooks/order/work-order/bom/use-bom';
import { ItemInterface } from '../../../../../../models/interfaces/inventory/item';
import { BOMFilterType } from '../../../../../../models/interfaces/order/work-order/bom';
import { renderNewCommonAllocationForm } from '../../../forms/new-common-allocation';
import { EditForm } from '../../bom-draft/forms/edit';
import { BomIcon } from '../../utils/bomIcons';

export function renderCommonBomTable({
  type,
  useCombinedBom,
  useCombinedGrade,
  useCombinedBomProject,
  useCombinedBatchItemGodown,
  useCombinedUser,
  useCombinedGodown,
  itemService,
  useCombinedPurchaseRequisition,
}: {
  type: 'draft' | 'approved';
  useCombinedBom: UseCombinedBom;
  useCombinedGrade: UseCombinedGrade;
  useCombinedBomProject: UseCombinedBomProject;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useCombinedGodown: UseCombinedGodown;
  useCombinedUser: UseCombinedUser;
  itemService: ItemInterface;
  useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
}) {
  const Table = renderTableWithMapperComponent<BOMRes, BOMFilterType>();

  return function CommonBomTable() {
    const { user: currentUser } = useContext(UserContext);

    const { syncSet: setBom } = useCombinedBom();
    const BombodyMapper: (
      item: BOMRes
    ) => {
      rowData: {
        bom: BOMRes;
      };
      cells: TableCell[];
    } = useCallback((item: BOMRes) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              <div className='flex'>
                <div className='my-auto'>{BomIcon[item.bomType] || null}</div>
                <div>
                  <div>
                    <a href={`/inventory/registers/bom/${item.id}/details`}>
                      {item.name || ''}
                    </a>
                    {/* <ExclamationTriangleIcon
                      className={`inline w-5 ${BomPriorityColor[item.priority]}`}
                    /> */}
                  </div>
                  <div>
                    <a
                      href={`/inventory/registers/bom-projects/${item.bomProject.id}`}
                    >
                      {item.bomProject.name}
                    </a>
                  </div>
                </div>
              </div>
            </>
          ),
        },
        {
          value: (
            <span>
              <ClockIcon className='w-5 inline text-blue-800' />
              {moment(item.targetDate).format('YYYY-MM-DD')}
            </span>
          ),
        },
        {
          value: (
            <a
              href={`/inventory/masters/items/${item.rawItem.item.id}/properties`}
              target='blank'
            >
              <div className='font-semibold'>{item.rawItem.name}</div>
              <div>{item.rawItem.item.name}</div>
            </a>
          ),
        },
        {
          value: item.details?.rawMaterial?.uom,
          style: 'text-center',
        },
        {
          value: (item.details as any).stock?.quantity?.total as number,
          style: 'text-right',
        },
        {
          value: item.details.stock?.quantity?.allocated?.physical,
          style: 'text-center',
        }, // Physically Allocated
        {
          value: (item.details as any).stock?.quantity?.allocated?.virtual,
          style: 'text-center',
        }, // Virtually Allocated
        {
          value: (item.details as any).stock?.quantity?.issued,
          style: 'text-center',
        }, // TODO: Map to proper values "Issued Quantity"
        {
          value: item.details.stock?.quantity?.balance,
          style: 'text-center',
        },
        {
          // Physical stock
          value: `${
            item.details.stock?.stock.currentCompany?.total?.physical
              ?.allocated || 0
            // (item.details as any).stock?.stock?.physical?.allocated
          } / ${
            item.details.stock?.stock.currentCompany?.total?.physical
              ?.unallocated || 0
            // (item.details as any).stock?.stock?.physical?.unallocated
          }`,
        }, // Allocated/Unallocated
        {
          value: `${
            item.details.stock?.stock.currentCompany.total.physical.total || 0
          }`,
        }, // Total Quantity
        {
          // Virtual stock
          value: `${
            item.details.stock?.stock.currentCompany.total.virtual.allocated ||
            0
            // (item.details as any).stock?.stock?.virtual?.allocated
          } / ${
            item.details.stock?.stock.currentCompany.total.virtual
              .unallocated || 0
            // (item.details as any).stock?.stock?.virtual?.unallocated
          }`,
        }, // Unallocated
        {
          value: `${
            item.details.stock?.stock.currentCompany.total.virtual.total || 0
          }`,
        }, // Total

        { value: `${item.details.status || ''}` },
      ];

      return {
        rowData: {
          bom: item,
        },
        cells,
      };
    }, []);

    const tableHeader: TableHeader = useMemo(() => {
      return [
        [
          { name: 'BOM #' },
          { name: 'Target' },
          { name: 'Item' },
          { name: 'UOM' },
          { name: 'QTY' },
          {
            name: 'Physically Allocated',
            style: 'w-10',
          },
          {
            name: 'Virtually Allocated',
            style: 'w-10',
          },
          { name: 'Issued' },
          {
            name: 'Balance to be Issued',
            style: 'border-l border-gray-200 w-28',
          },
          // Physical Stock
          {
            name: 'Physical Stock',
            style: 'w-14',
          },
          { name: 'Total' },
          // Virtual Stock
          {
            name: 'Virtual Stock',
            style: 'w-14',
          },
          { name: 'Total' },
          { name: 'Status' },
        ],
      ];
      // eslint-disable-next-line
    }, []);

    const filter: Filter<BOMFilterType> = useMemo(() => {
      if (type === 'draft') {
        return {
          version: 'v2',
          sortFields: [
            {
              key: 'createdAt',
              value: 'createdAt',
              defaultOrder: 'asc',
            },
            {
              key: 'lastModifiedAt',
              value: 'lastModifiedAt',
              defaultOrder: 'asc',
            },
          ],
          filterFields: [
            {
              key: 'search',
              value: 'all',
              type: 'text',
            },
            {
              key: 'bomProjectId',
              value: 'bomProjectId',
              type: 'search-select',
            },
            {
              key: 'rawItemId',
              value: '',
              type: 'search-select',
            },
            // {
            //   key: 'priority',
            //   value: 'all',
            //   type: 'drop-down',
            //   options: [
            //     { text: 'all', value: 'all' },
            //     { text: 'low', value: 'low' },
            //     { text: 'medium', value: 'medium' },
            //     { text: 'high', value: 'high' },
            //   ],
            // },
            {
              key: 'createdBy',
              type: 'search-select',
              value: '',
            },
            {
              key: 'lastModifiedBy',
              type: 'search-select',
              value: '',
            },
          ],
          filterMapper: (filterSelection: BOMFilterType) => {
            const filterData: BOMFilterType = {};

            // if (filterSelection.priority !== 'all') {
            //   filterData.priority = filterSelection.priority;
            // }

            if (
              filterSelection.rawItemId !== 'all' &&
              filterSelection.rawItemId !== ''
            ) {
              filterData.rawItemId = filterSelection.rawItemId;
            }

            if (filterSelection.createdBy) {
              filterData.createdBy = filterSelection.createdBy;
            }

            if (filterSelection.lastModifiedBy) {
              filterData.lastModifiedBy = filterSelection.lastModifiedBy;
            }

            if (filterSelection.bomProjectId) {
              filterData.bomProjectId = filterSelection.bomProjectId;
            }

            filterData.status = 'draft';

            if (
              filterSelection.search !== 'all' &&
              filterSelection.search !== ''
            ) {
              filterData.search = filterSelection.search;
            }

            return { ...filterSelection, ...filterData } as BOMFilterType;
          },
          filterResources: {
            createdBy: {
              searchOptions: {
                useSearch: useCombinedUser,
                onSearchValueSelect: (u) => {},
              },
            },
            lastModifiedBy: {
              searchOptions: {
                useSearch: useCombinedUser,
                onSearchValueSelect: (u) => {},
              },
            },
            rawItemId: {
              searchOptions: {
                useSearch: useCombinedGrade,
                onSearchValueSelect: (u) => {},
                searchOptionsBody: {
                  customBody: (data: Grade) => {
                    return (
                      <div>
                        <div className='font-bold'>{data.name}</div>
                        <div>{data.item?.name}</div>
                      </div>
                    );
                  },
                },
              },
            },
            bomProjectId: {
              searchOptions: {
                useSearch: useCombinedBomProject,
                onSearchValueSelect: (u) => {
                  console.log(u);
                },
              },
            },
          },
        };
      }
      return {
        version: 'v2',
        sortFields: [
          // {
          //   key: 'Item', //TODO sortfilter is not working
          //   value: 'item',
          //   defaultOrder: 'asc',
          // },
          {
            key: 'CreatedAt',
            value: 'createdAt',
            defaultOrder: 'asc',
          },
          {
            key: 'LastModifiedAt',
            value: 'lastModifiedAt',
            defaultOrder: 'asc',
          },
        ],
        filterFields: [
          {
            key: 'search',
            value: 'all',
            type: 'text',
          },
          {
            key: 'bomProjectId',
            value: 'bomProjectId',
            type: 'search-select',
          },
          {
            key: 'status',
            value: 'all',
            type: 'drop-down',
            options: [
              { text: 'all', value: 'all' },
              { text: 'new', value: 'new' },
              { text: 'partially-allocated', value: 'partially-allocated' },
              { text: 'allocated', value: 'allocated' },
              { text: 'pr-open', value: 'pr-open' },
              { text: 'purchase-order', value: 'purchase-order' },
              { text: 'grn', value: 'grn' },
              { text: 'inspection', value: 'inspection' },
              { text: 'closed', value: 'closed' },
              { text: 'cancelled', value: 'cancelled' },
            ],
          },
          {
            key: 'rawItemId',
            type: 'search-select',
            value: '',
          },
          // {
          //   key: 'priority',
          //   value: 'all',
          //   type: 'drop-down',
          //   options: [
          //     { text: 'all', value: 'all' },
          //     { text: 'low', value: 'low' },
          //     { text: 'medium', value: 'medium' },
          //     { text: 'high', value: 'high' },
          //   ],
          // },
        ],
        filterMapper: (filterSelection: BOMFilterType) => {
          const filterData: BOMFilterType = {};
          // if (filterSelection.priority !== 'all') {
          //   filterData.priority = filterSelection.priority;
          // }
          if (filterSelection.rawItemId) {
            filterData.rawItemId = filterSelection.rawItemId;
          }

          if (filterSelection.bomProjectId) {
            filterData.bomProjectId = filterSelection.bomProjectId;
          }

          if (filterSelection.status !== 'all') {
            filterData.status = filterSelection.status;
          }

          if (
            filterSelection.search !== 'all' &&
            filterSelection.search !== ''
          ) {
            filterData.search = filterSelection.search;
          }

          return { ...filterSelection, ...filterData } as BOMFilterType;
        },
        filterResources: {
          createdBy: {
            searchOptions: {
              useSearch: useCombinedUser,
              onSearchValueSelect: (u) => {},
            },
          },
          lastModifiedBy: {
            searchOptions: {
              useSearch: useCombinedUser,
              onSearchValueSelect: (u) => {},
            },
          },
          rawItemId: {
            searchOptions: {
              onSearchValueSelect: (u) => {},
              useSearch: useCombinedGrade,
              searchOptionsBody: {
                customBody: (data: Grade) => {
                  return (
                    <div>
                      <div className='font-bold'>{data.name}</div>
                      <div>{data.item?.name}</div>
                    </div>
                  );
                },
              },
            },
          },
          bomProjectId: {
            searchOptions: {
              useSearch: useCombinedBomProject,
              onSearchValueSelect: (u) => {
                console.log(u);
              },
            },
          },
        },
      };
      // eslint-disable-next-line
    }, []);

    function createActions(): TableActionsType[] {
      if (type === 'draft') {
        return [
          {
            name: 'Edit',
            show: (p) => {
              if (!p.approved) {
                return true;
              }
              return false;
            },
            behaviour: 'modal',
            modal: {
              title: 'Edit',
              content: ({ data: { bom }, onClose }) => {
                const onSubmit = async (form) => {
                  console.log(form);
                  await setBom(form);
                  onClose();
                  toast('Bom Edited successfully');
                };
                return (
                  <EditForm
                    useCombinedGodown={useCombinedGodown}
                    onSubmit={onSubmit}
                    defaults={bom}
                  />
                );
              },
            },
          },
          {
            name: 'Approve',
            show: (p) => {
              if (!p.approved) {
                return true;
              }
              return false;
            },
            behaviour: 'confirm',
            onConfirm: ({ bom }) => {
              console.log(bom);
              async function approveBom(p) {
                // Here update bom to approve
                const activity: Array<any> = p.details.activity || [];
                activity.push({
                  action: 'approved',
                  details: '',
                  user: currentUser,
                  event: `${currentUser.name} approved the BOM ${p.name}`,
                  time: moment.utc().format(),
                });
                const bom: BOMRes = {
                  id: p.id,
                  approved: true,
                  details: {
                    status: 'new',
                    activity: activity,
                  },
                } as BOMRes;
                await setBom(bom);
                toast(`Bom ${p.name} approved successfully`);
              }
              return {
                title: 'Are you sure you want to approve ?',
                onConfirm: async () => {
                  await approveBom(bom);
                },
              };
            },
          },
        ];
      }
      const NewCommonAllocator = renderNewCommonAllocationForm({
        useCombinedBatchItemGodown,
        useCombinedBom,
        itemService,
        useCombinedPurchaseRequisition,
      });
      return [
        {
          name: 'Edit',
          show: (data) => {
            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({ data: { bom }, onClose }) => {
              const Form = renderFormV2({
                fieldsData: [
                  {
                    property: 'targetDate',
                    type: 'date',
                  },
                ],
                initialFormState: {
                  targetDate: bom.targetDate,
                },
                onSubmit: async (form) => {
                  if (form.targetDate) {
                    await setBom({
                      id: bom.id,
                      targetDate: form.targetDate,
                    } as BOMRes);
                    toast.success('Bom Successfully Edited');
                  }
                  console.log(form);
                  onClose();
                },
                mapTToU: (t) => t,
              });
              return (
                <div>
                  <Form />
                </div>
              );
            },
          },
        },
        {
          name: 'Cancel',
          show: (data) => {
            if (data.bom.details && data.bom.details?.status !== 'cancelled') {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ bom }) => {
            return {
              title: 'Are your sure you want to Cancel BOM ?',
              onConfirm: async () => {
                await setBom({
                  id: bom.id,
                  status: 'cancelled',
                  details: {
                    status: 'cancelled',
                  },
                } as BOMRes);
                toast.success('Bom Successfully cancelled');
              },
            };
          },
        },
        {
          name: 'Allocate',
          show: (data) => {
            if (
              ['new', 'partially-allocated'].includes(data.bom.details.status)
            ) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Allocate',
            content: ({ data: { bom }, onClose }) => (
              <div>
                <NewCommonAllocator bom={bom} onClose={onClose} />
              </div>
            ),
          },
        },
      ];
    }

    return (
      <>
        <Table
          header={tableHeader}
          bodyMapper={BombodyMapper}
          type={{
            type: 'paginated',
            usePaginatedResources: useCombinedBom,
          }}
          filter={filter}
          defaultFilter={{ approved: type === 'approved' ? true : false }}
          actions={createActions()}
        />
      </>
    );
  };
}
