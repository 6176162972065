/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';

// eslint-disable-next-line
export function renderAllocateSalesOrderForm(): (props: any) => JSX.Element {
  return function AllocateSalesOrderForm({
    data,
    onSave,
  }: {
    data: {};
    onSave: any;
  }): JSX.Element {
    const Form = renderFormV2({
      formName: 'Dispatch',
      mapTToU: (f) => f,
      fieldsData: [
        {
          property: 'name',
          label: 'ORDER-29/12/2022',
          type: 'input',
        },
        {
          property: 'deliverBy',
          label: 'Deliver By: 22-02-2022',
          type: 'input',
        },
        {
          property: 'client',
          label: 'Client: Jilmeel Industry',
          type: 'input',
        },
        {
          property: 'item-1',
          label: 'Item 1',
          type: 'input',
        },
        {
          property: 'stats-1',
          label:
            'Total: 5000 Kgs | Issued 2000Kgs | Balanced: 3000 kgs | Available: 345 kgs',
          type: 'input',
        },
        {
          property: 'quantity-1',
          label: 'Dispatch Qty',
          type: 'number',
        },

        {
          property: 'item-2',
          label: 'Item 2',
          type: 'input',
        },
        {
          property: 'stats-2',
          label:
            'Total: 1000 Kgs | Issued 500Kgs | Balanced: 500 kgs | Available: 45 kgs',
          type: 'input',
        },
        {
          property: 'quantity-2',
          label: 'Dispatch Qty',
          type: 'number',
        },
      ],
      initialFormState: {
        name: '',
        deliverBy: '',
        client: '',
        'item-1': '',
        'item-2': '',
        'stats-1': '',
        'stats-2': '',
        'quantity-1': '',
        'quantity-2': '',
      },
      onSubmit: async (form) => {
        onSave(form);
      },
    });
    return <Form />;
  };
}
