import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';

export const activities: Array<IdName> = [
  { id: '1', name: 'SOP' },
  { id: '2', name: 'Format' },
  {
    id: '3',
    name: 'Product Catalogue & STP',
  },
  { id: '4', name: 'BPCR/BBR/BPR' },
  { id: '5', name: 'Validation' },
  { id: '6', name: 'Qualification' },
  { id: '7', name: 'Training' },
  { id: '8', name: 'SOP Master list' },
];
