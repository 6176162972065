/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { UseBatchItemAllocate } from '../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseCombinedBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseCombinedSalesEnquiry } from '../../../hooks/order/sales/enquiry/use-sales-enquiry';
import { UseCombinedManufacturingVoucher } from '../../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';
import { UseVirtuallyReserveMfVoucher } from '../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { renderVirtualStockMf } from '../../common/fragments/virtual-stock-mf';
import { renderPhysicalStock } from '../../inventory/requisitions/bom-register/fragments/physical-stock';

function renderSalesItemEnquiryDetails({
  useCombinedSalesEnquiry,
  usePhysicalStock,
  useCombinedBatchItemGodown,
  useBatchGodownItemAllocate,
  useVirtualMfStock,
  useCombinedManufacturingVoucher,
  useVirtuallyReserveMfVoucher,
}: {
  useCombinedSalesEnquiry: UseCombinedSalesEnquiry;
  usePhysicalStock: UsePhysicalStock;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useBatchGodownItemAllocate: UseBatchItemAllocate;
  useVirtualMfStock: UseVirtualMFStock;
  useCombinedManufacturingVoucher: UseCombinedManufacturingVoucher;
  useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
}) {
  return function SalesItemEnquiryDetails() {
    const PhysicalStock = renderPhysicalStock({ usePhysicalStock });

    const VirtualStock = renderVirtualStockMf({
      useVirtualStock: useVirtualMfStock,
    });

    // const { set: setSalesEnquiry } = useSalesEnquiry();
    const { getAll, list: enquiriesData } = useCombinedSalesEnquiry();

    const itemEnqData = _.flatten(enquiriesData?.map((ed) => ed.enquiryItems));

    useEffect(() => {
      // getData()
      getAll();
      // eslint-disable-next-line
    }, []);

    // const ItemAction = renderEnquiryItemAction({
    //   usePhysicalStock,
    //   showModal,
    //   handleSaveAllocation,
    //   setModalState,
    //   useBatchItemGodowns,
    //   useBatchGodownItemAllocate,
    //   useVirtualMfStock,
    //   useManufacturingVouchers,
    //   useVirtuallyReserveMfVoucher,
    //   useCurrentUser,
    // });

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item', rowSpan: 2 },
        { name: 'Respond By', rowSpan: 2 },
        { name: 'Quantity', rowSpan: 2 },
        { name: 'Grade', rowSpan: 2 },
        // { name: 'Needed By',  rowSpan: 2 },
        { name: 'remarks', rowSpan: 2 },
        { name: 'Attachments', rowSpan: 2 },
        { name: 'Physical stock', colSpan: 2 },
        { name: 'Virtual stock', colSpan: 2 },
        { name: 'Status', rowSpan: 2 },
        // { name: 'Actions', rowSpan: 2 },
      ],
      [
        // Physical Stock
        {
          name: 'Reserved / Unallocated',

          style: 'w-14',
        },
        { name: 'Total' },

        // Virtual Stock
        {
          name: 'Reserved / Unallocated',

          style: 'w-14',
        },
        { name: 'Total' },
      ],
    ];

    const tableData =
      itemEnqData?.map((item) => ({
        rowData: { item },
        cells: [
          {
            value: item.item.name,
            link: `/sales/enquiry/items/details/${item.item.id}`,
          },
          { value: item.enquiry.respondBy },
          { value: item.quantity },
          { value: item.details.specifications?.grade || 'NA' },
          //   { value: item.neededBy },
          { value: item.enquiry.details.remark },
          {
            value: (
              <div>
                {item.enquiry.details.attachments?.map((a, i) => (
                  <a key={i}>{a.name}, </a>
                ))}
              </div>
            ),
          },
          { value: <PhysicalStock id={item.item.id} uom='kg' view='combo' /> },
          { value: <PhysicalStock id={item.item.id} uom='kg' view='total' /> },
          {
            value: (
              <VirtualStock id={item.item.id} uom='kg' view='combo-reserved' />
            ),
          },
          { value: <VirtualStock id={item.item.id} uom='kg' view='total' /> },
          {
            value:
              moment() > moment(item.enquiry.respondBy) ? (
                <span className='bg-red-300'>expired</span>
              ) : (
                item.enquiry.status
              ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Sales Item Enquiries',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Allocate',
              show: () => true,
              behaviour: 'click',
              onClick: async () => {},
            },
            {
              name: 'Virtual Allocate',
              show: () => true,
              behaviour: 'click',
              onClick: async () => {},
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderSalesItemEnquiryDetails;
