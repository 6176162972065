import { StockGroup } from '@erp_core/erp-types/dist/types/modules/inventory/stockgroup';
import {
  applyFormula,
  comparisonStyleMapper,
} from '@erp_core/erp-ui-components';

/* eslint-disable eqeqeq */
export const getRowHeadings = ({ stockGroup }: { stockGroup: StockGroup }) => {
  const itemSpecRows =
    (stockGroup?.details as any)?.specification?.fields
      ?.filter((fl) => fl.neededInEnquiry === 'true')
      ?.map((f) => ({
        id: 'item-specs',
        name: f.property,
        applicable: true,
        formula: applyFormula[f.comparisonType || 'isEqual'],
      })) || [];

  // add item specs
  return [
    {
      id: 'createdAt',
      name: 'Created at',
    },
    {
      id: 'quantity',
      name: 'Quantity',
      applicable: true,
      formula: (cell, completeData) => {
        // get enquiry quantity
        const qty = 124333;
        return (
          <div
            className={`${
              qty <= cell ? `${comparisonStyleMapper.isInvalid}` : ''
            }`}
          >
            {cell}
          </div>
        );
      },
      formulaLogic: 'Quantity less than required will have red background',
    },
    {
      id: 'quantityPerPackage',
      name: 'Quantity per package',
    },
    {
      id: 'rate',
      name: 'Basic rate',
      applicable: true,
      formula: (cell, rowData, completeData) => {
        const min = Math.min(...rowData.slice(1));
        return (
          <div
            className={`${min == cell ? `${comparisonStyleMapper.minOf}` : ''}`}
          >
            {cell}
          </div>
        );
      },
      formulaLogic: 'minimum rate will be bold',
    },
    {
      id: 'discount',
      name: 'Discount',
      applicable: true,
      formula: (cell, rowData, completeData) => {
        const min = Math.min(...rowData.slice(1));
        return (
          <div
            className={`${min == cell ? `${comparisonStyleMapper.minOf}` : ''}`}
          >
            {cell}
          </div>
        );
      },
      formulaLogic: 'maximun discount will be bold',
    },
    {
      id: 'deliveredRate',
      name: 'Delivered rate',
    },
    {
      id: 'leadTime',
      name: 'Lead time',
    },
    {
      id: 'paymentTerms',
      name: 'Payment terms',
    },
    {
      id: 'creditPeriod',
      name: 'Credit period',
    },
    {
      id: 'termOfDelivery',
      name: 'Term of delivery',
    },
    {
      id: 'deliveryFrom',
      name: 'Delivery from',
    },
    {
      id: 'Category',
      name: 'Category',
    },
    ...itemSpecRows,
    {
      id: 'remarks',
      name: 'Remarks',
    },
    {
      id: 'landingCost',
      name: 'Landing Cost per unit',
      applicable: true,
      formula: (cell, rowData, completeData) => {
        const min = Math.min(...rowData.slice(1));
        return (
          <div
            className={`${min == cell ? `${comparisonStyleMapper.minOf}` : ''}`}
          >
            {cell}
          </div>
        );
      },
      formulaLogic: 'Minimum landing Cost will be Bolt',
    },
  ];
};
