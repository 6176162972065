/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useEffect } from 'react';
import { UseCombinedEmployeeProfile } from '../../../hooks/hrd/employee/profile/use-employee-profile';

export type DepartmentProps = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
};

export function renderDepartmentMaster({
  useCombinedEmployeeProfile,
}: DepartmentProps): () => JSX.Element {
  return function DepartmentMaster(): JSX.Element {
    const {
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();

    useEffect(() => {
      getAllEmployees();

      // eslint-disable-next-line
    }, []);

    let result;
    if (employees) {
      result = _.chain(employees)
        .groupBy((emp) => emp.details.department)
        .map((empname, dpt) => ({ dpt, empname })) //using ES6 shorthand to generate the objects
        .value();
    }

    const DepartmentTable = renderTableComponent();
    const DepartmentTableHeader: TableHeader = [
      [{ name: 'Department Name' }, { name: 'Employees' }],
    ];

    const tableData =
      result?.map((r, idx) => ({
        cells: [
          { value: r.dpt },
          { value: r.empname.map((x, id) => x.name + ' , ') },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Department',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: <DepartmentTable header={DepartmentTableHeader} body={tableBody} />,
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
