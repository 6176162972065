import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { LeaveAppType, LeaveType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  downloadPdfFile,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';
import { ArrowDownCircleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import {
  employeeIconMapperByGrade,
  employeeLeaveStatusMapper,
  employeeLeaveTypeIconMapper,
} from '../../../../utils/common';
import { UserRendererInterface } from '../../../common/fragments/user';

type RenderGenericLeaveCard = {
  id?: string;
  cardHeader: CardHeader;
  useCombinedLeave: UseCombinedLeave;
  useFileTransfer: UseFileTransfer;
  userRendererService: UserRendererInterface;
};

export function renderGenericLeaveCard({
  id,
  cardHeader,
  useCombinedLeave,
  useFileTransfer,
  userRendererService,
}: RenderGenericLeaveCard): () => JSX.Element {
  return function LeaveCard(): JSX.Element {
    const { list: leaves, getAll: getLeaves, loading } = useCombinedLeave();
    const { get: getPrivateFile } = useFileTransfer();

    useEffect(() => {
      if (id) {
        getLeaves({ employeeId: id, crossGroup: 'true' });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Card = renderCardComponent();
    const Table = renderTableComponent();

    const TableHeader: TableHeader = [
      [
        // { name: 'Type',  },
        // { name: 'Start Date',  },
        // { name: 'End Date',  },
        // { name: 'Duration',  },
        // { name: 'IssuedBy',  },
        // { name: 'ApprovedBy',  },
        // { name: 'Reason',  },
        // { name: 'Status',  },
        { name: 'Particulars' },
        { name: 'Reason / Justification' },
        { name: 'Activity' },
        { name: 'Status' },
        // { name: 'Actions',  },
      ],
    ];

    const currentYear = moment().format('YYYY');
    const unusedLeaves = leaves?.filter((l) => l.status === 'un-consumed');
    const redeemedLeaves = leaves?.filter(
      (l) =>
        l.status === 'redeemed' &&
        moment(l.lastModifiedAt).format('YYYY') === currentYear
    );
    const approvedLeaves = leaves?.filter(
      (l) =>
        l.status === 'approved' &&
        moment(l.lastModifiedAt).format('YYYY') === currentYear
    );
    const cancelledLeaves = leaves?.filter(
      (l) =>
        l.status === 'cancelled' &&
        moment(l.lastModifiedAt).format('YYYY') === currentYear
    );
    const pendingLeaves = leaves?.filter(
      (l) =>
        l.status === 'pending' &&
        moment(l.lastModifiedAt).format('YYYY') === currentYear
    );

    const mergedList: Array<{
      leave: LeaveAppType;
      count: number;
      leaves: Array<LeaveAppType>;
    }> = [];
    for (let i = 0; leaves && i < leaves?.length; i++) {
      const curr = leaves[i];
      const matched = mergedList.find(
        (x) =>
          x.leave.dateStart === curr.dateStart &&
          x.leave.dateEnd === curr.dateEnd &&
          // This block was missing as a result there was days miscalculation
          x.leave.employee.id === curr.employee.id &&
          x.leave.type === curr.type &&
          x.leave.status === curr.status
        //
      );
      if (matched) {
        matched.leaves.push(curr);
        matched.count += curr.duration === 'full-day' ? 2 : 1;
      } else {
        const newObj = {
          leave: curr,
          count: curr.duration === 'full-day' ? 2 : 1,
          leaves: [curr],
        };
        mergedList.push(newObj);
      }
    }

    // const TableBody: TableBody = usedLeaves?.map((e) => ({
    //   cells: [
    //     {
    //       value: <span>{employeeLeaveTypeIconMapper({ type: e.type })} {e?.type}</span>
    //     },
    //     {
    //       value: e?.dateStart
    //     },
    //     {
    //       value: e?.dateEnd
    //     },
    //     {
    //       value: e?.duration
    //     },
    //     {
    //       value: <div>
    //         <UserCircleIcon className='inline-block w-5 h-5 text-blue-700' />
    //         <a target='_blank' href={`employees/${e.appliedBy?.id}/profile`} rel="noreferrer">{e.appliedBy?.name}</a>
    //       </div>
    //     },
    //     {
    //       value: <div>
    //         <UserCircleIcon className='inline-block w-5 h-5 text-blue-700' />
    //         <a target='_blank' href={`employees/${e.approvedBy?.id}/profile`} rel="noreferrer">{e.approvedBy?.name}</a>
    //       </div>
    //     },
    //     {
    //       value: e?.reason
    //     },
    //     {
    //       value: <span>{employeeLeaveStatusMapper(e.status)} {e?.status}</span>
    //     }
    //   ]
    // })) || []

    const leavesList = [
      ...mergedList.filter((l) => l.leave.status === 'un-consumed'),
      ..._.sortBy(mergedList, 'leave.dateStart')
        .filter((l) => l.leave.status !== 'un-consumed')
        .reverse(),
    ];

    const TableBody: TableBody =
      leavesList?.map((e) => ({
        cells: [
          {
            value: (
              <div className='flex'>
                <div className='my-auto mr-2'>
                  <span className='text-3xl'>
                    {employeeLeaveTypeIconMapper({ type: e.leave.type })}
                  </span>
                </div>
                <div>
                  <div className='text-lg'>
                    {employeeIconMapperByGrade({
                      grade: (e.leave.employee as any)?.details?.grade,
                    })}
                    <a
                      target='_blank'
                      href={`/hrd/resources/employees/${e.leave.employee.id}/profile`}
                      rel='noreferrer'
                    >
                      {e.leave.employee?.name}
                    </a>
                  </div>
                  <div className='italic'>
                    <span className='text-blue-600'>{e?.leave.type}</span>
                    {e.leave.dateStart ? (
                      <span className='mx-1'>
                        from{' '}
                        {moment(e?.leave.dateStart, 'YYYY-MM-DD').format('LL')}{' '}
                        to {moment(e.leave.dateEnd, 'YYYY-MM-DD').format('LL')}{' '}
                      </span>
                    ) : null}
                    {<b className='mx-1'>{e.count / 2} Day(s)</b>}
                  </div>
                </div>
              </div>
            ),
          },
          {
            value: (
              <div>
                {e?.leave.reason ? (
                  <div className='italic'>
                    <div className='font-semibold'>Reason</div>
                    {e?.leave.reason}
                  </div>
                ) : null}
                <div className='italic'>
                  <div className='font-semibold'>Justification</div>
                  {calculateJustifications(e.leaves, e.leave.type).map(
                    (x, idx) => (
                      <div className='flex' key={idx}>
                        <div className='mx-1'>{x.count} -</div>
                        <div className='mx-1'>{x.reason}</div>
                      </div>
                    )
                  )}
                </div>
                {e?.leave.details?.document ? (
                  <>
                    <span>Proofs:</span>
                    <ArrowDownCircleIcon
                      className='inline w-5 h-5 m-1 cursor-pointer'
                      onClick={async () => {
                        const res = await getPrivateFile(
                          `${e.leave.companyGroup.id}/${e.leave.company.id}/employees/leaves/${e.leave.employee.id}/${e?.leave.type}-${e?.leave.id}.pdf`,
                          true
                        );
                        const fName = `leave-document-of-${e?.leave.employee?.name
                          .split(' ')
                          .join('-')
                          .toLowerCase()}-${e?.leave.dateStart}-to-${
                          e?.leave.dateEnd
                        }`;
                        downloadPdfFile({ result: res, fileName: fName });
                      }}
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
            ),
          },
          {
            value: (
              <div>
                <userRendererService.userCard
                  link={true}
                  size='small'
                  id={e.leave.appliedBy.id}
                  name={e.leave.appliedBy.name}
                  extraInfo={'applied By'}
                />
                {e.leave.status === 'approved' ||
                (e.leave.status === 'cancelled' && e.leave.approvedBy) ? (
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={e.leave.approvedBy?.id || ''}
                    name={e.leave.approvedBy?.name || '??'}
                    extraInfo={`${e.leave.status} By`}
                  />
                ) : null}
              </div>
            ),
          },
          {
            value: (
              <div>
                {employeeLeaveStatusMapper(e.leave.status)} {e?.leave.status}
              </div>
            ),
          },
        ],
      })) || [];

    const summary = [
      {
        unused: getCount(unusedLeaves || [], 'casual-leave'),
        redeemed: getCount(redeemedLeaves || [], 'casual-leave'),
        approved: getCount(approvedLeaves || [], 'casual-leave'),
        cancelled: getCount(cancelledLeaves || [], 'casual-leave'),
        pending: getCount(pendingLeaves || [], 'casual-leave'),
        type: '⛱️ casual-leave',
        actualType: 'casual-leave',
        justifications: calculateJustifications(
          unusedLeaves || [],
          'casual-leave'
        ),
      },
      {
        unused: getCount(unusedLeaves || [], 'sick-leave'),
        redeemed: getCount(redeemedLeaves || [], 'sick-leave'),
        approved: getCount(approvedLeaves || [], 'sick-leave'),
        cancelled: getCount(cancelledLeaves || [], 'sick-leave'),
        pending: getCount(pendingLeaves || [], 'sick-leave'),
        type: '🤒 sick-leave',
        actualType: 'sick-leave',
        justifications: calculateJustifications(
          unusedLeaves || [],
          'sick-leave'
        ),
      },
      {
        unused: getCount(unusedLeaves || [], 'privilege-leave'),
        redeemed: getCount(redeemedLeaves || [], 'privilege-leave'),
        approved: getCount(approvedLeaves || [], 'privilege-leave'),
        cancelled: getCount(cancelledLeaves || [], 'privilege-leave'),
        pending: getCount(pendingLeaves || [], 'privilege-leave'),
        type: '💸 privilege-leave',
        actualType: 'privilege-leave',
        justifications: calculateJustifications(
          unusedLeaves || [],
          'privilege-leave'
        ),
      },
      {
        unused: getCount(unusedLeaves || [], 'compensatory-off'),
        redeemed: getCount(redeemedLeaves || [], 'compensatory-off'),
        approved: getCount(approvedLeaves || [], 'compensatory-off'),
        cancelled: getCount(cancelledLeaves || [], 'compensatory-off'),
        pending: getCount(pendingLeaves || [], 'compensatory-off'),
        type: '🤝 compensatory-off',
        actualType: 'compensatory-off',
        justifications: calculateJustifications(
          unusedLeaves || [],
          'compensatory-off'
        ),
      },
      // {
      //   count: getCount(unusedLeaves || [], 'unpaid-leave'),
      //   type: '😞 unpaid-leave',
      //   actualType: 'unpaid-leave',
      //   justifications: calculateJustifications(
      //     unusedLeaves || [],
      //     'unpaid-leave'
      //   ),
      // },
      // {
      //   count: getCount(unusedLeaves || [], 'maternity-leave'),
      //   type: '🤰 maternity-leave',
      //   actualType: 'maternity-leave',
      //   justifications: calculateJustifications(
      //     unusedLeaves || [],
      //     'maternity-leave'
      //   ),
      // },
      // {
      //   count: getCount(unusedLeaves || [], 'special-leave'),
      //   type: '🎁 special-leave',
      //   actualType: 'special-leave',
      //   justifications: calculateJustifications(
      //     unusedLeaves || [],
      //     'special-leave'
      //   ),
      // },
      // {
      //   count: getCount(unusedLeaves || [], 'retrenchment-leave'),
      //   type: '🛋️ retrenchment-leave',
      //   actualType: 'retrenchment-leave',
      //   justifications: calculateJustifications(
      //     unusedLeaves || [],
      //     'retrenchment-leave'
      //   ),
      // },
    ];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          {id ? (
            <div className='p-2 border border-gray-200 rounded-lg'>
              <p className='text-center font-bold text-xl'>
                Leaves Bank Summary
              </p>
              <div className='border border-gray-200 m-2 p-2'>
                <div className='flex flex-row flex-wrap text-xl'>
                  {summary.map((x, idx) => (
                    <div className='basis-1/4' key={idx}>
                      <div className='flex border border-gray-100 m-1'>
                        <div className='text-3xl border border-gray-50 px-2'>
                          <Popover className='relative'>
                            {({ open }) => (
                              <>
                                <Popover.Button className='text-5xl'>
                                  {x.unused}
                                </Popover.Button>
                                <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                                  <>
                                    <ul className='text-sm'>
                                      {x.justifications.map((y, yIdx) => (
                                        <li key={yIdx}>
                                          {y.count} - {y.reason}
                                        </li>
                                      ))}
                                    </ul>
                                  </>
                                </Popover.Panel>
                              </>
                            )}
                          </Popover>
                        </div>
                        <div className='w-full my-auto'>
                          <div>
                            {employeeLeaveTypeIconMapper({
                              type: x.actualType as LeaveType,
                            })}{' '}
                            {x.actualType}
                          </div>
                          <div className='text-sm'>
                            {x.redeemed} -redeemed / {x.cancelled} -cancelled
                          </div>
                          <div className='text-sm animate-pulse text-red-500'>
                            {x.approved} - approved / {x.pending} - pending
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
          {TableBody.length ? (
            <div className='w-full'>
              {loading ? (
                <Recat className='h-5 inline animate-pulse mx-4' />
              ) : (
                <Table header={TableHeader} body={TableBody} />
              )}
            </div>
          ) : (
            <>
              {loading ? (
                <div className='flex justify-center'>
                  <Recat className='h-5 inline animate-pulse mx-4' />
                </div>
              ) : null}
            </>
          )}
        </div>
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}

export const getCount = (arr: LeaveAppType[], type: LeaveType) => {
  return arr
    .filter((s) => s.type === type)
    .reduce((count, curr) => {
      return count + (curr.duration === 'full-day' ? 1 : 0.5);
    }, 0);
};

export const calculateJustifications = (
  arr: LeaveAppType[],
  type: LeaveType
) => {
  const justifications: Array<{ count: number; reason: string }> = [];
  const filteredLeaves = arr.filter((s) => s.type === type);

  filteredLeaves.forEach((x) => {
    let reason = x.justification || x.reason || 'Unknown / Legacy Leave';
    reason += ` created on ${moment(x.createdAt).format('YYYY-MM-DD')}`;
    const exists = justifications.find((y) => y.reason === reason);
    if (exists) {
      exists.count = exists.count + 0.5;
    } else {
      justifications.push({ count: 0.5, reason });
    }
  });

  return justifications;
};
