import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  AdvancedLoadingButton,
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { HrdDashboardReportInterface } from '../../../../models/interfaces/analytics/hrd-dashboard-report/report';

type RenderExpireLeavesBook = {
  hrdDashboardReportService: HrdDashboardReportInterface;
};

export type ProjectedLeaves = Array<{
  employee: EmployeeProfileType;
  leaves: {
    currentYear: {
      [key: string]: number;
    };
    nextYear: {
      [key: string]: {
        companyEncash: number;
        userEncash: number;
        expire: number;
        carryForward: number;
      };
    };
  };
  reason: string;
}>;

export const renderExpireLeavesBook = ({
  hrdDashboardReportService,
}: RenderExpireLeavesBook) => {
  return function EmployeeLeavesBook() {
    const Table = renderTableComponent();

    const previousYear =
      moment().format('YYYY') === '2024'
        ? '2024'
        : moment().subtract(1, 'year').format('YYYY');
    // const newYear = moment().format('YYYY') === '2024' ? '2025' : moment().format('YYYY');

    const [projectedLeaves, setProjectedLeaves] = useState<ProjectedLeaves>([]);

    useEffect(() => {
      hrdDashboardReportService
        .getExpiryLeavesSummary(previousYear)
        .then((res) => {
          setProjectedLeaves(res);
        });

      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      console.log(projectedLeaves);
    }, [projectedLeaves]);

    const tableHeader: TableHeader = [
      [
        { name: 'Grade', rowSpan: 2 },
        { name: 'Employee', rowSpan: 2 },
        {
          name: 'Privelege Leave',
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: 'Casual Leave',
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: 'Sick Leave',
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: 'Compensatory Off',
          colSpan: 4,
          style: 'text-center',
        },
        {
          name: 'Reason',
          rowSpan: 2,
          style: 'w-1/6',
        },
        { name: 'Select', rowSpan: 2 },
      ],

      [
        // Privelege Leaves
        {
          name: 'Current',
          style: 'border border-l-gray-150',
        },
        // Actions Leaves
        { name: 'Expire' },
        { name: 'Encash' },
        { name: 'Carry-Forward' },

        // Casual
        {
          name: 'Current',
          style: 'border border-l-gray-150',
        },
        // Actions Leaves
        { name: 'Expire' },
        { name: 'Encash' },
        { name: 'Carry-Forward' },

        // Sick
        {
          name: 'Current',
          style: 'border border-l-gray-150',
        },
        // Actions Leaves
        { name: 'Expire' },
        { name: 'Encash' },
        { name: 'Carry-Forward' },

        // Compoff
        {
          name: 'Current',
          style: 'border border-l-gray-150',
        },
        // Actions Leaves
        { name: 'Expire' },
        { name: 'Encash' },
        { name: 'Carry-Forward' },
      ],
    ];

    const tableData =
      projectedLeaves?.map((emp) => {
        const currentPl = emp.leaves?.currentYear?.['privilege-leave'];
        const currentCl = emp.leaves?.currentYear?.['casual-leave'];
        const currentSl = emp.leaves?.currentYear?.['sick-leave'];
        const currentCO = emp.leaves?.currentYear?.['compensatory-off'];

        const nextPl = emp?.leaves?.nextYear?.['privilege-leave'];
        const nextCl = emp?.leaves?.nextYear?.['casual-leave'];
        const nextSl = emp?.leaves?.nextYear?.['sick-leave'];
        const nextCO = emp?.leaves?.nextYear?.['compensatory-off'];

        return {
          cells: [
            { value: emp?.employee?.details?.grade?.name || '-' },
            {
              value: (
                <>
                  <div>{emp?.employee?.name}</div>
                  <div>Status {emp?.employee?.status}</div>
                  <div className='italic'>
                    {emp?.employee?.company?.name || 'abc'}
                  </div>
                </>
              ),
              link: `/users/profile/${emp?.employee?.id}`,
            },

            {
              value: (
                <>
                  <div>{currentPl || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextPl?.expire || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>
                    {(nextPl?.userEncash || 0) + (nextPl.companyEncash || 0)}
                  </div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextPl?.carryForward || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{currentCl || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextCl?.expire || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>
                    {(nextCl?.userEncash || 0) + (nextCl.companyEncash || 0)}
                  </div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextCl?.carryForward || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{currentSl || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextSl?.expire || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>
                    {(nextSl?.userEncash || 0) + (nextSl?.companyEncash || 0)}
                  </div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextSl?.carryForward || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{currentCO || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextCO?.expire || 0}</div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>
                    {(nextCO?.userEncash || 0) + (nextCO?.companyEncash || 0)}
                  </div>
                </>
              ),
            },
            {
              value: (
                <>
                  <div>{nextCO?.carryForward || 0}</div>
                </>
              ),
            },
            {
              value: emp.reason,
            },
          ],
        };
      }) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Refill Leaves Book',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table header={tableHeader} body={tableBody} />
          <div className='flex justify-end'>
            <AdvancedLoadingButton
              text='Submit'
              behaviour='modal'
              modal={{
                size: 'large',
                title: 'Expire Leave Bank',
                content: ({ onClose }) => {
                  return <></>;
                  // return <PersistLeaves onClose={onClose} />;
                },
              }}
              defaultStyle='px-4 py-2 m-1.5 bg-green-600 border rounded-lg'
            />
          </div>
        </>
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
};
