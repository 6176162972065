import { NewCompanyLoan } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';

import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';

// eslint-disable-next-line
export function renderAddCompanyForm(): (data: any) => JSX.Element {
  return function AddCompanyForm({ onSave }: { onSave: any }): JSX.Element {
    const formProps: FormProps<Partial<NewCompanyLoan>> = {
      fieldsData: [
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          property: 'tenureInMonths',
          type: 'number',
          label: 'Tenure (in Months)',
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
        },
      ],
      initialFormState: {
        amount: 0,
        tenureInMonths: 0,
        reason: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<NewCompanyLoan>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<NewCompanyLoan>>
    ): NewCompanyLoan {
      return {
        dateApplied: moment().format('YYYY-MM-DD'),
        amount: b.amount as number,
        tenureInMonths: b.tenureInMonths as number,
        reason: b.reason as string,
        status: 'pending',
        approval: {} as any,
        repaymentSchedule: [],
      };
    }
  };
}
