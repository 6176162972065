import { HsnType } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddHsnForm() {
  return function AddHsnForm({
    onSave,
  }: {
    onSave: (data: HsnType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<HsnType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        {
          property: 'hsnCode',
          type: 'input',
          label: 'hsnCode',
          required: true,
        },
        {
          property: 'chapter',
          type: 'input',
          label: 'Chapter',
          required: true,
        },
        {
          property: 'gstRate',
          type: 'number',
          label: 'Gst Rate',
          required: true,
        },
        {
          property: 'rateRevision',
          type: 'input',
          label: 'Rate Revision',
          required: true,
        },
        {
          property: 'cess',
          type: 'number',
          label: 'Cess',
          required: true,
        },
        {
          property: 'effectiveFrom',
          type: 'input',
          label: 'Effective From',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        description: '',
        gstRate: 0,
        effectiveFrom: '',
        rateRevision: '',
        chapter: '',
        hsnCode: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          description: l.description,
          effectiveFrom: l.effectiveFrom,
          gstRate: parseInt(`${l.gstRate}`),
          cess: parseInt(`${l.cess}`),
          hsnCode: l.hsnCode,
          rateRevision: l.rateRevision,
        } as HsnType);
      },
    };

    const Form = renderFormV2<Partial<HsnType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<HsnType>>): HsnType {
      return (b as unknown) as HsnType;
    }
  };
}
