import { EnquiryQuotes } from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseCombinedVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';

export type RenderVendorPurchaseEnquiryListProps = {
  useCombinedVendor: UseCombinedVendor;
  id: string;
};

// eslint-disable-next-line
export function renderVendorPurchaseEnquiryList({
  useCombinedVendor,
  id,
}: RenderVendorPurchaseEnquiryListProps): () => JSX.Element {
  return function VendorPurchaseEnquiryList(): JSX.Element {
    const { resource: vendor, get: getVendor } = useCombinedVendor();

    useEffect(() => {
      getVendor(id);
      // eslint-disable-next-line
    }, []);

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Vendor',
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [{ name: 'Enquiry Date' }, { name: 'Item' }, { name: 'Target Date' }],
    ];

    const tableBody: TableBody = vendor?.enquiryQuotes?.length
      ? vendor.enquiryQuotes.map((r: EnquiryQuotes) => ({
          cells: [
            { value: moment(r.createdAt).format('LL') },
            {
              value: r.purchaseEnquiry?.purchaseRequisitions ? (
                <span>
                  <b>
                    {
                      r.purchaseEnquiry?.purchaseRequisitions[0]?.itemDetails
                        ?.name
                    }
                  </b>
                  &nbsp;
                  <i className='text-gray-600'>
                    (
                    {
                      r.purchaseEnquiry?.purchaseRequisitions[0]?.details
                        ?.quantity
                    }
                    {
                      r.purchaseEnquiry?.purchaseRequisitions[0]?.itemDetails
                        ?.uom
                    }
                    )
                  </i>
                </span>
              ) : (
                <></>
              ),
            },
            {
              value: ` (${
                r.purchaseEnquiry?.purchaseRequisitions &&
                r.purchaseEnquiry?.purchaseRequisitions[0]?.details?.targetDate
              })`,
            },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
