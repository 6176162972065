const settings: {
  [key: string]: {
    mode: 'DEV' | 'PROD_IP' | 'PROD_URL';
    dev: string;
    prod_url: string;
    prod_ip: string;
  };
} = {
  ADMIN_SERVER_URI: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:2000',
    prod_url: 'https://admin-api.recat.in',
    dev: 'http://localhost:2000',
  },
  INVENTORY_SERVER_URI: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:5000/api/v1',
    prod_url: 'https://inventory-api.recat.in/api/v1',
    dev: 'http://localhost:5000/api/v1',
  },
  FILE_MANAGER_SERVER_URI: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:8000/api/v1',
    prod_url: 'https://files-api.recat.in/api/v1',
    dev: 'http://localhost:8000/api/v1',
  },
  NOTIFICATON_SERVER_URI: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:9000/api/v1',
    prod_url: 'https://notification-api.recat.in/api/v1',
    dev: 'http://localhost:9000/api/v1',
  },
  NOTIFICATION_SERVER: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:9000',
    prod_url: 'https://notification-api.recat.in',
    dev: 'http://localhost:9000',
  },
  ORDER_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:9001',
    prod_ip: 'http://192.168.0.50:9001',
    prod_url: 'https://order-api.recat.in',
  },
  INSURANCE_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:4050/api/v1',
    prod_ip: 'http://192.168.0.50:4050/api/v1',
    prod_url: 'https://insurance-api.recat.in/api/v1',
  },
  HRD_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:4000',
    prod_ip: 'http://192.168.0.50:4000',
    prod_url: 'https://hrd-api.recat.in',
  },
  PAYROLL_SERVER_URI: {
    mode: 'PROD_URL',
    prod_ip: 'http://192.168.0.50:4040',
    prod_url: 'https://payroll-api.recat.in',
    dev: 'http://localhost:4040',
  },

  PLANNING_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:3001',
    prod_ip: 'http://192.168.0.50:3000',
    prod_url: 'https://planning-api.recat.in',
  },
  ANALYTICS_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:6050',
    prod_ip: 'http://192.168.0.50:6050',
    prod_url: 'https://analytics-api.recat.in',
  },
  LOANS_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:4060/api/v1',
    prod_ip: 'http://192.168.0.50:4060/api/v1',
    prod_url: 'https://loans-api.recat.in/api/v1',
  },
  ACCOUNTS_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:11000',
    prod_url: 'https://accounts-api.recat.in',
    prod_ip: 'http://192.168.0.50:11000',
  },
  QUESTIONNAIRE_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:6060',
    prod_url: 'https://questionairre-api.recat.in',
    prod_ip: 'http://192.168.0.50:6060',
  },
  DISCIPLINARY_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:4020/api/v1',
    prod_ip: 'http://192.168.0.50:4020/api/v1',
    prod_url: 'https://disciplinary-api.recat.in/api/v1',
  },
  SALARY_ADVANCE_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:4030/api/v1',
    prod_ip: 'http://192.168.0.50:4030/api/v1',
    prod_url: 'https://salary-advance-api.recat.in/api/v1',
  },
  COMMUNICATION_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:7000/api/v1',
    prod_ip: 'http://192.168.0.50:7000/api/v1',
    prod_url: 'https://communication-api.recat.in/api/v1',
  },
  REPORTING_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:6055',
    prod_ip: 'http://192.168.0.50:6055',
    prod_url: 'https://reporting-api.recat.in',
  },
  QUALITY_ASSURANCE_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:5070/api/v1',
    prod_ip: 'http://192.168.0.50:5070/api/v1',
    prod_url: 'https://quality-assurance-api.recat.in/api/v1',
  },
  SCHEDULER_SERVER_URI: {
    mode: 'PROD_URL',
    dev: 'http://localhost:9050/api/v1',
    prod_ip: 'http://192.168.0.50:9050/api/v1',
    prod_url: 'https://scheduler-api.recat.in/api/v1',
  },
};

function getUrl(service: string): string {
  const serviceSettings = settings[service];
  if (serviceSettings) {
    switch (serviceSettings.mode) {
      case 'DEV':
        return serviceSettings.dev;
      case 'PROD_IP':
        return serviceSettings.prod_ip;
      case 'PROD_URL':
        return serviceSettings.prod_url;
      default:
        return '';
    }
  }

  return '';
}

export const ADMIN_SERVER_URI = getUrl('ADMIN_SERVER_URI');
export const INVENTORY_SERVER_URI = getUrl('INVENTORY_SERVER_URI');
export const FILE_MANAGER_SERVER_URI = getUrl('FILE_MANAGER_SERVER_URI');
export const NOTIFICATON_SERVER_URI = getUrl('NOTIFICATON_SERVER_URI');
export const NOTIFICATION_SERVER = getUrl('NOTIFICATION_SERVER');
export const ORDER_SERVER_URI = getUrl('ORDER_SERVER_URI');
export const INSURANCE_SERVER_URI = getUrl('INSURANCE_SERVER_URI');
export const HRD_SERVER_URI = getUrl('HRD_SERVER_URI');
export const PAYROLL_SERVER_URI = getUrl('PAYROLL_SERVER_URI');
export const PLANNING_SERVER_URI = getUrl('PLANNING_SERVER_URI');
export const ANALYTICS_SERVER_URI = getUrl('ANALYTICS_SERVER_URI');
export const LOANS_SERVER_URI = getUrl('LOANS_SERVER_URI');
export const ACCOUNTS_SERVER_URI = getUrl('ACCOUNTS_SERVER_URI');
export const QUESTIONNAIRE_SERVER_URI = getUrl('QUESTIONNAIRE_SERVER_URI');
export const DISCIPLINARY_SERVER_URI = getUrl('DISCIPLINARY_SERVER_URI');
export const SALARY_ADVANCE_SERVER_URI = getUrl('SALARY_ADVANCE_SERVER_URI');
export const COMMUNICATION_SERVER_URI = getUrl('COMMUNICATION_SERVER_URI');
export const REPORTING_SERVER_URI = getUrl('REPORTING_SERVER_URI');
export const QUALITY_ASSURANCE_SERVER_URI = getUrl(
  'QUALITY_ASSURANCE_SERVER_URI'
);
export const SCHEDULER_SERVER_URI = getUrl('SCHEDULER_SERVER_URI');
