import { Revision } from '@erp_core/erp-types/dist/modules/inventory';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseCombinedGST } from '../../../../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../../../../hooks/admin/constants/metrics/use-metric';

type EditPropertyValueProps = {
  itemProperty: ItemProperty;
  currentValue: IdName;
  onSave: (data: Partial<Revision>) => Promise<void>;
  useResources: string;
};

export type EditPropertyIdNameFormType = {
  currentValue: IdName;
  newValue: IdName;
};

export const renderEditObjectPropertyValue = ({
  useCombinedMetric,
  useCombinedGST,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
}) => {
  return function EditObjectPropertyValue({
    itemProperty,
    currentValue,
    onSave,
    useResources,
  }: EditPropertyValueProps) {
    // const useSearchResource = (useResources: string) => {
    //   switch (useResources) {
    //     case 'useMetrics':
    //       return useMetrics;
    //     case 'useGST':
    //       return useGsts;
    //     default:
    //       return null;
    //   }
    // };

    const Table = renderTableComponent();

    // const searchResource = useSearchResource(useResources);
    // if (!searchResource) {
    //   return <></>;
    // }

    const [newValues, setNewValues] = useState<any>({});

    function getPropertyEditor(
      itemProperty: ItemProperty,
      key: string
    ): JSX.Element {
      if (
        itemProperty.childrenProperties &&
        itemProperty.childrenProperties[key].name &&
        ['input', 'number'].includes(itemProperty.childrenProperties[key].type)
      ) {
        return (
          <>
            <input
              className='border-gray-100 p-1'
              value={newValues[itemProperty.childrenProperties[key].name] || ''}
              onChange={(evt) => {
                if (evt.target.value) {
                  const property =
                    (itemProperty.childrenProperties &&
                      itemProperty.childrenProperties[key].name) ||
                    '';
                  setNewValues((v) => ({
                    ...v,
                    ...{ [property]: evt.target.value },
                  }));
                }
              }}
            />
          </>
        );
      }

      return <></>;
    }

    console.log('currentValue', currentValue);
    const TableBody: TableBody = (itemProperty.childrenProperties &&
    _.keys(itemProperty.childrenProperties).length
      ? _.keys(itemProperty.childrenProperties)
      : []
    ).map((key) => {
      return {
        cells: [
          {
            value:
              (itemProperty.childrenProperties &&
                itemProperty.childrenProperties[key].name) ||
              '',
          },
          { value: currentValue ? JSON.stringify(currentValue) : '' },
          {
            value:
              itemProperty.childrenProperties &&
              itemProperty.childrenProperties[key].name
                ? getPropertyEditor(itemProperty, key)
                : '',
          },
        ],
      };
    });

    return (
      <div>
        Object Property {itemProperty.name} Edit
        <Table
          header={[
            [
              { name: 'Property' },
              { name: 'Old Value' },
              { name: 'New Value' },
            ],
          ]}
          body={TableBody}
        />
        <div className='flex justify-center'>
          <LoadingButton
            behaviorFn={async () =>
              await onSave(({
                PropertyValueuset: {
                  currentValue: {
                    value: {
                      data: currentValue,
                    },
                  },
                  newValue: {
                    value: {
                      data: newValues,
                    },
                  },
                },
              } as unknown) as Revision)
            }
            defaultStyle='bg-green-500 text-white p-1'
            text='Save'
          />
        </div>
      </div>
    );
  };
};
