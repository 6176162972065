type InsuranceType = 'to-your-account' | 'to-our-account';
type Freight = 'paid-basis' | 'to-pay-basis';

export type DeliveryTerm = {
  id: string;
  name: string;
  insurance: InsuranceType;
  freight: Freight;
};

export const deliveryTermData: DeliveryTerm[] = [
  {
    id: '1',
    name: 'Solid goods',
    insurance: 'to-our-account',
    freight: 'paid-basis',
  },
  {
    id: '2',
    name: 'Gas Product',
    insurance: 'to-your-account',
    freight: 'to-pay-basis',
  },
  // TODO add Appropriate Data
];

export class DeliveryTermRepo {
  getAll(filter: {
    name?: string;
    insurance?: InsuranceType;
    freight: Freight;
  }) {
    const result = deliveryTermData.filter((a) => {
      return (
        (!filter.name || a.name.includes(filter.name)) &&
        (!filter.insurance || a.insurance === filter.insurance) &&
        (!filter.freight || a.freight === filter.freight)
      );
    });
    return result;
  }

  get(id: string) {
    return deliveryTermData.find((d) => d.id === id) || null;
  }
}
