import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import { Popover } from '@headlessui/react';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';

export function OffSiteIcon({ d }: { d: CombinedAttDataType }): JSX.Element {
  const empOffSiteWorkHours = d?.offSiteWorkHours?.filter(
    (el) =>
      el.employee.id === d.employee.id &&
      ['redeemed', 'approved', 'pending'].includes(el.status)
  );

  return (
    <>
      {empOffSiteWorkHours?.map((l) => {
        if (l.employee.id === d.employee.id) {
          return l.status.includes('pending') ? (
            <Popover className='relative' key={l.id}>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <BuildingOffice2Icon className='w-5 h-5 text-blue-500 inline-block' />
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div className='flex flex-col justify-center'>
                      Off-site work hours applied by {l?.appliedBy?.name}
                      <span>Reason: {l?.reason}</span>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ) : (
            <Popover className='relative' key={l.id}>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <BuildingOffice2Icon className='w-5 h-5 text-green-500 inline-block' />
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div className='flex flex-col justify-center'>
                      Off-site work hours {l.status} by {l?.approvedBy?.name}
                      <span>Reason: {l?.reason}</span>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          );
        }
        return null;
      })}
    </>
  );
}
