import { Vendor } from '@erp_core/erp-types/dist/modules/order';

export type VendorsToSendEnquiry = {
  id: string;
  name: string;
  vendorItemId: string;
  contactPerson: {
    id: string;
    name: string;
  };
  supplierLocation: {
    id: string;
    name: string;
  };
}[];

export const renderSelectVendorContact = () => {
  return function SelectVendorContact({
    vendorDetails,
    vendorsToSentEnquiries,
    setVendorsToSentEnquiries,
  }: {
    vendorDetails: Vendor[];
    vendorsToSentEnquiries: VendorsToSendEnquiry;
    setVendorsToSentEnquiries: any;
  }) {
    const handleContactChange = (vendorItemId: string, vendorId: string) => {
      setVendorsToSentEnquiries((current) => {
        const entry = current.find((c) => c.id === vendorId);
        const vendor = vendorDetails.find((v) => v.id === vendorId);
        const vendorItem = vendor?.items.find((vi) => vi.id === vendorItemId);
        const contactPerson = vendorItem?.details.contactPerson || {
          id: '',
          name: '',
        };
        const supplierLocation = vendorItem?.details.supplierLocation || {
          id: '',
          name: '',
        };
        if (!entry) {
          return [
            ...current,
            {
              id: vendorId,
              name: vendor?.name || '',
              vendorItemId: vendorItemId,
              contactPerson,
              supplierLocation,
            },
          ];
        } else {
          return current.map((c) => {
            if (c.id !== vendorId) return c;
            return {
              ...c,
              vendorItemId,
              contactPerson,
              supplierLocation,
            };
          });
        }
      });
    };

    return (
      <div>
        {vendorDetails.length > 0 && (
          <div className='mb-2 font-semibold'> Selected Vendors </div>
        )}
        <div className='grid grid-cols-3 gap-4 mb-2'>
          {vendorDetails.map((vd, index) => {
            const selectedVendorId = vendorsToSentEnquiries.find(
              (v) => v.id === vd.id
            )?.vendorItemId;
            return (
              <div key={index} className='p-2 border rounded-lg w-60  mr-2'>
                <div className='mb-1'>{vd.name}</div>
                <div>
                  <label className='mr-2'>Select Contact</label>
                  <select
                    name='contact'
                    onChange={(e) => handleContactChange(e.target.value, vd.id)}
                    required={true}
                    value={selectedVendorId || ''}
                  >
                    <option value=''>Select</option>
                    {vd.items?.map((c, ci) => {
                      return (
                        <option value={c.id}>
                          {c.details?.contactPerson?.name} -{' '}
                          {c.details?.supplierLocation?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
};
