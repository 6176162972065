import { EmployeeCategory } from '@erp_core/erp-types/dist/modules/constants';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddEmployeeCategoryForm() {
  return function AddEmployeeCategoryForm({
    onSave,
  }: {
    onSave: (data: EmployeeCategory) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<EmployeeCategory>> = {
      fieldsData: [
        {
          property: 'id',
          type: 'input',
          label: 'Id',
          required: true,
        },
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
      ],
      initialFormState: {
        id: '',
        name: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          id: l.id,
          name: l.name,
        } as EmployeeCategory);
      },
    };

    const Form = renderFormV2<Partial<EmployeeCategory>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EmployeeCategory>>
    ): EmployeeCategory {
      return (b as unknown) as EmployeeCategory;
    }
  };
}
