import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { renderTableComponent, TableHeader } from '@erp_core/erp-ui-components';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { findExtractableProperties } from '../util/esic-calculator';

export function createCompareRevision({
  activeSalaryRevision,
  selectedParams,
  salaryRevisions,
  employee,
  onClose,
}: {
  activeSalaryRevision: SalaryParamType;
  selectedParams: SalaryParamType[];
  salaryRevisions: SalaryRevisionType[];
  employee: EmployeeProfileType;
  onClose: () => void;
}): () => JSX.Element {
  const Table = renderTableComponent();
  const salRevs = _.sortBy(salaryRevisions, 'revisionNum').reverse();
  const selectedParam = selectedParams.find(
    (x) => x.name === employee.details.salaryParam?.name
  );
  const propertiesToShow: { property: string; group: string }[] = [];

  propertiesToShow.push({ group: '', property: 'ctcAnnual' });
  propertiesToShow.push({ group: '', property: 'incomeTax' });
  propertiesToShow.push({ group: '', property: 'skillLevel' });
  propertiesToShow.push({ group: '', property: 'epsContributionPercent' });
  propertiesToShow.push({ group: '', property: 'capWagesForPfContribution' });
  propertiesToShow.push({ group: '', property: 'advanceBonusPayout' });
  propertiesToShow.push({ group: '', property: 'isRetentionApplicable' });
  propertiesToShow.push({ group: '', property: 'notes' });

  if (selectedParam) {
    findExtractableProperties(selectedParam.details.inner, propertiesToShow);
  }

  return function CompareRevision(): JSX.Element {
    const tableHeader: TableHeader = [[{ name: 'Parameters', rowSpan: 2 }], []];
    salRevs.forEach((x) => {
      tableHeader[0].push({ name: x.name });
      tableHeader[1].push({
        name: (
          <>
            {x.status}{' '}
            {x.id === activeSalaryRevision.id ? (
              <span className='border border-gray-200 text-green-500 rounded-full p-0.5 animate-pulse'>
                active
              </span>
            ) : null}
          </>
        ),
      });
    });
    return (
      <div>
        <Table
          header={tableHeader}
          body={propertiesToShow.map((ps) => {
            return {
              cells: [
                { value: _.startCase(ps.property) },
                ..._.map(_.times(salRevs.length), (x, idx) => {
                  if (ps.group) {
                    const salParams = salRevs[idx].details?.salParams;
                    const salRevProperty =
                      salParams && salParams[ps.group]
                        ? salParams[ps.group][ps.property]
                        : 'not-saved';
                    return { value: salRevProperty };
                  }
                  let difference = 0;
                  let diffPercent = '';
                  const salRevProperty =
                    salRevs[idx].details && salRevs[idx].details[ps.property];
                  if (
                    ps.property === 'ctcAnnual' &&
                    idx + 1 !== salRevs.length
                  ) {
                    const prevCtc =
                      salRevs[idx + 1].details &&
                      salRevs[idx + 1].details[ps.property];
                    difference = parseInt(salRevProperty) - parseInt(prevCtc);
                    diffPercent = (
                      (difference / parseInt(prevCtc)) *
                      100
                    ).toFixed(2);
                  }
                  if (difference) {
                    return {
                      value: (
                        <>
                          {salRevProperty} (
                          {difference > 0 ? (
                            <>
                              <ArrowUpIcon className='w-5 inline text-green-500 mx-1' />
                              {difference} [{diffPercent}%]
                            </>
                          ) : (
                            <>
                              <ArrowDownIcon className='w-5 inline text-red-500 mx-1' />
                              {difference} [{diffPercent}%]
                            </>
                          )}
                          )
                        </>
                      ),
                    };
                  }
                  return {
                    value: <>{salRevProperty}</>,
                  };
                }),
              ],
            };
          })}
        />
      </div>
    );
  };
}
