import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import toast from 'react-hot-toast';

export async function saveNewOvertime({
  form,
  getUserSync,
  currentUser,
  setOvertime,
  onClose,
}: {
  form: any;
  getUserSync: (id: string) => Promise<EmployeeProfileType>;
  currentUser: CurrentUserType;
  setOvertime: (s: OvertimeType) => void;
  onClose: () => void;
}) {
  const emp = await getUserSync(form.employee.id);
  try {
    const finalData = {
      ...form,
      details: {
        requestedRedemptionFlow: form.requestedRedemptionFlow,
      },
      company: emp.company,
      issuedBy: {
        id: currentUser.id,
        name: currentUser.name,
      },
    } as OvertimeType;
    setOvertime(finalData);
    toast('Overtime created sucessfully');
    onClose();
  } catch (error) {
    toast('Something went wrong');
  }
}
