import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
// import { renderTabsComponent } from '@erp_core/erp-ui-components';
import { DataArray, renderTabsV2Component } from '@erp_core/erp-ui-components';
// import { nanoid } from 'nanoid';
import { UseCombinedUser } from '../../../hooks/admin/user-admin/use-users-admin';
import { UseBatchItemAllocate } from '../../../hooks/inventory/item/batch/use-batch-item-allocate';
import { UseCombinedBatchItemGodown } from '../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UsePhysicalStock } from '../../../hooks/inventory/item/stock/use-physical-stock';
import { UseVirtualMFStock } from '../../../hooks/inventory/item/stock/use-virtual-manufacturing-stock';
import { UseCombinedItem } from '../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { UseCombinedSalesOrder } from '../../../hooks/order/sales/order/use-sales-order';
import { UseCombinedManufacturingVoucher } from '../../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';
import { UseVirtuallyReserveMfVoucher } from '../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { UserRendererInterface } from '../../common/fragments/user';
import renderSalesItemOrderDetails from '../item-order';
import renderSalesManufacturingOrderDetails from '../manufacturing-order';
import renderSalesOrderDetails from '../sales-order';

type RenderSalesOrderTabsProps = {
  hooks: {
    useCombinedItem: UseCombinedItem;
    useCombinedClient: UseCombinedClient;
    useCombinedSalesOrder: UseCombinedSalesOrder;
    usePhysicalStock: UsePhysicalStock;
    useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
    useBatchGodownItemAllocate: UseBatchItemAllocate;
    useVirtualMfStock: UseVirtualMFStock;
    useCombinedManufacturingVoucher: UseCombinedManufacturingVoucher;
    useVirtuallyReserveMfVoucher: UseVirtuallyReserveMfVoucher;
    useCombinedUser: UseCombinedUser;
    userRendererService: UserRendererInterface;
  };
};

export function renderSalesOrderTabs({
  hooks: {
    useCombinedItem,
    useCombinedClient,
    useCombinedSalesOrder,
    usePhysicalStock,
    useBatchGodownItemAllocate,
    useCombinedBatchItemGodown,
    useVirtualMfStock,
    useCombinedManufacturingVoucher,
    useVirtuallyReserveMfVoucher,
    useCombinedUser,
    userRendererService,
  },
}: RenderSalesOrderTabsProps): () => JSX.Element {
  return function SalesOrderTabs(): JSX.Element {
    // const Tabs = renderTabsComponent();
    const NewTabs = renderTabsV2Component();
    const Orders = renderSalesOrderDetails({
      useCombinedItem,
      useCombinedClient,
      useCombinedSalesOrder,
      useCombinedUser,
      userRendererService,
    });
    const Allocate = renderSalesItemOrderDetails({
      usePhysicalStock,
      useBatchGodownItemAllocate,
      useCombinedBatchItemGodown,
      useVirtualMfStock,
      useCombinedManufacturingVoucher,
      useVirtuallyReserveMfVoucher,
      useCombinedSalesOrder,
    });

    const Manufacture = renderSalesManufacturingOrderDetails();

    // const categories: Map<string, () => JSX.Element> = new Map<
    //   string,
    //   () => JSX.Element
    // >([
    //   ['Orders', Orders],
    //   ['Allocate', Allocate],
    //   ['Manufacture', Manufacture],
    // ]);

    const newCategories: DataArray = [
      { name: 'orders', Fn: Orders },
      { name: 'allocate', Fn: Allocate },
      { name: 'manufacture', Fn: Manufacture },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Sales Orders',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          {/* <Tabs
            data={categories}
            keyTab={nanoid()}
            defaultTabIndex={0}
            components={{ Tab }}
          /> */}
          <NewTabs data={newCategories} manipulateLocation={true} />
        </>
      ),
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
