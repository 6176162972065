/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import {
  DepartmentAppType,
  DepartmentFilterType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpDepartmentRepo extends GenericHttpWithLogsRepo<
  DepartmentAppType,
  DepartmentFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/department',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
