import { EmployeeFamilyType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddEmployeeFamilyForm() {
  return function AddEmployeeFamilyForm({
    onSave,
    data,
  }: {
    onSave: (data: EmployeeFamilyType) => Promise<void>;
    data?: EmployeeFamilyType;
  }): JSX.Element {
    const formProps: FormProps<Partial<EmployeeFamilyType>> = {
      fieldsData: [
        {
          property: 'relation',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Spouse', value: 'Spouse' },
            { text: 'Son', value: 'Son' },
            { text: 'Daughter', value: 'Daughter' },
            { text: 'Father', value: 'Father' },
            { text: 'Mother', value: 'Mother' },
            { text: 'Other', value: 'Other' },
          ],
        },
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Name',
        },
        {
          property: 'phone',
          type: 'input',
          label: 'Phone No.',
        },
        {
          property: 'education',
          type: 'input',
          required: true,
          label: 'Education',
        },
        { property: 'profession', type: 'input' },
        {
          property: 'birthDate',
          type: 'date',
          required: true,
          label: 'BirthDate',
        },
        {
          property: 'emergency',
          type: 'select',
          options: [
            { text: 'Yes', value: 'Yes' },
            { text: 'No', value: 'No' },
          ],
          label: 'Emergency',
        },
        {
          property: 'emailAddress',
          type: 'input',
          label: 'Email',
        },
        { property: 'deathDate', type: 'date' },
      ],
      initialFormState: {
        relation: '' || data?.relation,
        name: '' || data?.name,
        phone: '' || data?.phone,
        education: '' || data?.education,
        profession: '' || data?.profession,
        birthDate: '' || data?.birthDate,
        emergency: '' || data?.emergency,
        emailAddress: '' || data?.emailAddress,
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          relation: l.relation || data?.relation,
          name: l.name || data?.name,
          phone: l.phone || data?.phone,
          education: l.education || data?.education,
          profession: l.profession || data?.profession,
          birthDate: l.birthDate || data?.birthDate,
          emergency: l.emergency || data?.emergency,
          emailAddress: l.emailAddress || data?.emailAddress,
        } as EmployeeFamilyType);
      },
    };

    const Form = renderFormV2<Partial<EmployeeFamilyType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EmployeeFamilyType>>
    ): EmployeeFamilyType {
      return (b as unknown) as EmployeeFamilyType;
    }
  };
}
