/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import { BOMRes, PurchaseOrder } from '@erp_core/erp-types/dist/modules/order';
import moment from 'moment';
import toast from 'react-hot-toast';
import { VirtuallyAllocateData } from '../../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  PurchaseOrderFilterType,
  PurchaseOrderInterface,
} from '../../../../../models/interfaces/order/purchase/purchase-order';
import { BOMInterface } from '../../../../../models/interfaces/order/work-order/bom';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpPurchaseOrderRepo
  extends GenericHttpWithLogsRepo<PurchaseOrder, PurchaseOrderFilterType>
  implements PurchaseOrderInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  bomService: BOMInterface;

  constructor(apiUrl: string, erpv2Api: ErpV2Api, bomService: BOMInterface) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/purchase/purchase-order',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
    this.bomService = bomService;
  }

  async createBulkOrder(enquiries: Array<string>): Promise<void> {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });

    const reqBody = {
      enquiries,
    };
    const result = await http.post(
      '/api/v1/purchase/purchase-order/bulk-purchase-orders',
      reqBody
    );

    if (result.status === 200) {
      toast.success('Purchase Orders Dispatched');
    }
    console.log(result);
  }

  async allocateItem(data: VirtuallyAllocateData) {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const reqBody = {
      purchaseOrderItemsId: data.id,
      referenceId: data.referenceId,
      quantity: data.quantity,
    };
    const result = await http.post(
      '/api/v1/purchase/purchase-order/allocate',
      reqBody
    );

    if (result.status === 200) {
      if (data.referenceType === 'bom') {
        const bomData = await this.bomService.get(data.referenceId);

        const bomDataRequest: BOMRes = {
          id: data.referenceId,
          details: {
            status:
              bomData.details.status === 'partially-allocated'
                ? 'partially-allocated'
                : 'allocated',
            activity: [
              {
                action: 'virtual-allocation',
                details: '',
                user: data.currentUser,
                event: `${data.currentUser.name} virtually allocated ${data.quantity} ${bomData.rawItem.name} the BOM ${bomData.name}`,
                time: moment.utc().format(),
              },
              ...(bomData.details.activity || []),
            ] as any,
          },
        } as BOMRes;

        await this.bomService.set(bomDataRequest);
      }
    }

    return !!result.data;
  }
}
