/* eslint-disable no-unused-vars */
import {
  ManufacturingVoucher,
  SalesEnquiry,
} from '@erp_core/erp-types/dist/modules/order';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { ManufacturingVoucherInterface } from '../../../../models/interfaces/order/work-order/manufacturing-voucher.ts/index.js';

type Props = {
  onSave: any;
  enquiryData: SalesEnquiry;
  manufacturingVoucherService: ManufacturingVoucherInterface;
};
// eslint-disable-next-line
export function renderSendSalesEnquiryQuotesForm(): (
  props: Props
) => JSX.Element {
  return function AddSalesOrderForm({
    onSave,
    enquiryData,
    manufacturingVoucherService,
  }: Props): JSX.Element {
    const [items, setItems] = useState<any[]>([]);
    const [errors, setErrors] = useState<any[]>([]);

    useEffect(() => {
      const myRunner = async () => {
        Promise.all(
          enquiryData.enquiryItems.map(async (i) => {
            const mv = (await manufacturingVoucherService.getAll({
              itemId: i.item.id,
              referenceId: i.id,
            })) as ManufacturingVoucher[];
            let allocatedQnt = 0;
            if (mv.length > 0 && mv[0].manufacturingVoucherItems.length > 0) {
              allocatedQnt = mv[0].manufacturingVoucherItems.reduce(
                (p, c) => p + c.quantity,
                0
              );
            }
            if (allocatedQnt >= i.quantity) {
              const itemName = (
                <span key={i.id} className='mb-2'>
                  Amount for {i.item.name} -{' '}
                  <span className='rounded shadow bg-blue-200'>
                    {i.quantity} kg
                  </span>{' '}
                  needed
                </span>
              );
              items.push({
                name: i.id,
                type: 'text',
                label: itemName,
              });
              setItems([...items]);
            } else {
              const label = (
                <span>
                  <li>
                    Can not send quotes for
                    <span className='bg-blue-200 rounded shadow'>
                      {i.item.name}
                    </span>
                    allocate/ reserve is pending.
                    <span className='bg-red-200 rounded shadow'>
                      {i.quantity - allocatedQnt} remaining out of {i.quantity}
                    </span>
                  </li>
                </span>
              );
              errors.push({
                type: 'label',
                label,
              });
              setErrors([...errors]);
            }
          })
        );
      };
      myRunner();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {}, [items, errors]);

    const Form = renderFormV2({
      fieldsData: [],
      mapTToU: (f) => f,
      onSubmit: async () => {},
      initialFormState: {},
    });

    return (
      <Form
      // onSubmit={(form) => {
      //   onSave(form);
      // }}
      // body={{
      //   fields: [
      //     {
      //       name: 'enquiry-name',
      //       label: 'Enquiry Name',
      //       required: true,
      //       default: enquiryData.name || '',
      //       type: 'text',
      //     },
      //     {
      //       type: 'label',
      //       label: 'Add Quoted Price for items',
      //     },
      //     ...items,
      //     {
      //       type: 'label',
      //       label: 'Items with remaining allocation/ reservation',
      //     },
      //     ...errors,
      //     {
      //       name: 'availableBy',
      //       label: 'Available By',
      //       type: 'date',
      //       required: true,
      //     },
      //     {
      //       name: 'credit',
      //       label: 'Credit Days',
      //       type: 'number',
      //       default: 15,
      //     },
      //   ],
      // }}
      />
    );
  };
}
