import { TemplatesType } from '@erp_core/erp-types/dist/modules/communication';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddTemplateForm() {
  return function AddTemplateForm({
    onSave,
  }: {
    onSave: (data: TemplatesType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<TemplatesType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
        {
          property: 'type',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'pdf', value: 'pdf' },
            { text: 'mail', value: 'mail' },
          ],
          required: true,
          label: 'Type',
        },
        {
          property: 'includesGraph',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
          required: true,
          label: 'Includes Graph',
        },
        {
          property: 'enabled',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
          required: true,
          label: 'Enabled',
        },
        {
          property: 'companySpecific',
          type: 'select',
          options: [
            { text: 'select', value: '' },
            { text: 'yes', value: 'yes' },
            { text: 'no', value: 'no' },
          ],
          required: true,
          label: 'Company Specific',
        },
      ],
      initialFormState: {
        name: '',
        description: '',
        type: '',
        includesGraph: '',
        enabled: '',
        companySpecific: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          description: l.description,
          type: l.type,
          includesGraph: l.includesGraph?.toString() === 'yes' ? true : false,
          enabled: l.enabled?.toString() === 'yes' ? true : false,
          companySpecific:
            l.companySpecific?.toString() === 'yes' ? true : false,
        } as TemplatesType);
      },
    };

    const Form = renderFormV2<Partial<TemplatesType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<TemplatesType>>): TemplatesType {
      return (b as unknown) as TemplatesType;
    }
  };
}
