import { SalaryType } from '@erp_core/erp-types/dist/types/modules/payroll/salary';
import {
  LoadingButton,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';

export function renderForm27({
  useCombinedEmployeeProfile,
  month,
  salaries,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  month: string;
  salaries: SalaryType[];
}): () => JSX.Element {
  const Table = renderTableComponent();
  return function Form27(): JSX.Element {
    const [loading, setLoading] = useState<boolean>(false);
    const { getListSync: getEmployees } = useCombinedEmployeeProfile();
    const [result, setResult] = useState<
      {
        employeeName: string;
        employeeId: string;
        company: string;
        resigned: string;
        gender: string;
        paydays: number;
        otHours: string;
        ncpDays: number;
        attendanceBonus: string;
        eligibleWeeklyOffs: number;
        paidLeaves: number;
        publicHolidays: number;
      }[]
    >([]);

    useEffect(() => {
      setLoading(true);
      getEmployees().then((employees) => {
        const daysInMonth = moment(month, 'YYYY-MM').daysInMonth();
        setResult(
          employees
            .filter((x) => x.details?.employeeId)
            .sort((a, b) => {
              return (
                parseInt(a.details?.employeeId || '0') -
                parseInt(b.details?.employeeId || '0')
              );
            })
            .map((x) => {
              const salary = salaries.find(
                (y) => y.employee.id === x.id.toLowerCase()
              );
              console.log(salary);

              return {
                employeeName: x.name,
                company: x.company?.name,
                employeeId: x.details?.employeeId || '',
                resigned: x.details?.resigned === 'yes' ? 'yes' : '',
                gender: x.details?.gender || '',
                paydays:
                  salary?.details?.salaryParams?.other?.adjustedPayableDays ||
                  0,
                otHours:
                  salary?.details?.salaryParams?.other?.overtimeHours || '',
                ncpDays:
                  daysInMonth -
                  (salary?.details?.salaryParams?.other?.adjustedPayableDays ||
                    0),
                attendanceBonus:
                  salary?.details?.salaryParams?.earning?.attendanceBonus || 0,
                eligibleWeeklyOffs:
                  salary?.details?.salaryParams?.other?.eligibleWeeklyOffs || 0,
                paidLeaves:
                  salary?.details?.salaryParams?.other?.totalPaidLeaves || 0,
                publicHolidays:
                  salary?.details?.salaryParams?.attendance?.holidays?.company
                    ?.length || 0,
              };
            })
        );

        setLoading(false);
      });
      // eslint-disable-next-line
    }, []);

    return (
      <div>
        <div className='flex border border-gray-200 p-1 rounded'>
          <div className='text-center flex-auto text-lg'>Form 27</div>
          <div className='flex-none text-lg'>{month}</div>
          <div className='flex-none'>
            <LoadingButton
              defaultStyle='bg-green-500 text-white p-1 border rounded-lg'
              behaviorFn={async () => {
                const elt = document.getElementById('form-27');
                const workbook = utils.table_to_book(elt);
                var ws = workbook.Sheets['form-27'];
                utils.sheet_add_aoa(ws, [], { origin: -1 });
                writeFileXLSX(workbook, 'form-27.xlsx');
              }}
              text={
                <>
                  <ArrowDownTrayIcon className='w-5 inline' /> Download
                </>
              }
            />
          </div>
        </div>
        <div className='flex justify-center'>
          {loading ? 'Loading...' : null}
        </div>
        <div className='border border-gray-200 p-1 my-1' id='form-27'>
          <Table
            header={[
              [
                { name: 'Employee' },
                { name: 'EmployeeId' },
                { name: 'Company' },
                { name: 'Resigned' },
                { name: 'Gender' },
                { name: `Paydays ${month}` },
                { name: 'OTHours' },
                { name: 'CO Earned' },
                { name: 'NCP Days' },
                { name: 'Attendance Bonus' },
                { name: 'Eligible Weekly Offs' },
                { name: 'Paid Leaves' },
                { name: 'Public Holidays' },
              ],
            ]}
            body={result.map((x) => {
              return {
                cells: [
                  { value: x.employeeName },
                  { value: x.employeeId },
                  { value: x.company },
                  { value: x.resigned },
                  { value: x.gender },
                  { value: x.paydays },
                  { value: x.otHours },
                  { value: '' },
                  { value: x.ncpDays },
                  { value: x.attendanceBonus },
                  { value: x.eligibleWeeklyOffs },
                  { value: x.paidLeaves },
                  { value: x.publicHolidays },
                ],
              };
            })}
          />
        </div>
      </div>
    );
  };
}
