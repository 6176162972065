import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';

type PersonalDetails = {
  prefix: 'Miss' | 'Mrs' | 'Mr' | 'Dr';
  firstName: string;
  middleName: string;
  lastName: string;
  gender: 'Other' | 'Female' | 'Male';
  birthDate: string;
  maritalStatus: 'Married' | 'Unmarried';
  // photo: string;
  identificationMarks: string;
  bloodGroup: 'A+' | 'O+' | 'B+' | 'AB+' | 'A-' | 'O-' | 'B-' | 'AB-';
  height: number;
  weight: number;
};

// eslint-disable-next-line
export function renderEditPersonalDetailsForm(): (data: any) => JSX.Element {
  return function EditPersonalDetailForm({
    data,
    onSave,
  }: {
    data: PersonalDetails;
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<PersonalDetails> = {
      fieldsData: [
        {
          property: 'prefix',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Miss', value: 'Miss' },
            { text: 'Mr', value: 'Mr' },
            { text: 'Mrs', value: 'Mrs' },
            { text: 'Dr', value: 'Dr' },
          ],
          required: true,
          label: 'Prefix',
        },
        {
          property: 'firstName',
          type: 'input',
          required: true,
          label: 'First Name',
        },
        {
          property: 'middleName',
          type: 'input',
          label: 'Middle Name',
        },
        {
          property: 'lastName',
          type: 'input',
          required: true,
          label: 'Last Name',
        },
        {
          property: 'gender',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Male', value: 'Male' },
            { text: 'Female', value: 'Female' },
            { text: 'Other', value: 'Other' },
          ],
          required: true,
          label: 'Gender',
        },
        {
          property: 'birthDate',
          type: 'date',
          required: true,
          label: 'BirthDate',
        },
        {
          property: 'maritalStatus',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Married', value: 'Married' },
            { text: 'Unmarried', value: 'Unmarried' },
          ],
          required: true,
          label: 'Martial Status',
        },
        {
          property: 'bloodGroup',
          type: 'select',
          label: 'Blood Group',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'A+', value: 'A+' },
            { text: 'O+', value: 'O+' },
            { text: 'B+', value: 'B+' },
            { text: 'AB+', value: 'AB+' },
            { text: 'A-', value: 'A-' },
            { text: 'O-', value: 'O-' },
            { text: 'B-', value: 'B-' },
            { text: 'AB-', value: 'AB-' },
          ],
        },
        { property: 'height', type: 'input', label: 'Height', required: true },
        { property: 'weight', type: 'input', label: 'Weight', required: true },
        {
          property: 'identificationMarks',
          type: 'input',
          label: 'Identification Marks',
        },
      ],
      initialFormState: data,
      onSubmit: onSave,
      mapTToU: (x) => {
        return x as any;
      },
      style: 'md:w-1/2',
      allowUnchanged: true,
    };

    const Form = renderFormV2<PersonalDetails>(formProps);

    return <Form />;
  };
}
