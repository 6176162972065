import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { UseVirtualStock } from '../../../../../hooks/inventory/item/stock/use-virtual-stock';

type Props = {
  id: string;
  uom: string;
  view: 'total' | 'combo';
  className?: string;
};
export function renderVirtualStock({
  useVirtualStock,
}: {
  useVirtualStock: UseVirtualStock;
}): // eslint-disable-next-line
({ id, uom, view }: Props) => JSX.Element {
  return function VirtualStock({
    id,
    uom,
    view,
    className,
  }: Props): JSX.Element {
    const { data, get } = useVirtualStock();
    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, []);

    return <div className={className || 'text-center'}>{renderStock()}</div>;
    function renderStock() {
      if (view === 'total') {
        if (data.hasOwnProperty('virtualQuantity')) {
          return (
            <span>
              {data.virtualQuantity} {uom}
            </span>
          );
        }
        return (
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' />
        );
      }

      if (
        data.hasOwnProperty('allocated') &&
        data.hasOwnProperty('unAllocated')
      ) {
        return (
          <span>
            {data.allocated} {uom} / {data.unAllocated} {uom}{' '}
          </span>
        );
      }

      return (
        <>
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' /> /{' '}
          <EllipsisHorizontalIcon className='w-6 h-6 inline animate-pulse' />
        </>
      );
    }
  };
}
