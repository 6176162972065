import { HazardCategory } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { HazardCategoryFilterType } from '../../../../../models/interfaces/admin/hazard-regulations/hazard-category';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpHazardCategoryRepo extends GenericHttpWithLogsRepo<
  HazardCategory,
  HazardCategoryFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/hazard-regulations/categories',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
