import { LoanType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  LoadingButton,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseCombinedLoan } from '../../../../hooks/loans/loan/use-loan';
import { UseDisbursementData } from '../../../../hooks/loans/loan/use-loan-disbursement';
import { UseDisbursementPostData } from '../../../../hooks/loans/loan/use-loan-post-disbursement';
import {
  GetDisbursementRequest,
  GetDisbursementResponse,
} from '../../../../models/interfaces/loans/loan';
import { LoanV2Type } from '../types/loan-v2-type';

type RenderDisbursementDetailsProps = {
  disbursementData: GetDisbursementResponse[];
  useDisbursementData: UseDisbursementData;
  useDisbursementPostData: UseDisbursementPostData;
  disburseValues: GetDisbursementRequest;
  useCombinedLoan: UseCombinedLoan;
  closeModal: any;
  action: string;
};
export function DisbursementDetails({
  disbursementData,
  useDisbursementData,
  useDisbursementPostData,
  disburseValues,
  useCombinedLoan,
  closeModal,
  action,
}: RenderDisbursementDetailsProps): JSX.Element {
  const {
    setDisbursementData: setPostDisbursement,
  } = useDisbursementPostData();

  const { syncSet: setLoan } = useCombinedLoan();

  useEffect(() => {}, [disbursementData]);

  const Table = renderTableComponent();

  const Card = renderCardComponent();
  const cardHeader: CardHeader = {
    title: 'Repayment Schedule',
    actions: [
      {
        type: 'jsx',
        jsx: (
          <>
            {disbursementData && disbursementData.length > 0 ? (
              action === 'Disbursement' ? (
                <LoadingButton
                  defaultStyle='p-2 shadow cursor-pointer bg-green-600 rounded-md'
                  behaviorFn={async () => saveDisbursement()}
                  text='Submit'
                />
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        ),
      },
    ],
  };

  const saveDisbursement = async () => {
    try {
      setPostDisbursement(disburseValues);
      saveLoan(disburseValues.loanId);
    } catch (error) {}
  };

  const saveLoan = async (loanId) => {
    try {
      const disbursedDate = [
        {
          date: disburseValues.month,
          amount: disburseValues.disbursementAmount,
        },
      ];
      const finalData = ({
        id: loanId,
        rateOfInterest: disburseValues.rateOfInterest,
        status: 'notify-to-disburse',
        details: {
          disbursedDate: disbursedDate,
        },
      } as unknown) as LoanType;

      await setLoan(finalData as LoanV2Type);
    } catch (error) {
      toast('Something went wrong');
    }
    closeModal();
  };

  const tableHeader: TableHeader = [
    [
      { name: 'Month' },
      { name: 'Disbursement' },
      { name: 'Opening Balance' },
      { name: 'Repayment' },
      {
        name: 'Repayment Contribution towards Interest',
      },
      {
        name: 'Repayment Contribution towards Principal',
      },
    ],
  ];

  let disbursementEntries: GetDisbursementResponse[] = [];
  if (disbursementData) {
    disbursementEntries.push({
      month: moment(disburseValues.month).format('YYYY-MM-DD'),
      noOfDays: 0, // i === 0 ? currentDate > firstDateOfRepayment ? getNoOfDays(monthsBetween[i]) : leftDays + getNoOfDays(monthsBetween[i]) : getNoOfDays(monthsBetween[i]),
      disbursementAmount: disburseValues.disbursementAmount,
      openingBalance: 0,
      interestCarriedOver: 0,
      repaymentAmount: 0,
      monthlyInterest: 0,
      interestContribution: 0,
      principalContribution: 0,
      closingBalance: 0,
    });

    for (let i = 0; i < disbursementData.length; i++) {
      disbursementEntries.push({
        month: disbursementData[i].month,
        noOfDays: 0, // i === 0 ? currentDate > firstDateOfRepayment ? getNoOfDays(monthsBetween[i]) : leftDays + getNoOfDays(monthsBetween[i]) : getNoOfDays(monthsBetween[i]),
        disbursementAmount: 0,
        openingBalance: disbursementData[i].openingBalance,
        interestCarriedOver: 0,
        repaymentAmount: disbursementData[i].repaymentAmount,
        monthlyInterest: disbursementData[i].interestContribution,
        interestContribution: disbursementData[i].interestContribution,
        principalContribution: disbursementData[i].principalContribution,
        closingBalance: disbursementData[i].closingBalance,
      });
    }
  }
  const tableData =
    disbursementEntries?.map((l, idx) => ({
      cells: [
        { value: l.month },
        { value: l.disbursementAmount },
        { value: l.openingBalance },
        { value: l.repaymentAmount },
        { value: l.interestContribution },
        { value: l.principalContribution },
      ],
    })) || [];

  const tableBody: TableBody = tableData;

  const cardBody: CardBody = {
    type: 'jsx-component',
    body: (
      <div className='w-full'>
        {<Table header={tableHeader} body={tableBody} />}
      </div>
    ),
  };

  return (
    <>
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    </>
  );
}
