/* eslint-disable no-unused-vars */
import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import {
  DaySchedule,
  MachineSchedule,
} from '@erp_core/erp-types/dist/modules/planning';
import _ from 'lodash';
import moment from 'moment';

const weekDaysMapper = {
  0: 'sunday',
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
};

export type ProposedSchedule = {
  id: string;
  calendarId: string;
  title: string;
  category: string;
  color: string;
  backgroundColor: string;
  dragBackgroundColor: string;
  borderColor: string;
  item: UserIdName;
  start: string;
  end: string;
};

export const getProposed = (
  ms?: MachineSchedule,
  colorScheme?: {
    bgLight: string;
    bgDark: string;
    textLight: string;
    textDark: string;
  }
) => {
  if (!ms) return [];
  const sched: ProposedSchedule[][] = [];
  for (let i = 0; i < 15; i++) {
    const requiredDate = moment().add(i, 'days');
    const dayOfWeek = requiredDate.day();
    const reqSch: DaySchedule[] =
      ms.details.schedule[weekDaysMapper[dayOfWeek]];
    // console.log('reqSch', reqSch);
    const sc: ProposedSchedule[] = reqSch.map((i) => ({
      id: String(Math.random()),
      calendarId: 'cal1',
      title: i.item.name,
      item: i.item,
      category: 'time',
      color: colorScheme?.textDark || '',
      backgroundColor: colorScheme?.bgLight || '',
      dragBackgroundColor: '#000',
      borderColor: colorScheme?.bgLight || '',
      start: `${requiredDate.format('YYYY-MM-DD')}T${i.from}`,
      end: `${requiredDate.format('YYYY-MM-DD')}T${i.to}`,
    }));
    sched.push(sc);
  }

  return _.flatten(sched);

  // const pp = ms.details.schedule()
};
