import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { Godown } from '@erp_core/erp-types/dist/modules/inventory';
import { RotationalShiftDayType } from '@erp_core/erp-types/dist/modules/planning';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { GodownDetails } from '@erp_core/erp-types/dist/types/modules/inventory/godown';
import {
  LoadingButton,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { SimpleShift } from '../../../schedule-allocator/utils/compute-schedule';
import { ExtEmpProfile, WASch } from './shift-generator-v2';

type ExtSimpleShift = SimpleShift & {
  createStatus: 'skip' | 'incomplete' | 'complete';
};

export function SaveWorkPlaceSchedule({
  data,
  onClose,
}: {
  onClose: () => void;
  data: {
    godown: Godown;
    sch: WASch;
    nextMonthSch: WASch;
    nextMonthDate: string;
    dates: Array<string>;
    shifts: Array<SimpleShift>;
    existingShifts: RotationalShiftDayType[];
    supervisorShifts: RotationalShiftDayType[];
    employeeProfiles: ExtEmpProfile[];
    currentLocation: LocationType;
    setGodown: (s: Godown, o?: any) => Promise<Godown>;
    setRotationalShiftDay: (
      s: RotationalShiftDayType,
      o?: any
    ) => Promise<RotationalShiftDayType>;
  };
}): JSX.Element {
  const Table = renderTableComponent();
  console.log(data);

  const finalShiftsToCreate: Array<RotationalShiftDayType> = [];
  const finalShiftsWithErrors: Array<ExtSimpleShift> = [];

  const proposedShifts: Array<ExtSimpleShift> = data.shifts.map((x) => {
    return {
      createStatus: 'complete',
      ...x,
    };
  });

  async function persistSchedule() {
    // TODO: Save Schedule id.

    // For Each shift create rotational shift day
    for (const shft of finalShiftsToCreate) {
      console.log(
        `${shft.date} ${shft.employee.name} will work in ${shft.details.shiftId?.name} shift under ${shft.supervisor.name} supervision`
      );
      const sc = await data.setRotationalShiftDay(shft, {
        enableLoading: false,
        enableResource: false,
      });
      console.log(sc.employee.name, sc.date, 'saved!');
    }

    const schConfig = data.godown.details.scheduleConfig || {
      type: 'system',
    };
    (schConfig as any).schedule = data.nextMonthSch;

    const finalData: Partial<Godown> = {
      id: data.godown.id,
      details: {
        nextSchDate: data.nextMonthDate,
        scheduleConfig: schConfig,
      } as GodownDetails,
    };
    await data.setGodown((finalData as unknown) as Godown, {
      enableLoading: false,
      enableResource: false,
    });
    onClose();
  }

  return (
    <div>
      <Table
        header={[
          [
            { name: 'Date' },
            { name: 'Proposed Schedule' },
            { name: 'Existing Schedule' },
            { name: 'Remarks' },
            { name: 'Action' },
          ],
        ]}
        body={data.dates.map((d) => {
          const shifts = proposedShifts.filter((x) => x.date === d);
          const existingShifts = data.existingShifts.filter(
            (x) => x.date === d
          );
          const supervisorShifts = data.supervisorShifts.filter(
            (x) => x.date === d
          );
          const remarks: Array<string> = [];
          const alreadyShifts: Array<string> = [];
          const noShifts: Array<string> = [];
          const noProfileSupervisors: Array<string> = [];
          shifts.forEach((s) => {
            if (existingShifts.find((e) => e.employee.id === s.employee.id)) {
              alreadyShifts.push(s.employee.name);
              s.createStatus = 'skip';
            } else {
              const emp = data.employeeProfiles.find(
                (x) => x.id === s.employee.id
              );
              const finalSupervisor: IdName = { id: '', name: '' };
              if (emp?.supervisorType === 'rotational') {
                if (s.status === 'weekly-off') {
                  // We pick first shift supervisor. In case not found then add to errors.
                  const rotSupShift = _.first(supervisorShifts);
                  finalSupervisor.id = rotSupShift?.employee.id || '';
                  finalSupervisor.name = rotSupShift?.employee.name || '';
                  if (!rotSupShift) {
                    s.createStatus = 'incomplete';
                    finalShiftsWithErrors.push(s);
                  }
                } else {
                  let rotSupShift = supervisorShifts.find(
                    (x) =>
                      x.details.shiftId && x.details.shiftId?.id === s.shift?.id
                  );
                  if (!rotSupShift) {
                    // In case of general SHift we come here. and select supervisor from profile
                    if (
                      s.shift?.name === 'General' &&
                      emp?.details.authorizations?.attendanceSupervisor
                    ) {
                      const profileSupervisor =
                        emp?.details.authorizations?.attendanceSupervisor;
                      finalSupervisor.id = profileSupervisor.id;
                      finalSupervisor.name = profileSupervisor.name;
                    }

                    // In case there are only 2  rotational supervisors then we allocate first
                    if (
                      !finalSupervisor.id &&
                      supervisorShifts.filter((x) => x.attendance === 'working')
                        .length
                    ) {
                      const firstOrSecond = supervisorShifts.find((x) =>
                        ['First', 'Second'].includes(
                          x.details.shiftId?.name || ''
                        )
                      );
                      if (firstOrSecond) {
                        finalSupervisor.id = firstOrSecond.employee.id;
                        finalSupervisor.name = firstOrSecond.employee.name;
                      }
                    }

                    if (s.shift?.name && !finalSupervisor.id) {
                      noShifts.push(s.shift?.name || '');
                      s.createStatus = 'incomplete';
                      finalShiftsWithErrors.push(s);
                    }
                  } else {
                    finalSupervisor.id = rotSupShift.employee.id;
                    finalSupervisor.name = rotSupShift.employee.name;
                  }
                }
              } else {
                const profileSupervisor =
                  emp?.details.authorizations?.attendanceSupervisor;
                if (!profileSupervisor?.id) {
                  noProfileSupervisors.push(s.employee.name);
                  s.createStatus = 'incomplete';
                  finalShiftsWithErrors.push(s);
                } else {
                  finalSupervisor.id = profileSupervisor.id;
                  finalSupervisor.name = profileSupervisor.name;
                  console.log('yayaha aya');
                }
              }

              if (finalSupervisor.id) {
                // This means the shift is ready to be created
                finalShiftsToCreate.push(({
                  date: s.date,
                  employee: { id: s.employee.id, name: s.employee.name },
                  name: `${s.date}-${s.employee.name}`,
                  supervisor: {
                    id: finalSupervisor.id,
                    name: finalSupervisor.name,
                  },
                  attendance: s.status,
                  details: {
                    scheduleId: '', // TODO
                    shiftId: s.shift,
                    location: data.currentLocation.name.toLowerCase(),
                    workArea: { id: s.location?.id, name: s.location?.name },
                    role: s.role,
                    roleName: s.roleName,
                    overtime: s.overtime,
                    expectedOvertimeHours: s.overtime ? 4 : 0,
                  },
                } as unknown) as RotationalShiftDayType);
              }
            }
          });
          if (alreadyShifts.length) {
            remarks.push(`${alreadyShifts.join(', ')} already has a shift.`);
          }

          if (noShifts.length) {
            remarks.push(
              `No Supervisor schedule found for ${noShifts.join(', ')}.`
            );
          }

          if (noProfileSupervisors.length) {
            remarks.push(
              `No supervisor set in profile for ${noProfileSupervisors.join(
                ', '
              )}.`
            );
          }

          return {
            cells: [
              { value: d },
              {
                value: (
                  <div className='flex flex-wrap'>
                    {shifts.map((x, idx) => {
                      const border =
                        x.createStatus === 'complete'
                          ? 'border-green-500'
                          : x.createStatus === 'skip'
                          ? 'border-red-500'
                          : 'border-orange-500';
                      return (
                        <div
                          key={idx}
                          className={`mx-1 border ${border} rounded-md p-0.5`}
                        >
                          <div>{x.employee.name}</div>
                          <div className='font-thin text-gray-800'>
                            {x.status === 'weekly-off'
                              ? 'Week-off'
                              : x.shift?.name}
                          </div>
                          <div className='font-thin italic text-gray-800'>
                            {x.status === 'weekly-off'
                              ? ''
                              : finalShiftsToCreate.find(
                                  (y) =>
                                    y.date === x.date &&
                                    y.employee.id === x.employee.id
                                )?.supervisor?.name || 'not-found'}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ),
              },
              {
                value: (
                  <div className='flex flex-wrap'>
                    {existingShifts.map((x) => (
                      <div
                        key={x.id}
                        className='mx-1 border border-gray-200 rounded-md p-0.5'
                      >
                        <div>{x.employee.name}</div>
                        <div className='font-thin text-gray-800'>
                          {x.attendance === 'weekly-off'
                            ? 'Week-off'
                            : x.details.shiftId?.name}
                        </div>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                value: (
                  <div>
                    {remarks.map((x, idx) => (
                      <div key={idx}>{x}</div>
                    ))}
                  </div>
                ),
              },
              { value: '' },
            ],
          };
        })}
      />

      {!finalShiftsWithErrors.length ? (
        <div className='flex py-2'>
          <div className='mx-auto'>
            <LoadingButton
              defaultStyle='bg-green-700 text-white p-2 border border-gray-200 rounded-lg'
              loadingStyle='bg-gray-700 text-white p-2 border border-gray-200 rounded-lg'
              text='Persist Schedule'
              behaviorFn={persistSchedule}
              behaviourParams={{}}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
