import {
  ItemRes,
  Transaction,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item/index';
import { ItemFilter } from '../../../../../models/types/inventory/item';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpItemV2Repo extends GenericHttpWithLogsRepo<ItemRes, ItemFilter>
  implements ItemInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/items',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getTransactions(id: string): Promise<Transaction[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/transactions`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c as Transaction);
    }

    return [];
  }

  async getProperties(id: string): Promise<ItemPropertyValue[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/properties`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c as ItemPropertyValue);
    }

    return [];
  }

  async getSpecificationsTemplate(id: string): Promise<{ template: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/specifications-template`
    );
    if (res.status === 200) {
      const { data } = res;

      return data as Promise<{ template: string }>;
    }

    return { template: '' };
  }

  async setPropertyValues(id: string, data: any): Promise<ItemRes> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${id}/property-values`,
      data
    );
    if (res.status === 200) {
      const { data } = res;

      return data as ItemRes;
    }

    return {} as ItemRes;
  }

  async getBomChart(id: string): Promise<any> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/bom-chart`
    );
    if (res.status === 200) {
      const { data } = res;

      return data;
    }

    return [];
  }

  async changeItemCategories({
    itemId,
    categories,
  }: {
    itemId: string;
    categories: { id: string; name: string }[];
  }): Promise<{
    success: boolean;
    message?: string;
  }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${itemId}/item-categories`,
      {
        categories,
      }
    );

    const { data } = res;

    return data;
  }
}
