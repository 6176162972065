//InsurancePayment
/* eslint-disable no-unused-vars */

import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { InsurancePayment } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  CardBody,
  CardHeader,
  // Modal,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment, { Moment } from 'moment';
import { renderAddBulkInsuranceForm } from '../insurance-form/insurance-add-bulk-form';
import { renderAddInsuranceForm } from '../insurance-form/insurance-add-form';
import { renderEditInsuranceForm } from '../insurance-form/insurance-edit-form';

export function renderInsurance({
  employee,
  setEmployee,
}: {
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => void;
}): // eslint-disable-next-line
({
  id,
}: {
  id: string;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  isHr: boolean;
}) => JSX.Element {
  return function Insurance({
    id,
    actions,
    isHr,
  }: {
    id: string;
    isHr: boolean;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    const handleInsuranceAdd = async ({
      data,
      onClose,
    }: {
      data: InsurancePayment;
      onClose: () => void;
    }) => {
      const finalData = {
        id: employee.id,
        details: {
          employeeInsurance: [
            ...(employee.details.employeeInsurance || []),
            data,
          ],
        },
      };

      setEmployee(finalData as EmployeeProfileType);
      onClose();
    };

    function differenceInMonths(startDate: Moment, endDate: Moment) {
      const startDt = moment(startDate, 'DD.MM.YYYY');
      const endDt = moment(endDate, 'DD.MM.YYYY');
      const calMonths = endDt.diff(startDt, 'months');
      return calMonths;
    }

    const handleBulkInsuranceAdd = async (form: any) => {
      const from = moment(form.fromMonthYear).format('YYYY-MM') + '-01'; // moment(props.queryReq?.month);
      const to = moment(form.toMonthYear).format('YYYY-MM') + '-01';

      const data: InsurancePayment[] = [];
      const difference = differenceInMonths(moment(from), moment(to));
      for (let i = 0; i <= difference; i++) {
        data.push({
          monthYear: moment(form.fromMonthYear)
            .add(i, 'month')
            .format('YYYY-MM'),
          insuranceCompanyName: form.insuranceCompanyName,
          policyNo: form.policyNo,
          amount: form.amount,
          status: 'pending',
          paidBy: {} as any,
        });
      }
      const finalData = {
        id: employee.id,
        details: {
          employeeInsurance: [
            ...(employee.details.employeeInsurance || []),
            ...data,
          ],
        },
      };
      setEmployee(finalData as EmployeeProfileType);
    };

    const InsuranceCard = renderCardComponent();
    const InsuranceCardHeader: CardHeader = {
      title: 'Insurance (Legacy)',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add Bulk',
            behaviour: 'modal',
            modal: {
              title: 'Add Bulk Insurance',
              content: ({ onClose }) => {
                const InsuranceForm = renderAddBulkInsuranceForm();
                return (
                  <InsuranceForm
                    onSave={(form) => {
                      handleBulkInsuranceAdd(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Add',
            style: 'float-right',
            behaviour: 'modal',
            modal: {
              title: 'Add Insurance',
              content: ({ onClose }) => {
                const Form = renderAddInsuranceForm();
                return (
                  <Form
                    onSave={async (form) => {
                      await handleInsuranceAdd({ data: form, onClose });
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const InsuranceTable = renderTableComponent();
    const insuranceTableHeader: TableHeader = [
      [
        { name: 'Month (YYYY-MM)' },
        { name: 'Ins Company Name' },
        { name: 'Policy #' },
        { name: 'Amount' },
        { name: 'Status' },
        { name: 'Paid By' },
        { name: 'Action' },
      ],
    ];

    function OpenEditInsurance({
      idx,
      insurance,
      onClose,
    }: {
      idx: number;
      insurance: InsurancePayment;
      onClose: () => void;
    }) {
      const EditInsurance = renderEditInsuranceForm();
      return (
        <EditInsurance
          ins={insurance}
          onSave={(c) => {
            const insurances = employee.details.employeeInsurance;
            insurances?.splice(idx, 1, c);
            const finalData = {
              id: employee.id,
              details: {
                employeeInsurance: [...(insurances || [])],
              },
            };

            setEmployee(finalData as EmployeeProfileType);
            onClose();
          }}
        />
      );
    }

    const InsuranceTableBody: TableBody = employee.details?.employeeInsurance
      ? employee.details.employeeInsurance.map((f, idx) => {
          const cells: Array<TableCell> = [];
          cells.push(
            // { value: f.fields?.id},
            { value: f.monthYear },
            { value: f.insuranceCompanyName },
            { value: f.policyNo },
            { value: f.amount },
            { value: f.status },
            { value: f.paidBy?.name || '-' }
          );

          return { cells, rowData: { f, idx } };
        })
      : [];

    const InsuranceCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <InsuranceTable
              header={insuranceTableHeader}
              body={InsuranceTableBody}
              actions={[
                {
                  name: 'Edit',
                  show: () => isHr,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data: { f, idx }, onClose }) => {
                      return (
                        <OpenEditInsurance
                          insurance={f}
                          idx={idx}
                          onClose={onClose}
                        />
                      );
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <InsuranceCard
          header={InsuranceCardHeader}
          body={InsuranceCardBody}
          auth={{ actions }}
        />
      </>
    );
  };
}
