/* eslint-disable react-hooks/exhaustive-deps */
import { Bank } from '@erp_core/erp-icons/icons/web/bank';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { CardHeader, renderSearchBox } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedLeave } from '../../../../hooks/hrd/leave/use-leave';
import { LeaveInterface } from '../../../../models/interfaces/hrd/leave';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddEmployeesLeaveForm } from '../forms/add-leave-roster-for-employee';
import { renderGenericLeaveCard } from '../util/generic-leave-card';

type RenderEmployeesLeaveForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedLeave: UseCombinedLeave;
  useFileTransfer: UseFileTransfer;
  useCurrentUserRoles: UseCurrentUserRoles;
  leaveService: LeaveInterface;
  userRendererService: UserRendererInterface;
};

export const renderEmployeeLeaves = ({
  useCurrentUserRoles,
  useCombinedEmployeeProfile,
  useCombinedLeave,
  useFileTransfer,
  leaveService,
  userRendererService,
}: RenderEmployeesLeaveForm) => {
  return function EmployeeLeaves() {
    const [selectedEmployee, setSelectedEmployee] = useState<
      EmployeeProfileType
    >();
    const { data: currentUserRoles } = useCurrentUserRoles();
    const SearchBox = renderSearchBox<EmployeeProfileType>({
      useSearchValues: useCombinedEmployeeProfile,
      extraFilter: {
        crossGroup: 'true',
      },
      onSearchValueSelect: (u) => {
        setSelectedEmployee(u);
      },
    });

    const isSystemAdmin = currentUserRoles?.find(
      (x) => x.name === 'System-Admin'
    )
      ? true
      : false;

    const EmployeesLeaveForm = renderAddEmployeesLeaveForm({
      useCombinedEmployeeProfile,
    });

    const CardHeader: CardHeader = {
      icon: (
        <Bank className='w-8 h-8 fill-indigo-900 stroke-indigo-900 inline self-center' />
      ),
      title: `Employee Leaves: ${selectedEmployee?.name || 'None Selected'}`,
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='w-96'>
              <SearchBox />
            </div>
          ),
        },
      ],
    };

    if (isSystemAdmin) {
      CardHeader.actions?.push({
        type: 'button',
        button: {
          name: 'Add Leave',
          behaviour: 'modal',
          modal: {
            title: 'Add Employee Leave',
            content: ({ onClose }) => {
              return (
                <EmployeesLeaveForm
                  onSave={async (form) => {
                    const count =
                      parseInt(`${form.quantity}`) *
                      (form.duration === 'full-day' ? 1 : 0.5);
                    const req = {
                      leaves: [
                        {
                          employeeId: form.employee?.id,
                          employeeName: form.employee?.name,
                          privilege:
                            form.type === 'privilege-leave' ? count : 0,
                          sick: form.type === 'sick-leave' ? count : 0,
                          compOff: form.type === 'compensatory-off' ? count : 0,
                          casual: form.type === 'casual-leave' ? count : 0,
                        },
                      ],
                      justification: form.justification,
                    };
                    if (count) {
                      const resp = await leaveService.refillLeaveBank(req);
                      if (resp.success === true) {
                        toast.success('leave added successfully!');
                      } else {
                        toast.error('Something went wrong!');
                      }
                    }

                    onClose();
                  }}
                />
              );
            },
          },
        },
      });
    }

    const GenericLeaveCard = renderGenericLeaveCard({
      userRendererService,
      id: selectedEmployee?.id,
      cardHeader: CardHeader,
      useCombinedLeave,
      useFileTransfer,
    });

    return (
      <>
        <GenericLeaveCard />
      </>
    );
  };
};
