import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { OvertimeType } from '@erp_core/erp-types/dist/types/modules/hrd/overtime';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { OvertimeFilterType } from '../../../../../models/interfaces/hrd/employee/overtime';

type EmployeeOTRedeemProps = {
  companyGroupSetting: CompanyGroupSetting;
  employees: EmployeeProfileType[];
  overtime: OvertimeType;
  setOvertime: (s: OvertimeType) => Promise<OvertimeType>;
  getOvertimes: (filter?: OvertimeFilterType) => void;
  date: string;
  onClose: () => void;
  isLocationOff: boolean;
};

export function EmployeeOTRedeemSelector({
  companyGroupSetting,
  employees,
  overtime,
  setOvertime,
  getOvertimes,
  date,
  onClose,
  isLocationOff,
}: EmployeeOTRedeemProps): JSX.Element {
  const [optFor, setOptFor] = useState<'salary' | 'comp-off'>('' as any);

  const overtimeCompoffCategory =
    companyGroupSetting?.details?.hrd?.attendanceRules.overTimeToCompOff?.categories?.map(
      (c) => {
        return c.id;
      }
    ) || [];
  const overtimeSalaryCategory =
    companyGroupSetting?.details?.hrd?.attendanceRules.overTimeToSalary?.categories?.map(
      (c) => {
        return c.id;
      }
    ) || [];

  const emp = employees?.find((e) => e.id === overtime.employee.id);
  if (!emp) return <></>;

  const hasCompoff = overtimeCompoffCategory.includes(emp?.details?.grade?.id);
  const hasSalary = overtimeSalaryCategory.includes(emp?.details?.grade?.id);

  if (!hasCompoff && !hasSalary && !isLocationOff) {
    return (
      <div>
        Seems like the {overtime.employee.name} is configured to neither
        Overtime-to-Comp-off or Overtime-to-Salary Category
      </div>
    );
  }

  const handleOTRedeemOption = async ({
    id,
    optedFor,
  }: {
    id: string;
    optedFor: string;
  }) => {
    const finalData = ({
      id: id,
      details: {
        redemptionFlow: optedFor,
      },
    } as unknown) as OvertimeType;
    await setOvertime(finalData);
    onClose();
    await getOvertimes({
      date,
      crossGroup: 'true',
      sortBy: 'date',
      order: 'desc',
    });
  };

  return (
    <div>
      <div className='flex'>
        {hasSalary || isLocationOff ? (
          <div className='basis-1/2'>
            <LoadingButton
              defaultStyle={`float-right p-2 border rounded-lg w-48 text-white mx-2 ${
                (optFor || overtime?.details?.redemptionFlow) === 'salary'
                  ? 'bg-green-800'
                  : 'bg-gray-700'
              }`}
              behaviorFn={async () => {
                setOptFor('salary');
              }}
              text='OPT FOR SALARY'
            />
          </div>
        ) : null}
        {hasCompoff || isLocationOff ? (
          <div className='basis-1/2'>
            <LoadingButton
              defaultStyle={`p-2 border rounded-lg w-48 text-white mx-2 ${
                (optFor || overtime?.details?.redemptionFlow) === 'comp-off'
                  ? 'bg-green-800'
                  : 'bg-gray-700'
              }`}
              behaviorFn={async () => {
                setOptFor('comp-off');
              }}
              text='OPT FOR COMP-OFF'
            />
          </div>
        ) : null}
      </div>
      <div className='w-full flex justify-center  items-center space-x-2 my-2'>
        <LoadingButton
          defaultStyle='bg-blue-500 p-2 border rounded-lg shadow text-white font-semibold hover:bg-blue-600 flex items-center justify-center'
          behaviorFn={async () =>
            handleOTRedeemOption({ id: overtime?.id, optedFor: optFor })
          }
          text='Submit'
        />
      </div>
    </div>
  );
}
