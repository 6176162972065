import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  propType?: string;
  path: string;
  currentValue: string;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
};

export type EditChangeReqFormType = {
  // currentValue: string;
  newValue: string;
  reason: string;
};

export const renderEditImageRequest = ({
  useFileTransfer,
}: {
  useFileTransfer: UseFileTransfer;
}) => {
  return function EditImageRequest({
    path,
    currentValue,
    onSave,
  }: EditChangeReqProps) {
    const formProps: FormProps<EditChangeReqFormType> = {
      fieldsData: [
        {
          property: 'newValue',
          type: 'file',
          required: true,
          label: 'New Value',
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        newValue: '',
        reason: '',
      },
      upload: {
        useFileTransfer: useFileTransfer,
        config: [
          {
            name: 'newValue',
            path: path,
            type: 'private',
          },
        ],
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason,
          changeRequest: {
            currentValue: {
              value: {
                data: currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditChangeReqFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EditChangeReqFormType>
    ): EditChangeReqFormType {
      return {
        currentValue: currentValue,
        newValue: b.newValue,
        reason: b.reason,
      } as EditChangeReqFormType;
    }
  };
};
