import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCombinedLoan } from '../../../../hooks/loans/loan/use-loan';
import { UseDisbursementData } from '../../../../hooks/loans/loan/use-loan-disbursement';
import { UseDisbursementPostData } from '../../../../hooks/loans/loan/use-loan-post-disbursement';
import { GetDisbursementRequest } from '../../../../models/interfaces/loans/loan';
import { DisbursementDetails } from '../components/repayment-schedule-info';

export type DisbursementFormType = {
  // disbursementDate: string;
  disbursementAmt: number;
  monthlyRepaymentAmount: number;
  month: string;
  rateOfInterest: string;
};

type DisbursementFormProps = {
  useDisbursementData: UseDisbursementData;
  useDisbursementPostData: UseDisbursementPostData;
  loanId: string;
  closeModal: any;
  action: string;
  isEdit?: boolean;
  useCombinedLoan: UseCombinedLoan;
};

export function DisbursementForm({
  useDisbursementData,
  useDisbursementPostData,
  loanId,
  useCombinedLoan,
  closeModal,
  action,
  isEdit,
}: DisbursementFormProps): JSX.Element {
  const {
    data: disbursementData,
    getDisbursementValue: getDisbursement,
  } = useDisbursementData();

  const { list: loans, getAll: getLoans } = useCombinedLoan();

  let disburseValues = {
    // disbursementDate: '',
    disbursementAmount: 0,
    monthlyRepaymentAmount: 0,
    month: '',
    loanId: '',
    rateOfInterest: '',
  } as GetDisbursementRequest;

  const [formInputData, setformInputData] = useState({
    disbursementAmount: 0,
    monthlyRepaymentAmount: 0,
    rateOfInterest: '',
    month: '',
    loanId: '',
  });

  useEffect(() => {}, [disbursementData]);

  useEffect(() => {}, [loans]);

  //useEffect(() => { }, [loans]);
  useEffect(() => {
    getLoans();
    // eslint-disable-next-line
  }, []);

  const rateOfInterestData = loans?.find((x) => x.id === loanId)
    ?.rateOfInterest;

  let rateOfInterest;
  if (rateOfInterestData) {
    rateOfInterest = rateOfInterestData;
  }

  // const validateForm = async (form) => {
  //   if (isEdit) return '';
  //   if (!form.month) return '';

  //   if (moment(form.month).format('YYYY-MM') < moment().format('YYYY-MM')) {
  //     return { month: 'month of disburse has passed' } as any;
  //   }
  //   return;
  // };

  const formProps: FormProps<DisbursementFormType> = {
    fieldsData: [
      // {
      //   property: 'disbursementDate',
      //   type: 'date',
      //   label: 'Disbursement Date',
      //   required: true,
      // },
      {
        property: 'disbursementAmt',
        type: 'input',
        label: 'Disbursement Amount',
        required: true,
      },
      {
        property: 'monthlyRepaymentAmount',
        type: 'input',
        label: 'Repayment Amount',
        required: true,
      },
      {
        property: 'rateOfInterest',
        type: 'input',
        label: 'Rate Of Interest',
        required: true,
        readonly: action === 'Disbursement' ? true : false,
      },
      {
        property: 'month',
        type: 'date',
        label: 'Disbursement Date (YYYY-MM-DD)',
        required: true,
        readonly: isEdit,
        // validate: validateForm,
      },
    ],
    initialFormState: {
      // disbursementDate: '',
      disbursementAmt: '',
      monthlyRepaymentAmount: '',
      month: '',
      rateOfInterest: rateOfInterest || '',
    },
    mapTToU: mapPFDToP,
    style: 'md:w-1/2',
    onSubmit: async (l) => {
      getDisbursement({
        // disbursementDate: l.disbursementDate,
        disbursementAmount: l.disbursementAmt,
        monthlyRepaymentAmount: l.monthlyRepaymentAmount,
        month: l.month,
        loanId: loanId,
        rateOfInterest: l.rateOfInterest || rateOfInterest,
      } as GetDisbursementRequest);
      disburseValues = {
        // disbursementDate: l.disbursementDate,
        disbursementAmount: l.disbursementAmt,
        monthlyRepaymentAmount: l.monthlyRepaymentAmount,
        month: l.month,
        loanId: loanId,
        rateOfInterest: l.rateOfInterest,
      } as GetDisbursementRequest;
      setformInputData(disburseValues);
    },
    button: {
      text: 'Next',
    },
  };

  const Form = renderFormV2<DisbursementFormType>(formProps);

  return (
    <div className='grid grid-cols-2 divide-y'>
      <div>
        <Form />
      </div>
      <div className='h-96  overflow-y-scroll'>
        <DisbursementDetails
          disbursementData={disbursementData}
          useDisbursementData={useDisbursementData}
          useDisbursementPostData={useDisbursementPostData}
          disburseValues={formInputData}
          useCombinedLoan={useCombinedLoan}
          closeModal={closeModal}
          action={action}
        />
      </div>
    </div>
  );

  function mapPFDToP(
    b: FormDataType<DisbursementFormType>
  ): DisbursementFormType {
    return (b as unknown) as DisbursementFormType;
  }
}
