/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */

import {
  ManufacturingVoucher,
  ManufacturingVoucherFilter,
} from '@erp_core/erp-types/dist/modules/order';
import { ReserveData } from '../../../../../hooks/order/work-order/mf-voucher/use-virtually-reserve-manufacturing-voucher';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpManufacturingVoucherRepo extends GenericHttpWithLogsRepo<
  ManufacturingVoucher,
  ManufacturingVoucherFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/work-order/mv',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async allocateItem(data: ReserveData) {
    const http = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.apiUrl,
    });
    const result = await http.post('/api/v1/work-order/mv/reserve', data);
    return result.data as boolean;
  }
}
