import { MisconductType } from '@erp_core/erp-types/dist/types/modules/disciplinary/mis-conduct';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type MisconductIssueMemoFormType = {
  date: string;
  details: string;
};

export const issueMemoFormComponent = () => {
  return function issueMemoForm({
    data,
    onSave,
  }: {
    data?: MisconductType;
    onSave: (data: MisconductType) => Promise<void>;
  }) {
    const formProps: FormProps<MisconductIssueMemoFormType> = {
      fieldsData: [
        { property: 'date', type: 'date', label: 'Date', required: true },
        {
          property: 'details',
          type: 'input',
          label: 'Details',
          required: true,
        },
      ],
      initialFormState: {
        date: data?.details?.issueMemo?.date || '',
        details: data?.details?.issueMemo?.details || '',
      },
      onSubmit: async (f) => {
        await onSave(({
          id: data?.id,
          details: {
            issueMemo: {
              date: f.date || data?.details?.issueMemo?.date,
              details: f.details || data?.details?.issueMemo?.details,
            },
          },
        } as unknown) as MisconductType);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<MisconductIssueMemoFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<MisconductIssueMemoFormType>
    ): MisconductIssueMemoFormType {
      return (b as unknown) as MisconductIssueMemoFormType;
    }
  };
};
