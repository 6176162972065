import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import { LoadingButton } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedSalaryRevision } from '../../../../hooks/hrd/salary-revision/use-salary-revision';
import { UserRendererInterface } from '../../../common/fragments/user';
// import { calculate } from '../util/esic-calculator';
import { UserContext } from '../../../../contexts/user';
import { SalaryRevisionInterface } from '../../../../models/interfaces/hrd/salary-revision';

export function createBulkRevisions({
  companyGroupConfig,
  useCombinedEmployeeProfile,
  useCombinedSalaryRevision,
  salaryParams,
  onClose,
  userRendererService,
  salaryRevisionService,
}: {
  companyGroupConfig: CompanyGroupSetting;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedSalaryRevision: UseCombinedSalaryRevision;
  salaryParams: SalaryParamType[];
  onClose: () => void;
  userRendererService: UserRendererInterface;
  salaryRevisionService: SalaryRevisionInterface;
}) {
  return function BulkRevisions(): JSX.Element {
    const { getListSync: getEmployees } = useCombinedEmployeeProfile();
    const { getListSync: getSalaryRevisions } = useCombinedSalaryRevision();
    const { syncSet: setSalaryRevision } = useCombinedSalaryRevision();
    const { user: currentUser } = useContext(UserContext);
    const [employeeList, setEmployeeList] = useState<Array<any>>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<{
      [key: string]: boolean;
    }>({});
    const [skippedList, setSkippedList] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
      setLoading(true);
      Promise.all([getSalaryRevisions(), getEmployees()]).then(
        async ([salaryRevisions, employees]) => {
          const empList: any = [];
          const skList: any = [];
          const selEmps: { [key: string]: boolean } = {};

          for (const x of employees) {
            // employees.forEach(x => {
            const salRevs = salaryRevisions.filter(
              (sal) =>
                sal.employee.id === x.id.toLowerCase() &&
                sal.status === 'published'
            );
            if (x.details.resigned !== 'yes' && salRevs.length) {
              const latestSalRev = _.chain(salRevs)
                .sortBy('revisionNum')
                .reverse()
                .first()
                .value();

              // Take current Sal revision.
              // Read the basicDA that is saved in the details blob
              // Compute BasicDA for min-wages:
              //    - Skill-Level (available in sal-rev)
              //    - skilled => (basicSkilled + daSkilled) (same for others)
              // Compare basic-da for min-wages with the basicDA value
              // if (basicDaFromSalRevision < salParams.basicDaMw) ? true : false;
              // if true sal-rev required
              // else not
              const salaryRules = companyGroupConfig.details.hrd?.salaryRules;
              const basicDaForSalRev =
                latestSalRev?.details?.salParams?.other?.basicDaForSalRev;
              const skillLevel = latestSalRev?.details?.skillLevel;
              let computedMinBasicDA = 0;

              if (skillLevel === 'Semi-Skilled') {
                computedMinBasicDA =
                  (salaryRules?.basicSemiSkilled || 0) +
                  (salaryRules?.daSemiSkilled || 0);
              }
              if (skillLevel === 'Skilled') {
                computedMinBasicDA =
                  (salaryRules?.basicSkilled || 0) +
                  (salaryRules?.daSkilled || 0);
              }
              if (skillLevel === 'Unskilled') {
                computedMinBasicDA =
                  (salaryRules?.basicUnSkilled || 0) +
                  (salaryRules?.daUnSkilled || 0);
              }

              if (
                computedMinBasicDA &&
                basicDaForSalRev &&
                basicDaForSalRev < computedMinBasicDA
              ) {
                const parentSalaryRevision = latestSalRev;
                const duplicateFormData: SalaryRevisionType = JSON.parse(
                  JSON.stringify(latestSalRev)
                );
                delete duplicateFormData.details.salParams;
                delete duplicateFormData.details.esicApplicability;

                duplicateFormData.revisionNum = `${
                  parseInt(`${duplicateFormData.revisionNum}`) + 1
                }`;
                duplicateFormData.prevRevisionId = duplicateFormData.id;
                duplicateFormData.id = '';
                const date =
                  companyGroupConfig.details.hrd?.salaryRules
                    .basicDaEffectiveDate || `${moment().format('YYYY')}-01-01`;
                duplicateFormData.date = date;
                duplicateFormData.name = `${duplicateFormData.employee.name}-${duplicateFormData.date}-v${duplicateFormData.revisionNum}`;
                duplicateFormData.details.notes =
                  'Salary Revision updated due to change in Basic DA';

                // const monthDetails = {
                //   month: date
                //     ? parseInt(moment(date, 'YYYY-MM-DD').format('MM'))
                //     : moment().subtract(1, 'M').month(),
                //   year: date
                //     ? moment(date, 'YYYY-MM-DD').year()
                //     : moment().subtract(1, 'M').year(),
                //   monthName: date
                //     ? moment(date, 'YYYY-MM-DD').format('MMM')
                //     : moment().subtract(1, 'M').format('MMM'),
                //   totalDays: date
                //     ? moment(date, 'YYYY-MM-DD').daysInMonth()
                //     : moment().subtract(1, 'M').daysInMonth(),
                //   totalOfficialDays: date
                //     ? moment(date, 'YYYY-MM-DD').daysInMonth()
                //     : moment().subtract(1, 'M').daysInMonth(),
                // };

                // const round1 = await calculate({
                //   employee: x,
                //   selectedParams: salaryParams,
                //   formData: duplicateFormData.details,
                //   cgSetting: companyGroupConfig,
                //   parentSalaryRevision,
                //   monthDetails,
                //   misconducts: [],
                //   salaryAdvances: [],
                //   premiums: [],
                //   getLoans: async () => [],
                //   getRepaymentSchedules: async () => [],
                // });

                const selectedParam = salaryParams.find((y) => {
                  return y.name === x.details.salaryParam?.name;
                });

                const round1 = await salaryRevisionService.computeESICAndParams(
                  {
                    employeeId: x.id,
                    companyGroupId: x.companyGroup.id,
                    salaryParamId: selectedParam ? selectedParam.id : '',
                    parentSalaryRevisionId: parentSalaryRevision?.id,
                    formData: duplicateFormData.details,
                    month: date,
                  }
                );

                console.log('ye mila round 1 salparam', round1);

                const basicDaForSalRev =
                  round1.salParams.other.basicDaForSalRev;
                duplicateFormData.details.salParams = {
                  other: {
                    basicDaForSalRev,
                  },
                };
                duplicateFormData.details.esicApplicability = round1.esic;
                console.log('calling round 2');
                // const { esic, salParams } = await calculate({
                //   employee: x,
                //   selectedParams: salaryParams,
                //   formData: duplicateFormData.details,
                //   cgSetting: companyGroupConfig,
                //   parentSalaryRevision,
                //   monthDetails,
                //   misconducts: [],
                //   salaryAdvances: [],
                //   premiums: [],
                //   getLoans: async () => [],
                //   getRepaymentSchedules: async () => [],
                // });

                const {
                  esic,
                  salParams,
                } = await salaryRevisionService.computeESICAndParams({
                  employeeId: x.id,
                  companyGroupId: x.companyGroup.id,
                  salaryParamId: selectedParam ? selectedParam.id : '',
                  parentSalaryRevisionId: parentSalaryRevision?.id,
                  formData: duplicateFormData.details,
                  month: date,
                });

                duplicateFormData.details.esicApplicability = esic;
                duplicateFormData.details.salParams = salParams;

                empList.push({
                  employee: {
                    id: x.id,
                    name: x.name,
                    company: {
                      id: x.company.id,
                      name: x.company.name,
                    },
                    skillLevel: x.details?.jobProfile?.skillLevel,
                    grade: x.details.grade,
                  },
                  currentRevision: latestSalRev,
                  newRevision: duplicateFormData,
                });
                selEmps[x.id] = true;
              } else {
                let reason = '';
                if (!computedMinBasicDA) {
                  reason += 'Company Config does not have Basic Da Values.';
                }

                if (!basicDaForSalRev) {
                  reason +=
                    'Current Salary Revision does not have basicDa saved.';
                }

                if (basicDaForSalRev >= computedMinBasicDA) {
                  reason +=
                    'Existing BasicDA is greater than basicDaMw from Salary Params.';
                }

                skList.push({
                  employee: {
                    id: x.id,
                    name: x.name,
                    company: {
                      id: x.company.id,
                      name: x.company.name,
                    },
                    skillLevel: x.details?.jobProfile?.skillLevel,
                    grade: x.details.grade,
                  },
                  currentRevision: latestSalRev,
                  reason: reason,
                });
              }
            }
          }
          setEmployeeList(empList);
          setSkippedList(skList);
          setSelectedEmployees(selEmps);
          setLoading(false);
        }
      );

      // eslint-disable-next-line
    }, []);

    const salaryRules = companyGroupConfig.details.hrd?.salaryRules;
    if (loading) {
      return (
        <div className='flex justify-center'>
          <Recat className='w-12 inline animate-pulse' />
        </div>
      );
    }
    return (
      <div>
        <div className='border border-gray-200 p-2 rounded-md'>
          <div className='font-bold text-center'>Basic</div>
          <div className='flex'>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Skilled</span> :{' '}
              {salaryRules?.basicSkilled}
            </div>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Semi-Skilled</span> :{' '}
              {salaryRules?.basicSemiSkilled}
            </div>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Un-Skilled</span> :{' '}
              {salaryRules?.basicUnSkilled}
            </div>
          </div>
          <div className='font-bold text-center'>Dearness Allowance</div>
          <div className='flex'>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Skilled</span> :{' '}
              {salaryRules?.daSkilled}
            </div>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Semi-Skilled</span> :{' '}
              {salaryRules?.daSemiSkilled}
            </div>
            <div className='w-1/3 p-2'>
              <span className='font-semibold'>Un-Skilled</span> :{' '}
              {salaryRules?.daUnSkilled}
            </div>
          </div>
        </div>

        <div className='border border-gray-200 p-2 rounded-md'>
          <div className='italic my-2 animate-pulse'>
            The following Employees will require change in their Salary Revision
          </div>
          <div className='my-0.5 border border-gray-200 '>
            <div className='flex'>
              <div className='w-1/5 font-semibold'>Employee Name</div>
              <div className='w-2/5 font-semibold'>Current Salary Revision</div>
              <div className='w-2/5 font-semibold'>New Salary Revision</div>
            </div>
            {employeeList.map((x) => (
              <div className='flex' key={x.employee.id}>
                <div className='w-1/5'>
                  <input
                    type='checkbox'
                    checked={selectedEmployees[x.employee.id]}
                    onChange={(e) => {
                      e.preventDefault();
                      selectedEmployees[x.employee.id] = e.target.checked;
                      setSelectedEmployees({
                        ...selectedEmployees,
                      });
                    }}
                    id='checked'
                    name='checked'
                  />
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={x.employee.id}
                    name={x.employee.name}
                    extraInfo={x.employee.company.name}
                  />
                  <div>{x.grade?.name}</div>
                </div>
                <div className='w-2/5'>
                  <div className='font-semibold'>{x.currentRevision.name}</div>
                  <div>CTC Annual: {x.currentRevision?.details?.ctcAnnual}</div>
                  <div>
                    Skill Level: {x.currentRevision?.details?.skillLevel}
                  </div>
                  <div>Income tax: {x.currentRevision?.details?.incomeTax}</div>
                  <div>
                    ESIC Applicability:{' '}
                    {x.currentRevision?.details?.esicApplicability?.value}
                  </div>
                  <div>
                    CTC{' '}
                    {x.currentRevision?.details?.salParams?.earning?.totalCtc}
                  </div>
                  <div>
                    BasicDA (for Sal Rev):{' '}
                    {
                      x.currentRevision?.details?.salParams?.other
                        ?.basicDaForSalRev
                    }
                  </div>
                  <div>Notes: {x.currentRevision?.details?.notes}</div>
                </div>
                <div className='w-2/5'>
                  <div className='font-semibold'>{x.newRevision.name}</div>
                  <div>CTC Annual: {x.newRevision?.details?.ctcAnnual}</div>
                  <div>Skill Level: {x.newRevision?.details?.skillLevel}</div>
                  <div>Income tax: {x.newRevision?.details?.incomeTax}</div>
                  <div>
                    ESIC Applicability:{' '}
                    {x.newRevision?.details?.esicApplicability?.value}
                  </div>
                  <div>
                    CTC {x.newRevision?.details?.salParams?.earning?.totalCtc}
                  </div>
                  <div>
                    BasicDA (for Sal Rev):{' '}
                    {x.newRevision?.details?.salParams?.other?.basicDaForSalRev}
                  </div>
                  <div>Notes: {x.newRevision?.details?.notes}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='border border-gray-200 p-2 rounded-md'>
          <div className='italic my-2 animate-pulse'>
            The following Employees are skipped
          </div>
          <div className='my-0.5 border border-gray-200 '>
            <div className='flex'>
              <div className='w-1/5 font-semibold'>Employee Name</div>
              <div className='w-2/5 font-semibold'>Current Salary Revision</div>
              <div className='w-2/5 font-semibold'>Reason</div>
            </div>
            {skippedList.map((x) => (
              <div className='flex' key={x.employee.id}>
                <div className='w-1/5'>
                  <userRendererService.userCard
                    link={true}
                    size='small'
                    id={x.employee.id}
                    name={x.employee.name}
                    extraInfo={x.employee.company.name}
                  />
                  <div>{x.grade?.name}</div>
                </div>
                <div className='w-2/5'>
                  <div className='font-semibold'>{x.currentRevision.name}</div>
                  <div>CTC Annual: {x.currentRevision?.details?.ctcAnnual}</div>
                  <div>
                    Skill Level: {x.currentRevision?.details?.skillLevel}
                  </div>
                  <div>Income tax: {x.currentRevision?.details?.incomeTax}</div>
                  <div>
                    ESIC Applicability:{' '}
                    {x.currentRevision?.details?.esicApplicability?.value}
                  </div>
                  <div>
                    CTC{' '}
                    {x.currentRevision?.details?.salParams?.earning?.totalCtc}
                  </div>
                  <div>
                    BasicDA (for Sal Rev):{' '}
                    {
                      x.currentRevision?.details?.salParams?.other
                        .basicDaForSalRev
                    }
                  </div>
                  <div>Notes: {x.currentRevision?.details?.notes}</div>
                </div>
                <div className='w-2/5'>{x.reason}</div>
              </div>
            ))}
          </div>
        </div>

        <div className='flex justify-center'>
          <LoadingButton
            text='Bulk Salary Revision Persist'
            behaviourParams={{}}
            behaviorFn={async () => {
              console.log(
                'Following employees salary revision will be created'
              );
              for (const x of employeeList) {
                if (selectedEmployees[x.employee.id]) {
                  console.log(x.employee.name, x.newRevision.name);

                  const formData = x.newRevision;

                  const data = { ...formData };
                  data.employee = x.employee;
                  data.approvedBy = currentUser;
                  data.createdBy = currentUser;

                  const result = await setSalaryRevision(data);
                  console.log(result);
                }
              }
              onClose();
            }}
          />
        </div>
      </div>
    );
  };
}
