import {
  Daybook,
  DaybookFilterType,
} from '@erp_core/erp-types/dist/types/modules/accounts/daybook';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseCombinedDaybook } from '../../../hooks/accounts/use-daybook';
import { amountFlow, createStatement } from '../daybook';

type RenderBillReceivableProps = {
  useCombinedDaybook: UseCombinedDaybook;
};

export function renderBillReceivable({
  useCombinedDaybook,
}: RenderBillReceivableProps): JSX.Element {
  const Table = renderTableWithMapperComponent<Daybook, DaybookFilterType>();

  const header: TableHeader = [
    [
      { name: 'Date' },
      { name: 'Statement' },
      { name: 'Amount' },
      { name: 'OverDue in Days' },
    ],
  ];

  const bodyMapper = (e: Daybook) => {
    const transactionType = amountFlow(e);
    const cells: Array<TableCell> = [
      { value: moment(e.transactionDate).format('YYYY-MM-DD') },
      { value: createStatement({ data: e }) },
      { value: `${transactionType === 'Dr' ? `${e.amount} Dr` : ''}` },
      { value: '--' },
    ];
    return {
      cells,
    };
  };

  const Card = renderCardComponent();
  const cardheader: CardHeader = {
    title: 'Bills-Receivable',
    actions: [],
  };
  const cardbody: CardBody = {
    type: 'jsx-component',
    body: (
      <div className='w-full'>
        <Table
          header={header}
          bodyMapper={bodyMapper}
          type={{
            type: 'simple',
            useResources: useCombinedDaybook,
          }}
        />
      </div>
    ),
  };

  return (
    <div>
      <Card header={cardheader} body={cardbody} />
    </div>
  );
}
