import {
  ConstantsFilterType,
  CurrencyType,
} from '@erp_core/erp-types/dist/modules/constants';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import { UseCombinedCurrency } from '../../../hooks/admin/constants/currency/use-currency';
import { renderAddCurrencyForm } from './form/add-currency-form';

export type RenderCurrencyProps = {
  useCombinedCurrency: UseCombinedCurrency;
};

export function renderCurrency({
  useCombinedCurrency,
}: RenderCurrencyProps): () => JSX.Element {
  return function Currency(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      CurrencyType,
      ConstantsFilterType
    >();

    const { syncSet: setCurrency } = useCombinedCurrency();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Currency',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Currency',
            behaviour: 'modal',
            modal: {
              title: 'Add Currency',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveCurrency(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddCurrencyForm();

    const saveCurrency = async (form: Partial<CurrencyType>) => {
      try {
        const finalData = {
          ...form,
        } as CurrencyType;
        await setCurrency(finalData as CurrencyType);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [{ name: 'Currency Name' }, { name: 'Country' }, { name: 'Symbol' }],
    ];

    const bodyMapper = (r: CurrencyType) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.country },
        { value: r.symbol || '' },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='h-full w-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                type: 'paginated',
                usePaginatedResources: useCombinedCurrency,
              }}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
