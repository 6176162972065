import { renderFormV2 } from '@erp_core/erp-ui-components';

export function renderSalaryRuleSimpleForm() {
  return function Form({
    data,
    onSave,
  }: {
    data: {
      lowerESICThreshold?: string | undefined;
      upperESICThreshold?: string | undefined;
      cutOffESICDates?: string | undefined;
    };
    onSave: (data) => Promise<void>;
  }) {
    const Form = renderFormV2<any>({
      fieldsData: [
        {
          type: 'input',
          property: 'upperESICThreshold',
          label: 'Upper ESIC Threshold',
          required: true,
        },
        {
          type: 'input',
          property: 'lowerESICThreshold',
          label: 'Lower ESIC Threshold',
          required: true,
        },
        {
          property: 'cutOffESICDates',
          type: 'input',
          label: 'Cut Off ESIC Dates (comma separated MM-DD)',
          required: true,
        },
      ],
      initialFormState: {
        ...data,
      },
      onSubmit: onSave,
      mapTToU: (s) => s,
    });

    return <Form />;
  };
}
