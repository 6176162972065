import { RepaymentScheduleType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { LoanFilter } from '@erp_core/erp-types/dist/types/modules/hrd/loan';
import {
  GetDisbursementRequest,
  GetDisbursementResponse,
  LoanInterface,
  PostDisbursementResp,
} from 'models/interfaces/loans/loan';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import { LoanV2Type } from '../../../../../../modules/hrd/loan-management/types/loan-v2-type';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class LoanHttpRepo
  extends GenericHttpWithLogsRepo<LoanV2Type, LoanFilter>
  implements LoanInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/loans',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getDisbursement(
    p: GetDisbursementRequest
  ): Promise<GetDisbursementResponse[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/disbursement/?disbursementAmount=${p.disbursementAmount}&monthlyRepaymentAmount=${p.monthlyRepaymentAmount}&month=${p.month}&loanId=${p.loanId}&rateOfInterest=${p.rateOfInterest}`
    );

    if (res.status === 200) {
      const { data } = res;
      return data as GetDisbursementResponse[];
    }
    return {} as GetDisbursementResponse[];
  }

  async postDisbursement(
    p: GetDisbursementRequest
  ): Promise<PostDisbursementResp> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/disbursement/?disbursementAmount=${p.disbursementAmount}&monthlyRepaymentAmount=${p.monthlyRepaymentAmount}&month=${p.month}&loanId=${p.loanId}&rateOfInterest=${p.rateOfInterest}`
    );

    if (res.status === 200) {
      return {
        success: true,
        message: toast('Repayment Schedule added successfully'),
      };
    }

    return { success: false, message: toast('Something went wrong') };
  }

  //v2
  async requestLoan(p: {
    amount: number;
    employee: IdName;
    reason: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/request-loan`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested Loan');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async approveLoan(p: { id: string; interestRate: number }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-loan`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Loan');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestDisburseAmount(p: {
    id: string;
    amount: number;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-disburse-amount`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested for disbursement');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async approveDisbursementAmount(p: {
    id: string;
    monthlyRepayment: number;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-disburse-amount`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved disbursement');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async getSchedule(p: { id: string }): Promise<RepaymentScheduleType[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${p.id}/schedule`
    );

    if (res.status === 200) {
      if (res.data.success) {
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
    return [];
  }

  async simulateSchedule(p: {
    id: string;
    monthlyDeduction?: number;
    interestRate?: number;
    disbursement?: number;
    fromMonth?: string;
  }): Promise<RepaymentScheduleType[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/simulate-schedule`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
    return [];
  }

  async requestTopUpLoan(p: {
    id: string;
    amount: number;
    reason: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-topup`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested topup');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveTopup(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-topup`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Topup Loan');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestAdhocRepayment(p: {
    id: string;
    amount: number;
    month: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-adhoc-repayment`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested adhoc repayment');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveAdhocRepayment(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-adhoc-repayment`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Adhoc Repayment');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestSkipRepayment(p: { id: string; month: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-skip-repayment`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested skip repayment');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveSkipRepayment(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-skip-repayment`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Skip repayment');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestEditUpcomingRepayment(p: {
    id: string;
    amount: number;
    month: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-edit-repayments`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested edit repayments');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveEditUpcomingRepayment(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-edit-repayments`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Edit Repayment');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestEditAllRepayments(p: {
    id: string;
    amount: number;
    date: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-edit-all-repayments`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested edit all repayments');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveEditAllRepayments(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-edit-all-repayments`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved Edit All Repayments');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }

  async requestEditInterestRate(p: {
    id: string;
    rate: number;
    date: string;
  }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/request-edit-interest-rates`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully requested edit interest rates');
        return res.data.data;
      } else {
        toast.error(res.data.message);
      }
    }
  }

  async approveEditInterestRate(p: { id: string }): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${p.id}/approve-edit-interest-rates`,
      p
    );

    if (res.status === 200) {
      if (res.data.success) {
        toast.success('Successfully approved edit interest rates');
        console.log(res.data.data);
      } else {
        toast.error(res.data.message);
      }
      return;
    }
    toast.error('Unexpected error!');
  }
}
