import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { ReportsHttpRepo } from '../datalayer/repository/http/reporting/reports';
import { ReportsInterface } from '../models/interfaces/reporting/reports';
import {
  ReportsFilterType,
  ReportsType,
} from '../models/types/reporting/reports';
import { erpV2Api } from './admin';
import { REPORTING_SERVER_URI } from './server-uri';

export const reportsService = new ReportsHttpRepo(
  REPORTING_SERVER_URI,
  erpV2Api
);

export const useCombinedReport = createUseCombinedResource<
  ReportsType,
  ReportsFilterType,
  ReportsInterface
>({
  useService: () => reportsService,
});
