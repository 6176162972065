import { VouchersType } from '@erp_core/erp-types/dist/modules/accounts';
import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedLedger } from '../../../../../hooks/accounts/tally/ledger/use-ledger';
import { UseCombinedVoucher } from '../../../../../hooks/accounts/vouchers/use-voucher';
import { UseCombinedGodown } from '../../../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedGrade } from '../../../../../hooks/inventory/grade/use-grade';
import { UseCombinedItemCategory } from '../../../../../hooks/inventory/item/item-category/use-item-category';
import { UseCombinedVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseCombinedClient } from '../../../../../hooks/order/sales/client/use-client';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderConsumptionVoucher } from './consumption-voucher';
import { renderGodownTransfer } from './godown-transfer';
import { renderPurchaseOrder } from './purchase-order';
import { renderSalesOrder } from './sales-order';

export function CreateVoucher({
  useCombinedVoucher,
  useCombinedVendor,
  useCombinedClient,
  useCombinedItemCategory,
  useCombinedGrade,
  useCombinedGodown,
  itemService,
  onClose,
}: {
  useCombinedVoucher: UseCombinedVoucher;
  useCombinedVendor: UseCombinedVendor;
  useCombinedLedger: UseCombinedLedger;
  useCombinedClient: UseCombinedClient;
  useCombinedItemCategory: UseCombinedItemCategory;
  useCombinedGrade: UseCombinedGrade;
  useCombinedGodown: UseCombinedGodown;
  itemService: ItemInterface;
  onClose: () => void;
}) {
  const [selectedVoucher, setSelectedVoucher] = useState<string>('');
  const { syncSet: setVoucher } = useCombinedVoucher();
  const Card = renderCardComponent();
  async function onSave(s: VouchersType) {
    await setVoucher(s);
    onClose();
  }
  const PurchaseOrderVoucher = renderPurchaseOrder({
    itemService,
    useCombinedGrade,
    useCombinedVendor,
    useCombinedItemCategory,
  });
  const GodownTransferVoucher = renderGodownTransfer({
    useCombinedGodown,
    useCombinedGrade,
    itemService,
  });
  const ConsumptionVoucher = renderConsumptionVoucher({
    useCombinedGodown,
    useCombinedGrade,
    itemService,
  });

  const SalesOrderVoucher = renderSalesOrder({
    useCombinedClient,
    useCombinedItemCategory,
    useCombinedGrade,
    itemService,
  });

  return (
    <div>
      <Card
        header={{
          title: 'Create Voucher',
          subheading: (
            <div>
              <select
                value={selectedVoucher}
                onChange={(evt) => setSelectedVoucher(evt.target.value)}
              >
                <option value=''>Select</option>
                <option value='purchase-order'>Purchase Order</option>
                <option value='godown-transfer'>Godown Transfer</option>
                <option value='sales-order'>Sales Order</option>
                <option value='consumption-voucher'>Consumption Voucher</option>
              </select>
            </div>
          ),
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              {selectedVoucher === '' ? (
                <div className='animate-pulse text-center text-3xl'>
                  Select Voucher
                </div>
              ) : null}
              {selectedVoucher === 'purchase-order' ? (
                <PurchaseOrderVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'godown-transfer' ? (
                <GodownTransferVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'sales-order' ? (
                <SalesOrderVoucher onSave={onSave} />
              ) : null}
              {selectedVoucher === 'consumption-voucher' ? (
                <ConsumptionVoucher onSave={onSave} />
              ) : null}
            </div>
          ),
        }}
      />
    </div>
  );
}
