import {
  AttendanceType,
  CombinedAttDataType,
  Punch,
} from '@erp_core/erp-types/dist/modules/hrd';
import {
  AdvancedLoadingButton,
  LoadingButton,
} from '@erp_core/erp-ui-components';
import { Popover } from '@headlessui/react';

import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  CheckBadgeIcon,
  FingerPrintIcon,
  NoSymbolIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { AttendanceFilter } from 'models/interfaces/hrd/attendance';
import moment from 'moment-timezone';
import { renderAuthWrapper } from '../../../../components/auth';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { deleteManualPunch } from './delete-manual-punch';
import { movePunch } from './move-punch';
import { updatePunch } from './update-punch';

type List = {
  location: { id: string; name: string };
  punches: Punch[];
};

export const renderGroup = ({
  list,
  date,
  type,
  isFinalized,
  currentUser,
  getAllAttendanceSync,
  getData,
  setAttendance,
  d,
  useUserAuthorization,
  isDaybook,
}: {
  isDaybook: boolean;
  list: List[];
  date: string;
  type: 'check-out' | 'check-in';
  isFinalized: boolean;
  d: AttendanceType;

  currentUser: CurrentUserType;
  getAllAttendanceSync: (
    filter?: AttendanceFilter
  ) => Promise<AttendanceType[]>;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  useUserAuthorization: UseUserAuthorization;
}) => {
  return (
    <div>
      {list?.map((ci, ciIndex) => {
        return (
          <div key={ciIndex} className='p-1 my-1 border hover:border-blue-500'>
            <div className='text-blue-600 font-bold'>{ci?.location?.name}</div>
            <div className=''>
              {ci.punches
                ?.sort((a, b) => moment(a.timeStamp).diff(moment(b.timeStamp)))
                ?.map((p, pIndex) => {
                  const setCheckOut = () => {
                    const newData: Punch = { ...p, action: 'check-out' };
                    updatePunch({
                      updatedPunch: newData,
                      setAttendance,
                      getData,
                      d,
                    });
                  };
                  const setCheckIn = () => {
                    const newData: Punch = { ...p, action: 'check-in' };
                    updatePunch({
                      updatedPunch: newData,
                      setAttendance,
                      getData,
                      d,
                    });
                  };

                  const ignorePunch = (reason) => {
                    const newData: Punch = {
                      ...p,
                      ignoreRequest: {
                        reason,
                        status: 'requested',
                        requestedBy: {
                          id: currentUser.id,
                          name: currentUser.name,
                        },
                      },
                    };
                    updatePunch({
                      updatedPunch: newData,
                      setAttendance,
                      getData,
                      d,
                    });
                  };

                  const acceptIgnorePunch = (response) => {
                    const newData: Punch = { ...p };
                    const newIgnoreRequest = { ...p.ignoreRequest };
                    if (response === 'accept') {
                      newIgnoreRequest.status = 'accepted';
                      newData.action = 'ignored';
                    } else {
                      newIgnoreRequest.status = 'rejected';
                    }
                    (newData as any).ignoreRequest = newIgnoreRequest;
                    updatePunch({
                      updatedPunch: newData,
                      setAttendance,
                      getData,
                      d,
                    });
                  };
                  if (isFinalized || !isDaybook) {
                    return <Chip key={pIndex} p={p} date={date} />;
                  }
                  return (
                    <Popover
                      className='relative'
                      key={`${pIndex}-${date}-${p.timeStamp}`}
                    >
                      {({ open }) => (
                        <>
                          <Popover.Button>
                            <Chip p={p} date={date} />
                          </Popover.Button>
                          <Popover.Panel className='absolute border border-gray-100 w-56 shadow-lg bg-white left-1/2 z-10 mt-3 px-2'>
                            <ContextMenu
                              setCheckIn={setCheckIn}
                              setCheckOut={setCheckOut}
                              ignorePunch={ignorePunch}
                              acceptIgnorePunch={acceptIgnorePunch}
                              type={type}
                              p={p}
                              d={d}
                              date={date}
                              currentUser={currentUser}
                              getAllAttendanceSync={getAllAttendanceSync}
                              setAttendance={setAttendance}
                              getData={getData}
                              onClose={() => {}}
                              useUserAuthorization={useUserAuthorization}
                            />
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                  );
                })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const Chip = ({ p, date }: { p: Punch; date: string }) => {
  const chipDay = moment
    .utc(p.timeStamp)
    .tz('Asia/Kolkata')
    .format('YYYY-MM-DD');
  const currentDay = moment
    .tz(`${date} 00:00:00`, 'Asia/Kolkata')
    .format('YYYY-MM-DD');
  const diffInDays = moment(chipDay).diff(moment(currentDay), 'days');
  return (
    <span className='bg-zinc-100 w-fit hover:shadow border inline-block text-xs mr-2 cursor-pointer rounded p-0'>
      {/* {p.action !== 'not-determined' ? <CheckBadgeIcon className='w-4 h-4 text-sm text-green-500 inline-block' /> : null} */}
      {moment(p.timeStamp).format('h:mm:ss a')}{' '}
      {diffInDays ? `${diffInDays}day` : ''}{' '}
      {p.type === 'manual' ? (
        <UserIcon
          title={` Created by ${p.createdBy?.name || ''}`}
          className={`${
            p.action === 'not-determined' ? 'text-gray-700' : 'text-green-600'
          } w-4 h-4 text-sm inline-block`}
        />
      ) : (
        <FingerPrintIcon
          title='created by attendance machine'
          className={`${
            p.action === 'not-determined' ? 'text-gray-600' : 'text-green-600'
          } w-4 h-4 text-sm inline-block`}
        />
      )}
      {p.action === 'ignored' ||
      (p.ignoreRequest && p.ignoreRequest.status !== 'rejected') ? (
        <NoSymbolIcon
          className={`w-4 h-4 inline ${
            p.ignoreRequest?.status === 'requested'
              ? 'animate-pulse text-orange-800'
              : 'text-red-800'
          }`}
        />
      ) : null}
    </span>
  );
};

const ContextMenu = ({
  setCheckIn,
  setCheckOut,
  ignorePunch,
  acceptIgnorePunch,
  type,
  p,
  d,
  date,
  getAllAttendanceSync,
  setAttendance,
  getData,
  currentUser,
  onClose,
  useUserAuthorization,
}: {
  ignorePunch: (reason: string) => void;
  acceptIgnorePunch: (reason: string) => void;
  setCheckIn: () => void;
  setCheckOut: () => void;
  p: Punch;
  type: string;
  d: AttendanceType;
  date: string;
  currentUser: CurrentUserType;
  getAllAttendanceSync: (
    filter?: AttendanceFilter
  ) => Promise<AttendanceType[]>;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
  onClose: () => void;
  useUserAuthorization: UseUserAuthorization;
}) => {
  const AuthWrapper = renderAuthWrapper({ useUserAuthorization });

  const Body = () => {
    if (p.approvedBy?.id) {
      return (
        <div>
          <CheckBadgeIcon className='w-4 h-4 text-sm text-green-500 inline-block' />
          <UserIcon
            title={` Created by ${p.createdBy?.name || ''}`}
            className='w-4 h-4 text-sm text-gray-600 inline-block'
          />
          Approved by {p.approvedBy?.name}
        </div>
      );
    } else {
      return (
        <div className='flex flex-col mt-2 items-start'>
          <AdvancedLoadingButton
            behaviour='confirm'
            onConfirm={() => {
              return {
                title:
                  type === 'check-in'
                    ? 'Are you sure you want to Confirm Checkin this punch?'
                    : 'Are you sure you want to move this punch to check-in?',
                onConfirm: async () => {
                  setCheckIn();
                },
              };
            }}
            defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
            iconStyle='fill-gray-500 w-5'
            text={type === 'check-in' ? 'Confirm check in' : 'Move to check in'}
          />
          <AdvancedLoadingButton
            behaviour='confirm'
            onConfirm={() => {
              return {
                title:
                  type === 'check-out'
                    ? 'Are you sure you want to Confirm Checkout this punch?'
                    : 'Are you sure you want to move this punch to check-out?',
                onConfirm: async () => {
                  setCheckOut();
                },
              };
            }}
            defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
            iconStyle='fill-gray-500 w-5'
            text={
              type === 'check-out' ? 'Confirm check out' : 'Move to check out'
            }
          />
          {!p.ignoreRequest?.status ? (
            <AuthWrapper action='UI:REQ-IGNR-PNCH:VIEW'>
              <AdvancedLoadingButton
                behaviour='confirm'
                onConfirm={() => {
                  return {
                    title: 'Are you sure you want to Ignore this punch?',
                    message: 'Specify Reason',
                    showInput: true,
                    onConfirm: async (reason) => {
                      ignorePunch(reason as string);
                    },
                  };
                }}
                defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
                iconStyle='fill-gray-500 w-5'
                text='Ignore Punch'
              />
            </AuthWrapper>
          ) : null}
          {p.ignoreRequest?.status === 'requested' ? (
            <AuthWrapper action='UI:ATH-IGNR-PNCH:VIEW'>
              <AdvancedLoadingButton
                behaviour='confirm'
                onConfirm={() => {
                  return {
                    title: 'Type "accept" or "reject" for this Ignore Request?',
                    showInput: true,
                    onConfirm: async (response) => {
                      if (response === 'accept' || response === 'reject') {
                        acceptIgnorePunch(response as string);
                      }
                    },
                  };
                }}
                defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
                iconStyle='fill-gray-500 w-5'
                text='Accept/Reject Ignore Punch'
              />
            </AuthWrapper>
          ) : null}
          <AdvancedLoadingButton
            behaviour='confirm'
            onConfirm={() => {
              return {
                title: 'Are you sure you want to move punch to previous day?',
                onConfirm: async () => {
                  await movePunch({
                    p,
                    direction: 'prev',
                    d,
                    date,
                    currentUser,
                    setAttendance,
                    getData,
                    getAllAttendanceSync,
                    onClose,
                  });
                },
              };
            }}
            defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
            iconStyle='fill-gray-500 w-5'
            text='Move to previous day'
          />
          <AdvancedLoadingButton
            behaviour='confirm'
            onConfirm={() => {
              return {
                title: 'Are you sure you want to move this punch on next day?',
                onConfirm: async () => {
                  await movePunch({
                    p,
                    direction: 'next',
                    d,
                    date,
                    currentUser,
                    setAttendance,
                    getData,
                    getAllAttendanceSync,
                    onClose,
                  });
                },
              };
            }}
            defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
            iconStyle='fill-gray-500 w-5'
            text='Move to next day'
          />
          {p.type === 'manual' ? (
            <AdvancedLoadingButton
              behaviour='confirm'
              onConfirm={() => {
                return {
                  title: 'Are you sure you want to delete punch?',
                  onConfirm: async () => {
                    await deleteManualPunch({
                      p,
                      d,
                      setAttendance,
                      getData,
                    });
                  },
                };
              }}
              defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
              iconStyle='fill-gray-500 w-5'
              text='Delete'
            />
          ) : null}
          {p.type === 'auto' ? (
            <AuthWrapper action='UI:DEL-SYS-PNCH:VIEW'>
              <AdvancedLoadingButton
                behaviour='confirm'
                onConfirm={() => {
                  return {
                    title: 'Are you sure you want to delete punch?',
                    onConfirm: async () => {
                      await deleteManualPunch({
                        p,
                        d,
                        setAttendance,
                        getData,
                      });
                    },
                  };
                }}
                defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
                iconStyle='fill-gray-500 w-5'
                text='Delete'
              />
            </AuthWrapper>
          ) : null}
          <LoadingButton
            behaviorFn={async () => {}}
            defaultStyle='p-2 hover:bg-gray-100 text-black w-full'
            iconStyle='fill-gray-500 w-5'
            text={
              p.approvedBy?.id && p.action !== 'not-determined'
                ? 'Un Approve'
                : 'Approve'
            }
          />
        </div>
      );
    }
  };
  return (
    <div className='p-3 rounded'>
      <div className='font-bold'>
        {moment(p.timeStamp).format('DD/MM/YY h:mm:ss a')}
      </div>
      <div>
        {p.ignoreRequest ? (
          <div>
            <div>
              <span className='font-semibold'>Ignore Status: </span>{' '}
              {p.ignoreRequest?.status}
            </div>
            <div>
              <span className='font-semibold'> Reason: </span>{' '}
              {p.ignoreRequest?.reason}
            </div>
          </div>
        ) : null}
      </div>
      <Body />
    </div>
  );
};

export function manualPunchCount(attendances: CombinedAttDataType[]): number {
  let count = 0;
  attendances.forEach((x) => {
    x.punchResult.checkInList?.forEach((y) => {
      y.punches?.forEach((z) => {
        if (z.type === 'manual') {
          count++;
        }
      });
    });
    x.punchResult.checkOutList?.forEach((y) => {
      y.punches?.forEach((z) => {
        if (z.type === 'manual') {
          count++;
        }
      });
    });
  });
  return count;
}

export function gatepassCount(attendances: CombinedAttDataType[]): number {
  let count = 0;
  attendances.forEach((x) => {
    count += x.gatePasses?.length || 0;
  });
  return count;
}

export function offsiteCount(attendances: CombinedAttDataType[]): number {
  let count = 0;
  attendances.forEach((x) => {
    count += x.offSiteWorkHours?.length || 0;
  });
  return count;
}

export function otSalaryHours(attendances: CombinedAttDataType[]): number {
  let hours = 0;
  attendances.forEach((x) => {
    if (
      x.finalized &&
      x.details?.summary?.addSalaryForOvertime?.overtimeHours
    ) {
      hours += parseFloat(
        `${x.details?.summary?.addSalaryForOvertime?.overtimeHours}`
      );
    }
  });

  return hours;
}
