import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import { Popover } from '@headlessui/react';
import { CheckBadgeIcon, ClockIcon } from '@heroicons/react/24/outline';

export function OvertimeIcon({ d }: { d: CombinedAttDataType }): JSX.Element {
  return (
    <>
      {d?.overtimes?.map((gp) => {
        if (gp.status === 'cancelled')
          return <div key={gp.id} className='hidden' />;
        return (
          <Popover className='relative' key={gp.id}>
            {({ open }) => (
              <>
                <Popover.Button>
                  <div className='flex flex-row bg-slate-50 border rounded-full p-0.5'>
                    <ClockIcon className='w-5 h-5 text-blue-500 inline' />
                    {gp.status === 'approved' ? (
                      <CheckBadgeIcon className='w-5 h-5 text-green-500 inline-block' />
                    ) : null}
                  </div>
                </Popover.Button>
                <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                  <div className='flex flex-col justify-center'>
                    Overtime issued by {gp?.issuedBy.name}
                    <span>Reason: {gp?.reason}</span>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
        );
      })}
    </>
  );
}
