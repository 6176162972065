/* eslint-disable react-hooks/exhaustive-deps */
import { Check } from '@erp_core/erp-icons/icons/web/check';
import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { ExcelUpload } from '@erp_core/erp-icons/icons/web/excel-upload';
import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  Filter,
  renderBulkDownload,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import toast from 'react-hot-toast';
// import { renderUploadExcel } from '@erp_core/erp-ui-components/dist/upload-excel';
import { ItemInterface } from '../../../../models/interfaces/inventory/item/index';
import { ItemFilter } from '../../../../models/types/inventory/item';
// import { renderItemSyncFunction } from './utils/onSync';
// import { getSummary } from './utils/getSummary';
// import { processData } from './utils/processData';
// import { validateData } from './utils/validateData';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedItemCategory } from '../../../../hooks/inventory/item/item-category/use-item-category';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UserRendererInterface } from '../../../common/fragments/user';
import { CategoryIcons } from '../item-categories';
import { renderAddItemForm } from './add-item-form';
import { renderExcelUpload } from './bulk-excel-upload';

const itemDownloadMapper: XlsxDownloadMapper = [
  {
    columnName: 'item-name',
    dataBinding: { resource: 'item', property: 'name' },
  },
  {
    columnName: 'stock-group',
    dataBinding: { resource: 'item', property: 'stockgroup.name' },
  },
  { columnName: 'item-id', dataBinding: { resource: 'item', property: 'id' } },
  {
    columnName: 'stock-group-id',
    dataBinding: { resource: 'item', property: 'stockGroupId' },
  },
];

export type RenderItemV2ListProps = {
  useCombinedItem: UseCombinedItem;
  useCombinedStockGroup: UseCombinedStockGroup;
  itemService: ItemInterface;
  useUserAuthorization: UseUserAuthorization;
  useCombinedItemCategory: UseCombinedItemCategory;
  useCombinedUser: UseCombinedUser;
  userRendererService: UserRendererInterface;
};

export function renderItemV2List({
  useCombinedUser,
  useCombinedItem,
  useCombinedStockGroup,
  itemService,
  useUserAuthorization,
  useCombinedItemCategory,
  userRendererService,
}: RenderItemV2ListProps): () => JSX.Element {
  return function ItemList(): JSX.Element {
    const { get: getAllowedActions } = useUserAuthorization();
    // const { syncSet: setStockGroup } = useStockGroup();
    const { syncSet: setItem } = useCombinedItem();

    const downloadItems = async () => {
      const items = await itemService.getAll();
      const stockGroupSeparated = _.groupBy(items, 'stockGroupId') as any;

      const result: {
        mapper: XlsxDownloadMapper;
        data: any[];
        fileName: string;
      }[] = [];

      // Iterating over all items grouped by stockGroupId and downloading items list for each stockgroup separated items
      _.forEach(stockGroupSeparated, (itemsGroup, stockGroupId) => {
        if (itemsGroup.length !== 0) {
          const dynamicMapper: XlsxDownloadMapper = [...itemDownloadMapper];

          // Adding specifications as column in dynamic Mapper
          _.map(
            itemsGroup[0].stockgroup.details.specification?.fields || [],
            (f) => {
              const key = f.property;
              dynamicMapper.push({
                columnName: key,
                dataBinding: {
                  resource: 'item',
                  property: `details.specification.${key}`,
                },
              });
            }
          );

          const fileName = `item-list-${stockGroupId}`; // File name based in stock group id

          result.push({ mapper: dynamicMapper, data: itemsGroup, fileName });
          // Downloading File
          // xlsxDownloader(dynamicMapper, itemsGroup, fileName);
        }
      });
      return result;
      // toast('Downloading');
    };

    const handleAddItem = async (form: any) => {
      const finalData = {
        name: form.name,
        category: form.category,
        stockgroup: form.stockgroup,
      } as any;
      try {
        setItem(finalData);
      } catch (error) {
        toast((error as any).message);
      }
    };

    const Table = renderTableWithMapperComponent<ItemRes, ItemFilter>();
    const tableHeader: TableHeader = [
      [
        {
          name: (
            <>
              <CubeIcon className='inline w-6 h-6' />
              Item Name
            </>
          ),
        },
        { name: 'Version' },
        // { name: 'Grade',  },
        // { name: 'Revision',  },
        // { name: 'Status' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (i: ItemRes) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {i.categories?.map((x) => (
                <CategoryIcons category={x.category} key={x.id} />
              ))}
              {i.name || ''}
              {i.status === 'approved' ? (
                <Check className='border rounded-full w-3 ml-1 inline bg-green-500 text-white' />
              ) : null}
            </>
          ),
          link: `/inventory/masters/items/${i.id}/properties`,
        },
        { value: i.version || '0.0.0' },
        // { value: i.details?.profile?.grade || '--', style: 'text-center' },
        // { value: i.details?.profile?.revision || '--' },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.createdBy?.id}
              name={i.createdBy?.name}
            />
          ),
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={i.lastModifiedBy?.id}
              name={i.lastModifiedBy?.name}
            />
          ),
        },
      ];

      return {
        cells,
      };
    };
    // const onSync = renderItemSyncFunction({ setItem, setStockGroup })
    // const GenericExcelUpload = renderUploadExcel({
    //   onSync,
    //   getSummary: getSummary,
    //   processData: processData,
    //   validateData: validateData
    //  });
    const GenericNewExcelUpload = renderExcelUpload<ItemRes>({
      resource: 'Item',
      setResource: setItem,
      excelConfig: [
        {
          type: 'one-on-one-mapping',
          fileName: 'profile',
          mapper: {
            identifier: {
              wsCol: 'item-id',
              objProp: 'id',
            },
            fields: [
              { wsCol: 'item-id', objProp: 'id' },
              { wsCol: 'item-name', objProp: 'name' },
              { wsCol: 'stock-group-id', objProp: 'stockGroupId' },
              { wsCol: 'alias', objProp: 'details.profile.alias' },
              {
                wsCol: 'abbreviation',
                objProp: 'details.profile.abbreviation',
              },
            ],
          },
        },
        {
          type: 'one-on-one-mapping',
          fileName: 'specification',
          mapper: {
            identifier: {
              wsCol: 'item-id',
              objProp: 'id',
            },
            fields: [
              { wsCol: 'item-id', objProp: 'id' },
              { wsCol: 'type', objProp: 'details.specification.type' },
              { wsCol: 'sub-type', objProp: 'details.specification.sub-type' },
            ],
          },
        },
        {
          type: 'many-on-one-mapping',
          fileName: 'packaging',
          targetArray: 'details.packaging',
          mapper: {
            identifier: {
              wsCol: 'item-id',
              objProp: 'id',
            },
            fields: [
              { wsCol: 'item-id', objProp: 'id', onTargetArray: false },
              {
                wsCol: 'packaging-type',
                objProp: 'packagingType',
                onTargetArray: true,
              },
              {
                wsCol: 'filling-factor',
                objProp: 'fillingFactor',
                onTargetArray: true,
              },
              {
                wsCol: 'filled-volume',
                objProp: 'filledVolume',
                onTargetArray: true,
              },
              {
                wsCol: 'net-weight',
                objProp: 'netWeight',
                onTargetArray: true,
              },
              {
                wsCol: 'commercial',
                objProp: 'commercial',
                onTargetArray: true,
              },
              {
                wsCol: 'is-standard',
                objProp: 'isStandard',
                onTargetArray: true,
              },
            ],
          },
        },
      ],
    });

    const Card = renderCardComponent();

    const AddItemForm = renderAddItemForm({
      useCombinedStockGroup,
      useCombinedItemCategory,
    });

    const BulkDownload = renderBulkDownload({
      mapperFun: downloadItems,
      downloadIcon: ExcelDownload,
    });
    const header: CardHeader = {
      title: 'Item List',
      actions: [
        {
          type: 'button',
          auth: 'UI:BTN-ADD-ITEM:VIEW',
          button: {
            name: 'Add Item',
            behaviour: 'modal',
            modal: {
              title: 'Add Item',
              content: ({ onClose }) => {
                return (
                  <AddItemForm
                    onSave={async (form) => {
                      await handleAddItem(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          auth: 'UI:BTN-ITEM-DOWNLOAD:VIEW',
          show: () => true,
          type: 'jsx',
          jsx: <BulkDownload />,
        },
        {
          type: 'button',
          auth: 'UI:BTN-ITEM-UPLOAD:VIEW',
          button: {
            name: 'Bulk Add Items',
            suffix: (
              <ExcelUpload className='w-6 h-6 inline cursor-pointer text-blue-700 hover:text-black' />
            ),
            behaviour: 'modal',
            modal: {
              title: 'Bulk Add Items',
              content: () => {
                return <GenericNewExcelUpload />;
              },
            },
          },
        },
      ],
    };

    const filter: Filter<ItemFilter> = {
      version: 'v2',
      sortFields: [
        {
          key: 'ItemName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'Search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'StockGroup',
          value: 'stockGroupId',
          type: 'search-select',
        },
        {
          key: 'Status',
          value: 'all',
          type: 'drop-down',
          options: [
            { text: 'all', value: 'all' },
            { text: 'approved', value: 'equal::approved' },
            { text: 'unapproved', value: 'equal::unapproved' },
          ],
        },
        {
          key: 'CreatedBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'LastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: any) => {
        const filterData: any = {};

        if (filterSelection.Status !== 'all') {
          filterData.status = filterSelection.Status;
        }
        if (filterSelection.Search !== 'all' && filterSelection.Search !== '') {
          filterData.search = filterSelection.Search;
        }

        if (filterSelection.StockGroup) {
          filterData.stockGroupId = filterSelection.StockGroup;
        }

        if (filterSelection.CreatedBy) {
          filterData.createdBy = filterSelection.CreatedBy;
        }

        if (filterSelection.LastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.LastModifiedBy;
        }

        return (filterData as unknown) as ItemFilter;
      },
      filterResources: {
        StockGroup: {
          searchOptions: {
            useSearch: useCombinedStockGroup,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        CreatedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
        LastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {
              console.log(u);
            },
          },
        },
      },
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          bodyMapper={bodyMapper}
          filter={filter}
          type={{
            type: 'paginated',
            usePaginatedResources: useCombinedItem,
          }}
        />
      ),
    };

    return (
      <>
        <Card
          header={header}
          body={body}
          auth={{ actions: getAllowedActions().actions }}
        />
      </>
    );
  };
}
