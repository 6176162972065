export function Landing() {
  return (
    <div className='flex w-full hidden lg:inline-block lg:w-1/2 bg-indigo-900'>
      <div className='flex h-full'>
        <div className='m-auto'>
          <img className='w-full' src='/login-1.svg' alt='' />
        </div>
      </div>
    </div>
  );
}
