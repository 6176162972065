import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ChangeAssesmentType } from 'models/types/quality-assurance/change-assesment';
import moment from 'moment';

export type EffectivenessEvaluationFormType = {
  effectivenessEvaluationComment: string;
};

// eslint-disable-next-line
export function renderEffectivenessEvaluationForm() {
  return function EffectivenessEvaluationForm({
    onSave,
    data,
  }: {
    onSave: (data: ChangeAssesmentType) => Promise<void>;
    data?: ChangeAssesmentType;
  }): JSX.Element {
    const formProps: FormProps<EffectivenessEvaluationFormType> = {
      fieldsData: [
        {
          rows: 3,
          property: 'effectivenessEvaluationComment',
          type: 'text-area',
          label: 'Effectiveness evaluation by Manager-QA',
        },
      ],
      initialFormState: {
        effectivenessEvaluationComment:
          data?.details?.effectivenessEvaluation
            ?.effectivenessEvaluationComment || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/3',
      onSubmit: async (f) => {
        await onSave(({
          details: {
            ...data?.details,
            effectivenessEvaluation: {
              effectivenessEvaluationComment: f.effectivenessEvaluationComment,
              createdAt: moment().utc().format(),
            },
          },
        } as unknown) as ChangeAssesmentType);
      },
    };

    const Form = renderFormV2<EffectivenessEvaluationFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<EffectivenessEvaluationFormType>
    ): EffectivenessEvaluationFormType {
      return (b as unknown) as EffectivenessEvaluationFormType;
    }
  };
}
