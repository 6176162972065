/* eslint-disable arrow-body-style */

import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { ManufacturingVoucher } from '@erp_core/erp-types/dist/modules/order';
import { Machine } from '@erp_core/erp-types/dist/modules/planning';
import { LoadingButton } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';
import { UseCombinedManufacturingVoucher } from '../../../../../hooks/order/work-order/mf-voucher/use-manufacturing-voucher';
import { ProposedSchedule } from './utils/get-proposed';

type Props = {
  filtered: {
    id: string;
    plannedSchedule: {
      id: string;
      calendarId: string;
      title: string;
      category: string;
      color: string | undefined;
      backgroundColor: string | undefined;
      dragBackgroundColor: string;
      borderColor: string | undefined;
      start: string | undefined;
      end: string | undefined;
    }[];
    proposedSchedule: ProposedSchedule[];
  }[];
  machines: Machine[];
  close: () => void;
};
export const renderCreateMv = ({
  useCombinedManufacturingVoucher,
}: {
  useCombinedManufacturingVoucher: UseCombinedManufacturingVoucher;
}) => {
  return function ({ filtered, machines, close }: Props) {
    const [progress, setProgress] = useState<number>(0);
    const { syncSet: set } = useCombinedManufacturingVoucher();

    const incrementProgress = (num) => {
      // Update state with incremented value
      setProgress((progress) => progress + num);
    };
    let count = 0;
    filtered.forEach((i) => {
      count += i.proposedSchedule.length;
    });

    const onProposeConfirm = async () => {
      for (let i = 0; i < filtered?.length; i++) {
        for (let j = 0; j < filtered[i].proposedSchedule.length; j++) {
          const newMv = {
            manufacturingVoucherItems: [],
            item: filtered[i].proposedSchedule[j].item || ({} as UserIdName),
            machineId: filtered[i].id,
            name: `M-VOUCHER-${
              machines.find((m) => m.id === filtered[i].id)?.name
            }-${moment().utc().format('YYYY-MM-DD')}`,
            expectedBy: moment().add(15, 'days').format('YYYY-MM-DD'),
            status: 'planned',
            details: {
              rawItems: [],
            },
          };
          const mvi = {
            quantity:
              Number(
                machines.find((m) => m.id === filtered[i].id)?.details
                  .capacity || '0'
              ) *
              moment(filtered[i].proposedSchedule[j].end).diff(
                moment(filtered[i].proposedSchedule[j].start),
                'hours'
              ),
            name: '',
            expireBy: filtered[i].proposedSchedule[j].end,
            details: {
              manufacturingDateTime: {
                start: filtered[i].proposedSchedule[j].start,
                end: filtered[i].proposedSchedule[j].end,
              },
            },
          };
          newMv.manufacturingVoucherItems.push(mvi as never);
          // eslint-disable-next-line no-await-in-loop
          await set((newMv as unknown) as ManufacturingVoucher);
          incrementProgress(100 / count);
        }
        close();
      }
    };
    return (
      <div className='px-2'>
        <div>
          {filtered.map((f) => (
            <ul className='list-disc'>
              {f.proposedSchedule.map((p) => (
                <li className='bg-zinc-100 p-1 mb-1'>
                  {p.title} {p.start} to {p.end}
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div>Above manufacturing vouchers will be created</div>
        <div>
          {progress === 0 ? (
            <LoadingButton
              defaultStyle='bg-blue-500 rounded shadow hover:bg-blue-600'
              behaviorFn={onProposeConfirm}
              text='Continue'
            />
          ) : (
            <div className='w-full bg-gray-200 rounded-full h-1.5 my-4 dark:bg-gray-700'>
              <div
                className='bg-blue-600 h-1.5 rounded-full dark:bg-blue-500'
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
        </div>
      </div>
    );
  };
};
