import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import {
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseCombinedRole } from '../../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseCombinedRevision } from '../../../../hooks/inventory/revision/use-revision';
import { BomFlowInterface } from '../../../../models/interfaces/inventory/bom-flow';
import { GradeInterface } from '../../../../models/interfaces/inventory/grade';
import { renderProperties } from '../../properties/properties';
import { renderBomChartComponent } from './bom-chart';

export function renderGrade({
  useCombinedGrade,
  bomFlowService,
  useCurrentUserRoles,
  useCombinedGST,
  useCombinedMetric,
  gradeService,
  useCombinedRevision,
  useFileTransfer,
  useCombinedRole,
  useCombinedUser,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
}: {
  useCombinedGrade: UseCombinedGrade;
  bomFlowService: BomFlowInterface;
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedColor: UseCombinedColor;
  useCombinedOdour: UseCombinedOdour;
  useCurrentUserRoles: UseCurrentUserRoles;
  gradeService: GradeInterface;
  useCombinedRevision: UseCombinedRevision;
  useFileTransfer: UseFileTransfer;
  useCombinedRole: UseCombinedRole;
  useCombinedUser: UseCombinedUser;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}): () => JSX.Element {
  const Card = renderCardComponent();
  const BomChart = renderBomChartComponent({
    bomFlowService,
  });
  return function Grade(): JSX.Element {
    const { id } = useParams();
    const { syncSet: setRevision } = useCombinedRevision();
    const {
      resource: grade,
      get: getGrade,
      syncSet: setGrade,
    } = useCombinedGrade();

    useEffect(() => {
      if (id) {
        getGrade(id);
      }
      // eslint-disable-next-line
    }, [id]);

    const Tabs = renderTabsV2Component();

    const Properties = renderProperties<Grade>({
      useCombinedMetric,
      useCombinedGST,
      useCombinedAppearance,
      useCombinedColor,
      useCombinedOdour,
      useCurrentUserRoles,
      useCombinedRevision,
      useFileTransfer,
      useCombinedRole,
      useCombinedUser,
      useCombinedEmployeeProfile,
    });

    const tabs = [
      {
        name: 'properties',
        Fn: () =>
          grade ? (
            <Properties
              renderOptions={{
                renderAuth: true,
                renderAdd: false,
                renderEditValue: true,
              }}
              entityService={gradeService}
              entity={grade}
              setEntity={setGrade}
              type='grade'
              setRevision={setRevision}
            />
          ) : (
            <></>
          ),
      },
      {
        name: 'bom-charts',
        Fn: () => <BomChart gradeId={grade?.id || ''} />,
      },
    ];

    return (
      <div>
        <Card
          header={{
            title: (
              <>
                <span>{grade?.name || id}</span>{' '}
                <span className='italic font-thin'>
                  version {grade?.version || '0.0.0'}
                </span>
              </>
            ),
            subheading: grade?.item?.name,
          }}
          body={{
            type: 'jsx-component',
            body: <Tabs data={tabs} manipulateLocation={true} />,
          }}
        />
      </div>
    );
  };
}
