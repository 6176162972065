/* eslint-disable no-unused-vars */
import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedActionAdmin } from '../../../hooks/admin/action-admin/use-action-admin';
import { UseCombinedAuthorization } from '../../../hooks/admin/authorization/use-authorization';
import { UseCombinedLocationAdmin } from '../../../hooks/admin/location-admin/use-location';
import { UseCombinedNotificationAction } from '../../../hooks/admin/notification-admin/use-authorizations';
import { UseCombinedRole } from '../../../hooks/admin/role-admin/use-roles-admin';

export type RenderEditRoleFormProps = {
  useCombinedRole: UseCombinedRole;
  useCombinedNotificationAction: UseCombinedNotificationAction;
  useCombinedAuthorization: UseCombinedAuthorization;
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedActionAdmin: UseCombinedActionAdmin;
};

export function renderEditRoleForm({
  useCombinedRole,
  useCombinedActionAdmin,
  useCombinedLocationAdmin,
  useCombinedAuthorization,
  useCombinedNotificationAction,
}: RenderEditRoleFormProps): (RoleType) => JSX.Element {
  return function EditRoleForm({
    r,
    onClose,
  }: {
    r: RoleType;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setRoleAdmin } = useCombinedRole();

    const saveRole = async (form: RoleForm) => {
      if (r.id) form['id'] = r.id;
      await setRoleAdmin((form as unknown) as RoleType);
      onClose();
    };

    type RoleForm = {
      name: string;
      description: string;
      notificationActions: { id: string; name: string }[];
      authorizations: { id: string; name: string }[];
      locations: { id: string; name: string }[];
    };

    const formData: FormProps<RoleForm> = {
      initialFormState: {
        name: r.name,
        description: r.description,
        notificationActions: (r as any).notificationActions
          ? (((r as any).notificationActions.map((na) => ({
              id: na.id,
              name: na.name,
            })) as unknown) as any)
          : [],
        authorizations: r.authorizations
          ? ((r.authorizations.map((a) => ({
              id: a.id,
              name: a.name,
            })) as unknown) as any)
          : [],
        locations: r.locations
          ? ((r.locations.map((a) => ({
              id: a.id,
              name: a.name,
            })) as unknown) as any)
          : [],
      },
      fieldsData: [
        { type: 'input', label: 'Name', property: 'name', required: true },
        {
          type: 'input',
          label: 'Description',
          property: 'description',
          required: true,
        },
        {
          type: 'searchable-multi-select',
          label: 'Notification actions',
          property: 'notificationActions',
          searchOptions: {
            useSearch: useCombinedNotificationAction,
            onSearchValueSelect: () => {},
          },
        },
        {
          type: 'searchable-multi-select',
          label: 'Actions (Authorizations)',
          property: 'authorizations',
          searchOptions: {
            useSearch: useCombinedAuthorization,
            onSearchValueSelect: () => {},
          },
        },
        {
          type: 'searchable-multi-select',
          label: 'Locations',
          property: 'locations',
          searchOptions: {
            useSearch: useCombinedLocationAdmin,
            onSearchValueSelect: () => {},
          },
        },
      ],
      mapTToU(p) {
        return ({ ...p } as unknown) as RoleForm;
      },
      onSubmit: saveRole,
    };

    const Form = renderFormV2(formData);

    return <Form />;
  };
}
