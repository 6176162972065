import { OffSiteWorkHoursType } from '@erp_core/erp-types/dist/modules/hrd';
import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedEmployeeProfile } from '../../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedOffSiteWorkHour } from '../../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import { calculateDateRange } from '../../util/calculate-date-range';
import { validateTimeFormat } from '../../util/validateTime';

export type BulkOffSiteWorkHoursFormType = {
  employee: IdName;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  reason: string;
};

type RenderBulkOffSiteWorkHoursForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedOffSiteWorkHour: UseCombinedOffSiteWorkHour;
};

export const renderBulkOffSiteWorkHoursForm = ({
  useCombinedEmployeeProfile,
  useCombinedOffSiteWorkHour,
}: RenderBulkOffSiteWorkHoursForm) => {
  return function OffSiteWorkHoursForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (d: BulkOffSiteWorkHoursFormType) => Promise<void>;
    isEdit?: boolean;
    data?: OffSiteWorkHoursType;
  }) {
    const { getListSync: getOffSite } = useCombinedOffSiteWorkHour();

    const validateOffSite = async (form: BulkOffSiteWorkHoursFormType) => {
      if (isEdit) return '';
      if (!form.startDate) return '';
      if (!form.endDate) return '';
      if (form.endDate < form.startDate)
        return 'End date cannot be less than start date';
      if (!form.employee) return '' as any;
      const offSite = await getOffSite({ crossGroup: 'true' } as any, {
        enableLoading: false,
        enableResource: false,
      });
      const dateRange = calculateDateRange(form.startDate, form.endDate);
      const exists = offSite?.find(
        (o) => o.employee.id === form.employee.id && dateRange.includes(o.date)
      );
      if (!exists) return '';
      return `${form.employee.name} is already on off-site for ${exists.date}`;
    };

    const formProps: FormProps<BulkOffSiteWorkHoursFormType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          label: 'Employee Name',
          required: true,
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            filter: { crossGroup: 'true' },
            onSearchValueSelect: () => {},
          },
          readonly: isEdit,
          validate: async (fd: any) => {
            const resp = await validateOffSite(fd);
            return {
              employee: resp,
              date: resp,
            };
          },
        },
        {
          property: 'startDate',
          type: 'date',
          label: 'Start Date ',
          required: true,
        },
        {
          property: 'endDate',
          type: 'date',
          label: 'End Date ',
          required: true,
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
        {
          property: 'startTime',
          type: 'input',
          label: 'Start Time ',
          required: true,
          validate: async (fd: any) => {
            return {
              startTime: validateTimeFormat(fd.startTime),
            };
          },
        },
        {
          property: 'endTime',
          type: 'input',
          label: 'End Time',
          required: true,
          validate: async (fd: any) => {
            return {
              endTime: validateTimeFormat(fd.endTime, fd.startTime),
            };
          },
        },
      ],
      initialFormState: {
        employee: data?.employee || '',
        startDate: data?.date || '',
        endDate: data?.date || '',
        startTime: data?.startTime || '',
        endTime: data?.endTime || '',
        reason: data?.reason || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<BulkOffSiteWorkHoursFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<BulkOffSiteWorkHoursFormType>
    ): BulkOffSiteWorkHoursFormType {
      return b as BulkOffSiteWorkHoursFormType;
    }
  };
};
