import { QuestionsType } from '@erp_core/erp-types/dist/modules/utility';
import { TopicType } from '@erp_core/erp-types/dist/types/modules/questionnaire/topic';
import {
  createUseCombinedResource,
  createUseResource,
  createUseResources,
} from '@erp_core/erp-ui-components';
import { QuestionHttpRepo } from '../datalayer/repository/http/hrd/questionnaire/questionnaire-question';
import { SurveyAnswersHttpRepo } from '../datalayer/repository/http/hrd/questionnaire/questionnaire-survey-answers';
import { QuestionnaireTopicHttpRepo } from '../datalayer/repository/http/hrd/questionnaire/questionnaire-topic';
import { QuestionsHttpRepo } from '../datalayer/repository/http/questionnaire/questions';
import { TopicHttpRepo } from '../datalayer/repository/http/questionnaire/topic';
import {
  QuestionFilter,
  QuestionInterface,
} from '../models/interfaces/hrd/questionnaire/question';
import {
  SurveyAnswersFilter,
  SurveyAnswersInterface,
} from '../models/interfaces/hrd/questionnaire/survey-answers';
import {
  QuestionnaireTopicFilter,
  QuestionnaireTopicInterface,
} from '../models/interfaces/hrd/questionnaire/topic';
import {
  QuestionsFilter,
  QuestionsInterface,
} from '../models/interfaces/questionnaire/questions';
import {
  TopicFilter,
  TopicInterface,
} from '../models/interfaces/questionnaire/topic';
import { QuestionType } from '../models/types/questionnairetopic/question';
import { SurveyAnswersType } from '../models/types/questionnairetopic/survey-answers';
import { QuestionnaireTopicType } from '../models/types/questionnairetopic/topic';
import { erpV2Api } from './admin';
import { QUESTIONNAIRE_SERVER_URI } from './server-uri';

export const topicService = new TopicHttpRepo(
  QUESTIONNAIRE_SERVER_URI,
  erpV2Api
);
export const useCombinedTopic = createUseCombinedResource<
  TopicType,
  TopicFilter,
  TopicInterface
>({
  useService: () => topicService,
});

export const questionsService = new QuestionsHttpRepo(
  QUESTIONNAIRE_SERVER_URI,
  erpV2Api
);
export const useQuestions = createUseResources<
  QuestionsType,
  QuestionsFilter,
  QuestionsInterface
>({
  useService: () => questionsService,
});
export const useQuestion = createUseResource<
  QuestionsType,
  QuestionsFilter,
  QuestionsInterface
>({
  useService: () => questionsService,
});

export const questionnaireTopicService = new QuestionnaireTopicHttpRepo(
  QUESTIONNAIRE_SERVER_URI,
  erpV2Api
);

export const useCombinedQuestionnaireTopic = createUseCombinedResource<
  QuestionnaireTopicType,
  QuestionnaireTopicFilter,
  QuestionnaireTopicInterface
>({
  useService: () => questionnaireTopicService,
});

export const questionnaireQuestionService = new QuestionHttpRepo(
  QUESTIONNAIRE_SERVER_URI,
  erpV2Api
);
export const useCombinedQuestionnaireQuestion = createUseCombinedResource<
  QuestionType,
  QuestionFilter,
  QuestionInterface
>({
  useService: () => questionnaireQuestionService,
});

export const questionnaireSurveyAnswerService = new SurveyAnswersHttpRepo(
  QUESTIONNAIRE_SERVER_URI,
  erpV2Api
);

export const useCombinedQuestionnaireSurveyAnswer = createUseCombinedResource<
  SurveyAnswersType,
  SurveyAnswersFilter,
  SurveyAnswersInterface
>({
  useService: () => questionnaireSurveyAnswerService,
});
