import {
  AdvancedLoadingButton,
  ModalProps,
  renderCardComponent,
  renderFormV2,
  renderModal,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { CogIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { renderPerformAuction } from './auction';
import { Player, PlayerSkills, Team, Tournament } from './data';
import { PlayerCard } from './player-card';
import { AuctionRoulette } from './player-selector';

export function renderAuction() {
  const PerformAuction = renderPerformAuction();
  return function Auction() {
    const Modal = renderModal();
    const [tab, setTab] = useState<'teams' | 'players'>('teams');
    const [tournament, setTournament] = useState<Tournament>(
      JSON.parse(localStorage.getItem('auction') || JSON.stringify({}))
    );
    const [modalState, setModalState] = useState<ModalProps>({
      isVisible: false,
      body: <></>,
      title: '',
      onClose: () => {
        setModalState((ms) => ({ ...ms, visible: false }));
      },
      size: 'large',
    });

    useEffect(() => {
      if (!localStorage.getItem('auction')) {
        new Promise((resolve) => resolve(true)).then(async () => {
          const tmt: Tournament = {
            name: 'Happy Cup 25',
            description: 'Annual Cricket Tournament',
            auction: {
              range: [
                { from: 0, to: 500000, increment: 25000, bonanza: 50000 },
                {
                  from: 500000,
                  to: 10000000,
                  increment: 50000,
                  bonanza: 100000,
                },
              ],
            },
            organizer: {
              name: 'Sailesh Chhadva',
              photo:
                'https://upload.wikimedia.org/wikipedia/commons/thumb/3/39/JayAmitbhaishah.jpg/220px-JayAmitbhaishah.jpg',
            },
            venue: 'Borivali West',
            logo: '/auction-logo.png',
            auctionDate: '08 December, 2024',
            teamBudget: {
              overall: 10000000,
              categoryWise: [
                {
                  name: 'A+',
                  maximumPlayers: 1,
                  maximumBudget: 2500000,
                  baseValue: 1000000,
                },
                {
                  name: 'A',
                  maximumPlayers: 1,
                  maximumBudget: 1700000,
                  baseValue: 700000,
                },
                {
                  name: 'B',
                  maximumPlayers: 2,
                  maximumBudget: 0,
                  baseValue: 500000,
                },
                {
                  name: 'C',
                  maximumPlayers: 5,
                  maximumBudget: 0,
                  baseValue: 200000,
                },
              ],
            },
            teams: [
              {
                name: 'Instarakshak',
                logo: '/unknown-team.png',

                // 'https://documents.iplt20.com/ipl/CSK/logos/Logooutline/CSKoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Apurva K Sarupria',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'SEVENUS STARS',
                logo: '/unknown-team.png',
                // 'https://documents.iplt20.com/ipl/GT/Logos/Logooutline/GToutline.png',
                description: 'GT',
                owner: {
                  name: 'Bajrang',
                  photo: '',
                  description: 'Owner of GT',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Venus Super Kings',
                logo: '/unknown-team.png',
                // 'https://documents.iplt20.com/ipl/MI/Logos/Logooutline/MIoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Dhaval',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'MumbaiMervicks',
                logo: '/unknown-team.png',
                // 'https://documents.iplt20.com/ipl/RCB/Logos/Logooutline/RCBoutline.png',
                description: 'RCB',
                owner: {
                  name: 'Dharmendra',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Elsyian Spaces',
                logo: '/unknown-team.png',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Hardik',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'BHARATNEXT',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Bakulbhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'LIGHTUP Darkness',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Jay',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'ESTRELLA',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Munjal',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Travelsmith',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Rasikbhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Ortus Gladiators',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Piyush',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'MD ROCKERS',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Rajesh',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Happy VIP',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Sachin / JK',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'VCR monopoly fighter',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Jitendra bhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },

              {
                name: 'Supreme Spartans ',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Sudeep bhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'Titan Tigers',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Manjeet bhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
              {
                name: 'EnovateIT',
                logo: '',
                // 'https://documents.iplt20.com/ipl/KKR/Logos/Logooutline/KKRoutline.png',
                description: 'CSK',
                owner: {
                  name: 'Amarish bhai',
                  photo: '',
                  description: 'Owner of XYZ',
                },
                purse: {
                  spent: 0,
                  remaining: 10000000,
                },
                players: [],
              },
            ],
            players: await getPlayers(),
            // players: [
            //   {
            //     name: 'Sachin Tendulkar',
            //     description: 'God of Cricket',
            //     category: 'gold',
            //     photo: '',
            //     skills: 'allrounder',
            //     basePrice: 10000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Virat Kohli',
            //     description: 'God of Cricket',
            //     category: 'gold',
            //     photo: 'https://documents.iplt20.com/ipl/IPLHeadshot2024/2.png',
            //     skills: 'batsman',
            //     basePrice: 10000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Jasprit Bumrah',
            //     description: 'God of Cricket',
            //     category: 'gold',
            //     photo: 'https://documents.iplt20.com/ipl/IPLHeadshot2024/9.png',
            //     skills: 'bowler',
            //     basePrice: 10000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Rishabh Pant',
            //     description: 'God of Cricket',
            //     category: 'silver',
            //     photo: '',
            //     skills: 'keeper',
            //     basePrice: 5000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Ravichandran Ashwin',
            //     description: 'God of Cricket',
            //     category: 'silver',
            //     photo: '',
            //     skills: 'bowler',
            //     basePrice: 5000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Hardik Pandya',
            //     description: 'God of Cricket',
            //     category: 'gold',
            //     photo: 'https://documents.iplt20.com/ipl/IPLHeadshot2024/54.png',
            //     skills: 'allrounder',
            //     basePrice: 10000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Kuldip Yadav',
            //     description: 'God of Cricket',
            //     category: 'bronze',
            //     photo: '',
            //     skills: 'bowler',
            //     basePrice: 0,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Tilak Varma',
            //     description: 'God of Cricket',
            //     category: 'bronze',
            //     photo: 'https://documents.iplt20.com/ipl/IPLHeadshot2024/993.png',
            //     skills: 'batsman',
            //     basePrice: 0,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            //   {
            //     name: 'Mahendra Singh Dhoni',
            //     description: 'God of Cricket',
            //     category: 'gold',
            //     photo: 'https://documents.iplt20.com/ipl/IPLHeadshot2024/57.png',
            //     skills: 'keeper',
            //     basePrice: 10000000,
            //     status: 'pending',
            //     team: '',
            //     soldAt: 0,
            //     bidActivities: [],
            //   },
            // ],
          };

          setTournament(tmt);
        });
      }
    }, []);
    const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
    // const [selectedPlayerCategory, setSelectedPlayerCategory] = useState<PlayerCategory | null>(null);
    const [filter, setFilter] = useState<string>('');
    const [playerList, setPlayerList] = useState<{
      batsmen: Array<Player>;
      bowlers: Array<Player>;
      keepers: Array<Player>;
      allrounders: Array<Player>;
    }>({
      batsmen: [],
      bowlers: [],
      keepers: [],
      allrounders: [],
    });

    function saveTournament(trnmt: Tournament) {
      setTournament(trnmt);
      localStorage.setItem('auction', JSON.stringify(trnmt));
    }

    useEffect(() => {
      if (tab === 'teams') {
        // setSelectedPlayerCategory(null);
      }

      if (tab === 'players') {
        setSelectedTeam(null);
      }
      setPlayerList({
        batsmen: [],
        bowlers: [],
        keepers: [],
        allrounders: [],
      });
    }, [tab]);

    const playersFilter = [
      {
        name: 'All Players',
        filter: () => {
          return tournament.players;
        },
      },
      {
        name: 'Pending',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'pending');
        },
      },
      {
        name: 'Sold Players',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'sold');
        },
      },
      {
        name: 'UnSold Players',
        filter: () => {
          return tournament.players.filter((x) => x.status === 'unsold');
        },
      },
      {
        name: 'A+ Category',
        filter: () => {
          return tournament.players.filter((x) => x.category === 'A+');
        },
      },
      {
        name: 'A Category',
        filter: () => {
          return tournament.players.filter((x) => x.category === 'A');
        },
      },
      {
        name: 'B Category',
        filter: () => {
          return tournament.players.filter((x) => x.category === 'B');
        },
      },
      {
        name: 'C Category',
        filter: () => {
          return tournament.players.filter((x) => x.category === 'C');
        },
      },
    ];

    useEffect(() => {
      if (selectedTeam) {
        const players = tournament.players.filter(
          (x) => x.status === 'sold' && x.team === selectedTeam.name
        );
        setPlayerList({
          batsmen: players.filter((y) => y.skills === 'batsman'),
          bowlers: players.filter((y) => y.skills === 'bowler'),
          allrounders: players.filter((y) => y.skills === 'allrounder'),
          keepers: players.filter((y) => y.skills === 'keeper'),
        });
      }
    }, [selectedTeam, tournament.players]);

    async function selectPlayer(sp: { player: Player }) {
      console.log('Player', sp.player.name, 'is selected');
      setModalState({
        size: 'large',
        isVisible: true,
        title: `Player ${sp.player.name} is selected`,
        body: (
          <div>
            <PerformAuction
              player={sp.player}
              tournament={tournament}
              onClose={() => {
                setModalState((ms) => ({ ...ms, visible: false }));
              }}
              onSold={() => {}}
              onUnsold={() => {}}
            />
          </div>
        ),
        onClose: () => {
          setModalState((ms) => ({ ...ms, visible: false }));
        },
      });
    }

    return (
      <div className='bg-black w-full h-screen text-white'>
        <div className='bg-gray-700 my-2 mx-2 h-500'>
          <div className='flex flex-wrap'>
            <div className='w-full lg:w-1/4'>
              <div className='m-1 bg-gray-800'>
                <img
                  src={tournament.logo}
                  alt=''
                  className='h-48 mx-auto animate-hflip'
                />
                <div className='text-4xl text-center text-yellow-500'>
                  {tournament.name}
                  <AdvancedLoadingButton
                    text={<CogIcon className='h-8 inline animate-none' />}
                    defaultStyle='p-0 bg-none'
                    behaviour='modal'
                    modal={{
                      size: 'normal',
                      title: 'Edit Tournament',
                      content: () => {
                        const Card = renderCardComponent();
                        const Table = renderTableComponent();

                        return (
                          <div className='text-sm text-black'>
                            <Card
                              header={{
                                title: 'Bid Increment',
                                actions: [
                                  {
                                    type: 'button',
                                    button: {
                                      name: 'Add',
                                      behaviour: 'click',
                                      onClick: async () => {
                                        const newTour = { ...tournament };
                                        newTour.auction.range.push({
                                          from: 0,
                                          to: 0,
                                          increment: 0,
                                          bonanza: 0,
                                        });
                                        saveTournament(newTour);
                                      },
                                    },
                                  },
                                ],
                              }}
                              body={{
                                type: 'jsx-component',
                                body: (
                                  <Table
                                    header={[
                                      [
                                        { name: 'From' },
                                        { name: 'To' },
                                        { name: 'Increment By' },
                                        { name: 'Bonanza Bid' },
                                      ],
                                    ]}
                                    body={(
                                      tournament.auction?.range || []
                                    )?.map((x, idx) => {
                                      return {
                                        rowData: { range: x, idx: idx },
                                        cells: [
                                          { value: x.from },
                                          { value: x.to },
                                          { value: x.increment },
                                          { value: x.bonanza },
                                        ],
                                      };
                                    })}
                                    actions={[
                                      {
                                        name: 'Edit',
                                        behaviour: 'modal',
                                        show: () => true,
                                        modal: {
                                          title: 'Edit Range',
                                          content: ({
                                            data: { range, idx },
                                            onClose,
                                          }) => {
                                            const Form = renderFormV2({
                                              initialFormState: {
                                                from: range.from,
                                                to: range.to,
                                                increment: range.increment,
                                                bonanza: range.bonanza,
                                              },
                                              fieldsData: [
                                                {
                                                  property: 'from',
                                                  type: 'number',
                                                  required: true,
                                                },
                                                {
                                                  property: 'to',
                                                  type: 'number',
                                                  required: true,
                                                },
                                                {
                                                  property: 'increment',
                                                  type: 'number',
                                                  required: true,
                                                },
                                                {
                                                  property: 'bonanza',
                                                  type: 'number',
                                                  required: true,
                                                },
                                              ],
                                              onSubmit: async (form) => {
                                                const newTour = {
                                                  ...tournament,
                                                };
                                                newTour.auction.range[idx] = {
                                                  from:
                                                    form.from ||
                                                    range[idx].from,
                                                  to: form.to || range[idx].to,
                                                  increment:
                                                    form.increment ||
                                                    range[idx].increment,
                                                  bonanza:
                                                    form.bonanza ||
                                                    range[idx].bonanza,
                                                };

                                                console.log(newTour);
                                                saveTournament(newTour);
                                                onClose();
                                              },
                                              mapTToU: (x) => x,
                                            });
                                            return <Form />;
                                          },
                                        },
                                      },
                                    ]}
                                  />
                                ),
                              }}
                            />
                          </div>
                        );
                      },
                    }}
                  />
                </div>
                <div className='text-center animate-pulse'>
                  <div className='text-lg'>Organized By</div>
                  <div className='text-xl'>{tournament.organizer?.name}</div>
                </div>
              </div>
            </div>
            <div className='w-full lg:w-3/4'>
              <div className='bg-gray-800'>
                <div className='flex justify-center'>
                  <div
                    className='border border-gray-700 rounded-lg p-2 text-center w-64 m-1'
                    onClick={() => {
                      if (tab !== 'teams') {
                        setTab('teams');
                      }
                    }}
                  >
                    Teams
                  </div>
                  <div
                    className='border border-gray-700 rounded-lg py-2 text-center w-64 m-1'
                    onClick={() => {
                      if (tab !== 'players') {
                        setTab('players');
                      }
                    }}
                  >
                    Players
                  </div>
                </div>
                {tab === 'teams' ? (
                  <>
                    <div className='flex flex-wrap justify-center'>
                      {tournament.teams?.map((x, idx) => (
                        <div
                          key={idx}
                          className={`cursor-pointer w-1/4 lg:w-1/6 ${
                            selectedTeam?.name === x.name
                              ? 'bg-gray-700'
                              : 'bg-gray-900'
                          }`}
                          onClick={() => {
                            setSelectedTeam(x);
                          }}
                        >
                          <div className='text-center border border-gray-700 rounded-md hover:bg-black'>
                            <img
                              src={x.logo || '/unknown-team.png'}
                              alt=''
                              className='h-16 mx-auto hover:scale-105'
                            />
                            <div className='text-2xl text-center'>{x.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {selectedTeam ? (
                      <div className='bg-gray-900 mx-24'>
                        <div className='flex justify-center items-center'>
                          <div className='bg-gray-700 [background:linear-gradient(45deg,#172033,theme(colors.slate.800)_50%,#172033)_padding-box,conic-gradient(from_var(--border-angle),theme(colors.slate.600/.48)_80%,_theme(colors.yellow.500)_86%,_theme(colors.yellow.300)_90%,_theme(colors.yellow.500)_94%,_theme(colors.slate.600/.48))_border-box] rounded-full border border-transparent animate-border rounded-lg'>
                            <img
                              alt=''
                              src={selectedTeam.logo || '/unknown-team.png'}
                              className='h-48 mx-auto'
                            />
                          </div>
                          <div className='text-center mr-8'>
                            <div className='text-4xl text-yellow-500'>
                              {selectedTeam.name}
                            </div>
                            <div className='text-2xl'>
                              Owner: {selectedTeam.owner.name}
                            </div>
                            <div className='text-lg'>
                              Auction: {selectedTeam.players.length} players for{' '}
                              {selectedTeam.purse.spent}
                            </div>
                            <div className='text-lg animate-pulse'>
                              Funds Remaining: {selectedTeam.purse.remaining}
                            </div>
                          </div>
                          <div>
                            <img
                              alt=''
                              src={
                                selectedTeam.owner.photo ||
                                'https://www.iplt20.com/assets/images/default-headshot.png'
                              }
                              className='h-28 mx-auto rounded-full'
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                {tab === 'players' ? (
                  <>
                    <div className='flex flex-wrap justify-center my-5'>
                      {playersFilter.map((x, idx) => (
                        <div
                          key={idx}
                          className='bg-gray-900 hover:bg-black w-1/4'
                          onClick={() => {
                            const players = x.filter();
                            setFilter(x.name);
                            setPlayerList({
                              batsmen: players.filter(
                                (y) => y.skills === 'batsman'
                              ),
                              bowlers: players.filter(
                                (y) => y.skills === 'bowler'
                              ),
                              allrounders: players.filter(
                                (y) => y.skills === 'allrounder'
                              ),
                              keepers: players.filter(
                                (y) => y.skills === 'keeper'
                              ),
                            });
                          }}
                        >
                          <div
                            className={`border border-gray-700 rounded-md m-2 text-center ${
                              x.name === filter
                                ? 'border border-yellow-500'
                                : ''
                            }`}
                          >
                            <div className='text-2xl text-center'>{x.name}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {playerList.batsmen.length ||
        playerList.bowlers.length ||
        playerList.keepers.length ||
        playerList.allrounders.length ? (
          <div className='min-h-96 m-2 p-10 text-black'>
            <div className='flex justify-center'>
              {[
                ...playerList.allrounders.filter((x) => x.status === 'pending'),
                ...playerList.batsmen.filter((x) => x.status === 'pending'),
                ...playerList.bowlers.filter((x) => x.status === 'pending'),
                ...playerList.keepers.filter((x) => x.status === 'pending'),
              ].length ? (
                <AdvancedLoadingButton
                  text='Sell Player'
                  behaviour='modal'
                  modal={{
                    title: 'Sell Player',
                    size: 'large',
                    content: ({ onClose }) => {
                      return (
                        <AuctionRoulette
                          onPlayerSelect={selectPlayer}
                          tournament={tournament}
                          onClose={onClose}
                          teams={tournament.teams}
                          players={[
                            ...playerList.allrounders.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.batsmen.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.bowlers.filter(
                              (x) => x.status === 'pending'
                            ),
                            ...playerList.keepers.filter(
                              (x) => x.status === 'pending'
                            ),
                          ]}
                          onSold={({ player, bid }) => {
                            const p = tournament.players.find((x) => {
                              return x.name === player.name;
                            });
                            const t = tournament.teams.find(
                              (x) => x.name === bid.topBidder
                            );

                            if (p && t) {
                              p.soldAt = bid.value;
                              p.status = 'sold';
                              p.team = bid.topBidder;
                              p.bidActivities = bid.activities;
                              t.purse.spent = t.purse.spent + bid.value;
                              t.purse.remaining = t.purse.remaining - bid.value;
                            }

                            saveTournament({ ...tournament });
                          }}
                          onUnsold={({ player }) => {
                            const p = tournament.players.find((x) => {
                              return x.name === player.name;
                            });

                            if (p) {
                              p.status = 'unsold';
                            }

                            saveTournament({ ...tournament });
                          }}
                        />
                      );
                    },
                  }}
                />
              ) : null}
            </div>
            <div className='flex'>
              <div className='w-1/4'>
                <div className='mx-2 bg-gray-100 '>
                  <div className='text-center text-4xl'>
                    Batsmen
                    <img
                      alt=''
                      src='https://www.iplt20.com/assets/images/teams-batsman-icon.svg'
                      className='inline bg-gray-200 p-1 rounded-full'
                    />
                  </div>
                  <div>
                    {playerList.batsmen.map((x) => (
                      <div className='bg-gray-800'>
                        <PlayerCard
                          selectPlayer={selectPlayer}
                          player={x}
                          teams={tournament.teams}
                          size='small'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='w-1/4'>
                <div className='mx-2 bg-gray-100'>
                  <div className='text-center  text-4xl'>
                    Bowlers
                    <img
                      alt=''
                      src='https://www.iplt20.com/assets/images/teams-bowler-icon.svg'
                      className='inline bg-gray-200 p-1 rounded-full'
                    />
                  </div>
                  <div>
                    {playerList.bowlers.map((x) => (
                      <div className='bg-gray-800'>
                        <PlayerCard
                          selectPlayer={async (p) => {
                            console.log(p);
                          }}
                          player={x}
                          teams={tournament.teams}
                          size='small'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='w-1/4'>
                <div className='mx-2 bg-gray-100'>
                  <div className='text-center  text-4xl'>
                    Wicket Keeper
                    <img
                      alt=''
                      src='https://www.iplt20.com/assets/images/teams-wicket-keeper-icon.svg'
                      className='inline bg-gray-200 p-1 rounded-full'
                    />
                  </div>
                  <div>
                    {playerList.keepers.map((x) => (
                      <div className='bg-gray-800'>
                        <PlayerCard
                          selectPlayer={async (p) => {
                            console.log(p);
                          }}
                          player={x}
                          teams={tournament.teams}
                          size='small'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='w-1/4'>
                <div className='mx-2 bg-gray-100'>
                  <div className='text-center  text-4xl'>
                    Allrounder
                    <img
                      alt=''
                      src='https://www.iplt20.com/assets/images/teams-all-rounder-icon.svg'
                      className='inline bg-gray-200 p-1 rounded-full'
                    />
                  </div>
                  <div>
                    {playerList.allrounders.map((x) => (
                      <div className='bg-gray-800'>
                        <PlayerCard
                          selectPlayer={async (p) => {
                            console.log(p);
                          }}
                          player={x}
                          teams={tournament.teams}
                          size='small'
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isVisible={modalState.isVisible}
              size={modalState.size}
              title={modalState.title}
              body={modalState.body}
              onClose={modalState.onClose}
            />
          </div>
        ) : null}
      </div>
    );
  };
}

async function getPlayers(): Promise<Player[]> {
  const players: Player[] = await axios.get('/players.json').then((res) => {
    console.log(res);
    return res.data.map((x) => {
      return {
        name: `${x['First Name']} ${x['Last Name']}`,
        photo: '',
        category: x.Category,
        description: '',
        skills: getSkills(x),
        status: 'pending',
        team: '',
        basePrice: getBasePrice(x),
        soldAt: 0,
        bidActivities: [],
      };
    });
  });

  return players;
}

function getSkills(x): PlayerSkills {
  const role = x['What is your primary role in the team?'];
  if (role === 'Batsman') {
    return 'batsman';
  }

  if (role === 'All-rounder') {
    return 'allrounder';
  }

  return 'bowler';
}

function getBasePrice(x) {
  const categoryWise = [
    {
      name: 'A+',
      maximumPlayers: 1,
      maximumBudget: 2500000,
      baseValue: 1000000,
    },
    {
      name: 'A',
      maximumPlayers: 1,
      maximumBudget: 1700000,
      baseValue: 700000,
    },
    {
      name: 'B',
      maximumPlayers: 2,
      maximumBudget: 0,
      baseValue: 500000,
    },
    {
      name: 'C',
      maximumPlayers: 5,
      maximumBudget: 0,
      baseValue: 200000,
    },
  ];
  const cat = categoryWise.find((y) => x.Category === y.name);
  if (cat) {
    return cat.baseValue;
  }

  return 0;
}
