import { Clock } from '@erp_core/erp-icons/icons/web/clock';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { OffSiteWorkHoursType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  MonthSelector,
  MultiSelect,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
  useConfirm,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { UserContext } from '../../../../contexts/user';
import { UseCurrentUserRoles } from '../../../../hooks/admin/role-admin/use-current-user-roles';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedOffSiteWorkHour } from '../../../../hooks/hrd/off-site-work-hours/use-off-site-work-hour';
import {
  employeeIconMapperByGrade,
  employeeLeaveStatusMapper,
} from '../../../../utils/common';
import { UserRendererInterface } from '../../../common/fragments/user';
import { calculateDateRange } from '../util/calculate-date-range';
import {
  BulkOffSiteWorkHoursFormType,
  renderBulkOffSiteWorkHoursForm,
} from './forms/add-bulk-off-site-work-hours';
import {
  OffSiteWorkHoursFormType,
  renderOffSiteWorkHoursForm,
} from './forms/add-off-site-work-hours';
import { BulkOffSiteActionForm } from './forms/bulk-off-site-approval-form';

export type RenderOffSiteMonthWorkHours = {
  useCombinedOffSiteWorkHour: UseCombinedOffSiteWorkHour;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useUserAuthorization: UseUserAuthorization;
  userRendererService: UserRendererInterface;
  useCurrentUserRoles: UseCurrentUserRoles;
};

export function renderOffSiteMonthWorkHours({
  useCombinedOffSiteWorkHour,
  useCombinedEmployeeProfile,
  useUserAuthorization,
  userRendererService,
  useCurrentUserRoles,
}: RenderOffSiteMonthWorkHours): () => JSX.Element {
  return function OffSiteWorkHours(): JSX.Element {
    const { user: currentUser } = useContext(UserContext);
    const { data: currentUserRoles } = useCurrentUserRoles();

    const isSystemAdmin = currentUserRoles?.find(
      (r) => r.name === 'System-Admin'
    )
      ? true
      : false;
    const {
      list: OffSiteWorkHours,
      getAll: getOffSiteWorkHours,
      loading,
    } = useCombinedOffSiteWorkHour();
    const { syncSet: setOffSiteWorkHour } = useCombinedOffSiteWorkHour();
    const {
      list: employees,
      getAll: getAllEmployees,
    } = useCombinedEmployeeProfile();

    const { Confirm, openConfirm } = useConfirm();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {
      getAllEmployees({ crossGroup: 'true' });
      // eslint-disable-next-line
    }, []);

    const [date, setDate] = useState(
      localStorage.getItem('off-site-date') || moment.utc().format('YYYY-MM')
    );

    useEffect(() => {
      getOffSiteWorkHours({ date });
      localStorage.setItem('off-site-date', date);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    const OffSiteWorkHrsForm = renderOffSiteWorkHoursForm({
      useCombinedEmployeeProfile,
    });

    const BulkOffSiteWorkHrsForm = renderBulkOffSiteWorkHoursForm({
      useCombinedEmployeeProfile,
      useCombinedOffSiteWorkHour,
    });

    const saveEmpOffSiteWorkHrs = async (form: any) => {
      try {
        const finalData = {
          ...form,
          appliedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
          // time being fix as approvedBy nullable true didn't worked
          approvedBy: {
            id: currentUser.id,
            name: currentUser.name,
          },
        };
        await setOffSiteWorkHour(finalData);

        toast('Employee Off-site work hours added sucessfully');
        getOffSiteWorkHours({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const saveEmpBulkOffSiteWorkHrs = async (
      form: BulkOffSiteWorkHoursFormType
    ) => {
      try {
        const dateRange = calculateDateRange(form.startDate, form.endDate);
        for (let i = 0; i < dateRange.length; i++) {
          const finalData = ({
            ...form,
            date: dateRange[i],
            appliedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
            // time being fix as approvedBy nullable true didn't worked
            approvedBy: {
              id: currentUser.id,
              name: currentUser.name,
            },
          } as unknown) as OffSiteWorkHoursType;
          await setOffSiteWorkHour(finalData);
        }
        toast('Employee Off-site work hours added sucessfully');
        getOffSiteWorkHours({ date });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const editOffSiteWorkHrs = async (
      id: string,
      e: OffSiteWorkHoursFormType
    ) => {
      const final: Partial<OffSiteWorkHoursType> = { id, ...e };
      openConfirm({
        title: 'Off-site work hours will be edited, Click confirm to continue',
        onConfirm: async () => {
          await setOffSiteWorkHour(final as OffSiteWorkHoursType);
          getOffSiteWorkHours({ date });
        },
      });
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Off-Site Work Hours',
      icon: (
        <Clock className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      subheading: (
        <span className='text-blue-700 font-bold'>
          {moment(date).format('MMM YYYY')}
        </span>
      ),
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
              <span className='font-bold truncate'>Select month</span>
              <MonthSelector
                format='YYYY-MM'
                initialState={date}
                onChange={(m) => setDate(m)}
              />
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            name: 'Add off-site work hours',
            behaviour: 'modal',
            modal: {
              title: 'Add Employee Off Site Work Hours',
              content: ({ onClose }) => {
                return (
                  <OffSiteWorkHrsForm
                    onSave={async (form) => {
                      await saveEmpOffSiteWorkHrs(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
        {
          type: 'button',
          button: {
            name: 'Add bulk off-site work hours',
            behaviour: 'modal',
            modal: {
              title: 'Add Employee Bulk Off Site Hours',
              content: ({ onClose }) => {
                return (
                  <BulkOffSiteWorkHrsForm
                    onSave={async (form) => {
                      await saveEmpBulkOffSiteWorkHrs(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const approveOffsiteWorkHour = async (id: string) => {
      const finalData = {
        id,
        status: 'approved',
      };
      await setOffSiteWorkHour(finalData as any);
      toast('Approved sucessfully');
      getOffSiteWorkHours({ date });
    };

    const cancelOffsiteWorkHour = async (id: string) => {
      const finalData = {
        id,
        status: 'cancelled',
      };
      await setOffSiteWorkHour(finalData as any);
      toast('Cancelled sucessfully');
      getOffSiteWorkHours({ date });
    };

    const Table = renderTableComponent();

    const TableHeader: TableHeader = [
      [
        { name: 'Employee' },
        { name: 'Date', style: 'hidden md:table-cell' },
        {
          name: 'Start Time',
          style: 'hidden md:table-cell',
        },
        {
          name: 'End Time',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Issued By',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Approved By',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Reason',
          style: 'hidden md:table-cell',
        },
        {
          name: 'Status',
          style: 'hidden md:table-cell',
        },
      ],
    ];

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          show: ({
            offSiteWorkHour,
          }: {
            offSiteWorkHour: OffSiteWorkHoursType;
          }) => {
            // we want to give ability to edit only when the offSiteWorkHour
            // is in pending state.
            if (['pending'].includes(offSiteWorkHour.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { offSiteWorkHour },
              onClose,
            }: {
              data: {
                offSiteWorkHour: OffSiteWorkHoursType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <OffSiteWorkHrsForm
                    isEdit={true}
                    data={offSiteWorkHour as any}
                    onSave={(data) =>
                      editOffSiteWorkHrs(offSiteWorkHour.id, data)
                    }
                  />
                </div>
              );
            },
          },
        },
        {
          name: 'Approve',
          auth: 'UI:BTN-APRV-OFST:VIEW',
          show: ({
            offSiteWorkHour,
          }: {
            offSiteWorkHour: OffSiteWorkHoursType;
          }) => {
            if (['pending'].includes(offSiteWorkHour.status)) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ offSiteWorkHour }) => {
            return {
              title: 'Are you sure you want to approve Offsite Workhours?',
              message: `${offSiteWorkHour?.employee?.name} will be approved off-site work hours on{' '}
              ${offSiteWorkHour?.date} from ${offSiteWorkHour?.startTime} to ${offSiteWorkHour?.endTime}?`,
              type: 'warning',
              onConfirm: async () => {
                await approveOffsiteWorkHour(offSiteWorkHour.id);
              },
            };
          },
        },
        {
          name: 'Cancel',
          show: ({
            offSiteWorkHour,
          }: {
            offSiteWorkHour: OffSiteWorkHoursType;
          }) => {
            if (['pending'].includes(offSiteWorkHour.status)) {
              return true;
            }

            if (isSystemAdmin) {
              return true;
            }
            return false;
          },
          behaviour: 'confirm',
          onConfirm: ({ offSiteWorkHour }) => {
            return {
              title: 'Are you sure you want to Cancel Offsite Workhours?',
              message: `${offSiteWorkHour?.employee?.name} will be cancelled off-site work hours on{' '}
              ${offSiteWorkHour?.date} from ${offSiteWorkHour?.startTime} to ${offSiteWorkHour?.endTime}?`,
              type: 'warning',
              onConfirm: async () => {
                await cancelOffsiteWorkHour(offSiteWorkHour.id);
              },
            };
          },
        },
      ];
    }

    function renderTableBodyMapper(offSiteWorkHours?: OffSiteWorkHoursType[]) {
      return (
        offSiteWorkHours?.map((e) => ({
          rowData: {
            offSiteWorkHour: e,
          },
          cells: [
            {
              value: (
                <div>
                  <div>
                    {employeeIconMapperByGrade({
                      grade: (e.employee as any)?.details?.grade,
                    })}
                    {e.employee?.name || ''}
                  </div>
                  <div className='md:hidden'>
                    <div>
                      {e.date} from {e.startTime} to {e.endTime} applied by{' '}
                      {e.appliedBy.name}
                    </div>
                    <div>
                      {e.approvedBy &&
                      ['approved', 'redeemed'].includes(e.status)
                        ? `approved by ${e.approvedBy.name}`
                        : ''}{' '}
                    </div>
                    <div>Status: {e.status}</div>
                    <div>Reason: {e.reason}</div>
                  </div>
                </div>
              ),
              link: `/users/profile/${e?.employee?.id}`,
            },
            {
              value: e?.date,
              style: 'hidden md:table-cell',
            },
            {
              value: e?.startTime,
              style: 'hidden md:table-cell',
            },
            {
              value: e?.endTime,
              style: 'hidden md:table-cell',
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <div>
                  {e.appliedBy ? (
                    <userRendererService.userCard
                      link={true}
                      size='small'
                      id={e?.appliedBy?.id}
                      name={e?.appliedBy.name}
                    />
                  ) : null}
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <div>
                  {['approved', 'redeemed'].includes(e.status) &&
                  e.approvedBy ? (
                    <userRendererService.userCard
                      link={true}
                      size='small'
                      id={e?.approvedBy?.id}
                      name={e?.approvedBy?.name}
                    />
                  ) : null}
                </div>
              ),
            },
            {
              style: 'hidden md:table-cell',
              value: e?.reason,
            },
            {
              style: 'hidden md:table-cell',
              value: (
                <span>
                  {employeeLeaveStatusMapper(e.status)} {e?.status}
                </span>
              ),
            },
          ],
        })) || []
      );
    }

    const TableBody: TableBody = renderTableBodyMapper(OffSiteWorkHours);

    const filteredOSH = _.sortBy(
      OffSiteWorkHours?.filter((x) => {
        const emp = employees?.find((e) => e.id === x.employee.id);
        if (
          emp &&
          (emp.details?.authorizations?.attendanceAuthorizer?.id ||
            emp.details?.authorizations?.attendanceSupervisor?.id) ===
            currentUser.id
        ) {
          return true;
        }
        return false;
      }),
      (ofswh) => {
        switch (ofswh.status) {
          case 'pending':
            return 0;
          case 'approved':
            return 1;
          case 'redeemed':
            return 2;
          default:
            return 3;
        }
      }
    );

    const FilteredTableBody: TableBody = renderTableBodyMapper(filteredOSH);

    const MultiSelect: MultiSelect = {
      actions: [
        {
          name: 'Approve',
          show: () => true,
          behaviour: 'modal',
          modal: {
            title: 'Approve',
            content: ({ entries, onClose }) => {
              return (
                <BulkOffSiteActionForm
                  list={filteredOSH.filter((x, idx) => entries.includes(idx))}
                  onClose={onClose}
                  setOffSiteWh={setOffSiteWorkHour}
                  currentUser={currentUser}
                  getOffSiteWorkHr={getOffSiteWorkHours}
                  date={date}
                />
              );
            },
          },
        },
      ],
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            {loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <>
                <div className='my-5'>
                  <Card
                    header={{ title: ' Requests for you', actions: [] }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={FilteredTableBody}
                          multiSelect={MultiSelect}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>

                <div className='my-5'>
                  <Card
                    header={{ title: 'All Requests', actions: [] }}
                    body={{
                      type: 'jsx-component',
                      body: (
                        <Table
                          header={TableHeader}
                          body={TableBody}
                          actions={addActions()}
                          auth={{ actions: getAllowedActions().actions }}
                        />
                      ),
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
        <Confirm />
      </>
    );
  };
}
