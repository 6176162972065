/* eslint-disable react-hooks/exhaustive-deps */
import { ItemCategoryRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import {
  ItemCategoryFilter,
  UseCombinedItemCategory,
} from '../../../hooks/inventory/item/item-category/use-item-category';
import { renderAddItemCategoryForm } from './form/add-item-category';

export type RenderItemCategoryProps = {
  useCombinedItemCategory: UseCombinedItemCategory;
};

export function renderItemCategoryV2({
  useCombinedItemCategory,
}: RenderItemCategoryProps): () => JSX.Element {
  return function ItemCategoryV2(): JSX.Element {
    const Table = renderTableWithMapperComponent<
      ItemCategoryRes,
      ItemCategoryFilter
    >();

    const { syncSet: setItemCategory } = useCombinedItemCategory();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Item Category',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Item Category',
            behaviour: 'modal',
            modal: {
              title: 'Add Item Category',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveItemCategory(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Form = renderAddItemCategoryForm();

    const saveItemCategory = async (form: Partial<ItemCategoryRes>) => {
      try {
        const finalData = {
          ...form,
        } as ItemCategoryRes;
        await setItemCategory(finalData as ItemCategoryRes);
        toast('Data added sucessfully');
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Item Category Name' },
        { name: 'Short-Form' },
        { name: 'Description' },
      ],
    ];

    const bodyMapper = (r: ItemCategoryRes) => {
      const cells: Array<TableCell> = [
        { value: r.name },
        { value: r.id },
        { value: r.description },
      ];
      return {
        cells,
      };
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full'>
            <Table
              header={tableHeader}
              bodyMapper={bodyMapper}
              type={{
                type: 'paginated',
                usePaginatedResources: useCombinedItemCategory,
              }}
            />
          </div>
        </div>
      ),
    };
    return (
      <>
        <Card containerStyle='h-full' header={cardHeader} body={cardBody} />
      </>
    );
  };
}
