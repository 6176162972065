export function renderPRName(): // eslint-disable-next-line
({ id, name }: { id: string; name: string }) => JSX.Element {
  return function PR({ id, name }: { id: string; name: string }): JSX.Element {
    return (
      <a
        href={`/purchase/registers/purchase-requisitions/${id}`}
        target='_blank'
        className='border bg-blue-100 rounded text-blue-800 text-xs'
      >
        {name}
      </a>
    );
  };
}
