/* eslint-disable no-unused-vars */
import { ManufacturingVoucher } from '@erp_core/erp-types/dist/modules/order';
import _ from 'lodash';

export const getPlanned = (
  mvs: ManufacturingVoucher[],
  colorScheme?: {
    bgLight: string;
    bgDark: string;
    textLight: string;
    textDark: string;
  }
) => {
  const sched = mvs?.map((smv) => {
    // console.log('smv', smv);

    return smv.manufacturingVoucherItems.map((mvi) => ({
      id: String(Math.random()),
      calendarId: 'cal1',
      title: mvi.name,
      category: 'time',
      color: colorScheme?.textLight,
      backgroundColor: colorScheme?.bgDark,
      dragBackgroundColor: '#000',
      borderColor: colorScheme?.bgDark,
      start: mvi.details.manufacturingDateTime?.start,
      end: mvi.details.manufacturingDateTime?.end,
    }));
  });
  return _.flatten(_.flatten(sched));
};
