import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import { MarkerType, Position } from 'reactflow';
import {
  Edges,
  ReactFlowNodeType,
} from '../../../../../../components/flow-chart';
import { getActionDate } from '../../../../../../components/flow-chart/utils/get-action-date';
import { getNodeStyle } from '../../../../../../components/flow-chart/utils/get-node-style';

export function createBOMFlowGraph({
  data,
}: {
  data: BOMRes;
}): { nodes: Array<ReactFlowNodeType>; edges: Array<Edges> } {
  if (!data.details) return { nodes: [], edges: [] };

  const readyToCollectDate = getActionDate(data, 'allocation');
  const physicallyAllocationDate = getActionDate(data, 'allocation');
  const bomOpenedDate = getActionDate(data, 'approved');
  const mrsOpenedDate = getActionDate(data, 'mrs-opened');
  const bomCompletedDate = getActionDate(data, 'completed');

  const virtualActivityOccurred =
    data.details.activity.findIndex(
      (f: any) => f.action === 'virtual-allocation'
    ) > -1;

  return {
    nodes: [
      {
        id: '1',
        data: {
          label: (
            <div>
              <strong>BOM Opened</strong>
              <p>{bomOpenedDate}</p>
            </div>
          ),
        },
        position: { x: 0, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('open', data.details.status),
      },
      {
        id: '2',
        data: {
          label: (
            <div>
              <strong>Virtually Allocated</strong>
              <p>PO-123</p>
            </div>
          ),
        },
        position: { x: 200, y: -50 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('virtually-allocated', data.details.status),
      },
      {
        id: '3',
        data: {
          label: (
            <div>
              <strong>Physically Allocated</strong>
              <p>{physicallyAllocationDate}</p>
            </div>
          ),
        },
        position: { x: 400, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('physically-allocated', data.details.status),
      },
      {
        id: '4',
        data: {
          label: (
            <div>
              <strong>Ready to Collect</strong>
              <p>{readyToCollectDate}</p>
            </div>
          ),
        },
        position: { x: 600, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('ready-to-collect', data.details.status),
      },
      {
        id: '5',
        data: {
          label: (
            <div>
              <strong>MRS Opened</strong>
              <p>{mrsOpenedDate}</p>
            </div>
          ),
        },
        position: { x: 800, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('mrs-opened', data.details.status),
      },
      {
        id: '6',
        data: {
          label: (
            <div>
              <strong>BOM Completed</strong>
              <p>{bomCompletedDate}</p>
            </div>
          ),
        },
        position: { x: 1000, y: 0 },
        draggable: false,
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        style: getNodeStyle('completed', data.details.status),
      },
    ],
    edges: [
      {
        id: 'e1-2',
        source: '1',
        target: '2',
        animated: virtualActivityOccurred,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
        id: 'e1-3',
        source: '1',
        target: '3',
        animated: !virtualActivityOccurred,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
        id: 'e2-3',
        source: '2',
        target: '3',
        animated: virtualActivityOccurred,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
        id: 'e3-4',
        source: '3',
        target: '4',
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
        id: 'e4-5',
        source: '4',
        target: '5',
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
        id: 'e5-6',
        source: '5',
        target: '6',
        animated: true,
        markerEnd: { type: MarkerType.ArrowClosed },
      },
    ],
  };
}
