export const getNodeStyle = (forAction: string, currentStatus) => {
  return forAction === currentStatus
    ? {
        background: '#2B6CB0',
        color: 'white',
      }
    : {
        background: 'white',
        color: 'black',
      };
};
