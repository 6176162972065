import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedQuestionnaireQuestion } from '../../../../hooks/hrd/questionnaire/questionnaire-question/use-questionnaire-question';
import { UseCombinedQuestionnaireTopic } from '../../../../hooks/hrd/questionnaire/questionnaire-topic/use-questionnaire-topic';
import { QuestionType } from '../../../../models/types/questionnairetopic/question';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';
import { renderAddQuestionsForm } from './forms/question-addform';

export type RenderQuestionProps = {
  useCombinedQuestionnaireQuestion: UseCombinedQuestionnaireQuestion;
  useCombinedQuestionnaireTopic: UseCombinedQuestionnaireTopic;
  userRendererService: UserRendererInterface;
};

// eslint-disable-next-line
export function renderQuestionForm({
  useCombinedQuestionnaireQuestion,
  userRendererService,
  useCombinedQuestionnaireTopic,
}: RenderQuestionProps): () => JSX.Element {
  return function Question(): JSX.Element {
    const { id } = useParams();
    const { companyGroup: currentCompanyGroup } = useContext(CurrentContext);

    const { syncSet: setQuestion } = useCombinedQuestionnaireQuestion();
    const { get: getTopic, resource: topic } = useCombinedQuestionnaireTopic();

    const { delete: deleteQuestion } = useCombinedQuestionnaireQuestion();

    const {
      list: questions,
      getAll: getQuestions,
    } = useCombinedQuestionnaireQuestion();

    useEffect(() => {}, [questions]);

    useEffect(() => {
      if (id) {
        getTopic(id);
        getQuestions({ topicId: id, order: 'asc', sortBy: 'orderNo' });
      }
      // eslint-disable-next-line
    }, [id]);

    const handleQuestionAdd = async (data: QuestionType) => {
      const finalData = {
        ...data,
        topic: {
          id: id,
        },
      };

      await setQuestion(finalData as QuestionType);

      getQuestions({ topicId: id, order: 'asc', sortBy: 'orderNo' });
    };

    const AddQuestionForm = renderAddQuestionsForm({
      onSave: handleQuestionAdd,
      companyGroupId: currentCompanyGroup?.id,
    });

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: `${topic?.name} Questions`,
      actions: [
        {
          type: 'button',
          button: {
            behaviour: 'modal',
            name: 'Add',
            modal: {
              content: ({ onClose }) => {
                return <AddQuestionForm onClose={onClose} />;
              },
              title: 'Add Question',
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Order No' },
        { name: 'Question' },
        { name: 'Answer Type' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const tableBody: TableBody =
      questions?.map((r) => ({
        rowData: {
          question: r,
        },
        cells: [
          { value: r.orderNo },
          { value: r.name },
          { value: r.answerType },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={r.createdBy?.id}
                name={r.createdBy?.name}
                extraInfo={moment.utc(r.createdAt).fromNow()}
              />
            ),
          },
          {
            value: (
              <userRendererService.userCard
                size='small'
                link={true}
                id={r.lastModifiedBy?.id}
                name={r.lastModifiedBy?.name}
                extraInfo={moment.utc(r.lastModifiedAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data: { question }, onClose }) => {
                      return (
                        <AddQuestionForm
                          defaultData={question}
                          questionList={questions}
                          onClose={onClose}
                        />
                      );
                    },
                  },
                },
                {
                  name: 'Delete',
                  show: () => true,
                  behaviour: 'confirm',
                  onConfirm: ({ question }) => {
                    return {
                      message: `Are you sure you want to delete this Question ${question.name}? This cannot be undone.`,
                      onConfirm: async () => {
                        deleteQuestion(question.id);
                      },
                    };
                  },
                },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <>
        <Card header={cardHeader} body={cardBody} />
      </>
    );
  };
}
