/* eslint-disable no-unused-vars */
import {
  CompanyGroupSetting,
  CompanyGroupSettingDetails,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderEditBomNamePatternFormProps = {
  comGrpSetDetails: CompanyGroupSettingDetails;
  closeModal: () => void;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

export function renderEditMinimumOrderQuantityForm({
  comGrpSetDetails,
  closeModal,
  useCombinedCompanyGroupSetting,
}: RenderEditBomNamePatternFormProps): () => JSX.Element {
  return function (): JSX.Element {
    const { set: setCompanyGroupSetting } = useCombinedCompanyGroupSetting();
    const save = async (form: FormType) => {
      const finalData: CompanyGroupSetting = {
        details: {
          inventory: {
            ...comGrpSetDetails?.inventory,
            item: {
              ...comGrpSetDetails?.inventory?.item,
              minimumOrderQuantity: form.minimumOrderQuantity,
            },
          },
        } as CompanyGroupSettingDetails,
      } as CompanyGroupSetting;
      await setCompanyGroupSetting(finalData);
      closeModal();
    };

    type FormType = {
      minimumOrderQuantity: number;
    };

    const formProps: FormProps<FormType> = {
      fieldsData: [
        {
          property: 'minimumOrderQuantity',
          label: 'Minimum Order Quantity %',
          type: 'number',
          required: true,
        },
      ],
      initialFormState: {
        minimumOrderQuantity:
          comGrpSetDetails?.inventory?.item?.minimumOrderQuantity || '',
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: save,
    };

    const Form = renderFormV2<any>(formProps);

    return (
      <div>
        <Form />
        <div className='font-bold text-md mt-1'>Guidelines</div>
        <div>
          Minimum order quantity is x percentage of the items default godowns
          capacity
        </div>
      </div>
    );

    function mapPFDToP(b: FormDataType<FormType>): FormType {
      return b as FormType;
    }
  };
}
