import { Abuse } from '@erp_core/erp-icons/icons/web/abuse';
import { Accessibility } from '@erp_core/erp-icons/icons/web/accessibility';
import { Account } from '@erp_core/erp-icons/icons/web/account';
import { Admin } from '@erp_core/erp-icons/icons/web/admin';
import { Api } from '@erp_core/erp-icons/icons/web/api';
import { Appearance } from '@erp_core/erp-icons/icons/web/appearance';
import { Application } from '@erp_core/erp-icons/icons/web/application';
import { Approval } from '@erp_core/erp-icons/icons/web/approval';
import { ApprovalSolid } from '@erp_core/erp-icons/icons/web/approval-solid';
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { Archive } from '@erp_core/erp-icons/icons/web/archive';
import { ArrowDown } from '@erp_core/erp-icons/icons/web/arrow-down';
import { ArrowLeft } from '@erp_core/erp-icons/icons/web/arrow-left';
import { ArrowRight } from '@erp_core/erp-icons/icons/web/arrow-right';
import { Arrowup } from '@erp_core/erp-icons/icons/web/arrow-up';
import { Assignee } from '@erp_core/erp-icons/icons/web/assignee';
import { At } from '@erp_core/erp-icons/icons/web/at';
import { Attention } from '@erp_core/erp-icons/icons/web/attention';
import { AttentionSolid } from '@erp_core/erp-icons/icons/web/attention-solid';
import { AttentionSolidSm } from '@erp_core/erp-icons/icons/web/attention-solid-sm';
import { Bank } from '@erp_core/erp-icons/icons/web/bank';
import { BitBucket } from '@erp_core/erp-icons/icons/web/bitbucket';
import { Bold } from '@erp_core/erp-icons/icons/web/bold';
import { BOM } from '@erp_core/erp-icons/icons/web/bom';
import { BomDraftRegister } from '@erp_core/erp-icons/icons/web/bom-draft-register';
import { BomRegister } from '@erp_core/erp-icons/icons/web/bom-register';
import { Book } from '@erp_core/erp-icons/icons/web/book';
import { BookMark } from '@erp_core/erp-icons/icons/web/bookmark';
import { Branch } from '@erp_core/erp-icons/icons/web/branch';
import { BranchDeleted } from '@erp_core/erp-icons/icons/web/branch-deleted';
import { BrandZoom } from '@erp_core/erp-icons/icons/web/brand-zoom';
import { Bug } from '@erp_core/erp-icons/icons/web/bug';
import { Building } from '@erp_core/erp-icons/icons/web/building';
import { Bulb } from '@erp_core/erp-icons/icons/web/bulb';
import { BullHorn } from '@erp_core/erp-icons/icons/web/bullhorn';
import { Calendar } from '@erp_core/erp-icons/icons/web/calendar';
import { Cancel } from '@erp_core/erp-icons/icons/web/cancel';
import { CanceledCircle } from '@erp_core/erp-icons/icons/web/canceled-circle';
import { Car } from '@erp_core/erp-icons/icons/web/car';
import { CasualLeave } from '@erp_core/erp-icons/icons/web/casual-leave';
import { Chart } from '@erp_core/erp-icons/icons/web/chart';
import { Check } from '@erp_core/erp-icons/icons/web/check';
import { CheckCircle } from '@erp_core/erp-icons/icons/web/check-circle';
import { CheckCircleDashed } from '@erp_core/erp-icons/icons/web/check-circle-dashed';
import { CheckCircleFilled } from '@erp_core/erp-icons/icons/web/check-circle-filled';
import { CheckSm } from '@erp_core/erp-icons/icons/web/check-sm';
import { CherryPickCommit } from '@erp_core/erp-icons/icons/web/cherry-pick-commit';
import { ChevronDoubleLgLeft } from '@erp_core/erp-icons/icons/web/chevron-double-lg-left';
import { ChevronDoubleLgRight } from '@erp_core/erp-icons/icons/web/chevron-double-lg-right';
import { ChevronDown } from '@erp_core/erp-icons/icons/web/chevron-down';
import { ChevronLeft } from '@erp_core/erp-icons/icons/web/chevron-left';
import { ChevronLgDown } from '@erp_core/erp-icons/icons/web/chevron-lg-down';
import { ChevronLgLeft } from '@erp_core/erp-icons/icons/web/chevron-lg-left';
import { ChevronLgUp } from '@erp_core/erp-icons/icons/web/chevron-lg-up';
import { ChevronRight } from '@erp_core/erp-icons/icons/web/chevron-right';
import { ChevronUp } from '@erp_core/erp-icons/icons/web/chevron-up';
import { Clear } from '@erp_core/erp-icons/icons/web/clear';
import { ClearAll } from '@erp_core/erp-icons/icons/web/clear-all';
import { Clock } from '@erp_core/erp-icons/icons/web/clock';
import { Close } from '@erp_core/erp-icons/icons/web/close';
import { CloudGear } from '@erp_core/erp-icons/icons/web/cloud-gear';
import { CloudPod } from '@erp_core/erp-icons/icons/web/cloud-pod';
import { CloudTerminal } from '@erp_core/erp-icons/icons/web/cloud-terminal';
import { Code } from '@erp_core/erp-icons/icons/web/code';
import { Collapse } from '@erp_core/erp-icons/icons/web/collapse';
import { CollapseLeft } from '@erp_core/erp-icons/icons/web/collapse-left';
import { CollapseRight } from '@erp_core/erp-icons/icons/web/collapse-right';
import { CollapseSolid } from '@erp_core/erp-icons/icons/web/collapse-solid';
import { College } from '@erp_core/erp-icons/icons/web/college';
import { Comment } from '@erp_core/erp-icons/icons/web/comment';
import { CommentDots } from '@erp_core/erp-icons/icons/web/comment-dots';
import { CommentLines } from '@erp_core/erp-icons/icons/web/comment-lines';
import { CommentNext } from '@erp_core/erp-icons/icons/web/comment-next';
import { Comments } from '@erp_core/erp-icons/icons/web/comments';
import { Commit } from '@erp_core/erp-icons/icons/web/commit';
import { Comparison } from '@erp_core/erp-icons/icons/web/comparison';
import { Compass } from '@erp_core/erp-icons/icons/web/compass';
import { CompensatoryOff } from '@erp_core/erp-icons/icons/web/compensatory-off';
import { Connected } from '@erp_core/erp-icons/icons/web/connected';
import { ContainerImage } from '@erp_core/erp-icons/icons/web/container-image';
import { CopyToClipboard } from '@erp_core/erp-icons/icons/web/copy-to-clipboard';
import { CreditCard } from '@erp_core/erp-icons/icons/web/credit-card';
import { Dash } from '@erp_core/erp-icons/icons/web/dash';
import { DashBoard } from '@erp_core/erp-icons/icons/web/dashboard';
import { Deployments } from '@erp_core/erp-icons/icons/web/deployments';
import { DetailsBlock } from '@erp_core/erp-icons/icons/web/details-block';
import { Diagram } from '@erp_core/erp-icons/icons/web/diagram';
import { Discord } from '@erp_core/erp-icons/icons/web/discord';
import { Disk } from '@erp_core/erp-icons/icons/web/disk';
import { DocChanges } from '@erp_core/erp-icons/icons/web/doc-changes';
import { DocChart } from '@erp_core/erp-icons/icons/web/doc-chart';
import { DocCode } from '@erp_core/erp-icons/icons/web/doc-code';
import { DocCompressed } from '@erp_core/erp-icons/icons/web/doc-compressed';
import { DocExpand } from '@erp_core/erp-icons/icons/web/doc-expand';
import { DocImage } from '@erp_core/erp-icons/icons/web/doc-image';
import { DocNew } from '@erp_core/erp-icons/icons/web/doc-new';
import { DocSymlink } from '@erp_core/erp-icons/icons/web/doc-symlink';
import { DocVersions } from '@erp_core/erp-icons/icons/web/doc-versions';
import { Document } from '@erp_core/erp-icons/icons/web/document';
import { Documents } from '@erp_core/erp-icons/icons/web/documents';
import { Dotgrid } from '@erp_core/erp-icons/icons/web/dot-grid';
import { DottedCircle } from '@erp_core/erp-icons/icons/web/dotted-circle';
import { DoubleHeadedArrow } from '@erp_core/erp-icons/icons/web/double-headed-arrow';
import { Download } from '@erp_core/erp-icons/icons/web/download';
import { DumbBell } from '@erp_core/erp-icons/icons/web/dumbbell';
import { Duplicate } from '@erp_core/erp-icons/icons/web/duplicate';
import { Earth } from '@erp_core/erp-icons/icons/web/earth';
import { EducationalDetails } from '@erp_core/erp-icons/icons/web/education-details';
import { EllipsisH } from '@erp_core/erp-icons/icons/web/ellipsis-h';
import { EllipsisV } from '@erp_core/erp-icons/icons/web/ellipsis-v';
import { EmployeeProfile } from '@erp_core/erp-icons/icons/web/employee-profile';
import { EntityBlocked } from '@erp_core/erp-icons/icons/web/entity-blocked';
import { Environment } from '@erp_core/erp-icons/icons/web/environment';
import { Epic } from '@erp_core/erp-icons/icons/web/epic';
import { EpicClosed } from '@erp_core/erp-icons/icons/web/epic-closed';
import { Error } from '@erp_core/erp-icons/icons/web/error';
import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { ExcelUpload } from '@erp_core/erp-icons/icons/web/excel-upload';
import { ExecutiveFemale } from '@erp_core/erp-icons/icons/web/executive-female';
import { ExecutiveMale } from '@erp_core/erp-icons/icons/web/executive-male';
import { Expand } from '@erp_core/erp-icons/icons/web/expand';
import { ExpandDown } from '@erp_core/erp-icons/icons/web/expand-down';
import { ExpandLeft } from '@erp_core/erp-icons/icons/web/expand-left';
import { ExpandRight } from '@erp_core/erp-icons/icons/web/expand-right';
import { ExpandUp } from '@erp_core/erp-icons/icons/web/expand-up';
import { Expire } from '@erp_core/erp-icons/icons/web/expire';
import { Export } from '@erp_core/erp-icons/icons/web/export';
import { ExternalLink } from '@erp_core/erp-icons/icons/web/external-link';
import { Eye } from '@erp_core/erp-icons/icons/web/eye';
import { EyeSlash } from '@erp_core/erp-icons/icons/web/eye-slash';
import { FaceNeutral } from '@erp_core/erp-icons/icons/web/face-neutral';
import { FaceUnhappy } from '@erp_core/erp-icons/icons/web/face-unhappy';
import { FactoryManager } from '@erp_core/erp-icons/icons/web/factory-manager';
import { FactoryWorkerFemale } from '@erp_core/erp-icons/icons/web/factory-worker-female';
import { FactoryWorkerMale } from '@erp_core/erp-icons/icons/web/factory-worker-male';
import { FalsePositive } from '@erp_core/erp-icons/icons/web/false-positive';
import { FeatureFlag } from '@erp_core/erp-icons/icons/web/feature-flag';
import { FeatureFlagDisabled } from '@erp_core/erp-icons/icons/web/feature-flag-disabled';
import { FileAddition } from '@erp_core/erp-icons/icons/web/file-addition';
import { FileAdditionSolid } from '@erp_core/erp-icons/icons/web/file-addition-solid';
import { FileDeletion } from '@erp_core/erp-icons/icons/web/file-deletion';
import { FileDeletionSolid } from '@erp_core/erp-icons/icons/web/file-deletion-solid';
import { FileModified } from '@erp_core/erp-icons/icons/web/file-modified';
import { FileModifiedSolid } from '@erp_core/erp-icons/icons/web/file-modified-solid';
import { FileTree } from '@erp_core/erp-icons/icons/web/file-tree';
import { Filter } from '@erp_core/erp-icons/icons/web/filter';
import { FinishedGood } from '@erp_core/erp-icons/icons/web/finished-good';
import { Fire } from '@erp_core/erp-icons/icons/web/fire';
import { FirstContribution } from '@erp_core/erp-icons/icons/web/first-contribution';
import { Flag } from '@erp_core/erp-icons/icons/web/flag';
import { Folder } from '@erp_core/erp-icons/icons/web/folder';
import { FolderNew } from '@erp_core/erp-icons/icons/web/folder-new';
import { FolderO } from '@erp_core/erp-icons/icons/web/folder-o';
import { FolderOpen } from '@erp_core/erp-icons/icons/web/folder-open';
import { Food } from '@erp_core/erp-icons/icons/web/food';
import { Fork } from '@erp_core/erp-icons/icons/web/fork';
import { Formula } from '@erp_core/erp-icons/icons/web/formula';
import { Git } from '@erp_core/erp-icons/icons/web/git';
import { GitMerge } from '@erp_core/erp-icons/icons/web/git-merge';
import { Gitea } from '@erp_core/erp-icons/icons/web/gitea';
import { Github } from '@erp_core/erp-icons/icons/web/github';
import { GoBack } from '@erp_core/erp-icons/icons/web/go-back';
import { GodownsList } from '@erp_core/erp-icons/icons/web/godown-list';
import { GodownTransfer } from '@erp_core/erp-icons/icons/web/godown-transfer';
import { Google } from '@erp_core/erp-icons/icons/web/google';
import { Grip } from '@erp_core/erp-icons/icons/web/grip';
import { Group } from '@erp_core/erp-icons/icons/web/group';
import { Hamburger } from '@erp_core/erp-icons/icons/web/hamburger';
import { Heading } from '@erp_core/erp-icons/icons/web/heading';
import { Heart } from '@erp_core/erp-icons/icons/web/heart';
import { HelperFemale } from '@erp_core/erp-icons/icons/web/helper-female';
import { HelperMale } from '@erp_core/erp-icons/icons/web/helper-male';
import { HighLight } from '@erp_core/erp-icons/icons/web/highlight';
import { History } from '@erp_core/erp-icons/icons/web/history';
import { Home } from '@erp_core/erp-icons/icons/web/home';
import { Hook } from '@erp_core/erp-icons/icons/web/hook';
import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { ImageCommentDark } from '@erp_core/erp-icons/icons/web/image-comment-dark';
import { ImageCommentLight } from '@erp_core/erp-icons/icons/web/image-comment-light';
import { Import } from '@erp_core/erp-icons/icons/web/import';
import { Incognito } from '@erp_core/erp-icons/icons/web/incognito';
import { Information } from '@erp_core/erp-icons/icons/web/information';
import { InformationO } from '@erp_core/erp-icons/icons/web/information-o';
import { InfrastructureRegistry } from '@erp_core/erp-icons/icons/web/infrastructure-registry';
import { IntermediatePoint } from '@erp_core/erp-icons/icons/web/intermediate-point';
import { InventoryTree } from '@erp_core/erp-icons/icons/web/inventory-tree';
import { IssueBlock } from '@erp_core/erp-icons/icons/web/issue-block';
import { IssueClose } from '@erp_core/erp-icons/icons/web/issue-close';
import { IssueClosed } from '@erp_core/erp-icons/icons/web/issue-closed';
import { IssueNew } from '@erp_core/erp-icons/icons/web/issue-new';
import { IssueOpenM } from '@erp_core/erp-icons/icons/web/issue-open-m';
import { IssueTypeEnhancement } from '@erp_core/erp-icons/icons/web/issue-type-enhancement';
import { IssueTypeFeature } from '@erp_core/erp-icons/icons/web/issue-type-feature';
import { IssueTypeFeatureFlag } from '@erp_core/erp-icons/icons/web/issue-type-feature-flag';
import { IssueTypeIncident } from '@erp_core/erp-icons/icons/web/issue-type-incident';
import { IssueTypeIssue } from '@erp_core/erp-icons/icons/web/issue-type-issue';
import { IssueTypeKeyResult } from '@erp_core/erp-icons/icons/web/issue-type-keyresult';
import { IssueTypeMaintenance } from '@erp_core/erp-icons/icons/web/issue-type-maintenance';
import { IssueTypeObjective } from '@erp_core/erp-icons/icons/web/issue-type-objective';
import { IssueTypeRequirements } from '@erp_core/erp-icons/icons/web/issue-type-requirements';
import { IssueTypeTask } from '@erp_core/erp-icons/icons/web/issue-type-task';
import { IssueTypeTestCase } from '@erp_core/erp-icons/icons/web/issue-type-test-case';
import { IssueUpdate } from '@erp_core/erp-icons/icons/web/issue-update';
import { Issues } from '@erp_core/erp-icons/icons/web/issues';
import { Italic } from '@erp_core/erp-icons/icons/web/italic';
import { ItemBatches } from '@erp_core/erp-icons/icons/web/item-batches';
import { ItemList } from '@erp_core/erp-icons/icons/web/item-list';
import { Iteration } from '@erp_core/erp-icons/icons/web/iteration';
import { Key } from '@erp_core/erp-icons/icons/web/key';
import { KeyBoard } from '@erp_core/erp-icons/icons/web/keyboard';
import { Kids } from '@erp_core/erp-icons/icons/web/kids';
import { KubernetesAgent } from '@erp_core/erp-icons/icons/web/kubernetes-agent';
import { Label } from '@erp_core/erp-icons/icons/web/label';
import { Labels } from '@erp_core/erp-icons/icons/web/labels';
import { Leave } from '@erp_core/erp-icons/icons/web/leave';
import { LevelUp } from '@erp_core/erp-icons/icons/web/level-up';
import { License } from '@erp_core/erp-icons/icons/web/license';
import { LicenseSM } from '@erp_core/erp-icons/icons/web/license-sm';
import { Link } from '@erp_core/erp-icons/icons/web/link';
import { Linkedin } from '@erp_core/erp-icons/icons/web/linkedin';
import { ListBulleted } from '@erp_core/erp-icons/icons/web/list-bulleted';
import { ListIndent } from '@erp_core/erp-icons/icons/web/list-indent';
import { ListNumbered } from '@erp_core/erp-icons/icons/web/list-numbered';
import { ListOutdent } from '@erp_core/erp-icons/icons/web/list-outdent';
import { ListTask } from '@erp_core/erp-icons/icons/web/list-task';
import { LivePreview } from '@erp_core/erp-icons/icons/web/live-preview';
import { LiveStream } from '@erp_core/erp-icons/icons/web/live-stream';
import { Location } from '@erp_core/erp-icons/icons/web/location';
import { LocationDot } from '@erp_core/erp-icons/icons/web/location-dot';
import { LocationMarker } from '@erp_core/erp-icons/icons/web/location-marker';
import { Lock } from '@erp_core/erp-icons/icons/web/lock';
import { LockOpen } from '@erp_core/erp-icons/icons/web/lock-open';
import { Log } from '@erp_core/erp-icons/icons/web/log';
import { LongArrow } from '@erp_core/erp-icons/icons/web/long-arrow';
import { MachineIcon } from '@erp_core/erp-icons/icons/web/machine-icon';
import { MachineLearning } from '@erp_core/erp-icons/icons/web/machine-learning';
import { Mail } from '@erp_core/erp-icons/icons/web/mail';
import { ManagerFemale } from '@erp_core/erp-icons/icons/web/manager-female';
import { ManagerMale } from '@erp_core/erp-icons/icons/web/manager-male';
import { MarkDownMark } from '@erp_core/erp-icons/icons/web/markdown-mark';
import { MarkDownMarkSolid } from '@erp_core/erp-icons/icons/web/markdown-mark-solid';
import { MarqueeSelection } from '@erp_core/erp-icons/icons/web/marquee-selection';
import { MaternityLeave } from '@erp_core/erp-icons/icons/web/maternity-leave';
import { Maximize } from '@erp_core/erp-icons/icons/web/maximize';
import { Media } from '@erp_core/erp-icons/icons/web/media';
import { MediaBroken } from '@erp_core/erp-icons/icons/web/media-broken';
import { Merge } from '@erp_core/erp-icons/icons/web/merge';
import { MergeRequest } from '@erp_core/erp-icons/icons/web/merge-request';
import { MergeRequestClose } from '@erp_core/erp-icons/icons/web/merge-request-close';
import { MergeRequestCloseM } from '@erp_core/erp-icons/icons/web/merge-request-close-m';
import { MergeRequestOpen } from '@erp_core/erp-icons/icons/web/merge-request-open';
import { Messages } from '@erp_core/erp-icons/icons/web/messages';
import { Microphone } from '@erp_core/erp-icons/icons/web/microphone';
import { Minimize } from '@erp_core/erp-icons/icons/web/minimize';
import { Mobile } from '@erp_core/erp-icons/icons/web/mobile';
import { MobileIssueClose } from '@erp_core/erp-icons/icons/web/mobile-issue-close';
import { Monitor } from '@erp_core/erp-icons/icons/web/monitor';
import { MonitorLines } from '@erp_core/erp-icons/icons/web/monitor-lines';
import { MonitorO } from '@erp_core/erp-icons/icons/web/monitor-o';
import { MrsCompleted } from '@erp_core/erp-icons/icons/web/mrs-completed';
import { MrsRegister } from '@erp_core/erp-icons/icons/web/mrs-register';
import { NameSpace } from '@erp_core/erp-icons/icons/web/name-space';
import { Nature } from '@erp_core/erp-icons/icons/web/nature';
import { Notifications } from '@erp_core/erp-icons/icons/web/notifications';
import { NotificationsOff } from '@erp_core/erp-icons/icons/web/notifications-off';
import { ObjectIcon } from '@erp_core/erp-icons/icons/web/object-icon';
import { OfficeArea } from '@erp_core/erp-icons/icons/web/office-area';
import { OfficeStaffFemale } from '@erp_core/erp-icons/icons/web/office-staff-female';
import { OfficeStaffMale } from '@erp_core/erp-icons/icons/web/office-staff-male';
import { Organization } from '@erp_core/erp-icons/icons/web/organization';
import { OverView } from '@erp_core/erp-icons/icons/web/overview';
import { Package } from '@erp_core/erp-icons/icons/web/package';
import { PaperAirplane } from '@erp_core/erp-icons/icons/web/paper-airplane';
import { PaperClip } from '@erp_core/erp-icons/icons/web/paperclip';
import { Parents } from '@erp_core/erp-icons/icons/web/parents';
import { Pause } from '@erp_core/erp-icons/icons/web/pause';
import { Pencil } from '@erp_core/erp-icons/icons/web/pencil';
import { PencilSquar } from '@erp_core/erp-icons/icons/web/pencil-squar';
import { PersonalInfo } from '@erp_core/erp-icons/icons/web/personal-info';
import { PipeLine } from '@erp_core/erp-icons/icons/web/pipeline';
import { Planing } from '@erp_core/erp-icons/icons/web/planing';
import { Play } from '@erp_core/erp-icons/icons/web/play';
import { Plus } from '@erp_core/erp-icons/icons/web/plus';
import { PlusSquare } from '@erp_core/erp-icons/icons/web/plus-square';
import { PlusSquareO } from '@erp_core/erp-icons/icons/web/plus-square-o';
import { Pod } from '@erp_core/erp-icons/icons/web/pod';
import { PodCast } from '@erp_core/erp-icons/icons/web/podcast';
import { Power } from '@erp_core/erp-icons/icons/web/power';
import { Preferences } from '@erp_core/erp-icons/icons/web/preferences';
import { PrivilegeLeave } from '@erp_core/erp-icons/icons/web/privilege-leave';
import { Profile } from '@erp_core/erp-icons/icons/web/profile';
import { Progress } from '@erp_core/erp-icons/icons/web/progress';
import { Project } from '@erp_core/erp-icons/icons/web/project';
import { PurchaseOrder } from '@erp_core/erp-icons/icons/web/purchase-order';
import { PushRules } from '@erp_core/erp-icons/icons/web/push-rules';
import { Question } from '@erp_core/erp-icons/icons/web/question';
import { Questionnaire } from '@erp_core/erp-icons/icons/web/questionnaire';
import { QuestionO } from '@erp_core/erp-icons/icons/web/quetion-o';
import { QuickActions } from '@erp_core/erp-icons/icons/web/quick-actions';
import { Quota } from '@erp_core/erp-icons/icons/web/quota';
import { Quote } from '@erp_core/erp-icons/icons/web/quote';
import { RawMaterial } from '@erp_core/erp-icons/icons/web/raw-material';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { Redo } from '@erp_core/erp-icons/icons/web/redo';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import { Remove } from '@erp_core/erp-icons/icons/web/remove';
import { RemoveAll } from '@erp_core/erp-icons/icons/web/remove-all';
import { Repeat } from '@erp_core/erp-icons/icons/web/repeat';
import { Reply } from '@erp_core/erp-icons/icons/web/reply';
import { Requirements } from '@erp_core/erp-icons/icons/web/requirements';
import { Retry } from '@erp_core/erp-icons/icons/web/retry';
import { ReviewCheckmark } from '@erp_core/erp-icons/icons/web/review-checkmark';
import { ReviewList } from '@erp_core/erp-icons/icons/web/review-list';
import { ReviewWarning } from '@erp_core/erp-icons/icons/web/review-warning';
import { Rocket } from '@erp_core/erp-icons/icons/web/rocket';
import { RocketLaunch } from '@erp_core/erp-icons/icons/web/rocket-launch';
import { Rss } from '@erp_core/erp-icons/icons/web/rss';
import { Scale } from '@erp_core/erp-icons/icons/web/scale';
import { ScrollDown } from '@erp_core/erp-icons/icons/web/scroll-down';
import { ScrollHandle } from '@erp_core/erp-icons/icons/web/scroll-handle';
import { ScrollUp } from '@erp_core/erp-icons/icons/web/scroll-up';
import { Search } from '@erp_core/erp-icons/icons/web/search';
import { SearchDot } from '@erp_core/erp-icons/icons/web/search-dot';
import { SearchMinus } from '@erp_core/erp-icons/icons/web/search-minus';
import { SearchPlus } from '@erp_core/erp-icons/icons/web/search-plus';
import { SearchResults } from '@erp_core/erp-icons/icons/web/search-results';
import { SearchSm } from '@erp_core/erp-icons/icons/web/search-sm';
import { Settimgs } from '@erp_core/erp-icons/icons/web/settings';
import { SeverityCritical } from '@erp_core/erp-icons/icons/web/severity-critical';
import { SeverityHigh } from '@erp_core/erp-icons/icons/web/severity-high';
import { SeverityInfo } from '@erp_core/erp-icons/icons/web/severity-info';
import { SeverityMedium } from '@erp_core/erp-icons/icons/web/severity-medium';
import { SeverityUnknown } from '@erp_core/erp-icons/icons/web/severity-unknown';
import { Share } from '@erp_core/erp-icons/icons/web/share';
import { Shield } from '@erp_core/erp-icons/icons/web/shield';
import { SickLeave } from '@erp_core/erp-icons/icons/web/sick-leave';
import { Sidebar } from '@erp_core/erp-icons/icons/web/sidebar';
import { SidebarRight } from '@erp_core/erp-icons/icons/web/sidebar-right';
import { Skype } from '@erp_core/erp-icons/icons/web/skype';
import { SlightFrown } from '@erp_core/erp-icons/icons/web/slight-frown';
import { SlightSmile } from '@erp_core/erp-icons/icons/web/slight-smile';
import { SmartCard } from '@erp_core/erp-icons/icons/web/smart-card';
import { Smile } from '@erp_core/erp-icons/icons/web/smile';
import { Smiley } from '@erp_core/erp-icons/icons/web/smiley';
import { Snippet } from '@erp_core/erp-icons/icons/web/snippet';
import { SoftUnwrap } from '@erp_core/erp-icons/icons/web/soft-unwrap';
import { SoftWrap } from '@erp_core/erp-icons/icons/web/soft-wrap';
import { SortHighest } from '@erp_core/erp-icons/icons/web/sort-highest';
import { SortLowest } from '@erp_core/erp-icons/icons/web/sort-lowest';
import { Spam } from '@erp_core/erp-icons/icons/web/spam';
import { Spinner } from '@erp_core/erp-icons/icons/web/spinner';
import { StageAll } from '@erp_core/erp-icons/icons/web/stage-all';
import { Star } from '@erp_core/erp-icons/icons/web/star';
import { StarO } from '@erp_core/erp-icons/icons/web/star-o';
import { StatusAlert } from '@erp_core/erp-icons/icons/web/statu-alert';
import { Status } from '@erp_core/erp-icons/icons/web/status';
import { StatusCanceled } from '@erp_core/erp-icons/icons/web/status-canceled';
import { StatusCanceledBorderless } from '@erp_core/erp-icons/icons/web/status-canceled-borderless';
import { StatusCancelled } from '@erp_core/erp-icons/icons/web/status-cancelled';
import { StatusClosed } from '@erp_core/erp-icons/icons/web/status-closed';
import { StatusCreated } from '@erp_core/erp-icons/icons/web/status-created';
import { StatusFailed } from '@erp_core/erp-icons/icons/web/status-failed';
import { StatusFailedBorderless } from '@erp_core/erp-icons/icons/web/status-failed-borderless';
import { StatusFailedX } from '@erp_core/erp-icons/icons/web/status-failedx';
import { StatusHealth } from '@erp_core/erp-icons/icons/web/status-health';
import { StatusManual } from '@erp_core/erp-icons/icons/web/status-manual';
import { StatusManualBorderless } from '@erp_core/erp-icons/icons/web/status-manual-borderless';
import { StatusNeautral } from '@erp_core/erp-icons/icons/web/status-neutral';
import { StatusNotFoundBorderless } from '@erp_core/erp-icons/icons/web/status-notfound-borderless';
import { StatusOpen } from '@erp_core/erp-icons/icons/web/status-open';
import { StatusPaused } from '@erp_core/erp-icons/icons/web/status-paused';
import { StatusPendingBorderless } from '@erp_core/erp-icons/icons/web/status-pending-borderless';
import { StatusRuning } from '@erp_core/erp-icons/icons/web/status-runing';
import { StatusRunning } from '@erp_core/erp-icons/icons/web/status-running';
import { StatusRunningBorderless } from '@erp_core/erp-icons/icons/web/status-running-borderless';
import { StatusScheduled } from '@erp_core/erp-icons/icons/web/status-scheduled';
import { StatusScheduledBorderless } from '@erp_core/erp-icons/icons/web/status-scheduled-borderless';
import { StatusStopped } from '@erp_core/erp-icons/icons/web/status-stopped';
import { StatusSuccess } from '@erp_core/erp-icons/icons/web/status-success';
import { StatusSuccessBorderless } from '@erp_core/erp-icons/icons/web/status-success-borderless';
import { StatusSuccessSolid } from '@erp_core/erp-icons/icons/web/status-success-solid';
import { StatusWaiting } from '@erp_core/erp-icons/icons/web/status-waiting';
import { StatusWarningBorderless } from '@erp_core/erp-icons/icons/web/status-warning-borderless';
import { Stockgroup } from '@erp_core/erp-icons/icons/web/stock-group';
import { StockGroupList } from '@erp_core/erp-icons/icons/web/stock-group-list';
import { StockGroupListSolid } from '@erp_core/erp-icons/icons/web/stock-group-list-solid';
import { Stocks } from '@erp_core/erp-icons/icons/web/stocks';
import { StrikeThrough } from '@erp_core/erp-icons/icons/web/strikethrough';
import { SubGroup } from '@erp_core/erp-icons/icons/web/subgroup';
import { SubScript } from '@erp_core/erp-icons/icons/web/subscript';
import { Subsitute } from '@erp_core/erp-icons/icons/web/substitute';
import { SuperScript } from '@erp_core/erp-icons/icons/web/superscript';
import { Symlink } from '@erp_core/erp-icons/icons/web/symlink';
import { Table } from '@erp_core/erp-icons/icons/web/table';
import { Tablet } from '@erp_core/erp-icons/icons/web/tablet';
import { Tachometer } from '@erp_core/erp-icons/icons/web/tachometer';
import { Tag } from '@erp_core/erp-icons/icons/web/tag';
import { Tanuki } from '@erp_core/erp-icons/icons/web/tanuki';
import { TanukiAi } from '@erp_core/erp-icons/icons/web/tanuki-ai';
import { TanukiVerified } from '@erp_core/erp-icons/icons/web/tanuki-verified';
import { TaskDone } from '@erp_core/erp-icons/icons/web/task-done';
import { Template } from '@erp_core/erp-icons/icons/web/template';
import { Terminal } from '@erp_core/erp-icons/icons/web/terminal';
import { Terraform } from '@erp_core/erp-icons/icons/web/terraform';
import { TextDescription } from '@erp_core/erp-icons/icons/web/text-description';
import { ThumbDown } from '@erp_core/erp-icons/icons/web/thumb-down';
import { ThumbUp } from '@erp_core/erp-icons/icons/web/thumb-up';
import { Thumbtack } from '@erp_core/erp-icons/icons/web/thumbtack';
import { ThumbtackSolid } from '@erp_core/erp-icons/icons/web/thumbtack-solid';
import { TimeOut } from '@erp_core/erp-icons/icons/web/time-out';
import { Timer } from '@erp_core/erp-icons/icons/web/timer';
import { Title } from '@erp_core/erp-icons/icons/web/title';
import { TodoAdd } from '@erp_core/erp-icons/icons/web/todo-add';
import { TodoDone } from '@erp_core/erp-icons/icons/web/todo-done';
import { Token } from '@erp_core/erp-icons/icons/web/token';
import { TrendDown } from '@erp_core/erp-icons/icons/web/trend-down';
import { TrendStatic } from '@erp_core/erp-icons/icons/web/trend-static';
import { TrendUp } from '@erp_core/erp-icons/icons/web/trend-up';
import { TriggerSource } from '@erp_core/erp-icons/icons/web/trigger-source';
import { Twitter } from '@erp_core/erp-icons/icons/web/twitter';
import { Unapproval } from '@erp_core/erp-icons/icons/web/unapproval';
import { Unassignee } from '@erp_core/erp-icons/icons/web/unassignee';
import { Underline } from '@erp_core/erp-icons/icons/web/underline';
import { Unlink } from '@erp_core/erp-icons/icons/web/unlink';
import { UnpaidLeave } from '@erp_core/erp-icons/icons/web/unpaid-leave';
import { UnstageAll } from '@erp_core/erp-icons/icons/web/unstage-all';
import { Upgrade } from '@erp_core/erp-icons/icons/web/upgrade';
import { Upload } from '@erp_core/erp-icons/icons/web/upload';
import { User } from '@erp_core/erp-icons/icons/web/user';
import { Users } from '@erp_core/erp-icons/icons/web/users';
import { Vendor } from '@erp_core/erp-icons/icons/web/vendor';
import { VolumeUp } from '@erp_core/erp-icons/icons/web/volume-up';
import { Warning } from '@erp_core/erp-icons/icons/web/warning';
import { WarningSolid } from '@erp_core/erp-icons/icons/web/warning-solid';
import { Weight } from '@erp_core/erp-icons/icons/web/weight';
import { Work } from '@erp_core/erp-icons/icons/web/work';
import { WorkArea } from '@erp_core/erp-icons/icons/web/work-area';

const icons: { Icon: any; title: string }[] = [
  { Icon: Abuse, title: 'Abuse' },
  { Icon: Accessibility, title: 'Accessibility' },
  { Icon: Account, title: 'Account' },
  { Icon: Admin, title: 'Admin' },
  { Icon: Api, title: 'Api' },
  { Icon: Appearance, title: 'Appearance' },
  { Icon: Application, title: 'Application' },
  { Icon: Approval, title: 'Approval' },
  { Icon: ApprovalSolid, title: 'ApprovalSolid' },
  { Icon: ApprovedBadge, title: 'ApprovedBadge' },
  { Icon: Archive, title: 'Archive' },
  { Icon: ArrowDown, title: 'ArrowDown' },
  { Icon: ArrowLeft, title: 'ArrowLeft' },
  { Icon: ArrowRight, title: 'ArrowRight' },
  { Icon: Arrowup, title: 'Arrowup' },
  { Icon: Assignee, title: 'Assignee' },
  { Icon: At, title: 'At' },
  { Icon: Attention, title: 'Attention' },
  { Icon: AttentionSolid, title: 'AttentionSolid' },
  { Icon: AttentionSolidSm, title: 'AttentionSolidSm' },
  { Icon: Bank, title: 'Bank' },
  { Icon: BitBucket, title: 'BitBucket' },
  { Icon: Bold, title: 'Bold' },
  { Icon: BOM, title: 'BOM' },
  { Icon: BomDraftRegister, title: 'BomDraftRegister' },
  { Icon: BomRegister, title: 'BomRegister' },
  { Icon: Book, title: 'Book' },
  { Icon: BookMark, title: 'BookMark' },
  { Icon: Branch, title: 'Branch' },
  { Icon: BranchDeleted, title: 'BranchDeleted' },
  { Icon: BrandZoom, title: 'BrandZoom' },
  { Icon: Bug, title: 'Bug' },
  { Icon: Building, title: 'Building' },
  { Icon: Bulb, title: 'Bulb' },
  { Icon: BullHorn, title: 'BullHorn' },
  { Icon: Calendar, title: 'Calendar' },
  { Icon: Cancel, title: 'Cancel' },
  { Icon: CanceledCircle, title: 'CanceledCircle' },
  { Icon: Car, title: 'Car' },
  { Icon: CasualLeave, title: 'CasualLeave' },
  { Icon: Chart, title: 'Chart' },
  { Icon: Check, title: 'Check' },
  { Icon: CheckCircle, title: 'CheckCircle' },
  { Icon: CheckCircleDashed, title: 'CheckCircleDashed' },
  { Icon: CheckCircleFilled, title: 'CheckCircleFilled' },
  { Icon: CheckSm, title: 'CheckSm' },
  { Icon: CherryPickCommit, title: 'CherryPickCommit' },
  { Icon: ChevronDoubleLgLeft, title: 'ChevronDoubleLgLeft' },
  { Icon: ChevronDoubleLgRight, title: 'ChevronDoubleLgRight' },
  { Icon: ChevronDown, title: 'ChevronDown' },
  { Icon: ChevronLeft, title: 'ChevronLeft' },
  { Icon: ChevronLgDown, title: 'ChevronLgDown' },
  { Icon: ChevronLgLeft, title: 'ChevronLgLeft' },
  { Icon: ChevronLgUp, title: 'ChevronLgUp' },
  { Icon: ChevronRight, title: 'ChevronRight' },
  { Icon: ChevronUp, title: 'ChevronUp' },
  { Icon: Clear, title: 'Clear' },
  { Icon: ClearAll, title: 'ClearAll' },
  { Icon: Clock, title: 'Clock' },
  { Icon: Close, title: 'Close' },
  { Icon: CloudGear, title: 'CloudGear' },
  { Icon: CloudPod, title: 'CloudPod' },
  { Icon: CloudTerminal, title: 'CloudTerminal' },
  { Icon: Code, title: 'Code' },
  { Icon: Collapse, title: 'Collapse' },
  { Icon: CollapseLeft, title: 'CollapseLeft' },
  { Icon: CollapseRight, title: 'CollapseRight' },
  { Icon: CollapseSolid, title: 'CollapseSolid' },
  { Icon: College, title: 'College' },
  { Icon: Comment, title: 'Comment' },
  { Icon: CommentDots, title: 'CommentDots' },
  { Icon: CommentLines, title: 'CommentLines' },
  { Icon: CommentNext, title: 'CommentNext' },
  { Icon: Comments, title: 'Comments' },
  { Icon: Commit, title: 'Commit' },
  { Icon: Comparison, title: 'Comparison' },
  { Icon: Compass, title: 'Compass' },
  { Icon: CompensatoryOff, title: 'CompensatoryOff' },
  { Icon: Connected, title: 'Connected' },
  { Icon: ContainerImage, title: 'ContainerImage' },
  { Icon: CopyToClipboard, title: 'CopyToClipboard' },
  { Icon: CreditCard, title: 'CreditCard' },
  { Icon: Dash, title: 'Dash' },
  { Icon: DashBoard, title: 'DashBoard' },
  { Icon: Deployments, title: 'Deployments' },
  { Icon: DetailsBlock, title: 'DetailsBlock' },
  { Icon: Diagram, title: 'Diagram' },
  { Icon: Discord, title: 'Discord' },
  { Icon: Disk, title: 'Disk' },
  { Icon: DocChanges, title: 'DocChanges' },
  { Icon: DocChart, title: 'DocChart' },
  { Icon: DocCode, title: 'DocCode' },
  { Icon: DocCompressed, title: 'DocCompressed' },
  { Icon: DocExpand, title: 'DocExpand' },
  { Icon: DocImage, title: 'DocImage' },
  { Icon: DocNew, title: 'DocNew' },
  { Icon: DocSymlink, title: 'DocSymlink' },
  { Icon: Document, title: 'Document' },
  { Icon: Documents, title: 'Documents' },
  { Icon: DocVersions, title: 'DocVersions' },
  { Icon: Dotgrid, title: 'Dotgrid' },
  { Icon: DottedCircle, title: 'DottedCircle' },
  { Icon: DoubleHeadedArrow, title: 'DoubleHeadedArrow' },
  { Icon: Download, title: 'Download' },
  { Icon: DumbBell, title: 'DumbBell' },
  { Icon: Duplicate, title: 'Duplicate' },
  { Icon: Earth, title: 'Earth' },
  { Icon: EducationalDetails, title: 'EducationalDetails' },
  { Icon: EllipsisH, title: 'EllipsisH' },
  { Icon: EllipsisV, title: 'EllipsisV' },
  { Icon: EmployeeProfile, title: 'EmployeeProfile' },
  { Icon: EntityBlocked, title: 'EntityBlocked' },
  { Icon: Environment, title: 'Environment' },
  { Icon: Epic, title: 'Epic' },
  { Icon: EpicClosed, title: 'EpicClosed' },
  { Icon: Error, title: 'Error' },
  { Icon: ExcelDownload, title: 'ExcelDownload' },
  { Icon: ExcelUpload, title: 'ExcelUpload' },
  { Icon: ExecutiveFemale, title: 'ExecutiveFemale' },
  { Icon: ExecutiveMale, title: 'ExecutiveMale' },
  { Icon: Expand, title: 'Expand' },
  { Icon: ExpandDown, title: 'ExpandDown' },
  { Icon: ExpandLeft, title: 'ExpandLeft' },
  { Icon: ExpandRight, title: 'ExpandRight' },
  { Icon: ExpandUp, title: 'ExpandUp' },
  { Icon: Expire, title: 'Expire' },
  { Icon: Export, title: 'Export' },
  { Icon: ExternalLink, title: 'ExternalLink' },
  { Icon: Eye, title: 'Eye' },
  { Icon: EyeSlash, title: 'EyeSlash' },
  { Icon: FaceNeutral, title: 'FaceNeutral' },
  { Icon: FaceUnhappy, title: 'FaceUnhappy' },
  { Icon: FactoryManager, title: 'FactoryManager' },
  { Icon: FactoryWorkerFemale, title: 'FactoryWorkerFemale' },
  { Icon: FactoryWorkerMale, title: 'FactoryWorkerMale' },
  { Icon: FalsePositive, title: 'FalsePositive' },
  { Icon: FeatureFlag, title: 'FeatureFlag' },
  { Icon: FeatureFlagDisabled, title: 'FeatureFlagDisabled' },
  { Icon: FileAddition, title: 'FileAddition' },
  { Icon: FileAdditionSolid, title: 'FileAdditionSolid' },
  { Icon: FileDeletion, title: 'FileDeletion' },
  { Icon: FileDeletionSolid, title: 'FileDeletionSolid' },
  { Icon: FileModified, title: 'FileModified' },
  { Icon: FileModifiedSolid, title: 'FileModifiedSolid' },
  { Icon: FileTree, title: 'FileTree' },
  { Icon: Filter, title: 'Filter' },
  { Icon: FinishedGood, title: 'FinishedGood' },
  { Icon: Fire, title: 'Fire' },
  { Icon: FirstContribution, title: 'FirstContribution' },
  { Icon: Flag, title: 'Flag' },
  { Icon: Folder, title: 'Folder' },
  { Icon: FolderNew, title: 'FolderNew' },
  { Icon: FolderO, title: 'FolderO' },
  { Icon: FolderOpen, title: 'FolderOpen' },
  { Icon: Food, title: 'Food' },
  { Icon: Fork, title: 'Fork' },
  { Icon: Formula, title: 'Formula' },
  { Icon: Git, title: 'Git' },
  { Icon: Gitea, title: 'Gitea' },
  { Icon: Github, title: 'Github' },
  { Icon: GitMerge, title: 'GitMerge' },
  { Icon: GoBack, title: 'GoBack' },
  { Icon: GodownsList, title: 'GodownsList' },
  { Icon: GodownTransfer, title: 'GodownTransfer' },
  { Icon: Google, title: 'Google' },
  { Icon: Grip, title: 'Grip' },
  { Icon: Group, title: 'Group' },
  { Icon: Hamburger, title: 'Hamburger' },
  { Icon: Heading, title: 'Heading' },
  { Icon: Heart, title: 'Heart' },
  { Icon: HelperFemale, title: 'HelperFemale' },
  { Icon: HelperMale, title: 'HelperMale' },
  { Icon: HighLight, title: 'HighLight' },
  { Icon: History, title: 'History' },
  { Icon: Home, title: 'Home' },
  { Icon: Hook, title: 'Hook' },
  { Icon: Hourglass, title: 'Hourglass' },
  { Icon: ImageCommentDark, title: 'ImageCommentDark' },
  { Icon: ImageCommentLight, title: 'ImageCommentLight' },
  { Icon: Import, title: 'Import' },
  { Icon: Incognito, title: 'Incognito' },
  { Icon: Information, title: 'Information' },
  { Icon: InformationO, title: 'InformationO' },
  { Icon: InfrastructureRegistry, title: 'InfrastructureRegistry' },
  { Icon: IntermediatePoint, title: 'IntermediatePoint' },
  { Icon: InventoryTree, title: 'InventoryTree' },
  { Icon: IssueBlock, title: 'IssueBlock' },
  { Icon: IssueClose, title: 'IssueClose' },
  { Icon: IssueClosed, title: 'IssueClosed' },
  { Icon: IssueNew, title: 'IssueNew' },
  { Icon: IssueOpenM, title: 'IssueOpenM' },
  { Icon: Issues, title: 'Issues' },
  { Icon: IssueTypeEnhancement, title: 'IssueTypeEnhancement' },
  { Icon: IssueTypeFeature, title: 'IssueTypeFeature' },
  { Icon: IssueTypeFeatureFlag, title: 'IssueTypeFeatureFlag' },
  { Icon: IssueTypeIncident, title: 'IssueTypeIncident' },
  { Icon: IssueTypeIssue, title: 'IssueTypeIssue' },
  { Icon: IssueTypeKeyResult, title: 'IssueTypeKeyResult' },
  { Icon: IssueTypeMaintenance, title: 'IssueTypeMaintenance' },
  { Icon: IssueTypeObjective, title: 'IssueTypeObjective' },
  { Icon: IssueTypeRequirements, title: 'IssueTypeRequirements' },
  { Icon: IssueTypeTask, title: 'IssueTypeTask' },
  { Icon: IssueTypeTestCase, title: 'IssueTypeTestCase' },
  { Icon: IssueUpdate, title: 'IssueUpdate' },
  { Icon: Italic, title: 'Italic' },
  { Icon: ItemBatches, title: 'ItemBatches' },
  { Icon: ItemList, title: 'ItemList' },
  { Icon: Iteration, title: 'Iteration' },
  { Icon: Key, title: 'Key' },
  { Icon: KeyBoard, title: 'KeyBoard' },
  { Icon: Kids, title: 'Kids' },
  { Icon: KubernetesAgent, title: 'KubernetesAgent' },
  { Icon: Label, title: 'Label' },
  { Icon: Labels, title: 'Labels' },
  { Icon: Leave, title: 'Leave' },
  { Icon: LevelUp, title: 'LevelUp' },
  { Icon: License, title: 'License' },
  { Icon: LicenseSM, title: 'LicenseSM' },
  { Icon: Link, title: 'Link' },
  { Icon: Linkedin, title: 'Linkedin' },
  { Icon: ListBulleted, title: 'ListBulleted' },
  { Icon: ListIndent, title: 'ListIndent' },
  { Icon: ListNumbered, title: 'ListNumbered' },
  { Icon: ListOutdent, title: 'ListOutdent' },
  { Icon: ListTask, title: 'ListTask' },
  { Icon: LivePreview, title: 'LivePreview' },
  { Icon: LiveStream, title: 'LiveStream' },
  { Icon: Location, title: 'Location' },
  { Icon: LocationDot, title: 'LocationDot' },
  { Icon: LocationMarker, title: 'LocationMarker' },
  { Icon: Lock, title: 'Lock' },
  { Icon: LockOpen, title: 'LockOpen' },
  { Icon: Log, title: 'Log' },
  { Icon: LongArrow, title: 'LongArrow' },
  { Icon: MachineIcon, title: 'MachineIcon' },
  { Icon: MachineLearning, title: 'MachineLearning' },
  { Icon: Mail, title: 'Mail' },
  { Icon: ManagerFemale, title: 'ManagerFemale' },
  { Icon: ManagerMale, title: 'ManagerMale' },
  { Icon: MarkDownMark, title: 'MarkDownMark' },
  { Icon: MarkDownMarkSolid, title: 'MarkDownMarkSolid' },
  { Icon: MarqueeSelection, title: 'MarqueeSelection' },
  { Icon: MaternityLeave, title: 'MaternityLeave' },
  { Icon: Maximize, title: 'Maximize' },
  { Icon: Media, title: 'Media' },
  { Icon: MediaBroken, title: 'MediaBroken' },
  { Icon: Merge, title: 'Merge' },
  { Icon: MergeRequest, title: 'MergeRequest' },
  { Icon: MergeRequestClose, title: 'MergeRequestClose' },
  { Icon: MergeRequestCloseM, title: 'MergeRequestCloseM' },
  { Icon: MergeRequestOpen, title: 'MergeRequestOpen' },
  { Icon: Messages, title: 'Messages' },
  { Icon: Microphone, title: 'Microphone' },
  { Icon: Minimize, title: 'Minimize' },
  { Icon: Mobile, title: 'Mobile' },
  { Icon: MobileIssueClose, title: 'MobileIssueClose' },
  { Icon: Monitor, title: 'Monitor' },
  { Icon: MonitorLines, title: 'MonitorLines' },
  { Icon: MonitorO, title: 'MonitorO' },
  { Icon: MrsCompleted, title: 'MrsCompleted' },
  { Icon: MrsRegister, title: 'MrsRegister' },
  { Icon: NameSpace, title: 'NameSpace' },
  { Icon: Nature, title: 'Nature' },
  { Icon: Notifications, title: 'Notifications' },
  { Icon: NotificationsOff, title: 'NotificationsOff' },
  { Icon: ObjectIcon, title: 'ObjectIcon' },
  { Icon: OfficeArea, title: 'OfficeArea' },
  { Icon: OfficeStaffFemale, title: 'OfficeStaffFemale' },
  { Icon: OfficeStaffMale, title: 'OfficeStaffMale' },
  { Icon: Organization, title: 'Organization' },
  { Icon: OverView, title: 'OverView' },
  { Icon: Package, title: 'Package' },
  { Icon: PaperAirplane, title: 'PaperAirplane' },
  { Icon: PaperClip, title: 'PaperClip' },
  { Icon: Parents, title: 'Parents' },
  { Icon: Pause, title: 'Pause' },
  { Icon: Pencil, title: 'Pencil' },
  { Icon: PencilSquar, title: 'PencilSquar' },
  { Icon: PersonalInfo, title: 'PersonalInfo' },
  { Icon: PipeLine, title: 'PipeLine' },
  { Icon: Planing, title: 'Planing' },
  { Icon: Play, title: 'Play' },
  { Icon: Plus, title: 'Plus' },
  { Icon: PlusSquare, title: 'PlusSquare' },
  { Icon: PlusSquareO, title: 'PlusSquareO' },
  { Icon: Pod, title: 'Pod' },
  { Icon: PodCast, title: 'PodCast' },
  { Icon: Power, title: 'Power' },
  { Icon: Preferences, title: 'Preferences' },
  { Icon: PrivilegeLeave, title: 'PrivilegeLeave' },
  { Icon: Profile, title: 'Profile' },
  { Icon: Progress, title: 'Progress' },
  { Icon: Project, title: 'Project' },
  { Icon: PurchaseOrder, title: 'PurchaseOrder' },
  { Icon: PushRules, title: 'PushRules' },
  { Icon: Question, title: 'Question' },
  { Icon: Questionnaire, title: 'Questionnaire' },
  { Icon: QuestionO, title: 'QuestionO' },
  { Icon: QuickActions, title: 'QuickActions' },
  { Icon: Quota, title: 'Quota' },
  { Icon: Quote, title: 'Quote' },
  { Icon: RawMaterial, title: 'RawMaterial' },
  { Icon: Recat, title: 'Recat' },
  { Icon: Redo, title: 'Redo' },
  { Icon: RejectedBadge, title: 'RejectedBadge' },
  { Icon: Remove, title: 'Remove' },
  { Icon: RemoveAll, title: 'RemoveAll' },
  { Icon: Repeat, title: 'Repeat' },
  { Icon: Reply, title: 'Reply' },
  { Icon: Requirements, title: 'Requirements' },
  { Icon: Retry, title: 'Retry' },
  { Icon: ReviewCheckmark, title: 'ReviewCheckmark' },
  { Icon: ReviewList, title: 'ReviewList' },
  { Icon: ReviewWarning, title: 'ReviewWarning' },
  { Icon: Rocket, title: 'Rocket' },
  { Icon: RocketLaunch, title: 'RocketLaunch' },
  { Icon: Rss, title: 'Rss' },
  { Icon: Scale, title: 'Scale' },
  { Icon: ScrollDown, title: 'ScrollDown' },
  { Icon: ScrollHandle, title: 'ScrollHandle' },
  { Icon: ScrollUp, title: 'ScrollUp' },
  { Icon: Search, title: 'Search' },
  { Icon: SearchDot, title: 'SearchDot' },
  { Icon: SearchMinus, title: 'SearchMinus' },
  { Icon: SearchPlus, title: 'SearchPlus' },
  { Icon: SearchResults, title: 'SearchResults' },
  { Icon: SearchSm, title: 'SearchSm' },
  { Icon: Settimgs, title: 'Settimgs' },
  { Icon: SeverityCritical, title: 'SeverityCritical' },
  { Icon: SeverityHigh, title: 'SeverityHigh' },
  { Icon: SeverityInfo, title: 'SeverityInfo' },
  { Icon: SeverityMedium, title: 'SeverityMedium' },
  { Icon: SeverityUnknown, title: 'SeverityUnknown' },
  { Icon: Share, title: 'Share' },
  { Icon: Shield, title: 'Shield' },
  { Icon: SickLeave, title: 'SickLeave' },
  { Icon: Sidebar, title: 'Sidebar' },
  { Icon: SidebarRight, title: 'SidebarRight' },
  { Icon: Skype, title: 'Skype' },
  { Icon: SlightFrown, title: 'SlightFrown' },
  { Icon: SlightSmile, title: 'SlightSmile' },
  { Icon: SmartCard, title: 'SmartCard' },
  { Icon: Smile, title: 'Smile' },
  { Icon: Smiley, title: 'Smiley' },
  { Icon: Snippet, title: 'Snippet' },
  { Icon: SoftUnwrap, title: 'SoftUnwrap' },
  { Icon: SoftWrap, title: 'SoftWrap' },
  { Icon: SortHighest, title: 'SortHighest' },
  { Icon: SortLowest, title: 'SortLowest' },
  { Icon: Spam, title: 'Spam' },
  { Icon: Spinner, title: 'Spinner' },
  { Icon: StageAll, title: 'StageAll' },
  { Icon: Star, title: 'Star' },
  { Icon: StarO, title: 'StarO' },
  { Icon: Status, title: 'Status' },
  { Icon: StatusAlert, title: 'StatusAlert' },
  { Icon: StatusCanceled, title: 'StatusCanceled' },
  { Icon: StatusCanceledBorderless, title: 'StatusCanceledBorderless' },
  { Icon: StatusCancelled, title: 'StatusCancelled' },
  { Icon: StatusClosed, title: 'StatusClosed' },
  { Icon: StatusCreated, title: 'StatusCreated' },
  { Icon: StatusFailed, title: 'StatusFailed' },
  { Icon: StatusFailedBorderless, title: 'StatusFailedBorderless' },
  { Icon: StatusFailedX, title: 'StatusFailedX' },
  { Icon: StatusHealth, title: 'StatusHealth' },
  { Icon: StatusManual, title: 'StatusManual' },
  { Icon: StatusManualBorderless, title: 'StatusManualBorderless' },
  { Icon: StatusNeautral, title: 'StatusNeautral' },
  { Icon: StatusNotFoundBorderless, title: 'StatusNotFoundBorderless' },
  { Icon: StatusOpen, title: 'StatusOpen' },
  { Icon: StatusPaused, title: 'StatusPaused' },
  { Icon: StatusPendingBorderless, title: 'StatusPendingBorderless' },
  { Icon: StatusRuning, title: 'StatusRuning' },
  { Icon: StatusRunning, title: 'StatusRunning' },
  { Icon: StatusRunningBorderless, title: 'StatusRunningBorderless' },
  { Icon: StatusScheduled, title: 'StatusScheduled' },
  { Icon: StatusScheduledBorderless, title: 'StatusScheduledBorderless' },
  { Icon: StatusStopped, title: 'StatusStopped' },
  { Icon: StatusSuccess, title: 'StatusSuccess' },
  { Icon: StatusSuccessBorderless, title: 'StatusSuccessBorderless' },
  { Icon: StatusSuccessSolid, title: 'StatusSuccessSolid' },
  { Icon: StatusWaiting, title: 'StatusWaiting' },
  { Icon: StatusWarningBorderless, title: 'StatusWarningBorderless' },
  { Icon: Stockgroup, title: 'Stockgroup' },
  { Icon: StockGroupList, title: 'StockGroupList' },
  { Icon: StockGroupListSolid, title: 'StockGroupListSolid' },
  { Icon: Stocks, title: 'Stocks' },
  { Icon: StrikeThrough, title: 'StrikeThrough' },
  { Icon: SubGroup, title: 'SubGroup' },
  { Icon: SubScript, title: 'SubScript' },
  { Icon: Subsitute, title: 'Subsitute' },
  { Icon: SuperScript, title: 'SuperScript' },
  { Icon: Symlink, title: 'Symlink' },
  { Icon: Table, title: 'Table' },
  { Icon: Tablet, title: 'Tablet' },
  { Icon: Tachometer, title: 'Tachometer' },
  { Icon: Tag, title: 'Tag' },
  { Icon: Tanuki, title: 'Tanuki' },
  { Icon: TanukiAi, title: 'TanukiAi' },
  { Icon: TanukiVerified, title: 'TanukiVerified' },
  { Icon: TaskDone, title: 'TaskDone' },
  { Icon: Template, title: 'Template' },
  { Icon: Terminal, title: 'Terminal' },
  { Icon: Terraform, title: 'Terraform' },
  { Icon: TextDescription, title: 'TextDescription' },
  { Icon: ThumbDown, title: 'ThumbDown' },
  { Icon: Thumbtack, title: 'Thumbtack' },
  { Icon: ThumbtackSolid, title: 'ThumbtackSolid' },
  { Icon: ThumbUp, title: 'ThumbUp' },
  { Icon: TimeOut, title: 'TimeOut' },
  { Icon: Timer, title: 'Timer' },
  { Icon: Title, title: 'Title' },
  { Icon: TodoAdd, title: 'TodoAdd' },
  { Icon: TodoDone, title: 'TodoDone' },
  { Icon: Token, title: 'Token' },
  { Icon: TrendDown, title: 'TrendDown' },
  { Icon: TrendStatic, title: 'TrendStatic' },
  { Icon: TrendUp, title: 'TrendUp' },
  { Icon: TriggerSource, title: 'TriggerSource' },
  { Icon: Twitter, title: 'Twitter' },
  { Icon: Unapproval, title: 'Unapproval' },
  { Icon: Unassignee, title: 'Unassignee' },
  { Icon: Underline, title: 'Underline' },
  { Icon: Unlink, title: 'Unlink' },
  { Icon: UnpaidLeave, title: 'UnpaidLeave' },
  { Icon: UnstageAll, title: 'UnstageAll' },
  { Icon: Upgrade, title: 'Upgrade' },
  { Icon: Upload, title: 'Upload' },
  { Icon: User, title: 'User' },
  { Icon: Users, title: 'Users' },
  { Icon: Vendor, title: 'Vendor' },
  { Icon: VolumeUp, title: 'VolumeUp' },
  { Icon: Warning, title: 'Warning' },
  { Icon: WarningSolid, title: 'WarningSolid' },
  { Icon: Weight, title: 'Weight' },
  { Icon: Work, title: 'Work' },
  { Icon: WorkArea, title: 'WorkArea' },
];

export function Icons(): JSX.Element {
  return (
    <>
      <div className='m-2 bg-gray-50 flex flex-wrap'>
        {icons.map((x, idx) => (
          <div
            key={idx}
            title={x.title}
            className='w-1/12 h-24 border border-gray-200 rounded-lg my-auto'
          >
            <x.Icon className='w-12 h-24 text-gray-900 fill-gray-200 stroke-blue-700 mx-auto my-auto ' />
          </div>
        ))}
      </div>
      <div className='grid grid-cols-2'>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-3'>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-5'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-4'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-6'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-8'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className='grid grid-cols-7'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </>
  );
}
