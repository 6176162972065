/* eslint-disable class-methods-use-this */

import { RoleType } from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { RoleFilterType } from '../../../../models/interfaces/admin/role-admin';
import { GenericHttpWithLogsRepo } from '../generic-resource-with-logs';

export class HttpRoleAdminRepo extends GenericHttpWithLogsRepo<
  RoleType & { isSystemRole: boolean },
  RoleFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/roles',
      notificationConfig: {
        set: {
          create: {
            enable: true,
            defaultMessage: {
              title: 'Role Created',
            },
          },
          update: {
            enable: true,
            defaultMessage: {
              title: 'Role updated.',
            },
            // dynamicMessage: (req: any)=>{
            //   return {
            //     title: 'Role field updated.'
            //   }
            // }
          },
        },
      },
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
