import {
  MachineSchedule,
  MachineScheduleFilter,
} from '@erp_core/erp-types/dist/modules/planning';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpMachineScheduleRepo extends GenericHttpWithLogsRepo<
  MachineSchedule,
  MachineScheduleFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/production/machines/schedules',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getByMachineId(id: string): Promise<MachineSchedule> {
    const axios = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: `${this.baseURI}${this.resourceEndpoint}`,
    });
    const res = await axios.get(`/${id}/machine-id`);
    if (res.status !== 200) {
      throw new Error(`Resource with ${id} does not exist`);
    }
    const { data } = res;
    return data as MachineSchedule;
  }
}
