import { SalaryAdvanceType } from '@erp_core/erp-types/dist/types/modules/hrd/salary-advance';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type SalaryAdvanceActionFormType = {
  status: string;
  approvalRejectionReason: string;
  relasedAmount: string;
  releasedDate: string;
};

export const SalaryAdvanceApprovalFormComponent = () => {
  return function SalaryAdvanceApprovalForm({
    data,
    onSave,
  }: {
    data?: SalaryAdvanceType;
    onSave: (data: SalaryAdvanceType) => Promise<void>;
  }) {
    const formProps: FormProps<SalaryAdvanceActionFormType> = {
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          required: true,
          options: [
            { value: '', text: 'select' },
            { value: 'approved', text: 'approved' },
            { value: 'cancelled', text: 'cancelled' },
          ],
        },
        {
          property: 'relasedAmount',
          type: 'input',
          label: 'Released Amount',
          dependentOn: (formData) => {
            return formData.status === 'approved';
          },
        },
        {
          property: 'releasedDate',
          type: 'date',
          label: 'Released Date',
          dependentOn: (formData) => {
            return formData.status === 'approved';
          },
        },
        {
          property: 'approvalRejectionReason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        status: data?.status || '',
        relasedAmount: data?.details?.relasedAmount || '',
        releasedDate: data?.details?.releasedDate || '',
        approvalRejectionReason: data?.details?.approvalRejectionReason || '',
      },
      onSubmit: async (f) => {
        await onSave(({
          id: data?.id,

          status: f.status || data?.status,

          details: {
            relasedAmount: f.relasedAmount || data?.details?.relasedAmount,
            releasedDate: f.releasedDate || data?.details?.releasedDate,
            approvalRejectionReason:
              f.approvalRejectionReason ||
              data?.details?.approvalRejectionReason,
          },
        } as unknown) as SalaryAdvanceType);
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      button: {
        style: 'float-left',
      },
    };

    const Form = renderFormV2<SalaryAdvanceActionFormType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<SalaryAdvanceActionFormType>
    ): SalaryAdvanceActionFormType {
      return (b as unknown) as SalaryAdvanceActionFormType;
    }
  };
};
