import { SalaryParamGroup } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param-group';
import _ from 'lodash';

export function outerDependencyCalculator(
  salParams: SalaryParamGroup,
  res: Array<string>,
  innerParams: Array<string>,
  allParams: Array<string>,
  errors: Array<string>
): void {
  salParams.parameters?.forEach((p) => {
    if (allParams.includes(_.camelCase(p.name))) {
      errors.push(
        `${_.camelCase(p.name)} is already declared. Duplicate Detected!`
      );
    }
    allParams.push(_.camelCase(p.name));
    if (res.includes(_.camelCase(p.name))) {
      errors.push(
        `${_.camelCase(
          p.name
        )} seems to be used some condition before it is declared`
      );
    }
    if (p.valueType === 'derived') {
      p.examples?.forEach((ex) => {
        if (ex.input) {
          try {
            const parsed = JSON.parse(ex.input);
            for (const key in parsed) {
              if (allParams.includes(key)) {
                if (!innerParams.includes(key)) {
                  innerParams.push(key);
                }
              } else if (!res.includes(key)) {
                res.push(key);
              }
            }
          } catch (e) {
            console.log('idhar aya');
          }
        }
      });
    }
  });
  salParams.subgroups?.forEach((sbg) => {
    outerDependencyCalculator(sbg, res, innerParams, allParams, errors);
  });
}
