import { PolicyType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { UseCombinedPolicy } from '../../../../hooks/hrd/insurance/policy/use-policy';

type RenderAddPolicyForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedPolicy: UseCombinedPolicy;
};

// eslint-disable-next-line
export function renderAddPolicyForm({
  useCombinedEmployeeProfile,
  useCombinedPolicy,
}: RenderAddPolicyForm) {
  return function AddPolicyForm({
    onSave,
    isEdit,
    data,
  }: {
    onSave: (data: PolicyType) => Promise<void>;
    isEdit?: boolean;
    data?: PolicyType;
  }): JSX.Element {
    const { list: policies, getAll: getPolicies } = useCombinedPolicy();

    useEffect(() => {}, [policies]);

    useEffect(() => {
      getPolicies();
      // eslint-disable-next-line
    }, []);

    const validatePolicyNo = async (form) => {
      if (policies?.find((x) => x.policyNumber === form.policyNumber)) {
        return `${form.policyNumber} already exist`;
      }
      return '';
    };
    const formProps: FormProps<Partial<PolicyType>> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            filter: { crossGroup: true },
            onSearchValueSelect: () => {},
          },
          required: true,
          label: 'Employee Name',
        },
        {
          property: 'insuranceCompany',
          type: 'input',
          required: true,
          label: 'Insurance Company',
        },
        {
          property: 'policyNumber',
          type: 'input',
          label: 'Policy Number',
          validate: async (fd: any) => {
            const resp = await validatePolicyNo(fd);
            return {
              policyNumber: resp,
            };
          },
        },
        {
          property: 'amount',
          type: 'input',
          label: 'Amount',
          required: true,
        },
        {
          property: 'frequency',
          type: 'select',
          required: true,
          options: [
            { value: '', text: 'select' },
            { value: 'monthly', text: 'monthly' },
            { value: 'quarterly', text: 'quarterly' },
            { value: 'half-yearly', text: 'half-yearly' },
            { value: 'yearly', text: 'yearly' },
          ],
        },
      ],
      initialFormState: {
        employee: '',
        insuranceCompany: '',
        policyNumber: '',
        amount: '',
        frequency: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (f) => {
        await onSave({
          employee: f.employee || data?.employee,
          insuranceCompany: f.insuranceCompany || data?.insuranceCompany,
          policyNumber: f.policyNumber || data?.policyNumber,
          amount: f.amount || data?.amount,
          frequency: f.frequency || data?.frequency,
        } as PolicyType);
      },
    };

    const Form = renderFormV2<Partial<PolicyType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<PolicyType>>): PolicyType {
      return (b as unknown) as PolicyType;
    }
  };
}
