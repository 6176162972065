import { useEffect } from 'react';
import { UseCombinedBom } from '../../../hooks/order/work-order/bom/use-bom';

export function renderBOMName({
  useCombinedBom,
}: {
  useCombinedBom: UseCombinedBom;
}): // eslint-disable-next-line
({ id }: { id: string }) => JSX.Element {
  return function BOM({ id }: { id: string }): JSX.Element {
    const { resource: data, get } = useCombinedBom();
    useEffect(() => {
      get(id);
      // eslint-disable-next-line
    }, [id]);

    return data?.name ? (
      <a
        key={id}
        href={`/inventory/registers/bom/${id}/details`}
        target='_blank'
        className='border bg-green-100 rounded text-green-800 text-xs'
      >
        {data?.name}
      </a>
    ) : (
      <span />
    );
  };
}
