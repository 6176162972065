import { LocationType } from '@erp_core/erp-types/dist/modules/admin';
import { renderCardComponent, Strip } from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedLocationAdmin } from '../../../../hooks/admin/location-admin/use-location';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';

export function renderVendorGodowns({
  useCombinedLocationAdmin,
  useCombinedGodown,
}: {
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedGodown: UseCombinedGodown;
}) {
  const Card = renderCardComponent();

  return function VendorGodowns() {
    const {
      list: locations,
      getAll: getLocations,
    } = useCombinedLocationAdmin();
    const { company: currentCompany } = useContext(CurrentContext);
    const [vendorLocation, setVendorLocation] = useState<LocationType | null>(
      null
    );
    const { list: godowns, getAll: getGodowns } = useCombinedGodown();

    useEffect(() => {
      if (currentCompany) {
        getLocations({
          type: 'virtual',
          companyId: currentCompany.id,
        });
      }
      // eslint-disable-next-line
    }, [currentCompany]);

    useEffect(() => {
      if (locations?.length && currentCompany?.id) {
        const vendorLoc = `Vendors Loc ${currentCompany.name}`;
        const selectedLoc = locations.find((x) => x.name === vendorLoc);
        if (selectedLoc) {
          setVendorLocation(selectedLoc);
        } else {
          setVendorLocation(null);
        }
      }
    }, [locations, currentCompany]);

    useEffect(() => {
      if (vendorLocation?.id && currentCompany.id) {
        getGodowns({
          type: 'virtual',
          companyId: currentCompany.id,
          locationId: vendorLocation.id,
        });
      }
      // eslint-disable-next-line
    }, [vendorLocation, currentCompany]);

    if (!vendorLocation) {
      return (
        <Strip
          styleCSS={'my-2'}
          message={`Seems like you dont have any Vendor Location created for ${currentCompany.name}`}
          type='info'
        />
      );
    }

    return (
      <Card
        header={{ title: `Godowns at ${vendorLocation.name}` }}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {godowns?.length === 0 ? (
                <Strip
                  message={`Seems link there are no virtual godowns at ${vendorLocation.name}`}
                  type='info'
                />
              ) : null}
              {godowns?.map((x) => (
                <div key={x.id}>
                  Name:{x.name} - Company:{x.company.name} - Location:
                  {x.location.name} - Type:{x.type}
                </div>
              ))}
            </>
          ),
        }}
      />
    );
  };
}
