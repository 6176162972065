import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import toast from 'react-hot-toast';
import {
  ContactDetails,
  renderEditContactDetailsForm,
} from '../../profile-form/contact-details';

export function renderContactDetails(): (p: {
  setEmployee: (s: EmployeeProfileType) => void;
  employee: EmployeeProfileType;
  isHr: boolean;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}) => JSX.Element {
  return function ContactDetails({
    setEmployee,
    employee,
    isHr,
    actions,
  }: {
    setEmployee: (s: EmployeeProfileType) => void;
    employee: EmployeeProfileType;
    isHr: boolean;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    const handleSaveEdit = async (data: ContactDetails) => {
      const finalData: EmployeeProfileType = {
        id: employee.id,
        details: {
          contactDetails: {
            mobileNumber: {
              mobile1: data.mobile1,
              mobile2: data.mobile2,
            },
            emailAddress: {
              personalEmail: data.personalEmail,
              officeEmail: data.officeEmail,
              departmentEmail: data.departmentEmail,
            },
            currentAddress: {
              addressLine1: data.currentAddressLine1,
              addressLine2: data.currentAddressLine2,
              country: data.currentAddressCountry,
              state: data.currentAddressState,
              pincode: data.currentAddressPincode,
              city: data.currentAddressCity,
              district: data.currentAddressDistrict,
            },
            permanentAddress: {
              addressLine1: data.permanentAddressLine1,
              addressLine2: data.permanentAddressLine2,
              country: data.permanentAddressCountry,
              state: data.permanentAddressState,
              pincode: data.permanentAddressPincode,
              city: data.permanentAddressCity,
              district: data.permanentAddressDistrict,
            },
          },
        },
      } as any;

      try {
        setEmployee(finalData as EmployeeProfileType);
      } catch (error) {
        toast((error as any).message);
      }
    };

    const ContactDetailCard = renderCardComponent();
    const ContactDetailCardHeader: CardHeader = {
      title: 'Contact Detail',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          show: () => isHr,
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              title: 'Edit Contact Detail',
              content: ({ onClose }) => {
                const ContactDetailsEditFormData: ContactDetails = {
                  mobile1:
                    employee.details?.contactDetails?.mobileNumber?.mobile1,
                  mobile2:
                    employee.details?.contactDetails?.mobileNumber?.mobile2,
                  personalEmail:
                    employee.details?.contactDetails?.emailAddress
                      ?.personalEmail,
                  officeEmail:
                    employee.details?.contactDetails?.emailAddress?.officeEmail,
                  departmentEmail:
                    employee.details?.contactDetails?.emailAddress
                      ?.departmentEmail || '',
                  permanentAddressLine1:
                    employee.details?.contactDetails?.permanentAddress
                      ?.addressLine1,
                  permanentAddressLine2:
                    employee.details?.contactDetails?.permanentAddress
                      ?.addressLine2,
                  permanentAddressCity:
                    employee.details?.contactDetails?.permanentAddress?.city,
                  permanentAddressDistrict:
                    employee.details?.contactDetails?.permanentAddress
                      ?.district,
                  permanentAddressPincode:
                    employee.details?.contactDetails?.permanentAddress?.pincode,
                  permanentAddressCountry:
                    employee.details?.contactDetails?.permanentAddress?.country,
                  permanentAddressState:
                    employee.details?.contactDetails?.permanentAddress?.state,
                  currentAddressLine1:
                    employee.details?.contactDetails?.currentAddress
                      ?.addressLine1,
                  currentAddressLine2:
                    employee.details?.contactDetails?.currentAddress
                      ?.addressLine2,
                  currentAddressCity:
                    employee.details?.contactDetails?.currentAddress?.city,
                  currentAddressDistrict:
                    employee.details?.contactDetails?.currentAddress?.district,
                  currentAddressPincode:
                    employee.details?.contactDetails?.currentAddress?.pincode,
                  currentAddressCountry:
                    employee.details?.contactDetails?.currentAddress?.country,
                  currentAddressState:
                    employee.details?.contactDetails?.currentAddress?.state,
                };
                const Form1 = renderEditContactDetailsForm();
                return (
                  <Form1
                    data={ContactDetailsEditFormData}
                    onSave={async (form) => {
                      handleSaveEdit(form);
                      onClose();
                    }}
                    onCancel={onClose}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const ContactDetailCardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div className='flex'>
          <div className='w-1/3 p-1'>
            <div className='border border-gray-200 rounded p-1'>
              <div className='font-semibold text-center'>Permanent Address</div>
              <div>
                <div>
                  {
                    employee.details?.contactDetails?.permanentAddress
                      ?.addressLine1
                  }
                </div>
                <div>
                  {employee.details?.contactDetails?.permanentAddress
                    ?.addressLine2 || '-'}
                </div>
                <div>
                  {employee.details?.contactDetails?.permanentAddress?.city} -{' '}
                  {employee.details?.contactDetails?.permanentAddress?.pincode}
                </div>
                <div>
                  {employee.details?.contactDetails?.permanentAddress?.district
                    ? `${employee.details?.contactDetails?.permanentAddress?.district}, `
                    : null}
                  {employee.details?.contactDetails?.permanentAddress?.state ||
                    ''}
                </div>
                <div>
                  {employee.details?.contactDetails?.permanentAddress?.country}
                </div>
              </div>
            </div>
          </div>
          <div className='w-1/3 p-1'>
            <div className='border border-gray-200 rounded p-1'>
              <div className='font-semibold text-center'>Current Address</div>
              <div>
                <div>
                  {
                    employee.details?.contactDetails?.currentAddress
                      ?.addressLine1
                  }
                </div>
                <div>
                  {employee.details?.contactDetails?.currentAddress
                    ?.addressLine2 || '-'}
                </div>
                <div>
                  {employee.details?.contactDetails?.currentAddress?.city} -{' '}
                  {employee.details?.contactDetails?.currentAddress?.pincode}
                </div>
                <div>
                  {employee.details?.contactDetails?.currentAddress?.district
                    ? `${employee.details?.contactDetails?.currentAddress?.district}, `
                    : null}
                  {employee.details?.contactDetails?.currentAddress?.state ||
                    ''}
                </div>
                <div>
                  {employee.details?.contactDetails?.currentAddress?.country}
                </div>
              </div>
            </div>
          </div>
          <div className='w-1/3 p-1'>
            <div className='border border-gray-200 rounded p-1'>
              <div className='font-semibold text-center'>Email</div>
              <div>
                <div>
                  Personal:{' '}
                  {
                    employee.details?.contactDetails?.emailAddress
                      ?.personalEmail
                  }
                </div>
                <div>
                  Office:{' '}
                  {employee.details?.contactDetails?.emailAddress?.officeEmail}
                </div>
                <div>
                  Department:{' '}
                  {
                    employee.details?.contactDetails?.emailAddress
                      ?.departmentEmail
                  }
                </div>
                <div>
                  Mobile1:{' '}
                  {employee.details?.contactDetails?.mobileNumber?.mobile1}
                </div>
                <div>
                  Mobile2:{' '}
                  {employee.details?.contactDetails?.mobileNumber?.mobile2}{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    };

    return (
      <ContactDetailCard
        body={ContactDetailCardBody}
        header={ContactDetailCardHeader}
        auth={{ actions }}
      />
    );
  };
}
