import { RepaymentScheduleType } from '@erp_core/erp-types/dist/modules/hrd';
import { RepaymentScheduleFilter } from '@erp_core/erp-types/dist/types/modules/loan/repayment-schedule';
import {
  GetAdvPaymentReq,
  GetSkipRepaymentReq,
  PostActionRepaymentResp,
} from 'models/interfaces/loans/repayment-schedule';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class RepaymentScheduleHttpRepo extends GenericHttpWithLogsRepo<
  RepaymentScheduleType,
  RepaymentScheduleFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/repayment-schedules',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async skipRepayment(
    p: GetSkipRepaymentReq
  ): Promise<PostActionRepaymentResp> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/skip/?month=${p.month}&loanId=${p.loanId}`
    );

    if (res.status === 200) {
      return {
        success: true,
        message: toast('Skipped Repayment successfully'),
      };
    }

    return { success: false, message: toast('Skipped Repayment failed') };
  }

  async advancePayment(p: GetAdvPaymentReq): Promise<PostActionRepaymentResp> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/advance/?month=${p.month}&loanId=${p.loanId}&advanceRepaymentAmount=${p.advanceRepaymentAmount}&advancePaymentMode=${p.advancePaymentMode}`
    );

    if (res.status === 200) {
      return { success: true, message: toast('Advance Repayment added') };
    }

    return { success: false, message: toast('Advance Repayment failed') };
  }
}
