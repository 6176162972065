import moment from 'moment';

export const validateTimeFormat = (time: string, exitTime?: string) => {
  const timeSplit = time.split(':');
  if (!timeSplit[0]) return '';
  const hours = Number(timeSplit[0]);
  const minutes = Number(timeSplit[1]);
  // const seconds = Number(timeSplit[2])
  if (!hours) {
    if (hours !== 0) {
      return 'Time should be in HH:MM format';
    }
  }
  if (hours < 0 || hours > 23) return 'Hours could be from 0 to 23';
  if (!minutes) {
    if (minutes !== 0) {
      return 'Time should be in HH:MM format';
    }
  }
  if (minutes < 0 || minutes > 59) return 'Minutes could be from 0 to 59';
  // if(!seconds ) return 'Time should be in HH:MM:SS format'
  // if( seconds < 0 || seconds > 59) return 'seconds could be from 0 to 59'
  if (timeSplit[3]) return 'Time should be in HH:MM format';

  if (exitTime) {
    const start = moment(`${moment().format('YYYY-MM-DD')}T${exitTime}`);
    const end = moment(`${moment().format('YYYY-MM-DD')}T${time}`);
    const diff = end.diff(start, 'seconds');
    if (diff <= 0) return 'Selected time should be greater';
  }
  return '';
};

// GatePass time validate testing if tests covered can be used in other forms
export const validateGpTimeFormat = (time: string, exitTime?: string) => {
  const timeSplit = time.split(':');
  if (!timeSplit[0]) return '';
  const hours = Number(timeSplit[0]);
  const minutes = Number(timeSplit[1]);
  // const seconds = Number(timeSplit[2])
  if (!hours) {
    if (hours !== 0) {
      return 'Time should be in HH:MM format';
    }
  }
  if (hours < 0 || hours > 23) return 'Hours could be from 0 to 23';
  if (!minutes) {
    if (minutes !== 0) {
      return 'Time should be in HH:MM format';
    }
  }
  if (minutes < 0 || minutes > 59) return 'Minutes could be from 0 to 59';
  // if(!seconds ) return 'Time should be in HH:MM:SS format'
  // if( seconds < 0 || seconds > 59) return 'seconds could be from 0 to 59'
  if (timeSplit[2]) return 'Time should be in HH:MM format';

  if (exitTime) {
    const start = moment(`${moment().format('YYYY-MM-DD')}T${exitTime}`);
    const end = moment(`${moment().format('YYYY-MM-DD')}T${time}`);
    const diff = end.diff(start, 'seconds');
    if (diff <= 0) return 'Selected time should be greater';
  }
  return '';
};
