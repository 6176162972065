import {
  BomFlow,
  BomFlowFilter,
} from '@erp_core/erp-types/dist/modules/inventory';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpBomFlowRepo extends GenericHttpWithLogsRepo<
  BomFlow,
  BomFlowFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/work-order/bom-flow',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getBomCharts(gradeId: string): Promise<any[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/bom-charts/${gradeId}`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c);
    }

    return [];
  }

  async getBomList(gradeId: string): Promise<any[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/bom-list/${gradeId}`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c);
    }

    return [];
  }
}
