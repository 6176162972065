import { ConfiguratorType } from '@erp_core/erp-types/dist/modules/notification';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddNotifcnConfiguratorForm() {
  return function AddNotifcnConfiguratorForm({
    onSave,
    data,
  }: {
    onSave: (data: Partial<ConfiguratorType>) => Promise<void>;
    data?: ConfiguratorType;
  }): JSX.Element {
    const formProps: FormProps<Partial<ConfiguratorType>> = {
      fieldsData: [
        {
          property: 'resource',
          type: 'input',
          label: 'Resource',
          required: true,
        },
        {
          property: 'module',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'hrd', value: 'hrd' },
            { text: 'inventory', value: 'inventory' },
            { text: 'purchase', value: 'purchase' },
            { text: 'sales', value: 'sales' },
            { text: 'accounts', value: 'accounts' },
          ],
          label: 'Module',
          required: true,
        },
      ],
      initialFormState: {
        resource: data?.resource || '',
        module: data?.module || '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          resource: l.resource,
          module: l.module,
        } as Partial<ConfiguratorType>);
      },
      allowUnchanged: true,
    };

    const Form = renderFormV2<Partial<ConfiguratorType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ConfiguratorType>>
    ): Partial<ConfiguratorType> {
      return (b as unknown) as Partial<ConfiguratorType>;
    }
  };
}
