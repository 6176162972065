import { Check } from '@erp_core/erp-icons/icons/web/check';
import {
  Client,
  ClientFilterType,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  Filter,
  renderCardComponent,
  renderTableWithMapperComponent,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';
// import { renderAddLeadCustomerForm } from '../../lead-customer-list/form';
import { UseCombinedLeadCustomer } from '../../../../hooks/order/sales/lead-customer/use-lead-customer';
import { UserRendererInterface } from '../../../common/fragments/user';
import { renderAddClientForm } from './form';

export type RenderClientListProps = {
  useCombinedClient: UseCombinedClient;
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedUser: UseCombinedUser;
  userRendererService: UserRendererInterface;
};

export function renderClientList({
  useCombinedClient,
  useCombinedLeadCustomer,
  useCombinedUser,
  userRendererService,
}: RenderClientListProps): () => JSX.Element {
  return function ClientList(): JSX.Element {
    const Card = renderCardComponent();
    // const { data: clients, getAll: getClients } = useClients();
    const { syncSet: setLeadCustomer } = useCombinedLeadCustomer();
    const { syncSet: setClient } = useCombinedClient();
    // const { data: leadCustomers, getAll: getLeadCustomers } = useLeadCustomers();

    const cardHeader: CardHeader = {
      title: 'Client List',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add Client',
            behaviour: 'modal',
            modal: {
              title: 'Add Client',
              content: ({ onClose }) => {
                const AddClientForm = renderAddClientForm();

                const handleAddClient = async (formData: any) => {
                  try {
                    const clientRes = await setLeadCustomer({
                      name: formData.name,
                      details: {
                        createdAs: 'client',
                      },
                    } as LeadCustomerType);

                    if (clientRes) {
                      await setClient({
                        leadCustomerId: clientRes?.id,
                        description: formData?.description,
                        name: formData?.name,
                        details: {
                          createdAs: 'client',
                        },
                        createdAt: moment().utc().format(),
                      } as Client);
                      toast.success('Added Client');
                      await onClose();
                    }
                  } catch {
                    toast.error('Something went wrong');
                    await onClose();
                  }
                };

                return <AddClientForm onSave={handleAddClient} />;
              },
            },
          },
        },
      ],
    };

    // useEffect(() => {
    //   getClients();
    //   getLeadCustomers();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    const TableWithMapper = renderTableWithMapperComponent<
      Client,
      ClientFilterType
    >();
    // const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        {
          name: (
            <>
              <UserIcon className='w-5 h-5 inline mr-1' />
              Client Name
            </>
          ),
        },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Last Modified By
            </>
          ),
        },
      ],
    ];

    const bodyMapper = (r: Client) => {
      const cells: Array<TableCell> = [
        {
          value: (
            <>
              {r.name}
              {r.status === 'approved' ? (
                <Check className='border rounded-full w-3 ml-1 inline bg-green-500 text-white' />
              ) : null}
            </>
          ),
          link: `/sales/masters/clients/${r.id}/profile`,
        },
        {
          value: (
            <userRendererService.userCard
              size='small'
              link={true}
              id={r.lastModifiedBy?.id}
              name={r.lastModifiedBy?.name}
            />
          ),
        },
      ];
      return {
        cells,
      };
    };

    // const salesCustomers = _.concat(clients, leadCustomers);

    // const tableBody: TableBody = salesCustomers ?
    //   salesCustomers.map((c) => ({
    //     cells: [
    //       { value: c?.name || '', link: `/sales/clients/${c?.id}/profile` },
    //       { value: <User id={c?.createdBy?.id || ''} name={c?.createdBy?.name || ''} /> },
    //       {
    //         value: (
    //           <User id={c?.lastModifiedBy?.id || ''} name={c?.lastModifiedBy?.name || ''} />
    //         ),
    //       },
    //     ]
    //   }))
    // : [];

    const filter: Filter<ClientFilterType> = {
      version: 'v2',
      sortFields: [
        {
          key: 'ClientName',
          value: 'name',
          defaultOrder: 'asc',
        },
        {
          key: 'CreatedAt',
          value: 'createdAt',
          defaultOrder: 'asc',
        },
        {
          key: 'LastModifiedAt',
          value: 'lastModifiedAt',
          defaultOrder: 'asc',
        },
      ],
      filterFields: [
        {
          key: 'search',
          value: 'all',
          type: 'text',
        },
        {
          key: 'status',
          value: 'drop-down',
          type: 'drop-down',
          options: [
            {
              text: 'all',
              value: 'all',
            },
            {
              text: 'approved',
              value: 'approved',
            },
            {
              text: 'pending',
              value: 'pending',
            },
            {
              text: 'rejected',
              value: 'rejected',
            },
          ],
        },
        {
          key: 'createdBy',
          type: 'search-select',
          value: '',
        },
        {
          key: 'lastModifiedBy',
          type: 'search-select',
          value: '',
        },
      ],
      filterMapper: (filterSelection: ClientFilterType) => {
        const filterData: ClientFilterType = {};

        if (filterSelection.search !== 'all' && filterSelection.search !== '') {
          filterData.search = filterSelection.search;
        }
        if (
          (filterSelection as any).status !== 'all' &&
          (filterSelection as any).status !== ''
        ) {
          (filterData as any).status = (filterSelection as any).status;
        }
        if (filterSelection.createdBy) {
          filterData.createdBy = filterSelection.createdBy;
        }
        if (filterSelection.lastModifiedBy) {
          filterData.lastModifiedBy = filterSelection.lastModifiedBy;
        }

        return filterData as ClientFilterType;
      },
      filterResources: {
        createdBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
        lastModifiedBy: {
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect: (u) => {},
          },
        },
      },
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <TableWithMapper
              header={tableHeader}
              bodyMapper={bodyMapper}
              filter={filter}
              type={{
                type: 'paginated',
                usePaginatedResources: useCombinedClient,
              }}
            />
            {/* <Table
              header={tableHeader}
              body={tableBody}
            /> */}
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
