import { BankDetailsType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type EditBankFormProps = {
  data: BankDetailsType;
  onSave: (data: Partial<BankDetailsType>) => Promise<void>;
  onClose: () => void;
};

export function renderEditBankForm(): (data: EditBankFormProps) => JSX.Element {
  return function EditBankForm({
    data,
    onSave,
    onClose,
  }: EditBankFormProps): JSX.Element {
    const formProps: FormProps<Partial<BankDetailsType>> = {
      fieldsData: [
        {
          property: 'bank',
          type: 'input',
          required: true,
          label: 'Bank Name',
        },
        {
          property: 'branch',
          type: 'input',
          required: true,
          label: 'Bank Branch',
        },
        {
          property: 'branchCode',
          type: 'input',
          required: true,
          label: 'Branch Code',
        },
        {
          property: 'accountNo',
          type: 'input',
          required: true,
          label: 'Account Number',
        },
        {
          property: 'ifsc',
          type: 'input',
          required: true,
          label: 'IFSC Code',
        },
        {
          property: 'micrCode',
          type: 'input',
          required: true,
          label: 'Micr Code',
        },
        {
          property: 'swiftCode',
          type: 'input',
          label: 'Swift Code',
        },
        {
          property: 'accountStatus',
          type: 'select',

          options: [
            { text: 'Select', value: '' },
            { text: 'Pending', value: 'pending' },
            { text: 'Accepted', value: 'accepted' },
            { text: 'Rejected', value: 'rejected' },
          ],
        },
        {
          property: 'active',
          type: 'select',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
        // {
        //   property: 'uploadDocument',
        //   type: 'file',
        //   accept: 'pdf',
        //   label: 'Documents',

        // }
      ],
      initialFormState: {
        bank: data?.bank,
        branch: data?.branch,
        accountNo: data?.accountNo,
        ifsc: data?.ifsc,
        branchCode: data?.branchCode,
        // uploadDocument: b.uploadDocument as string,
        micrCode: data?.micrCode,
        swiftCode: data?.swiftCode,
        accountStatus: data?.accountStatus,
        active: data?.active,
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      // upload: {
      //   useFileTransfer: useFileTransfer,
      //   config: [
      //     {
      //       name: 'uploadDocument',
      //       path: `${companyGroup.id}/${company.id}/employees/bank-details/${employeeId}/${data.bank}-${data.accountNo}`,
      //       type: 'private'
      //     }
      //   ]
      // },
      onSubmit: async (f) => {
        await onSave({
          bank: f.bank || data?.bank,
          branch: f.branch || data?.branch,
          branchCode: f.branchCode || data?.branchCode,
          accountNo: f.accountNo || data?.accountNo,
          ifsc: f.ifsc || data?.ifsc,
          // uploadDocument: b.uploadDocument as string,
          micrCode: f.micrCode || data?.micrCode,
          swiftCode: f.swiftCode || data?.swiftCode,
          accountStatus: f.accountStatus || data?.accountStatus,
          active: f.active || data?.active,
        } as Partial<BankDetailsType>);
        onClose();
      },
      // allowUnchanged: true,
    };

    const Form = renderFormV2<Partial<BankDetailsType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<BankDetailsType>>
    ): BankDetailsType {
      return (b as unknown) as BankDetailsType;
    }

    // function mapPFDToP(
    //   b: FormDataType<Partial<BankDetailsType>>
    // ): BankDetailsType {
    //   return {
    //     bank: b.bank as string,
    //     branch: b.branch as string,
    //     branchCode: b.branchCode as string,
    //     accountNo: b.accountNo as string,
    //     ifsc: b.ifsc as string,
    //     // uploadDocument: b.uploadDocument as string,
    //     micrCode: b.micrCode as string,
    //     swiftCode: b.swiftCode as string,
    //     accountStatus: b.accountStatus as any,
    //     active: b.active as any,
    //   } as any;
    // }
  };
}
