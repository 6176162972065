import { PurchaseEnquiry } from '@erp_core/erp-types/dist/modules/order';
import {
  renderCardComponent,
  renderPureHistory,
} from '@erp_core/erp-ui-components';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { OverviewFlow } from '../../../../../../components/flow-chart';
import { renderVendor } from '../../../../../../modules/common/fragments/vendor';
import { getEnquiryFlowGraph } from './utils/get-enquiry-flow-graph';
// import { getPrFlowGraph } from './utils/get-pr-flow-graph';

export function renderEnquiryProfile({
  enquiry,
}: {
  enquiry: PurchaseEnquiry;
}): () => JSX.Element {
  return function PRProfile() {
    const Vendor = renderVendor();
    const History = renderPureHistory();
    const Card = renderCardComponent();
    return (
      <div>
        <div className='w-full h-48 border border-gray-200 mx-auto'>
          <OverviewFlow data={enquiry} getFlowGraph={getEnquiryFlowGraph} />
        </div>
        <div>
          <Card
            header={{ title: enquiry.name }}
            body={{
              type: 'jsx-component',
              body: (
                <>
                  <div className='border border-gray-200 p-2 m-1'>
                    <div className='text-center text-lg'>
                      Purchase Requisitions
                    </div>
                  </div>
                  <div className='border border-gray-200 p-2 m-1'>
                    <div className='text-center text-lg'>
                      Vendors Shortlisted
                    </div>
                    {enquiry?.details?.vendors?.map((q) => {
                      return (
                        <span
                          className='inline text-purple-600 bg-purple-100 border p-0.5 m-0.5 rounded-md'
                          key={q.id}
                        >
                          <Vendor key={q.id} id={q.id} name={q.name} />
                          <span className='mx-1 text-blue-500 border rounded-full'>
                            {enquiry?.enquiryQuotes?.filter(
                              (qu) => qu.vendor.id === q.id
                            ).length || 0}
                            <EnvelopeIcon className='h-4 w-4 inline text-blue-500' />
                          </span>
                        </span>
                      );
                    })}
                  </div>
                  <div className='border border-gray-200 p-2 m-1'>
                    <div className='text-center text-lg'>Delivery Schedule</div>
                    <div className='flex flex-row'>
                      {enquiry?.details?.schedule?.delivery?.map((c, idx) => (
                        <div className='border border-gray-200 p-1' key={idx}>
                          <div>
                            <span className='font-semibold mx-1'>
                              Batch {idx + 1}
                            </span>
                          </div>
                          <div>
                            <span className='text-gray-600'>
                              Quantity: {c.quantity}
                            </span>
                          </div>
                          <div>
                            <span className='italic'>
                              Days: {c.daysAfterOrder || '-'}
                            </span>
                          </div>
                          <div>
                            BOMs:{' '}
                            {c.boms?.map((b) => (
                              <span key={b.id}>
                                {b.name}-{b.quantity}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ),
            }}
          />
        </div>
        <div className='w-full p-4'>
          {enquiry.details?.activity ? (
            <History logs={enquiry.details.activity} />
          ) : null}
        </div>
      </div>
    );
  };
}
