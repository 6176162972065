import {
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useState } from 'react';

export function renderProfitNLoss() {
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  return function ProfitNLoss() {
    const [range, setRange] = useState<{
      start: string;
      end: string;
    }>({
      start: moment().subtract(3, 'M').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    });
    return (
      <div>
        <Card
          header={{
            title: 'Profit N Loss',
            actions: [
              {
                type: 'button',
                button: {
                  name: `${range.start} to ${range.end}`,
                  behaviour: 'modal',
                  modal: {
                    title: 'Change range',
                    content: ({ onClose }) => {
                      const Form = renderFormV2({
                        initialFormState: {
                          start: range.start,
                          end: range.end,
                        },
                        style: 'w-1/2',
                        fieldsData: [
                          { type: 'date', property: 'start', required: true },
                          { type: 'date', property: 'end', required: true },
                        ],
                        mapTToU: (t) => t,
                        onSubmit: async (form) => {
                          setRange(
                            (r) =>
                              ({
                                start: form.start || r.start || '',
                                end: form.end || r.end || '',
                              } as any)
                          );
                          onClose();
                        },
                      });
                      return <Form />;
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: (
              <div>
                <Table
                  header={[
                    [
                      { name: 'Particulars', style: 'w-1/3' },
                      { name: `${range.start} to ${range.end}`, style: '1/6' },
                      { name: 'Particulars', style: 'w-1/3' },
                      { name: `${range.start} to ${range.end}`, style: '1/6' },
                    ],
                  ]}
                  body={[]}
                />
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
