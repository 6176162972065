import { BOMRes } from '@erp_core/erp-types/dist/modules/order';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseCombinedGodown } from '../../../../../../hooks/inventory/godown-inventory/use-godown';

type EditBOMFormProps = {
  defaults: BOMRes | undefined;
  onSubmit: (form: any) => Promise<void>;
  useCombinedGodown: UseCombinedGodown;
};

export function EditForm({
  defaults,
  onSubmit,
  useCombinedGodown,
}: EditBOMFormProps) {
  const submit = async (form) => {
    if (form.quantity)
      form['details'] = {
        rawMaterial: {
          ...defaults?.details?.rawMaterial,
          quantity: form.quantity,
        },
      };
    if (defaults) {
      await onSubmit({ id: defaults.id, ...form });
    }
  };

  const formProps: FormProps<Partial<any> & { quantity: string }> = {
    fieldsData: [
      // {
      //   property: 'priority',
      //   label: 'Priority',
      //   type: 'select',
      //   options: [
      //     { text: 'low', value: 'low' },
      //     { text: 'medium', value: 'medium' },
      //     { text: 'high', value: 'high' },
      //   ],
      // },
      {
        type: 'searchable-select',
        property: 'dispatchTo',
        searchOptions: {
          useSearch: useCombinedGodown,
          onSearchValueSelect(u) {},
        },
        label: 'Dispatch to',
        style: '',
        required: true,
      },
      { property: 'bomType', label: 'Bom type', type: 'input', readonly: true },
      { property: 'targetDate', label: 'Target date', type: 'date' },
      { property: 'quantity', label: 'Quantity', type: 'number' },
    ],
    initialFormState: {
      // priority: defaults?.priority || '',
      dispatchTo: defaults?.details?.dispatchTo || { id: '', name: '' },
      bomType: defaults?.bomType || '',
      targetDate: moment(defaults?.targetDate).format('YYYY-MM-DD') || '',
      quantity: defaults?.details?.rawMaterial.quantity || '',
    },
    mapTToU: mapPFDToP,
    onSubmit: async (f) => {
      const finalForm = { ...f };
      if (f.dispatchTo) {
        finalForm.details = {
          dispatchTo: f.dispatchTo,
        };
        delete finalForm.dispatchTo;
      }
      await submit(finalForm);
    },
    button: { style: 'w-fit' },
    style: 'md:w-1/2',
  };

  const Form = renderFormV2<Partial<BOMRes> & { quantity: string }>(formProps);

  return <Form />;

  function mapPFDToP(
    b: FormDataType<Partial<BOMRes>> & { [k: string]: any }
  ): Partial<BOMRes> & { quantity: string } {
    return (b as unknown) as Partial<BOMRes> & { quantity: string };
  }
}
