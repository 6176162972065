import { ItemCategoryRes } from '@erp_core/erp-types/dist/modules/inventory';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export function renderAddItemCategoryForm() {
  return function AddItemCategoryForm({
    onSave,
  }: {
    onSave: (data: ItemCategoryRes) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<ItemCategoryRes>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Item Category Name',
          required: true,
        },
        {
          property: 'id',
          type: 'input',
          label: 'Short-Form',
          required: true,
        },
        {
          property: 'description',
          type: 'input',
          label: 'Description',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        id: '',
        description: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          id: l.id,
          description: l.description,
        } as ItemCategoryRes);
      },
    };

    const Form = renderFormV2<Partial<ItemCategoryRes>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<ItemCategoryRes>>
    ): ItemCategoryRes {
      return (b as unknown) as ItemCategoryRes;
    }
  };
}
