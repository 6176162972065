import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { downloadFile, downloadPdfFile } from '@erp_core/erp-ui-components';
import axios from 'axios';
import toast from 'react-hot-toast';
import { FILE_MANAGER_SERVER_URI } from '../../../../config/server-uri';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';

export const downloadItemSpec = async ({
  companyGroupId,
  stockGroupId,
  item,
  getSpecTemplate,
  itemService,
}: {
  companyGroupId: string;
  stockGroupId: string;
  item: ItemRes;
  getSpecTemplate: (path: string, returnBase64: boolean) => Promise<string>;
  itemService?: ItemInterface;
}) => {
  // const html = await getSpecTemplate(
  //   `${companyGroupId}/sg-tmce-template/${stockGroupId.toLowerCase()}.html`
  // );

  // let htmlPath: string = '';
  // if (itemService) {
  //   htmlPath = (await itemService.getSpecificationsTemplate(item.id)).template;
  // }
  // const html = htmlPath ?  await getSpecTemplate(
  //   // `${companyGroupId}/sg-tmce-template/${stockGroupId.toLowerCase()}.html`
  //   htmlPath
  // ) : '<div>Unavailable Template</div>';

  const html = itemService
    ? await getSpecsPath({
        getSpecificationsTemplate: (id: string) => {
          return itemService.getSpecificationsTemplate(id);
        },
        itemId: item.id,
        getTemplate: getSpecTemplate,
      })
    : '<div>Unavailable Item Id</div>';

  // const html = convertToHTML(JSON.parse(data));

  const axiosInstance = axios.create({
    baseURL: FILE_MANAGER_SERVER_URI,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const properties = await itemService?.getProperties(item.id);
  const newSpecification: any = {};
  if (properties) {
    properties.forEach((p) => {
      newSpecification[p.name] = (p as any)?.value?.data;
    });
  }

  const response = await axiosInstance.post('/pdf', {
    template: html,
    data: {
      name: item.name,
      description: item.description,
      status: item.status,
      category: item.category,
      ...item.details,
      ...newSpecification,
    },
  });

  const fType = 'application/pdf';
  const fName = `${item.name}.pdf`;
  const res = response.data;

  downloadFile({ fileType: fType, fileName: fName, result: res });
};

export async function getSpecsPath({
  getSpecificationsTemplate,
  itemId,
  getTemplate,
}: {
  itemId: string;
  getSpecificationsTemplate(
    id: string
  ): Promise<{
    template: string;
  }>;
  getTemplate: (path: string, returnBase64: boolean) => Promise<string>;
}): Promise<string> {
  let htmlPath: string = '';
  htmlPath = (await getSpecificationsTemplate(itemId)).template;

  const html = htmlPath
    ? await getTemplate(
        // `${companyGroupId}/sg-tmce-template/${stockGroupId.toLowerCase()}.html`
        htmlPath,
        false
      )
    : '<div>Unavailable Template</div>';

  return html;
}

export async function getItemSpecList({
  companyGroupId,
  stockGroupId,
  itemId,
  list,
}: {
  companyGroupId: string;
  stockGroupId: string;
  itemId: string;
  list: (path: string) => Promise<{ type: string; name: string }[]>;
}): Promise<
  {
    name: string;
    url: string;
  }[]
> {
  const url = `${companyGroupId}/specification-sheets/${stockGroupId}/items/${itemId}`;

  const sp: Array<{ name: string; url: string }> = [];
  await list(url).then((res) => {
    if (Array.isArray(res)) {
      res.forEach((x) => {
        if (x.type === 'file') {
          const name = x.name.split('/').pop()?.replace('.pdf', '');
          sp.push({
            name: `version ${name}`,
            url: x.name,
          });
        }
      });
    }
  });

  return sp;
}

export async function downloadLatestSpec({
  companyGroupId,
  stockGroupId,
  itemId,
  itemName,
  list,
  getFile,
}: {
  companyGroupId: string;
  stockGroupId: string;
  itemId: string;
  itemName: string;
  list: (path: string) => Promise<{ type: string; name: string }[]>;
  getFile: (path: string, returnBase64?: boolean) => Promise<string>;
}) {
  const speclist = await getItemSpecList({
    companyGroupId,
    stockGroupId,
    itemId,
    list,
  });

  if (speclist.length) {
    const latest = speclist.sort((a, b) => a.name.localeCompare(b.name)).pop();
    if (latest) {
      const res = await getFile(latest.url, true);
      const fName = `${itemName}-${latest.name}.pdf`;
      downloadPdfFile({ result: res, fileName: fName });
      return;
    }
  }

  toast.error('Could not find Specification Sheet');
}
