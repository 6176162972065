import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type HolidayFormType = {
  name?: string;
  sisterLocation: string;
  date?: string;
  description?: string;
  locations: { text: string; value: string }[];
};

type RenderHolidayForm = {};

export const renderHolidayForm = (e: RenderHolidayForm) => {
  return function HolidayForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: HolidayFormType;
    isEdit?: boolean;
  }) {
    const formProps: FormProps<Partial<HolidayFormType>> = {
      fieldsData: [
        {
          property: 'sisterLocation',
          type: 'select',
          required: true,
          label: 'Location',
          options: [{ text: 'select', value: '' }, ...(data?.locations || [])],
          readonly: isEdit,
        },
        { property: 'name', type: 'input', required: true, label: 'name' },
        { property: 'date', type: 'date', required: true, label: 'Date' },
        {
          property: 'description',
          type: 'input',
          required: true,
          label: 'description',
        },
      ],
      initialFormState: {
        name: data?.name || '',
        description: data?.description || '',
        date: data?.date || '',
        sisterLocation: data?.sisterLocation || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<HolidayFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<HolidayFormType>>
    ): HolidayFormType {
      return {
        name: b.name || data?.name,
        description: b.description || data?.description,
        date: b.date || data?.date,
        sisterLocation: b.sisterLocation || data?.sisterLocation,
      } as HolidayFormType;
    }
  };
};
