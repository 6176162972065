/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { Hourglass } from '@erp_core/erp-icons/icons/web/hourglass';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderHistory,
  renderInlineInput,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CloudIcon, CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedDeliveryTerm } from '../../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedBatchItemGodown } from '../../../../hooks/inventory/item/batch/use-batch-item-godown';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from '../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendorItem } from '../../../../hooks/inventory/purchase/vendors/use-vendor-item';
import { UseCombinedStockGroup } from '../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UseCombinedLicenseContract } from '../../../../hooks/order/work-order/license-contract/use-license-contract';
import { ItemInterface } from '../../../../models/interfaces/inventory/item';
import { renderVendorContracts } from './contract';
import { renderVendorPurchaseEnquiryList } from './purchase-enquiries';
import { renderVendorPurchaseOrderList } from './purchase-orders';
import { renderVendorGodown } from './vendor-godown';
import { renderVendorProfileTabs } from './vendor-profile-tab';

type RenderVendoeV2Profile = {
  useCombinedVendor: UseCombinedVendor;
  useCombinedGodown: UseCombinedGodown;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCombinedItem: UseCombinedItem;
  useVendorItem: UseVendorItem;
  useCombinedUser: UseCombinedUser;
  useUserAuthorization: UseUserAuthorization;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedStockGroup: UseCombinedStockGroup;
  itemService: ItemInterface;
  useCombinedBatchItemGodown: UseCombinedBatchItemGodown;
  useCombinedLicenseContract: UseCombinedLicenseContract;
};

export function renderVendorV2Profile({
  useCombinedVendor,
  useFileTransfer,
  usePublicTransfer,
  useVendorItem,
  useCombinedDeliveryTerm,
  useCombinedPaymentTerm,
  useCombinedUser,
  useUserAuthorization,
  useCombinedItem,
  useCombinedStockGroup,
  itemService,
  useCombinedGodown,
  useCombinedBatchItemGodown,
  useCombinedLicenseContract,
}: RenderVendoeV2Profile): () => JSX.Element {
  const Card = renderCardComponent();
  const History = renderHistory();

  return function VendorV2Profile(): JSX.Element {
    const { id } = useParams();
    const NewTabs = renderTabsV2Component();
    const { getAll: getGodowns, list: godowns } = useCombinedGodown();

    const {
      resource: vendor,
      get: getVendor,
      loading,
      syncSet: setVendor,
    } = useCombinedVendor();

    useEffect(() => {
      if (id) {
        getVendor(id);
      }
    }, [id]);

    useEffect(() => {
      if (vendor?.id && vendor.company?.id) {
        getGodowns({
          type: 'virtual',
          companyId: vendor.company?.id,
          search: `Vendor Godown of ${vendor.name}`,
        });
      }
    }, [vendor?.id]);

    // useEffect(() => {
    //   console.log(godowns);
    //   if (godowns?.length) {
    //   }
    // }, [godowns])

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useCombinedVendor,
      id,
    });

    const header: CardHeader = {
      menu: {
        actions: [
          {
            name: 'Approve',
            behaviour: 'confirm',
            show: () => {
              return vendor?.status !== 'approved';
            },
            onConfirm: () => ({
              title: 'Are you sure you want to approve this vendor?',
              type: 'warning',
              onConfirm: async () => {
                setVendor({
                  id: vendor?.id,
                  status: 'approved',
                } as any);
              },
            }),
          },
        ],
      },
      title: (
        <div className='flex'>
          <InlineInput name={'name'} value={vendor?.name || id} />
          {godowns?.length ? (
            <CloudIcon
              title='Virtual Godown Exists'
              className='w-5 h-5 inline text-indigo-900 mx-1'
            />
          ) : null}
        </div>
      ) as any,
      icon: vendor?.details?.partyProfile?.logo ? (
        <img
          alt={vendor?.name}
          src={vendor.details?.partyProfile?.logo}
          className='w-24 h-24 self-center'
        />
      ) : (
        <CubeIcon className='text-gray-800 w-24 h-full' />
      ),
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            {vendor?.description || 'Add a description for the item...'}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${vendor?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {vendor?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${vendor?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {vendor?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(vendor?.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {vendor?.status === 'approved' ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : vendor?.status === 'pending' ? (
              <span className='border border-orange-600 rounded-md px-2 py-1 text-sm text-orange-600'>
                <Hourglass className='inline w-5 text-red-600' />
                <span className='capitalize'>Pending</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
            {vendor?.details?.partyProfile?.vendorType === 'trader' ? (
              <span className='border border-blue-600 rounded-md px-2 py-1 text-sm text-blue-600'>
                <span className='inline w-5 text-blue-600' />
                <span className='capitalize'>Trader</span>
              </span>
            ) : (
              <span className='border border-yellow-600 rounded-md px-2 py-1 text-sm text-yellow-600'>
                <span className='inline w-5 text-yellow-600' />
                <span className='capitalize'>Manufacturer</span>
              </span>
            )}
          </div>
        </>
      ),
    };

    const Profile = renderVendorProfileTabs();

    const PurchaseOrders = renderVendorPurchaseOrderList({
      useCombinedVendor,
      id,
    });

    const VendorGodown = renderVendorGodown({ useCombinedBatchItemGodown, id });

    const PurchaseEnquiries = renderVendorPurchaseEnquiryList({
      useCombinedVendor,
      id,
    });

    const VendorContracts = renderVendorContracts({
      useCombinedVendor,
      useCombinedDeliveryTerm,
      useCombinedPaymentTerm,
      useUserAuthorization,
      id,
      useCombinedLicenseContract,
    });

    const newCategories: DataArray = [
      {
        name: 'profile',
        Fn: () =>
          vendor ? (
            <Profile
              setVendor={setVendor}
              vendor={vendor}
              loading={loading}
              useCombinedVendor={useCombinedVendor}
              id={id}
              useFileTransfer={useFileTransfer}
              usePublicTransfer={usePublicTransfer}
              useVendorItem={useVendorItem}
              useUserAuthorization={useUserAuthorization}
              useCombinedPaymentTerm={useCombinedPaymentTerm}
              useCombinedItem={useCombinedItem}
              useCombinedStockGroup={useCombinedStockGroup}
              useCombinedDeliveryTerm={useCombinedDeliveryTerm}
              itemService={itemService}
            />
          ) : (
            <></>
          ),
      },
      { name: 'overview', Fn: PurchaseOrders },
      { name: 'purchase-orders', Fn: PurchaseOrders },
      { name: 'godown', Fn: VendorGodown },
      { name: 'contracts', Fn: VendorContracts },
      { name: 'enquiry-and-offers', Fn: PurchaseEnquiries },
      {
        name: 'history',
        Fn: () => <History useResource={useCombinedVendor} id={id} />,
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
