/* eslint-disable class-methods-use-this */
import {
  LocationFilterType,
  LocationType,
} from '@erp_core/erp-types/dist/modules/admin';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithCurrentRepo } from '../generic-resource-with-current';

export class HttpLocationsRepo extends GenericHttpWithCurrentRepo<
  LocationType,
  LocationFilterType,
  LocationType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/locations',
      itemName: 'currentLocation',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getCurrent(): Promise<LocationType> {
    const currentLocation = localStorage.getItem(this.itemName);
    if (currentLocation) {
      return JSON.parse(currentLocation) as LocationType;
    }
    return {} as LocationType;
  }
}
