/* eslint-disable no-unused-vars */
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderEditBomNamePatternFormProps = {
  closeModal: () => void;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

type Props = {
  // bomNamePattern: string
  data: CompanyGroupSetting;
};
export function renderEditBomNamePatternForm({
  closeModal,
  useCombinedCompanyGroupSetting,
}: RenderEditBomNamePatternFormProps): (data: Props) => JSX.Element {
  return function EditBomNamePatternForm({ data }: Props): JSX.Element {
    const {
      syncSet: setCompanyGroupSetting,
    } = useCombinedCompanyGroupSetting();

    const bomNamePattern = data?.details?.workOrder?.bom?.bomNamePattern || '';

    const saveBomNamePattern = async (form) => {
      const finalData = {
        id: data.id,
        details: {
          ...data.details,
          workOrder: {
            ...data.details.workOrder,
            bom: {
              ...data.details.workOrder?.bom,
              bomNamePattern: form.pattern,
            },
          },
        },
      };
      await setCompanyGroupSetting(finalData as any);
      closeModal();
    };

    const guidelines = [
      {
        title: 'YYYY',
        description: 'full year like 2022',
      },
      {
        title: 'YY',
        description: 'year like 22',
      },
      {
        title: 'FYYYY',
        description: 'full financial year like 2022-2023',
      },
      {
        title: 'FYY',
        description: 'financial year like 22-23',
      },
      {
        title: 'MM',
        description: 'Month like 12, for this YYYY or YY is required',
      },
      {
        title: 'DD',
        description: 'Day like 02, for this (YYYY or YY) and MM is required',
      },
      {
        title: 'INC',
        description: 'will add +1 to last added name',
      },
    ];

    const formProps: FormProps<any> = {
      fieldsData: [
        {
          property: 'pattern',
          label: 'pattern',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        pattern: bomNamePattern || '',
      },
      mapTToU: mapPFDToP,
      style: '',
      onSubmit: saveBomNamePattern,
    };

    const Form = renderFormV2<any>(formProps);

    return (
      <div>
        <Form />
        <div className='italic'>Example Patterns: {'BOM-%{YYYY}-%{INC}'}</div>
        <div className='font-bold text-md mt-1'> Guidelines</div>
        {guidelines.map((g, index) => {
          return (
            <div key={index} className='flex'>
              <div className='mr-4 font-bold'> {g.title}</div>
              {g.description}
            </div>
          );
        })}
      </div>
    );

    function mapPFDToP(b: FormDataType<any>): any {
      return {
        pattern: b.pattern || '',
      };
    }
  };
}
