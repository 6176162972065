import {
  GetAdvPaymentReq,
  PostActionRepaymentResp,
  RepaymentScheduleInterface,
} from 'models/interfaces/loans/repayment-schedule';
import { useState } from 'react';

export type UseAdvPaymentData = () => {
  setAdvPaymentData: (p: GetAdvPaymentReq) => void;
  res: PostActionRepaymentResp;
};

export interface UseAdvPaymentOptions {
  useAdvPaymentService: () => RepaymentScheduleInterface;
}

export function createUseAdvPaymentValue({
  useAdvPaymentService,
}: UseAdvPaymentOptions): UseAdvPaymentData {
  return () => {
    const repaymentScheduleService = useAdvPaymentService();
    const [res, setData] = useState<any>();

    const advPaymentApiCall = async (p: GetAdvPaymentReq) => {
      try {
        const resp = await repaymentScheduleService.advancePayment(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      setAdvPaymentData: (p: GetAdvPaymentReq) => {
        advPaymentApiCall(p);
      },
      res,
    };
  };
}
