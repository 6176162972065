import { LoadingButton } from '@erp_core/erp-ui-components';
import { ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';

export type DragItem = {
  id: string;
  name: string;
};

export type ClassifierDragItem = {
  name: string;
  items: Array<DragItem>;
};

export function PropertiesSorter({
  classifiers,
  onSave,
}: {
  onSave: (res: Array<ClassifierDragItem>) => Promise<void>;
  classifiers: Array<ClassifierDragItem>;
}) {
  const [draggingItem, setDraggingItem] = useState<DragItem | null>(null);
  const [items, setItems] = useState<Array<ClassifierDragItem>>(classifiers);

  function handleDragStart(e, item) {
    setDraggingItem(item);
    e.dataTransfer.setData('text/plain', '');
  }

  function handleDragEnd() {
    setDraggingItem(null);
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDrop(e, targetItem, cIndex) {
    if (!draggingItem) {
      return;
    }

    const classifier = items[cIndex];
    const currentIndex = classifier.items.indexOf(draggingItem);
    const targetIndex = classifier.items.indexOf(targetItem);

    if (currentIndex !== -1 && targetIndex !== -1) {
      classifier.items.splice(currentIndex, 1);
      classifier.items.splice(targetIndex, 0, draggingItem);
      const newItems = [...items];
      setItems(newItems);
    }
  }

  return (
    <div>
      <div className='flex flex-row'>
        {items.map((classifier, cIndex) => (
          <div
            className='w-auto mx-2 border border-gray-100'
            key={classifier.name}
          >
            <div className='text-center font-semibold'>{classifier.name}</div>
            {classifier.items.map((item, index) => (
              <div
                key={item.id}
                className={`hover:bg-gray-50 flex cursor-grab my-1 mx-2 p-1 border border-gray-300 ${
                  item === draggingItem ? 'opacity-60' : ''
                }`}
                draggable='true'
                onDragStart={(e) => handleDragStart(e, item)}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, item, cIndex)}
              >
                <div className='flex my-auto'>
                  <span className='bg-gray-50 my-auto text-center'>
                    {index + 1}.
                  </span>
                  <ArrowsUpDownIcon className='w-5 mx-2 inline' />
                  <span className='ml-2 my-auto'>{item.name}</span>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <LoadingButton
        text='Save'
        behaviourParams={{}}
        behaviorFn={() => onSave(classifiers)}
      />
    </div>
  );
}
