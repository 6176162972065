import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../contexts/current';
import { UserContext } from '../../../../../contexts/user';
import { UseCombinedLicenseContractDocType } from '../../../../../hooks/order/work-order/license-contract-doc-type/use-license-contract-doc-type';
import { renderLicenseContractDocForm } from './form/license-contract-doc-type';

export type RenderLicenseContractDocTypeProps = {
  useCombinedLicenseContractDocType: UseCombinedLicenseContractDocType;
};

export function renderLicenseContractDocType({
  useCombinedLicenseContractDocType,
}: RenderLicenseContractDocTypeProps): () => JSX.Element {
  return function LicenseContractDocTypeList(): JSX.Element {
    const { companyGroup, company } = useContext(CurrentContext);
    const { user: currentUser } = useContext(UserContext);

    const {
      list: licensceContractDocs,
      getAll: getLicenseContractDocuments,
    } = useCombinedLicenseContractDocType();

    const {
      set: setLicenseContractDocument,
    } = useCombinedLicenseContractDocType();

    useEffect(() => {
      // TODO: Add current location
      getLicenseContractDocuments();
      // eslint-disable-next-line
    }, []);

    console.log('licensceContractDocs', licensceContractDocs);

    const onSaveLicenseContract = async (form: any) => {
      const finalData = {
        ...form,
        companyGroup: {
          id: companyGroup.id,
          name: companyGroup.name,
        },
        company: {
          id: company.id,
          name: company.name,
        },
        createdBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
        lastModifiedBy: {
          id: currentUser.id,
          name: currentUser.name,
        },
      };

      await setLicenseContractDocument(finalData);
      toast('Data added sucessfully');
      getLicenseContractDocuments();
    };

    const Table = renderTableComponent();

    const Card = renderCardComponent();

    const onEditLicenseContractDocument = async (form: any, id: string) => {
      console.log('id', id);
      const finalData = {
        id,
        ...form,
      };
      await setLicenseContractDocument(finalData);
      toast('Schedule edited sucessfully');
      getLicenseContractDocuments();
    };

    const cardHeader: CardHeader = {
      title: 'License Contract Document Type',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add License Contract Document Type',
              content: ({ onClose }) => {
                const Form = renderLicenseContractDocForm();
                const formData = {};
                return (
                  <Form
                    onSave={async (e) => {
                      onSaveLicenseContract(e);
                      onClose();
                    }}
                    data={formData as any}
                    isEdit={false}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        { name: 'Affect Upon' },
        { name: 'Depends Upon' },
      ],
    ];
    const tableBody: TableBodyRow[] =
      licensceContractDocs?.map((e) => {
        const cells: Array<TableCell> = [
          { value: e?.name || '' },
          { value: e?.description },
          { value: e?.affectUpon.toString() },
          { value: e?.dependsUpon.toString() },
        ];

        return {
          rowData: {
            licenseContract: e,
          },
          cells,
        };
      }) || [];
    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Edit',
              behaviour: 'modal',
              show: () => true,
              modal: {
                title: 'Edit Schedule',
                content: ({ data: { licenseContract }, onClose }) => {
                  console.log('data', licenseContract);
                  const Form = renderLicenseContractDocForm();
                  const formData = {
                    ...licenseContract,
                  };
                  return (
                    <Form
                      onSave={async (e) => {
                        console.log(e, licenseContract.id);
                        await onEditLicenseContractDocument(
                          e,
                          licenseContract.id
                        );
                        onClose();
                      }}
                      data={formData as any}
                      isEdit={true}
                    />
                  );
                },
              },
            },
          ]}
        />
      ),
    };

    return (
      <div className='m-3'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
