import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  CardBody,
  CardHeader,
  ColumnizedContent,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import toast from 'react-hot-toast';
import { renderEditPersonalDetailsForm } from '../../profile-form/personal-details';

type PersonalDetails = {
  prefix: 'Miss' | 'Mrs' | 'Mr' | 'Dr';
  firstName: string;
  middleName: string;
  lastName: string;
  gender: 'Other' | 'Female' | 'Male';
  birthDate: string;
  maritalStatus: 'Married' | 'Unmarried';
  // photo: string;
  identificationMarks: string;
  bloodGroup: 'A+' | 'O+' | 'B+' | 'AB+' | 'A-' | 'O-' | 'B-' | 'AB-';
  height: number;
  weight: number;
};

export function renderPersonalDetails(): ({
  // eslint-disable-next-line
  setEmployee,
  employee,
}: {
  setEmployee: (s: EmployeeProfileType) => void;
  employee: EmployeeProfileType;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
}) => JSX.Element {
  return function PersonalDetails({
    setEmployee,
    employee,
    actions,
  }: {
    setEmployee: (s: EmployeeProfileType) => void;
    employee: EmployeeProfileType;
    actions: {
      [key: string]: {
        action: boolean;
        locations?: string[] | undefined;
      };
    };
  }) {
    const handleSaveEdit = async (data: PersonalDetails) => {
      const finalData: EmployeeProfileType = {
        id: employee.id,
        details: {
          fullName: {
            prefix: data.prefix,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
          },
          gender: data.gender,
          birthDate: data.birthDate,
          maritalStatus: data.maritalStatus,
          identificationMarks: data.identificationMarks,
          bloodGroup: data.bloodGroup,
          height: parseFloat(`${data.height}`),
          weight: parseFloat(`${data.weight}`),
        },
      } as any;

      try {
        setEmployee(finalData as EmployeeProfileType);
      } catch (error) {
        toast((error as any).message);
      }
    };

    const PersonalDetailCard = renderCardComponent();
    const PersonalDetailCardHeader: CardHeader = {
      title: 'Personal Detail',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            name: 'Edit',
            behaviour: 'modal',
            modal: {
              size: 'large',
              title: 'Edit',
              content: ({ onClose }) => {
                const PersonalDetailsEditFormData: PersonalDetails = {
                  prefix: employee.details.fullName?.prefix || '',
                  firstName: employee.details.fullName?.firstName || '',
                  middleName: employee.details.fullName?.middleName || '',
                  lastName: employee.details.fullName?.lastName || '',
                  gender: employee.details?.gender,
                  birthDate: employee.details?.birthDate,
                  maritalStatus: employee.details?.maritalStatus,
                  // photo: employee.details.photo,
                  identificationMarks: employee.details?.identificationMarks,
                  bloodGroup: employee.details?.bloodGroup,
                  height: employee.details?.height,
                  weight: employee.details?.weight,
                };
                const Form1 = renderEditPersonalDetailsForm();
                return (
                  <Form1
                    data={PersonalDetailsEditFormData}
                    onSave={async (form) => {
                      await handleSaveEdit(form);
                      onClose();
                    }}
                    onCancel={onClose}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const PersonalDetailData: ColumnizedContent = [
      [
        {
          key: 'Name:',
          value: employee.name,
          type: 'text',
        },
        {
          key: 'Gender:',
          value: employee.details?.gender,
          type: 'text',
        },
        {
          key: 'Marital Status:',
          value: employee.details?.maritalStatus || '',
          type: 'text',
        },
        {
          key: 'Birth Date:',
          value: employee.details?.birthDate || '',
          type: 'text',
        },
        {
          key: 'Age:',
          value: employee.details?.birthDate
            ? moment().diff(employee.details.birthDate, 'years').toString()
            : '',
          type: 'text',
        },
      ],
      [
        {
          key: 'Identification Marks:',
          value: employee.details?.identificationMarks || '',
          type: 'text',
        },
        {
          key: 'Blood Group:',
          value: employee.details?.bloodGroup || '',
          type: 'text',
        },
        // {
        //   key: 'Retirement Date:',
        //   value: employee.details.retirementDate || '',
        //   type: 'text'
        // },
        {
          key: 'Height:',
          value: employee.details?.height?.toString() || '',
          type: 'text',
        },
        {
          key: 'Weight:',
          value: employee.details?.weight?.toString() || '',
          type: 'text',
        },
      ],
      [
        {
          key: 'profile-image:',
          value: employee.details?.photo || '',
          type: 'image',
        },
      ],
    ];

    const PersonalDetailCardBody: CardBody = {
      type: 'columns',
      body: PersonalDetailData,
    };

    return (
      <>
        <PersonalDetailCard
          body={PersonalDetailCardBody}
          header={PersonalDetailCardHeader}
          auth={{ actions }}
        />
      </>
    );
  };
}
