import { renderFormV2 } from '@erp_core/erp-ui-components';

type ScheEmployeeType = {
  roleName: string;
  count: number;
  supervisor: 'profile' | 'rotational';
};

export const AddEmployeeScheduleForm = ({
  onClose,
  save,
  data,
}: {
  save: (form: any) => Promise<void>;
  data?: ScheEmployeeType;
  onClose: () => void;
}) => {
  const Form = renderFormV2<{
    roleName: string;
    count: number;
    supervisor: 'profile' | 'rotational';
  }>({
    fieldsData: [
      {
        property: 'roleName',
        label: 'Role Name',
        required: true,
        type: 'input',
      },
      {
        property: 'supervisor',
        label: 'Supervisor Type',
        required: true,
        type: 'select',
        options: [
          { text: 'Select', value: '' },
          { text: 'Profile', value: 'profile' },
          { text: 'Rotational', value: 'rotational' },
        ],
      },
      {
        property: 'count',
        label: 'Count',
        required: true,
        type: 'number',
      },
    ],
    onSubmit: async (form) => {
      await save(form);
      onClose();
    },
    mapTToU: (p) => {
      return {
        roleName: p.roleName as string,
        count: parseInt(p.count + ''),
        supervisor: p.supervisor as 'profile' | 'rotational',
      };
    },
    allowUnchanged: true,
    initialFormState: {
      roleName: data?.roleName || '',
      count: data?.count || 1,
      supervisor: data?.supervisor || 'rotational',
    },
  });

  return <Form />;
};
