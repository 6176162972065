import {
  Grade,
  GradeFilter,
} from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GradeInterface } from '../../../../../models/interfaces/inventory/grade';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpGradeRepo extends GenericHttpWithLogsRepo<Grade, GradeFilter>
  implements GradeInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/grades',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async getProperties(id: string): Promise<ItemPropertyValue[]> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/properties`
    );
    if (res.status === 200) {
      const { data } = res;

      return data.map((c) => c as ItemPropertyValue);
    }

    return [];
  }

  async setPropertyValues(id: string, data: any): Promise<Grade> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/${id}/property-values`,
      data
    );
    if (res.status === 200) {
      const { data } = res;

      return data as Grade;
    }

    return {} as Grade;
  }

  async getSpecificationsTemplate(id: string): Promise<{ template: string }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.get(
      `${this.resourceEndpoint}/${id}/specifications-template`
    );
    if (res.status === 200) {
      const { data } = res;

      return data as Promise<{ template: string }>;
    }

    return { template: '' };
  }
}
