import { Vendor } from '@erp_core/erp-types/dist/modules/order';
import { useEffect, useState } from 'react';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedDeliveryTerm } from '../../../../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../../../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedItem } from '../../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedVendor } from '../../../../../hooks/inventory/purchase/vendors/use-vendor';
import { UseVendorItem } from '../../../../../hooks/inventory/purchase/vendors/use-vendor-item';
import { UseCombinedStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { ItemInterface } from '../../../../../models/interfaces/inventory/item';
import { renderBrochure } from '../brochure';
import { renderContact } from '../contact';
import { renderVendorProfile } from '../profile';
import { renderVendorRegistrations } from '../registrations';
import { renderVendorTerms } from '../terms';
import { renderVendorItemsList } from '../vendor-items/vendor-items';

export type VendorProfileProp = {
  useCombinedVendor: UseCombinedVendor;
  id: string;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useVendorItem: UseVendorItem;
  useUserAuthorization: UseUserAuthorization;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedItem: UseCombinedItem;
  useCombinedStockGroup: UseCombinedStockGroup;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  vendor: Vendor;
  loading: boolean;
  setVendor: (s: Vendor) => Promise<Vendor>;
  itemService: ItemInterface;
};

export function renderVendorProfileTabs(): (
  x: VendorProfileProp
) => JSX.Element {
  return function VendorProfileTabs({
    vendor,
    loading,
    useCombinedVendor,
    id,
    useFileTransfer,
    usePublicTransfer,
    useCombinedItem,
    useVendorItem,
    useUserAuthorization,
    useCombinedPaymentTerm,
    useCombinedStockGroup,
    useCombinedDeliveryTerm,
    setVendor,
    itemService,
  }: VendorProfileProp): JSX.Element {
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
      let tabs: Array<string> = [];

      tabs = ['Basic Info', 'Items', 'Terms', 'Registrations', 'Brochure'];
      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Basic Info': {
            const Profile = renderVendorProfile({
              usePublicTransfer,
              setVendor,
              vendor,
              loading,
            });
            const Contact = renderContact({ vendor, setVendor });
            Props = (
              <div className='w-full space-y-3 flex flex-col'>
                <div className='w-full space-y-3'>
                  <Profile />
                  <Contact />
                </div>
              </div>
            );
            break;
          }
          case 'Items': {
            const VendorItemsList = renderVendorItemsList({
              useVendorItem,
              useUserAuthorization,
              usePublicTransfer,
              useCombinedPaymentTerm,
              useFileTransfer,
              useCombinedItem,
              useCombinedStockGroup,
              useCombinedDeliveryTerm,
              vendor,
              setVendor,
              loading,
              itemService,
            });
            Props = <VendorItemsList />;
            break;
          }
          case 'Terms': {
            const Terms = renderVendorTerms({
              vendor,
              loading,
              setVendor,
              useCombinedDeliveryTerm,
              useCombinedPaymentTerm,
            });

            Props = <Terms />;
            break;
          }
          case 'Registrations': {
            const Registrations = renderVendorRegistrations({
              vendor,
              setVendor,
              useUserAuthorization,
              useFileTransfer,
            });

            Props = <Registrations />;
            break;
          }
          case 'Brochure': {
            const Brochure = renderBrochure({
              vendor,
              setVendor,
              usePublicTransfer,
              useCombinedVendor,
            });

            Props = <Brochure />;
            break;
          }
          default: {
            const Profile = renderVendorProfile({
              loading,
              usePublicTransfer,
              vendor,
              setVendor,
            });
            Props = <Profile />;
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab, id]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full flex flex-wrap'>{body}</div>
        </div>
      </div>
    );
  };
}
