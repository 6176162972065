/* eslint-disable prefer-template */
import { HazardStatement } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type AddHazardStatementFormProps = {
  onSave: (data: Partial<HazardStatement>) => Promise<void>;
};

// eslint-disable-next-line
export function renderAddHazardStatementsForm(): (
  data: AddHazardStatementFormProps
) => JSX.Element {
  return function AddHazardStatementsForm({
    onSave,
  }: AddHazardStatementFormProps): JSX.Element {
    const formProps: FormProps<Partial<HazardStatement>> = {
      fieldsData: [
        {
          property: 'code',
          type: 'input',
          required: true,
          label: 'Statement Code',
        },
        {
          property: 'statement',
          required: true,
          type: 'input',
          label: 'Statement',
        },
      ],
      initialFormState: {
        code: '',
        chapterNo: '',
      },
      onSubmit: onSave,
      mapTToU: mapFFDToF,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<HazardStatement>>(formProps);

    return <Form />;

    function mapFFDToF(
      b: FormDataType<Partial<HazardStatement>>
    ): HazardStatement {
      return (b as unknown) as HazardStatement;
    }
  };
}
