/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedItem } from '../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedClient } from '../../../../hooks/order/sales/client/use-client';

type Props = {
  useCombinedItem: UseCombinedItem;
  useCombinedClient: UseCombinedClient;
  onClose: () => void;
};
// eslint-disable-next-line
export function renderAddSalesOrderForm({
  useCombinedItem,
  useCombinedClient,
  onClose,
}: Props): (props: any) => JSX.Element {
  return function AddSalesOrderForm({
    data,
    onSave,
  }: {
    data: {};
    onSave: any;
  }): JSX.Element {
    const Form = renderFormV2({
      onSubmit: async (form) => {
        onSave(form);
        onClose();
      },
      mapTToU: (f) => f,
      initialFormState: {
        name: '',
        deliverBy: '',
        client: '',
        remarks: '',

        id: '',
        quantity: '',
        color: '',
        grade: '',
        packaging: '',
        stateOfMatter: '',
      },
      fieldsData: [
        {
          property: 'name',
          label: 'Name',
          type: 'input',
        },
        {
          property: 'deliverBy',
          label: 'Deliver By',
          type: 'date',
        },
        {
          property: 'client',
          label: 'Client',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedClient,
            onSearchValueSelect: () => null,
          },
        },
        {
          groupName: 'items',
          property: 'id',
          label: 'Item',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedItem,
            onSearchValueSelect: (v) => {
              // console.log(v);
            },
          },
        },
        {
          groupName: 'items',
          property: 'quantity',
          label: 'Quantity',
          type: 'number',
        },
        {
          groupName: 'items',
          property: 'color',
          label: 'Color',
          type: 'input',
        },
        {
          groupName: 'items',
          property: 'grade',
          label: 'Grade',
          type: 'input',
        },
        {
          groupName: 'items',
          property: 'packaging',
          label: 'Packaging',
          type: 'input',
        },
        {
          groupName: 'items',
          property: 'stateOfMatter',
          label: 'State of matter',
          type: 'input',
        },
        {
          property: 'remarks',
          label: 'Remarks',
          type: 'input',
        },
      ],
    });

    return <Form />;
  };
}
