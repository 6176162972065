import { Terms } from '@erp_core/erp-types/dist/types/modules/order/purchase/purchase-enquiry';
import {
  Vendor,
  VendorContact,
  VendorLocation,
} from '@erp_core/erp-types/dist/types/modules/order/purchase/vendor';
import { LoadingButton } from '@erp_core/erp-ui-components';
import { useState } from 'react';

export function EditVendorInEnquiry({
  onSave,
  vendor,
  vendors,
  modeOfTransportOptions,
  deliveryTermsOptions,
}: {
  onSave: (v: {
    id: string;
    name: string;
    location: VendorLocation;
    contact: VendorContact;
    verified: boolean;
    selected: boolean;
    terms: Terms;
  }) => Promise<void>;
  vendors: Vendor[];
  vendor: {
    id: string;
    name: string;
    location: VendorLocation;
    contact: VendorContact;
    verified: boolean;
    selected: boolean;
    terms: Terms;
  };
  deliveryTermsOptions: Array<{ id: string; name: string }>;
  modeOfTransportOptions: Array<{ id: string; name: string }>;
}) {
  const [currentVendor, setCurrentVendor] = useState<{
    id: string;
    name: string;
    location: VendorLocation;
    contact: VendorContact;
    verified: boolean;
    selected: boolean;
    terms: Terms;
  }>(vendor);

  const availableLocations =
    vendors.find((x) => x.id === vendor.id)?.details?.locations || [];
  const availableContacts =
    vendors.find((x) => x.id === vendor.id)?.details?.contact || [];

  return (
    <div>
      <div className='text-center font-bold'>{currentVendor.name}</div>
      <div>
        <span className='font-semibold'>Location: </span>
        <select
          value={currentVendor.location.id}
          onChange={(e) => {
            const ven = vendors.find((x) => x.id === vendor.id);
            const loc = ven?.details?.locations?.find(
              (x) => x.id === e.target.value
            );
            if (loc) {
              setCurrentVendor((x) => {
                const newCurrVendor = { ...x };
                newCurrVendor.location = loc;
                return newCurrVendor;
              });
            }
          }}
        >
          {availableLocations.map((o) => (
            <option key={o.id} value={o.id}>
              {o.address?.addressLine1}, {o.address?.addressLine2},{' '}
              {o.address?.city}
            </option>
          ))}
        </select>
      </div>
      <div>
        <span className='font-semibold'>Contact: </span>
        <select
          value={currentVendor.contact.id}
          onChange={(e) => {
            const ven = vendors.find((x) => x.id === vendor.id);
            const con = ven?.details?.contact?.find(
              (x) => x.id === e.target.value
            );
            if (con) {
              setCurrentVendor((x) => {
                const newCurrVendor = { ...x };
                newCurrVendor.contact = con;
                return newCurrVendor;
              });
            }
          }}
        >
          {availableContacts.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}, {o.designation}
            </option>
          ))}
        </select>
      </div>

      <div>
        <span className='font-semibold'>Mode Of Transport: </span>
        <select
          value={currentVendor.terms.delivery?.modeOfTransport?.id}
          onChange={(e) => {
            const mod = modeOfTransportOptions.find(
              (x) => x.id === e.target.value
            );
            if (mod) {
              setCurrentVendor((x) => {
                const newCurrVendor = { ...x };
                newCurrVendor.terms.delivery.modeOfTransport = mod;
                return newCurrVendor;
              });
            }
          }}
        >
          {modeOfTransportOptions.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <span className='font-semibold'>Delivery Terms: </span>
        <select
          value={currentVendor.terms.delivery?.deliveryTerms?.id}
          onChange={(e) => {
            const deli = deliveryTermsOptions.find(
              (x) => x.id === e.target.value
            );
            if (deli) {
              setCurrentVendor((x) => {
                const newCurrVendor = { ...x };
                newCurrVendor.terms.delivery.deliveryTerms = deli;
                return newCurrVendor;
              });
            }
          }}
        >
          {deliveryTermsOptions.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </select>
      </div>

      <LoadingButton
        text={'Save'}
        behaviorFn={async () => {
          onSave(currentVendor);
        }}
        behaviourParams={{}}
      />
    </div>
  );
}
