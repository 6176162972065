import { DashboardType } from '@erp_core/erp-types/dist/modules/analytics';
import {
  LoadingButton,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';

export function renderEditUserSettings() {
  const Table = renderTableComponent();
  return function EditUserSettings({
    userSettings,
    module,
    filteredReports,
    update,
  }: {
    userSettings: any;
    module: string;
    filteredReports: DashboardType[];
    update: (data: any) => void;
  }) {
    const [currentUserSettings, setCurrentUserSettings] = useState<any>(
      userSettings
    );

    useEffect(() => {
      setCurrentUserSettings(userSettings);
      // eslint-disable-next-line
    }, []);

    function updateUserSettings(
      reportName: string,
      key: 'sequence' | 'rowSpan' | 'colSpan' | 'enabled',
      value: number | string
    ) {
      const newSettings = JSON.parse(JSON.stringify(currentUserSettings));
      if (!newSettings[module]) {
        newSettings[module] = {};
      }

      if (!newSettings[module][reportName]) {
        newSettings[module][reportName] = {};
      }

      newSettings[module][reportName][key] = value;
      // update(newSettings);
      setCurrentUserSettings(newSettings);
    }
    return (
      <div>
        <Table
          header={[
            [
              { name: 'Report Name', rowSpan: 2 },
              { name: 'Sub-Module', rowSpan: 2 },
              { name: 'Enabled', rowSpan: 2 },
              { name: 'Display Position', style: 'text-center', colSpan: 2 },
            ],
            [
              { name: 'System', style: 'border-l border-gray-200' },
              { name: 'User' },
            ],
          ]}
          body={filteredReports
            .sort((a, b) => {
              const aSeq =
                (currentUserSettings[module] &&
                  currentUserSettings[module][a.name] &&
                  currentUserSettings[module][a.name].sequence) ||
                0;
              const bSeq =
                (currentUserSettings[module] &&
                  currentUserSettings[module][b.name] &&
                  currentUserSettings[module][b.name].sequence) ||
                0;
              return aSeq - bSeq;
            })
            .map((x) => {
              return {
                cells: [
                  { value: x.name },
                  { value: x.subModule },
                  {
                    value: (
                      <>
                        {/* {currentUserSettings[module] && currentUserSettings[module][x.name] && currentUserSettings[module][x.name].enabled === 'yes' ? 'yes' : 'no'} */}

                        <select
                          className='w-12 mx-2'
                          defaultValue={
                            (currentUserSettings[module] &&
                              currentUserSettings[module][x.name] &&
                              currentUserSettings[module][x.name].enabled) ||
                            'no'
                          }
                          onChange={(e) => {
                            updateUserSettings(
                              x.name,
                              'enabled',
                              e.target.value
                            );
                          }}
                        >
                          <option value='no'>no</option>
                          <option value='yes'>yes</option>
                        </select>
                      </>
                    ),
                  },
                  {
                    value: (
                      <div>
                        <div>
                          Sequence:{' '}
                          {x.details?.displaySettings?.sequence || '-'}
                        </div>
                        <div>
                          RowSpan: {x.details?.displaySettings?.rowSpan || '-'}
                        </div>
                        <div>
                          ColSpan: {x.details?.displaySettings?.colSpan || '-'}
                        </div>
                      </div>
                    ),
                  },
                  {
                    value: (
                      <>
                        <div>
                          Sequence:{' '}
                          <input
                            className='w-12 mx-2'
                            type='number'
                            min={0}
                            defaultValue={
                              (currentUserSettings[module] &&
                                currentUserSettings[module][x.name] &&
                                currentUserSettings[module][x.name].sequence) ||
                              0
                            }
                            onBlur={(e) => {
                              updateUserSettings(
                                x.name,
                                'sequence',
                                parseFloat(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div>
                          RowSpan:{' '}
                          <input
                            className='w-12 mx-2'
                            type='number'
                            min={0}
                            defaultValue={
                              (currentUserSettings[module] &&
                                currentUserSettings[module][x.name] &&
                                currentUserSettings[module][x.name].rowSpan) ||
                              0
                            }
                            onBlur={(e) => {
                              updateUserSettings(
                                x.name,
                                'rowSpan',
                                parseFloat(e.target.value)
                              );
                            }}
                          />
                        </div>
                        <div>
                          ColSpan:{' '}
                          <input
                            className='w-12 mx-2'
                            style={{ width: '24px' }}
                            type='number'
                            min={0}
                            defaultValue={
                              (currentUserSettings[module] &&
                                currentUserSettings[module][x.name] &&
                                currentUserSettings[module][x.name].colSpan) ||
                              0
                            }
                            onBlur={(e) => {
                              updateUserSettings(
                                x.name,
                                'colSpan',
                                parseFloat(e.target.value)
                              );
                            }}
                          />
                        </div>
                      </>
                    ),
                  },
                ],
              };
            })}
        />
        <div className='flex justify-center'>
          <LoadingButton
            text='Save'
            behaviorFn={async () => {
              update(currentUserSettings);
            }}
          />
        </div>
      </div>
    );
  };
}
