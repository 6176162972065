/* eslint-disable no-unused-vars */
import { useLocation, useNavigate } from 'react-router';
import { resetUserToken } from '../../utils/token-helper';

export type UseLogout = () => {
  logout: () => void;
};

export interface UseLogoutOptions {}

export function createUseLogout(a: UseLogoutOptions): UseLogout {
  return () => {
    const location = useLocation();
    const navigate = useNavigate();

    const logout = () => {
      resetUserToken();
      const loc = location.pathname;
      localStorage.setItem('redirect-url', loc);
      navigate('/login');
    };
    return {
      logout,
    };
  };
}
