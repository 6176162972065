import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { Grade } from '@erp_core/erp-types/dist/types/modules/inventory/grade';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedUser } from '../../../../hooks/admin/user-admin/use-users-admin';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedGrade } from '../../../../hooks/inventory/grade/use-grade';
import { UseCombinedBomProject } from '../../../../hooks/order/work-order/bom-project/use-bom-project';
import { GradeInterface } from '../../../../models/interfaces/inventory/grade';

export function renderStep1({
  useCombinedGrade,
  useCombinedUser,
  useCombinedGodown,
  useCombinedBomProject,
  currentUser,
  gradeService,
}: {
  useCombinedGrade: UseCombinedGrade;
  useCombinedUser: UseCombinedUser;
  useCombinedGodown: UseCombinedGodown;
  useCombinedBomProject: UseCombinedBomProject;
  gradeService: GradeInterface;
  currentUser: CurrentUserType;
}): ({ onSave }: { onSave: (d: any) => Promise<void> }) => JSX.Element {
  return function Step1({
    onSave,
  }: {
    onSave: (d: any) => Promise<void>;
  }): JSX.Element {
    const initialData = {
      bomType: '',
      grade: { id: '', name: '' },
      engineer: { id: currentUser.id, name: currentUser.name },
      bomProject: { id: '', name: '' },
      quantity: 0,
      dispatchTo: { id: '', name: '' },
      // priority: '',
      targetDate: '',
    };
    const Form = renderFormV2({
      style: 'w-1/2',
      fieldsData: [
        {
          type: 'select',
          property: 'bomType',
          label: 'BOM Type',
          options: [
            { value: '', text: 'Select' },
            { value: 'M-BOM', text: 'Maintenance' },
            { value: 'P-BOM', text: 'Production' },
            { value: 'F-BOM', text: 'Fabrication' },
            { value: 'A-BOM', text: 'Asset' },
          ],
        },
        {
          type: 'searchable-select',
          property: 'bomProject',
          searchOptions: {
            useSearch: useCombinedBomProject,
            onSearchValueSelect(u) {},
          },
          label: 'Bom Project',
          style: '',
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'grade',
          label: 'Item Grade',
          searchOptions: {
            useSearch: useCombinedGrade,
            onSearchValueSelect: (u: any) => {},
            // filter?: any;
            searchOptionsBody: {
              customBody: (data: Grade) => {
                return (
                  <div>
                    <div className='font-bold'>{data.name}</div>
                    <div>{data.item?.name}</div>
                  </div>
                );
              },
            },
          },
          required: true,
        },
        {
          type: 'number',
          property: 'quantity',
          label: async (f) => {
            let grade: any = f.grade;
            if (grade?.id) {
              const gradeProperties = await gradeService.getProperties(
                grade.id
              );
              console.log(gradeProperties);

              const uom =
                gradeProperties?.find((x) => x.name === 'uom')?.value?.data?.[
                  'uom-user'
                ]?.name || '??';

              return `Quantity of ${grade?.name} in ${uom}`;
            }
            return 'Quantity in ??';
          },
          style: '',
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'engineer',
          searchOptions: {
            useSearch: useCombinedUser,
            onSearchValueSelect(u) {},
          },
          label: 'Engineer',
          style: '',
          required: true,
        },
        {
          type: 'searchable-select',
          property: 'dispatchTo',
          searchOptions: {
            useSearch: useCombinedGodown,
            onSearchValueSelect(u) {},
          },
          label: 'Dispatch to',
          style: '',
          required: true,
        },
        // {
        //   type: 'select',
        //   options: [
        //     { value: '', text: 'Select' },
        //     { value: 'low', text: 'low' },
        //     { value: 'medium', text: 'medium' },
        //     { value: 'high', text: 'high' },
        //   ],
        //   property: 'priority',
        //   label: 'Priority',
        //   style: 'mt-2',
        //   required: true,
        // },
        {
          type: 'date',
          property: 'targetDate',
          label: 'Target Date',
          style: '',
          // required: true,
        },
      ],
      initialFormState: initialData,
      onSubmit: async (form: any) => {
        const gradeProperties = await gradeService.getProperties(
          form?.grade?.id
        );
        const uom =
          gradeProperties?.find((x) => x.name === 'uom')?.value?.data?.[
            'uom-user'
          ]?.name || '??';
        onSave({ ...initialData, ...form, uom, priority: 'low' });
      },
      mapTToU: (x) => x,
    });
    return (
      <div>
        <Form />
      </div>
    );
  };
}
