/* eslint-disable no-unused-vars */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';

export function renderClientOffer(): () => JSX.Element {
  return function ClientOffer(): JSX.Element {
    const ClientOfferData = [
      {
        item: 'Rice',
        offerDate: '12-Oct-2023',
        targetDate: '12-Nov-2023',
      },
      {
        item: 'wheat',
        offerDate: '07-Oct-2023',
        targetDate: '07-Nov-2023',
      },
    ];

    const BomRegisterTable = renderTableComponent();
    const BomRegisterTableHeader: TableHeader = [
      [{ name: 'Offer Date' }, { name: 'Item' }, { name: 'Target Date' }],
    ];

    const tableData =
      ClientOfferData?.map((r, idx) => ({
        cells: [
          { value: r.offerDate },
          { value: r.item },
          { value: r.targetDate },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Client Offers',
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <BomRegisterTable header={BomRegisterTableHeader} body={tableBody} />
      ),
    };

    return (
      <>
        <Card header={header} body={body} />
      </>
    );
  };
}
