export function calculateIsExecutive(gradeId: string): boolean {
  const executiveLevelGrades = [
    'G13',
    'G14',
    'G15',
    'G16',
    'G17',
    'G18',
    'G19',
    'G20',
    'G21',
  ];

  return executiveLevelGrades.includes(gradeId);
}
