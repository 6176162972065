import { EmployeeFamilyType } from '@erp_core/erp-types/dist/modules/hrd';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableActionsType,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { UseUserAuthorization } from '../../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedEmployeeFamily } from '../../../../../../hooks/hrd/employee-family-dtls/use-employee-family';
import { UserRendererInterface } from '../../../../../common/fragments/user';
import { renderAddEmployeeFamilyForm } from '../../profile-form/family-details/employee-family-details-add-form';

type RenderEmployeeFamilyProps = {
  useCombinedEmployeeFamily: UseCombinedEmployeeFamily;
  employee: EmployeeProfileType;
  useUserAuthorization: UseUserAuthorization;
  actions: {
    [key: string]: {
      action: boolean;
      locations?: string[] | undefined;
    };
  };
  userRendererService: UserRendererInterface;
};

export function renderEmployeeFamilyDetails(): ({
  useCombinedEmployeeFamily,
  employee,
  useUserAuthorization,
}: RenderEmployeeFamilyProps) => JSX.Element {
  return function EmployeeFamily({
    employee,
    useCombinedEmployeeFamily,
    useUserAuthorization,
    actions,
    userRendererService,
  }: RenderEmployeeFamilyProps): JSX.Element {
    const {
      list: families,
      getAll: getEmployeeFamilies,
    } = useCombinedEmployeeFamily();

    const { syncSet: setFamily } = useCombinedEmployeeFamily();

    const { get: getAllowedActions } = useUserAuthorization();

    useEffect(() => {}, [families]);

    useEffect(() => {
      getEmployeeFamilies({ employeeId: employee.id });
      // eslint-disable-next-line
    }, []);

    function addActions(): TableActionsType[] {
      return [
        {
          name: 'Edit',
          auth: 'HRD:EMP-PROFILE:EDIT',
          show: ({ family }: { family: EmployeeFamilyType }) => {
            // we want to give ability to edit only when the gatepass
            // is in pending state.
            return true;
          },
          behaviour: 'modal',
          modal: {
            title: 'Edit',
            content: ({
              data: { family },
              onClose,
            }: {
              data: {
                family: EmployeeFamilyType;
              };
              onClose: () => void;
            }) => {
              return (
                <div>
                  <Form
                    onSave={async (e) => {
                      await editFamily(e, family.id, family.employee.id);
                      onClose();
                    }}
                    data={family as any}
                  />
                </div>
              );
            },
          },
        },
      ];
    }

    const editFamily = async (form: any, id: string, employeeId: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setFamily(finalData);
      toast('Family details edited sucessfully');
      getEmployeeFamilies({ employeeId: employeeId });
    };

    const Form = renderAddEmployeeFamilyForm();

    const saveFamily = async (form: Partial<EmployeeFamilyType>) => {
      try {
        const finalData = {
          ...form,
          employee: {
            id: employee.id,
            name: employee.name,
          },
        } as EmployeeFamilyType;
        await setFamily(finalData as EmployeeFamilyType);
        toast('Data added sucessfully');
        getEmployeeFamilies({ employeeId: employee.id });
      } catch (error) {
        toast('Something went wrong');
      }
    };

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Employee Family Details',
      actions: [
        {
          auth: 'HRD:EMP-PROFILE:EDIT',
          type: 'button',
          button: {
            behaviour: 'modal',
            modal: {
              title: 'Add Family',
              content: ({ onClose }) => {
                return (
                  <Form
                    onSave={async (form) => {
                      await saveFamily(form);
                      onClose();
                    }}
                  />
                );
              },
            },
            name: 'Add',
          },
        },
      ],
    };

    const Table = renderTableComponent();

    const tableHeader: TableHeader = [
      [
        { name: 'Relation' },
        { name: 'Name' },
        { name: 'Phone' },
        { name: 'Education' },
        { name: 'Profession' },
        { name: 'Birth Date' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
        // { name: 'Actions' },
      ],
    ];

    const tableData =
      families?.map((l) => ({
        rowData: {
          family: l,
        },
        cells: [
          { value: l.relation },
          { value: l.name },
          { value: l.phone },
          { value: l.education },
          { value: l.profession },
          { value: l.birthDate },
          {
            value: (
              <userRendererService.userCard
                link={true}
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
              />
            ),
          },
        ],
      })) || [];

    const tableBody: TableBody = tableData;

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={addActions()}
              auth={{ actions: getAllowedActions().actions }}
            />
          </div>
        </div>
      ),
    };

    return (
      <div className='border border-gray-200 rounded-lg p-2'>
        <Card header={cardHeader} body={cardBody} auth={{ actions }} />
      </div>
    );
  };
}
