/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  SalaryRevisionFilterType,
  SalaryRevisionType,
} from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { SalaryRevisionInterface } from '../../../../../models/interfaces/hrd/salary-revision';
import { CalESIC } from '../../../../../modules/hrd/salary-revisions/util/esic-calculator';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class SalaryRevisionHttpRepo
  extends GenericHttpWithLogsRepo<SalaryRevisionType, SalaryRevisionFilterType>
  implements SalaryRevisionInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/salary-revisions',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  async computeESICAndParams(p: {
    employeeId: string;
    companyGroupId: string;
    salaryParamId: string;
    parentSalaryRevisionId: string;
    formData: any;
    month: string;
  }): Promise<{ esic: CalESIC; salParams: any }> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.post(
      `${this.resourceEndpoint}/calculate-params`,
      p
    );
    if (res.status === 200) {
      const { data } = res;
      return data;
    }

    toast.error('ESIC Calculation Failed');
    return {} as any;
  }
}
