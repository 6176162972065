import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import {
  AttendanceStatus,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { AttendanceInterface } from '../../../../../../models/interfaces/hrd/attendance';

export function renderCustomFinalize({
  getData,
  currentUser,
  attendanceService,
}: {
  getData: () => Promise<void>;
  currentUser: CurrentUserType;
  attendanceService: AttendanceInterface;
}): ({ data, onClose }: { data: any; onClose: () => void }) => JSX.Element {
  return function Finalize({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const Form = renderFormV2({
      formName: 'Custom Finalize',
      fieldsData: [
        {
          property: 'status',
          type: 'select',
          label: 'Status',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Present', value: 'present' },
            { text: 'Present With Latemark', value: 'present-with-late' },
            { text: 'Present Half Day', value: 'present-half-day' },
            { text: 'Week-Off', value: 'week-off' },
            { text: 'Worked on WeekOff', value: 'worked-on-week-off' },
            { text: 'Absent', value: 'absent' },
          ],
        },
        {
          property: 'payableWorkHours',
          type: 'number',
          label: 'Payable Work Hours',
          required: true,
        },
        {
          property: 'totalWorkHours',
          type: 'number',
          label: 'Total Work Hours',
          required: true,
        },
        {
          property: 'shiftDuration',
          type: 'select',
          label: 'Shift Duration',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: '8', value: '8' },
            { text: '9', value: '9' },
          ],
        },
        {
          property: 'payableDay',
          type: 'select',
          label: 'Payable Day',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
        {
          property: 'payableDayPenalty',
          type: 'select',
          label: 'Payable Day Penalty',
          required: true,
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      style: 'w-1/2',
      initialFormState: {
        status: '',
        reason: '',
        payableWorkHours: 0,
        payableDay: 'no',
        payableDayPenalty: 'no',
        totalWorkHours: 0,
        shiftDuration: 8,
      },
      mapTToU: (p) => p,
      onSubmit: async (data) => {
        await attendanceService.finalize({
          summary: {
            payableWorkHours: parseFloat(`${data.payableWorkHours}`),
            payableDay: data.payableDay === 'yes' ? true : false,
            payableDayPenalty: data.payableDayPenalty === 'yes' ? true : false,
            totalWorkHours: parseFloat(`${data.totalWorkHours}`),
            shiftDuration: parseInt(`${data.shiftDuration}`),
          },
          employeeId: attendance.employee.id,
          date: attendance.date,
          attendanceId: attendance.id,
          status: data.status as AttendanceStatus,
          companyGroupId:
            attendance.companyGroup?.id ||
            attendance?.employeeProfile?.companyGroup?.id,
        });
        onClose();
      },
    });
    return <Form />;
  };
}
