/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { MisconductRuleBookType } from '@erp_core/erp-types/dist/modules/hrd';
import { ErpV2Api } from 'models/interfaces/adapters/erp-v2-api';
import { MisconductRuleBookFilter } from 'models/interfaces/hrd/disciplinary/misconduct-rule-book';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class MisconductRuleBookHttpRepo extends GenericHttpWithLogsRepo<
  MisconductRuleBookType,
  MisconductRuleBookFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/misconduct-rule-book',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
