import {
  renderCardComponent,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UseCombinedRole } from '../../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedDashboard } from '../../../hooks/analytics/dashboard/use-dashboard';
import { renderAddEditReport } from './forms/add-edit-report';

type ModuleData = {
  name: string;
  description: string;
  reports: {
    published: number;
    draft: number;
  };
};
export function renderDashboardSettings({
  useCombinedDashboard,
  useCombinedRole,
}: {
  useCombinedDashboard: UseCombinedDashboard;
  useCombinedRole: UseCombinedRole;
}) {
  return function DashboardSettings() {
    const { list: reports, getAll: getReports } = useCombinedDashboard();
    const Card = renderCardComponent();
    const Table = renderTableComponent();

    useEffect(() => {
      getReports();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (reports?.length) {
        const list = moduleList.map((x) => {
          return {
            name: x,
            description: `Dashboard for ${x}`,
            reports: {
              published: reports.filter(
                (y) => y.module === x && y.status === 'published'
              ).length,
              draft: reports.filter(
                (y) => y.module === x && y.status === 'draft'
              ).length,
            },
          };
        });

        setModules(list);
      }
      // eslint-disable-next-line
    }, [reports]);

    const moduleList = [
      'system',
      'hrd',
      'inventory',
      'purchase',
      'sales',
      'quality-assurance',
      'production',
      'accounts',
    ];

    const [modules, setModules] = useState<ModuleData[]>(
      moduleList.map((x) => {
        return {
          name: x,
          description: `Dashboard for ${x}`,
          reports: {
            published: 0,
            draft: 0,
          },
        };
      })
    );

    return (
      <Card
        header={{
          title: 'Dashboard Config',
        }}
        body={{
          type: 'jsx-component',
          body: (
            <Table
              header={[
                [
                  { name: 'Module' },
                  { name: 'Description' },
                  { name: 'Summary' },
                ],
              ]}
              body={modules.map((x) => {
                return {
                  cells: [
                    {
                      value: x.name,
                      style: 'capitalize',
                      link: `/system/group-settings/dashboard/module/${x.name}`,
                    },
                    { value: x.description },
                    {
                      value: (
                        <div>
                          <div>{x.reports?.published} - Published</div>
                          <div>{x.reports?.draft} - Draft</div>
                        </div>
                      ),
                    },
                  ],
                  rowData: {
                    module: x,
                  },
                };
              })}
              actions={[
                {
                  name: 'Add',
                  show: () => true,
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Report',
                    content: ({ data: { module }, onClose }) => {
                      console.log(module);
                      const Add = renderAddEditReport({
                        useCombinedRole,
                        useCombinedDashboard,
                      });
                      return <Add module={module.name} onClose={onClose} />;
                    },
                  },
                },
              ]}
            />
          ),
        }}
      />
    );
  };
}
