import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { PayrollReportsInterface } from '../../../../../models/interfaces/hrd/payroll-reports';
import {
  PayrollReport,
  PayrollReportFilter,
} from '../../../../../models/types/payroll-reports';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class PayrollReportsHttpRepo
  extends GenericHttpWithLogsRepo<PayrollReport, PayrollReportFilter>
  implements PayrollReportsInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/reports',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
