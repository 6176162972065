import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useEffect } from 'react';
import { UseCombinedLicenseContract } from '../../../../../hooks/order/work-order/license-contract/use-license-contract';

export type RenderVendorLicenseContractProps = {
  useCombinedLicenseContract: UseCombinedLicenseContract;
  id: string;
};

export function renderVendorLicenseContract({
  useCombinedLicenseContract,
  id,
}: RenderVendorLicenseContractProps): () => JSX.Element {
  return function LicenseContractList(): JSX.Element {
    const {
      list: licensceContracts,
      getAll: getLicenseContracts,
    } = useCombinedLicenseContract();

    useEffect(() => {
      // TODO: Add current location
      getLicenseContracts();
      // eslint-disable-next-line
    }, []);

    const vendorContracts = licensceContracts
      ?.filter((x) => x.partyType === 'vendor')
      .filter((y) => y.details.vendor.id === id);

    console.log('vendorContracts', vendorContracts);

    const Table = renderTableComponent();

    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: 'Vendor License Contract',
      actions: [],
    };

    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Description' },
        // { name: 'Document Type' },
        { name: 'Party Type' },
        // { name: 'Party Name' },
        { name: 'Valid From' },
        { name: 'Expires At' },
      ],
    ];
    const tableBody: TableBodyRow[] =
      vendorContracts?.map((e) => {
        const cells: Array<TableCell> = [
          { value: e?.name || '' },
          { value: e?.description },
          // { value: e?.docType.name },
          { value: e?.partyType },
          // { value: e?.partyName },
          { value: moment(e?.validFrom).format('DD MMM yyyy') },
          { value: moment(e?.expiresAt).format('DD MMM yyyy') },
        ];

        return {
          rowData: {
            licenseContract: e,
          },
          cells,
        };
      }) || [];
    const cardBody: CardBody = {
      type: 'jsx-component',
      body: <Table header={tableHeader} body={tableBody} actions={[]} />,
    };

    return (
      <div className='m-3'>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
