import {
  ConfiguratorFilterType,
  ConfiguratorType,
} from '@erp_core/erp-types/dist/modules/notification';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class NotificationConfiguratorHttpRepo extends GenericHttpWithLogsRepo<
  ConfiguratorType,
  ConfiguratorFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/notification-service/configurator',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
