import { ErpV2Api } from '../../../models/interfaces/adapters/erp-v2-api';
import {
  BaseResourceType,
  ResourceWithCurrentInterface,
} from '../../../models/interfaces/generic-resource';
import { GenericHttpRepo } from './generic-resource';

export class GenericHttpWithCurrentRepo<
  T extends BaseResourceType,
  U extends { search?: string },
  V
> extends GenericHttpRepo<T, U>
  implements ResourceWithCurrentInterface<T, U, V> {
  itemName: string;

  baseURI: string;

  resourceEndpoint: string;

  erpv2Api: ErpV2Api;

  constructor({
    itemName,
    baseURI,
    erpv2Api,
    resourceEndpoint,
  }: {
    itemName: string;
    erpv2Api: ErpV2Api;
    baseURI: string;
    resourceEndpoint: string;
  }) {
    super({ baseURI, erpv2Api, resourceEndpoint });

    this.itemName = itemName;
    this.baseURI = baseURI;
    this.erpv2Api = erpv2Api;
    this.resourceEndpoint = resourceEndpoint;
  }

  getLogs(
    id: string
  ): Promise<
    { event: string; time: string; action: string; details: string }[]
  > {
    throw new Error('Method not implemented.');
  }

  async getCurrent(): Promise<V> {
    const currentCompany = localStorage.getItem(this.itemName);
    if (currentCompany) {
      return JSON.parse(currentCompany) as V;
    }

    const res = await this.getAll();

    if (res.length) {
      const data = (res[0] as unknown) as V;
      await this.setCurrent(data);
      return data;
    }

    return {} as V;
  }

  async setCurrent(s: V): Promise<void> {
    if (s) {
      return localStorage.setItem(this.itemName, JSON.stringify(s));
    }
  }
}
