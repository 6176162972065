import Axios from 'axios';
import { Buffer } from 'buffer';
import { FileTransferInterface } from '../../../models/interfaces/fileTransfer/fileTransfer';

export class FileTransferRepo implements FileTransferInterface {
  private fileTransferServer;

  constructor(fileTransferServer: string) {
    this.fileTransferServer = fileTransferServer;
  }

  async set(
    id: string,
    data: any,
    ext: string
  ): Promise<{ success: boolean; url: string }> {
    const file = new FormData();
    file.append('file', data);

    try {
      const response = await Axios.post(
        `${this.fileTransferServer}/upload?filePath=${id}`,
        file,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status !== 200) {
        return { success: false, url: '' };
      }

      const imageURL = await response.data.url;

      return { success: true, url: imageURL };
    } catch (err) {
      console.log(err);
      return { success: false, url: '' };
    }
  }

  async publicSet(
    id: string,
    data: any
  ): Promise<{ success: boolean; url: string }> {
    const file = new FormData();
    file.append('file', data);

    try {
      const response = await Axios.post(
        `${this.fileTransferServer}/public-upload?filePath=${id}`,
        file,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const imageURL = await response.data.url;

      return { success: true, url: imageURL };
    } catch (err) {
      return { success: false, url: (err as any).toString() };
    }
  }

  async get(id: string, returnBase64?: boolean): Promise<string> {
    try {
      const { data } = await Axios.get(
        `${this.fileTransferServer}/download?filePath=${id}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );

      if (!data.data.success) return '';

      if (!returnBase64) {
        return Buffer.from(data.data.data, 'base64').toString('utf-8');
      }
      return data.data.data;
    } catch (err) {
      return (err as any).toString();
    }
  }

  async list(id: string): Promise<any> {
    try {
      const { data } = await Axios.get(
        `${this.fileTransferServer}/list?filePath=${id}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );

      if (!data.data.success) return '';
      return data.data.result;
    } catch (err) {
      return (err as any).toString();
    }
  }

  async publicGet(id: string, returnBase64?: boolean): Promise<string> {
    try {
      const { data } = await Axios.get(
        `${this.fileTransferServer}/download-public?filePath=${id}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      );
      if (!data.data.success) return '';

      if (!returnBase64) {
        return Buffer.from(data.data.data, 'base64').toString('utf-8');
      }
      return data.data.data;
    } catch (err) {
      return (err as any).toString();
    }
  }
}
