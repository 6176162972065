import { ItemPropertyValue } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import { SimpleImageRenderer } from '@erp_core/erp-ui-components';
import { PhotoIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { Fragment } from 'react';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import {
  renderFormula,
  renderFormulaInSimpleArray,
  renderHeaderTrailerNestedInComplexArray,
  renderVariableNestedInComplexArray,
} from './formula-renderer';

export function ValueRenderer({
  x,
  useFileTransfer,
  parentValues,
}: {
  parentValues: ItemPropertyValue[];
  x: ItemPropertyValue;
  useFileTransfer: UseFileTransfer;
}): JSX.Element {
  if (!x.value) {
    return <div key={x.name}>Value not set...</div>;
  }

  switch (x.type) {
    case 'input': {
      return <div key={x.name}>{x.value?.data}</div>;
    }

    case 'number': {
      return <div key={x.name}>{x.value?.data}</div>;
    }

    case 'value-uom': {
      if (!x.value?.data) {
        return <div key={x.name}>Value not set</div>;
      }

      if (
        x.value.data &&
        ['number', 'string'].includes(typeof x.value.data?.value)
      ) {
        return (
          <div key={x.name}>
            {x.value.data.value} {x.value.data.uom?.name}
          </div>
        );
      }

      return <div key={x.name}>Invalid Value</div>;
    }

    case 'range': {
      if (!x.value?.data) {
        return <div key={x.name}>Value not set</div>;
      }

      if (
        x.value.data &&
        ['number', 'string'].includes(typeof x.value.data?.maxValue)
      ) {
        if (x.range?.type === 'double') {
          return (
            <div key={x.name}>
              {x.value.data.minValue} to {x.value.data.maxValue}
            </div>
          );
        }
        return <div key={x.name}>{x.value.data.maxValue}</div>;
      }

      return <div key={x.name}>Invalid Value</div>;
    }

    case 'formula': {
      return (
        <div key={x.name}>{renderFormula(x, x.value?.data, parentValues)}</div>
      );
    }

    case 'select': {
      return <div key={x.name}>{x.value?.data}</div>;
    }

    case 'image': {
      const Image = SimpleImageRenderer({
        url: x.value?.data || '',
        isPrivate: true,
        useFileTransfer: useFileTransfer,
        defaultImage: <PhotoIcon className='w-12 h-10 animate-pulse' />,
      });

      return <div key={x.name}>{Image.content}</div>;
    }

    case 'searchable-select': {
      return <div key={x.name}>{x.value?.data?.name}</div>;
    }

    case 'searchable-multiselect': {
      return (
        <div key={x.name} className='flex'>
          {x.value?.data?.map((y) => (
            <div
              className='mx-1 border rounded-full border-gray-100 p-0.5'
              key={y.id}
            >
              {y.name}
            </div>
          ))}
        </div>
      );
    }

    case 'checklist': {
      return (
        <div key={x.name} className='flex'>
          {x.value?.data?.map((y) => (
            <div
              className='mx-1 border rounded-full border-gray-100 p-0.5'
              key={y}
            >
              {y}
            </div>
          ))}
        </div>
      );
    }

    case 'object': {
      return renderObjectProperties(x, parentValues);
    }

    case 'array-object': {
      return renderArrayProperties(x);
    }
    case 'fixed-array-object': {
      return renderArrayProperties(x);
    }

    case 'complex-array-object': {
      return renderComplexArrayProperties(x);
    }

    default: {
      return <div key={(x as any).name}>unknown-type</div>;
    }
  }
}

function renderComplexArrayProperties(x: ItemPropertyValue) {
  const length = x.complexArrayObjectProperties?.variableRow.length;

  const dataCells: Array<Array<JSX.Element>> = [];

  const headerRows = x.value?.data?.headerRows || [];

  headerRows.forEach((headerRow, headerIndex) => {
    const row: Array<JSX.Element> = [];
    x.complexArrayObjectProperties.variableRow?.forEach((prop, propIndex) => {
      const propName = prop.name;
      const type = prop.type;

      if (
        ['input', 'number', 'formula', 'select', 'searchable-select'].includes(
          type
        )
      ) {
        if (headerRow[propName]) {
          row.push(
            <div
              key={propIndex}
              className='text-center border border-gray-300 bg-green-100'
            >
              {type === 'formula' ? (
                <div>
                  {renderHeaderTrailerNestedInComplexArray(
                    headerRow[propName],
                    propName,
                    'header',
                    headerIndex,
                    x
                  )}
                </div>
              ) : null}
              {['input', 'number', 'select'].includes(type) ? (
                <div>{headerRow[propName]}</div>
              ) : null}
              {type === 'searchable-select' ? (
                <div>{headerRow[propName] && headerRow[propName]?.value}</div>
              ) : null}
            </div>
          );
        } else {
          row.push(
            <div
              key={propIndex}
              className='text-center border border-gray-300 bg-green-100'
            >
              <div>-</div>
            </div>
          );
        }
      } else if (type === 'object') {
        row.push(
          <div
            key={propIndex}
            className='text-center border border-gray-300 bg-green-100'
          >
            {_.keys(prop.childrenProperties).length ? (
              <div className='flex'>
                {_.keys(prop.childrenProperties).map(
                  (innerKey, innerPropIdx) => (
                    <div
                      key={innerKey + innerPropIdx}
                      className={`text-center w-1/${
                        prop.childrenProperties &&
                        _.keys(prop.childrenProperties).length
                      } border border-gray-300 bg-green-100 break-words`}
                    >
                      {headerRow[propName] ? (
                        <>
                          {typeof headerRow[propName][innerKey] === 'object'
                            ? headerRow[propName][innerKey]?.value
                            : null}
                          {prop.childrenProperties &&
                          prop.childrenProperties[innerKey].type === 'formula'
                            ? renderHeaderTrailerNestedInComplexArray(
                                headerRow[propName][innerKey],
                                `${propName}.${innerKey}`,
                                'header',
                                headerIndex,
                                x
                              )
                            : null}
                          {prop.childrenProperties &&
                          ['input', 'number', 'select'].includes(
                            prop.childrenProperties[innerKey].type
                          )
                            ? headerRow[propName][innerKey]
                            : null}
                        </>
                      ) : null}
                    </div>
                  )
                )}
              </div>
            ) : null}
          </div>
        );
      } else {
        row.push(
          <div
            key={propIndex}
            className='text-center border border-gray-300 bg-green-100'
          >
            <div>not-implemented</div>
          </div>
        );
      }
    });
    dataCells.push(row);
  });

  const variableRows = x.value?.data?.variableRows || [];
  variableRows.forEach((variableRow, variableIndex) => {
    const row: Array<JSX.Element> = [];
    x.complexArrayObjectProperties.variableRow?.forEach((prop) => {
      const propName = prop.name;
      const type = prop.type;

      if (
        ['input', 'number', 'formula', 'select', 'searchable-select'].includes(
          type
        )
      ) {
        if (variableRow[propName]) {
          row.push(
            <div className='text-center border border-gray-300'>
              {type === 'formula' ? (
                <div>
                  {renderVariableNestedInComplexArray(
                    variableRow[propName],
                    propName,
                    variableIndex,
                    x
                  )}
                </div>
              ) : null}
              {['input', 'number', 'select'].includes(type) ? (
                <div>{variableRow[propName] || '-'}</div>
              ) : null}
              {type === 'searchable-select' ? (
                <div>
                  {variableRow[propName] && variableRow[propName]?.value}
                </div>
              ) : null}
            </div>
          );
        } else {
          row.push(
            <div className='text-center border border-gray-300'>
              <div>-</div>
            </div>
          );
        }
      } else if (type === 'object') {
        row.push(
          <div className='text-center border border-gray-300'>
            {_.keys(prop.childrenProperties).length ? (
              <div className='flex'>
                {_.keys(prop.childrenProperties).map(
                  (innerKey, innerPropIdx) => (
                    <div
                      key={innerKey + innerPropIdx}
                      className={`text-center w-1/${
                        prop.childrenProperties &&
                        _.keys(prop.childrenProperties).length
                      } border border-gray-300 break-words`}
                    >
                      {variableRow[propName] ? (
                        <>
                          {typeof variableRow[propName][innerKey] === 'object'
                            ? variableRow[propName][innerKey]?.value
                            : null}
                          {prop.childrenProperties &&
                          prop.childrenProperties[innerKey].type === 'formula'
                            ? renderVariableNestedInComplexArray(
                                variableRow[propName][innerKey],
                                `${propName}.${innerKey}`,
                                variableIndex,
                                x
                              )
                            : null}
                          {prop.childrenProperties &&
                          ['input', 'number', 'select'].includes(
                            prop.childrenProperties[innerKey].type
                          )
                            ? variableRow[propName][innerKey]
                            : null}
                        </>
                      ) : null}
                    </div>
                  )
                )}
              </div>
            ) : null}
          </div>
        );
      } else {
        row.push(
          <div className='text-center border border-gray-300'>
            <div>not-implemented</div>
          </div>
        );
      }
    });
    dataCells.push(row);
  });

  const trailerRows = x.value?.data?.trailerRows || [];

  trailerRows.forEach((trailerRow, trailerIndex) => {
    const row: Array<JSX.Element> = [];
    x.complexArrayObjectProperties.trailerRows[trailerIndex]?.forEach(
      (prop) => {
        const propName = prop.name;
        const type = prop.type;

        if (
          [
            'input',
            'number',
            'formula',
            'select',
            'searchable-select',
          ].includes(type)
        ) {
          if (trailerRow[propName]) {
            row.push(
              <div className='text-center border border-gray-300 bg-blue-100'>
                {type === 'formula' ? (
                  <div>
                    {renderHeaderTrailerNestedInComplexArray(
                      trailerRow[propName],
                      propName,
                      'trailer',
                      trailerIndex,
                      x
                    )}
                  </div>
                ) : null}
                {['input', 'number', 'select'].includes(type) ? (
                  <div>{trailerRow[propName]}</div>
                ) : null}
                {type === 'searchable-select' ? (
                  <div>
                    {trailerRow[propName] && trailerRow[propName]?.value}
                  </div>
                ) : null}
              </div>
            );
          } else {
            row.push(
              <div className='text-center border border-gray-300 bg-blue-100'>
                <div>-</div>
              </div>
            );
          }
        } else if (type === 'object') {
          row.push(
            <div className='text-center border border-gray-300 bg-blue-100'>
              {_.keys(prop.childrenProperties).length ? (
                <div className='flex'>
                  {_.keys(prop.childrenProperties).map(
                    (innerKey, innerPropIdx) => (
                      <div
                        key={innerKey + innerPropIdx}
                        className={`text-center w-1/${
                          prop.childrenProperties &&
                          _.keys(prop.childrenProperties).length
                        } border border-gray-300 bg-blue-100 break-words`}
                      >
                        {trailerRow[propName] ? (
                          <>
                            {typeof trailerRow[propName][innerKey] === 'object'
                              ? trailerRow[propName][innerKey]?.value
                              : null}
                            {prop.childrenProperties &&
                            prop.childrenProperties[innerKey].type === 'formula'
                              ? renderHeaderTrailerNestedInComplexArray(
                                  trailerRow[propName][innerKey],
                                  `${propName}.${innerKey}`,
                                  'trailer',
                                  trailerIndex,
                                  x
                                )
                              : null}
                            {prop.childrenProperties &&
                            ['input', 'number', 'select'].includes(
                              prop.childrenProperties[innerKey].type
                            )
                              ? trailerRow[propName][innerKey]
                              : null}
                          </>
                        ) : null}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </div>
          );
        } else {
          row.push(
            <div className='text-center border border-gray-300'>
              <div>not-implemented</div>
            </div>
          );
        }
      }
    );
    dataCells.push(row);
  });

  return (
    <div key={x.name}>
      <div className={`grid grid-cols-${length}`}>
        {x.complexArrayObjectProperties?.variableRow?.map((prop, propIdx) => {
          return (
            <div
              key={prop.name + propIdx}
              className='text-center font-bold border border-gray-300'
            >
              <div>{prop.name}</div>
              {prop &&
              prop.childrenProperties &&
              _.keys(prop.childrenProperties).length ? (
                <div className='flex'>
                  {_.keys(prop.childrenProperties).map(
                    (innerKey, innerPropIdx) => (
                      <div
                        key={innerKey + innerPropIdx}
                        className={`text-center font-bold w-1/${
                          prop.childrenProperties &&
                          _.keys(prop.childrenProperties).length
                        } border border-gray-300`}
                      >
                        {innerKey || '-'}
                      </div>
                    )
                  )}
                </div>
              ) : null}
            </div>
          );
        })}
        {dataCells.map((d, dIdx) => (
          <Fragment key={dIdx}>
            {d.map((fg, fgIdx) => (
              <Fragment key={`${dIdx}-${fgIdx}`}>{fg}</Fragment>
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function renderArrayProperties(x: ItemPropertyValue) {
  if (!x.value) {
    x.value = {
      data: [],
    };
  }
  if (Array.isArray(x.value?.data)) {
    const length = _.keys(x.childrenListProperties).length;

    const dataCells: Array<JSX.Element> = [];

    x.value?.data.forEach((d, idx) => {
      _.keys(x.childrenListProperties)?.forEach((key, iidx) => {
        if (
          x.childrenListProperties &&
          x.childrenListProperties[key].type === 'object'
        ) {
          dataCells.push(
            <div key={`${idx}-${iidx}`} className='border border-gray-300'>
              {_.keys(
                x.childrenListProperties &&
                  x.childrenListProperties[key].childrenProperties
              ).map((childProp, cpropIdx) => {
                return (
                  <div
                    key={childProp + cpropIdx}
                    className='text-center border border-gray-300'
                  >
                    {d[key] && d[key][childProp] ? (
                      <>
                        {_.isObject(d[key][childProp]) &&
                        (d[key][childProp] as any).name
                          ? (d[key][childProp] as any).name
                          : d[key][childProp] || '-'}
                      </>
                    ) : (
                      '-'
                    )}
                  </div>
                );
              })}
            </div>
          );
        } else {
          if (
            ['formula'].includes(
              x.childrenListProperties && x.childrenListProperties[key].type
            )
          ) {
            dataCells.push(
              <div key={`${idx}-${iidx}`} className='border border-gray-300'>
                {renderFormulaInSimpleArray(d[key], key, x, idx)}
              </div>
            );
          } else {
            dataCells.push(
              <div key={`${idx}-${iidx}`} className='border border-gray-300'>
                {typeof d[key] === 'object' && d[key].name
                  ? d[key].name
                  : typeof d[key] === 'object'
                  ? JSON.stringify(d[key])
                  : d[key]}
              </div>
            );
          }
        }
      });
    });

    return (
      <div key={x.name}>
        <div className={`grid grid-cols-${length}`}>
          {_.keys(x.childrenListProperties)?.map((key, clpIdx) => {
            return (
              <div
                key={clpIdx}
                className='text-center font-bold border border-gray-300'
              >
                <div>{key}</div>
                {x.childrenListProperties &&
                x.childrenListProperties[key] &&
                x.childrenListProperties[key].childrenProperties &&
                _.keys(x.childrenListProperties[key].childrenProperties)
                  .length ? (
                  <div className='flex'>
                    {_.keys(
                      x.childrenListProperties[key].childrenProperties
                    ).map((innerKey, innerIdx) => (
                      <div
                        key={innerKey + innerIdx}
                        className={`text-center font-bold w-1/${
                          x.childrenListProperties &&
                          _.keys(
                            x.childrenListProperties[key].childrenProperties
                          ).length
                        } border border-gray-300`}
                      >
                        {innerKey || '-'}
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            );
          })}
          {dataCells.map((d) => d)}
        </div>
      </div>
    );
  }

  return <>-</>;
}

function renderObjectProperties(
  x: ItemPropertyValue,
  parentValues: ItemPropertyValue[]
) {
  return (
    <div key={x.name}>
      {_.keys(x.childrenProperties)?.map((key, idx) => {
        return (
          <div className='flex' key={key}>
            <div className='w-1/2 font-bold border border-gray-300'>{key}</div>
            <div key={idx} className='w-1/2 border border-gray-300'>
              {renderValue(x.value?.data[key], x, key, parentValues) || '-'}
            </div>
          </div>
        );
      })}
    </div>
  );
}

function renderValue(
  val,
  x: ItemPropertyValue,
  key: string,
  parentValues: ItemPropertyValue[]
) {
  const childType = x.childrenProperties && x.childrenProperties[key];

  let finalVal = val;
  if (childType?.type === 'formula') {
    finalVal = renderFormula(childType, finalVal, parentValues);
  }

  if (['string', 'number'].includes(typeof finalVal)) {
    return finalVal;
  }

  if (typeof finalVal === 'object') {
    if (finalVal.hasOwnProperty('name') && finalVal.hasOwnProperty('id')) {
      return finalVal.name;
    } else {
      const result: Array<JSX.Element> = [];
      _.each(val, (value, key) => {
        if (value && ['string', 'number'].includes(typeof value)) {
          result.push(
            <div className='grid grid-cols-2'>
              <div>{key}</div>
              {typeof value === 'object' ? (
                <div>{value?.name ? value.name : JSON.stringify(value)}</div>
              ) : (
                <div>{value}</div>
              )}
            </div>
          );
        } else if (
          value &&
          typeof value === 'object' &&
          value.hasOwnProperty('name') &&
          value.hasOwnProperty('id')
        ) {
          result.push(
            <div className='grid grid-cols-2'>
              <div>{key}</div>
              <div>{value.name}</div>
            </div>
          );
        } else if (value && typeof value === 'object') {
          result.push(
            <div className='grid grid-cols-2'>
              <div>{key}</div>
              <div>{JSON.stringify(value)}</div>
            </div>
          );
        } else {
          result.push(
            <div className='grid grid-cols-2'>
              <div>{key}</div>
              <div>-</div>
            </div>
          );
        }
      });
      return result;
    }
  }

  if (val) {
    console.log('unhandled-value', val);
  }
  return '-';
}
