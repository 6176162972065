export const impactCategories: Array<any> = [
  { name: 'Impact on quality' },
  { name: 'Impact on process' },
  { name: 'Impact on stability' },
  { name: 'Impact on Product Catlogue & STP' },
  { name: 'Validation Required' },
  { name: 'Qualification Required' },
  { name: 'Training Required' },
  { name: 'Impact on Documents' },
  { name: 'Other (if any)' },
];
