import { LoadingButton } from '@erp_core/erp-ui-components';
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline';
import { QuestionType } from 'models/types/questionnairetopic/question';
import { useState } from 'react';

export function MultichoiceAnswers({
  id,
  answers,
  saveQuestion,
  hasIdealAnswer,
  idealAnswer,
}: {
  id: string;
  answers: Array<string>;
  saveQuestion: (data: QuestionType) => Promise<void>;
  hasIdealAnswer: string;
  idealAnswer: string;
}): JSX.Element {
  const [ans, setAns] = useState<Array<string>>(answers);
  const [currentAns, setcurrentAns] = useState<{ idx: number; value: string }>({
    idx: 0,
    value: ans[0] || '',
  });
  const [idealAns, setIdealAns] = useState<string>();

  return (
    <div className='border-gray-800 p-4 mx-16 space-y-4'>
      <label className='block text-gray-700 text-sm font-bold mb-8 text-center'>
        Answers
      </label>
      <PlusIcon
        className='w-6 text-green-600 ml-auto'
        onClick={() => {
          setAns([...ans, '']);
        }}
      />
      {ans.map((a, index) => (
        <div
          className={`border border-gray-200 p-1 ${
            currentAns.idx === index ? 'bg-green-100' : ''
          }`}
          key={index}
        >
          {index + 1} - {a}{' '}
          <PencilIcon
            className='inline w-5'
            onClick={() => {
              setcurrentAns({ idx: index, value: a });
            }}
          />
        </div>
      ))}
      <div>
        <input
          className='w-100 border border-gray-200'
          type='text'
          value={currentAns.value}
          onChange={(e) => {
            setcurrentAns({ ...currentAns, ...{ value: e.target.value } });
          }}
        />
        <button
          className='bg-green-500 text-white'
          onClick={(e) => {
            e.preventDefault();
            const newAns = [...ans];
            newAns.splice(currentAns.idx, 1, currentAns.value);
            setAns(newAns);
          }}
        >
          {' '}
          Save
        </button>
      </div>

      {hasIdealAnswer === 'yes' ? (
        <>
          <div>
            <label className='block text-gray-700 text-sm font-bold mb-8 text-center'>
              Ideal Answer <br />
            </label>
            <input
              className='w-100 border border-gray-200'
              type='text'
              onChange={(e) => {
                setIdealAns(e.target.value);
              }}
            />

            <label className='w-100 border border-gray-200'>
              {idealAnswer}
            </label>
          </div>
        </>
      ) : (
        <></>
      )}
      {idealAns ? (
        ans.includes(idealAns) ? (
          <></>
        ) : (
          <>
            <label className='block text-red-700 text-sm font-bold mb-8 text-center'>
              Ideal answer should be from the Answers
            </label>
          </>
        )
      ) : null}

      <div className='my-2 mx-auto'>
        <LoadingButton
          defaultStyle='bg-green-500 text-white p-2'
          behaviorFn={async () => {
            saveQuestion({
              id,
              details: { answers: ans, idealAnswer: idealAns },
            } as QuestionType);
          }}
          text='Save Answers'
        />
      </div>
    </div>
  );
}
