/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
// import { Transaction } from '@erp_core/erp-types/dist/modules/inventory';
import { renderGenericStockTab, Transaction } from '@erp_core/erp-reports';
import _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { StockGroupInterface } from '../../../../../models/interfaces/inventory/stock-group';

// import { renderGenericStockTab } from '../../../../../components/stockTab';

type RenderStockTab = {
  services: {
    stockGroupService: StockGroupInterface;
  };
  stockGroupId: string;
};

export function renderStockTab({
  services: { stockGroupService },
  stockGroupId,
}: RenderStockTab) {
  return function () {
    const [tr, setTr] = useState<Transaction[]>([]);
    // console.log('tr', tr);

    const fetchTransactions = async () => {
      const tran = await stockGroupService.getTransactions(stockGroupId);
      const mapped = tran.map((t) => ({
        id: t.item.id,
        name: t.item.name,
        createdAt: t.createdAt,
        to: {
          id: t.toGodown?.id || '',
          name: t.toGodown?.name || '',
        },
        from: {
          id: t.fromGodown?.id || '',
          name: t.fromGodown?.name || '',
        },
        quantity: t.quantity,
      }));
      setTr(mapped);
      return tran;
    };

    useEffect(() => {
      fetchTransactions();
    }, []);

    const getList = (
      data: Transaction[],
      range: { from: string; to: string }
    ) => {
      const itemList: { id: string; name: string }[] = [];
      _.forEach(data, (n) => {
        if (
          !itemList.find((i) => i.id === n.id) &&
          (!n.from?.id || !n.to?.id)
        ) {
          itemList.push({ id: n.id, name: n.name });
        }
      });
      return itemList;
    };

    const calculateInwardQnt = (data: Transaction[], id?: string) => {
      const qty = data
        .filter((i) => i && !i.from?.id && i.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const calculateOutwardQnt = (data: Transaction[], id?: string) => {
      const qty = data
        .filter((i) => i && !i.to?.id && i.id === id)
        .reduce((c, p) => c + p.quantity, 0);
      return qty;
    };
    const StockT = renderGenericStockTab({
      // title: 'Item',
      primaryColumnTitle: 'item',
      transactions: tr,
      components: {
        LineChart: Line,
      },
      getList,
      calculateInwardQnt,
      calculateOutwardQnt,
    });
    return (
      <div>
        <StockT />
      </div>
    );
  };
}
