import {
  EmployeeProfileType,
  LoanType,
} from '@erp_core/erp-types/dist/modules/hrd';
import moment from 'moment';

export type ResourceReportType = {
  type: 'employee-loan';
  owner: 'ICICI INR' | 'DBS INR';
  isApplicable: (data: any) => boolean;
  fileName: () => string;
  lineBreak: '\n' | '\r\n' | '#~#\n';
  details: {
    type: 'summary' | 'detailed';
    separator: 'comma' | 'semi-colon' | 'hash-tilda';
    rows: Array<{
      type: 'header' | 'employee-details' | 'summary';
      values: Array<{ value: (data: any) => string }>;
    }>;
    recordEligibility: (data: any) => boolean;
  };
};

export function createResourceReports(
  data: Array<{
    employee: EmployeeProfileType;
    resource: LoanType;
  }>
): Array<{
  name: string;
  data: string;
}> {
  let momentUnix = moment().unix();
  let sum = 0;

  const reports: Array<ResourceReportType> = [
    {
      type: 'employee-loan',
      owner: 'DBS INR',
      lineBreak: '\n',
      isApplicable: (data) => {
        if (data) {
          return true;
        }

        return false;
      },
      fileName: () =>
        `DBS-${moment().format('YYYY-MM-DD--hh:mm:ss')}-report.txt`,
      details: {
        type: 'detailed',
        separator: 'comma',
        rows: [
          {
            type: 'header',
            values: [
              { value: () => 'HEADER' },
              {
                value: () => {
                  return moment().format('DDMMYYYY');
                },
              },
              { value: () => '' }, //Empty in my case
              // { value: () => 'INCATA01' },// TODO: Pick from Company Bank Profile
              {
                value: (data) => {
                  const emp = data[0].employee as EmployeeProfileType;
                  return emp.company.name;
                },
              },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
            ],
          },
          {
            type: 'employee-details',
            values: [
              {
                value: (data) => {
                  return 'PAYMENT';
                },
              },
              {
                value: (data) => {
                  return 'SAL';
                },
              }, // Maybe different for a different module
              {
                value: (data) => {
                  return '829210068217';
                },
              }, //Company's acc. no.
              {
                value: (data) => {
                  return 'INR';
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return 'INR';
                },
              },
              {
                value: (data) => {
                  return `${momentUnix.toString().slice(-6)}`;
                },
              },
              {
                value: (data) => {
                  return moment().format('DDMMYYYY');
                },
              }, //Date of loan payment
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  const emp = data.employee as EmployeeProfileType; //Emp Name
                  return emp.name;
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return '';
                },
              },
              {
                value: (data) => {
                  return (
                    data?.employee?.details?.bankDetails[0]?.accountNo || ''
                  ); //Emp Bank Acc. No.
                },
              },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              {
                value: (data) => {
                  return data.employee?.details?.bankDetails[0].ifsc || ''; //Emp IFSC
                },
              },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              {
                value: (data) => {
                  return data.resource?.amount || ''; //Emp-Loan Amount
                },
              },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '22' }, //Fixed val
              { value: () => '' },
              { value: () => '' },
              { value: () => `Employee Loan ${moment().format('MMM YYYY')}` }, //Remarks
            ],
          },
          {
            type: 'header',
            values: [
              { value: () => 'TRAILER' },
              {
                value: () => {
                  return `${data.length}`;
                },
              },
              {
                value: (data) => {
                  for (const value of data) {
                    sum += value?.resource?.amount;
                  }
                  return `${sum}`;
                },
              },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
              { value: () => '' },
            ],
          },
        ],
        recordEligibility: (data) => {
          return data.resource?.status === 'notify-to-disburse'; //Disbursed entries
        },
      },
    },
  ];
  const result: Array<{
    name: string;
    data: string;
  }> = [];

  reports.forEach((r) => {
    if (r.isApplicable(data)) {
      const g = generateReport(r, data);
      result.push(g);
    }
  });

  return result;
}

function generateReport(
  rep: ResourceReportType,
  data: Array<{
    employee: EmployeeProfileType;
    resource: LoanType;
  }>
): {
  name: string;
  data: string;
} {
  let detailsData: string = '';

  rep.details.rows.forEach((r) => {
    if (r.type === 'header') {
      let line = '';
      r.values.forEach((v) => {
        line += `${line.length ? sep[rep.details.separator] : ''}${v.value(
          data
        )}`;
      });

      detailsData += line;
    }

    if (r.type === 'employee-details') {
      data.forEach((x, idx) => {
        if (rep.details.recordEligibility(x)) {
          let line = '';
          r.values.forEach((v) => {
            line += `${line.length ? sep[rep.details.separator] : ''}${v.value(
              x
            )}`;
          });

          detailsData += line;
          if (idx !== data.length - 1) {
            detailsData += rep.lineBreak;
          }
        }
      });
    }

    if (r.type === 'summary') {
    }

    detailsData += rep.lineBreak;
  });

  return {
    name: rep.fileName(),
    data: detailsData,
  };
}

const sep = {
  comma: ',',
  'semi-colon': ';',
  'hash-tilda': '#~#',
};
