/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderHistory,
  renderInlineInput,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { CloudIcon, CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedUser } from '../../hooks/admin/user-admin/use-users-admin';
import { UseUserAuthorization } from '../../hooks/admin/user-authorization/use-user-authorization';
import { UseCombinedDeliveryTerm } from '../../hooks/constants/delivery-term/use-delivery-term';
import { UseCombinedPaymentTerm } from '../../hooks/constants/payment-term/use-payment-term';
import { UseFileTransfer } from '../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../hooks/file-transfer/use-public-transfer';
import { UseCombinedGodown } from '../../hooks/inventory/godown-inventory/use-godown';
import { UseCombinedGrade } from '../../hooks/inventory/grade/use-grade';
import { UseCombinedItem } from '../../hooks/inventory/item/use-combined-item';
import { UseCombinedClientItem } from '../../hooks/order/sales/client-item/use-client-item';
import { UseCombinedClient } from '../../hooks/order/sales/client/use-client';
import { UseCombinedSalesOrder } from '../../hooks/order/sales/order/use-sales-order';
import { ItemInterface } from '../../models/interfaces/inventory/item';
import { UserRendererInterface } from '../common/fragments/user';
import { renderClientOffer } from './client-offer';
import { renderClientProfileTabs } from './client-profile-tab';
import renderClientSalesOrderDetails from './client-sales-order';

type RenderClientV2Profile = {
  useCombinedGodown: UseCombinedGodown;
  useCombinedClient: UseCombinedClient;
  useCombinedSalesOrder: UseCombinedSalesOrder;
  useCombinedUser: UseCombinedUser;
  useCombinedItem: UseCombinedItem;
  useCombinedGrade: UseCombinedGrade;
  useCombinedClientItem: UseCombinedClientItem;
  useUserAuthorization: UseUserAuthorization;
  useCombinedPaymentTerm: UseCombinedPaymentTerm;
  useCombinedDeliveryTerm: UseCombinedDeliveryTerm;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  itemService: ItemInterface;
  userRendererService: UserRendererInterface;
};

export function renderClientV2Profile({
  useCombinedClient,
  useCombinedSalesOrder,
  useCombinedUser,
  useCombinedGrade,
  useCombinedItem,
  useCombinedClientItem,
  useUserAuthorization,
  useCombinedPaymentTerm,
  useCombinedDeliveryTerm,
  useFileTransfer,
  usePublicTransfer,
  useCombinedGodown,
  itemService,
  userRendererService,
}: RenderClientV2Profile): () => JSX.Element {
  const Card = renderCardComponent();
  const History = renderHistory();

  return function ClientV2Profile(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();

    const {
      resource: client,
      get: getClient,
      loading,
      syncSet: setClient,
    } = useCombinedClient();

    const { getAll: getGodowns, list: godowns } = useCombinedGodown();

    useEffect(() => {
      if (id) getClient(id);
    }, [id]);

    useEffect(() => {
      if (client?.id && client?.company?.id) {
        getGodowns({
          type: 'virtual',
          companyId: client?.company?.id,
          search: `Client Godown of ${client.name}`,
        });
      }
    }, [client?.id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useCombinedClient,
      id,
    });

    const header: CardHeader = {
      title: (
        <div className='flex'>
          <InlineInput name={'name'} value={client?.name || id} />
          {godowns?.length ? (
            <CloudIcon
              title='Virtual Godown Exists'
              className='w-5 h-5 inline text-indigo-900 mx-1'
            />
          ) : null}
        </div>
      ) as any,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            {client?.description || 'Add a description for the item...'}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${client?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {client?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${client?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {client?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(client?.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {/* {client.disabled === false ? ( */}
            {client?.status === 'approved' ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
          </div>
        </>
      ),
    };

    const Profile = renderClientProfileTabs();

    const SalesOrders = renderClientSalesOrderDetails({
      id,
      userRendererService,
      useCombinedSalesOrder,
    });

    const Offers = renderClientOffer();

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      // ['Overview', UnderConstruction], // renderItemProfile(itemService, id) ],
      [
        'Profile',
        () =>
          client ? (
            <Profile
              client={client}
              loading={loading}
              setClient={setClient}
              useCombinedGrade={useCombinedGrade}
              useCombinedItem={useCombinedItem}
              useCombinedClientItem={useCombinedClientItem}
              useUserAuthorization={useUserAuthorization}
              useFileTransfer={useFileTransfer}
              usePublicTransfer={usePublicTransfer}
              useCombinedPaymentTerm={useCombinedPaymentTerm}
              useCombinedDeliveryTerm={useCombinedDeliveryTerm}
              itemService={itemService}
            />
          ) : (
            <></>
          ),
      ],
      ['Sales Orders', SalesOrders],
      ['Offers', Offers],
      ['History', () => <History useResource={useCombinedClient} id={id} />],
    ]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}
