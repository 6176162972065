import { useCallback, useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MarkerType,
  Position,
  useEdgesState,
  // MiniMap,
  // Controls,
  // Background,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import customNode from './custom-node';

const nodeTypes = {
  custom: customNode,
};

export type ReactFlowNodeType = {
  id: string;
  data: {
    selects?: any;
    label: JSX.Element;
  };
  position: { x: number; y: number };
  draggable: boolean;
  sourcePosition: Position;
  targetPosition: Position;
  style: {
    background: string;
    color: string;
  };
};

export type Edges = {
  id: string;
  source: string;
  target: string;
  animated: boolean;
  markerEnd: { type: MarkerType };
};

export function OverviewFlow({
  data,
  getFlowGraph,
}: {
  data: any;
  getFlowGraph: (e: {
    data: any;
  }) => { nodes: Array<ReactFlowNodeType>; edges: Array<Edges> };
}) {
  const initialNodes = getFlowGraph({ data });
  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialNodes.edges);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    const graphData = getFlowGraph({ data });
    setNodes(graphData.nodes);
    setEdges(graphData.edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      let node = nodes.find((n) => n.type === 'custom');

      if (node && node.data && node.data.selects) {
        const edgeType = node.data.selects[edge.sourceHandle];
        edge.type = edgeType;
      }
    }

    return edge;
  });

  return (
    <ReactFlow
      nodes={nodes}
      edges={edgesWithUpdatedTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      fitView
      proOptions={{ hideAttribution: true }}
      nodeTypes={nodeTypes}
    ></ReactFlow>
  );
}
