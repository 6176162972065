import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { ChangeRequestDataType } from './types';

type EditChangeReqProps = {
  currentValue: IdName;
  onSave: (data: ChangeRequestDataType) => Promise<void>;
  useResources: string;
};

type EditPropertyIdNameChangeReqFormType = {
  currentValue: IdName;
  newValue: IdName;
  reason: string;
};

export const renderEditSearchableSelectPropertyChangeReq = ({
  useCombinedMetric,
  useCombinedGST,
  useCombinedEmployeeProfile,
  useCombinedColor,
  useCombinedOdour,
  useCombinedAppearance,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedColor: UseCombinedColor;
  useCombinedOdour: UseCombinedOdour;
  useCombinedAppearance: UseCombinedAppearance;
}) => {
  return function EditSearchableSelectPropertyChangeReq({
    currentValue,
    onSave,
    useResources,
  }: EditChangeReqProps) {
    const useSearchResource = (useResources: string) => {
      switch (useResources) {
        case 'useMetrics':
          return useCombinedMetric;
        case 'useGST':
          return useCombinedGST;
        case 'useEmployees':
          return useCombinedEmployeeProfile;
        case 'useColors':
          return useCombinedColor;
        case 'useOdours':
          return useCombinedOdour;
        case 'useAppearances':
          return useCombinedAppearance;
        default:
          return null;
      }
    };

    const searchResource = useSearchResource(useResources);
    if (!searchResource) {
      return <></>;
    }

    const formProps: FormProps<EditPropertyIdNameChangeReqFormType> = {
      fieldsData: [
        {
          property: 'currentValue',
          type: 'input',
          label: 'Current Value',
          readonly: true,
        },
        {
          property: 'newValue',
          type: 'searchable-select',
          required: true,
          label: 'New Value',
          searchOptions: {
            useSearch: searchResource,
            filter:
              searchResource === useCombinedEmployeeProfile
                ? { crossGroup: 'true' }
                : undefined,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
          required: true,
        },
      ],
      initialFormState: {
        currentValue: currentValue.name,
        newValue: { id: '', name: '' },
        reason: '',
      },
      onSubmit: async (f) => {
        await onSave({
          reason: f.reason || '',
          changeRequest: {
            currentValue: {
              value: {
                data: f.currentValue,
              },
            },
            newValue: {
              value: {
                data: f.newValue,
              },
            },
          },
        });
      },
      mapTToU(p) {
        return (p as unknown) as EditPropertyIdNameChangeReqFormType;
      },
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<EditPropertyIdNameChangeReqFormType>(formProps);

    return <Form />;
  };
};
