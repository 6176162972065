import { downloadFile } from '@erp_core/erp-ui-components';
import axios from 'axios';
import _ from 'lodash';
import { FILE_MANAGER_SERVER_URI } from '../../../../config/server-uri';

export const downloadTemplatePdf = async ({
  templateUrl,
  coaData,
  getFile,
  dynamicRenderer,
}: {
  templateUrl: string;
  coaData: any;
  getFile: (path: string) => Promise<string>;
  dynamicRenderer: { [key: string]: string };
}) => {
  let html = await getFile(templateUrl);

  console.log(html);

  _.keys(dynamicRenderer).forEach((key) => {
    html = html.replace(`#${key}`, dynamicRenderer[key]);
  });

  const axiosInstance = axios.create({
    baseURL: FILE_MANAGER_SERVER_URI,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const response = await axiosInstance.post('/pdf', {
    template: `<div style="padding: 20px;">${html}</div>`,
    data: coaData,
  });
  const fType = 'application/pdf';
  const fName = 'coa.pdf';
  const res = response.data;
  console.log('res', res);

  downloadFile({ fileType: fType, fileName: fName, result: res });
};
