import {
  OffSiteWorkHoursFilterType,
  OffSiteWorkHoursType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class OffSiteWorkHoursHttpRepo extends GenericHttpWithLogsRepo<
  OffSiteWorkHoursType,
  OffSiteWorkHoursFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/off-site-work-hours',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
