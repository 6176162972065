/* eslint-disable no-unused-vars */
import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { useEffect, useState } from 'react';
import { UserAdminInterface } from '../../../models/interfaces/admin/user-admin';

export type UseCurrentUser = () => {
  data: CurrentUserType;
  setCurrentUser: (s: CurrentUserType) => void;
};

export interface UseCurrentUserOptions {
  useUserAdminService: () => UserAdminInterface;
}

export function createUseCurrentUser({
  useUserAdminService,
}: UseCurrentUserOptions): UseCurrentUser {
  return () => {
    const [user, setUser] = useState<CurrentUserType>({} as CurrentUserType);
    const userService = useUserAdminService();

    const apiCall = async () => {
      const loc = await userService.getCurrent();
      setUser(loc);
    };

    useEffect(() => {
      apiCall();
      // eslint-disable-next-line
    }, []);

    const setCurrentUser = async (u: CurrentUserType) => {
      await userService.setCurrent(u);
      setUser(u);
    };

    return {
      data: user,
      setCurrentUser: (s: CurrentUserType) => {
        setCurrentUser(s);
      },
    };
  };
}
