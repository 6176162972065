import { GroupLeavesType } from '@erp_core/erp-types/dist/modules/hrd';
import { Popover } from '@headlessui/react';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CheckBadgeIcon,
} from '@heroicons/react/24/outline';
import { employeeLeaveTypeIconMapper } from '../../../../../../utils/common';

export function LeavesIcon({
  empLeaves,
}: {
  empLeaves: GroupLeavesType[];
}): JSX.Element {
  return (
    <>
      {empLeaves?.map((l) => (
        <div
          key={l.leave.id}
          className='flex flex-row bg-slate-50 border rounded-full p-0.5'
        >
          {employeeLeaveTypeIconMapper({ type: l.leave.type })}
          {l.leave.status.includes('pending') ? (
            <Popover className='relative' key={l.leave.id}>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <ChatBubbleOvalLeftEllipsisIcon className='w-5 h-5 text-blue-500 inline-block' />
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div className='flex flex-col justify-center'>
                      {l.leave.type} applied by {l?.leave.appliedBy?.name}
                      <span>
                        Reason:{' '}
                        {l?.leave?.reason ? l.leave.reason : 'Not specificed'}
                      </span>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          ) : (
            <Popover className='relative' key={l.leave.id}>
              {({ open }) => (
                <>
                  <Popover.Button>
                    <CheckBadgeIcon className='w-5 h-5 text-green-500 inline-block' />
                  </Popover.Button>
                  <Popover.Panel className='absolute bg-white left-1/2 z-10 mt-3 -translate-x-1/2 transform px-4'>
                    <div className='flex flex-col justify-center'>
                      {l.leave.type} approved by {l?.leave.approvedBy?.name}
                      <span>
                        Reason:{' '}
                        {l?.leave?.reason ? l.leave.reason : 'Not specificed'}
                      </span>
                    </div>
                  </Popover.Panel>
                </>
              )}
            </Popover>
          )}
        </div>
      ))}
    </>
  );
}
