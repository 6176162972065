import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { ReportsType } from 'models/types/reporting/reports';

export function renderAddReportForm() {
  return function AddReportForm({
    onSave,
  }: {
    onSave: (data: ReportsType) => Promise<void>;
  }): JSX.Element {
    const formProps: FormProps<Partial<ReportsType>> = {
      fieldsData: [
        {
          property: 'name',
          type: 'input',
          label: 'Name',
          required: true,
        },
        {
          property: 'module',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'hrd', value: 'hrd' },
            { text: 'inventory', value: 'inventory' },
            { text: 'purchase', value: 'purchase' },
            { text: 'sales', value: 'sales' },
            { text: 'accounts', value: 'accounts' },
          ],
          label: 'Module',
          required: true,
        },
      ],
      initialFormState: {
        name: '',
        module: '',
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          name: l.name,
          module: l.module,
        } as ReportsType);
      },
    };

    const Form = renderFormV2<Partial<ReportsType>>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<Partial<ReportsType>>): ReportsType {
      return (b as unknown) as ReportsType;
    }
  };
}
