import { CardError, renderCardComponent } from '@erp_core/erp-ui-components';
// import { NodeGraph } from '@erp_core/erp-ui-components';
// import { Node } from '@erp_core/erp-ui-components';
import { Node } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { renderBomChart } from '../../../components/bom-chart';
// import { UseItem } from '../../../../../hooks/inventory/item/use-item';
// import { ItemInterface } from '../../../../../models/interfaces/inventory/item';

export function renderDependencyGraph(): ({
  // id,
  nodeGraphData,
}: {
  // id: string;
  nodeGraphData: Node;
}) => JSX.Element {
  const Card = renderCardComponent();
  const BomChart = renderBomChart();

  return function DependencyGraph({
    // id,
    nodeGraphData,
  }: {
    // id: string;
    nodeGraphData: Node;
  }): JSX.Element {
    // const { data: itemData, get: getItem } = useItem();

    const [error] = useState<CardError | undefined>();
    // const [nodeGraphData, setNodeGraphData] = useState<any>();

    // useEffect(() => {
    //   itemService.getBomChart(id)
    //     .then(r => {
    //       setNodeGraphData(r);
    //     });
    // // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //   getItem(id);
    //   // eslint-disable-next-line
    // }, []);

    // useEffect(() => {
    //   if (!itemData || !itemData.category) return;

    //   ['RM', 'INT', 'FG', 'FA'].includes(itemData.category?.id || '')
    //   // cateogoryType==='FA' && mapMachinePartsNodeGraphData()
    //   // eslint-disable-next-line
    // }, [itemData]);

    return (
      <Card
        header={{
          title: 'BOM Chart',
        }}
        error={error}
        body={{
          type: 'jsx-component',
          body: (
            <>
              {/* <NodeGraph data={nodeGraphData} /> */}
              <BomChart node={nodeGraphData} />
            </>
          ),
        }}
      />
    );
  };
}
