import {
  GetSkipRepaymentReq,
  PostActionRepaymentResp,
  RepaymentScheduleInterface,
} from 'models/interfaces/loans/repayment-schedule';
import { useState } from 'react';

export type UseSkipRepaymentData = () => {
  setSkipRepaymentData: (p: GetSkipRepaymentReq) => void;
  res: PostActionRepaymentResp;
};

export interface UseSkipRepaymentOptions {
  useSkipRepaymentService: () => RepaymentScheduleInterface;
}

export function createUseSkipRepaymentValue({
  useSkipRepaymentService,
}: UseSkipRepaymentOptions): UseSkipRepaymentData {
  return () => {
    const repaymentScheduleService = useSkipRepaymentService();
    const [res, setData] = useState<any>();

    const skipRepaymentApiCall = async (p: GetSkipRepaymentReq) => {
      try {
        const resp = await repaymentScheduleService.skipRepayment(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      setSkipRepaymentData: (p: GetSkipRepaymentReq) => {
        skipRepaymentApiCall(p);
      },
      res,
    };
  };
}
