import {
  ExclamationCircleIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';

export type PaymentSchedule = {
  event: string;
  amount: number;
};

export const PEPaymentSchedule = ({
  total = 100,
  setPaymentSchedule,
  paymentSchedule,
  closeModal,
}: {
  total?: number;
  setPaymentSchedule: React.Dispatch<React.SetStateAction<PaymentSchedule[]>>;
  paymentSchedule: PaymentSchedule[];
  closeModal: () => void;
}): JSX.Element => {
  const [schedule, setSchedule] = useState(paymentSchedule);

  const totalScheduled = schedule.reduce((p, c) => p + c.amount, 0);

  return (
    <div>
      <div>
        <div>
          <div className='flex justify-between'>
            <div className='p-1 border font-bolder'>Total : {total} % </div>
            <div className='p-1 border font-bolder'>
              Total scheduled amount: {totalScheduled} %
            </div>
            <div className='p-1 border font-bolder'>
              Total un-scheduled: {total - totalScheduled} %
            </div>
          </div>
          <div className='text-center'>
            {total === totalScheduled ? (
              <div className='text-green-500'>Scheduled all total.</div>
            ) : (
              <div className='text-red-500'>
                Please schedule payment completely. {total - totalScheduled} %
                remaining.
              </div>
            )}
          </div>
        </div>
      </div>
      <form
        onSubmit={() => {
          setPaymentSchedule(schedule.filter((f) => f.amount !== 0));
          closeModal();
        }}
      >
        <div className='m-4 flex flex-wrap'>
          {schedule.map((s, index) => {
            return (
              <div key={index} className='basis-1/3 p-2'>
                <div className='p-2 flex border hover:border-black justify-center items-center rounded'>
                  <div className='flex-1 mr-2'>
                    <div className='w-full'>
                      <div>Event</div>
                      <input
                        required
                        className='border p-2 w-full'
                        value={s.event}
                        onChange={(e) => {
                          setSchedule((t) => {
                            t[index] = { ...s, event: e.target.value };
                            return [...t];
                          });
                        }}
                      />
                    </div>
                    <div className='w-full'>
                      <div>Amount in %</div>
                      <div>
                        <input
                          required
                          className='border p-2 w-full'
                          type='number'
                          value={s.amount}
                          placeholder={(total - totalScheduled).toString()}
                          onChange={(e) => {
                            setSchedule((t) => {
                              t[index] = {
                                ...s,
                                amount: Number(e.target.value),
                              };
                              return [...t];
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <TrashIcon
                      className='w-8 h-8 fort-bold cursor-pointer hover:text-red-600'
                      onClick={() => {
                        setSchedule((rr) => {
                          return [
                            ...rr.slice(0, index),
                            ...rr.slice(index + 1, rr.length),
                          ];
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div>
          <FormBottom
            schedule={schedule}
            setSchedule={setSchedule}
            totalQuantity={total}
            totalScheduled={totalScheduled}
          />
        </div>
      </form>
    </div>
  );
};

const FormBottom = ({
  schedule,
  setSchedule,
  totalQuantity,
  totalScheduled,
}) => {
  if (totalScheduled === totalQuantity)
    return (
      <div className=''>
        <button
          className='bg-blue-500 float-right hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
          type='submit'
        >
          Save Schedule
        </button>
      </div>
    );

  if (totalScheduled < totalQuantity)
    return (
      <div className='flex justify-between'>
        <PlusIcon
          className='w-6 h-6 border cursor-pointer'
          onClick={() => {
            setSchedule([...schedule, { event: '', amount: 0 }]);
          }}
        />
        <button
          className='bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed'
          disabled
        >
          Save Schedule
        </button>
      </div>
    );

  return (
    <div>
      <div className='flex items-center text-red-600'>
        <ExclamationCircleIcon className='w-5 h-5' />
        Invalid Selection
      </div>
    </div>
  );
};
