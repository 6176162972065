/* eslint-disable no-unused-vars */
import { renderFormV2 } from '@erp_core/erp-ui-components';
import { useState } from 'react';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { ChangeAssesmentType } from '../../../../models/types/quality-assurance/change-assesment';

type Props = {
  data: {};
  onSave: any;
  changeAssesmentData: ChangeAssesmentType;
};

// eslint-disable-next-line
export function renderAddStorgedCopiesForm({
  useCombinedEmployeeProfile,
}: {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}): (props: Props) => JSX.Element {
  return function AddAddStorgedCopiesForm({
    data,
    onSave,
    changeAssesmentData,
  }: Props): JSX.Element {
    const newDocNums = changeAssesmentData?.details.implementationActivitiesDocs.documents.map(
      (x) => x
    );
    const Form = renderFormV2({
      mapTToU: (f) => f,
      initialFormState: {
        docname: '',
        docno: '',
        docversion: '',
        storageloc: '',
        storedby: '',
        verifiedby: '',
      },
      fieldsData: [
        {
          property: 'docname',
          label: 'Name (Document)',
          type: 'searchable-select',
          searchOptions: {
            useSearch: () => {
              const newDocs = newDocNums.map((x) => ({
                ...x,
                id: x.list,
              }));
              const [filtered, setFiltered] = useState<{ list: string }[]>(
                newDocs
              );

              return {
                data: filtered,
                getAll: (filter) => {
                  if (filter.search) {
                    setFiltered(
                      newDocs.filter((x) =>
                        x.list
                          .toLowerCase()
                          .includes((filter.search || '').toLowerCase())
                      )
                    );
                  } else {
                    setFiltered(newDocs);
                  }
                },
              };
            },
            onSearchValueSelect: (e) => {
              // console.log(e);
            },
          },
        },
        {
          property: 'docno',
          label: 'Number (Document)',
          type: 'searchable-select',
          searchOptions: {
            useSearch: () => {
              const newDocNo = newDocNums.map((x) => ({
                ...x,
                id: x.numberNewDoc,
              }));
              const [filtered, setFiltered] = useState<
                { numberNewDoc: string }[]
              >(newDocNo);
              return {
                data: filtered,
                getAll: (filter) => {
                  if (filter.search) {
                    setFiltered(
                      newDocNo.filter((x) =>
                        x.numberNewDoc
                          .toLowerCase()
                          .includes((filter.search || '').toLowerCase())
                      )
                    );
                  } else {
                    setFiltered(newDocNo);
                  }
                },
              };
            },
            onSearchValueSelect: (e) => {
              // console.log(e);
            },
          },
        },
        {
          property: 'docversion',
          label: 'Version (Document)',
          type: 'searchable-select',
          searchOptions: {
            useSearch: () => {
              const newVersion = newDocNums.map((x) => ({
                ...x,
                id: x.versionNewDoc,
              }));

              const [filtered, setFiltered] = useState<
                { versionNewDoc: string }[]
              >(newVersion);

              return {
                data: filtered,
                getAll: (filter) => {
                  if (filter.search) {
                    setFiltered(
                      newVersion.filter((x) =>
                        x.versionNewDoc.includes(filter.search || '')
                      )
                    );
                  } else {
                    setFiltered(newVersion);
                  }
                },
              };
            },
            onSearchValueSelect: (e) => {
              //   console.log('abc', e);
            },
          },
        },
        {
          property: 'storageloc',
          label: 'Storage location',
          type: 'input',
        },
        {
          property: 'storedby',
          label: 'Stored by',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
        },
        {
          property: 'verifiedby',
          label: 'Verified by',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect: (v) => {
              console.log(v);
            },
          },
        },
      ],
      onSubmit: async (form) => {
        onSave({
          softcopies: form,
        });
      },
    });

    return <Form />;
  };
}
