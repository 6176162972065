import { Location } from '@erp_core/erp-icons/icons/web/location';
import {
  Company,
  CompanyGroup,
  LocationType,
} from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { CloudIcon, MapPinIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { UseCombinedCompanyAdmin } from '../../../hooks/admin/company-admin/use-company-admin';
import { UseCombinedLocationAdmin } from '../../../hooks/admin/location-admin/use-location';
import { renderEditLocationForm } from './edit-location-form';

export type RenderLocationAdminProps = {
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
};

export function renderLocationAdmin({
  useCombinedLocationAdmin,
  useCombinedCompanyAdmin,
}: RenderLocationAdminProps): ({
  companyId,
}: {
  companyId?: string;
}) => JSX.Element {
  return function LocationAdmin({
    companyId,
  }: {
    companyId?: string;
  }): JSX.Element {
    const {
      list: companies,
      getAll: getAllCompanies,
    } = useCombinedCompanyAdmin();
    const { list: data, getAll: getLocations } = useCombinedLocationAdmin();
    const [selectedCompany, setSelectedCompany] = useState<string>(
      companyId || 'all'
    );

    useEffect(() => {
      getLocations({ companyId: selectedCompany, type: 'real' });
      getAllCompanies();
      // eslint-disable-next-line
    }, []);

    const EditLocationForm = renderEditLocationForm({
      useCombinedLocationAdmin,
      companyId: selectedCompany,
    });

    // const { delete: deleteLocationAdmin } = useLocationAdmin();

    useEffect(() => {
      const filters =
        selectedCompany === 'all' ? {} : { companyId: selectedCompany };
      getLocations(filters);
      // eslint-disable-next-line
    }, [selectedCompany]);

    const onCompanyChange = (event) => {
      getLocations({ companyId: event.target.value });
      setSelectedCompany(event.target.value);
    };

    const hideAddButton = selectedCompany === 'all' ? 'hidden' : '';

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      icon: (
        <Location className='w-8 h-8 fill-indigo-900 stroke-none self-center' />
      ),
      title: 'Location Admin Screen',
      actions: [
        {
          type: 'jsx',
          jsx: (
            <div className='inline-block float-right border-2 bg-white rounded-2'>
              {!companyId ? (
                <select
                  value={selectedCompany}
                  className='w-28 h-8 bg-white hover:cursor-pointer'
                  onChange={onCompanyChange}
                >
                  <option value='all'>All</option>
                  {companies?.map((mod, idx) => (
                    <option value={mod.id} key={idx}>
                      {mod.name}
                    </option>
                  ))}
                </select>
              ) : null}
            </div>
          ),
        },
        {
          type: 'button',
          button: {
            name: 'Add Location',
            style: `${hideAddButton} p-1 text-black flex justify-center items-center border-2 w-36 rounded shadow bg-green-500 hover:cursor-pointer hover:bg-green-400`,
            behaviour: 'modal',
            modal: {
              title: 'Add Location',
              content: ({ onClose }) => {
                const al: Partial<LocationType> = {
                  id: '',
                  company: { id: selectedCompany, name: '' } as Company,
                  companyGroup: { id: '', name: '' } as CompanyGroup,
                  description: '',
                  name: '',
                  shortName: '',
                };

                return <EditLocationForm r={al} onClose={onClose} />;
              },
            },
          },
        },
      ],
    };

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Company' },
        { name: 'Short Name' },
        { name: 'Type' },
      ],
    ];

    const tableBody: TableBody = data
      ? data.map((r) => ({
          rowData: { location: r },
          cells: [
            {
              value: r.name,
              link: `/system/admin/location/profile/${r.id}/location-profile`,
            },
            {
              value: companies?.find((m) => m.id === r.company.id)?.name || '',
            },
            {
              value: r.shortName,
            },
            {
              value: (
                <>
                  {r.type === 'virtual' ? (
                    <CloudIcon className='inline w-5 h-5 text-green-900' />
                  ) : (
                    <MapPinIcon className='inline w-5 h-5 text-indigo-900' />
                  )}
                  {r.type}
                </>
              ),
            },
          ],
        }))
      : [];

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full'>
            <Table
              header={tableHeader}
              body={tableBody}
              actions={[
                {
                  name: 'Edit',
                  show: ({ location }) => location.type === 'real',
                  behaviour: 'modal',
                  modal: {
                    title: 'Edit',
                    content: ({ data, onClose }) => (
                      <EditLocationForm r={data.location} onClose={onClose} />
                    ),
                  },
                },
                // {
                //   name: 'Delete',
                //   show: () => true,
                //   onClick: async ({ location }) => {
                //     openConfirm({
                //       title: `Are you sure you want to delete this Location ${location.name}? This cannot be undone.`,
                //       type: 'warning',
                //       onConfirm: async () => {
                //         await deleteLocationAdmin(location.id);
                //         getLocations({ companyId: selectedCompany });
                //       },
                //     });
                //   },
                // },
              ]}
            />
          </div>
        </div>
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}
