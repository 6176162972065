import { Account } from '@erp_core/erp-icons/icons/web/account';
import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { UseCombinedActionAdmin } from '../../hooks/admin/action-admin/use-action-admin';
import { UseCombinedAuthorization } from '../../hooks/admin/authorization/use-authorization';
import { UseCombinedCompanyAdmin } from '../../hooks/admin/company-admin/use-company-admin';
import { UseCombinedCompanyGroupAdmin } from '../../hooks/admin/company-group-admin/use-company-group-admin';
import { UseCombinedLocationAdmin } from '../../hooks/admin/location-admin/use-location';
import { UseCombinedModuleAdmin } from '../../hooks/admin/module-admin/use-modules-admin';
import { UseCombinedNotificationAction } from '../../hooks/admin/notification-admin/use-authorizations';
import { UseCombinedRole } from '../../hooks/admin/role-admin/use-roles-admin';
import { UseCombinedResourcesAdmin } from '../../hooks/admin/use-resource-admin/use-resources-admin';
import { UseCombinedUser } from '../../hooks/admin/user-admin/use-users-admin';
import { UseFileTransfer } from '../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../hooks/file-transfer/use-public-transfer';
import { UseCombinedNotificationConfigurator } from '../../hooks/notification/configurator/use-notification-configurator';
import { renderActionAdmin } from './action-admin';
import { renderAuthorizationAdmin } from './authorization-admin';
import { renderCompanyAdmin } from './company-admin';
import { renderCompanyGroupAdmin } from './company-group-admin';
import { renderLocationAdmin } from './location-admin';
import { renderModuleAdmin } from './module-admin';
import { renderNotificationAction } from './notification-admin';
import { renderNotificationV2Action } from './notification-v2-admin';
import { renderResourceAdmin } from './resource-admin';
import { renderRoleAdmin } from './role-admin';
import { renderUserAdminV2 } from './user-admin-v2';

type RenderAdminMastersProps = {
  useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
  useCombinedCompanyGroupAdmin: UseCombinedCompanyGroupAdmin;
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedModuleAdmin: UseCombinedModuleAdmin;
  useCombinedActionAdmin: UseCombinedActionAdmin;
  useCombinedUser: UseCombinedUser;
  useCombinedNotificationAction: UseCombinedNotificationAction;
  useCombinedResourcesAdmin: UseCombinedResourcesAdmin;
  useCombinedAuthorization: UseCombinedAuthorization;
  useCombinedRole: UseCombinedRole;
  useCombinedNotificationConfigurator: UseCombinedNotificationConfigurator;
};

export function renderAdminMasters({
  useCombinedCompanyAdmin,
  useFileTransfer,
  usePublicTransfer,
  useCombinedCompanyGroupAdmin,
  useCombinedLocationAdmin,
  useCombinedModuleAdmin,
  useCombinedActionAdmin,
  useCombinedNotificationAction,
  useCombinedUser,
  useCombinedResourcesAdmin,
  useCombinedAuthorization,
  useCombinedRole,
  useCombinedNotificationConfigurator,
}: RenderAdminMastersProps): () => JSX.Element {
  return function AdminMasters(): JSX.Element {
    const NewTabs = renderTabsV2Component();

    const CompanyGroupAdmin = renderCompanyGroupAdmin({
      useCombinedCompanyGroupAdmin,
    });

    const CompanyAdmin = renderCompanyAdmin({
      useCombinedCompanyAdmin,
      useFileTransfer,
      usePublicTransfer,
    });
    const LocationAdmin = renderLocationAdmin({
      useCombinedLocationAdmin,
      useCombinedCompanyAdmin,
    });

    const ModuleAdmin = renderModuleAdmin({
      useCombinedModuleAdmin,
    });

    const ResourceAdmin = renderResourceAdmin({
      useCombinedModuleAdmin,
      useCombinedResourcesAdmin,
    });

    const AuthorizationAdmin = renderAuthorizationAdmin({
      useCombinedResourcesAdmin,
      useCombinedActionAdmin,
      useCombinedModuleAdmin,
      useCombinedAuthorization,
    });

    const ActionAdmin = renderActionAdmin({
      useCombinedActionAdmin,
    });

    const RoleAdmin = renderRoleAdmin({
      useCombinedLocationAdmin,
      useCombinedAuthorization,
      useCombinedActionAdmin,
      useCombinedNotificationAction,
      useCombinedRole,
    });

    const UserAdminV2 = renderUserAdminV2({
      useCombinedUser,
      useCombinedRole,
    });

    const NotificationAction = renderNotificationAction({
      useCombinedNotificationAction,
      useCombinedRole,
    });

    const NotificationActionV2 = renderNotificationV2Action({
      useCombinedNotificationConfigurator,
      useCombinedRole,
      useCombinedAuthorization,
      useCombinedUser,
    });

    const newCategories: DataArray = [
      { name: 'group', Fn: CompanyGroupAdmin },
      { name: 'company', Fn: CompanyAdmin },
      { name: 'location', Fn: () => <LocationAdmin /> },
      { name: 'module', Fn: ModuleAdmin },
      { name: 'resource', Fn: ResourceAdmin },
      { name: 'action', Fn: ActionAdmin },
      { name: 'authorization', Fn: AuthorizationAdmin },
      { name: 'role', Fn: RoleAdmin },
      { name: 'user', Fn: UserAdminV2 },
      { name: 'notification', Fn: NotificationAction },
      { name: 'notification-v2', Fn: NotificationActionV2 },
    ];

    const Card = renderCardComponent();
    const header: CardHeader = {
      icon: (
        <Account className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
      ),
      title: 'Admin Masters',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
