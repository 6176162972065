import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { UseCombinedEmployeeCategory } from '../../../../../hooks/admin/constants/employee-category/use-employee-category';
import { renderLeaveRuleForm } from './form';

export type LeaveRulesType = {
  basicInformation: {
    leaveName?: string;
    leaveType?: string;
    dependOnGrade?: string;
    grades?: Array<{ id: string; name: string }>;
    dependOnGender?: string;
    gender?: string;
    allowedDuringProbation?: string;
  };
  availmentRules: {
    availType?: string;
    maxLeavesAllotment?: string;
    document?: string;
    noticePeriod?: string;
  };
  redemptionRules: {
    encashmentAllowed?: string;
    leavesRedeemed?: string;
  };
  expirationRules: {
    allowCarryForward?: string;
    leavesExpire?: string;
  };
};

type RenderLeaveRulesProps = {
  useCombinedEmployeeCategory: UseCombinedEmployeeCategory;
};

export function renderLeaveRules({
  useCombinedEmployeeCategory,
}: RenderLeaveRulesProps) {
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return function LeaveRules({
    comGrpSet,
    saveEditLeaveRules,
    saveAddLeaveRules,
  }: {
    comGrpSet: CompanyGroupSetting;
    saveEditLeaveRules: (formData: any, index: number) => Promise<void>;
    saveAddLeaveRules: (formData: any) => Promise<void>;
  }): JSX.Element {
    const LeaveRuleForm = renderLeaveRuleForm({ useCombinedEmployeeCategory });

    const leaveRules =
      (comGrpSet.details.hrd?.leaveRules as LeaveRulesType[]) || [];

    const tableBody: TableBody =
      leaveRules.map((leaveRule, index) => {
        return {
          rowData: { leaveRule, idx: index },
          cells: [
            {
              value: (
                <>
                  <b>Name:</b> {leaveRule?.basicInformation?.leaveName || '-'}
                  <br></br>
                  <b>Type:</b> {leaveRule?.basicInformation?.leaveType || ''}
                </>
              ),
            },
            {
              value: (
                <>
                  <b>Grades:</b>
                  {leaveRule?.basicInformation?.grades?.map(
                    (grade) => grade.name || '-'
                  )}
                  <br></br>
                  <b>Gender:</b> {leaveRule?.basicInformation?.gender || '-'}
                  <br></br>
                  <b>Allowed during probation?:</b>{' '}
                  {leaveRule?.basicInformation?.allowedDuringProbation || '-'}
                </>
              ),
            },
            {
              value: (
                <>
                  <b>Availability: </b>
                  {leaveRule?.availmentRules?.availType || '-'}
                  <br></br>
                  <b>Max Leaves Alloted: </b>
                  {leaveRule?.availmentRules?.maxLeavesAllotment || '-'}
                  <br></br>
                  <b>Document required: </b>
                  {leaveRule?.availmentRules?.document || '-'}
                  <br></br>
                  <b>Notice period days: </b>
                  {leaveRule?.availmentRules?.noticePeriod || '-'}
                </>
              ),
            },
            {
              value: (
                <>
                  <b>How many leaves can be redeemed?: </b>
                  {leaveRule?.redemptionRules?.leavesRedeemed || '-'}
                  <br></br>
                  <b>Encashment allowed: </b>
                  {leaveRule?.redemptionRules?.encashmentAllowed || '-'}
                </>
              ),
            },
            {
              value: (
                <>
                  <b>How many leaves carry forwarded?: </b>
                  {leaveRule?.expirationRules?.allowCarryForward || '-'}
                  <br></br>
                  <b>When will leaves expire: </b>
                  {leaveRule?.expirationRules?.leavesExpire || '-'}
                </>
              ),
            },
          ],
        };
      }) || [];

    const header: CardHeader = {
      title: 'Leave Rules',
      actions: [
        {
          type: 'button',
          button: {
            name: 'Add',
            behaviour: 'modal',
            modal: {
              title: 'Add Leave Rule',
              content: ({ onClose }) => {
                const data: LeaveRulesType = {
                  basicInformation: {
                    leaveName: '',
                    leaveType: '',
                    dependOnGrade: '',
                    grades: [],
                    dependOnGender: '',
                    gender: '',
                    allowedDuringProbation: '',
                  },
                  availmentRules: {
                    availType: '',
                    maxLeavesAllotment: '',
                    document: '',
                    noticePeriod: '',
                  },
                  redemptionRules: {
                    encashmentAllowed: '',
                    leavesRedeemed: '',
                  },
                  expirationRules: {
                    allowCarryForward: '',
                    leavesExpire: '',
                  },
                };

                return (
                  <LeaveRuleForm
                    data={data}
                    onSave={async (form) => {
                      saveAddLeaveRules(form);
                      onClose();
                    }}
                  />
                );
              },
            },
          },
        },
      ],
    };

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <Table
            header={[
              [
                { name: 'Name & Type' },
                { name: 'Leave Rules' },
                { name: 'Availment Rules' },
                { name: 'Redemption Rules' },
                { name: 'Expiration Rules' },
              ],
            ]}
            body={tableBody}
            actions={[
              {
                name: 'Edit',
                show: () => true,
                behaviour: 'modal',
                modal: {
                  title: 'Edit',
                  content: ({ data, onClose }) => {
                    return (
                      <LeaveRuleForm
                        data={data.leaveRule}
                        onSave={async (d) => {
                          saveEditLeaveRules(d, data.index);
                          onClose();
                        }}
                      />
                    );
                  },
                },
              },
            ]}
          />
        </>
      ),
    };

    return (
      <div>
        <Card header={header} body={body} />
      </div>
    );
  };
}
