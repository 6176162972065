import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  LoadingButton,
  renderCardComponent,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { GRNVoucher } from './purchase-order';

export function EditGRNDetails({
  grn,
  saveGRN,
}: {
  grn: GRNVoucher;
  saveGRN: (details: {
    name: string;
    date: string;
    invoiceNumber: string;
    totalAmountPayable: number;
    dispatchThrough: string;
  }) => Promise<void>;
}) {
  const Form = renderFormV2({
    initialFormState: {
      name: grn?.details?.name || '',
      date: grn?.details?.date || '',
      invoiceNumber: grn?.details?.invoiceNumber || '',
      totalAmountPayable: grn?.details?.totalAmountPayable || 0,
      dispatchThrough: grn?.details?.dispatchThrough || '',
    },
    fieldsData: [
      { property: 'name', type: 'input', required: true },
      { property: 'date', type: 'date', required: true },
      { property: 'invoiceNumber', type: 'input', required: true },
      { property: 'totalAmountPayable', type: 'number', required: true },
      { property: 'dispatchThrough', type: 'input' },
    ],
    mapTToU: (x) => x,
    onSubmit: async (form) => {
      const finalData = {
        name: (form.name as string) || grn.details?.name,
        date: (form.date as string) || grn?.details?.date,
        invoiceNumber:
          (form.invoiceNumber as string) || grn?.details?.invoiceNumber,
        totalAmountPayable: form.totalAmountPayable
          ? parseFloat(`${form.totalAmountPayable}`)
          : grn?.details?.totalAmountPayable,
        dispatchThrough:
          (form.dispatchThrough as string) || grn?.details?.dispatchThrough,
      };

      await saveGRN(finalData);
    },
  });

  return <Form />;
}

export function EditGRNItems({
  poItems,
  grn,
  saveGrnItems,
}: {
  poItems: Array<IdName>;
  grn: GRNVoucher;
  saveGrnItems: (
    x: Array<{
      grade: IdName;
      batchNumber: number;
      quantity: number;
      rate: number;
      amount: number;
      reason: string;
    }>
  ) => Promise<void>;
}) {
  const [items, setItems] = useState<
    Array<{
      grade: IdName;
      batchNumber: number;
      quantity: number;
      rate: number;
      amount: number;
      reason: string;
    }>
  >(grn.items || []);
  const Card = renderCardComponent();
  const Table = renderTableComponent();
  const [poItemsRemarks, setPoItemRemarks] = useState<
    Array<{
      grade: IdName;
      batchNumber: number;
      quantity: number;
      rate: number;
    }>
  >([]);

  useEffect(() => {
    const poRemarks: Array<{
      grade: IdName;
      batchNumber: number;
      quantity: number;
      rate: number;
    }> = [];

    items.forEach((x) => {
      if (x.grade?.id && x.batchNumber) {
        const item = grn.items?.find(
          (y) => y.grade?.id === x.grade?.id && x.batchNumber === y.batchNumber
        );
        if (item) {
          poRemarks.push({
            grade: item.grade,
            batchNumber: item.batchNumber,
            quantity: item.quantity,
            rate: item.rate,
          });
        }
      }
    });
    setPoItemRemarks(poRemarks);
  }, [items, grn.items]);

  return (
    <div>
      <Card
        header={{
          title: 'Item Details',
          subheading: (
            <>
              {poItemsRemarks.map((x, idx) => (
                <div key={idx}>
                  {x.grade.name} Batch {x.batchNumber}: Rate {x.rate}: Quantity{' '}
                  {x.quantity}
                </div>
              ))}
            </>
          ),
          actions: [
            {
              type: 'button',
              button: {
                name: 'Add',
                behaviour: 'click',
                onClick: async () => {
                  const newItems = [...items];
                  newItems.push({
                    grade: { id: '', name: '' },
                    batchNumber: 0,
                    quantity: 0,
                    rate: 0,
                    amount: 0,
                    reason: '',
                  });
                  setItems(newItems);
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Grade' },
                    { name: 'Batch #' },
                    { name: 'Quantity' },
                    { name: 'Rate' },
                    { name: 'Amount' },
                    { name: 'Reason' },
                  ],
                ]}
                body={items.map((x, idx) => {
                  return {
                    cells: [
                      {
                        value: (
                          <select
                            onChange={(evt) => {
                              const newItems = [...items];
                              const poItem = poItems.find(
                                (t) => t.id === evt.target.value
                              );
                              if (poItem) {
                                newItems[idx].grade = {
                                  id: poItem.id,
                                  name: poItem.name,
                                };
                              }
                              setItems(newItems);
                            }}
                            value={x.grade.id}
                          >
                            <option value=''>Select</option>
                            {poItems.map((y) => (
                              <option key={y.id} value={y.id}>
                                {y.name}
                              </option>
                            ))}
                          </select>
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.batchNumber}
                            type='number'
                            onBlur={(evt) => {
                              const newItems = [...items];
                              newItems[idx].batchNumber = parseInt(
                                evt.target.value
                              );
                              setItems(newItems);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.quantity}
                            type='number'
                            onBlur={(evt) => {
                              const newItems = [...items];
                              newItems[idx].quantity = parseFloat(
                                evt.target.value
                              );
                              setItems(newItems);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.rate}
                            type='number'
                            onBlur={(evt) => {
                              const newItems = [...items];
                              newItems[idx].rate = parseFloat(evt.target.value);
                              setItems(newItems);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.amount}
                            type='number'
                            onBlur={(evt) => {
                              const newItems = [...items];
                              newItems[idx].amount = parseFloat(
                                evt.target.value
                              );
                              setItems(newItems);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.reason}
                            onBlur={(evt) => {
                              const newItems = [...items];
                              newItems[idx].reason = evt.target.value;
                              setItems(newItems);
                            }}
                          />
                        ),
                      },
                    ],
                  };
                })}
              />
              <LoadingButton
                text={'Save'}
                behaviorFn={async () => {
                  await saveGrnItems(items);
                }}
              />
            </div>
          ),
        }}
      />
    </div>
  );
}

export function EditGRNTransport({
  grn,
  saveGrnTransport,
}: {
  grn: GRNVoucher;
  saveGrnTransport: (
    x: Array<{
      name: string;
      type: 'BL' | 'E-WAY-Bill' | 'AWB' | 'LR' | 'Courier Docket' | 'GIRIR';
      documentNumber: string;
      vehicleNumber: string;
      amount: number;
      date: string;
      remarks: string;
      attachment: string;
    }>
  ) => Promise<void>;
}) {
  const [transports, setTransports] = useState<
    Array<{
      name: string;
      type: 'BL' | 'E-WAY-Bill' | 'AWB' | 'LR' | 'Courier Docket' | 'GIRIR';
      documentNumber: string;
      vehicleNumber: string;
      amount: number;
      date: string;
      remarks: string;
      attachment: string;
    }>
  >(grn.transport || []);
  const Card = renderCardComponent();
  const Table = renderTableComponent();

  return (
    <div>
      <Card
        header={{
          title: 'Transport Details',
          actions: [
            {
              type: 'button',
              button: {
                name: 'Add',
                behaviour: 'click',
                onClick: async () => {
                  const newTransports = [...transports];
                  newTransports.push({
                    name: '',
                    type: '',
                    documentNumber: '',
                    vehicleNumber: '',
                    amount: 0,
                    date: '',
                    remarks: '',
                    attachment: '',
                  } as any);
                  setTransports(newTransports);
                },
              },
            },
          ],
        }}
        body={{
          type: 'jsx-component',
          body: (
            <div>
              <Table
                header={[
                  [
                    { name: 'Name' },
                    { name: 'Type' },
                    { name: 'Document #' },
                    { name: 'Vehicle #' },
                    { name: 'Amount' },
                    { name: 'Date' },
                    { name: 'Remarks' },
                  ],
                ]}
                body={transports.map((x, idx) => {
                  return {
                    cells: [
                      {
                        value: (
                          <input
                            defaultValue={x.name}
                            onBlur={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].name = evt.target.value;
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <select
                            onChange={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].type = evt.target.value as any;
                              setTransports(newTransports);
                            }}
                            value={x.type}
                          >
                            <option value=''>Select</option>
                            <option value='BL'>BL</option>
                            <option value='E-WAY-Bill'>E-WAY-Bill</option>
                            <option value='AWB'>AWB</option>
                            <option value='LR'>LR</option>
                            <option value='Courier Docket'>
                              Courier Docket
                            </option>
                            <option value='GIRIR'>GIRIR</option>
                          </select>
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.documentNumber}
                            onBlur={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].documentNumber =
                                evt.target.value;
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.vehicleNumber}
                            onBlur={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].vehicleNumber =
                                evt.target.value;
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.amount}
                            type='number'
                            onBlur={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].amount = parseFloat(
                                evt.target.value
                              );
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            value={x.date}
                            type='date'
                            onChange={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].date = evt.target.value;
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                      {
                        value: (
                          <input
                            defaultValue={x.remarks}
                            onBlur={(evt) => {
                              const newTransports = [...transports];
                              newTransports[idx].remarks = evt.target.value;
                              setTransports(newTransports);
                            }}
                          />
                        ),
                      },
                    ],
                  };
                })}
              />
              <LoadingButton
                text={'Save'}
                behaviorFn={async () => {
                  await saveGrnTransport(transports);
                }}
              />
            </div>
          ),
        }}
      />
    </div>
  );
}
