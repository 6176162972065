import { CurrentUserType } from '@erp_core/erp-types/dist/modules/admin';
import { BatchItemGodownInterface } from '../../../../models/interfaces/inventory/item/batch/batch-item-godown';

export type AllocateData = {
  id: string;
  referenceId: string;
  referenceType: 'bom' | 'sales-enquiry' | 'sales-order';
  quantity: number;
  currentUser: CurrentUserType;
  allocationComplete: boolean; // Send this true when no further allocation should be made for the targeted reference type.
};

export type UseBatchItemAllocate = () => {
  // eslint-disable-next-line no-unused-vars
  set(data: AllocateData): Promise<boolean>;
};

type CreateUseBatchItemAllocate = {
  batchItemGodownService: BatchItemGodownInterface;
};

export function createUseBatchItemAllocate({
  batchItemGodownService,
}: CreateUseBatchItemAllocate): UseBatchItemAllocate {
  return () => {
    const set = async (data: AllocateData) => {
      return batchItemGodownService.allocateItem(data);
    };
    return {
      set,
    };
  };
}
