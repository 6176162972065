/* eslint-disable no-unused-vars */
// file contains error

import { CheckCircleFilled } from '@erp_core/erp-icons/icons/web/check-circle-filled';
import { Clear } from '@erp_core/erp-icons/icons/web/clear';
import { TimeOut } from '@erp_core/erp-icons/icons/web/time-out';
import {
  CardBody,
  CardHeader,
  PaginatedData,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableBodyRow,
  TableCell,
  TableHeader,
  ValidPageSize,
} from '@erp_core/erp-ui-components';
import { UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedJob } from '../../../../hooks/admin/scheduler-job/use-job';
import { UserRendererInterface } from '../../../../modules/common/fragments/user';

export type RenderSchedulerJob = {
  useCombinedJob: UseCombinedJob;
  userRendererService: UserRendererInterface;
};

export function renderSchedulerJob({
  useCombinedJob,
  userRendererService,
}: RenderSchedulerJob): () => JSX.Element {
  return function SchedulerJob(): JSX.Element {
    const { id } = useParams();
    const [pageState, setPageState] = useState<{
      pageNumber: number;
      pageSize: ValidPageSize;
    }>({ pageNumber: 1, pageSize: 10 });

    const {
      paginatedList: paginatedLogs,
      getWithPagination,
    } = useCombinedJob();

    useEffect(() => {
      getWithPagination({
        taskId: id,
        ...pageState,
        sortBy: 'dateTime',
        order: 'desc',
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      getWithPagination({
        taskId: id,
        ...pageState,
        sortBy: 'dateTime',
        order: 'desc',
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState]);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Name' },
        { name: 'Resource' },
        { name: 'Status' },
        { name: 'Date' },
        { name: 'Last Updated at' },
        {
          name: (
            <>
              <UserIcon className='inline w-5 h-5' />
              Created By
            </>
          ),
        },
      ],
    ];

    if (!paginatedLogs?.data) return <div>LOADING</div>;

    const res: TableBodyRow[] =
      paginatedLogs?.data.map((l) => {
        const cells: Array<TableCell> = [
          { value: l.name },
          { value: l.resource },
          {
            value:
              l.executed === 'success' ? (
                <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                  <CheckCircleFilled className='inline mr-1 w-4 text-green-600' />
                  <span className='capitalize'>{l.executed || ''}</span>
                </span>
              ) : l.executed === 'pending' ? (
                <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                  <Clear className='inline mr-1 w-4 text-red-600' />
                  <span className='capitalize'>{l.executed || ''}</span>
                </span>
              ) : (
                <span className='border border-yellow-600 rounded-md px-2 py-1 text-sm text-yellow-600'>
                  <TimeOut className='inline mr-1 w-4 text-yellow-600' />
                  <span className='capitalize'>{l.executed || ''}</span>
                </span>
              ),
          },

          { value: moment(l.dateTime).calendar() },
          { value: moment(l.lastModifiedAt).fromNow() },
          {
            value: (
              <userRendererService.userCard
                size='small'
                id={l.createdBy?.id}
                name={l.createdBy?.name}
                extraInfo={moment.utc(l.createdAt).fromNow()}
                link={true}
              />
            ),
          },
        ];

        return {
          cells,
        };
      }) || [];

    const tableBody: TableBody = paginatedLogs ? res : [];

    const Card = renderCardComponent();
    const header: CardHeader = {
      title: 'Jobs',
    };
    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          pagination={{
            enabled: true,
            data: paginatedLogs as PaginatedData<any>,
            activePage: pageState.pageNumber,
            activePageSize: pageState.pageSize,
            handlePageChange(e) {
              getWithPagination({
                taskId: id,
                pageNumber: e.currentPage,
                pageSize: e.pageSize,
                sortBy: 'dateTime',
                order: 'desc',
              });
              setPageState({
                pageSize: e.pageSize,
                pageNumber: e.currentPage,
              });
            },
          }}
        />
      ),
    };

    return <Card header={header} body={body} />;
  };
}
