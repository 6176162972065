/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { deliveryModesData } from '../../../datalayer/repository/in-memory/admin/delivery-mode';

export function renderDeliveryModes(): () => JSX.Element {
  return function Currency(): JSX.Element {
    const Table = renderTableComponent();

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Delivery Modes',
    };

    const tableHeader: TableHeader = [[{ name: 'Delivery Mode' }]];

    const tableBody: TableBody = deliveryModesData.map((d) => {
      return {
        cells: [{ value: d.name }],
      };
    });

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <div>
          <div className='w-full h-full overflow-y-auto scrollbar-none'>
            <Table header={tableHeader} body={tableBody} />
          </div>
        </div>
      ),
    };

    return <Card containerStyle='h-full' header={cardHeader} body={cardBody} />;
  };
}
