/* eslint-disable no-unused-vars */
import { User } from '@erp_core/erp-types/dist/modules/admin';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';

type RenderAddNewUserFormProps = {
  saveNewUser: (user: User) => void;
  onClose: () => void;
};

type AddNewUserFormProps = {
  name: string;
  email: string;
};

export function renderAddNewUserForm({
  saveNewUser,
  onClose,
}: RenderAddNewUserFormProps): () => JSX.Element {
  return function (): JSX.Element {
    const onSave = async (form) => {
      saveNewUser(form);
      onClose();
    };

    const formProps: FormProps<AddNewUserFormProps> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input', required: true },
        { property: 'email', label: 'Email', type: 'email', required: true },
      ],
      initialFormState: {
        name: '',
        email: '',
      },
      mapTToU: (b) => {
        return {
          name: b.name as string,
          email: b.email as string,
        };
      },
      onSubmit: onSave,
    };
    const Form = renderFormV2<AddNewUserFormProps>(formProps);
    return <Form />;
  };
}
