/* eslint-disable class-methods-use-this */
import { renderToast } from '@erp_core/erp-ui-components';
import { stringify } from 'query-string';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../models/interfaces/adapters/erp-v2-api';
import {
  BaseResourceType,
  FilterType,
  SimpleResourceInterface,
} from '../../../models/interfaces/generic-resource';

type Message = {
  title?: string;
  description?: string;
  link?: string;
};
export type NotificationConfig<T> = {
  set?: {
    create?: {
      enable?: boolean;
      defaultMessage: Message;
    };
    update?: {
      enable?: boolean;
      defaultMessage: Message;
      dynamicMessage?: (req: T) => Message;
    };
  };
};

export class GenericHttpRepo<T extends BaseResourceType, U extends FilterType>
  implements SimpleResourceInterface<T, U> {
  baseURI: string;

  resourceEndpoint: string;

  erpv2Api: ErpV2Api;

  notificationConfig?: NotificationConfig<T>;

  constructor({
    baseURI,
    erpv2Api,
    resourceEndpoint,
    notificationConfig,
  }: {
    erpv2Api: ErpV2Api;
    baseURI: string;
    resourceEndpoint: string;
    notificationConfig?: NotificationConfig<T>;
  }) {
    this.baseURI = baseURI;
    this.erpv2Api = erpv2Api;
    this.resourceEndpoint = resourceEndpoint;
    this.notificationConfig = notificationConfig;
  }

  async get(id: string): Promise<T> {
    if (id) {
      const httpInstance = await this.erpv2Api.getAuthorizedInstance({
        apiUrl: this.baseURI,
      });
      const res = await httpInstance.get(`${this.resourceEndpoint}/${id}`);
      if (res.status !== 200) {
        throw new Error(`Resource with ${id} does not exist`);
      }

      const { data } = res;

      return data as T;
    }

    throw new Error('Unknown User');
  }

  async set(s: T): Promise<T> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    if (!s.id) {
      const res = await httpInstance.post(this.resourceEndpoint, s);
      if (res.status !== 200) {
        throw new Error('Resource creation failed');
      }
      this.notificationConfig?.set?.create?.enable &&
        renderToast({
          title:
            this.notificationConfig?.set?.create?.defaultMessage.title || '',
          description: this.notificationConfig?.set?.create?.defaultMessage
            .description,
          link: this.notificationConfig?.set?.create?.defaultMessage.link,
          componentToast: toast as any,
        });
      return res.data as T;
    }

    const res = await httpInstance.patch(`${this.resourceEndpoint}/${s.id}`, s);

    if (res.status !== 200) {
      throw new Error('Resource saving failed');
    }

    if (this.notificationConfig?.set?.update?.enable) {
      if (!this.notificationConfig?.set?.update?.dynamicMessage) {
        renderToast({
          title:
            this.notificationConfig?.set?.update?.defaultMessage.title || '',
          description: this.notificationConfig.set.update.defaultMessage
            .description,
          link: this.notificationConfig.set.update.defaultMessage.link,
          componentToast: toast as any,
        });
      } else {
        const msg = this.notificationConfig?.set?.update?.dynamicMessage?.(s);
        renderToast({
          title: msg.title || '',
          description: msg.description,
          link: msg.link,
          componentToast: toast as any,
        });
      }
    }
    return this.get(s.id);
  }

  async delete(id: string): Promise<void> {
    const httpInstance = await this.erpv2Api.getAuthorizedInstance({
      apiUrl: this.baseURI,
    });

    const res = await httpInstance.delete(`${this.resourceEndpoint}/${id}`);

    if (res.status !== 200) {
      throw new Error('Resource deletion failed');
    }
  }

  // eslint-disable-next-line
  async getAll(filter?: U): Promise<Array<T>> {
    try {
      const httpInstance = await this.erpv2Api.getAuthorizedInstance({
        apiUrl: this.baseURI,
      });

      const res = await httpInstance.get(
        `${this.resourceEndpoint}?${stringify(filter ?? {})}`
      );
      if (res.status === 200) {
        const { data } = res;
        if (data.metaInfo) return data;
        return data.map((c) => c as T);
      }
      return [];
    } catch (e) {
      if (
        (e as any)
          .toString()
          .includes('Error: Request failed with status code 401')
      ) {
        let resource: string | string[] = this.resourceEndpoint.split('/');
        resource = resource[resource.length - 1];
        toast(`You are not authorized to read ${resource as string}`, {
          id: this.resourceEndpoint,
          icon: '🔒',
          className: 'bg-red-500',
        });
      }
      throw new Error(e as string);
    }
  }
}
