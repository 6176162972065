import { InsurancePayment } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';

import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

// eslint-disable-next-line
export function renderEditInsuranceForm(): (data: {
  ins: InsurancePayment;
  onSave: any;
}) => JSX.Element {
  return function EditInsuranceForm({
    ins,
    onSave,
  }: {
    ins: InsurancePayment;
    onSave: any;
  }): JSX.Element {
    const formProps: FormProps<InsurancePayment> = {
      fieldsData: [
        {
          property: 'amount',
          type: 'number',
          label: 'Amount',
        },
        {
          property: 'monthYear',
          type: 'input',
          label: 'Month',
        },
        {
          property: 'insuranceCompanyName',
          type: 'input',
          label: 'Insurance Company',
        },
        {
          property: 'policyNo',
          type: 'input',
          label: 'Policy Number',
        },
        {
          property: 'status',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Pending', value: 'pending' },
            { text: 'Paid', value: 'paid' },
          ],
        },
      ],
      initialFormState: {
        amount: ins.amount,
        monthYear: ins.monthYear,
        insuranceCompanyName: ins.insuranceCompanyName,
        policyNo: ins.policyNo,
        status: ins.status,
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<InsurancePayment>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<InsurancePayment>): InsurancePayment {
      return {
        monthYear: (b.monthYear as string) || ins.monthYear,
        amount: (b.amount as number) || ins.amount,
        insuranceCompanyName:
          (b.insuranceCompanyName as string) || ins.insuranceCompanyName,
        policyNo: (b.policyNo as string) || ins.policyNo,
        status: (b.status as any) || ins.status,
        paidBy: ({} as any) || ins.paidBy,
      };
    }
  };
}
