import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderHistory,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseCombinedCompanyAdmin } from '../../../../hooks/admin/company-admin/use-company-admin';
import { UseCombinedLocationAdmin } from '../../../../hooks/admin/location-admin/use-location';
import { UseFileTransfer } from '../../../../hooks/file-transfer/use-file-transfer';
import { renderLocationAdmin } from '../../../../modules/admin/location-admin';
import { renderCompanyProfile } from './company-details';
import { createCompanyRegistration } from './registration';
import { createCompanyDocumentRegistration } from './registration-doc';

export type RenderCompanyDetailsProp = {
  hooks: {
    useCombinedCompanyAdmin: UseCombinedCompanyAdmin;
    useFileTransfer: UseFileTransfer;
    useCombinedLocationAdmin: UseCombinedLocationAdmin;
  };
};

export function renderCompanyDetails({
  hooks: { useCombinedCompanyAdmin, useFileTransfer, useCombinedLocationAdmin },
}: RenderCompanyDetailsProp): () => JSX.Element {
  const History = renderHistory();
  return function CompanyDetails(): JSX.Element {
    const { id } = useParams();
    const TabsV2 = renderTabsV2Component();
    const {
      resource: data,
      get: getCompanyDetail,
      syncSet: setCompany,
      loading,
    } = useCombinedCompanyAdmin();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getCompanyDetail(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${data?.name || id}`,
    };

    const Registration = createCompanyRegistration();

    const RegistrationDocument = createCompanyDocumentRegistration();

    const Location = renderLocationAdmin({
      useCombinedCompanyAdmin,
      useCombinedLocationAdmin,
    });

    const CompanyDetails = renderCompanyProfile({ loading });

    const newCategories: DataArray = [
      {
        name: 'company-profile',
        Fn: () =>
          data ? (
            <div>
              <CompanyDetails company={data} />
            </div>
          ) : (
            <></>
          ),
      },
      {
        name: 'registration',
        Fn: () =>
          data ? (
            <div className='flex flex-col'>
              <div className='px-4 py-2 m-2'>
                <Registration company={data} setCompany={setCompany} />
              </div>
              <div className='px-4 py-2 m-2'>
                <RegistrationDocument
                  company={data}
                  setCompany={setCompany}
                  useFileTransfer={useFileTransfer}
                />
              </div>
            </div>
          ) : (
            <></>
          ),
      },
      {
        name: 'history',
        Fn: () => <History useResource={useCombinedCompanyAdmin} id={id} />,
      },
      {
        name: 'location',
        Fn: () => (
          <div>
            <Location companyId={id} />
          </div>
        ),
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <TabsV2 data={newCategories} manipulateLocation={true} />
        </>
      ),
    };

    return <Card header={header} body={body} />;
  };
}
