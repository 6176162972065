import { LoadingButton } from '@erp_core/erp-ui-components';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { read, utils, writeFileXLSX } from 'xlsx';

type OneOnOneMappingConfig = {
  type: 'one-on-one-mapping';
  fileName: string;
  mapper: {
    identifier: {
      wsCol: string;
      objProp: string;
    };
    fields: Array<{ wsCol: string; objProp: string }>;
  };
};

type ManyOnOneMappingConfig = {
  type: 'many-on-one-mapping';
  targetArray: string;
  fileName: string;
  mapper: {
    identifier: {
      wsCol: string;
      objProp: string;
    };
    fields: Array<{ wsCol: string; objProp: string; onTargetArray: boolean }>;
  };
};

type ExcelConfig = OneOnOneMappingConfig | ManyOnOneMappingConfig;

export function renderExcelUpload<T extends { id: string }>({
  resource,
  setResource,
  excelConfig,
}: {
  resource: string;
  setResource: (resource: T, opt?: any) => Promise<T>;
  excelConfig: Array<ExcelConfig>;
}): () => JSX.Element {
  async function syncData(
    data: Array<T>,
    incrementProgress: (number: number) => void
  ) {
    let count = data.length;

    // eslint-disable-next-line
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      // eslint-disable-next-line
      const it = await setResource(item, {
        enableLoading: false,
        enableResource: false,
      });
      item.id = it.id;
      incrementProgress(100 / count);
    }

    const wb = utils.book_new();

    for (let i = 0; i < excelConfig.length; i++) {
      const sheetConfig = excelConfig[i];

      const sheetData = [sheetConfig.mapper.fields.map((f) => f.wsCol)];

      if (sheetConfig.type === 'one-on-one-mapping') {
        mapToSheetForOneOnOne(data, sheetConfig, sheetData);
      }

      if (sheetConfig.type === 'many-on-one-mapping') {
        mapToSheetForManyOnOne(data, sheetConfig, sheetData);
      }

      const ws = utils.aoa_to_sheet(sheetData);
      utils.book_append_sheet(wb, ws, sheetConfig.fileName);
    }

    writeFileXLSX(wb, `${resource}s.xlsx`);
  }

  function mapToSheetForOneOnOne(data, sheetConfig, sheetData) {
    for (let j = 0; j < data.length; j++) {
      const row: Array<string> = [];
      const obj = data[j];
      sheetConfig.mapper.fields.forEach((f) => {
        assignToSheet(f.objProp, obj, row);
      });

      sheetData.push(row);
    }
  }

  function mapToSheetForManyOnOne(
    data,
    sheetConfig: ManyOnOneMappingConfig,
    sheetData
  ) {
    for (let j = 0; j < data.length; j++) {
      const obj = data[j];
      const targetArray = _.get(obj, sheetConfig.targetArray);

      targetArray.forEach((i) => {
        const row: Array<string> = [];
        sheetConfig.mapper.fields.forEach((f) => {
          if (f.onTargetArray) {
            assignToSheet(f.objProp, i, row);
          } else {
            assignToSheet(f.objProp, obj, row);
          }
        });
        sheetData.push(row);
      });
    }
  }

  return function ExcelUpload(): JSX.Element {
    const [validationError, setValidationError] = useState<
      string | JSX.Element
    >('');
    const [stage, setStage] = useState<
      | 'initial'
      | 'processing'
      | 'validating'
      | 'validation-error'
      | 'summary'
      | 'uploading'
      | 'done'
    >('initial');
    const [finalData, setFinalData] = useState<Array<T>>([]);
    const [progress, setProgress] = useState<number>(0);

    const incrementProgress = (num: number) => {
      // Update state with incremented value
      setProgress((progress) => progress + num);
    };

    useEffect(() => {
      if (stage === 'uploading') {
        syncData(finalData, incrementProgress);
      }
      // eslint-disable-next-line
    }, [stage]);

    const processFiles = (files: FileList) => {
      setStage('processing');
      const reader = new FileReader();
      reader.readAsBinaryString(files[0]);

      reader.addEventListener('loadend', (event: any) => {
        let hasError = false;
        const { result } = event.target;
        // Do something with result
        const workbook = read(result, { type: 'binary' });

        let processedData: Array<T> = [];

        for (let i = 0; i < excelConfig.length; i++) {
          const f = excelConfig[i];
          const wsname = workbook.SheetNames[i];
          if (wsname !== f.fileName) {
            setValidationError('Invalid File Format');
            hasError = true;
            break;
          }

          const sheetData: any = utils.sheet_to_json(workbook.Sheets[wsname], {
            defval: '',
          });

          if (!sheetData[0].hasOwnProperty(f.mapper.identifier.wsCol)) {
            setValidationError(
              `${f.mapper.identifier.wsCol} is required in sheet: "${wsname}"`
            );
            hasError = true;
            break;
          }

          const missingSheetFields: Array<string> = [];

          f.mapper.fields.map((mf) => {
            if (!sheetData[0].hasOwnProperty(mf.wsCol)) {
              missingSheetFields.push(mf.wsCol);
            }
          });

          if (missingSheetFields.length) {
            setValidationError(
              `Sheet: "${wsname}" has following fields missing: ${missingSheetFields.join()}`
            );
            hasError = true;
            break;
          }

          if (i === 0) {
            sheetData.forEach((e) => {
              processedData.push({} as T);
            });
          }

          if (f.type === 'one-on-one-mapping') {
            performOneOnOneMapping(sheetData, processedData, f);
          } else if (f.type === 'many-on-one-mapping') {
            performManyOnOneMapping(sheetData, processedData, f);
          }
        }

        if (hasError) {
          setStage('validation-error');
          return;
        }

        setFinalData(processedData);
        setStage('summary');
      });
    };

    switch (stage) {
      case 'initial':
        return (
          <div className='mx-auto w-64 h-24 border border-dotted border-gray-400 bg-slate-50 p-5'>
            <div className='text-center content-center align-middle h-full'>
              <label
                htmlFor='upload-file'
                className='bg-green-400 text-white h-10 border rounded-md cursor-pointer p-2'
              >
                Upload File
                <ArrowUpIcon className='inline w-4 h-4' />
              </label>
              <input
                id='upload-file'
                hidden
                type='file'
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
                onChange={(e) => e.target.files && processFiles(e.target.files)}
              />
            </div>
          </div>
        );
      case 'processing':
        return (
          <div className='h-24 w-full'>
            <div className='italic text-blue-800 text-center'>
              Processing...
            </div>
          </div>
        );
      case 'validating':
      case 'validation-error':
        return (
          <div className='flex flex-col'>
            <p className='text-lg font-medium'>Validation Error</p>
            {validationError}
            {/* <button type='button' className='mt-5 p-3 w-40 bg-blue-300' onClick={onInvalidValidationContinue} > Continue Anyway</button> */}
          </div>
        );
      case 'summary':
        return (
          <div className='h-24 w-full'>
            <p className='text-lg font-medium'>Summary</p>
            <div className='italic text-blue-800 text-center'>
              {finalData.length} {resource}
              {finalData.length > 1 ? 's' : ''} to be Synced...
            </div>
            <LoadingButton
              defaultStyle='mt-5 p-3 w-32 bg-blue-300'
              behaviorFn={async () => setStage('uploading')}
              text='SYNC'
            />
          </div>
        );
      case 'uploading':
        return (
          <div className=''>
            Uploading...
            <div className='w-full bg-gray-200 rounded-full h-1.5 my-4 dark:bg-gray-700'>
              <div
                className='bg-blue-600 h-1.5 rounded-full dark:bg-blue-500'
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>
        );
      case 'done':
      default:
        return <div>Something went wrong {stage}.</div>;
    }
  };
}

function performOneOnOneMapping(
  sheetData,
  processedData,
  f: OneOnOneMappingConfig
) {
  sheetData.forEach((e, idx) => {
    let objToUpdate = processedData[idx];

    f.mapper.fields.forEach((m) => {
      // assign the value
      assignFromSheet(m.objProp, objToUpdate, m.wsCol, e);
    });
  });
}

function performManyOnOneMapping(
  sheetData,
  processedData,
  f: ManyOnOneMappingConfig
) {
  sheetData.forEach((e, idx) => {
    if (f.mapper.identifier.objProp.includes('.')) {
      console.log(
        'Attempted to provide a nested field identifier. This has not been handled so far :('
      );
    } else {
      let objToUpdate = processedData.find(
        (x) => x[f.mapper.identifier.objProp] === e[f.mapper.identifier.wsCol]
      );

      let targetArrayWithinObjToUpdate = _.get(objToUpdate, f.targetArray);
      if (!targetArrayWithinObjToUpdate) {
        _.set(objToUpdate, f.targetArray, []);
        targetArrayWithinObjToUpdate = _.get(objToUpdate, f.targetArray);
      }

      let objToPushInTargetArray: any = {};

      f.mapper.fields.forEach((m) => {
        // append the value to the targetArray
        if (m.onTargetArray) {
          assignFromSheet(m.objProp, objToPushInTargetArray, m.wsCol, e);
        } else {
          assignFromSheet(m.objProp, objToUpdate, m.wsCol, e);
        }
      });

      targetArrayWithinObjToUpdate.push(objToPushInTargetArray);
    }
  });
}

function assignFromSheet(propertyName, objectToUpdate, colName, excelObj) {
  if (!propertyName.includes('.')) {
    objectToUpdate[propertyName] = excelObj[colName];
    return;
  }

  const path = propertyName.split('.');

  let finalObjToUpdate = objectToUpdate;

  for (let i = 0; i < path.length - 1; i++) {
    if (typeof finalObjToUpdate[path[i]] !== 'object') {
      finalObjToUpdate[path[i]] = {};
    }

    finalObjToUpdate = finalObjToUpdate[path[i]];
  }

  finalObjToUpdate[path[path.length - 1]] = excelObj[colName];
}

function assignToSheet(propertyName, obj, excelArray) {
  const data = _.get(obj, propertyName) || '';
  excelArray.push(data);
}
