/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderEditItemLandingCostFactors = {
  closeModal: () => void;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

type Props = {
  comGrpSet?: CompanyGroupSetting;
};

export function renderEditLandingCostFactorsForm({
  closeModal,
  useCombinedCompanyGroupSetting,
}: RenderEditItemLandingCostFactors): (data: Props) => JSX.Element {
  return function ({ comGrpSet }: Props): JSX.Element {
    const {
      syncSet: setCompanyGroupSetting,
    } = useCombinedCompanyGroupSetting();
    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;
    const landingCostFactor =
      comGrpSet?.details?.purchase?.landingCostFactors || '';

    const saveLandingCostFactor = async (form: FormType) => {
      const finalData: Partial<CompanyGroupSetting> = {
        id: comGrpSet.id,
        details: {
          ...comGrpSet.details,
          purchase: {
            ...comGrpSet.details?.purchase,
            landingCostFactors: form.landingCostFactor,
          },
        },
      };
      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      closeModal();
    };

    const Chip = ({ title, text, className }) => (
      <span
        title={title}
        className={` ${className} shadow-sm px-1 ml-1 shadow-gray-500 cursor-pointer rounded text-gray-900 `}
      >
        {text}
      </span>
    );

    const guidelines = [
      {
        title: 'BASE',
        description: (
          <span>
            Base cost of the item.
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Rate from Enquiry Quotes'}
                text='Quotes'
                className='bg-blue-300'
              />
              ]
            </i>
          </span>
        ),
      },
      {
        title: 'TRANSPORT',
        description: (
          <span>
            Overall transport cost.{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of delivery'}
                text='TOD'
                className='bg-green-300'
              />{' '}
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'CUSTOM',
        description: (
          <span>
            Custom duty charges.{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of delivery'}
                text='TOD'
                className='bg-green-300'
              />{' '}
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'FREIGHT',
        description: (
          <span>
            Overall Freight charges.{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of delivery'}
                text='TOD'
                className='bg-green-300'
              />
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'PF',
        description: (
          <span>
            P&F charges{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of delivery'}
                text='TOD'
                className='bg-green-300'
              />
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'LC',
        description: (
          <span>
            Letter of Credit Charges{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of Payment'}
                text='TOP'
                className='bg-yellow-300'
              />{' '}
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'CREDIT-PERIOD',
        description: (
          <span>
            Credit Period in days.{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of Payment'}
                text='TOP'
                className='bg-yellow-300'
              />{' '}
              ]{' '}
            </i>
          </span>
        ),
      },
      {
        title: 'BANK-INTEREST',
        description: (
          <span>
            Bank interest rate{' '}
            <i className='text-gray-600'>
              {' '}
              [ depends on
              <Chip
                title={'Terms of Payment'}
                text='TOP'
                className='bg-yellow-300'
              />{' '}
              ]{' '}
            </i>
          </span>
        ),
      },
    ];

    type FormType = { landingCostFactor: string };

    const formProps: FormProps<FormType> = {
      fieldsData: [
        {
          property: 'landingCostFactor',
          label: 'Landing cost Factor Pattern',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        landingCostFactor,
      },
      mapTToU: (d) => {
        return d as FormType;
      },
      style: '',
      onSubmit: saveLandingCostFactor,
    };

    const Form = renderFormV2<FormType>(formProps);

    const validOperations = ['(', ')', '/', '*', '+', '-'];

    return (
      <div>
        <Form />
        <div className='italic'>
          Example Patterns:{' '}
          {
            '%{BASE} + %{TRANSPORT} + %{PF} + %{LC} - ( (%{BASE} * %{BANK-INTEREST / 12} * %{CREDIT-PERIOD / 30}) / 100 )'
          }
        </div>

        <div className='font-bold text-md mt-1'> Operations </div>
        <div className='flex space-x-2 flex-wrap'>
          {validOperations.map((vo) => {
            return (
              <div
                key={vo}
                className='flex items-center justify-center w-6 rounded-sm border mr-1'
              >
                {vo}
              </div>
            );
          })}
        </div>

        <div className='font-bold text-md mt-1'> Guidelines</div>
        <div className='flex flex-wrap'>
          {guidelines.map((g, index) => {
            return (
              <div key={index} className='flex w-full xl:w-1/2'>
                <div className='mr-4 font-bold'> {g.title}</div>
                {g.description}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
}
