import {
  AttendanceType,
  CombinedAttDataType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { renderFormV2 } from '@erp_core/erp-ui-components';

export function renderUnVerify({
  setAttendance,
  getData,
}: {
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
}): (p: {
  data: { attendance: CombinedAttDataType };
  onClose: () => void;
}) => JSX.Element {
  return function UnVerify({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const Form = renderFormV2({
      formName: 'Are you sure you want to un-verify?',
      fieldsData: [
        {
          property: 'unverify',
          label: 'UnVerify',
          required: true,
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
      ],
      initialFormState: {
        unverify: '',
      },
      onSubmit: async (form) => {
        if (form.unverify === 'yes') {
          if (attendance.id) {
            await setAttendance({
              id: attendance.id,
              verified: {
                verified: 'no',
              },
            } as AttendanceType);
            await getData();
            onClose();
          }
        }
      },
      mapTToU: (p) => p,
    });

    return <Form />;
  };
}
