import { MachineSchedule } from '@erp_core/erp-types/dist/modules/planning';
import _ from 'lodash';
import moment from 'moment';

export const mapSchedule = (machineSchedule: MachineSchedule) => {
  const startDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
  const sched = Object.entries(machineSchedule.details.schedule).map(
    ([k, v]) => {
      if (k === 'monday') {
        const date = startDate;
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }
      if (k === 'tuesday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(1, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }
      if (k === 'wednesday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(2, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }
      if (k === 'thursday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(3, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }
      if (k === 'friday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(4, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }
      if (k === 'saturday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(5, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }

      if (k === 'sunday') {
        const date = moment(startDate, 'YYYY-MM-DD')
          .add(6, 'days')
          .format('YYYY-MM-DD');
        return v.map((d) => ({
          id: String(Math.random()),
          calendarId: 'cal1',
          title: d.item.name,
          category: 'time',
          color: '#ffffff',
          backgroundColor: '#4b89dc',
          dragBackgroundColor: '#000',
          borderColor: '#54B8CC',
          start: `${date}T${d.from}`,
          end: `${date}T${d.to}`,
        }));
      }

      return {};
    }
  );

  return _.flatten(sched);
};
