import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { renderFormV2 } from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext } from 'react';
import { UserContext } from '../../../../../contexts/user';

export function RecordActivity({
  employee,
  setEmployee,
  onClose,
}: {
  employee: EmployeeProfileType;
  setEmployee: (s: EmployeeProfileType) => void;
  onClose: () => void;
}) {
  const { user: currentUser } = useContext(UserContext);

  const Form = renderFormV2({
    initialFormState: {
      activity: '',
      date: '',
    },
    fieldsData: [
      {
        property: 'activity',
        type: 'select',
        required: true,
        options: [
          { text: 'Select', value: '' },
          { text: 'completed-probation', value: 'completed-probation' },
          { text: 'went-on-strike', value: 'went-on-strike' },
          { text: 'came-back-from-strike', value: 'came-back-from-strike' },
          { text: 'sent-on-lay-off', value: 'sent-on-lay-off' },
          { text: 'back-from-lay-off', value: 'back-from-lay-off' },
        ],
      },
      {
        property: 'date',
        type: 'date',
        required: true,
      },
    ],
    onSubmit: async (form) => {
      if (form.activity && form.date) {
        if (
          !employee.details?.workTimeLines?.find((x) => x.date === form.date)
        ) {
          const workTimeLines = _.sortBy(
            [
              {
                activity: form.activity,
                date: form.date,
                updatedBy: {
                  id: currentUser.id,
                  name: currentUser.name,
                },
                updatedOn: moment.utc(),
              },
              ...(employee?.details?.workTimeLines || []),
            ],
            'date'
          ).reverse();
          const finalData = {
            id: employee.id,
            details: {
              workTimeLines,
            },
          };
          setEmployee(finalData as any);
        }
      }
      onClose();
    },
    mapTToU: (m) => m,
  });

  return <Form />;
}
