import { IdName } from '@erp_core/erp-types/dist/types/modules/common/dependent-resources';
import {
  LoadingButton,
  renderFormV2,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
import { useState } from 'react';

export type DeliveryScheduleType = {
  idx: number;
  name: string;
  daysAfterOrder: number;
  quantity: number;
  boms: { id: string; name: string; quantity: number }[];
};

export function DeliverySchedule({
  deliverySchedule,
  setDeliverySchedule,
  availableBoms,
}: {
  deliverySchedule: DeliveryScheduleType[];
  setDeliverySchedule: React.Dispatch<
    React.SetStateAction<DeliveryScheduleType[]>
  >;
  availableBoms: Array<IdName>;
}): JSX.Element {
  const Table = renderTableComponent();

  return (
    <>
      <Table
        header={[
          [
            { name: 'Name' },
            { name: 'Quantity' },
            { name: 'Days' },
            { name: 'Boms' },
          ],
        ]}
        body={deliverySchedule.map((s) => {
          return {
            rowData: {
              sch: s,
            },
            cells: [
              { value: s.name },
              { value: s.quantity },
              { value: s.daysAfterOrder },
              {
                value: (
                  <>
                    {s.boms?.map((b) => (
                      <span key={b.id}>
                        {b.name || 'not-set'} - {b.quantity}
                      </span>
                    ))}
                  </>
                ),
              },
            ],
          };
        })}
        actions={[
          {
            name: 'Edit',
            behaviour: 'modal',
            show: () => true,
            modal: {
              title: 'Edit',
              content: ({
                data: { sch },
                onClose,
              }: {
                data: { sch: DeliveryScheduleType };
                onClose: () => void;
              }) => {
                const Form = renderFormV2({
                  initialFormState: {
                    name: sch.name,
                    daysAfterOrder: sch.daysAfterOrder,
                    quantity: sch.quantity,
                    boms: sch.boms,
                  },
                  fieldsData: [
                    { property: 'name', type: 'input', required: true },
                    {
                      property: 'daysAfterOrder',
                      type: 'number',
                      required: true,
                    },
                    { property: 'quantity', type: 'number', required: true },
                  ],
                  mapTToU: (p) => {
                    return p;
                  },
                  onSubmit: async (data) => {
                    const newDeliverySch = [...deliverySchedule];
                    console.log(newDeliverySch);
                    console.log(sch);
                    newDeliverySch[sch.idx].name = (data.name ||
                      sch.name) as string;
                    newDeliverySch[sch.idx].daysAfterOrder = data.daysAfterOrder
                      ? parseInt(`${data.daysAfterOrder}`)
                      : sch.daysAfterOrder;
                    newDeliverySch[sch.idx].quantity = data.quantity
                      ? parseInt(`${data.quantity}`)
                      : sch.quantity;

                    setDeliverySchedule(newDeliverySch);

                    onClose();
                  },
                });
                return <Form />;
              },
            },
          },
          {
            name: 'Add BOM',
            behaviour: 'click',
            show: () => true,
            onClick: async ({ sch }) => {
              const newDeliverySch = [...deliverySchedule];
              newDeliverySch[sch.idx].boms.push({
                id: '',
                name: '',
                quantity: 0,
              });
              setDeliverySchedule(newDeliverySch);
            },
          },
          {
            name: 'Edit BOM',
            behaviour: 'modal',
            show: () => true,
            modal: {
              title: 'Edit Bom',
              content: ({ data: { sch }, onClose }) => (
                <EditBom
                  deliverySchedule={deliverySchedule}
                  onClose={onClose}
                  availableBoms={availableBoms}
                  sch={sch}
                  setDeliverySchedule={setDeliverySchedule}
                />
              ),
            },
          },
        ]}
      />
    </>
  );
}

function EditBom({
  availableBoms,
  sch,
  deliverySchedule,
  setDeliverySchedule,
  onClose,
}: {
  availableBoms: Array<{ id: string; name: string }>;
  deliverySchedule: Array<DeliveryScheduleType>;
  sch: DeliveryScheduleType;
  setDeliverySchedule: (
    value: React.SetStateAction<DeliveryScheduleType[]>
  ) => void;
  onClose: () => void;
}) {
  const [boms, setBoms] = useState<
    Array<{ id: string; name: string; quantity }>
  >(sch.boms || []);

  return (
    <>
      {boms.map((x, bidx) => (
        <div key={bidx}>
          BOM:{' '}
          <select
            value={x.id}
            onChange={(e) => {
              const newBoms = [...boms];
              const avl = availableBoms.find((y) => y.id === e.target.value);
              if (avl) {
                newBoms[bidx].id = avl.id;
                newBoms[bidx].name = avl.name;
              }

              setBoms(newBoms);
            }}
          >
            <option value={''}>Select</option>
            {availableBoms.map((a) => (
              <option value={a.id}>{a.name}</option>
            ))}
          </select>
          &nbsp;&nbsp; Quantity:{' '}
          <input
            type='number'
            defaultValue={x.quantity}
            onBlur={(e) => {
              const newBoms = [...boms];
              newBoms[bidx].quantity = parseInt(e.target.value);
              setBoms(newBoms);
            }}
          />
          <LoadingButton
            text={'Save'}
            behaviorFn={async () => {
              const newDeliverySch = [...deliverySchedule];
              newDeliverySch[sch.idx].boms = boms;
              setDeliverySchedule(newDeliverySch);
              onClose();
            }}
          />
        </div>
      ))}
    </>
  );
}
