import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { utils, writeFileXLSX } from 'xlsx';
import { getCategories } from '../utils/category-sorter';
import { formatParam } from '../utils/generate-salary';
import { SRType } from './create-statutory-reports';

export type DetailsProps = {
  srList: SRType;
};

export function renderSalaryRegulatoryReport({
  companyGroupId,
}: {
  companyGroupId: string;
}): (p: DetailsProps) => JSX.Element {
  const Table = renderTableComponent();
  return function SalaryDetailsRegulatoryReport({
    srList,
  }: DetailsProps): JSX.Element {
    const categories: Array<{ name: string; list: SRType }> = getCategories({
      srList,
    });

    function calculateBody(c: { name: string; list: SRType }): TableBody {
      const body: TableBody = [];
      if (companyGroupId === 'A49079C2-8852-471B-A86E-FCD70D53DBDE') {
        c.list.data.forEach((x) => {
          const sal = x.salary;
          const emp = x.employee;

          body.push({
            cells: [
              { value: emp.details?.employeeId, rowSpan: 4 },
              { value: emp.name, rowSpan: 4 },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'other.adjustedPayableDays'
                ),
                rowSpan: 4,
              },
              { value: 'Earning', rowSpan: 2 },
              { value: 'Basic-DA' },
              { value: 'LTA' },
              { value: 'Wash' },
              { value: 'Conveyance' },
              { value: 'Medical' },
              { value: 'HRA' },
              { value: 'NPA' },
              { value: 'Skill' },
              { value: 'Education' },
              { value: 'Other' },
              { value: 'Overtime' },
              { value: 'Management Inc' },
              { value: 'Performance Bonus' },
              { value: 'Attendance Bonus' },
              { value: 'Ex-Gratia (Monthly)' },
              { value: 'Adhoc Earning' },
              { value: 'Gross Earnings' },
              { value: 'Net Pay' },
            ],
          });
          body.push({
            cells: [
              { value: _.get(sal?.details?.salaryParams, 'earning.basicDa') },
              { value: _.get(sal?.details?.salaryParams, 'earning.lta') },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.washAllowance'
                ),
              },
              {
                value: formatParam({
                  value:
                    sal?.details?.salaryParams?.earning?.conveyanceAllowance,
                  metric: 'number',
                  scale: 2,
                  plain: true,
                }),

                // _.get(
                //   sal?.details?.salaryParams,
                //   'earning.conveyanceAllowance'
                // ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.medicalAllowance'
                ),
              },
              { value: _.get(sal?.details?.salaryParams, 'earning.hra') },
              { value: _.get(sal?.details?.salaryParams, 'earning.npa') },
              { value: _.get(sal?.details?.salaryParams, 'earning.skill') },
              { value: _.get(sal?.details?.salaryParams, 'earning.education') },
              { value: _.get(sal?.details?.salaryParams, 'earning.other') },
              { value: _.get(sal?.details?.salaryParams, 'earning.overtime') },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.managementIncentive'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.performanceBonus'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.attendanceBonus'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.exGratiaMonthly'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.adhocEarningsVal'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.grossEarnings'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.employeeTakeHome'
                ),
              },
            ],
          });
          body.push({
            cells: [
              { value: 'Deduction', rowSpan: 2 },
              { value: 'PF' },
              { value: 'ESIC' },
              { value: 'PT' },
              { value: 'MLWF' },
              { value: 'Advance' },
              { value: 'Advance2' },
              { value: 'Insurance Premium' },
              { value: 'Loan EMI' },
              { value: 'Loan Interest EMI' },
              { value: 'Income Tax' },
              { value: 'Adhoc deduction' },
              { value: 'Others' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: 'Total Deductions' },
              { value: 'Sign' },
            ],
          });
          body.push({
            cells: [
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.employeePf'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.employeeEsic'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.employeePt'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.employeeMlwf'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.salaryAdvanceTaken'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.adjustedAdvances'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.insurancePremiumDeductions'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.loanEmiDeductions'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.loanInterestEmiDeductions'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.incomeTaxDeduction'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.adhocDeductionsVal'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.otherDeductions'
                ),
              },
              { value: '' },
              { value: '' },
              { value: '' },
              { value: '' },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.totalEmployeeDeductions'
                ),
              },
              { value: '' },
            ],
          });
        });
      } else {
        c.list.data.forEach((x) => {
          const sal = x.salary;
          const emp = x.employee;

          body.push({
            cells: [
              { value: emp.details?.employeeId, rowSpan: 4 },
              { value: emp.name, rowSpan: 4 },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'other.adjustedPayableDays'
                ),
                rowSpan: 4,
              },
              { value: 'Earning', rowSpan: 2 },
              { value: 'Basic-DA' },
              { value: 'Bonus' },
              { value: 'Overtime' },
              { value: 'Performance Bonus' },
              { value: 'Management Inc' },
              { value: 'Gross Earnings' },
              { value: 'Net Pay' },
            ],
          });
          body.push({
            cells: [
              { value: _.get(sal?.details?.salaryParams, 'earning.basicDa') },
              { value: _.get(sal?.details?.salaryParams, 'earning.bonus') },
              {
                value: _.get(sal?.details?.salaryParams, 'earning.overtime'),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.performanceBonus'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.managementIncentive'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'earning.grossEarnings'
                ),
              },
              { value: _.get(sal?.details?.salaryParams, 'earning.takeHome') },
            ],
          });
          body.push({
            cells: [
              { value: 'Deduction', rowSpan: 2 },
              { value: 'PF' },
              { value: 'PFA' },
              { value: 'EDLI' },
              { value: 'PT' },
              { value: 'Advance1' },
              { value: 'Advance2' },
              { value: 'Loan EMI' },
              { value: 'Loan Interest EMI' },
              { value: 'Adhoc deduction' },
            ],
          });
          body.push({
            cells: [
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.pfEmployee'
                ),
              },
              {
                value: _.get(sal?.details?.salaryParams, 'deduction.pfa'),
              },
              {
                value: _.get(sal?.details?.salaryParams, 'deduction.edli'),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.ptEmployee'
                ),
              },
              {
                value: _.get(sal?.details?.salaryParams, 'deduction.advance1'),
              },
              {
                value: _.get(sal?.details?.salaryParams, 'deduction.advance2'),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.loanPrincipal'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'deduction.loanInterest'
                ),
              },
              {
                value: _.get(
                  sal?.details?.salaryParams,
                  'other.adhocDeductionsVal'
                ),
              },
            ],
          });
        });
      }

      return body;
    }
    return (
      <div id='employee-details-report'>
        <div className='flex flex-row-reverse'>
          <LoadingButton
            defaultStyle='bg-green-500 text-white p-1 border rounded-lg'
            behaviorFn={async () => {
              categories.forEach((c) => {
                const elt = document.getElementById(`${c.name}-sal-sheet`);
                const workbook = utils.table_to_book(elt);
                var ws = workbook.Sheets['Sheet1'];
                utils.sheet_add_aoa(ws, [], { origin: -1 });
                writeFileXLSX(workbook, `${c.name}.xlsx`);
              });
            }}
            text={
              <>
                <ArrowDownTrayIcon className='w-5 inline' /> Download
              </>
            }
          />
        </div>

        {categories.map((c, idx) => {
          const add = _.first(c.list.data)?.company?.details?.address;
          const companyAddress = `${add?.addressLine1 || '-'}, ${
            add?.addressLine2 || '-'
          }, ${add?.city || '-'}, ${add?.district} - ${add?.pincode}, ${
            add?.state
          }`;
          const salMonth = _.first(c.list.data)?.salary?.month || '';
          return (
            <div key={idx} id={`${c.name}-sal-sheet`}>
              <Table
                header={[
                  [
                    {
                      name: _.first(c.list.data)?.company.name || '',
                      colSpan: 21,
                      style: 'text-center',
                    },
                  ],
                  [
                    {
                      name: companyAddress,
                      colSpan: 21,
                      style: 'text-center',
                    },
                  ],
                  [
                    {
                      name: `Salary Sheet for ${c.name}`,
                      colSpan: 21,
                      style: 'text-center',
                    },
                  ],
                  [
                    { name: `Month: ${salMonth}`, colSpan: 11 },
                    {
                      name: `No of entries ${c.list.data.length}`,
                      colSpan: 10,
                    },
                  ],
                  [
                    { name: 'EmpId' },
                    { name: 'Name' },
                    { name: 'Payable Days' },
                    { name: 'Categories' },
                    {
                      name: 'Details',
                      colSpan:
                        companyGroupId ===
                        'A49079C2-8852-471B-A86E-FCD70D53DBDE'
                          ? 18
                          : 9,
                    },
                  ],
                ]}
                body={calculateBody(c)}
              />
            </div>
          );
        })}
      </div>
    );
  };
}
