import { WASch } from '../components/shift-generator-v2';

export function scheduleShifter(sch: WASch, leftShift: number): WASch {
  if (leftShift <= 6) {
    const newSch: WASch = JSON.parse(JSON.stringify(sch));

    if (newSch[0] && newSch[2]) {
      const firstWeek = newSch[0];
      const secondWeek = newSch[1];
      const thirdWeek = newSch[2];
      // Logic to pass entries from 1st to 3rd week
      for (let empInx = 0; empInx < firstWeek.length; empInx++) {
        const firstEmp = firstWeek[empInx];
        let leftShifted = firstEmp.splice(0, leftShift);

        const thirdEmp = thirdWeek[empInx];
        thirdEmp.splice(thirdEmp.length, 0, ...leftShifted);
      }

      // Logic to pass entries from 3rd to 2nd week
      for (let empInx = 0; empInx < thirdWeek.length; empInx++) {
        const thirdEmp = thirdWeek[empInx];
        let leftShifted = thirdEmp.splice(0, leftShift);

        const secondEmp = secondWeek[empInx];
        secondEmp.splice(secondEmp.length, 0, ...leftShifted);
      }

      // Logic to pass entries from 2nd to 1st week
      for (let empInx = 0; empInx < secondWeek.length; empInx++) {
        const secondEmp = secondWeek[empInx];
        let leftShifted = secondEmp.splice(0, leftShift);

        const firstEmp = firstWeek[empInx];
        firstEmp.splice(firstEmp.length, 0, ...leftShifted);
      }
    }

    return newSch;
  }

  const newSch = scheduleShifter(sch, 6);

  return scheduleShifter(newSch, leftShift - 6);
}
