import {
  PurchaseOrder,
  PurchaseOrderFulfilment,
} from '@erp_core/erp-types/dist/modules/order';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
// import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { generateName } from '@erp_core/erp-utils';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../../../../../contexts/current';
import { UseCombinedPurchaseOrderFulfilment } from '../../../../../../../hooks/inventory/purchase/purchase-order-fulfilment/use-purchase-order-fulfilment';
import { AddFulfillment } from '../types/add-fulfilment';

// eslint-disable-next-line
export function renderCreateEditPurchaseOrderFulfilmentForm({
  useCombinedPurchaseOrderFulfilment,
}: // cgs
{
  useCombinedPurchaseOrderFulfilment: UseCombinedPurchaseOrderFulfilment;
  // cgs: CompanyGroupSetting;
}) {
  return function CreatePurchaseOrderFulfilmentForm({
    purchaseOrder,
    fulfillment,
    onClose,
  }: {
    fulfillment?: PurchaseOrderFulfilment;
    purchaseOrder: PurchaseOrder;
    onClose: () => void;
  }): JSX.Element {
    const { cgSetting: cgs } = useContext(CurrentContext);
    const {
      list: grns,
      getAll: getFulfillments,
      getListSync: getAllFulfilments,
    } = useCombinedPurchaseOrderFulfilment();
    const { syncSet: setFulfillments } = useCombinedPurchaseOrderFulfilment();
    const [fulfillmentSummary, setFulfillmentSummary] = useState<{
      total: number;
      previouslyAdded: number;
    }>({
      total: 0,
      previouslyAdded: 0,
    });

    useEffect(() => {
      if (purchaseOrder.id) {
        getFulfillments({
          purchaseOrderId: purchaseOrder.id,
        });
      }
      // eslint-disable-next-line
    }, [purchaseOrder.id]);

    useEffect(() => {
      setFulfillmentSummary({
        total: Number(purchaseOrder?.details.quantity),
        previouslyAdded:
          grns?.reduce((a, b) => a + Number(b?.details?.quantity || 0), 0) || 0,
      });
    }, [grns, purchaseOrder?.details?.quantity]);

    console.log(purchaseOrder);
    const getSummary = (total, previous, added) => {
      if (total === previous + added) {
        return 'Order completed';
      }
      if (total < previous + added) {
        return `Order exceeded by ${previous + added - total}`;
      }
      return `${total - previous - added} remaining out of ${total} `;
    };

    // const handleSave = async (form: any) => {
    //   form.summary = getSummary(
    //     data.total,
    //     data.previouslyAdded,
    //     Number(form.quantity)
    //   );
    //   await onSave(form); // TODO Enable after proper testing
    // };

    const colors = [
      'blue',
      'green',
      'gray',
      'purple',
      'pink',
      'violet',
      'sky',
      'emerald',
    ];
    const formProps: FormProps<Partial<AddFulfillment>> = {
      dynamicContent: {
        top: async () => {
          const chips = [
            { name: 'PO', value: purchaseOrder?.name || '--' },
            { name: 'Vendor', value: purchaseOrder?.vendor?.name || '--' },
            {
              name: 'Supplier',
              value: purchaseOrder?.details?.supplier?.name || '--',
            },
            { name: 'Total PO Quantity', value: fulfillmentSummary.total },
            {
              name: 'Total Received',
              value: fulfillmentSummary.previouslyAdded,
            },
            {
              name: 'GRNs',
              value: (
                <>
                  {grns?.map((x) => (
                    <span key={x.id} className='bg-slate-50 mx-1 px-1'>
                      <span className='font-semibold'>{x.name}</span>
                      <span className='mx-0.5 text-green-600'>
                        [{x.details.quantity}]
                      </span>
                      ({x.status})
                    </span>
                  ))}
                </>
              ),
            },
            {
              name: 'Remaining',
              value:
                fulfillmentSummary.total - fulfillmentSummary.previouslyAdded,
            },
          ];
          return (
            <div>
              <div className='flex p-2 justify-center items-center space-x-3 flex-wrap'>
                {chips.map((x, idx) => (
                  <div
                    key={x.name}
                    className={`border-${colors[idx]}-500 border p-2 rounded-lg bg-${colors[idx]}-50`}
                  >
                    <span className={`text-${colors[idx]}-500 font-semibold`}>
                      {x.name}:{' '}
                    </span>
                    <span className='font-medium'>{x.value} </span>
                  </div>
                ))}
              </div>
            </div>
          );
        },
      },
      fieldsData: [
        {
          property: 'invoiceNo',
          type: 'input',
          label: 'Invoice Number',
          required: true,
        },
        {
          property: 'invoiceDate',
          type: 'date',
          label: 'Invoice Date',
          required: true,
        },
        {
          property: 'invoiceQuantity',
          type: 'number',
          label: 'Quantity in Invoice',
          required: true,
          validate: (formData) => {
            if (
              formData.invoiceQuantity !== formData.quantity &&
              formData.allowMismatchedQuantity === 'no'
            ) {
              return {
                invoiceQuantity:
                  'Invoice quantity is not same as actual quantity',
                quantity: 'Actual quantity is not same as invoice quantity',
              };
            }
            return {} as any;
          },
        },
        {
          property: 'quantity',
          type: 'number',
          label: 'Actual Quantity Received',
          required: true,
        },
        {
          property: 'allowMismatchedQuantity',
          label: 'Allow Mismatching quantity',
          type: 'select',
          options: [
            { value: 'yes', text: 'Yes' },
            { value: 'no', text: 'No' },
          ],
        },
        {
          property: 'reasonForMismatchQuantity',
          label: 'Reason for mismatch quantity',
          type: 'input',
          dependentOn(formData) {
            if (formData.allowMismatchedQuantity === 'yes') return true;
            return false;
          },
        },
        {
          property: 'eWayBillNumber',
          type: 'input',
          label: 'E Way bill Number',
          required: true,
        },
        {
          property: 'uom',
          type: 'input',
          label: 'Unit of Measurement',
          required: true,
        },
        {
          property: 'transporter',
          type: 'input',
          label: 'Transporter',
          required: true,
        },

        {
          property: 'lorryReceiptNumber',
          type: 'input',
          label: 'Lorry Receipt Number',
          validate(formData) {
            if (!formData.lorryReceiptNumber && !formData.AWBorBlNumber) {
              return {
                lorryReceiptNumber:
                  'At least LR number or AWB/BL number is mandatory',
                AWBorBlNumber:
                  'At least LR number or AWB/BL number is mandatory',
              };
            }
            return {} as any;
          },
        },
        {
          property: 'AWBorBlNumber',
          type: 'input',
          label: 'Air Way Bill/ Bill of lading Number',
        },

        {
          property: 'girirNumber',
          type: 'input',
          label: 'GIRIR No',
        },

        {
          property: 'sealNumber',
          type: 'input',
          label: 'Seal No',
        },

        {
          property: 'totalAmountPayable',
          type: 'number',
          label: 'Total Amount Payable',
        },
        {
          property: 'arrivedAt',
          type: 'datetime-local',
          label: 'Arrived at',
          required: true,
        },
        { property: 'remarks', type: 'input' },
      ],
      initialFormState: {
        quantity: fulfillment?.details?.quantity || 0,
        invoiceNo: fulfillment?.details?.invoiceNo || '',
        sealNumber: fulfillment?.details?.sealNumber || '',
        totalAmountPayable: fulfillment?.details?.totalAmountPayable || '',
        girirNumber: fulfillment?.details?.girirNumber || '',
        invoiceDate:
          fulfillment?.details?.invoiceDate || moment().format('YYYY-MM-DD'),
        // actualQuantity: 0,
        // eBillNumber: '',
        uom: fulfillment?.details?.uom || '',
        lorryReceiptNumber: fulfillment?.details?.lorryReceiptNumber || '',
        AWBorBlNumber: fulfillment?.details?.AWBorBlNumber || '',
        allowMismatchedQuantity:
          fulfillment?.details?.allowMismatchedQuantity || 'no',
        transporter: fulfillment?.details?.transporter || '',
        invoiceQuantity: fulfillment?.details?.invoiceQuantity || 0,
        remarks: fulfillment?.details?.remarks || '',
        arrivedAt:
          fulfillment?.details?.arrivedAt ||
          moment().format('YYYY-MM-DDTHH:MM'),
      },
      allowUnchanged: true,
      onSubmit: async (formData) => {
        (formData as any).summary = getSummary(
          fulfillmentSummary.total,
          fulfillmentSummary.previouslyAdded,
          Number(formData.quantity)
        );
        const { data: grnName } = fulfillment
          ? { data: fulfillment.name }
          : await generateName({
              pattern: cgs.details.purchase?.grn?.grnNamePattern || '',
              getMany: getAllFulfilments,
            });
        const finalData = {
          id: fulfillment?.id || '',
          purchaseOrder: { id: purchaseOrder.id },
          name: grnName || '',
          status: fulfillment?.status || 'delivered',
          details: {
            quantity: formData.quantity,
            invoiceNo: formData.invoiceNo,
            arrivedAt: formData.arrivedAt,
            attachments: [],
            remarks: formData.remarks,
            isApproved: (formData as any).isApproved || false,
            grnNumber: grnName,
            invoiceQuantity: formData.invoiceQuantity,
            allowMismatchedQuantity: formData.allowMismatchedQuantity,
            uom: formData.uom,
            reasonForMismatchQuantity: formData.reasonForMismatchQuantity || '',
            lorryReceiptNumber: formData.lorryReceiptNumber || '',
            AWBorBlNumber: formData.AWBorBlNumber || '',
            invoiceDate: formData.invoiceDate,
            eWayBillNumber: formData.eWayBillNumber,
            girirNo: formData.girirNumber,
            totalAmountPayable: formData.totalAmountPayable,
            sealNo: formData.sealNumber,
            transporter: { id: '', name: formData.transporter }, // TODO
            supplier: { id: '', name: formData.supplier },
          },
        };
        // const poActivity = {
        //   action: 'fulfilment-added',
        //   details: '',
        //   user: currentUser,
        //   event: `${currentUser.name} added a fulfilment`,
        //   time: moment.utc().format(),
        // };
        try {
          await setFulfillments(
            (finalData as unknown) as PurchaseOrderFulfilment
          );
          // await setPurchaseOrder({
          //   id,
          //   details: {
          //     ...purchaseOrder?.details,
          //     summary: (formData as any).summary,
          //     activity: [poActivity, ...(purchaseOrder?.details.activity || [])],
          //   },
          // } as any);
          getFulfillments({ purchaseOrderId: purchaseOrder.id });
          toast('Added successfully');
        } catch (error) {
          toast((error as any).message);
        }
        onClose();
      },
      mapTToU: (t) => {
        return t as AddFulfillment;
      },
      style: 'md:w-1/3',
      // button: { style: 'w-fit' },
    };

    const Form = renderFormV2<Partial<AddFulfillment>>(formProps);

    return <Form />;
  };
}
