import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { UseCombinedLocationAdmin } from '../../../../hooks/admin/location-admin/use-location';
import { UseCombinedLocationHoliday } from '../../../../hooks/planning/location-holiday/use-location-holiday';
import { UseCombinedShiftSchedule } from '../../../../hooks/planning/shift-schedule/use-shift-schedule';
import { renderLocationHolidaysList } from '../../../planning/location-holidays';
import { renderShiftScheduleV2List } from '../../../planning/shift-schedule';
import { CGConfigRenderer } from '../config-renderer';
import { renderSisterLocationForm } from './forms/sister-location-form';

type CreateInventoryTemplatesPage = {
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
  useCombinedLocationAdmin: UseCombinedLocationAdmin;
  useCombinedShiftSchedule: UseCombinedShiftSchedule;
  useCombinedLocationHoliday: UseCombinedLocationHoliday;
};

export const createCompanyGroupSettingPage = ({
  useCombinedCompanyGroupSetting,
  useCombinedLocationAdmin,
  useCombinedShiftSchedule,
  useCombinedLocationHoliday,
}: CreateInventoryTemplatesPage) => {
  const Card = renderCardComponent();

  return function () {
    const { companyGroup: currentCompanyGroup, cgSetting } = useContext(
      CurrentContext
    );

    type Configs = {
      settingName: string;
      settingValue?: string;
      editAction: () => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'Sister Locations',
        settingValue:
          Object.keys(
            cgSetting?.details?.companyGroup?.location?.sisterLocations || {}
          )
            .map((k) => k)
            .join(', ') || '',
        editAction: () => <></>,
      },
    ];

    const ShiftSchedules = renderShiftScheduleV2List({
      useCombinedShiftSchedule,
    });

    const LocationHolidays = renderLocationHolidaysList({
      useCombinedLocationHoliday,
    });

    return (
      <div className='w-full'>
        <Card
          header={{ title: 'Company-Group Config' }}
          body={{
            type: 'jsx-with-behaviour',
            body: {
              content: <CGConfigRenderer configs={configs} loading={false} />,
              behaviour: 'modal',
              modal: {
                title: 'Configure Sister Locations',
                content: ({ onClose }) => {
                  const Form = renderSisterLocationForm({
                    closeModal: onClose,
                    useCombinedCompanyGroupSetting,
                    useCombinedLocationAdmin,
                    refreshData: () => {},
                  });
                  return (
                    <Form
                      companyGroupId={currentCompanyGroup.id}
                      comGrpSetDetails={cgSetting?.details || {}}
                    />
                  );
                },
              },
            },
          }}
        />

        <ShiftSchedules />
        <LocationHolidays />
      </div>
    );
  };
};
