import {
  PolicyFilter,
  PolicyType,
  PremiumsFilter,
  PremiumsType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { PolicyHttpRepo } from '../datalayer/repository/http/hrd/insurance/policy';
import { PremiumsHttpRepo } from '../datalayer/repository/http/hrd/insurance/premiums';
import { PolicyInterface } from '../models/interfaces/hrd/insurance/policy';
import { PremiumsInterface } from '../models/interfaces/hrd/insurance/premiums';
import { erpV2Api } from './admin';
import { INSURANCE_SERVER_URI } from './server-uri';

export const insurancePolicyService = new PolicyHttpRepo(
  INSURANCE_SERVER_URI,
  erpV2Api
);
export const useCombinedPolicy = createUseCombinedResource<
  PolicyType,
  PolicyFilter,
  PolicyInterface
>({
  useService: () => insurancePolicyService,
});

export const insurancePremiumService = new PremiumsHttpRepo(
  INSURANCE_SERVER_URI,
  erpV2Api
);

export const useCombinedPremium = createUseCombinedResource<
  PremiumsType,
  PremiumsFilter,
  PremiumsInterface
>({
  useService: () => insurancePremiumService,
});
