import { ErpV2Api } from '../../../../../../models/interfaces/adapters/erp-v2-api';
import {
  LeadEnquiryFilterType,
  LeadEnquiryType,
} from '../../../../../../models/types/sales/lead-enquiry';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class HttpLeadEnquiryRepo extends GenericHttpWithLogsRepo<
  LeadEnquiryType,
  LeadEnquiryFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/sales/lead-enquiry',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
