import { SalesQcOrder } from '../../../../models/types/sales/qc-order';

export const SalesQcOrderDummyData: SalesQcOrder[] = [
  {
    id: 'ii',
    name: 'ENQ-21/21/21',
    respondBy: '22-02-2022',
    client: 'Reliance',
    items: [{ name: 'steel' }, { name: 'iron' }],
    remarks: 'must meet exact specs',
    attachments: ['email copy', 'previous transactions'],
    createdBy: 'Sarit chauhan',
    status: '3/4 pending',
  },
  {
    id: 'ii',
    name: 'ENQ-21/21/21',
    respondBy: '22-02-2022',
    client: 'Reliance',
    items: [{ name: 'steel' }, { name: 'iron' }],
    remarks: 'must meet exact specs',
    attachments: ['email copy', 'previous transactions'],
    createdBy: 'Sarit chauhan',
    status: '3/4 pending',
  },
];
