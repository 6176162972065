/* eslint-disable no-unused-vars */
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { CompanyGroupSetting } from '@erp_core/erp-types/dist/modules/admin';
import { FormProps, renderFormV2 } from '@erp_core/erp-ui-components';
import { UseCombinedCompanyGroupSetting } from '../../../../../hooks/admin/company-group-setting/use-company-group-setting';

export type RenderEditSENamePatternFormProps = {
  closeModal: () => void;
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
  comGrpSet?: CompanyGroupSetting;
};

export function renderEditSENamePatternForm({
  closeModal,
  comGrpSet,
  useCombinedCompanyGroupSetting,
}: RenderEditSENamePatternFormProps): () => JSX.Element {
  return function (): JSX.Element {
    const {
      syncSet: setCompanyGroupSetting,
    } = useCombinedCompanyGroupSetting();
    if (!comGrpSet) return <Recat className='h-5 inline animate-pulse mx-4' />;
    const pattern =
      comGrpSet?.details?.sales?.salesEnquiry?.seNamePattern || '';
    const save = async (form) => {
      const finalData: Partial<CompanyGroupSetting> = {
        id: comGrpSet.id,
        details: {
          ...comGrpSet.details,
          sales: {
            ...comGrpSet.details?.sales,
            salesEnquiry: {
              ...comGrpSet.details?.sales?.salesEnquiry,
              seNamePattern: form.pattern,
            },
          },
        },
      };
      await setCompanyGroupSetting(finalData as CompanyGroupSetting);
      closeModal();
    };

    const guidelines = [
      {
        title: 'YYYY',
        description: 'full year like 2022',
      },
      {
        title: 'YY',
        description: 'year like 22',
      },
      {
        title: 'FYYYY',
        description: 'full financial year like 2022-2023',
      },
      {
        title: 'FYY',
        description: 'financial year like 22-23',
      },
      {
        title: 'MM',
        description: 'Month like 12, for this YYYY or YY is required',
      },
      {
        title: 'DD',
        description: 'Day like 02, for this (YYYY or YY) and MM is required',
      },
      {
        title: 'INC',
        description: 'will add +1 to last added name',
      },
    ];

    const formProps: FormProps<any> = {
      fieldsData: [
        {
          property: 'pattern',
          label: 'pattern',
          type: 'input',
          required: true,
        },
      ],
      initialFormState: {
        pattern,
      },
      mapTToU: (d) => {
        return d;
      },
      style: '',
      onSubmit: save,
    };

    const Form = renderFormV2<any>(formProps);

    return (
      <div>
        <Form />
        <div className='italic'>Example Patterns: {'SE-%{YYYY}-%{INC}'}</div>
        <div className='font-bold text-md mt-1'> Guidelines</div>
        {guidelines.map((g, index) => {
          return (
            <div key={index} className='flex'>
              <div className='mr-4 font-bold'> {g.title}</div>
              {g.description}
            </div>
          );
        })}
      </div>
    );
  };
}
