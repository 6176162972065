import { ItemRes } from '@erp_core/erp-types/dist/modules/inventory';
import { downloadFile } from '@erp_core/erp-ui-components';
import axios from 'axios';
import { FILE_MANAGER_SERVER_URI } from '../../../../config/server-uri';

export const downloadItemMsdS = async ({
  companyGroupId,
  item,
  getSpecTemplate,
}: {
  companyGroupId: string;
  item: ItemRes;
  getSpecTemplate: Function;
}) => {
  if (!item || !item.id) return;

  // Fetching MSDS template for the item
  let html = await getSpecTemplate(
    `${companyGroupId}/msds-template/${item.id.toLowerCase()}.html`
  );

  // If not found sds template then use default template from company group settings
  if (!html)
    html = await getSpecTemplate(
      `${companyGroupId}/templates/msds-sheet-template.html`
    );

  const axiosInstance = axios.create({
    baseURL: FILE_MANAGER_SERVER_URI,
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
    },
  });

  const response = await axiosInstance.post('/pdf', {
    template: html,
    data: {
      name: item.name,
      description: item.description,
      status: item.status,
      category: item.category,
      ...item.details,
    },
  });

  const fType = 'application/pdf';
  const fName = `${item.name}-msds-sheet.pdf`;
  const res = response.data;

  downloadFile({ fileType: fType, fileName: fName, result: res });
};
