import { LeadCustomerType } from '@erp_core/erp-types/dist/modules/order';
import { UnderConstruction } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { renderLeadCustomerContact } from '../lead-customer-contact';
import { renderLeadCustomerProfileInfo } from '../lead-customer-profile-info';

export type LeadCustomerProfileProp = {
  leadCustomer: LeadCustomerType;
  loading: boolean;
  setLeadCustomer: (s: LeadCustomerType) => Promise<LeadCustomerType>;
  usePublicTransfer: UsePublicTransfer;
};

export function renderLeadCustomerProfileTabs(): (
  x: LeadCustomerProfileProp
) => JSX.Element {
  return function LeadCustomerProfileTabs({
    leadCustomer,
    setLeadCustomer,
    loading,
    usePublicTransfer,
  }: LeadCustomerProfileProp): JSX.Element {
    const [currentTab, setCurrentTab] = useState<string>('');
    const [tabs, setTabs] = useState<Array<string>>([]);
    const [body, setBody] = useState<JSX.Element>(<div></div>);

    useEffect(() => {
      let tabs: Array<string> = [];

      tabs = ['Basic Info', 'Contacts'];
      setTabs(tabs);

      if (tabs.length) {
        setCurrentTab(tabs[0]);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (currentTab) {
        let Props = <div></div>;

        switch (currentTab) {
          case 'Basic Info': {
            const Profile = renderLeadCustomerProfileInfo({
              usePublicTransfer,
              leadCustomer,
              setLeadCustomer,
              loading,
            });
            Props = <Profile />;
            break;
          }

          case 'Contacts': {
            const Contact = renderLeadCustomerContact({
              leadCustomer,
              setLeadCustomer,
            });
            Props = <Contact />;
            break;
          }

          default: {
            Props = <UnderConstruction />;
          }
        }

        setBody(Props);
      }
      // eslint-disable-next-line
    }, [currentTab]);

    const selectedTabStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 bg-white shadow text-blue-700 border-b-2 border-b-blue-600';
    const nonSelectedStyle =
      'cursor-pointer py-2.5 focus-visible:outline-0 font-medium leading-5 text-gray-500 bg-white px-2 hover:bg-white/[0.12] hover:border-b-2 hover:border-b-gray-400 hover:text-blue-700';

    return (
      <div>
        <div className='flex w-full'>
          {tabs.map((x, idx) => (
            <div
              key={x || idx}
              onClick={() => {
                if (x !== currentTab) {
                  setCurrentTab(x);
                }
              }}
              className={currentTab === x ? selectedTabStyle : nonSelectedStyle}
            >
              {x}
            </div>
          ))}
        </div>
        <div className='w-full'>
          <div className='w-full flex flex-wrap'>{body}</div>
        </div>
      </div>
    );
  };
}
