import { HazardPrecaution } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type AddGhsPrecautionaryStatementFormProps = {
  onSave: (data: Partial<HazardPrecaution>) => Promise<void>;
};

// eslint-disable-next-line
export function renderAddGhsPrecautionaryStatementForm(): (
  data: AddGhsPrecautionaryStatementFormProps
) => JSX.Element {
  return function AddGhsPrecautionaryStatementForm({
    onSave,
  }: AddGhsPrecautionaryStatementFormProps): JSX.Element {
    const formProps: FormProps<Partial<HazardPrecaution>> = {
      fieldsData: [
        {
          property: 'code',
          type: 'input',
          required: true,
          label: 'Statement Code',
        },
        {
          property: 'statement',
          required: true,
          type: 'input',
          label: 'Statement',
        },
      ],
      initialFormState: {
        code: '',
        statement: '',
      },
      onSubmit: onSave,
      mapTToU: mapFFDToF,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<Partial<HazardPrecaution>>(formProps);

    return <Form />;

    function mapFFDToF(
      b: FormDataType<Partial<HazardPrecaution>>
    ): HazardPrecaution {
      return (b as unknown) as HazardPrecaution;
    }
  };
}
