import {
  EmployeeProfileType,
  LoanType,
} from '@erp_core/erp-types/dist/modules/hrd';
import { downloadFile } from '@erp_core/erp-ui-components';
import {
  ArrowDownTrayIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { createResourceReports } from '../utils/loan-report';

type LoanReportType = {
  data: Array<{
    employee: EmployeeProfileType;
    resource: LoanType;
  }>;
};

export function createLoanReportsModal(): (
  data: LoanReportType
) => JSX.Element {
  return function LoanReports({ data }: LoanReportType): JSX.Element {
    const reports = createResourceReports(data);

    return (
      <div>
        <div className='text-center'>Loan Reports</div>
        <div>
          {reports.map((x) => (
            <div key={x.name} className='border border-gray-200 p-2 m-0.5'>
              <div className='flex'>
                <div className='flex-none'>
                  <DocumentTextIcon className='w-5 inline mr-2' />
                  {x.name}
                </div>
                <div className='flex-auto '>
                  <ArrowDownTrayIcon
                    className='cursor-pointer inline w-4 float-right'
                    onClick={() => {
                      downloadFile({
                        fileType: 'application/csv',
                        fileName: x.name,
                        result: x?.data,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
}
