/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import {
  SalaryBonus,
  SalaryBonusFilter,
} from '@erp_core/erp-types/dist/types/modules/hrd/salary-bonus';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { GenericHttpWithLogsRepo } from '../generic-resource-with-logs';

export class SalaryBonusHttpRepo extends GenericHttpWithLogsRepo<
  SalaryBonus,
  SalaryBonusFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/salary-bonus',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
