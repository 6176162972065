import { LoadingButton } from '@erp_core/erp-ui-components';

export type VersionUpdaterProps = {
  version: string;
  updateVersion: (val: string) => void;
};

export function renderVersionUpdater(): (
  p: VersionUpdaterProps
) => JSX.Element {
  return function Version({
    version,
    updateVersion,
  }: VersionUpdaterProps): JSX.Element {
    console.log('rendering version updater');
    return (
      <div>
        Version from Version Updater : {version}
        <LoadingButton
          defaultStyle='bg-green-500 text-white p-2 border rounded-lg'
          behaviorFn={async () => {
            updateVersion(String(parseInt(version) + 1));
          }}
          text='Increment'
        />
      </div>
    );
  };
}
