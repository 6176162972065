/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBody,
  TableHeader,
} from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { SalesManufacturingOrder } from '../../../models/types/sales/manufacturing-order';
import { SalesManufacturingOrderDummyData } from './dummyData';

function renderSalesManufacturingOrderDetails() {
  return function SalesManufacturingOrderDetails() {
    // eslint-disable-next-line
    const [data, setData] = useState<SalesManufacturingOrder[]>();

    const getData = async () => {
      try {
        const resp = SalesManufacturingOrderDummyData;
        if (resp) {
          setData(resp);
        }
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      getData();
    }, []);

    const Table = renderTableComponent();
    const tableHeader: TableHeader = [
      [
        { name: 'Item' },
        { name: 'Manufacture By' },
        { name: 'Quantity' },
        { name: 'Specs' },
        { name: 'remarks' },
        { name: 'Attachments' },
        { name: 'Status' },
      ],
    ];

    const tableData =
      data?.map((item) => ({
        cells: [
          { value: item.itemDetails.name },
          { value: item.manufactureBy },
          { value: item.itemDetails.quantity },
          { value: item.itemDetails.specs },
          { value: item.remarks },
          {
            value: (
              <div>
                {item.attachments?.map((a, i) => (
                  <a key={i}>{a}, </a>
                ))}
              </div>
            ),
          },
          { value: 'status' },
        ],
        rowData: {
          salesManufacturingOrder: item,
        },
      })) || [];

    const tableBody: TableBody = tableData;

    const Card = renderCardComponent();
    const cardHeader: CardHeader = {
      title: 'Sales Manufacturing Orders',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <Table
          header={tableHeader}
          body={tableBody}
          actions={[
            {
              name: 'Create BOM',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Create BOM',
                content: ({ data, onClose }) => {
                  return <div>Create BOM</div>;
                },
              },
            },
            {
              name: 'Mark Done',
              show: () => true,
              behaviour: 'modal',
              modal: {
                title: 'Mark Done',
                content: ({ data, onClose }) => {
                  return <div>Mark Done</div>;
                },
              },
            },
          ]}
        />
      ),
    };

    return (
      <div>
        <Card header={cardHeader} body={cardBody} />
      </div>
    );
  };
}

export default renderSalesManufacturingOrderDetails;
