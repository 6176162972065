import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import moment from 'moment';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { validateGpTimeFormat } from '../util/validateTime';

type GatePassType = {
  date: string;
  name: string;
  type: string;
  employee: string;
  exitTime: string;
  entryTime: string;
  reason: string;
};

type RenderGatePassForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
};

export const renderGatePassForm = ({
  useCombinedEmployeeProfile,
}: RenderGatePassForm) => {
  return function GatePassForm({
    currentUser,
    onSave,
    data,
    isEdit,
  }: {
    currentUser?: boolean;
    onSave: any;
    data?: GatePassType;
    isEdit?: boolean;
  }) {
    const formProps: FormProps<GatePassType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            onSearchValueSelect: () => {},
            filter: { crossGroup: 'true' },
          },
          required: true,
          label: 'Employee Name',
          readonly: isEdit || currentUser,
        },
        {
          property: 'name',
          type: 'input',
          required: true,
          label: 'Gate pass no.',
          readonly: isEdit,
        },
        {
          property: 'type',
          type: 'select',
          required: true,
          label: 'Type',
          options: [
            { text: 'Select', value: '' },
            { text: 'personal', value: 'personal' },
            { text: 'professional', value: 'professional' },
          ],
        },
        {
          property: 'date',
          type: 'date',
          required: true,
          label: 'Date',
          readonly: isEdit,
        },

        {
          property: 'exitTime',
          type: 'input',
          required: true,
          label: 'Exit time',
          validate: async (fd: any) => {
            return {
              exitTime: validateGpTimeFormat(fd.exitTime),
            };
          },
        },
        {
          property: 'entryTime',
          type: 'input',
          required: true,
          label: 'Entry time',
          validate: async (fd: any) => {
            return {
              entryTime: validateGpTimeFormat(fd.entryTime, fd.exitTime),
            };
          },
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
      ],
      initialFormState: {
        date: data?.date || '',
        name: data?.name || `GP-${moment().format('YYYYMMDD-HHmm')}`,
        type: data?.type || '',
        exitTime: data?.exitTime ? data.exitTime : '',
        entryTime: data?.entryTime ? data.entryTime : '',
        reason: data?.reason || '',
        employee: data?.employee || '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<GatePassType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<GatePassType>): GatePassType {
      return {
        name: b.name || data?.name,
        type: b.type || data?.type,
        date: b.date || data?.date,
        exitTime: b.exitTime || data?.exitTime,
        entryTime: b.entryTime || data?.entryTime,
        reason: b.reason || data?.reason,
        employee: b.employee || data?.employee,
      } as any;
    }
  };
};
