import { AttendanceType, Punch } from '@erp_core/erp-types/dist/modules/hrd';
import { sleep } from '@erp_core/erp-utils';

export async function deleteManualPunch({
  d,
  p,
  setAttendance,
  getData,
}: {
  d: AttendanceType;
  p: Punch;
  setAttendance: (s: AttendanceType) => Promise<AttendanceType>;
  getData: () => Promise<void>;
}) {
  const punches = d.details?.punches.filter((x) => x.timeStamp !== p.timeStamp);
  // remove attendance from current day
  const finalData: AttendanceType = {
    id: d.id,
    details: {
      punches: punches,
    },
  } as AttendanceType;

  if (d.finalized) {
    return;
    // console.log('attmepting to delete a finalized record punch');
    // finalData.finalized = true;
  }
  await setAttendance(finalData);
  await sleep(2000);
  getData();
}
