import { RevisionType } from '@erp_core/erp-types/dist/modules/inventory';
import { ItemProperty } from '@erp_core/erp-types/dist/types/modules/inventory/item-property';
import {
  LoadingButton,
  renderTableComponent,
  TableBody,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import { useState } from 'react';
import { UseCombinedAppearance } from '../../../../hooks/admin/constants/appearance/use-appearance';
import { UseCombinedColor } from '../../../../hooks/admin/constants/color/use-color';
import { UseCombinedGST } from '../../../../hooks/admin/constants/gst/use-gst-paginations';
import { UseCombinedMetric } from '../../../../hooks/admin/constants/metrics/use-metric';
import { UseCombinedOdour } from '../../../../hooks/admin/constants/odour/use-odour';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';
import { GetComplexPropertyEditor } from './common';
import { EditPropertyValProps } from './types';

export const renderEditComplexArrayObjectPropertyChangeReq = ({
  useCombinedMetric,
  useCombinedGST,
  useCombinedAppearance,
  useCombinedColor,
  useCombinedOdour,
  useCombinedEmployeeProfile,
}: {
  useCombinedMetric: UseCombinedMetric;
  useCombinedGST: UseCombinedGST;
  useCombinedOdour: UseCombinedOdour;
  useCombinedColor: UseCombinedColor;
  useCombinedAppearance: UseCombinedAppearance;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
}) => {
  const Table = renderTableComponent();
  return function EditComplexArrayObjectPropertyChangeReq({
    itemProperty,
    currentValue,
    onSave,
    entityId,
    type,
  }: EditPropertyValProps & {
    type: RevisionType;
  }) {
    const [newValues, updateNewValues] = useState<{
      headerRows: Array<any>;
      variableRows: Array<any>;
      trailerRows: Array<any>;
    }>(createInitialValue(itemProperty, currentValue));

    const [reason, setReason] = useState<string>();

    let currentList: any = JSON.parse(JSON.stringify(currentValue)) || [];
    if (!Array.isArray(currentList)) {
      currentList = [];
    }
    const HeaderBody: TableBody =
      itemProperty.complexArrayObjectProperties &&
      itemProperty.complexArrayObjectProperties.headerRows.length
        ? itemProperty.complexArrayObjectProperties.headerRows.map(
            (headerRow, idx) => {
              return {
                cells: headerRow.map((prop, propIndex) => {
                  const value =
                    (newValues.headerRows[idx] &&
                      newValues.headerRows[idx][prop.name]) ||
                    '-';
                  if (itemProperty.complexArrayObjectProperties) {
                    // if its a fixed type. hence we make it editable only for
                    // current level renderer
                    // if (entity !== itemProperty.owner?.id) {
                    //   return {
                    //     value: (
                    //       <div>
                    //         <div className='italic font-semibold'>
                    //           {prop.name}:
                    //         </div>
                    //         <div>{value}</div>
                    //         <div className='italic font-xs text-gray-500'>
                    //           [readonly]
                    //         </div>
                    //       </div>
                    //     ),
                    //   };
                    // }
                    return {
                      value: (
                        <div>
                          <div>
                            New Value:{' '}
                            <GetComplexPropertyEditor
                              entityId={entityId}
                              setNewValues={(abc) => {
                                const nv = JSON.parse(
                                  JSON.stringify(newValues)
                                );

                                if (!nv.headerRows[idx]) {
                                  nv.headerRows[idx] = {};
                                }
                                nv.headerRows[idx][prop.name] = abc;

                                updateNewValues(nv);
                              }}
                              itemProperty={{
                                ...prop,
                                owner: itemProperty.owner,
                              }}
                              keyProp={prop.name}
                              val={value}
                              useCombinedGST={useCombinedGST}
                              useCombinedMetric={useCombinedMetric}
                              useCombinedAppearance={useCombinedAppearance}
                              useCombinedColor={useCombinedColor}
                              useCombinedOdour={useCombinedOdour}
                              useCombinedEmployeeProfile={
                                useCombinedEmployeeProfile
                              }
                            />
                          </div>
                        </div>
                      ),
                    };
                  }
                  return {
                    value: <>-</>,
                  };
                }),
              };
            }
          )
        : [];

    const VariableBody: TableBody = newValues.variableRows.map(
      (row, rowIdx) => {
        return {
          cells: itemProperty.complexArrayObjectProperties.variableRow.map(
            (prop) => {
              const value = row[prop.name] || '-';
              if (itemProperty.complexArrayObjectProperties) {
                // if its a fixed type. hence we make it editable only for
                // current level renderer
                if (entityId === itemProperty.owner?.id) {
                  return {
                    value: (
                      <div>
                        <div className='italic font-semibold'>{prop.name}:</div>
                        <div>
                          {typeof value === 'object'
                            ? JSON.stringify(value)
                            : value}
                        </div>
                        <div className='italic font-xs text-gray-500'>
                          [readonly]
                        </div>
                      </div>
                    ),
                  };
                }
                return {
                  value: (
                    <div>
                      <div>
                        New Value:{' '}
                        <GetComplexPropertyEditor
                          entityId={entityId}
                          setNewValues={(abc) => {
                            const nv = JSON.parse(JSON.stringify(newValues));
                            nv.variableRows[rowIdx][prop.name] = abc;

                            updateNewValues(nv);
                          }}
                          itemProperty={{ ...prop, owner: itemProperty.owner }}
                          keyProp={prop.name}
                          val={value}
                          useCombinedGST={useCombinedGST}
                          useCombinedMetric={useCombinedMetric}
                          useCombinedAppearance={useCombinedAppearance}
                          useCombinedColor={useCombinedColor}
                          useCombinedOdour={useCombinedOdour}
                          useCombinedEmployeeProfile={
                            useCombinedEmployeeProfile
                          }
                        />
                      </div>
                    </div>
                  ),
                };
              }
              return {
                value: <>-</>,
              };
            }
          ),
        };
      }
    );

    const TrailerBody: TableBody =
      itemProperty.complexArrayObjectProperties &&
      itemProperty.complexArrayObjectProperties.trailerRows.length
        ? itemProperty.complexArrayObjectProperties.trailerRows.map(
            (headerRow, idx) => {
              return {
                cells: headerRow.map((prop, propIndex) => {
                  const value =
                    (newValues.trailerRows[idx] &&
                      newValues.trailerRows[idx][prop.name]) ||
                    '-';
                  if (itemProperty.complexArrayObjectProperties) {
                    // if its a fixed-array-object type then first value is fixed. hence we make it editable only for
                    // current level renderer
                    // if (entity !== itemProperty.owner?.id) {
                    //   return {
                    //     value: (
                    //       <div>
                    //         <div className='italic font-semibold'>
                    //           {prop.name}:
                    //         </div>
                    //         <div>{value}</div>
                    //         <div className='italic font-xs text-gray-500'>
                    //           [readonly]
                    //         </div>
                    //       </div>
                    //     ),
                    //   };
                    // }
                    return {
                      value: (
                        <div>
                          <div>
                            New Value:{' '}
                            <GetComplexPropertyEditor
                              entityId={entityId}
                              setNewValues={(abc) => {
                                const nv = JSON.parse(
                                  JSON.stringify(newValues)
                                );

                                if (!nv.trailerRows[idx]) {
                                  nv.trailerRows[idx] = {};
                                }
                                nv.trailerRows[idx][prop.name] = abc;

                                updateNewValues(nv);
                              }}
                              itemProperty={{
                                ...prop,
                                owner: itemProperty.owner,
                              }}
                              keyProp={prop.name}
                              val={value}
                              useCombinedGST={useCombinedGST}
                              useCombinedMetric={useCombinedMetric}
                              useCombinedAppearance={useCombinedAppearance}
                              useCombinedColor={useCombinedColor}
                              useCombinedOdour={useCombinedOdour}
                              useCombinedEmployeeProfile={
                                useCombinedEmployeeProfile
                              }
                            />
                          </div>
                        </div>
                      ),
                    };
                  }
                  return {
                    value: <>-</>,
                  };
                }),
              };
            }
          )
        : [];

    const TableBody: TableBody = [
      {
        cells: [
          {
            value: 'Header Rows',
            style: 'bg-green-100 text-center italic font-semibold',
            colSpan:
              _.first(
                itemProperty?.complexArrayObjectProperties?.headerRows || []
              )?.length || 1,
          },
        ],
      },
      ...HeaderBody,
      {
        cells: [
          {
            value: 'Variable Rows',
            style: 'bg-green-100  text-center italic font-semibold',
            colSpan:
              itemProperty.complexArrayObjectProperties?.variableRow.length - 1,
          },
          {
            value: (
              <LoadingButton
                defaultStyle='bg-green-500 text-white'
                behaviorFn={async () => {
                  console.log(newValues);
                  const nv = JSON.parse(JSON.stringify(newValues));
                  nv.variableRows.push({});
                  updateNewValues(nv);
                }}
                text='Add'
              />
            ),
          },
        ],
      },
      ...VariableBody,
      {
        cells: [
          {
            value: 'Trailer Rows',
            style: 'bg-green-100  text-center italic font-semibold',
            colSpan:
              _.first(
                itemProperty?.complexArrayObjectProperties?.trailerRows || []
              )?.length || 1,
          },
        ],
      },
      ...TrailerBody,
    ];

    return (
      <div>
        <div className='flex'>
          <div className='text-center flex-auto'>
            <b>Type:</b> {itemProperty.type}
          </div>
          <div className='text-center flex-auto'>
            <b>Edit Property:</b> {itemProperty.name}
          </div>
          <div className='text-center flex-auto'>
            <b>Property Ownership: </b>{' '}
            {entityId === itemProperty.owner?.id
              ? 'Owned at Current Level'
              : 'Not owned at Current Level'}
          </div>
          <div className='flex-none'>
            {['fixed-array-object'].includes(itemProperty.type) &&
            entityId === itemProperty.owner?.id ? (
              <LoadingButton
                defaultStyle='bg-green-500 text-white'
                behaviorFn={async () => {
                  updateNewValues((x) => {
                    console.log(x);
                    return x;
                  });
                  // setNewValues((nv) => [...nv, ...[{}]]);
                }}
                text='Add'
              />
            ) : null}
          </div>
        </div>

        <Table
          header={[
            [
              {
                colSpan: (
                  itemProperty.complexArrayObjectProperties.variableRow || []
                ).length,
                name: 'Properties',
                style: 'text-center',
              },
            ],
            (itemProperty.complexArrayObjectProperties.variableRow || []).map(
              (prop) => {
                return {
                  name:
                    prop.type === 'object' ? (
                      <div>
                        <div className='border border-gray-300'>
                          {prop.name}
                        </div>
                        <div className='flex'>
                          {prop.childrenProperties &&
                            _.keys(prop.childrenProperties).map((childKey) => (
                              <div
                                className={`border border-gray-300 w-1/${
                                  _.keys(prop.childrenProperties).length
                                }`}
                                key={childKey}
                              >
                                {childKey}
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      prop.name
                    ),
                  style: 'text-center',
                };
              }
            ),
          ]}
          body={TableBody}
        />
        <div className='flex justify-center'>
          <label className='font-bold '>Reason : </label>{' '}
          <input
            type='text'
            className='border border-gray text-left h-50'
            onChange={(evt) => {
              setReason(evt.target.value);
            }}
          />
        </div>
        <div className='flex justify-center'>
          <LoadingButton
            behaviorFn={async () => {
              await onSave({
                reason: reason || '',
                changeRequest: {
                  currentValue: {
                    value: {
                      data: currentValue,
                    },
                  },
                  newValue: {
                    value: {
                      data: newValues,
                    },
                  },
                },
              });
            }}
            defaultStyle='bg-green-500 text-white p-1'
            text='Save'
          />
        </div>
      </div>
    );
  };
};

function createInitialValue(
  itemProperty: ItemProperty,
  currentValue
): {
  headerRows: Array<any>;
  variableRows: Array<any>;
  trailerRows: Array<any>;
} {
  const finalValue = {
    ...{
      headerRows: [],
      variableRows: [],
      trailerRows: [],
    },
    ...currentValue,
  };

  itemProperty.complexArrayObjectProperties?.headerRows?.forEach(
    (row, rowIndex) => {
      if (finalValue.headerRows.length < rowIndex + 1) {
        finalValue.headerRows.push({});
      }
    }
  );

  itemProperty.complexArrayObjectProperties?.trailerRows?.forEach(
    (row, rowIndex) => {
      if (finalValue.trailerRows.length < rowIndex + 1) {
        finalValue.trailerRows.push({});
      }
    }
  );

  return finalValue;
}
