import {
  AdjustmentsHorizontalIcon,
  BeakerIcon,
  BriefcaseIcon,
  CpuChipIcon,
} from '@heroicons/react/24/outline';

export const BomIcon: { [key: string]: JSX.Element } = {
  'M-BOM': (
    <AdjustmentsHorizontalIcon className='inline w-6 h-6 text-red-500' />
  ),
  'A-BOM': <BriefcaseIcon className='inline w-6 h-6 text-orange-500' />,
  'P-BOM': <BeakerIcon className='inline w-6 h-6 text-blue-500' />,
  'F-BOM': <CpuChipIcon className='inline w-6 h-6 text-purple-500' />,
};

export const BomPriorityColor: { [key: string]: string } = {
  high: 'text-red-800 fill-red-200',
  medium: 'text-yellow-800 fill-yellow-200',
  low: 'text-green-800 fill-green-200',
};
