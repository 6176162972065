/* eslint-disable class-methods-use-this */
import { ResetOtpRequest } from '@erp_core/erp-types/dist/modules/admin';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { ResetPasswordInterface } from '../../../../models/interfaces/reset-password';

export class HttpResetPasswordRepo implements ResetPasswordInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  // eslint-disable-next-line no-unused-vars
  async resetPassword(a: ResetOtpRequest): Promise<void> {
    const httpInstance = await this.erpv2Api.getPlainInstance({
      apiUrl: this.apiUrl,
    });

    const payload = {
      email: a.email,
      domain: a.domain,
    };

    const res = await httpInstance.post(
      '/api/v1/users/reset-password',
      payload
    );

    if (res.status !== 200) {
      throw new Error('reset failed');
    }
    toast(`${res.data.message}`);
  }
}
