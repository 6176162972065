import { ExcelDownload } from '@erp_core/erp-icons/icons/web/excel-download';
import { Company } from '@erp_core/erp-types/dist/modules/admin';
import { EmployeeProfileType } from '@erp_core/erp-types/dist/modules/hrd';
import { SalaryParamType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-param';
import { SalaryRevisionType } from '@erp_core/erp-types/dist/types/modules/payroll/salary-revision';
import {
  renderBulkDownload,
  XlsxDownloadMapper,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useState } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { SalaryInterface } from '../../../../models/interfaces/hrd/salary';
import { findParamType, formatParam } from '../utils/generate-salary';

type Report = {
  employee: string;
  employeeId: string;
  salaryRevision: string;
  employeeTakeHome: string;
  adjustedPayableDays: string;
  employeeGrossEarnings: string;
  salaryGenerated: string;
  reason: string;
  ctcAnnual: string;
  totalCtc: string;
  esic: string;
};

export function BulkSimulate({
  filteredEmployees,
  date,
  salaryRevisions,
  selectedParams,
  currentCompany,
  salaryService,
}: {
  currentCompany: Company;
  filteredEmployees: EmployeeProfileType[];
  date;
  salaryRevisions: SalaryRevisionType[];
  selectedParams: SalaryParamType[];
  salaryService: SalaryInterface;
}): JSX.Element {
  const [mode, setMode] = useState<'real' | 'simulate'>('real');
  const { cgSetting } = useContext(CurrentContext);

  const report: XlsxDownloadMapper = [
    { columnName: 'employee', dataBinding: { property: 'employee' } },
    {
      columnName: 'employeeId',
      dataBinding: { property: 'employeeId' },
    },
    {
      columnName: 'salaryRevision',
      dataBinding: { property: 'salaryRevision' },
    },
    {
      columnName: 'salaryGenerated',
      dataBinding: { property: 'salaryGenerated' },
    },
    {
      columnName: 'reason',
      dataBinding: { property: 'reason' },
    },
    {
      columnName: 'employeeTakeHome',
      dataBinding: { property: 'employeeTakeHome' },
    },
    {
      columnName: 'employeeGrossEarnings',
      dataBinding: { property: 'employeeGrossEarnings' },
    },
    {
      columnName: 'adjustedPayableDays',
      dataBinding: { property: 'adjustedPayableDays' },
    },
    {
      columnName: 'totalCtc',
      dataBinding: { property: 'totalCtc' },
    },
    {
      columnName: 'ctcAnnual',
      dataBinding: { property: 'ctcAnnual' },
    },
    {
      columnName: 'esic',
      dataBinding: { property: 'esic' },
    },
  ];

  async function downloadReport() {
    const eligibleEmployees: Report[] = [];
    const ineligibleEmployees: EmployeeProfileType[] = [];

    for (const employee of filteredEmployees) {
      try {
        const selectedParam = selectedParams.find(
          (x) => x.name === employee.details.salaryParam?.name
        );

        const selectedSalRev = _.chain(salaryRevisions || [])
          .filter(
            (x) =>
              x.employee.id.toLowerCase() === employee.id.toLowerCase() &&
              x.status === 'published' &&
              moment(x.date).isSameOrBefore(moment(`${date}-01`))
          )
          .sort((a, b) => {
            return parseInt(b.revisionNum) - parseInt(a.revisionNum);
          })
          .first()
          .value();

        if (!selectedParam) {
          throw new Error('No Salary Param Selected');
        }

        if (!selectedSalRev) {
          throw new Error('No Salary Revision Selected');
        }

        const finalSalaryParams = await salaryService
          .calculateSalary({
            employeeId: employee.id,
            month: moment(date).format('YYYY-MM'),
            salaryRevisionId: selectedSalRev.id,
            salaryParamId: selectedParam.id,
            mode: mode,
            companyGroupSetting: cgSetting,
            salaryUntil: null,
          })
          .then((res) => {
            if (res.salary?.salaryParams) {
              return res.salary?.salaryParams;
            }

            return null;
          });

        if (!finalSalaryParams) {
          throw new Error('Failed to Generate Salary');
        }

        const esicApplicable =
          selectedSalRev?.details?.esicApplicability?.data?.value || '';

        eligibleEmployees.push({
          employee: employee.name,
          employeeId: employee.details.employeeId,
          salaryRevision: selectedSalRev.name,
          salaryGenerated: 'yes',
          employeeTakeHome: formatParam({
            value: finalSalaryParams.earning.employeeTakeHome,
            metric: 'number',
            scale: findParamType(
              'employeeTakeHome',
              selectedParam.details.inner,
              'scale'
            ),
          }),
          // finalSalaryParams.earning.employeeTakeHome as string,
          employeeGrossEarnings: finalSalaryParams.earning
            .grossEarnings as string,
          adjustedPayableDays: finalSalaryParams.other
            .adjustedPayableDays as string,
          ctcAnnual: finalSalaryParams.earning.ctcAnnual,
          totalCtc: finalSalaryParams.earning.totalCtc,
          reason: '',
          esic: esicApplicable,
        });
      } catch (err) {
        eligibleEmployees.push({
          employee: employee.name,
          employeeId: employee.details.employeeId,
          salaryRevision: '',
          employeeTakeHome: '',
          salaryGenerated: 'no',
          employeeGrossEarnings: '',
          adjustedPayableDays: '',
          ctcAnnual: '',
          totalCtc: '',
          esic: '',
          reason: (err as any).message || 'Failed during calculation',
        });
      }
    }
    console.log(eligibleEmployees.length, ineligibleEmployees.length);
    return [
      {
        data: eligibleEmployees,
        mapper: report,
        fileName: `${currentCompany.shortName}-${date}-Salary-Report`,
      },
    ];
  }

  const Report = renderBulkDownload({
    mapperFun: downloadReport,
    downloadIcon: ExcelDownload,
    name: 'Download Report',
  });

  return (
    <div className='w-full'>
      <div className='text-center'>
        Download Report of {filteredEmployees.length} employees
      </div>
      <div className='w-full flex justify-center'>
        <div className='my-auto'>Select Mode: </div>
        <select
          value={mode}
          className='border border-gray-100 p-1 rounded'
          onChange={(e) => setMode(e.target.value as 'real' | 'simulate')}
        >
          <option value='real'>Real</option>
          <option value='simulate'>Simulate</option>
        </select>
      </div>
      <div className='w-full flex justify-center my-2'>
        <Report />
      </div>
    </div>
  );
}
