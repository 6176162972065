/* eslint-disable class-methods-use-this */
import { SetPswdRequest } from '@erp_core/erp-types/dist/modules/admin';
import toast from 'react-hot-toast';
import { ErpV2Api } from '../../../../models/interfaces/adapters/erp-v2-api';
import { SetPasswordInterface } from '../../../../models/interfaces/set-password';

export class HttpSetPasswordRepo implements SetPasswordInterface {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }

  // eslint-disable-next-line no-unused-vars
  async setPassword(a: SetPswdRequest): Promise<void> {
    const httpInstance = await this.erpv2Api.getPlainInstance({
      apiUrl: this.apiUrl,
    });

    const payload = {
      email: a.email,
      otp: a.otp,
      password: a.password,
      companyGroupId: a.companyGroupId,
    };

    const res = await httpInstance.post('/api/v1/users/set-password', payload);

    if (res.status !== 200) {
      throw new Error('password generation failed');
    }
    toast(`${res.data.message}`);
  }
}
