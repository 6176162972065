/* eslint-disable react-hooks/exhaustive-deps */
import { ApprovedBadge } from '@erp_core/erp-icons/icons/web/approved-badge';
import { RejectedBadge } from '@erp_core/erp-icons/icons/web/rejected-badge';
import {
  Client,
  ClientDetails,
  ClientItem,
  LeadCustomerType,
} from '@erp_core/erp-types/dist/modules/order';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderFormV2,
  renderInlineInput,
  renderTabsComponent,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { UseFileTransfer } from '../../../hooks/file-transfer/use-file-transfer';
import { UsePublicTransfer } from '../../../hooks/file-transfer/use-public-transfer';
import { UseCombinedClient } from '../../../hooks/order/sales/client/use-client';
import { UseCombinedLeadCustomer } from '../../../hooks/order/sales/lead-customer/use-lead-customer';
import { renderLeadCustomerProfileTabs } from '../lead-customer-profile-tab';

type RenderLeadCustomerProfile = {
  useCombinedLeadCustomer: UseCombinedLeadCustomer;
  useCombinedClient: UseCombinedClient;
  useFileTransfer: UseFileTransfer;
  usePublicTransfer: UsePublicTransfer;
};

export function renderLeadCustomerProfile({
  useCombinedLeadCustomer,
  useCombinedClient,
  useFileTransfer,
  usePublicTransfer,
}: RenderLeadCustomerProfile): () => JSX.Element {
  const Card = renderCardComponent();

  return function LeadCustomerProfile(): JSX.Element {
    const { id } = useParams();
    const Tabs = renderTabsComponent();

    const {
      resource: leadCustomer,
      get: getLeadCustomer,
      loading,
      syncSet: setLeadCustomer,
    } = useCombinedLeadCustomer();
    const { syncSet: setClient } = useCombinedClient();

    useEffect(() => {
      if (id) getLeadCustomer(id);
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const InlineInput = renderInlineInput({
      useResource: useCombinedLeadCustomer,
      id,
    });

    const header: CardHeader = {
      title: (
        <div>
          <></>
          <InlineInput name={'name'} value={leadCustomer?.name || id} />
        </div>
      ) as any,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomer?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomer?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${leadCustomer?.lastModifiedBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {leadCustomer?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(leadCustomer?.lastModifiedAt).fromNow()}
          </div>
          <div className='flex space-x-2 my-2'>
            {leadCustomer?.disabled === false ? (
              <span className='border border-green-600 rounded-md px-2 py-1 text-sm text-green-600'>
                <ApprovedBadge className='inline w-5 text-green-600' />
                <span className='capitalize'>Approved</span>
              </span>
            ) : (
              <span className='border border-red-600 rounded-md px-2 py-1 text-sm text-red-600'>
                <RejectedBadge className='inline w-5 text-red-600' />
                <span className='capitalize'>Unapproved</span>
              </span>
            )}
          </div>
        </>
      ),
      actions: [
        {
          type: 'button',
          button: {
            name: 'Transform as Client',
            behaviour: 'modal',
            modal: {
              title: 'Do you want to transform lead customer as client ?',
              content: ({ onClose }) => {
                const LeadToClientForm = renderLeadtoClientForm();

                const handleLeadToClientForm = async (formData: any) => {
                  try {
                    await setLeadCustomer({
                      id: leadCustomer?.id,
                      details: {
                        ...leadCustomer?.details,
                        convertedToClient: formData?.convertedToClient,
                      },
                    } as LeadCustomerType);

                    if (formData?.convertedToClient === 'yes') {
                      try {
                        const client = {
                          leadCustomerId: leadCustomer?.id || '',
                          name: leadCustomer?.name,
                          items: [] as ClientItem[],
                          details: {
                            email: leadCustomer?.details?.email || '',
                            locations: leadCustomer?.details?.locations || [],
                            contact: leadCustomer?.details?.contact || [],
                            partyProfile:
                              leadCustomer?.details?.partyProfile || [],
                            website: leadCustomer?.details?.website || '',
                            createdAs: leadCustomer?.details?.createdAs || '',
                          } as ClientDetails,
                          company: leadCustomer?.company,
                          companyGroup: leadCustomer?.companyGroup,
                        } as Client;
                        // console.log(client);
                        await setClient(client);
                        toast.success('Lead Customer added as Client');
                        await onClose();
                      } catch (e) {
                        await onClose();
                        toast.error('Something went wrong!');
                      }
                    }
                  } catch (e) {
                    // console.log('err', e);
                    toast.error('Something went wrong!');
                  }
                };

                return (
                  <LeadToClientForm
                    onSave={handleLeadToClientForm}
                    convertToClientStatus={
                      leadCustomer?.details?.convertedToClient
                    }
                  />
                );
              },
            },
          },
        },
      ],
    };

    const Profile = renderLeadCustomerProfileTabs();

    const categories: Map<string, () => JSX.Element> = new Map<
      string,
      () => JSX.Element
    >([
      [
        'Profile',
        () =>
          leadCustomer ? (
            <Profile
              leadCustomer={leadCustomer}
              loading={loading}
              setLeadCustomer={setLeadCustomer}
              usePublicTransfer={usePublicTransfer}
            />
          ) : (
            <></>
          ),
      ],
    ]);

    const body: CardBody = {
      type: 'jsx-component',
      body: <Tabs data={categories} keyTab={nanoid()} defaultTabIndex={0} />,
    };

    return <Card header={header} body={body} />;
  };
}

type Props = {
  onSave: any;
  convertToClientStatus?: string;
};
export function renderLeadtoClientForm(): (props: Props) => JSX.Element {
  return function LeadtoClientForm({
    onSave,
    convertToClientStatus,
  }: Props): JSX.Element {
    const Form = renderFormV2<any>({
      style: 'md:w-1/2',
      fieldsData: [
        {
          property: 'convertedToClient',
          type: 'select',
          required: true,
          label: 'Convert to Client',
          options: [
            { text: 'Select', value: '' },
            { text: 'Yes', value: 'yes' },
            { text: 'No', value: 'no' },
          ],
        },
      ],
      initialFormState: {
        convertedToClient: convertToClientStatus || '',
      },
      onSubmit: onSave,
      mapTToU: (b) => b,
    });

    return <Form />;
  };
}
