import {
  TemplatesFilter,
  TemplatesType,
} from '@erp_core/erp-types/dist/modules/communication';
import { createUseCombinedResource } from '@erp_core/erp-ui-components';
import { TemplateHttpRepo } from '../datalayer/repository/http/admin/communication/template';
import { TemplateInterface } from '../models/interfaces/admin/communication/template';
import { erpV2Api } from './admin';
import { COMMUNICATION_SERVER_URI } from './server-uri';

export const communicationTemplateService = new TemplateHttpRepo(
  COMMUNICATION_SERVER_URI,
  erpV2Api
);

export const useCombinedTemplate = createUseCombinedResource<
  TemplatesType,
  TemplatesFilter,
  TemplateInterface
>({
  useService: () => communicationTemplateService,
});
