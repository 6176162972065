import { renderFormV2 } from '@erp_core/erp-ui-components';

export type BasicDaType = {
  basicSkilled?: number;
  basicSemiSkilled?: number;
  basicUnSkilled?: number;
  daSkilled?: number;
  daSemiSkilled?: number;
  daUnSkilled?: number;
  basicDaEffectiveDate?: string;
};

export function renderBasicDaForm() {
  return function Form({
    data,
    onSave,
  }: {
    data: BasicDaType;
    onSave: (data) => Promise<void>;
  }) {
    const Form = renderFormV2<any>({
      fieldsData: [
        {
          type: 'number',
          property: 'basicSkilled',
          label: 'Basic Skilled',
          required: true,
        },
        {
          type: 'number',
          property: 'basicSemiSkilled',
          label: 'Basic Semi-Skilled',
          required: true,
        },
        {
          type: 'number',
          property: 'basicUnSkilled',
          label: 'Basic Unskilled',
          required: true,
        },
        {
          type: 'number',
          property: 'daSkilled',
          label: 'DA Skilled',
          required: true,
        },
        {
          type: 'number',
          property: 'daSemiSkilled',
          label: 'DA Semi-Skilled',
          required: true,
        },
        {
          type: 'number',
          property: 'daUnSkilled',
          label: 'DA Unskilled',
          required: true,
        },
        {
          type: 'date',
          property: 'basicDaEffectiveDate',
          label: 'Effective Date',
          required: true,
        },
      ],
      initialFormState: {
        basicSkilled: data.basicSkilled || 0,
        basicSemiSkilled: data.basicSemiSkilled || 0,
        basicUnSkilled: data.basicUnSkilled || 0,
        daSkilled: data.daSkilled || 0,
        daSemiSkilled: data.daSemiSkilled || 0,
        daUnSkilled: data.daUnSkilled || 0,
        basicDaEffectiveDate: data.basicDaEffectiveDate || '',
      },
      onSubmit: async (f) => {
        f.basicSkilled = parseInt(`${f.basicSkilled || '0'}`);
        f.basicSemiSkilled = parseInt(`${f.basicSemiSkilled || '0'}`);
        f.basicUnSkilled = parseInt(`${f.basicUnSkilled || '0'}`);
        f.daSkilled = parseInt(`${f.daSkilled || '0'}`);
        f.daSemiSkilled = parseInt(`${f.daSemiSkilled || '0'}`);
        f.daUnSkilled = parseInt(`${f.daUnSkilled || '0'}`);

        onSave({
          ...data,
          ...f,
        });
      },
      mapTToU: (s) => s,
    });

    return <Form />;
  };
}
