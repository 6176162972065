import { UserIdName } from '@erp_core/erp-types/dist/modules/common';
import { ItemBatchGodown } from '@erp_core/erp-types/dist/modules/inventory';
import {
  LoadingButton,
  renderTableComponent,
} from '@erp_core/erp-ui-components';
// import { FormDataType, FormField, FormProps, renderFormV2 } from '@erp_core/erp-ui-components'

export const createGodownTransferForm = () => {
  return function ({
    defaultData,
    onSubmit,
  }: {
    onSubmit?: Function;
    defaultData: { batch: ItemBatchGodown; godown: UserIdName }[];
  }): JSX.Element {
    // function mapTToU(b: FormDataType): any {
    //   return {
    //     batch: b.batch as string,
    //     godown: b.godown as string,
    //     reference: b.reference || ''
    //   };
    // }
    const onSave = () => {};
    // const generate = () => {
    //   const res: FormField<any>[] = [];
    //   const initial: FormDataType = {

    //   }

    //   defaultData.forEach((d,i) => {
    //     res.push({ type: 'input', property: `${i}`, label: `Transaction ${i+1}` });

    //     res.push({ type: 'input', property: `batch-${i}`, label: 'Batch' });
    //     initial[`batch-${i}`] = d.batch.id;

    //     res.push({ type: 'input', property: `godown-${i}`, label: 'Godown' });
    //     initial[`godown-${i}`] = d.godown.id;
    //   })

    //   return { res, initial }
    // }
    // const formData: FormProps<any> = {
    //   formName: '',
    //   onSubmit: () => {},
    //   mapTToU,
    //   initialFormState: defaultData ? generate().initial : {
    //     batch: '',
    //     godown: ''
    //   },
    //   fields: defaultData ? generate().res : [
    //     { type: 'input', property: 'batch', label: 'Batch' },
    //     { type: 'input', property: 'godown', label: 'Godown' }
    //   ]
    // }
    // const Form = renderFormV2<any>(formData);

    const Table = renderTableComponent();
    return (
      <div>
        {/* <Form /> */}
        <div className='text-lg font-semibold'>Transfer Details</div>
        <Table
          header={[
            [
              { name: 'Batch name' },
              { name: 'Source Godown' },
              { name: 'Destination Godown' },
            ],
          ]}
          body={defaultData.map((dd) => {
            return {
              cells: [
                { value: dd.batch.name },
                { value: dd.batch.godown.name },
                { value: dd.godown.name },
              ],
            };
          })}
        />
        <LoadingButton
          behaviorFn={async () => (onSubmit ? onSubmit() : onSave)}
          defaultStyle='bg-blue-500 p-2 mt-2 text-white hover:bg-blue-600'
          text='Transfer'
        />
      </div>
    );
  };
};
