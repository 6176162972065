import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import {
  ReportsFilterType,
  ReportsType,
} from '../../../../../models/types/reporting/reports';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class ReportsHttpRepo extends GenericHttpWithLogsRepo<
  ReportsType,
  ReportsFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: 'api/v1/reports',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
