/* eslint-disable class-methods-use-this */
/* eslint-disable no-unused-vars */
import { ErpV2Api } from 'models/interfaces/adapters/erp-v2-api';
import { QuestionnaireTopicFilter } from 'models/interfaces/hrd/questionnaire/topic';
import { QuestionnaireTopicType } from 'models/types/questionnairetopic/topic';
import { GenericHttpWithLogsRepo } from '../../../generic-resource-with-logs';

export class QuestionnaireTopicHttpRepo extends GenericHttpWithLogsRepo<
  QuestionnaireTopicType,
  QuestionnaireTopicFilter
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/topics',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
