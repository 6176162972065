import { CombinedAttDataType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedOvertime } from '../../../../hooks/hrd/employee/profile/overtime/use-overtime';
import { UseCombinedEmployeeProfile } from '../../../../hooks/hrd/employee/profile/use-employee-profile';

type OvertimeReportingType = {
  date: string;
  employee: string;
  hours: string;
  reason: string;
  requestedRedemptionFlow: string;
};

type RenderOvertimeReportingForm = {
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  useCombinedOvertime: UseCombinedOvertime;
};

export const renderOvertimeReportingForm = ({
  useCombinedEmployeeProfile,
  useCombinedOvertime,
}: RenderOvertimeReportingForm) => {
  return function OvertimeReportingForm({
    onSave,
    data,
    isEdit,
  }: {
    onSave: any;
    data?: OvertimeReportingType;
    isEdit?: boolean;
  }) {
    const Form = renderAddOvertimeForm({
      useCombinedOvertime,
      useCombinedEmployeeProfile,
      isEdit,
      formData: data,
      onSave,
    });

    return <Form data={{}} onClose={() => {}} />;
  };
};

export function renderAddOvertimeForm({
  useCombinedOvertime,
  isEdit,
  useCombinedEmployeeProfile,
  formData,
  onSave,
}: {
  useCombinedOvertime: UseCombinedOvertime;
  isEdit?: boolean;
  useCombinedEmployeeProfile: UseCombinedEmployeeProfile;
  formData: any;
  onSave: (form) => Promise<void>;
}): (p: { data: any; onClose: () => void }) => JSX.Element {
  return function AddOvertimeForm({
    data: { attendance },
    onClose,
  }: {
    data: { attendance: CombinedAttDataType };
    onClose: () => void;
  }): JSX.Element {
    const { getListSync: getOvertimes } = useCombinedOvertime();

    const validateForm = async (form) => {
      if (isEdit) return {};
      if (!form.date) return {};
      if (!form.employee) return {} as any;
      const ots = await getOvertimes(
        {
          date: form.date,
          crossGroup: 'true',
        },
        { enableLoading: false, enableResource: false }
      );
      const exists = ots?.find(
        (o) => o.employee.id === form.employee.id && o.status !== 'cancelled'
      );
      if (!exists) return {};
      return {
        employee: `Overtime already exists for ${form.employee.name} on ${form.date}`,
        date: `Overtime already exists for ${form.employee.name} on ${form.date}`,
      };
    };

    const formProps: FormProps<OvertimeReportingType> = {
      fieldsData: [
        {
          property: 'employee',
          type: 'searchable-select',
          searchOptions: {
            useSearch: useCombinedEmployeeProfile,
            filter: { crossGroup: true },
            onSearchValueSelect: (form) => {},
          },
          required: true,
          label: 'Employee Name',
          readonly: isEdit,
          validate: validateForm,
        },
        {
          property: 'date',
          type: 'date',
          required: true,
          label: 'Date',
          readonly: isEdit,
          //  validate: ValidateDate
        },
        {
          property: 'hours',
          type: 'number',
          required: true,
          label: 'Hours',
          validate: async (fd: any) => {
            return {
              hours: fd.hours < 1 ? 'Hours need to be greater than 0' : '',
            };
          },
        },
        {
          property: 'reason',
          type: 'input',
          required: true,
          label: 'Reason',
        },
        {
          property: 'requestedRedemptionFlow',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Salary', value: 'salary' },
            { text: 'Comp-Off', value: 'comp-off' },
          ],
        },
      ],
      initialFormState: {
        date: formData?.date || attendance?.date || '',
        hours:
          formData?.hours ||
          attendance?.punchResult.overtimeHours.toString() ||
          '',
        reason: formData?.reason || '',
        employee: formData?.employee || attendance?.employee || '',
        requestedRedemptionFlow:
          formData?.details?.requestedRedemptionFlow ||
          formData?.requestedRedemptionFlow ||
          '',
      },
      onSubmit: async (form) => {
        const finalPayload: any = form;
        if (finalPayload.requestedRedemptionFlow) {
          finalPayload.details = {
            requestedRedemptionFlow: finalPayload.requestedRedemptionFlow,
          };
        }
        await onSave(form);
        onClose();
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };

    const Form = renderFormV2<OvertimeReportingType>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<OvertimeReportingType>
    ): OvertimeReportingType {
      return {
        date: b.date || formData?.date || attendance?.date || '',
        hours:
          b.hours ||
          formData?.hours ||
          attendance?.punchResult.overtimeHours.toString() ||
          '',
        reason: b.reason || formData?.reason,
        employee:
          b.employee || formData?.employee || attendance?.employee || '',
        requestedRedemptionFlow:
          b.requestedRedemptionFlow || formData?.requestedRedemptionFlow || '',
      } as any;
    }
  };
}
