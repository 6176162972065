export const functionsStored: Array<{
  name: string;
  fn: (d: any) => string;
}> = [
  {
    name: 'dummy-fn1',
    fn: (d: any) => '',
  },
  {
    name: 'dummy-fn2',
    fn: (d: any) => '',
  },
  {
    name: 'dummy-fn2',
    fn: (d: any) => '',
  },
];
