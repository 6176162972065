/* eslint-disable no-unused-vars */
import { Authorization } from '@erp_core/erp-types/dist/modules/admin';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedActionAdmin } from '../../../hooks/admin/action-admin/use-action-admin';
import { UseCombinedAuthorization } from '../../../hooks/admin/authorization/use-authorization';
import { UseCombinedModuleAdmin } from '../../../hooks/admin/module-admin/use-modules-admin';
import { UseCombinedResourcesAdmin } from '../../../hooks/admin/use-resource-admin/use-resources-admin';

export type RenderEditAuthorizationFormProps = {
  useCombinedAuthorization: UseCombinedAuthorization;
  useCombinedResourcesAdmin: UseCombinedResourcesAdmin;
  useCombinedActionAdmin: UseCombinedActionAdmin;
  useCombinedModuleAdmin: UseCombinedModuleAdmin;
  closeEditModal: () => void;
};

export function renderEditAuthorizationForm({
  useCombinedAuthorization,
  useCombinedResourcesAdmin,
  useCombinedActionAdmin,
  useCombinedModuleAdmin,
  closeEditModal,
}: RenderEditAuthorizationFormProps): (AuthorizationType) => JSX.Element {
  return function EditAuthorizationForm({
    r,
    onClose,
  }: {
    r: Authorization;
    onClose: () => void;
  }): JSX.Element {
    const { syncSet: setAuthorization } = useCombinedAuthorization();

    const saveAuthorization = async (form) => {
      if (r.id) form.id = r.id;
      await setAuthorization(form);
      closeEditModal();
      onClose();
    };

    const formProps: FormProps<Partial<Authorization>> = {
      fieldsData: [
        { property: 'name', label: 'Name', type: 'input' },
        {
          property: 'module',
          label: 'Module',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedModuleAdmin,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'resource',
          label: 'Resource',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedResourcesAdmin,
            onSearchValueSelect(u) {},
          },
        },
        {
          property: 'action',
          label: 'Action',
          type: 'searchable-select',
          required: true,
          searchOptions: {
            useSearch: useCombinedActionAdmin,
            onSearchValueSelect(u) {},
          },
        },
      ],
      initialFormState: {
        name: r.name,
        module: { id: r.module?.id, name: r.module?.name } as any,
        resource: { id: r.resource?.id, name: r.resource?.name } as any,
        action: { id: r.action?.id, name: r.action?.name } as any,
      },
      mapTToU: mapPFDToP,
      onSubmit: saveAuthorization,
    };

    const Form = renderFormV2<Partial<Authorization>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<Authorization>>
    ): Partial<Authorization> {
      return (b as unknown) as Authorization;
    }
  };
}
