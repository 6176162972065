import toast from 'react-hot-toast';
import { FileTransferInterface } from '../../models/interfaces/fileTransfer/fileTransfer';

export type UseFileTransfer = () => {
  // eslint-disable-next-line
  set(path: string, data: File, ext: string): Promise<any>;
  // eslint-disable-next-line
  get(path: string, returnBase64?: boolean): Promise<string>;
  // eslint-disable-next-line
  list(path: string): Promise<any>;
};

export type UseFileTransferProp = {
  useFileTransferService: () => FileTransferInterface;
};

export function createUseFileTransfer({
  useFileTransferService,
}: UseFileTransferProp): UseFileTransfer {
  return () => {
    const fileTransferService = useFileTransferService();
    const set = async (path: string, data: File, ext: string) => {
      // eslint-disable-next-line
      const res = await fileTransferService.set(path, data, ext);
      if (res.success) {
        toast('File uploaded successfully');
        return res;
      }
      toast('File failed to upload');
      return;
    };

    const get = async (path: string, returnBase64?: boolean) => {
      return fileTransferService.get(path, returnBase64);
    };

    const list = async (path: string) => {
      return fileTransferService.list(path);
    };

    return {
      set: (path, data, ext) => set(path, data, ext),
      get: (path, returnBase64) => get(path, returnBase64),
      list: (path) => list(path),
    };
  };
}
