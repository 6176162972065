import { renderPureHistory } from '@erp_core/erp-ui-components';
import { useEffect } from 'react';
import { OverviewFlow } from '../../../../../../components/flow-chart';
import { UseCombinedPurchaseRequisition } from '../../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { getPrFlowGraph } from './utils/get-pr-flow-graph';

export function renderPRProfile({
  id,
  useCombinedPurchaseRequisition,
}: {
  id: string;
  useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
}): () => JSX.Element {
  return function PRProfile() {
    const { get: getPr, resource: data } = useCombinedPurchaseRequisition();
    useEffect(() => {
      getPr(id);
      // eslint-disable-next-line
    }, []);

    const History = renderPureHistory();

    return (
      <div>
        <div className='w-full h-48 border border-gray-200 mx-auto'>
          <OverviewFlow data={data} getFlowGraph={getPrFlowGraph} />
          {/* <PrFlow /> */}
        </div>
        <div className='w-full p-4'>
          {data?.details?.activity ? (
            <History logs={data.details.activity} />
          ) : null}
        </div>
      </div>
    );
  };
}
