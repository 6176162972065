import { EmployeeEducationType } from '@erp_core/erp-types/dist/modules/hrd';
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

export type EmployeeEducationFormType = {
  statusOfEducation: string;
  degreeTitle: string;
  college: string;
  yearOfPassing: string;
  marks: string;
};

export function renderAddEmployeeEducationForm() {
  return function AddEmployeeEducationForm({
    onSave,
    data,
  }: {
    onSave: (data: EmployeeEducationType) => Promise<void>;
    data?: EmployeeEducationType;
  }): JSX.Element {
    const formProps: FormProps<Partial<EmployeeEducationFormType>> = {
      fieldsData: [
        {
          property: 'statusOfEducation',
          type: 'select',
          options: [
            { text: 'Select', value: '' },
            { text: 'Below-V', value: 'Below-V' },
            { text: 'V-IX', value: 'V-IX' },
            { text: 'X', value: 'X' },
            { text: 'XII', value: 'XII' },
            { text: 'ITI', value: 'ITI' },
            { text: 'Engineering-Diploma', value: 'Engineering-Diploma' },
            { text: 'Vocational-Diploma', value: 'Vocational-Diploma' },
            { text: 'Graduation', value: 'Graduation' },
            { text: 'Post-Graduation', value: 'Post-Graduation' },
            { text: 'Other', value: 'Other' },
          ],
          label: 'Status Of Education',
        },
        {
          property: 'degreeTitle',
          type: 'input',
          label: 'Degree Title',
        },
        {
          property: 'college',
          type: 'input',
          label: 'College Name',
        },
        {
          property: 'yearOfPassing',
          type: 'input',
          label: 'Year Of Passing',
        },
        { property: 'marks', type: 'input' },
      ],
      initialFormState: {
        statusOfEducation: '' || data?.statusOfEducation,
        degreeTitle: '' || data?.degreeTitle,
        college: '' || data?.college,
        yearOfPassing: '' || data?.yearOfPassing,
        marks: '' || data?.details.marks,
      },
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
      onSubmit: async (l) => {
        await onSave({
          statusOfEducation: l.statusOfEducation || data?.statusOfEducation,
          degreeTitle: l.degreeTitle || data?.degreeTitle,
          college: l.college || data?.college,
          yearOfPassing: l.yearOfPassing || data?.yearOfPassing,
          details: {
            marks: l.marks || data?.details.marks,
          },
        } as EmployeeEducationType);
      },
    };

    const Form = renderFormV2<Partial<EmployeeEducationFormType>>(formProps);

    return <Form />;

    function mapPFDToP(
      b: FormDataType<Partial<EmployeeEducationFormType>>
    ): EmployeeEducationFormType {
      return (b as unknown) as EmployeeEducationFormType;
    }
  };
}
