import { QuestionType } from '../../../../models/types/questionnairetopic/question';
import { SurveyAnswerType } from '../survey-answers/form';

export type QuestionRendererProps = {
  surveyAns: SurveyAnswerType;
  r: QuestionType;
  dynamicData: any;
  setSurveyAns: React.Dispatch<React.SetStateAction<SurveyAnswerType>>;
};

export const customQuestionRenderer: {
  [key: string]: Array<{
    functionName: string;
    renderer: (p: QuestionRendererProps) => JSX.Element;
  }>;
} = {
  '85025EE1-E570-43D1-A390-4A141E31BE7D': [
    {
      functionName: 'chemicalCompositionRenderer',
      renderer: chemicalCompositionRenderer,
    },
    {
      functionName: 'impurityRenderer',
      renderer: impurityRenderer,
    },
  ],
  'A49079C2-8852-471B-A86E-FCD70D53DBDE': [
    {
      functionName: 'chemicalCompositionRenderer',
      renderer: chemicalCompositionRenderer,
    },
    {
      functionName: 'impurityRenderer',
      renderer: impurityRenderer,
    },
  ],
};

function impurityRenderer(p: QuestionRendererProps): JSX.Element {
  const impurities: Array<{
    impurity: string;
    'unit-of-measurement': string;
    MoA: string;
    'test-done': string;
    'lower-value': string;
    'upper-value': string;
  }> = p.dynamicData.properties?.find(
    (x) => x.name === 'assay-of-other-impurity'
  )?.value?.data;

  return (
    <div>
      {impurities?.map((i, index) => {
        const surAns = p.surveyAns?.find((x) => x.questionId === p.r.id);
        const ansVal =
          surAns?.answerValue && (surAns as any).answerValue[index].actual;

        if (i['test-done'] === 'yes') {
          return (
            <div key={index} className='border border-gray-200 p-1 rounded'>
              <div className='bg-slate-100 p-1'>
                <div className='text-lg'>
                  Record {i.impurity} in {i['unit-of-measurement']}.
                </div>
                <div className='italic'>The Moa is {i.MoA}</div>
                <div className='italic text-red-500'>
                  Ideal Answer: Between {i['lower-value']} and{' '}
                  {i['upper-value']}
                </div>
              </div>

              <div className='pl-6'>
                <input
                  type='text'
                  className='block w-1/2 p-2 border border-gray-300 rounded-lg'
                  value={ansVal}
                  onChange={(e) => {
                    const newVal = e.target.value;
                    const sa = p.surveyAns.find((x) => x.questionId === p.r.id);
                    if (sa) {
                      if (!sa.answerValue) {
                        (sa as any).answerValue = impurities?.map((t) => ({
                          name: t.impurity,
                          expected: `Between ${i['lower-value']} and ${i['upper-value']}`,
                          actual: '',
                        }));
                      }
                      (sa as any).answerValue[index].actual = newVal;
                      p.setSurveyAns([...p.surveyAns]);
                    }
                  }}
                ></input>
              </div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}

function chemicalCompositionRenderer(p: QuestionRendererProps): JSX.Element {
  // console.log(p.dynamicData);
  const chemComp: Array<{
    component: string;
    moa: string;
    specification: string;
    uom: string;
  }> = p.dynamicData.properties?.find((x) => x.name === 'chemical-composition')
    ?.value?.data;
  // console.log(chemComp)
  return (
    <div>
      {chemComp?.map((i, index) => {
        const surAns = p.surveyAns?.find((x) => x.questionId === p.r.id);
        const ansVal =
          surAns?.answerValue && (surAns as any).answerValue[index].actual;
        return (
          <div key={index} className='border border-gray-200 p-1 rounded'>
            <div className='bg-slate-100 p-1'>
              <div className='text-lg'>
                Record {i.component} in {i.uom}.
              </div>
              <div className='italic'>The Moa is {i.moa}</div>
              <div className='italic text-red-500'>
                Ideal Answer: {i.specification}
              </div>
            </div>

            <div className='pl-6'>
              <input
                type='text'
                className='block w-1/2 p-2 border border-gray-300 rounded-lg'
                value={ansVal}
                onChange={(e) => {
                  const newVal = e.target.value;
                  const sa = p.surveyAns.find((x) => x.questionId === p.r.id);
                  if (sa) {
                    if (!sa.answerValue) {
                      (sa as any).answerValue = chemComp?.map((t) => ({
                        name: t.component,
                        expected: t.specification,
                        actual: '',
                      }));
                    }
                    (sa as any).answerValue[index].actual = newVal;
                    p.setSurveyAns([...p.surveyAns]);
                  }
                }}
              ></input>
            </div>
          </div>
        );
      })}
    </div>
  );
}
