import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useContext } from 'react';
import { CurrentContext } from '../../../../contexts/current';
import { UseCombinedCompanyGroupSetting } from '../../../../hooks/admin/company-group-setting/use-company-group-setting';
import { CGConfigRenderer } from '../config-renderer';
import { renderClassifiers } from './classifiers';
import { renderEditItemBatchNamePatternForm } from './forms/edit-item-batch-name-pattern';
import { renderEditMinimumOrderQuantityForm } from './forms/edit-minimum-order-quantity';
import { renderEditMinimumSalesQuantityForm } from './forms/edit-minimum-sales-quantity';
import { renderEditMinimumStockLevelForm } from './forms/edit-minimum-stock-level';
import { renderOperations } from './operations';

type CreateInventoryConfigPage = {
  useCombinedCompanyGroupSetting: UseCombinedCompanyGroupSetting;
};

export const createInventoryConfigPage = ({
  useCombinedCompanyGroupSetting,
}: CreateInventoryConfigPage) => {
  const Classifiers = renderClassifiers();
  const Operations = renderOperations();
  const Card = renderCardComponent();
  return function () {
    const { syncSet: setCGS } = useCombinedCompanyGroupSetting();
    const { cgSetting } = useContext(CurrentContext);

    const handleEditMinimumOrderQuantity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumOrderQuantityForm = renderEditMinimumOrderQuantityForm({
        comGrpSetDetails: cgSetting?.details || {},
        closeModal: onClose,
        useCombinedCompanyGroupSetting,
      });

      return <EditMinimumOrderQuantityForm />;
    };

    const handleEditMinimumSalesQuantity = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumSalesQuantityForm = renderEditMinimumSalesQuantityForm({
        comGrpSetDetails: cgSetting?.details || {},
        closeModal: onClose,
        useCombinedCompanyGroupSetting,
      });
      return <EditMinimumSalesQuantityForm />;
    };

    const handleEditMinimumStockLevel = ({
      onClose,
    }: {
      onClose: () => void;
    }) => {
      const EditMinimumStockLevel = renderEditMinimumStockLevelForm({
        comGrpSetDetails: cgSetting?.details || {},
        closeModal: onClose,
        useCombinedCompanyGroupSetting,
      });
      return <EditMinimumStockLevel />;
    };

    type Configs = {
      settingName: string;
      settingValue?: string;
      settingValueSuffix?: string;
      editAction: ({ onClose }: { onClose: () => void }) => JSX.Element;
    };

    const configs: Configs[] = [
      {
        settingName: 'Minimum order quantity',
        settingValue: cgSetting?.details?.inventory?.item?.minimumOrderQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumOrderQuantity,
      },
      {
        settingName: 'Minimum Sales quantity: ',
        settingValue: cgSetting?.details?.inventory?.item?.minimumSalesQuantity?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumSalesQuantity,
      },
      {
        settingName: 'Minimum Stock Level quantity: ',
        settingValue: cgSetting?.details?.inventory?.item?.minimumStockLevel?.toString(),
        settingValueSuffix: '% of godown capacity',
        editAction: handleEditMinimumStockLevel,
      },
      {
        settingName: 'Item batch name pattern: ',
        settingValue: cgSetting?.details?.inventory?.itemBatch?.itemBatchNamePattern?.toString(),
        settingValueSuffix: '',
        editAction: ({ onClose }) => {
          const NewForm = renderEditItemBatchNamePatternForm({
            comGrpSetDetails: cgSetting?.details || {},
            id: cgSetting?.id || '',
            closeModal: onClose,
            useCombinedCompanyGroupSetting,
          });

          return <NewForm />;
        },
      },
    ];

    return (
      <div className='w-full'>
        <div className='flex flex-wrap'>
          {configs.map((config) => (
            <div className='w-1/2' key={config.settingName}>
              <Card
                key={config.settingName}
                header={{
                  title: config.settingName,
                }}
                body={{
                  type: 'jsx-with-behaviour',
                  body: {
                    content: (
                      <CGConfigRenderer
                        configs={[config]}
                        loading={cgSetting?.id ? false : true}
                      />
                    ),
                    behaviour: 'modal',
                    modal: {
                      title: config.settingName,
                      content: ({ onClose }) => {
                        const Comp = config.editAction({ onClose });
                        return Comp;
                      },
                    },
                  },
                }}
              />
            </div>
          ))}
        </div>

        <Classifiers
          config={cgSetting?.details?.inventory || {}}
          setCGS={setCGS}
        />
        <Operations
          config={cgSetting?.details?.inventory || {}}
          setCGS={setCGS}
        />
      </div>
    );
  };
};
