import { renderCardComponent } from '@erp_core/erp-ui-components';
import { useEffect, useState } from 'react';
import { BomFlowInterface } from '../../../../../models/interfaces/inventory/bom-flow';
import { renderBomChart } from '../../../components/bom-chart';

type RenderBomChartProps = {
  bomFlowService: BomFlowInterface;
};

export function renderBomChartComponent({
  bomFlowService,
}: RenderBomChartProps): ({ gradeId }: { gradeId: string }) => JSX.Element {
  const Card = renderCardComponent();
  const BomChart = renderBomChart();

  return function BomChartComponent({
    gradeId,
  }: {
    gradeId: string;
  }): JSX.Element {
    const [bomCharts, setBomCharts] = useState<Array<any>>([]);
    useEffect(() => {
      if (gradeId) {
        bomFlowService.getBomCharts(gradeId).then((res) => {
          setBomCharts(res);
          // console.log(res);
        });
      }
    }, [gradeId]);
    return (
      <div>
        {bomCharts.map((x) => (
          <Card
            key={x.id}
            header={{
              title: x.name,
            }}
            body={{
              type: 'jsx-component',
              body: (
                <>
                  <BomChart node={x.chartNode} />
                </>
              ),
            }}
          />
        ))}
      </div>
    );
  };
}
