import { CasualLeave } from '@erp_core/erp-icons/icons/web/casual-leave';
import { Recat } from '@erp_core/erp-icons/icons/web/recat';
import { LocationHolidayType } from '@erp_core/erp-types/dist/modules/planning';
import {
  CardBody,
  CardHeader,
  renderCardComponent,
  renderTableComponent,
  TableBodyRow,
  TableCell,
  TableHeader,
} from '@erp_core/erp-ui-components';
import _ from 'lodash';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { CurrentContext } from '../../../contexts/current';
import { UseCombinedLocationHoliday } from '../../../hooks/planning/location-holiday/use-location-holiday';
import { HolidayFormType, renderHolidayForm } from './forms';

export type RenderLocationHolidaysListProps = {
  useCombinedLocationHoliday: UseCombinedLocationHoliday;
};

export function renderLocationHolidaysList({
  useCombinedLocationHoliday,
}: RenderLocationHolidaysListProps): () => JSX.Element {
  return function LocationHolidaysList(): JSX.Element {
    const { cgSetting } = useContext(CurrentContext);
    const {
      list: locationHolidays,
      getAll: getLocationHolidays,
      loading,
    } = useCombinedLocationHoliday();

    const {
      syncSet: setLocationHoliday,
      delete: deleteLocationHoliday,
    } = useCombinedLocationHoliday();
    const [date, setDate] = useState<string>(moment().format('YYYY'));

    const yr: Array<string> = [
      moment().subtract(2, 'years').format('YYYY'),
      moment().subtract(1, 'years').format('YYYY'),
      moment().add(0, 'years').format('YYYY'),
      moment().add(1, 'years').format('YYYY'),
      moment().add(2, 'years').format('YYYY'),
    ];

    useEffect(() => {
      getLocationHolidays({
        date,
      });
      // getCgs();
      // eslint-disable-next-line
    }, [date]);

    const groupedLocationHolidays = _.groupBy(
      locationHolidays,
      'sisterLocation'
    );

    const onSaveHoliday = async (form: LocationHolidayType) => {
      const finalData = form;
      await setLocationHoliday(finalData);
      toast('Holiday added sucessfully');
      getLocationHolidays();
    };

    // const cgsDetails = cgs?.[0]?.details;
    const keys = _.keys(
      cgSetting?.details?.companyGroup?.location?.sisterLocations || []
    );

    const onEditHoliday = async (form: any, id: string) => {
      const finalData = {
        id,
        ...form,
      };
      await setLocationHoliday(finalData);
      toast('Holiday edited sucessfully');
      getLocationHolidays();
    };

    // const handleEditHoliday = (id: string) => {
    //   const Form = renderHolidayForm({});
    //   const hd = locationHolidays?.find((s) => s.id === id);
    //   const formData = {
    //     ...hd,
    //     locations: keys.map((k) => ({ text: k, value: k })),
    //   };
    //   setModal({
    //     ...modal,
    //     title: 'Edit Holiday',
    //     isVisible: true,
    //     body: (
    //       <Form
    //         onSave={(e) => onEditHoliday(e, id)}
    //         data={formData as HolidayFormType}
    //         isEdit={true}
    //       />
    //     ),
    //   });
    // };

    // const handleDeleteHoliday = (id: string) => {
    //   openConfirm({
    //     title: `Are you sure you want to delete ${
    //       locationHolidays?.find((p) => p.id === id)?.name
    //     }?`,
    //     type: 'warning',
    //     onConfirm: async () => {
    //       await deleteLocationHoliday(id);
    //     },
    //   });
    // };

    const Table = renderTableComponent();

    const Card = renderCardComponent();

    const getCardHeader = (title: string) => {
      const header: CardHeader = {
        title: `${title}`,
      };
      return header;
    };

    const getCardBody = (data: LocationHolidayType[]) => {
      const tableHeader: TableHeader = [
        [{ name: 'Name' }, { name: 'Date' }, { name: 'Description' }],
      ];
      const tableBody: TableBodyRow[] =
        data?.map((e) => {
          const cells: Array<TableCell> = [
            { value: e?.name || '', link: `/hrd/shift-schedule/${e.id}/shift` },
            { value: e?.date },
            { value: e?.description },
          ];

          return {
            rowData: {
              holiday: e,
            },
            cells,
          };
        }) || [];
      const body: CardBody = {
        type: 'jsx-component',
        body: (
          <Table
            header={tableHeader}
            body={tableBody}
            actions={[
              {
                name: 'Edit',
                behaviour: 'modal',
                show: () => true,
                modal: {
                  title: 'Edit',
                  content: ({ data: { holiday }, onClose }) => {
                    const Form = renderHolidayForm({});
                    const formData = {
                      ...holiday,
                      locations: keys.map((k) => ({ text: k, value: k })),
                    };
                    return (
                      <Form
                        onSave={async (e) => {
                          await onEditHoliday(e, holiday.id);
                          onClose();
                        }}
                        data={formData as HolidayFormType}
                        isEdit={true}
                      />
                    );
                  },
                },
              },
              {
                name: 'Delete',
                behaviour: 'confirm',
                show: () => true,
                onConfirm: ({ holiday }) => {
                  return {
                    title: `Are you sure you want to delete ${holiday.name}?`,
                    type: 'warning',
                    onConfirm: async () => {
                      await deleteLocationHoliday(holiday.id);
                    },
                  };
                },
              },
            ]}
          />
        ),
      };
      return body;
    };

    return (
      <div className='m-3'>
        <Card
          header={{
            title: 'Holiday',
            icon: (
              <CasualLeave className='w-8 h-8 inline stroke-none fill-indigo-900 self-center' />
            ),
            subheading: (
              <span className='text-blue-700 font-bold'>
                {moment(date).format('YYYY')}
              </span>
            ),
            actions: [
              {
                type: 'jsx',
                jsx: (
                  <div className='flex w-fit p-1 space-x-2 items-center font-bolder'>
                    <span className='font-bold truncate'>Select year</span>
                    <select
                      value={date}
                      className='bg-gray-100 w-36 text-center'
                      onChange={(e) => setDate(e.target.value)}
                    >
                      {yr.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                ),
              },
              {
                type: 'button',
                button: {
                  name: 'Add holiday',
                  behaviour: 'modal',
                  modal: {
                    title: 'Add Holiday',
                    content: ({ onClose }) => {
                      const Form = renderHolidayForm({});
                      const formData = {
                        locations: keys.map((k) => ({ text: k, value: k })),
                      };
                      return (
                        <Form
                          onSave={async (e) => {
                            await onSaveHoliday(e);
                            onClose();
                          }}
                          data={formData as HolidayFormType}
                        />
                      );
                    },
                  },
                },
              },
            ],
          }}
          body={{
            type: 'jsx-component',
            body: loading ? (
              <div className='flex my-24 justify-center'>
                <Recat className='h-5 inline animate-pulse mx-4' />
              </div>
            ) : (
              <div>
                {Object.entries(groupedLocationHolidays).map((gs, index) => {
                  return (
                    <div key={index} className='mb-4'>
                      <Card
                        header={getCardHeader(gs[0])}
                        body={getCardBody(gs[1])}
                      />
                    </div>
                  );
                })}
              </div>
            ),
          }}
        />
      </div>
    );
  };
}
