import { useState } from 'react';
import {
  AttendanceInterface,
  GetMonthlySummary,
  GetMonthlySummaryReq,
} from '../../../models/interfaces/hrd/attendance';

export type UseAttendanceMonthlySummary = () => {
  getAttdMonthlySummary: (p: GetMonthlySummaryReq) => void;
  data: GetMonthlySummary;
};

export interface UseAttendanceMonthlySummaryOptions {
  useAttendanceService: () => AttendanceInterface;
}

export function createUseAttendanceMonthlySummary({
  useAttendanceService,
}: UseAttendanceMonthlySummaryOptions): UseAttendanceMonthlySummary {
  return () => {
    const attendanceService = useAttendanceService();
    const [data, setData] = useState<any>();

    const monthSummaryApiCall = async (p: GetMonthlySummaryReq) => {
      try {
        const resp = await attendanceService.getMonthlySummary(p);
        setData(resp);
      } catch (e) {
        throw new Error(e as string);
      }
    };

    return {
      getAttdMonthlySummary: (p: GetMonthlySummaryReq) => {
        monthSummaryApiCall(p);
      },
      data,
    };
  };
}
