import {
  CardBody,
  CardHeader,
  DataArray,
  renderCardComponent,
  renderTabsV2Component,
} from '@erp_core/erp-ui-components';
import { CubeIcon, UserIcon } from '@heroicons/react/24/outline';
import moment from 'moment';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UseUserAuthorization } from '../../../../../hooks/admin/user-authorization/use-user-authorization';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';
import { UseCombinedItem } from '../../../../../hooks/inventory/item/use-combined-item';
import { UseCombinedEnquiryQuote } from '../../../../../hooks/inventory/purchase/enquiry-quotes/use-quote';
import { UseCombinedPurchaseEnquiry } from '../../../../../hooks/inventory/purchase/purchase-enquiry/use-purchase-enquiriy';
import { UseVirtualItemAllocate } from '../../../../../hooks/inventory/purchase/purchase-order/use-allocate-virtual-item';
import { UseCombinedPurchaseOrder } from '../../../../../hooks/inventory/purchase/purchase-order/use-purchase-order';
import { UseCombinedPurchaseRequisition } from '../../../../../hooks/inventory/purchase/purchase-requisition/use-purchase-resource';
import { UseCombinedStockGroup } from '../../../../../hooks/inventory/stockgroup/use-stockgroup';
import { UseCombinedBom } from '../../../../../hooks/order/work-order/bom/use-bom';
import { renderPRName } from '../../../../../modules/common/fragments/purchase-requisition';
import { renderEnquiryProfile } from './enquiry-flow';
import { renderEnquiryQuotes } from './enquiry-quote';

export type RenderPRDetailsProp = {
  hooks: {
    useCombinedPurchaseEnquiry: UseCombinedPurchaseEnquiry;
    useCombinedEnquiryQuote: UseCombinedEnquiryQuote;
    useCombinedPurchaseOrder: UseCombinedPurchaseOrder;
    useUserAuthorization: UseUserAuthorization;
    useVirtualItemAllocate: UseVirtualItemAllocate;
    useFileTransfer: UseFileTransfer;
    useCombinedBom: UseCombinedBom;
    useCombinedItem: UseCombinedItem;
    useCombinedStockGroup: UseCombinedStockGroup;
    useCombinedPurchaseRequisition: UseCombinedPurchaseRequisition;
  };
};

export function renderEnquiryDetails({
  hooks: {
    useCombinedEnquiryQuote,
    useCombinedPurchaseOrder,
    useUserAuthorization,
    useVirtualItemAllocate,
    useCombinedPurchaseEnquiry,
    useFileTransfer,
    useCombinedBom,
    useCombinedItem,
    useCombinedStockGroup,
    useCombinedPurchaseRequisition,
  },
}: RenderPRDetailsProp): () => JSX.Element {
  return function PRDetails(): JSX.Element {
    const { id } = useParams();
    const PrName = renderPRName();
    const {
      resource: enquiry,
      get: getEnquiry,
      syncSet: setPurchaseEnquiry,
    } = useCombinedPurchaseEnquiry();
    const NewTabs = renderTabsV2Component();
    const Card = renderCardComponent();

    useEffect(() => {
      if (id) {
        getEnquiry(id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    if (!id) {
      return <div>Loading...</div>;
    }

    const header: CardHeader = {
      title: `${enquiry?.name || id}`,
      icon: <CubeIcon className='text-gray-800 w-24 h-full self-center' />,
      subheading: (
        <>
          <div className='text-gray-700 italic'>
            Status:{' '}
            <span className='border rounded-md bg-indigo-100 text-indigo-900 px-1 py-0.5'>
              {enquiry?.status || 'open'}
            </span>{' '}
            {enquiry?.purchaseRequisitions ? (
              <>
                Item:{' '}
                <a
                  className='font-bold'
                  href={
                    '/inventory/items' // `/inventory/items/${data?.purchaseRequisitions[0]?.itemDetails?.id}/profile`
                  }
                  target='_blank'
                  rel='noreferrer'
                >
                  {enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.itemDetails?.name}
                </a>
                &nbsp;&nbsp;|&nbsp;&nbsp; Quantity:{' '}
                <b>
                  {(enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.details?.quantity) ||
                    '??'}{' '}
                  {enquiry.purchaseRequisitions &&
                    enquiry?.purchaseRequisitions[0]?.itemDetails?.uom}
                </b>
                &nbsp;&nbsp;|&nbsp;&nbsp; PRs:{' '}
                <PrName
                  id={enquiry?.purchaseRequisitions[0]?.id}
                  name={enquiry?.purchaseRequisitions[0]?.name}
                />
              </>
            ) : null}
          </div>
          <div className='text-gray-700'>
            created by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${enquiry?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {enquiry?.createdBy?.name || '??'}
            </a>
            &nbsp;&nbsp;|&nbsp;&nbsp; last modified by{' '}
            <a
              className='font-bold'
              href={`/users/profile/${enquiry?.createdBy?.id}`}
              target='_blank'
              rel='noreferrer'
            >
              <UserIcon className='w-4 inline' />{' '}
              {enquiry?.lastModifiedBy?.name || '??'}
            </a>{' '}
            at {moment.utc(enquiry?.lastModifiedAt).fromNow()}
          </div>
        </>
      ),
    };

    const newCategories: DataArray = [
      {
        name: 'details',
        Fn: enquiry
          ? renderEnquiryProfile({
              enquiry,
            })
          : () => <></>,
      },
      {
        name: 'quotes',
        Fn: enquiry
          ? renderEnquiryQuotes({
              enquiry,
              setPurchaseEnquiry,
              useCombinedEnquiryQuote,
              useCombinedPurchaseOrder,
              useUserAuthorization,
              useVirtualItemAllocate,
              useFileTransfer,
              useCombinedBom,
              useCombinedItem,
              useCombinedStockGroup,
              useCombinedPurchaseRequisition,
            })
          : () => <></>,
      },
    ];

    const body: CardBody = {
      type: 'jsx-component',
      body: <NewTabs data={newCategories} manipulateLocation={true} />,
    };

    return <Card header={header} body={body} />;
  };
}
