/* eslint-disable class-methods-use-this */

import { GatePassType } from '@erp_core/erp-types/dist/types/modules/hrd/employee-profile-detail';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { GatePassFilterType } from '../../../../../models/interfaces/hrd/employee/gate-pass';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class GatePassHttpRepo extends GenericHttpWithLogsRepo<
  GatePassType,
  GatePassFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/api/v1/gate-passes',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
