import {
  CardBody,
  CardHeader,
  renderCardComponent,
} from '@erp_core/erp-ui-components';
import { useContext, useEffect, useState } from 'react';
import { renderTextEditor } from '../../../../../components/html-editor';
import { CurrentContext } from '../../../../../contexts/current';
import { UseFileTransfer } from '../../../../../hooks/file-transfer/use-file-transfer';

// eslint-disable-next-line
const TextEditor = renderTextEditor();

export const renderHtmlTemplate = () => {
  return function TemplateForm({
    id,
    type,
    useFileTransfer,
  }: {
    id: string;
    type: string;
    useFileTransfer: UseFileTransfer;
  }) {
    const { get: getTemplate, set: setTemplate } = useFileTransfer();
    const { companyGroup: currentCompanyGroup } = useContext(CurrentContext);

    function onTemplateSave(data: any) {
      const file = new File([data], `${id.toLowerCase()}.html`, {
        type: 'text/plain',
      });
      setTemplate(
        `${currentCompanyGroup.id}/email-templates/${type}/${id}`,
        file,
        'html'
      );
    }

    const Card = renderCardComponent();

    const cardHeader: CardHeader = {
      title: 'Edit-Template',
    };

    const cardBody: CardBody = {
      type: 'jsx-component',
      body: (
        <>
          <OpenSpecTemplate
            path={`${currentCompanyGroup.id}/email-templates/${type}/${id}.html`}
            onTemplateSave={onTemplateSave}
            companyGroupId={currentCompanyGroup.id}
            getTemplate={getTemplate}
          />
        </>
      ),
    };

    if (!currentCompanyGroup.id) return <div />;
    return (
      <>
        <Card body={cardBody} header={cardHeader} />
      </>
    );
  };
};

function OpenSpecTemplate({
  path,
  onTemplateSave,
  getTemplate,
  companyGroupId,
}: {
  path: string;
  onTemplateSave: (data: any) => void;
  getTemplate: (path: string) => Promise<string>;
  companyGroupId: string;
}): JSX.Element {
  const [data, setData] = useState<string>('');
  useEffect(() => {
    getTemplate(path).then((res) => {
      if (!res) {
        // If no response fetch default stockgroup template
        getTemplate(`${path}`).then((res) => {
          setData(res);
        });
      } else {
        setData(res);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <TextEditor initialHtml={data} onSave={onTemplateSave} />
    </>
  );
}
