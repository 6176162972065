/* eslint-disable no-unused-vars */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';

type Fileds = {
  extendedDate: string;
  reason: string;
};

type Props = { onSave: any };
// eslint-disable-next-line
export function renderExtendEnquiryFrom(): (props: Props) => JSX.Element {
  return function ExtendEnquiryForm({ onSave }: Props): JSX.Element {
    const formProps: FormProps<Fileds> = {
      fieldsData: [
        {
          property: 'extendedDate',
          type: 'date',
          label: 'extended date',
          required: true,
        },
        {
          property: 'reason',
          type: 'input',
          label: 'Reason',
        },
      ],
      initialFormState: {
        extendedDate: '',
        reason: '',
      },
      onSubmit: onSave,
      mapTToU: mapPFDToP,
      style: 'md:w-1/2',
    };
    const NewForm = renderFormV2<Fileds>(formProps);

    return <NewForm />;

    function mapPFDToP(b: FormDataType<Fileds>): Fileds {
      return {
        extendedDate: b.extendedDate as string,
        reason: b.reason as string,
      };
    }
  };
}
