/* eslint-disable no-unused-vars */
import {
  FormDataType,
  FormProps,
  renderFormV2,
} from '@erp_core/erp-ui-components';
import { UseCombinedGodown } from '../../../../hooks/inventory/godown-inventory/use-godown';

type GodownFormType = {
  name: string;
  parentGodownId: string;
  address: string;
};

export type RenderAddGodownFormProps = {
  useCombinedGodown: UseCombinedGodown;
};

export function renderAddGodownForm({
  useCombinedGodown,
}: RenderAddGodownFormProps): (Godown) => JSX.Element {
  return function EditGodownForm({
    onSave,
  }: {
    onSave: (props: any) => Promise<void>;
  }): JSX.Element {
    const saveGodown = async (form: GodownFormType) => {
      await onSave(form);
    };
    const formProps: FormProps<GodownFormType> = {
      fieldsData: [
        {
          property: 'name',
          label: 'Godown Name',
          type: 'input',
          required: true,
        },
        {
          property: 'parentGodownId',
          type: 'searchable-select',
          label: 'Parent Godown (Do not add if want to create root godown)',
          searchOptions: {
            useSearch: useCombinedGodown,
            onSearchValueSelect(u) {},
          },
        },
        { property: 'address', label: 'Godown Address', type: 'input' },
      ],
      allowUnchanged: true,
      initialFormState: {
        name: '',
        parentGodownId: '',
        address: '',
      },
      mapTToU: mapPFDToP,
      onSubmit: saveGodown,
    };

    const Form = renderFormV2<GodownFormType>(formProps);

    return <Form />;

    function mapPFDToP(b: FormDataType<GodownFormType>): GodownFormType {
      return b as GodownFormType;
    }
  };
}
