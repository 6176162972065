import { HazardPrecaution } from '@erp_core/erp-types/dist/types/modules/inventory/hazard';
import { ErpV2Api } from '../../../../../models/interfaces/adapters/erp-v2-api';
import { HazardPrecautionFilterType } from '../../../../../models/interfaces/admin/hazard-regulations/ghs-precautionary-statement';
import { GenericHttpWithLogsRepo } from '../../generic-resource-with-logs';

export class HttpGhsPrecautionaryStatementRepo extends GenericHttpWithLogsRepo<
  HazardPrecaution,
  HazardPrecautionFilterType
> {
  apiUrl: string;

  erpv2Api: ErpV2Api;

  constructor(apiUrl: string, erpv2Api: ErpV2Api) {
    super({
      erpv2Api,
      baseURI: apiUrl,
      resourceEndpoint: '/hazard-regulations/precaution-statements',
    });

    this.apiUrl = apiUrl;
    this.erpv2Api = erpv2Api;
  }
}
